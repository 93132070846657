import { Checkbox, Picker, t } from 'owc-react-lib-common';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import { IconLink } from 'src/components/IconLink';
import { Modal } from 'src/components/Modal';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { PhotoCircle } from "src/components/PhotoCircle";
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { MODALITY_3x3, styling } from 'src/config';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from 'src/hooks/useAlerts';
import { useUserPermission } from "src/hooks/useUserPermission";
import { TEAM_STATUS_COMPLETED, TEAM_STATUS_INVITE, TEAM_STATUS_PENDING, TEAM_STATUS_PROCESSED, TEAM_STATUS_VALIDATED } from 'src/pages/auth/components/config';
import * as Team3x3Service from 'src/services/Team3x3Service';
import * as TeamService from 'src/services/TeamService';
import { EventContext } from '../EventContext';


interface ITeams {
  teamsData: any[];
  questions?: any[];
  onDelete: (uuid: string) => void;
  onMoveTeam?: (id: number, increase: boolean) => void;
  onReplace?: (uuid: string) => void;
  updateTeams: (addedTeams: any[]) => void;
  persist?: boolean;
  eventCategory?: any;
  groups?: any;
  pendingCheckbox?: boolean;
}

export const Teams = ({ eventCategory, teamsData, questions = [], onDelete, onMoveTeam, updateTeams, onReplace, persist = false, groups, pendingCheckbox = false }: ITeams) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [teamToDelete, setTeamToDelete] = React.useState<any>();
  const [teamToChangeStatus, setTeamToChangeStatus] = React.useState<any>(null);
  const [teamToChangeCategory, setTeamToChangeCategory] = React.useState<any>(null);
  const [completed, setCompleted] = React.useState<any>(false);
  const newEventCategoryId = React.useRef<number>(null);
  const closeTeamStatusToUpdateModal = React.useCallback(() => setTeamToChangeStatus(null), []);
  const closeTeamToChangeCategoryModal = React.useCallback(() => setTeamToChangeCategory(null), []);
  const { data } = React.useContext(EventContext);

  const alerts = useAlerts();
  const hasPosition = teamsData[0] && 'position' in teamsData[0];

  /**
   * render logo
   */
  const logo = React.useCallback((object: any) => {
    return (
      <PhotoCircle alt={object.name} url={object.teamLogoUrl} size={60} />
    );
  }, []);

  const status = React.useMemo(() => [
    { id: TEAM_STATUS_PENDING, label: t('admin.competitions.eventsCompetition.eventTeams.status.pending'), color: "orange" },
    { id: TEAM_STATUS_PROCESSED, label: t('admin.competitions.eventsCompetition.eventTeams.status.processed'), color: "" },
    { id: TEAM_STATUS_VALIDATED, label: t('admin.competitions.eventsCompetition.eventTeams.status.validated'), color: "teal" },
    { id: TEAM_STATUS_COMPLETED, label: t('admin.competitions.eventsCompetition.eventTeams.status.completed'), color: "teal" },
    { id: TEAM_STATUS_INVITE, label: t('admin.competitions.eventsCompetition.eventTeams.status.invite'), color: "darkgreen" },
  ], []);

  /**
   * get status name
   */
  const getStatusName = React.useCallback((object: any) => {
    return status.find((element: any) => element.id === parseInt(object.registerStatus)).label;
  }, [status]);

  /**
 * render status element
 */
  const statusRender = React.useCallback((object: any, onPress = () => { }) => {
    const statusName = getStatusName(object);
    const statusColor = status.find((element: any) => element.id === parseInt(object.registerStatus)).color;
    return (
      <TouchableOpacity onPress={onPress}>
        <Text bold color={statusColor} fontSize={10} uppercase>{statusName}</Text>
      </TouchableOpacity>
    )

  }, [getStatusName, status]);

  /**
 * update status name
 */
  const updateTeamStatus = React.useCallback(async () => {
    try {
      if (parseInt(data.modalityId) === MODALITY_3x3) {
        const payload: Team3x3Service.ITeamEventCategoryDTO = {
          team: { id: teamToChangeStatus.id },
          eventCategory: { id: teamToChangeStatus.eventCategoryId },
          status: parseInt(teamToChangeStatus.registerStatus)
        }
        await Team3x3Service.changeStatus(payload);
      } else {
        await TeamService.update(teamToChangeStatus);
      }
      alerts.create({ text: t("global.success"), type: "success" });
      updateTeams(teamsData.map((team: any) => (team.id === teamToChangeStatus.id && team.eventCategoryId === teamToChangeStatus.eventCategoryId) ? teamToChangeStatus : team))
    } catch (error) {
      alerts.createDSMError(error);
    }
    closeTeamStatusToUpdateModal();
  }, [alerts, closeTeamStatusToUpdateModal, teamToChangeStatus, data.modalityId, teamsData, updateTeams]);

  const changeTeamCategory = React.useCallback(async () => {
    const newTeam = {
      ...teamToChangeCategory,
      eventCategoryId: newEventCategoryId.current,
      eventCategoryName: data.eventCategories.find((eventCategory: any) => eventCategory.id === newEventCategoryId.current).name
    };
    try {
      if (parseInt(data.modalityId) === MODALITY_3x3) {
        const payload: Team3x3Service.ITeamEventCategoryDTO = {
          team: { id: teamToChangeCategory.id },
          eventCategory: { id: teamToChangeCategory.eventCategoryId },
          status: parseInt(teamToChangeCategory.registerStatus)
        }
        await Team3x3Service.changeEventCategory(newEventCategoryId.current, payload);
      } else {
        await TeamService.update(newTeam);
      }
      alerts.create({ text: t("global.success"), type: "success" });

      if (!!eventCategory) {
        updateTeams(teamsData.filter((team: any) => team.id !== teamToChangeCategory.id || team.eventCategoryId !== teamToChangeCategory.eventCategoryId))
      } else {
        updateTeams(teamsData.map((team: any) => (team.id === teamToChangeCategory.id && team.eventCategoryId === teamToChangeCategory.eventCategoryId) ? newTeam : team))
      }
    } catch (error) {
      alerts.createDSMError(error);
    }
    closeTeamToChangeCategoryModal();
    newEventCategoryId.current = null;
  }, [alerts, teamToChangeCategory, data.modalityId, closeTeamToChangeCategoryModal, teamsData, data.eventCategories, eventCategory, updateTeams])

  /**
 * delete team
 */
  const deleteTeam = React.useCallback(async () => {
    try {
      if (!persist) {
        await TeamService.deleteTeam(teamToDelete.uuid, teamToDelete.eventCategoryId);
        alerts.create({ text: t('admin.competitions.eventsCompetition.eventTeams.deleteSuccess'), type: 'success' });
      }

      onDelete(teamToDelete);
      setTeamToDelete(undefined);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [teamToDelete, alerts, onDelete, persist]);

  const replaceTeam = React.useCallback(async (teamId: string) => {
    onReplace(teamId);
  }, [onReplace])

  /**
   * actions
   */
  const actions = React.useCallback((object: any) => {
    return (
      <View flexDirection='row' alignItems='center'>
        {!readOnly && hasPosition && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name='arrow-up-2'
          title={t('global.up')}
          onPress={() => onMoveTeam(object.id, false)}
        />}
        {!readOnly && hasPosition && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name='arrow-down-2'
          title={t('global.down')}
          onPress={() => onMoveTeam(object.id, true)}
        />}
        <IconLink
          backgroundColor={'transparent'}
          color={'black'}
          name={readOnly ? "eye" : "edit"}
          title={t('global.edit')}
          to={`/organizer/events/${object.eventUuid}/teams/${object.uuid}?eventCategoryId=${eventCategory?.id || object.eventCategoryId}`}
        />
        {!readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name={"send"}
          title={t('admin.competitions.eventsCompetition.eventTeams.changeCategory')}
          onPress={() => setTeamToChangeCategory(object)}
        />}
        {(!readOnly && onReplace) && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name={'right-left'}
          title={t('global.replace')}
          onPress={() => replaceTeam(object.id)}
        />}
        {!readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name={persist ? 'ko' : 'trash'}
          title={ t('admin.competitions.eventsCompetition.eventTeams.' + (persist ? 'removeFromGroup' : 'deleteTeam'))}
          onPress={() => setTeamToDelete(object)}
        />}
      </View>
    )
  }, [eventCategory, readOnly, persist, setTeamToDelete, replaceTeam, onReplace, hasPosition, onMoveTeam]);

  /**
   * close delete team
   */
  const closeDeleteModal = React.useCallback(() => {
    setTeamToDelete(undefined);
  }, []);

  /**
   * render
   */
  const headers: ITableHeader[] = [
    ...(hasPosition ? [{ name: 'position', label: '', width: 40 }] : []),
    { name: 'logo', label: '', render: logo, width: 70, sort: (object: any) => object.name },
    {
      name: 'name', label: t('admin.competitions.eventsCompetition.eventTeams.name'), textProps: { uppercase: true },
      render: (o) => {
        if (o.isFicticious) {
          let groupNames = '';
          let position = ''

          // Expresión regular para encontrar el primer conjunto de dígitos
          var patron = /\d+/g;

          // Aplicar la expresión regular a la cadena de texto
          var resultado = o.teamReference.match(patron);
          if (resultado.length > 2) {
            // Es un best of. Les 2 ultimes posicions son la posicio i el millor. Eliminar i quedar-se només amb els grups
            resultado.splice(-2);
          }
          else {
            // Es del tipus posicio 1 del grup x. Quedar-se nomes amb el grup
            position = resultado[1]
            resultado.splice(-1);

          }

          for (let i = 0; i < resultado.length; i++) {
            const group = groups.find(g => g.id === parseInt(resultado[i]));
            groupNames += (group ? group.name + ' ' : '')
         }


          return (
            <View>
              <Text>{o.name}</Text>
              {o.isFicticious && <Text fontSize={10}>{groupNames} {position === '' ? '' : t("admin.rankingSystems.position")} {position}</Text>}
            </View>
          )
        }
        return <Text>{o.name}</Text>
      }
    },
    { name: 'eventCategoryName', label: t('admin.competitions.eventsCompetition.eventTeams.category'), textProps: { uppercase: true } },
    { name: 'teamLevelName', label: t('admin.competitions.eventsCompetition.eventTeams.teamLevelName'), width: 100 },
    { name: 'contact', label: t('admin.competitions.eventsCompetition.eventTeams.contact'), render: (object: any) => <Text uppercase>{object.contactName} {object.contactSurname}</Text>, search: (object: any) => `${object.contactName} ${object.contactSurname}` },
    { name: 'contactEmail', label: t('admin.competitions.eventsCompetition.eventTeams.email'), textProps: { overflow: true } },
    { name: 'contactPhone', label: t('admin.competitions.eventsCompetition.eventTeams.phone'), width: 90 },
    { name: 'registerStatus', label: t('admin.competitions.eventsCompetition.eventTeams.registerStatus'), render: (object: any) => statusRender(object, () => setTeamToChangeStatus(object)), search: getStatusName, width: 110 },
    { name: 'actions', label: t('global.actions'), render: actions, width: (hasPosition ? 180 : 90) }
  ];

  questions.forEach((question: any) => {
    headers.splice(headers.length - 1, 0, {
      name: 'question-' + question.id,
      label: question.listHeader,
      sort: (object: any) => {
        const answer = object.extraInformation?.find((answer: any) => answer.questionId === question.id);
        return answer?.value;
      },
      render: (object: any) => {
        const answer = object.extraInformation?.find((answer: any) => answer.questionId === question.id);

        if (question?.type === 'boolean') {
          return (
            <Text uppercase>{!!answer?.value && answer.value + '' === "true" ? t('global.yes') : t('global.no')}</Text>
          );
        }

        return (
          <Text>{answer?.value}</Text>
        );
      },
    });
  });

  return (
    <View>
      {pendingCheckbox && <Checkbox label={t("admin.events.team.notFinished")} onChange={(v) => setCompleted(v)} defaultValue={false} />}
      <Table defaultSort={"position"} defaultSize={25} headers={headers} data={completed ? teamsData.filter(t => t.registerStatus > 0) : teamsData} />
      {/* <Table defaultSize={25} headers={headers} data={data.filter(t => !t.isFicticious)} /> */}

      <Modal visible={teamToChangeStatus} title={t('admin.competitions.eventsCompetition.eventTeams.changeStatus')} onClose={closeTeamStatusToUpdateModal} width={600}>
        <View flex={1} flexDirection='row' style={{ padding: styling.spacing }}>
          <Picker
            defaultOptionLabel={null}
            label=''
            items={status.map((element: any) => ({ label: element.label, value: element.id }))}
            defaultValue={teamToChangeStatus?.registerStatus}
            onChange={(value: string) => setTeamToChangeStatus({ ...teamToChangeStatus, registerStatus: value || 0 })}
            containerStyle={{ flex: 1, marginBottom: 0 }}
          />
          <Button label={t('global.save')} onPress={updateTeamStatus} marginBottom={0} shadow={false} />
        </View>
      </Modal>

      <Modal visible={teamToChangeCategory} title={t('admin.competitions.eventsCompetition.eventTeams.changeCategory')} onClose={closeTeamToChangeCategoryModal} width={600}>
        <View flex={1} flexDirection='row' style={{ padding: styling.spacing }}>
          <Picker
            defaultOptionLabel={null}
            label=''
            items={data.eventCategories.map((eventCategory: any) => ({ label: eventCategory.name, value: eventCategory.id || 0 }))}
            defaultValue={teamToChangeCategory?.eventCategoryId}
            onChange={(value: string) => newEventCategoryId.current = parseInt(value)}
            containerStyle={{ flex: 1, marginBottom: 0 }}
          />
          <Button label={t('global.save')} onPress={changeTeamCategory} marginBottom={0} shadow={false} />
        </View>
      </Modal>

      <ModalConfirm 
        visible={!!teamToDelete} 
        onSuccess={deleteTeam} 
        onClose={closeDeleteModal} 
        text={persist ? t('admin.competitions.eventsCompetition.eventTeams.removeFromGroupConfirmation', { teamName: teamToDelete?.name }) : t('admin.competitions.eventsCompetition.eventTeams.deleteConfirmation', { teamName: teamToDelete?.name, categoryName: teamToDelete?.eventCategoryName.toUpperCase() })} />
    </View>
  );
}