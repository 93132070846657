import dayjs from "dayjs";
import { Icon, t, Table, Text } from "owc-react-lib-common";
import { View } from "src/components/View";
import { REGISTER_STATUS_COMPLETED } from 'src/pages/public/actor/IRegistrationProcess';

interface IRegisterTeam3x3Team {
  disabled?: boolean;
  teams: any[];
  eventCategory: any;
  onSelectTeam: (team: any) => void;
}

export const RegisterTeam3x3Team = ({ disabled = false, teams, eventCategory, onSelectTeam }: IRegisterTeam3x3Team) => {
  return (
    <Table pagination={false} headers={[{ label: t('3x3.register.name'), width: 400 }, { key: 'errors' }, { key: 'actions', width: 120, justifyContent: 'flex-end' }]} data={teams.map((team: any) => {
      const errors: string[] = [];

      // team already registered
      if (team?.teamEventCategories?.some((teamEventCategory: any) => teamEventCategory.eventCategory.id === eventCategory?.id)) {
        // errors.push('Aquest equip ja està inscrit a aquesta categoria');
        errors.push(t('3x3.register.errors.alreadyRegistered'))
      }

      // equip registrat a una altra categoria si la competicio no ho permet
      if (!eventCategory?.competitionAllowTeamDiferentCategory && (team?.teamEventCategories?.some((teamEventCategory: any) => teamEventCategory.eventCategory.categoryId !== eventCategory?.categoryId))) {
        // errors.push('Aquest equip ja està inscrit a aquesta competició');
        errors.push(t('3x3.register.errors.alreadyRegisteredInOtherCategory'))
      }



      // gender requirements
      if (eventCategory?.gender === 'F' && team?.actors?.some((actor: any) => actor.gender === 'M')) {
        // errors.push('Algun participant no compleix amb el sexe de la competició');
        errors.push(t('3x3.register.errors.wrongGender'))
      }

      // age requirements
      if (team.actors.some((actor: any) => dayjs(actor.formattedPeopleBirthdate, "DD/MM/YYYY").isBefore(dayjs(eventCategory?.earliestBirthdate))
        || dayjs(actor.formattedPeopleBirthdate, "DD/MM/YYYY").isAfter(dayjs(eventCategory?.latestBirthdate)))) {
        // errors.push('Algun participant no compleix amb el requisits d\'edat');
        errors.push(t('3x3.register.errors.wrongAge'))
      }

      // actor amount requirement
      const actorAmount = team.actors.filter((actor: any) => actor.registrationStatus >= REGISTER_STATUS_COMPLETED).length;

      if (actorAmount < (eventCategory?.minPlayers || 0) /*|| actorAmount > (eventCategory?.maxPlayers || 99)*/) {
        // errors.push('L\'equip no compleix el requisit de número de participants (' + actorAmount + ')');
        errors.push(t('3x3.register.errors.wongActorNumber', { actorAmount: actorAmount }))
      }

      // data
      return [
        team.name,
        <View>
          {errors.length ? errors.map((error: string) => <Text color='crimson' key={error}>{error}</Text>) : <Text color='teal'>{t('3x3.register.errors.validTeam')}</Text>}
        </View>,
        <View>
          {!!errors.length && <Icon type='exclamation-circle' color='crimson' />}
          {!errors.length && !disabled && <Icon type='arrowright' onPress={() => onSelectTeam(team)} />}
        </View>
      ];
    })} />
  );
}