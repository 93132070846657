import * as React from 'react';
import { Navbar } from "src/components/Navbar";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { getLangName } from 'src/utils/StringUtils';

export const PublicNavbar = () => {
  const context = React.useContext(OWCOpenDmrContext);

  return (
    <Navbar
      data={[]}
      languages={context.configuration.portal?.availableLanguages?.map((lang) => ({
        label: getLangName(lang),
        onPress: () => context.changeLanguage(lang)
      }))}
    />
  );
}