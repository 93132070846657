import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';

export const useAlerts = () => {
  const context = React.useContext(OWCOpenDmrContext);


  const createDSMError = React.useCallback((error: any) => {
    context.alerts.create({
      text: (error?.response?.data?.issuer === 'optimalway' && error?.response?.data?.message) || t('errors.default'),
      type: 'error'
    });
  }, [context.alerts]);

  return {
    create: context.alerts.create,
    delete: context.alerts.delete,
    createDSMError,
  };
}