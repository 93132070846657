import * as React from "react";
import { Checkbox, Picker, t } from 'owc-react-lib-common';
import { StyleSheet } from "react-native";
import { Tabs } from "src/components/Tabs";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Text } from "src/components/Text";
import { EventContext } from "../EventContext";
import { useAlerts } from "src/hooks/useAlerts";
import { ITableHeader, Table } from "src/components/Table";
import { ROLE_ASSISTANT, ROLE_COACH, ROLE_PLAYER } from "src/pages/auth/components/config";
import * as SettingService from 'src/services/SettingService';
import { ISettingDTO } from "src/services/SettingService";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { Loader } from "src/components/Loader";
import { getSettingName } from "./EventCategorySettingUtils";

interface IEventCategorySettings {
  data: any;
}

export const EventCategorySettings = ({ data }: IEventCategorySettings) => {
  const { create, createDSMError } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const tab = React.useRef(0);
  const { data: event } = React.useContext(EventContext);

  const [settings, setSettings] = React.useState<any>();
  const [categoryToReplicate, setCategoryToReplicate] = React.useState<any>(null);

  const categories = event.eventCategories
    .filter((category: any) => category.id !== data.id)
    .map((category: any) => ({ label: category.name, value: category.id }));

  useComponentDidMount(async () => {
    setLoading(true);
    const response = await SettingService.findByEventCategory(data.id);
    matchSettings(response.data);
    setLoading(false);
  });

  const getRole = React.useCallback((roleId: number) => {
    switch (roleId) {
      case ROLE_PLAYER: return "player";
      case ROLE_COACH: return "coach";
      case ROLE_ASSISTANT: return "assistant";
    }
  }, []);

  const matchSettings = React.useCallback((settings: ISettingDTO[]) => {
    const matchedSettings = { player: [], coach: [], assistant: [] };

    settings.forEach(async (setting: ISettingDTO) => {
      matchedSettings[getRole(setting.roleId)].push({ ...setting, eventCategoryId: data.id });
    });

    setSettings(matchedSettings);

  }, [data, getRole]);

  const replicateSettings = React.useCallback(async () => {
    try {
      const response = await SettingService.replicateEventCategory(data.id, categoryToReplicate.id);
      create({ type: "success", text: t("admin.events.settings.replicate.success", { category: categoryToReplicate?.name }) });
      matchSettings(response.data);
    } catch (error) {
      createDSMError(error);
    }

    setCategoryToReplicate(null);
  }, [create, createDSMError, categoryToReplicate, data, matchSettings]);

  const updateSettings = React.useCallback(async (newSetting: ISettingDTO) => {
    const role = getRole(newSetting.roleId);

    const currentRequiredSettings = settings[role].filter((setting: ISettingDTO) => setting.required);
    if (currentRequiredSettings.length <= 1 && newSetting.typeId === currentRequiredSettings[0].typeId) {
      create({ type: "error", text: t("admin.events.settings.nonRequiredError") });
      return;
    }

    try {
      const response = newSetting.id === null ? await SettingService.addSetting(data.id, newSetting) : await SettingService.updateSetting(data.id, newSetting);
      setSettings({ ...settings, [role]: settings[role].map((setting: ISettingDTO) => newSetting.typeId === setting.typeId ? response.data : setting) });
      create({ type: "success", text: t("global.success") });
    } catch (error) {
      createDSMError(error);
    }

  }, [create, createDSMError, data, settings, getRole]);

  const renderType = React.useCallback((setting: ISettingDTO) => {
    return <Text>{getSettingName(setting.typeId)}</Text>
  }, [])

  const renderCheckboxVisible = React.useCallback((setting: ISettingDTO) => {
    return (
      <Checkbox
        key={setting.id}
        value={setting.visible}
        containerStyle={styles.checkbox}
        onChange={(value) => updateSettings({ ...setting, visible: value, required: value ? setting.required : false })}
      />
    )
  }, [updateSettings]);

  const renderCheckboxRequired = React.useCallback((setting: ISettingDTO) => {
    return (
      <Checkbox
        key={setting.id}
        value={setting.required}
        containerStyle={styles.checkbox}
        disabled={!setting.visible}
        onChange={(value) => updateSettings({ ...setting, required: value })}
      />
    )
  }, [updateSettings]);

  if (loading) {
    return <Loader />;
  }

  const tabHeaders = [
    t("auth.competitions.teamCreation.actors.player"),
    t("auth.competitions.teamCreation.actors.coach"),
    t("auth.competitions.teamCreation.actors.assistant"),
  ];

  const tableHeaders: ITableHeader[] = [
    {
      name: "typeId",
      label: t("admin.events.settings.table.name"),
      textProps: { uppercase: true },
      render: (setting: ISettingDTO) => renderType(setting),
    },
    {
      name: "visible",
      label: t("admin.events.settings.table.visible"),
      textProps: { uppercase: true, textAlign: "center" },
      width: 90,
      render: (setting: ISettingDTO) => renderCheckboxVisible(setting),
    },
    {
      name: "required",
      label: t("admin.events.settings.table.required"),
      textProps: { uppercase: true, textAlign: "center" },
      width: 90,
      render: (setting: ISettingDTO) => renderCheckboxRequired(setting),
    },
  ];

  return (
    <>
      {categories.length > 0 && <Picker
        label={`${t("admin.events.settings.replicate.label")}:`}
        items={categories}
        onChange={(object) => setCategoryToReplicate(event.eventCategories.find((category: any) => category.id === parseInt(object)))}
      />}

      <Tabs headers={tabHeaders} initialTab={tab.current} onChangeTab={(index: number) => tab.current = index}>
        <Table defaultSize={25} defaultSort={"typeId"} headers={tableHeaders} data={settings?.player} />
        <Table defaultSize={25} defaultSort={"typeId"} headers={tableHeaders} data={settings?.coach} />
        <Table defaultSize={25} defaultSort={"typeId"} headers={tableHeaders} data={settings?.assistant} />
      </Tabs>

      <ModalConfirm
        text={t("admin.events.settings.replicate.question", { category: categoryToReplicate?.name })}
        visible={!!categoryToReplicate}
        onSuccess={replicateSettings}
        onClose={() => setCategoryToReplicate(null)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: "center",
  },
});
