import { Button as OWCButton } from 'owc-react-lib-common';
import * as React from 'react';
import { ColorValue, ViewProps } from 'react-native';
import { styling } from 'src/config';
import { Icon, IIcon } from './Icon';

/**
 * interface
 */
export interface IButton {
  label?: string;
  onPress: (e: any) => void;
  type?: "primary" | "secondary" | 'accent' | "success" | "info" | "warning" | "danger" | "link";
  disabled?: boolean;
  containerStyle?: ViewProps['style'];
  icon?: IIcon;
  color?: ColorValue;
  backgroundColor?: ColorValue;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  textAlign?: 'left' | 'center' | 'right';
  shadow?: boolean;
  minHeight?: number;
  marginBottom?: number;
  textPaddingLeft?: number;
  title?: string;
  flexDirection?: "column" | "column-reverse" | "row" | "row-reverse" | undefined;
  fontWeight?: number;
}

/**
 * button component
 */
export const Button = ({ label, onPress, type = 'primary', disabled = false, containerStyle, icon, color, justifyContent = 'center', textAlign = 'center', shadow = true, minHeight = 40, marginBottom = styling.spacing, textPaddingLeft, title, backgroundColor, flexDirection, fontWeight }: IButton) => {
  const [loading, setLoading] = React.useState(false);

  /**
   * on press
   */
  const handlePress = React.useCallback(async (e?: any) => {
    setLoading(true);

    try {
      await Promise.resolve(onPress(e));
    } catch (error) {
      //
    }

    setLoading(false);
  }, [onPress]);

  /**
   * render
   */
  const style: any = { width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', justifyContent, minWidth: 0, minHeight, color: color as string };

  if (!shadow) {
    style.boxShadow = 'none';
  }

  return (
    <OWCButton fontWeight={fontWeight} flexDirection={flexDirection} justifyContent={justifyContent} backgroundColor={backgroundColor?.toString()} containerStyle={[containerStyle, { minHeight }]} type={getColor(type)} onPress={handlePress} disabled={loading || disabled} text={label} marginBottom={marginBottom} color={color?.toString()} borderColor={"transparent"}>
      {!!icon && <Icon {...icon} color={color} />}
    </OWCButton>
  );
}

/**
 * type to color
 */
const getColor = (type: IButton['type']) => {
  if (type?.toString() === 'text') {
    return 'primary';
  }

  return type;
}