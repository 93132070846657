import { Input, Picker, t } from 'owc-react-lib-common';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import { PhotoCircle } from 'src/components/PhotoCircle';
import { Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { useAlerts } from 'src/hooks/useAlerts';
import { useEffectChange } from 'src/hooks/useEffectChange';
import * as StandingService from 'src/services/StandingService';


export const Standings = ({ teams, matches }) => {
	const alerts = useAlerts();
	const { createDSMError } = useAlerts();
	const [standing, setStanding] = React.useState<any>(null);
	const [rounds, setRounds] = React.useState<any>([]);
	const [selectedRound, setSelectedRound] = React.useState<any>(null);
	const { groupUuid } = useParams();

	useEffectChange(() => {
		load();
	}, [matches])

	const getStandings = React.useCallback(async (round = null) => {
		try {
			const response = await StandingService.findByUuid(groupUuid, round);

			setStanding(response.data)
			setSelectedRound(round);
		}
		catch(error) {
			createDSMError(error);
		}
	}, [createDSMError, groupUuid]);

	const load = React.useCallback(async () => {
		await getStandings();

		const roundMap = matches.map((m: any) => {
			return {
				label: `${t('admin.events.standings.round')} ${m[0].roundNumber}`,
				value: m[0].roundNumber
			}
		})

		setRounds(roundMap)
	}, [getStandings, matches]);


	const recalculate = React.useCallback(async () => {
		try {
			await StandingService.compute(groupUuid);
			getStandings()
		}
		catch(error) {
			createDSMError(error);
		}
	}, [createDSMError, groupUuid, getStandings]);

	
	const modifyRound = async () => {
		try {
			await StandingService.modifyRound(groupUuid, selectedRound, standing);
			alerts.create({ text: t('admin.events.standings.updatedRound'), type: 'success' })
		} catch (error) {
			createDSMError(error);
		}

	}
	const toggleBlockRound = async () => {
		try {
			await StandingService.blockRound(groupUuid, !standing[0].blockedRound, selectedRound);
			setStanding(standing.map(s => {
				return {
					...s,
					blockedRound: !s.blockedRound
				}
			}))
			alerts.create({ text: !standing[0].blockedRound ? t('admin.events.standings.roundBlocked') : t('admin.events.standings.roundUnblocked'), type: 'success' })
		} catch (error) {
			createDSMError(error);
		}
	}

	const renderPosition = (object) => {
		if (object.blockedRound) {
			return (
				<Input defaultValue={object.standingPosition} onChange={e => object.standingPosition = parseInt(e)} />
			)
		} else {
			return (
				<Text>{object.standingPosition}</Text>
			)
		}
	}

	const headers = [
		{ name: 'standingPosition', label: t('admin.events.standings.position'), width: 60, render: renderPosition },
		{
			name: 'logo', label: t('admin.events.standings.team'), width: 70, render: (object: any) => {
				const team = teams.find((team: any) => parseInt(team.id) === parseInt(object.teamId))
				return <PhotoCircle size={60} url={team?.teamLogoUrl} alt={team?.name} />;
			}
		},
		{ name: 'teamId', label: '', render: (object) => (<Text>{teams.find(t => parseInt(t.id) === parseInt(object.teamId))?.name}</Text>) },
		{ name: 'playedGames', label: t('admin.events.standings.playedGames') },
		{ name: 'wonGames', label: t('admin.events.standings.wonGames') },
		{ name: 'lostGames', label: t('admin.events.standings.lostGames') },
		{ name: 'notPlayed', label: t('admin.events.standings.notPlayed') },
		{ name: 'scoreTeam', label: t('admin.events.standings.scoreTeam') },
		{ name: 'scoreOpponent', label: t('admin.events.standings.scoreOpponent') },
		{ name: 'points', label: t('admin.events.standings.points') },
	]

	return (
		<View>
			<View flexDirection='row' justifyContent='space-between' alignItems='center' style={{ padding: 10 }}>
				<View style={{ width: '50%', gap: 10 }} flexDirection='row'>
					<Picker items={rounds} onChange={e => getStandings(e)} />
					{selectedRound &&
						<Button onPress={toggleBlockRound} label={standing[0]?.blockedRound ? t('admin.events.standings.unblockRound') : t('admin.events.standings.blockRound')} />
					}
					{standing?.[0]?.blockedRound &&
						<Button onPress={modifyRound} label={t('admin.events.standings.save')} />
					}
				</View>

				<IconButton containerStyle={{ width: 40, height: 40 }} title={t('admin.events.standings.generate')} type='primary' name='backtime' onPress={recalculate} />
			</View>
			{!!standing &&
				<Table
					headers={headers}
					data={standing}
					pagination={false}
				/>
			}
		</View>
	)
}
