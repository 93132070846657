import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import { default as resourceTimeGridDayPlugin, default as resourceTimelinePlugin } from '@fullcalendar/resource-timeline'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { t } from 'owc-react-lib-common'
import React, { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { View } from 'src/components/View'
import * as CalendarHelper from './CalendarHelper'
import { ColorHelper } from './ColorHelper'
import { ExternalEvents } from './ExternalEvents'


export interface MatchEvent {
  localName: string;
  awayName: string;
  categoryName: string;
  id: number;
  duration: string;
}
export interface ICalendar {
  locale?: string;
  leftToolbar?: string;
  centerToolbar?: string;
  rightToolbar?: string;
  initialView?: string;
  editable?: boolean;
  selectable?: boolean;
  allDaySlot?: boolean;
  slotDuration?: string;
  slotLabelInterval?: string;
  slotMinTime?: string;
  slotMaxTime?: string;
  selectMirror?: boolean;
  dayMaxEvents?: boolean;
  weekends?: boolean;
  initialEvents?: any[];
  externalEvents?: any[];
  groupedExternalEvents?: any[];
  calendarEvents?: any[];
  resources?: any[];
  customButtons?: any;
  colorHelper?: ColorHelper;
  select?: () => void;
  eventContent?: (data: any) => ReactElement;
  groupExternalEvents?: (data: any) => any;
  eventClick?: (e: any) => void;
  eventsSet?: () => void;
  eventDrop?: (e: any) => any;
  eventAdd?: (e: any) => void;
  eventChange?: () => void;
  eventRemove?: (e: any) => any;
}


export const Calendar = ({
  locale = 'es',
  leftToolbar = 'prev,next today openModalButton',
  centerToolbar = 'title',
  rightToolbar = 'testButton,resourceTimeGridDay,dayGridMonth',
  //initialView = 'resourceTimelineDay',
  initialView = 'resourceTimeGridDay',
  editable = true,
  selectable = false,
  allDaySlot = false,
  slotDuration = '00:05:00',
  slotLabelInterval = '00:20',
  slotMinTime = '10:00:00',
  slotMaxTime = '19:00:00',
  selectMirror = true,
  dayMaxEvents = true,
  weekends = true,
  externalEvents = [],
  groupedExternalEvents = [],
  calendarEvents = [],
  resources = [],
  customButtons,
  colorHelper,
  select = () => { },
  eventContent,
  eventClick = () => { },
  eventAdd,
  eventDrop,
  eventRemove
}: ICalendar) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const [events, setEvents] = React.useState({
    calendarEvents: [...calendarEvents],
    externalEvents: [...externalEvents],
    groupedExternalEvents: [...groupedExternalEvents],
  });

  //const [dashedBorder, setDashedBorder] = React.useState('#DDDDDD');
  const eventComponent = React.useRef(null);
  const calendar = React.useRef(null);

  const searchExternalEvent = (id) => externalEvents.find(e => e.id === Number.parseInt(id));


  /**
   * Final de arrastrar un elemento del calendario
   * @param e 
   */
  const onEventDragStop = (e) => {

    //setDashedBorder('#DDDDDD')
    
    // Eliminar eventos bloqueados
    const calendar = e.view.calendar;
    CalendarHelper.removeBlockElements(calendar);

    // Si el drag esta fuera de los limites del calendario, eliminar el evento
    const offsides = eventComponent.current.getBoundingClientRect();
    if (CalendarHelper.isInside(offsides, e.jsEvent)) {
      calendar.getEventById(e.event.id).remove()
      const newCalendarEvents = events.calendarEvents.filter((event) => event.id !== Number.parseInt(e.event.id));
      const newExternalEvents = events.calendarEvents.filter((event) => event.id === Number.parseInt(e.event.id));
      
      setEvents({
        calendarEvents: [...newCalendarEvents],
        externalEvents: [...events.externalEvents, ...newExternalEvents],
        groupedExternalEvents: [...events.groupedExternalEvents, ...newExternalEvents]
      });

      // Funcion callback de eliminar
      eventRemove(e)
    }
  }


  /**
   * Inicio de arrastrar un elemento del calendario
   * @param e 
   */
  const onEventDragStart = (e) => {

    //setDashedBorder('green')

    const calendarApi = e.view.calendar;
    const events = calendarApi.getEvents();

    //Primero limpiamos..
    CalendarHelper.removeBlockElements(calendarApi);

    // Mirar eventos de los mismos equipos y bloquear las filas donde ya haya un partido puesto
    events.forEach((event) => {
      if (Number.parseInt(event.id) === Number.parseInt(e.event.id)) {
        return;
      }

      if (
        Number.parseInt(event.extendedProps.localId) !== Number.parseInt(e.event.extendedProps.localId) &&
        Number.parseInt(event.extendedProps.localId) !== Number.parseInt(e.event.extendedProps.awayId) &&
        Number.parseInt(event.extendedProps.awayId) !== Number.parseInt(e.event.extendedProps.localId) &&
        Number.parseInt(event.extendedProps.awayId) !== Number.parseInt(e.event.extendedProps.awayId)
      ) {
        return;
      }

      CalendarHelper.addBlockElements(calendarApi, event.start, event.end)

    });

  }


  /**
   * Elemento añadido desde menu externo
   * @param e 
   * @returns 
   */
  const onDrop = (e) => {

    // Eliminar elementos bloqueados (se habian puesto desde el menu externo)
    CalendarHelper.removeBlockElements(e.view.calendar);

    //setDashedBorder('#DDDDDD')

    const filteredEvents = events.externalEvents.filter(event => event.id !== Number.parseInt(e.draggedEl.dataset.id))

    const calendarEvent = searchExternalEvent(e.draggedEl.dataset.id);
    if(!calendarEvent) return;

    setEvents({
      externalEvents: filteredEvents,
      calendarEvents: [calendarEvent, ...events.calendarEvents],
      groupedExternalEvents: [...events.groupedExternalEvents]
    })
    
    // Funcion de callback de añadir
    eventAdd(
      {
        awayName: calendarEvent.awayName,
        awayId: calendarEvent.awayId,
        categoryName: calendarEvent.categoryName,
        duration: calendarEvent.duration,
        id: calendarEvent.id,
        localName: calendarEvent.localName,
        localId: calendarEvent.localId,
        roundNumber: calendarEvent.roundId,
        // GroupId, named this way because conflicts with FullCalendar reserved word
        matchGroupId: calendarEvent.matchGroupId,
        matchGroupName: calendarEvent.matchGroupName,
        errorAutomaticAssignation: calendarEvent.errorAutomaticAssignation,
        categoryInitials: calendarEvent.categoryInitials,
        automaticAssignationCalendarColor: calendarEvent.automaticAssignationCalendarColor,
        start: e.date,
        resourceId: e.resource.id,
      }
      
    );

    
    
  }

  const onDateClick = (e) => {
    const api = calendar.current.getApi();
    api.changeView('resourceTimeGridDay', e.date);
  }

  const datesSet = (e) => {
    setSearchParams({ date: e.startStr });
  }

  const CUSTOM_BUTTONS = {
    ...customButtons,
    testButton: {
      text: t('admin.events.calendar.resourceDay'),
      hint: t('admin.events.calendar.resourceDay'),
      click: () => {
        const api = calendar.current.getApi();
        api.changeView('resourceTimelineDay');
      }
    }
  };

  const SLOT_LABEL_FORMAT = {
    hour: 'numeric',
    minute: 'numeric',
    omitZeroMinute: false,
    meridiem: false
  } as any;

  const BUTTON_TEXT = {
    day: t('admin.events.calendar.day'),
    today: t('admin.events.calendar.today'),
    month: t('admin.events.calendar.month')
  }


  return (
    <View flexDirection='row'>
      <View style={[{ width: '15%', padding: 10 }]}>
        <ExternalEvents editable={editable} colorHelper={colorHelper} calendar={calendar} dashedBorder={'#DDDDDD'} eventComponent={eventComponent} groupedExternalEvents={groupedExternalEvents}></ExternalEvents>
      </View>
      <View style={[{ width: '85%', padding: 10 }]}>
        <FullCalendar
          ref={calendar}
          initialDate={searchParams.get('date')}
          dragRevertDuration={0}
          height={'90vh'}

          customButtons={CUSTOM_BUTTONS}
          stickyFooterScrollbar
          slotMinTime={slotMinTime}
          slotMaxTime={slotMaxTime}
          slotLabelInterval={slotLabelInterval}
          slotDuration={slotDuration}
          slotLabelFormat={SLOT_LABEL_FORMAT}
          dateClick={onDateClick}
          locale={locale}
          buttonText={BUTTON_TEXT}
          datesSet={datesSet}
          schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin, resourceTimeGridDayPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
          headerToolbar={{
            left: leftToolbar,
            center: centerToolbar,
            right: rightToolbar
          }}
          initialView={initialView}
          editable={editable}
          eventDurationEditable={false}
          selectable={selectable}
          allDaySlot={allDaySlot}
          selectMirror={selectMirror}
          dayMaxEvents={dayMaxEvents}
          weekends={weekends}
          initialEvents={events.calendarEvents} // alternatively, use the `events` setting to fetch from a feed
          resources={resources}
          resourceOrder={'-type1,type2'}
          resourceGroupField='facilityName'
          select={select}
          eventContent={eventContent} // custom render function
          eventClick={eventClick}
          eventChange={eventDrop}     // update event
          droppable          
          drop={onDrop}               // add event from external list
          eventOverlap={false}
          nowIndicator          
          eventDragStop={onEventDragStop}
          eventDragStart={onEventDragStart}
        />
      </View>
    </ View>
  );

}
