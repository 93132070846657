
import dayjs from 'dayjs';
import * as React from 'react';
import { i18n, t } from 'owc-react-lib-common';
import { init } from '../lang/i18next'
import { DEFAULT_LANGUAGE } from 'src/config';
import * as RestService from 'src/services/RestService';
import * as UserService from 'src/services/UserService';
import * as AsyncStorageUtils from 'src/utils/AsyncStorageUtils';
import * as UserUtils from 'src/utils/UserUtils';
import * as PortalService from 'src/services/PortalService';
import * as ShoppingCartService from 'src/services/ShoppingCartService';
import { useQueryParams } from './useQueryParams';

export default function useCachedResources() {
  const [data, setData] = React.useState(null as any);
  const { lang: queryLang } = useQueryParams();

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      const data: any = {};

      try {
        //Portal definition
        data.portal = window.location.hostname

        RestService.setDefaultHeader('X-Portal', data.portal);

        // set rest headers
        RestService.setDefaultHeader('federation', 'opendmr');
        RestService.setDefaultHeader('X-App-Origin', 'opendmr');
        RestService.setDefaultHeader('X-Organizer-Code', '');

        // fetch logged user
        const [jwt, lang, portal]: any[] = await Promise.all([
          AsyncStorageUtils.getJwt(),
          AsyncStorageUtils.getLanguage(),
          PortalService.getPortal()
        ]);

        data.portal = portal.data;
        
        // user
        try {
          if (!!jwt) {
            RestService.setDefaultHeader('Authorization', jwt);

            // refresh token
            const response = await UserService.refresh();
            RestService.setDefaultHeader('Authorization', response.headers.authorization);
            data.user = UserUtils.decode(response.headers.authorization);
          }
          else {
            throw new Error('not logged');
          }
        } catch (error) {
          // AsyncStorageUtils.setJwt('');
          RestService.setDefaultHeader('Authorization', '');
          delete data.user;
          AsyncStorageUtils.setJwt('');
          AsyncStorageUtils.setRedirect(window.location.href);
        }

        // language
        await init();

        // i18n.instance.on('languageChanged', setLanguage);
        const language = queryLang || lang || portal.data.availableLanguages[0] ||DEFAULT_LANGUAGE;
        AsyncStorageUtils.setLanguage(language);
        i18n.changeLanguage(language);
        RestService.setDefaultHeader('Accept-Language', language);
        dayjs.locale(language);

        document.title = t('global.registration') + " " + portal.data.name.toUpperCase();

        // Shopping Cart
        if(!!jwt){
          const shoppingCart = await ShoppingCartService.find();
          data.shoppingCart = shoppingCart;
        }
      } catch (error) {
        //
      }

      data.loaded = true;
      setData(data);
    }

    // 
    loadResourcesAndDataAsync();
  }, [queryLang]);

  return data;
}
