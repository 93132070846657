import { BACKEND_URL } from "src/config";
import * as RestService from "src/services/RestService";

export interface ICourtEventDTO {
  id?: number;
  courtId?: number;
  eventId?: number;
  facilityName?: string;
  courtDescription?: string;
  courtCamera?: string;
  courtIsCovered?: boolean;
}

const BASE_URL = BACKEND_URL + "/auth/court-events";

export const getCourtEventsByEventId = (eventId: string) => {
  return RestService.get(`${BASE_URL}/courts-by-event/${eventId}`);
};

export const createCourtEvent = (courtEventDTO: ICourtEventDTO) => {
  return RestService.post(`${BASE_URL}`, courtEventDTO);
};

export const deleteCourtEvent = (courtEventId: string) => {
  return RestService.delete(`${BASE_URL}/${courtEventId}`);
};
