import * as React from "react";
import { t } from "owc-react-lib-common";

import { useEffectChange } from "src/hooks/useEffectChange";
import { useAlerts } from "src/hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { Container } from "src/components/Container";
import { Button } from "src/components/Button";
import { View } from "src/components/View";
import { ModalConfirm } from "src/components/ModalConfirm";
import { IconButton } from "src/components/IconButton";
import * as RankingSystemService from "src/services/RankingSystemService";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const RankingSystemList = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rankingSystemToDelete, setRankingSystemToDelete] = React.useState<any>(null);
  const [data, setData] = React.useState<any[]>([]);

  useEffectChange(async () => {
    setLoading(true);
    try {
      const response = await RankingSystemService.getRankingSystem();
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  }, []);

  const deleteRankingSystem = React.useCallback(async () => {
    try {
      await RankingSystemService.deleteRankingSystem(rankingSystemToDelete);
      alerts.create({ text: t("admin.rankingSystems.deleteConfirmation"), type: "success", });
      setData(data.filter((rankingSystem: any) => rankingSystem.id !== rankingSystemToDelete));
      setRankingSystemToDelete(null);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [rankingSystemToDelete, alerts, data]);

  const actions = React.useCallback((rankingSystem: any) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name={readOnly ? "eye" : "edit"}
          onPress={() => navigate(`${rankingSystem.uuid}`)}
        />
        {!readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'} name="trash"
          onPress={() => setRankingSystemToDelete(rankingSystem.id)}
        />}


      </View>
    );
  }, [navigate, readOnly]);

  if (loading) {
    return <Loader />;
  }

  // RENDER
  const headers: ITableHeader[] = [
    { name: "id", label: t("admin.rankingSystems.id"), width: 90 },
    {
      name: "name",
      label: t("admin.rankingSystems.name"),
      textProps: { uppercase: true },
    },
    {
      name: "actions",
      label: "",
      render: actions,
      width: 90,
    },
  ];

  return (
    <Container title={t("admin.rankingSystems.title")}>
      <Button label={t("admin.rankingSystems.create")} onPress={() => navigate(`create`)} disabled={readOnly} />
      <Table headers={headers} data={data} />
      <ModalConfirm visible={rankingSystemToDelete} text={t("admin.rankingSystems.deleteQuestion")} onClose={() => setRankingSystemToDelete(null)} onSuccess={deleteRankingSystem} />
    </Container>
  );
};
