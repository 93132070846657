import * as React from "react";
import { View } from "src/components/View";
import { Combination } from "./Combination";
import { Button } from "src/components/Button";
import { t } from "owc-react-lib-common";

export const OneRound = ({ groupId, groupTeams, updateRound, onSave }) => {
  const [rounds, setRounds] = React.useState([]);
  const [positionTable] = React.useState(() => {
    const matches = []
    groupTeams.forEach((team, i) => {
      if (i % 2 !== 0) return
      matches.push(
        {
          homeTeam: {
            position: i + 1,
            bgColor: team.bgColor
          },
          awayTeam: {
            position: i + 2,
            bgColor: groupTeams[i + 1].bgColor
          }
        }
      )
    });
    return [
      {
        roundNumber: 1,
        matches: matches
      }
    ]
  })

  React.useEffect(() => {
    const matches = []
    groupTeams.forEach((team, i) => {
      if (i % 2 !== 0) return
      matches.push(
        {
          homeTeam: team.id,
          awayTeam: groupTeams[i + 1].id,
          roundNumber: 1,
          gameDate: null,
          courtId: null
        }
      )
    });

    setRounds([
      {
        id: null,
        uuid: null,
        startDate: null,
        groupId: groupId,
        roundNumber: 1,
        externalUuid: null,
        matches: matches
      }
    ])
  }, [groupTeams, groupId])

  return (
    <React.Fragment>

      <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <View style={{ width: 290, padding: 10 }}>
          <Combination matchTable={rounds} groupTeams={groupTeams} positionTable={positionTable} updateRound={updateRound} />
        </View>
      </View>
      <Button onPress={() => {
        const payload = [];
        const copy = [...rounds];
        copy.forEach(round => {
          const matches = round.matches.map(match => {
            return {
              homeTeam: match.homeTeam,
              awayTeam: match.awayTeam,
              roundNumber: 1,
              gameDate: null,
              courtId: null
            };
          })
          payload.push({ roundNumber: round.roundNumber, matches: matches, groupId: groupId, startDate: round.roundDate });

        })
        onSave(payload)

      }} label={t('global.save')} />
    </React.Fragment>
  )
}
