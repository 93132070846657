import { t } from 'owc-react-lib-common';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import { Loader } from 'src/components/Loader';
import { Modal } from 'src/components/Modal';
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { useAlerts } from 'src/hooks/useAlerts';
import * as Team3x3Service from 'src/services/Team3x3Service';
import * as TeamService from 'src/services/TeamService';
import { EventContext } from '../EventContext';
import { Teams } from '../components/Teams';


interface IEventCategoryTeams {
  data: any[];
  questions: any[];
  onDelete: (uuid: string) => void;
  updateTeams: (addedTeams: any[]) => void;
  onMoveTeam?: (id: number, increase: boolean) => void;
  onReplace?: (uuid: string) => void;
  onAdd?: (uuid: string) => void;
  persist?: boolean;
  eventCategory?: any;
  allTeamsButton?: boolean;
  groups?: any[];
}

export const EventCategoryTeams = ({ eventCategory, data, questions, onDelete, updateTeams, onMoveTeam, onReplace, onAdd, persist, allTeamsButton = true, groups }: IEventCategoryTeams) => {
  const [loading, setLoading] = React.useState(true);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [allTeams, setAllTeams] = React.useState([]);
  const { data: event } = React.useContext(EventContext);
  const alerts = useAlerts();

  React.useEffect(() => {
    setLoading(false)
    TeamService.findAllByModality(event.modalityId).then(response => {
      setAllTeams(response.data)
    })
  }, [event])

  const registerTeam = async (object: any) => {
    const params = window.location.pathname.split('/');
    try {
      await Team3x3Service.registerByInvitation(object.uuid, params[params.length - 1]);
      alerts.create({ text: t("global.success"), type: "success" });
      onAdd(object);
      setModalVisible(false)
    } catch (error) {
      alerts.createDSMError(error);
    }
  }

  const headers: ITableHeader[] = [
    { name: 'name', label: t('admin.competitions.eventsCompetition.eventTeams.name'), textProps: { uppercase: true } },
    { name: 'gender', label: t('admin.competitions.eventsCompetition.eventTeams.gender'), textProps: { uppercase: true } },
    { name: 'contact', label: t('admin.competitions.eventsCompetition.eventTeams.contact'), render: (object: any) => <Text uppercase>{object.contactName} {object.contactSurname}</Text>, search: (object: any) => `${object.contactName} ${object.contactSurname}` },
    { name: 'contactEmail', label: t('admin.competitions.eventsCompetition.eventTeams.email'), textProps: { overflow: true } },
    { name: 'contactPhone', label: t('admin.competitions.eventsCompetition.eventTeams.phone'), width: 90 },
    {
      name: "actions", label: " ", render: (object) => {
        return (
          <View flexDirection='row' alignItems='center'>
            <IconButton name='add' onPress={() => { registerTeam(object) }} />
          </View>
        )
      },
      width: 100
    },

  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <View>
      {allTeamsButton && <Button label={t('admin.competitions.eventsCompetition.eventTeams.allTeamsList')} onPress={() => { setModalVisible(true) }} />}
      <Teams
        eventCategory={eventCategory}
        updateTeams={updateTeams}
        teamsData={data}
        questions={questions}
        onDelete={onDelete}
        onMoveTeam={onMoveTeam}
        onReplace={onReplace}
        persist={persist}
        groups={groups}
      />
      <Modal visible={modalVisible} onClose={() => setModalVisible(false)}>
        <Table headers={headers} data={allTeams} />
      </Modal>
    </View>
  );
}
