import { BACKEND_URL } from 'src/config';
import { IEventDTO } from 'src/services/EventService';
import * as RestService from 'src/services/RestService';


export interface CompetitionDTO {
  id?: number;
  uuid?: string;
  name?: string;
  externalUuid?: string;
  seasonId?: number;
  modalityId?: number;
  seasonName?: string;
  events?: IEventDTO[]
  description?: string;
  organizerId?: number;
  organizerName?: string;
  organizerUuid?: string;
  deleted?: any;
  logoFile?: string;
  originalLogoFilename?: string;
}

const BASE_URL = BACKEND_URL + '/auth/competitions'



/**
 * 
 * @returns Devuelve la competicion por su uuid
 */
export const findByUuid = (uuid?: string) => {
  return RestService.get(`${BASE_URL}/${uuid}`);
}



/**
 * 
 Devuelve todas las competiciones del organizador en la temporada o en la activa si no se informa
 */
export const findBySeason = (seasonId?: number) => {
  return RestService.get(`${BASE_URL}` + (!!seasonId ? `?seasonId=${seasonId}` : ''))
}


/**
 * 
 Crea una competicion
 */
export const create = (competitionDTO: CompetitionDTO) => {
  return RestService.post(`${BASE_URL}`, competitionDTO)
}


/**
 * 
 Modifica una competicion
 */
export const update = (competitionDTO: CompetitionDTO) => {
  return RestService.put(`${BASE_URL}`, competitionDTO)
}


/**
 * 
 Elimina una competicion
 */
export const deleteCompetition = (competitionUuid: string) => {
  return RestService.delete(`${BASE_URL}/${competitionUuid}`)
}

/**
 * Devuelve todas las competiciones en las que el usuario tiene un equipo inscrito
 */
export const findAll = () => {
  return RestService.get(`${BASE_URL}/user-competitions`);
}