import * as React from 'react';
import { Image, StyleSheet } from 'react-native';
import { Link, useNavigate } from 'react-router-dom';
import { styling } from 'src/config';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { ButtonLink } from './ButtonLink';
import { View } from './View';
import { useResponsiveStyles } from 'owc-react-lib-common';
import { Button } from './Button';
import { NavbarDropdown } from './NavbarDropdown';
import { Hoverable } from 'react-native-web-hover';
import { IconButton } from './IconButton';
import { Text } from './Text';

interface INavbar {
  data?: INavbarData[];
  organizer?: INavbarData[];
  admin?: INavbarData[];
  settings?: INavbarData[];
  languages?: INavbarData[];
  invitations?: INavbarData[];
}

interface INavbarData {
  label: string;
  to?: string;
  onPress?: () => void;
  type?: 'text' | 'separator';
}

export const Navbar = ({ data = [], organizer = [], admin = [], settings = [], languages = [], invitations = [] }: INavbar) => {
  const navigate = useNavigate();
  const context = React.useContext(OWCOpenDmrContext);
  const styles = useResponsiveStyles({ xs, md });

  /**
   * on item click
   */
  const onItemClick = React.useCallback((item: INavbarData) => {
    if (!!item.to) {
      navigate(item.to);
    }
    else if (!!item.onPress) {
      item.onPress();
    }
  }, [navigate]);

  return (
    <View style={{ backgroundColor: context.configuration.colors.primary.main, zIndex: 1 }}>
      <View style={{ maxWidth: 'none', paddingRight: styling.spacing, paddingLeft: styling.spacing }}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginRight: 30 }}>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Link to='/auth' >
              <Image
                source={{ uri: context.configuration.portal.secondarylogo }}
                style={{ resizeMode: 'contain', width: 120, height: 80, marginRight: 25 }}
              />
            </Link>

            <View style={styles.bars}>
              <NavbarDropdown color={context.configuration.colors.primary.text} icon={'burger'} position={{ top: '100%' }}>
                {data.map((item: INavbarData) => (
                  <Button key={item.label} onPress={() => onItemClick(item)} marginBottom={0} backgroundColor={'white'} color={'black'} label={item.label}></Button>
                ))}
              </NavbarDropdown>
            </View>

            <View style={styles.extended}>
              {data.map((item: INavbarData) => (
                <ButtonLink key={item.to} to={item.to!} label={item.label} color={context.configuration.colors.primary.text} />
              ))}
            </View>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', width: '10em', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
            {!!(context?.shoppingCart?.details.filter(d => !d.paid && !d.deleted).length > 0) &&
              <View style={{ position: 'relative' }}>
                <IconButton
                  fontSize={25}
                  name={'cart'}
                  onPress={() => onItemClick({ label: 'nose', to: '/auth/shopping-cart' })}
                />
                <Text
                  fontSize={10}
                  style={{ right: 0, position: 'absolute', backgroundColor: 'red', color: 'white', borderRadius: 100, height: 15, width: 15, textAlign: 'center', alignItems: 'center', }}
                >{context?.shoppingCart?.details.filter(d => !d.paid && !d.deleted).length}</Text>
              </View>
            }
            {!!(context.user?.admin) && (
              <NavbarDropdown color={context.configuration.colors.primary.text} fontSize={25} icon={'key'} position={{ top: '100%', right: -50 }}>
                {admin.map((item: INavbarData) => (
                  <Hoverable key={item.label}>
                    {({ hovered }) => <Button key={item.label} onPress={() => onItemClick(item)} marginBottom={0} backgroundColor={hovered ? '#eee' : 'white'} color={'black'} label={item.label} containerStyle={{ width: '100%' }} />}
                  </Hoverable>
                ))}
              </NavbarDropdown>
            )}

            {!!(context.user?.isOrganizer) && (
              <IconButton
                color={context.configuration.colors.primary.text}
                fontSize={30}
                name={'user-shield'}
                onPress={()=>{ navigate('/organizer/competitions')} }
              />
            )}

            {(!!settings.length || !!languages.length) &&
              <NavbarDropdown indexSeparator={[languages.length]} color={context.configuration.colors.primary.text} fontSize={25} icon={'gear'} position={{ top: '100%', right: -50 }}>
                {
                  languages.concat(settings).map((item: INavbarData) => (
                    <Hoverable key={item.label}>
                      {({ hovered }) => <Button onPress={() => onItemClick(item)} marginBottom={0} backgroundColor={hovered ? '#eee' : 'white'} color={'black'} label={item.label} containerStyle={{ width: '100%' }} />}
                    </Hoverable>
                  ))}
              </NavbarDropdown>
            }
          </View>
        </View>
      </View>
    </View>
  );
}
const md = StyleSheet.create({
  bars: {
    display: 'none'
  },
  extended: {
    display: 'flex',
    flexDirection: 'row'
  }
});

const xs = StyleSheet.create({
  bars: {
    display: 'flex'
  },
  extended: {
    display: 'none'
  }
});