import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


export interface IMatchDTO {
	id: number;
	homeTeamId: number;
	awayTeamId: number;
	groupId: number;
	uuid: string;
	externalUuid: string;
	created: Date;
	deleted: Date;
	adUser: string;
	adPassword: string;
	adCredentialId: string;
	kongConsumer: string;
	externalAdUser: Boolean;
	roundId: number;
}

const BASE_URL = BACKEND_URL + '/auth/matches'

/**
 * Recupera todos los partidos de un evento
 */
export const getByEventId = (eventId: number) => {
	return RestService.get(`${BASE_URL}/by-event-id/${eventId}`);
}

/**
 * Recupera todos los partidos de un evento
 */
export const getByEventUuid = (eventUuid: string) => {
	return RestService.get(`${BASE_URL}/by-event-uuid/${eventUuid}`);
}

/**
 * Recupera todos los partidos de un evento de un grupo
 */
export const getByEventAndGroupUuid = (eventUuid: string, groupUuid: string) => {
	return RestService.get(`${BASE_URL}/${eventUuid}?groupUuid=${groupUuid}`);
}

/**
 * Crea un partido
 */
export const createMatch = (payload: any) => {
	return RestService.post(`${BASE_URL}/new-match`, payload)
}

/**
 * Get by match call uuid
 */
export const getByMatchCallId = (matchCallId: number) => {
	return RestService.get(`${BACKEND_URL}/auth/match-call/${matchCallId}`);
}

/**
 * Update court and date
 */
export const updateCourtAndDate = (match: any) => {
	return RestService.post(`${BASE_URL}/update-court-and-date`, match);
}

export const initializeHoursAndCourts = (form: any) => {
	return RestService.post(`${BASE_URL}/initalize-hours-and-courts`, form);
}

/**
 * 
  Elimina todos los partidos del grupo
*/
export const deleteGroupMatches = (groupUuid: any) => {
	return RestService.delete(`${BASE_URL}/delete-group-matches/${groupUuid}`);
  }


