import { BACKEND_URL } from "../config";
import * as RestService from "./RestService";

const BASE_URL = BACKEND_URL + "/auth/standings";

/**
 * @returns Devuelve una clasificacion por uuid de grupo
 */
export const findByUuid = (group: string, round?: number) => {
  return RestService.get(`${BASE_URL}?groupUuid=${group}${round?`&round=${round}`:''}`);
};

/**
 * @returns Bloquea un jornada
 */
export const blockRound = (group: string, block: boolean, round?: number ) => {
  return RestService.put(`${BASE_URL}/block-round/${group}/${round}?block=${block}`);
};


/**
 * @returns Modifica una classificació
 */
export const modifyRound = (group: string, round: number, standing: any[] ) => {
  return RestService.put(`${BASE_URL}/modify-round/${group}/${round}`, standing);
};


/**
 * @returns Calcula la clasificación del grupo
 */
export const compute = (groupUuid:  string) => {
  return RestService.get(`${BASE_URL}/compute?groupUuid=${groupUuid}`);
};


