import { Alert, Button, Input, Loader, Modal, Separator, t, Table, Text, useComponentDidMount } from "owc-react-lib-common";
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from "src/components/Container";
import { Stepper } from 'src/components/Stepper';
import { styling } from "src/config";
import { useAlerts } from 'src/hooks/useAlerts';
import { useQueryParams } from "src/hooks/useQueryParams";
import * as Team3x3Service from 'src/services/Team3x3Service';
import * as EventService from 'src/services/EventService';
import { RegisterTeam3x3Payment } from '../../competitions/RegisterTeam3x3Payment';
import { RegisterTeam3x3Validation } from '../../competitions/RegisterTeam3x3Validation';
import { View } from "src/components/View";
import { RegisterTeam3x3Actors } from "../../competitions/RegisterTeam3x3Actors";
import dayjs from "dayjs";

enum STEPS {
  VALIDATION,
  TPV,
  SUCCESS,
};

export enum STATUS {
  CREATED = 0,
  COMPLETE = 4,
  INVITE = 5
}

export const Team3x3 = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { createDSMError } = useAlerts();
  const { uuid } = useParams<any>();
  const [teamEventCategory, setTeamEventCategory] = React.useState<any>();
  const [team, setTeam] = React.useState<any>();
  const [step, setStep] = React.useState<number>(STEPS.VALIDATION);
  const [selected, setSelected] = React.useState<number>(STEPS.VALIDATION);
  const { payment_error: paymentError } = useQueryParams();
  const [paymentErrorModal, setPaymentErrorModal] = React.useState<boolean>(paymentError === 'true');
  const [limitInscriptionDate, setLimitInscriptionDate] = React.useState<any>();
  const today = dayjs(new Date());
  const [actors, setActors] = React.useState<any[]>();

  /**
   * use component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await Team3x3Service.getTeamEventCategory(uuid);
      setTeamEventCategory(response.data);
      const r = await Team3x3Service.findById(response.data.team.id);
      setTeam(r.data)
      if (response.data.status === STATUS.COMPLETE || response.data.status === STATUS.INVITE) {
        setStep(STEPS.SUCCESS);
        setSelected(STEPS.SUCCESS);
      }
      const eventServiceResponse = await EventService.findByUuid(response.data.eventCategory.eventUuid);
      if(eventServiceResponse.data.changeRosterLimitDate){
        setLimitInscriptionDate(eventServiceResponse.data.changeRosterLimitDate);
      }else{
        setLimitInscriptionDate(eventServiceResponse.data.limitInscriptionDate);
      }
    } catch (error) {
      createDSMError(error);
    }

    setLoading(false);
  });

  const validate = React.useCallback(() => {
    setStep(STEPS.TPV);
    setSelected(STEPS.TPV);
  }, []);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  const headers = [
    { value: STEPS.VALIDATION, label: t('3x3.register.validationStep') },
    { value: STEPS.TPV, label: t('3x3.register.tpvStep') },
    { value: STEPS.SUCCESS, label: t('3x3.register.successStep') },
  ];

  return (
    <Container title={teamEventCategory?.team?.name}>
      <Stepper headers={headers} step={step} selected={selected} onPress={setSelected}>
        <RegisterTeam3x3Validation eventCategory={teamEventCategory.eventCategory} team={teamEventCategory.team} actors={teamEventCategory.actors} onSave={validate} />
        <RegisterTeam3x3Payment teamEventCategory={teamEventCategory} eventCategory={teamEventCategory.eventCategory} rate={{}} />
        <View>
          {actors ?
            <>
              <Alert type='success' text={t('3x3.register.finalRoaster')} />
              <Table
                searchable={false}
                csvButton={false}
                pagination={false}
                headers={[
                  t('3x3.teams.data.name'),
                  t('3x3.teams.data.lastName'),
                  { label: t('3x3.teams.data.gender'), width: 120 },
                  { label: t('3x3.teams.data.birthDate'), width: 120 },
                  { label: t('3x3.teams.data.number'), width: 100 },
                  { key: 'checkbox', width: 50 },
                ]}
                data={actors?.map((actor: any) => [
                  actor.peopleName,
                  actor.peopleSurname,
                  actor.gender,
                  actor.formattedPeopleBirthdate,
                  <Input key={`number-` + actors.some((current: any) => current.id === actor.id)} defaultValue={actor.number} containerStyle={{ maxWidth: '100%', marginBottom: 0 }} disabled numeric />,
                ]) || []}
              />
            </>
            :
            <>
              {today.isBefore(limitInscriptionDate) &&
                <RegisterTeam3x3Actors teamEventCategory={teamEventCategory} eventCategory={teamEventCategory.eventCategory} team={team} actors={teamEventCategory.actors} onSelectActors={(actors) => { setActors(actors) }} />
              }
            </>

          }


        </View>

      </Stepper>

      <Modal visible={paymentErrorModal} title={t('3x3.register.paymentErrorTitle')} close={() => setPaymentErrorModal(false)}>
        <Alert text={t('3x3.register.paymentErrorAlert')} />
        <Text style={{ marginBottom: styling.spacing }}>{t('3x3.register.paymentErrorDescription')}</Text>
        <Separator height={1} width='100%' backgroundColor="#eee" marginBottom={styling.spacing} />
        <Button text={t('global.accept')} onPress={() => setPaymentErrorModal(false)} marginBottom={0} />
      </Modal>
    </Container>
  );
}