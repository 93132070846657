import { Input, t } from "owc-react-lib-common";
import * as React from 'react';
import { Button } from "src/components/Button";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { Text } from 'src/components/Text';
import { styling } from 'src/config';
import { IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';

export interface IMatchResult {
	matchCall: any;
	match: any;
	teams: any[];
	onSave: (matchCall: any) => void;
	courts: any[];
}
export const MatchResult = ({ match, matchCall, onSave }: IMatchResult) => {
	const [errors, setErrors] = React.useState<any>({})
	const form = React.useRef<any>({});
	const { validateAll } = useValidation();

	const formRequiredFields: IValidationParams[] = [
		{ name: "homeScore", type: REQUIRED },
		{ name: "awayScore", type: REQUIRED },
	];

	const generatePayload = () => {
		const { errors, hasErrors } = validateAll(form.current, formRequiredFields);
		setErrors(errors);

		if (hasErrors) {
			return;
		}

		const payload = {
			matchCallId: matchCall?.matchCallId,
			id: match?.matchResultId ?? matchCall?.matchResultId,
			homeScore: parseInt(form.current.homeScore),
			awayScore: parseInt(form.current.awayScore),
		}

		onSave(payload);
	}

	return (
		<Form>
			<Row containerStyle={{ flexDirection: 'row', alignItems: 'center', marginBottom: styling.spacing }}>
				<Text textAlign='center'>{matchCall?.homeTeamName || match?.homeTeam?.name}</Text>

				<Input
					onChange={(val) => (form.current.homeScore = val)}
					numeric
					// label={`${t('admin.events.knockoff.home')}`}
					error={errors.homeScore}
					containerStyle={{ marginBottom: 0 }}
				/>

				<Input
					onChange={(val) => (form.current.awayScore = val)}
					numeric
					// label={`${t('admin.events.knockoff.away')}`}
					error={errors.awayScore}
					containerStyle={{ marginBottom: 0 }}
				/>

				<Text textAlign='center'>{matchCall?.awayTeamName || match?.awayTeam?.name}</Text>
			</Row>

			<Row>
				<Button onPress={generatePayload} label={t('global.save')} marginBottom={0} />
			</Row>
		</Form>
	)
}
