import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { Image, StyleSheet } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'src/components/Alert';
import { Loader } from 'src/components/Loader';
import { Stepper } from 'src/components/Stepper';
import { Title } from 'src/components/Title';
import { View } from 'src/components/View';
import { styling } from 'src/config';
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useQueryParams } from 'src/hooks/useQueryParams';
import * as ActorService from 'src/services/ActorService';
import * as EventService from 'src/services/EventService';
import * as RoleService from 'src/services/RoleService';
import * as TeamService from 'src/services/TeamService';
import * as LicenceUtils from 'src/utils/LicenceUtils';
import { TeamCreationActors } from './TeamCreationActors';
import { TeamCreationCategory } from './TeamCreationCategory';
import { TeamCreationData } from './TeamCreationData';
import { TeamCreationSummary } from './TeamCreationSummary';
import dayjs from 'dayjs';

interface ITeamCreation {
  initialStep?: number;
  initialData?: any;
  update?: boolean;
}

const STEP_CATEGORY = 0;
const STEP_DATA = 1;
const STEP_ACTORS = 2;
const STEP_SUMMARY = 3;

export const TeamCreation = ({ initialStep = STEP_CATEGORY, initialData, update = false }: ITeamCreation) => {

  const navigate = useNavigate();
  const alerts = useAlerts();
  const { eventCategoryId } = useQueryParams();
  const { eventUuid } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [event, setEvent] = React.useState<any>();
  const [category, setCategory] = React.useState<any>();
  const [roles, setRoles] = React.useState<any[]>([]);
  const [sizes, setSizes] = React.useState<any[]>([]);
  const [step, setStep] = React.useState<number>(initialStep);
  const [selected, setSelected] = React.useState<number>(initialStep);
  const [data, setData] = React.useState<any>(initialData || { teamDTO: { eventUuid, eventCategoryId }, actors: [] });

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    const uuid = data?.teamDTO?.eventUuid;

    // validate
    if (!uuid) {
      navigate('/auth/competitions');
      return;
    }

    // fetch data
    try {
      const response = await Promise.all([
        EventService.findByUuid(uuid),
        EventService.findShirtSizes(uuid),
        RoleService.findAll(),
      ]);
      setEvent(response[0].data);
      setSizes(response[1].data);
      setRoles(response[2].data);

      const category = response[0].data.eventCategories.find((category: any) => parseInt(category.id) === parseInt(data?.teamDTO?.eventCategoryId));

      const today = dayjs(new Date());
      const start = dayjs(response[0].data.startInscriptionDate);
      const end = dayjs(response[0].data.limitInscriptionDate);

      const startVerification = response[0].data.startInscriptionDate ? (today.isAfter(start, 'day') || start.isSame(today, 'day')) : true;
      const endVerification = response[0].data.limitInscriptionDate ? (today.isBefore(end, 'day') || end.isSame(today, 'day')) : true;

      if (!(startVerification && endVerification) && !update) {
        navigate('/');
      }


      setCategory(category);


      if (!!eventCategoryId && !!category) {
        setData({
          teamDTO: { ...data.teamDTO, eventCategoryId: category.id, eventCategoryName: category.name },
          actors: data.actors,
        });

        setSelected(STEP_DATA);
        setStep(STEP_DATA);
      }
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * back
   */
  const back = React.useCallback(() => {
    setSelected(Math.max(selected - 1, 0));
  }, [selected]);

  /**
   * on select step
   */
  const onSelectStep = React.useCallback((index: number) => {
    if (index <= step) {
      setSelected(index);
    }
  }, [step]);

  /**
   * on select category
   */
  const onSelectCategory = React.useCallback((object: any) => {
    // set category
    setCategory(object);

    // set data
    setData({
      ...data,
      teamDTO: { ...data.teamDTO, eventCategoryId: object.id, eventCategoryName: object.name },
    });

    // steps
    setSelected(STEP_DATA);

    // refresh categories
    setEvent({
      ...event,
      eventCategories: [...event.eventCategories],
    })

    if (step < STEP_DATA) {
      setStep(STEP_DATA);
    }
  }, [event, data, step]);

  /**
   * on save team data
   */
  const onSaveTeamData = React.useCallback(async (object: any) => {
    try {
      let saved;

      // update
      if (update) {
        const response = await TeamService.update(object);
        saved = { ...object, ...response.data };
        alerts.create({ text: t('global.success'), type: 'success' });
      }
      // create
      else {
        saved = { ...object };
      }

      // set data
      setData({
        ...data,
        teamDTO: { ...data.teamDTO, ...saved },
      });

      // steps
      if (!update) {
        setSelected(STEP_ACTORS);
      }

      if (step < STEP_ACTORS) {
        setStep(STEP_ACTORS);
      }
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [update, data, step, alerts]);

  /**
   * on save actors
   */
  const onSaveActors = React.useCallback(() => {
    // steps
    setSelected(STEP_SUMMARY);

    if (step < STEP_SUMMARY) {
      setStep(STEP_SUMMARY);
    }
  }, [step]);

  /**
   * on save actor
   */
  const onSaveActor = React.useCallback(async (object: any) => {
    // prepare saved object
    let saved = {
      ...object,
      _id: object._id || Date.now(),
      peopleBirthdate: object.peopleBirthdate,
      formattedBirthDate: object.formattedBirthDate,
      teamId: data.teamDTO.id,
      number: object.number === '00' ? '100' : object.number,
    };

    let actors;
    // new actor
    if (!object?._id) {
      if (update) {
        const response = await ActorService.addToTeam(saved, true);
        saved = { ...saved, ...response.data };
        alerts.create({ text: t('global.success'), type: 'success' });
      }

      actors = [...data.actors, saved];
    }
    // updated actor
    else {
      if (update) {
        const response = await ActorService.update(saved);
        saved = { ...saved, ...response.data };
        alerts.create({ text: t('global.success'), type: 'success' });
      }

      actors = data.actors.map((object: any) => {
        if (object._id === saved._id) {
          return saved;
        }

        return object;
      });
    }
    setData({
      ...data,
      actors: actors.sort(LicenceUtils.sort),
    });
  }, [update, data, alerts]);

  /**
   * on delete actor
   */
  const onDeleteActor = React.useCallback(async (object: any) => {
    if (update) {
      await ActorService.deleteActor(object.uuid);
    }

    setData({
      ...data,
      actors: data.actors.filter((actor: any) => actor._id !== object._id),
    });

    alerts.create({ text: t('global.success'), type: 'success' });
  }, [data, update, alerts]);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    const response = await TeamService.registerTeam(data);
    navigate(`/auth/teams/${response.data.uuid}`);
  }, [data, navigate]);

  /**
   * register and process
   */
  const saveAndProcess = React.useCallback(async () => {
    const response = await TeamService.registerTeam(data, true);
    navigate(`/auth/teams/${response.data.uuid}`);
  }, [data, navigate]);

  /**
   * process
   */
  const process = React.useCallback(async () => {
    const response = await TeamService.process(data.teamDTO.uuid);
    navigate(`/auth/teams/${response.data.uuid}`);
  }, [data, navigate]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  const headers = [
    { label: data.teamDTO?.eventCategoryName || t('auth.competitions.teamCreation.category.title'), value: data.teamDTO?.eventCategoryName },
    { label: data.teamDTO?.name || t('auth.competitions.teamCreation.team.title'), value: data.teamDTO?.name },
    { label: t('auth.competitions.teamCreation.actors.title') },
    { label: t('auth.competitions.teamCreation.summary.title') },
  ];

  if (!data?.teamDTO.id && !!category && !category.inscriptionOpen) {
    const eventCategory = event.eventCategories.find(ec => parseInt(ec.id) === parseInt(eventCategoryId));
    return (
      <View style={styles.container}>
        <Title text={event.name} right={event.competitionName} />
        {
          eventCategory.waitingRoomText
            ? <Alert type='info' text={eventCategory.waitingRoomText} containerStyle={{ marginBottom: 0 }} />
            : <Alert text={t('auth.competitions.teamCreation.category.enrollmentClosedAlert')} containerStyle={{ marginBottom: 0 }} />
        }
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Title text={event.name} right={event.competitionName} />

      <Stepper headers={headers} step={step} selected={selected} onPress={onSelectStep} containerStyle={styles.stepper}>
        <TeamCreationCategory
          categories={event.eventCategories}
          onSuccess={onSelectCategory}
          editable={step === STEP_CATEGORY}
        />

        <TeamCreationData
          initialData={initialData?.teamDTO}
          levels={category?.teamLevels}
          category={category}
          // questions={category?.extraInformationQuestions}
          // answers={data?.teamDTO?.extraInformation}
          back={back}
          onSuccess={onSaveTeamData}
          update={update}
        />

        <TeamCreationActors
          data={data?.actors}
          category={category}
          sizes={sizes}
          roles={roles}
          back={back}
          onSuccess={onSaveActors}
          update={update}
          onSaveActor={onSaveActor}
          onDeleteActor={onDeleteActor}
        />

        <TeamCreationSummary
          data={data}
          category={category}
          back={back}
          save={save}
          setSelectedStep={setSelected}
          process={!update ? saveAndProcess : process}
          update={update}
        // questions={category?.extraInformationQuestions}
        // answers={data?.teamDTO?.extraInformation}
        />
      </Stepper>

      <View style={styles.imageContainer}>
        <Image source={{ uri: event.competitionLogoUrl }} style={styles.image} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
  },
  imageContainer: {
    minHeight: 70,
    marginVertical: styling.spacing,
    backgroundColor: '#fff',
  },
  image: {
    width: '100%',
    height: 'auto',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
  },
  stepper: {
    width: '100%',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    marginBottom: 25,
    // marginTop: 25,
  },
});