import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface ITeamDTO {
  id?: number;
  uuid?: string;
  name?: string;
  externalUuid?: string;
  baseUserId?: number;
  baseUserEmail?: string;
  eventUuid?: number,
  eventCategoryId?: number;
  eventCategoryName?: string;
  teamImageUrl?: string;
  teamLogoUrl?: string;
  originalLogoFilename?: string;
  originalImgFilename?: string;
  teamImageFile?: string;
  teamLogoFile?: string;
  rateId?: number;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  teamLevelId?: number;
  teamLevelName?: string;
  hotelId?: number;
  hotelName?: string;
  deleted?: any;
  created?: any;
}

export interface ITeamRoomDTO {
  id?: number;
  teamId?: number;
  roomTypeId?: number;
  roomTypeDescription?: string;
  numRooms?: number;
}

const BASE_URL = BACKEND_URL + '/auth/teams'

/**
 * 
 Devuelve todos los equipos de un evento o de una categoria si se indica
 */
export const findTeamsByEvent = (eventUuid: string, eventCategoryId?: number, withHotelInfo?: boolean, withoutGroup?: boolean) => {
  // const queryEventCategory = (!!eventCategoryId ? `&eventCategoryId=${eventCategoryId}&` : '')
  // const queryHotelInfo = (!!withHotelInfo ? `withHotelInfo=${withHotelInfo}` : 'withHotelInfo=false')

  return RestService.get(`${BASE_URL}/by-event/${eventUuid}`, {
    eventCategoryId,
    withHotelInfo,
    withoutGroup
  });
}

export const findFicticiousTeamsByEvent = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/by-event/${eventUuid}/ficticious`);
}

export const findAllByModality = (modalityId: number) => {
  return RestService.get(`${BASE_URL}/all/${modalityId}`);
}

/**
 * 
 Devuelve todos los equipos de un evento o de una categoria si se indica
 */
export const findbyGroupUuid = (groupUuid: string) => {
  return RestService.get(`${BASE_URL}/by-group/${groupUuid}`);
}

/**
 * 
 Devuelve todos los equipos de un evento o de una categoria si se indica
 */
export const findbyGroupId = (groupId: number) => {
  return RestService.get(`${BASE_URL}/by-group-id/${groupId}`);
}

/**
 * Crea un equipo ficticio
 */
export const createFicticiousTeam = (payload: any) => {
  return RestService.post(`${BASE_URL}/new-ficticious-team`, payload);
}

/**
 * Sustituye los equipos ficticious de un grupo
 */
export const changeFicticious = (groupId: any) => {
  return RestService.put(`${BASE_URL}/change-ficticious/${groupId}`);
}
/**
 * 
  Recupera la informacion de un equipo
 * @returns 
 */
export const findByUuid = (teamUuid: string, eventCategoryId?: number) => {
  const queryParam = eventCategoryId === undefined ? '': '?eventCategoryId=' + eventCategoryId;

  return RestService.get(`${BASE_URL}/${teamUuid}${queryParam}`);
}

/**
 * 
 Crea un equipo
 */
export const registerTeam = (team: ITeamDTO, process = false) => {
  return RestService.post(`${BASE_URL}/register-team?processTeam=${process}`, team);
}

/**
 * Procesa un equipo
 */
export const process = (uuid: string) => {
  return RestService.post(`${BASE_URL}/${uuid}/process-team`);
}

/**
 * 
 Modifica un equipo
 */
export const update = (team: ITeamDTO) => {
  return RestService.put(`${BASE_URL}/`, team);
}

/**
 * 
 Elimina un equipo
 */
// export const deleteTeam = (teamUuid: string, eventCategoryId: number) => {
//   return RestService.delete(`${BASE_URL}/${teamUuid}?eventCategoryId=${eventCategoryId}`);
// }
export const deleteTeam = (teamUuid: string, eventCategoryId: number) => {
  return RestService.delete(`${BASE_URL}/${teamUuid}?eventCategoryId=${eventCategoryId}`);
}


/**
 * 
 Habitaciones para el equipo
 */
export const getRooms = (teamId: string) => {
  return RestService.get(`${BASE_URL}/get-rooms?teamId=${teamId}`);
}

/**
 * 
 Añade habitaciones a un equipo
 */
export const addRooms = (teamRoom: ITeamRoomDTO) => {
  return RestService.post(`${BASE_URL}/add-rooms`, teamRoom);
}

/**
 * 
 Modifica habitaciones a un equipo
 */
export const updateRooms = (teamRoom: ITeamRoomDTO) => {
  return RestService.put(`${BASE_URL}/change-rooms`, teamRoom);
}

/**
 * 
 Modifica habitaciones a un equipo
 */
export const removeRooms = (teamRoomId: number) => {
  return RestService.delete(`${BASE_URL}/unassign-rooms/${teamRoomId}`);
}

/**
   * 
   Notifica a los participantes que el proceso de inscripción esta listo para que lo completed
   */
export const notifyInscriptionReady = (teamUuid: string) => {
  return RestService.get(`${BASE_URL}/${teamUuid}/notify-actors-inscription-ready`);
}

/**
 * 
 Notifica a los participantes que el proceso de inscripción esta listo para que lo completed
 */
export const notifyActorInscriptionReady = (teamUuid: string, teamActorUuid: string) => {
  return RestService.get(`${BASE_URL}/${teamUuid}/notify-actors/${teamActorUuid}/inscription-ready`);
}

/**
 * Devuelve mis equipos inscritos en un evento
 */
export const findMyTeamsByUuid = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/me/in-event/${eventUuid}`);
}

/**
 * Devuelve un fichero pdf con la lista de miembros de un equipo que se alojaran en un hotel
 */
export const getActorsCheckIn = (teamUuid: string, teamName: string) => {
  return RestService.requestPdf(`${BACKEND_URL}/auth/hotels/pdf/check-in-by-team/${teamUuid}`, '_blank', `${teamName}.pdf`);
}

/**
 * Envía el email de check in al hotel
 */
export const sendChekInMail = (teamUuid: string) => {
  return RestService.get(`${BACKEND_URL}/auth/hotels/mail/check-in-by-team/${teamUuid}`);
}

/**
 * Descarrega l'excel resum de l'equip
 */
export const downloadTeamSummaryExcel = (teamUuid: string, teamName: string) => {
  return RestService.requestFile(`${BACKEND_URL}/auth/teams/${teamUuid}/summary-excel`, 'excel', teamName + '.xlsx');
}

/**
 * 
 * @param uuid 
 * @returns 
 */
export const validate = (uuid: string) => {
  return RestService.post(`${BASE_URL}/${uuid}/validate-team`);
}

/**
 * 
 */
export const updateHotelObservations = (uuid: string, observations: string) => {
  return RestService.post(`${BASE_URL}/${uuid}/validate-team`, { observations });
}

export const findByCompetitionUuid = (uuid: string) => {
  return RestService.get(`${BASE_URL}/by-competition/${uuid}`);
}