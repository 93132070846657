import { Picker, t } from "owc-react-lib-common";
import * as React from "react";

import { Input } from "owc-react-lib-common";
import { StyleSheet } from "react-native";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { Form } from "src/components/Form";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Row } from "src/components/Row/Row";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { MODALITY_3x3, MODALITY_5x5, styling } from "src/config";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useUserPermission } from "src/hooks/useUserPermission";
import { CUSTOM, EMAIL, IValidationParams, PHONE, REQUIRED, useValidation } from "src/hooks/useValidation";
import { TeamCreationQuestion } from "src/pages/auth/components/TeamCreationQuestion";
import {
  TEAM_STATUS_COMPLETED,
  TEAM_STATUS_PENDING,
  TEAM_STATUS_PROCESSED,
  TEAM_STATUS_VALIDATED,
} from "src/pages/auth/components/config";
import * as Team3x3Service from "src/services/Team3x3Service";
import * as TeamService from "src/services/TeamService";

interface ITeamEditData {
  levels: any[];
  data?: any;
  category?: any;
  modality?: typeof MODALITY_5x5 | typeof MODALITY_3x3
}

export const TeamEditData = ({ data = {}, levels = [], category, modality }: ITeamEditData) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const alerts = useAlerts();
  const { required, validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [teamEventCategory] = React.useState<any>(data.modalityId === MODALITY_5x5 ? {} : data.teamEventCategories.find((tec: any) => tec.eventCategory.id === parseInt(category.id)));

  // form
  const form = React.useRef<any>(data);
  const setName = React.useCallback((value: string) => (form.current.name = value), []);
  const setContactName = React.useCallback((value: string) => (form.current.contactName = value), []);
  const setContactSurname = React.useCallback((value: string) => (form.current.contactSurname = value), []);
  const setContactPhone = React.useCallback((value: string) => (form.current.contactPhone = value), []);
  const setContactEmail = React.useCallback((value: string) => (form.current.contactEmail = value), []);
  const setObservations = React.useCallback((value: string) => (form.current.observations = value), []);
  const setLevel = React.useCallback((value: any, label: any) => {
    form.current.teamLevelId = value;
    form.current.teamLevelName = label;
  }, []);
  const setLogo = React.useCallback((object: IImageUploaderData) => {
    form.current.originalLogoFilename = object.name;
    form.current.teamLogoFile = object.base64;
  }, []);
  const setPhoto = React.useCallback((object: IImageUploaderData) => {
    form.current.originalImgFilename = object.name;
    form.current.teamImageFile = object.base64;
  }, []);
  const setRegisterStatus = (value: string) => {
    form.current.registerStatus = value !== undefined && value !== null && value !== "" ? parseInt(value) : undefined;
  };

  const isModality5x5 = data.modalityId === MODALITY_5x5;


  /**
   * validate form
   */
  const validate = React.useCallback(() => {

    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "contactName", type: REQUIRED },
      { name: "contactSurname", type: REQUIRED },
      { name: "contactPhone", type: [REQUIRED, PHONE] },
      { name: "contactEmail", type: [REQUIRED, EMAIL] },
      //{ name: 'originalLogoFilename', type: REQUIRED },
      //{ name: 'originalImgFilename', type: REQUIRED },
      { name: "registerStatus", type: REQUIRED },
    ];

    if(isModality5x5){
      fields.push({ name: "teamLevelId", type: REQUIRED })
    }

    category?.extraInformationQuestions?.forEach((question: any) => {
      fields.push({
        name: `question-${question.id}`,
        type: CUSTOM,
        validate: () => {
          const answer = form.current?.extraInformation?.find((answer: any) => answer.questionId === question.id);
          return required(answer?.value);
        },
      });
    });

    const { errors, hasErrors } = validateAll(form.current, fields);
    setErrors(errors);
    return !hasErrors;
  }, [category, required, validateAll, isModality5x5]);

  /**
   * on change answer
   */
  const onChangeAnswer = React.useCallback((question: any, value: string) => {
    if (!form.current.extraInformation) {
      form.current.extraInformation = [];
    }

    const index = form.current.extraInformation.findIndex((answer: any) => answer.questionId === question.id);

    if (index === undefined || index === -1) {
      form.current.extraInformation.push({ questionId: question.id, value });
    } else {
      form.current.extraInformation[index].value = value;
    }
  }, []);

  /**
   * update team data
   */
  const updateTeam = async () => {
    if (!validate()) {
      return;
    }
    setBtnLoading(true);
    try {
      if (data.modalityId === MODALITY_3x3) {
        await Team3x3Service.save(data, teamEventCategory.eventCategory.id)
      }
      else {
        await TeamService.update(data);
      }
      alerts.create({ text: t("admin.teams.teamEditDatat.updateTeam"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
    setBtnLoading(false);
  };



  /**
   *
   */
  const statusAlert = React.useCallback(() => {
    if (data.modalityId === MODALITY_3x3) {
      return null;
    }

    switch (parseInt(data.registerStatus)) {
      case TEAM_STATUS_PENDING:
        return (
          <Alert
            type="warning"
            text={`${t("admin.teams.status.title")}: ${t("admin.teams.status.pending")}`}
            containerStyle={{ marginBottom: 0, flex: 1, marginRight: 60 }}
          />
        );
      case TEAM_STATUS_PROCESSED:
        return (
          <Alert
            type="info"
            text={`${t("admin.teams.status.title")}: ${t("admin.teams.status.processed")}`}
            containerStyle={{ marginBottom: 0, flex: 1, marginRight: 60 }}
          />
        );
      case TEAM_STATUS_VALIDATED:
        return (
          <Alert
            type="success"
            text={`${t("admin.teams.status.title")}: ${t("admin.teams.status.validated")}`}
            containerStyle={{ marginBottom: 0, flex: 1, marginRight: 60 }}
          />
        );
      case TEAM_STATUS_COMPLETED:
        return (
          <Alert
            type="success"
            text={`${t("admin.teams.status.title")}: ${t("admin.teams.status.completed")}`}
            containerStyle={{ marginBottom: 0, flex: 1, marginRight: 60 }}
          />
        );
    }
  }, [data]);

  /**
   *
   */
  const downloadTeamSummaryExcel = React.useCallback(async () => {
    try {
      await TeamService.downloadTeamSummaryExcel(data.uuid, data.name);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, alerts]);

  

  /**
   * render
   */
  const status = [
    { label: t("admin.teams.status.pending"), value: TEAM_STATUS_PENDING },
    { label: t("admin.teams.status.processed"), value: TEAM_STATUS_PROCESSED },
    { label: t("admin.teams.status.validated"), value: TEAM_STATUS_VALIDATED },
    { label: t("admin.teams.status.completed"), value: TEAM_STATUS_COMPLETED },
  ];

  return (
    <View>
      {isModality5x5 && <View flexDirection="row" alignItems="center" marginBottom={styling.spacing}>
        {statusAlert()}

        <FloatingIconButton
          backgroundColor='transparent'
          color='black'
          name="stats"
          title="Excel"
          onPress={downloadTeamSummaryExcel}
          size="large"
          right={10}
          top={10}
        />
      </View>}

      <Form>
        <Row>
          <Text bold uppercase style={styles.text}>
            {t("admin.teams.teamEditDatat.infoevent")}
          </Text>
        </Row>

        <Row>
          <Input
            label={t("admin.teams.teamEditDatat.eventCategoryName")}
            defaultValue={isModality5x5 ? form.current.eventCategoryName : category.name}
            disabled
          />
          <Input
            label={t("admin.teams.teamEditDatat.eventName")}
            defaultValue={isModality5x5 ? form.current.eventName : category.eventName}
            disabled
          />
        </Row>

        <Row>
          <Text bold uppercase style={styles.text}>
            {t("admin.teams.teamEditDatat.infoteam")}
          </Text>
        </Row>

        <Row flex={[2, 1, 1]}>
          <Input
            label={t("admin.teams.teamEditDatat.name")}
            defaultValue={form.current.name}
            onChange={setName}
            error={errors.name}
            disabled={readOnly}
            required
          />

          <Picker
            label={t("admin.teams.teamEditDatat.status")}
            items={status}
            defaultValue={isModality5x5 ? form.current.registerStatus : teamEventCategory.status}
            onChange={setRegisterStatus}
            error={errors.registerStatus}
            disabled={readOnly}
            required
          />

          {isModality5x5 && <Picker
            defaultValue={form.current.teamLevelId}
            label={t("admin.teams.teamEditDatat.level")}
            items={levels.map((level: any) => ({ label: level.name, value: level.id }))}
            onChange={setLevel}
            error={errors.teamLevelId}
            disabled={readOnly}
            required
          />}
        </Row>
        <Row>
          <Text bold uppercase style={styles.text}>
            {t("admin.teams.teamEditDatat.contact")}
          </Text>
        </Row>

        {/* <View flexDirection="row" flex={3} marginRight={styling.spacing}> */}
        <Row>
          <Input
            label={t("admin.teams.teamEditDatat.contactName")}
            defaultValue={form.current.contactName}
            onChange={setContactName}
            error={errors.contactName}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.teams.teamEditDatat.contactSurname")}
            defaultValue={form.current.contactSurname}
            onChange={setContactSurname}
            error={errors.contactSurname}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.teams.teamEditDatat.contactEmail")}
            defaultValue={form.current.contactEmail}
            onChange={setContactEmail}
            disabled={readOnly}
            error={errors.contactEmail}
            required
          />
        </Row>
        <Row flex={[1, 2]}>
          <Input
            label={t("admin.teams.teamEditDatat.contactPhone")}
            defaultValue={form.current.contactPhone}
            onChange={setContactPhone}
            disabled={readOnly}
            error={errors.contactPhone}
            required
          />
          <Input
            label={t("admin.teams.teamEditDatat.observations") + ` (${t('global.optional')})`}
            defaultValue={form.current.observations}
            onChange={setObservations}
            error={errors.observations}
            disabled={readOnly}
          />

        </Row>
        <Row>
          {!!category?.extraInformationQuestions?.length && (
            <View>
              {category?.extraInformationQuestions?.map((question: any) => {
                const answer = form.current.extraInformation?.find((answer: any) => parseInt(answer.questionId) === parseInt(question.id));

                return (
                  <TeamCreationQuestion
                    key={question.id}
                    data={question}
                    value={answer?.value}
                    onChange={(value: string) => onChangeAnswer(question, value)}
                    required
                    error={errors[`question-${question.id}`]}
                    disabled={readOnly}
                  />
                );
              })}
            </View>
          )}
        </Row>
        <Row>
          <Text bold uppercase style={styles.text}>
            {t("admin.teams.teamEditDatat.image")}
          </Text>
        </Row>
        <Row>
          <View style={styles.imageUploaders}>
            <ImageUploader
              label={t("admin.teams.teamEditDatat.logo")}
              defaultValue={form.current.teamLogoUrl}
              onChange={setLogo}
              placeholder="https://dieselpunkcore.com/wp-content/uploads/2014/06/logo-placeholder.png"
              error={errors.originalLogoFilename}
              disabled={readOnly}
              containerStyle={styles.imageUploader}
            />
            <ImageUploader
              label={t("admin.teams.teamEditDatat.photo")}
              defaultValue={form.current.teamImageUrl}
              onChange={setPhoto}
              placeholder="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYA14VTh0gOZ8Do8KWTkb3VIfuS-3RzX5LgKsGXwSM5EwnPlI_FS82s9BE2XmUP_q-VEk&usqp=CAU"
              error={errors.originalImgFilename}
              disabled={readOnly}
            />
          </View>
        </Row>
        <Row>
          <Button disabled={readOnly || btnLoading} type="primary" label={t("global.save")} onPress={updateTeam} marginBottom={0} />
        </Row>
      </Form>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    marginBottom: styling.spacing,
  },
  imageUploaders: {
    flexDirection: "row"
  },
  imageUploader: {
    marginRight: 15
  }
});
