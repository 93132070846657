import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

const BASE_URL = BACKEND_URL + '/auth/towns'

export const findByTownId = (townId: number) => {
  return RestService.get(`${BASE_URL}/${townId}`);
}

export const findByProvinceId = (provinceId: number) => {
  return RestService.get(`${BASE_URL}/${provinceId}/all-towns`);
}