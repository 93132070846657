import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


const PUBLIC_BASE_URL = BACKEND_URL + '/public/users'
const AUTH_BASE_URL = BACKEND_URL + '/auth/users'

export interface IBaseUserDTO {
  id?: number;
  uuid?: string;
  email?: string;
  password?: string;
  defaultLang?: string;
  registerStatus?: string;
  validationCode?: string;
  pwRecoveryCode?: string;
  organizerId?: number;
  organizerName?: string;
  oldPassword?: string;
}


export const sendPass = (email: string) => {
  return RestService.get(`${PUBLIC_BASE_URL}/${email}/init-password-recovery`);
}


export const recoveryPassword = (verificationCode: string, password: string) => {
  return RestService.put(`${PUBLIC_BASE_URL}/do-password-recovery/${verificationCode}`, { password })
}


export const login = (email: string, password: string) => {
  return RestService.post(`${PUBLIC_BASE_URL}/login`, { email, password });
};

export const register = (email: string, password: string, defaultLang?: string) => {
  return RestService.post(`${PUBLIC_BASE_URL}/signup`, { email, password, defaultLang });
};


export const confirmRegister = async (uuid: string) => {
  return RestService.put(`${PUBLIC_BASE_URL}/validate/` + uuid, {});
}


export const resendAccountValidationEmail = (email: string) => {
  return RestService.put(`${PUBLIC_BASE_URL}/${email}/resend-validation`, {});
}


export const changePassword = (oldPassword: string, password: string) => {
  return RestService.put(`${AUTH_BASE_URL}/change-password`, { oldPassword, password });
}


export const changeLanguage = (defaultLang: string) => {
  return RestService.put(`${AUTH_BASE_URL}/change-language`, { defaultLang });
}

export const refresh = () => {
  return RestService.get(`${AUTH_BASE_URL}/refresh-token`);
}

export const getAll = () => {
  return RestService.get(`${AUTH_BASE_URL}`);
}

export const updateUser = (user: any) => {
  return RestService.put(`${AUTH_BASE_URL}`, user);
}

export const getIdentityCode = () => {
  return RestService.get(`${AUTH_BASE_URL}/bind`)
}