import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IGroupDTO {
	 id: number;
	 uuid: String;
	 name: String;
	 externalUuid: String;
	 eventCategoryId: number;
	 eventCategoryName: String;
	 maxTeams: number;
	 deleted: Date;
}

const BASE_URL = BACKEND_URL + '/auth/groups'

/**
 * 
 Devuelve todos los equipos de un evento o de una categoria si se indica
 */
export const getByCategoryId = (categoryId: number) => {
  return RestService.get(`${BASE_URL}/event-category/${categoryId}`);
}

/**
 * 
  Crea un grupo  
*/
 export const createGroup = (form: any) => {
  return RestService.post(`${BASE_URL}`, form);
}

/**
 * 
  Actualiza un grupo  
*/
export const updateGroup = (form: any) => {
  return RestService.put(`${BASE_URL}`, form);
}

/**
 * 
  Elimina un grupo  
*/
export const deleteGroup = (groupUuid: any) => {
	return RestService.delete(`${BASE_URL}/${groupUuid}`);
  }

/**
 * 
  Recupera la informacion de un equipo
 * @returns 
 */
export const findByUuid = (groupUuid: string) => {
  return RestService.get(`${BASE_URL}/${groupUuid}`);
}


 /**
 * 
  Elimina un equipo de un grupo
  */
  export const deleteTeamFromGroup = (groupId: number, teamId: number) => {
    return RestService.delete(`${BASE_URL}/delete-team/${groupId}?teamId=${teamId}`);
    }


