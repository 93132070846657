import { Radio, RadioGroup as OWCRadioGroup } from "owc-react-lib-common";
import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { StyleSheet, ViewProps } from 'react-native';
import { styling } from 'src/config';
import { Text } from "./Text";
import { View } from "./View";

interface IRadioGroup {
  items: IRadio[];
  label?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  containerStyle?: ViewProps['style'];
  required?: boolean;
  error?: string;
  disabled?: boolean;
  value?: string;
}

export interface IRadio {
  label: string;
  value: string;
}

export const RadioGroup = ({ items, label, defaultValue, value, onChange, containerStyle, required, error, disabled = false }: IRadioGroup) => {
  const [selected, setSelected] = React.useState<string>(defaultValue);

  /**
   * handle change
   */
  const handleChange = React.useCallback((event: any) => {
    if (disabled) {
      return;
    }

    onChange?.(event);
    setSelected(event);
  }, [onChange, disabled]);

  /**
   * render
   */
  const finalLabel = !label ? '' : `${label} ${required !== undefined ? (required ? '*' : `(${t('global.optional').toLowerCase()})`) : ''}`;

  return (
    <View style={[styles.container, containerStyle]}>
      {!!finalLabel && <Text color={!!error ? 'crimson' : undefined} fontSize={14} style={{ marginBottom: 10 }}>{finalLabel}</Text>}

      {items.map((item) => (
        <Radio label={item.label} value={selected === item.value} onChange={() => handleChange(item.value)} disabled={disabled} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: styling.spacing,
  }
}); 