import { useState } from "react";
import { SketchPicker as ReactColor } from 'react-color';
import { ColorValue, StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { OutsideAlerter } from 'src/hooks/useOutsideAlerter';

interface IColorPicker {
  label?: string;
  onChange?: (value: ColorValue) => void;
  defaultValue: ColorValue;
  disabled?: boolean;
}

export const ColorPicker = ({ label, onChange, defaultValue, disabled = false }: IColorPicker) => {
  const [open, setOpen] = useState<boolean>(false);
  const [color, setColor] = useState<ColorValue>(defaultValue || '#ffffff');

  const handleChange = (object: any) => {
    setColor(object.hex);
    onChange?.(object.hex);
  }

  return (
    <View style={styles.container}>
      {!!label && <Text>{label}</Text>}

      <TouchableOpacity onPress={() => !disabled && setOpen(!open)} style={styles.input}>
        <View style={{ flex: 1, backgroundColor: color }} />
      </TouchableOpacity>

      <View>
        {open && (
          <View style={styles.picker}>
            <OutsideAlerter handleClick={() => setOpen(false)}>
              <ReactColor color={color} onChange={handleChange} />
            </OutsideAlerter>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  input: {
    borderWidth: 1,
    flex: 1,
    marginBottom: 15,
    height: 40,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 5,
    boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px inset',
  },
  picker: {
    position: 'absolute',
    // bottom: 0,
  },
});