import { useState } from 'react';
import { t } from "owc-react-lib-common";
import { View } from "src/components/View";
import { ITableHeader, Table } from "src/components/Table";
import { IconButton } from 'src/components/IconButton';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Form } from 'src/components/Form';
import { Row } from 'src/components/Row/Row';
import { Input } from 'owc-react-lib-common';
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as QuestionService from "src/services/QuestionService"
import { Loader } from 'src/components/Loader';
import { useUserPermission } from 'src/hooks/useUserPermission';
import { ORGANIZER_READ_ONLY } from 'src/context/OWCOpenDmrContext';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { useAlerts } from 'src/hooks/useAlerts';
import { RadioGroup } from 'src/components/RadioGroup';
import { IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';

interface IEventCategoryQuestions {
  data: any;
}

export const EventCategoryQuestions = ({ data }: IEventCategoryQuestions) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>({});
  const { create, createDSMError } = useAlerts();
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [questionToEdit, setQuestionToEdit] = useState<QuestionService.IQuestionDTO>()
  const [questionToDelete, setQuestionToDelete] = useState<QuestionService.IQuestionDTO>()
  const [questions, setQuestions] = useState<QuestionService.IQuestionDTO[]>()
  const { validateAll } = useValidation();


  const validate = () => {
    const formRequiredFields: IValidationParams[] = [
      { name: "text", type: REQUIRED },
      { name: "type", type: REQUIRED },
    ];

    const validated = validateAll(questionToEdit, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }

  useComponentDidMount(async () => {
    const response = await QuestionService.getQuestions(data.id);
    setQuestions(response.data)
    setLoading(false)
  })

  const createQuestion = async () => {
    if (!validate()) {
      return
    }

    const payload = { ...questionToEdit, eventCategoryId: data.id }
    try {
      const response = await QuestionService.createQuestion(payload)
      if (questionToEdit?.id === undefined) {
        setQuestions([...questions, response.data])
        create({ type: "success", text: t('admin.events.question.createSuccess') })
      } else {
        setQuestions(questions.map(question => question.id === questionToEdit.id ? questionToEdit : question))
        create({ type: "success", text: t('admin.events.question.modifySuccess') })
      }
    } catch (err) {
      createDSMError(err)
    } finally {
      setQuestionToEdit(null)
    }
  }

  const deleteQuestion = async () => {
    try {
      await QuestionService.deleteQuestion(questionToDelete.id)
      create({ type: "success", text: t('admin.events.question.deleteSuccess') })
      setQuestions(questions.filter(question => question.id !== questionToDelete.id))
    } catch (err) {
      createDSMError(err)
    } finally {
      setQuestionToDelete(null)
    }
  }

  const actions = (question: QuestionService.IQuestionDTO) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="edit"
          onPress={() => setQuestionToEdit(question)}
        />
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="trash"
          onPress={() => setQuestionToDelete(question)}
        />
      </View>
    )
  }

  const headers: ITableHeader[] = [
    {
      name: "text",
      label: t("admin.events.question.text"),
    },
    {
      name: "type",
      label: t("admin.events.question.values"),
      width: 200
    },
    { name: "actions", label: " ", render: actions, width: 100, hidden: readOnly },
  ]

  const questionTypes = {
    boolean: { 
      value: 'boolean',
      label: t(`admin.events.question.types.boolean`),
    },
    // sample: 'sample'
  }

  // const questionTypeItems = Object.keys(questionTypes).map((key) => ({
  //   label: t(`admin.events.question.types.${key}`),
  //   value: key,
  // }))

  if (loading) {
    return <Loader />
  }

  return (
    <View>
      <Button onPress={() => setQuestionToEdit({})} label={t('admin.events.question.create')} disabled={readOnly} />
      <Table data={questions} headers={headers} />
      <Modal title={!questionToEdit?.id ? t('admin.events.question.create') : t('admin.events.question.update')} visible={!!questionToEdit} onClose={() => setQuestionToEdit(null)}>
        <Form>
          <Row>
            <Input error={errors.text} onChange={(value) => setQuestionToEdit({ ...questionToEdit, text: value })} label={t('admin.events.question.text')} value={questionToEdit?.text} required />
          </Row>
          <Row>
            <RadioGroup
              error={errors.type}
              label={t('admin.events.question.type')}
              items={Object.values(questionTypes)}
              value={questionTypes[questionToEdit?.type]?.value}
              onChange={(value) => setQuestionToEdit({ ...questionToEdit, type: questionTypes[value]?.value })}
              required
            />
          </Row>
          <Row>
            {/* {questionTypes[questionToEdit?.type] === 'boolean' &&
              <Checkbox value={questionToEdit.values === "true"} label={t(`admin.events.question.types.form.boolean`)} onChange={(value) => setQuestionToEdit({ ...questionToEdit, values: value ? "true" : "false" })} />
            } */}
          </Row>
        </Form>
        {
          !questionToEdit?.id
            ? <Button label={t("global.create")} onPress={createQuestion} />
            : <Button label={t("global.update")} onPress={createQuestion} />
        }
      </Modal>
      <ModalConfirm visible={!!questionToDelete} onSuccess={deleteQuestion} onClose={() => { setQuestionToDelete(null) }} text={t('admin.events.question.delete')} />
    </View>
  )

}
