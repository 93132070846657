import { BACKEND_URL } from "src/config";
import * as RestService from "src/services/RestService";

export interface ICourtDTO {
  id?: number;
  uuid?: string;
  name?: string;
  facilityName?: string;
  camera?: string;
  externalUuid?: string;
  facilityId?: number;
  description?: string;
  courtLevel?: string;
  dropUser?: string;
  dropDate?: any;
  deleted?: any;
  isCovered?: string;
}

const BASE_URL = BACKEND_URL + "/auth/courts";

export const getCourt = (courtId: string) => {
  return RestService.get(`${BASE_URL}/${courtId}`);
};

export const createCourt = (courtDTO: ICourtDTO) => {
  return RestService.post(`${BASE_URL}`, courtDTO);
};

export const updateCourt = (courtDTO: ICourtDTO) => {
  return RestService.put(`${BASE_URL}`, courtDTO);
};

export const deleteCourt = (courtId: string) => {
  return RestService.delete(`${BASE_URL}/${courtId}`);
};

export const getCourtsByFacilityUuid = (facilityUuidd: string) => {
  return RestService.get(`${BASE_URL}/courts-by-facility-uuid/${facilityUuidd}`);
};

export const getCourtsByEventUuid = (eventUuidd: string) => {
  return RestService.get(`${BASE_URL}/courts-by-event-uuid/${eventUuidd}`);
};

export const getCourtsByOrganizerId = () => {
  return RestService.get(`${BASE_URL}/courts-by-organizer/`);
};
