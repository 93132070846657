import * as React from 'react';

export const useComponentDidMount = (onMount: any, onDismount?: any) => {
  React.useEffect(() => {
    const func = async () => {
      await Promise.resolve(onMount());
    }

    func();
    return onDismount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}