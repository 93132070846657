import { t } from 'owc-react-lib-common';
import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import * as ColorUtils from 'src/utils/ColorUtils';
import { Icon } from "../Icon";
import { Text } from "../Text";
import { View } from "../View";

export const Accordion = ({ data, renderItem }) => {
    const [activeIndex, setActiveIndex] = React.useState([]);
    const [activeSubIndex, setActiveSubIndex] = React.useState([]);
    const [activeLastIndex, setActiveLastIndex] = React.useState([]);

    const changeIndex = (index) => {
        const newActiveIndex = [...activeIndex];
        const hasElement = newActiveIndex.filter(i => i === index).length > 0;

        if (!hasElement) {
            newActiveIndex.push(index);
            setActiveIndex(newActiveIndex);
        } else {
            const deletedNewIndex = newActiveIndex.filter(i => i !== index);
            const deletedNewSubIndex = activeSubIndex.filter(i => !i.startsWith((index - 1).toString()));

            setActiveIndex([...deletedNewIndex]);
            setActiveSubIndex([...deletedNewSubIndex])

        }
    }
    const changeSubIndex = (index) => {
        const newActiveSubIndex = [...activeSubIndex];
        const hasElement = newActiveSubIndex.filter(i => i === index).length > 0;
        if (!hasElement) {
            newActiveSubIndex.push(index);
            setActiveSubIndex(newActiveSubIndex);
        } else {
            const deletedNewIndex = newActiveSubIndex.filter(i => i !== index);
            setActiveSubIndex([...deletedNewIndex]);
        }
    }
    const changeLastIndex = (index) => {
        const newActiveLastIndex = [...activeLastIndex];
        const hasElement = newActiveLastIndex.filter(i => i === index).length > 0;
        if (!hasElement) {
            newActiveLastIndex.push(index);
            setActiveLastIndex(newActiveLastIndex);
        } else {
            const deletedNewIndex = newActiveLastIndex.filter(i => i !== index);
            setActiveLastIndex([...deletedNewIndex]);
        }
    }

    const renderLastIndex = (value, id) => {

        return (
            <View style={{ padding: 5 }}>
                {
                    Object.entries(value).map(([key, value], i) => {
                        const phaseStr = `${t('admin.events.calendar.phase')} ${value[0]?.numPlayOff}`
                        const roundStr = `${t('admin.events.calendar.round')} ${value[0]?.roundNumber}`
                        return (
                            <View key={i}>
                                <TouchableOpacity style={[styles.rowcenter, { backgroundColor: '#eeeeee', padding: 5 }]} onPress={() => changeLastIndex(`${id}.${key}`)}>
                                    <View>
                                        {/* @ts-ignore */}
                                        {
                                            value[0]?.numPlayOff ?
                                                (<Text>{phaseStr}</Text>)
                                                :
                                                (<Text>{roundStr}</Text>)
                                        }
                                        <Icon name={activeLastIndex.find(index => index === `${id}.${key}`) ? 'arrow-down' : 'arrowleft'} fontSize={12}></Icon>
                                    </View>
                                </TouchableOpacity>
                                {
                                    // @ts-ignore
                                    value.map((v, i) => {
                                        return (
                                            <View key={i} style={{
                                                padding: `${activeLastIndex.find(index => index === `${id}.${key}`) ? '5px' : 0}`,
                                                height: `${activeLastIndex.find(index => index === `${id}.${key}`) ? 'auto' : 0}`,
                                                overflow: 'hidden',
                                                backgroundColor: '#f9f9f9'
                                            }}>
                                                {renderItem(v)}
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        )
                    })
                }


            </View>
        )
    }
    const renderSubIndex = (key, i, value, index, color) => {
        
        return (
            <View key={key} style={{ overflow: "hidden", padding: 3 }}>
                <TouchableOpacity onPress={() => changeSubIndex(`${i}.${key}`)} key={key} style={[
                    styles.rowcenter,
                    {
                        backgroundColor: color,
                        display: `${activeIndex.find(index => index === i + 1) ? 'flex' : 'none'}`,
                        padding: `${activeIndex.find(index => index === i + 1) ? '5px' : 0}`,
                    }]
                }>
                    <Text bold color={ColorUtils.isDarkColor(color) ? '#eee' : '#333'}>{`${key}`}</Text>
                    <Icon name={activeSubIndex.find(index => index === `${i}.${key}`) ? 'arrow-down' : 'arrowleft'} fontSize={12}></Icon>

                </TouchableOpacity>
                <View style={{ height: `${activeSubIndex.find(index => index === `${i}.${key}`) ? 'auto' : 0}`, overflow: 'hidden' }}>
                    {renderLastIndex(value, `${i}.${key}`)}
                </View>
            </View>
        )
    }
    return (
        <View>
            {
                
                data.map((item, i) => {
                    let color = 'blue'

                    Object.entries(item).forEach(e=>{
                        Object.entries(e[1]).forEach(nose => {
                            Object.entries(nose[1]).forEach(wiwi => {
                                color = wiwi[1][0]?.categoryColor
                            })
                        })
                    })

                    // const eventCategoryColor = colorHelper.getCategoryColor(Object.keys(item).map(r => r)[0])
                    return (
                        <View key={i} style={{ overflow: 'hidden', marginBottom: 30 }}>
                            <TouchableOpacity style={[styles.rowcenter, { backgroundColor: color, padding: 5 }]} onPress={() => changeIndex(i + 1)}>
                                <Text bold color={ColorUtils.isDarkColor(color) ? '#eee' : '#333'}>{Object.keys(item).map(r => r)}</Text>
                                <Icon name={activeIndex.find(index => index === i + 1) ? 'arrow-down' : 'arrowleft'} fontSize={12}></Icon>
                            </TouchableOpacity>

                            <View style={{ overflow: 'hidden', height:activeIndex.find(index => index === i + 1) ? 'auto' : '0' }}>
                                {Object.entries(item).map(([key, value]) => {
                                    return Object.entries(value).map(([key, value], index) => {
                                        const groupColor = value[Object.keys(value)[0]][0].automaticAssignationCalendarColor
                                        return renderSubIndex(key, i, value, Object.keys(item).map(r => r)[0], groupColor)
                                    })
                                })}
                            </View>
                        </View>
                    );
                })
            }
        </View>
    )
};

/**
 * styles
 */
const styles = StyleSheet.create({
    rowcenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
});
