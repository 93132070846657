import * as React from "react";
import { t, i18n, Picker } from "owc-react-lib-common";
import { Button } from "src/components/Button";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { DEFAULT_LANGUAGE } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { DOCUMENT_ID, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as PeopleService from "src/services/PeopleService";
import * as ProvinceService from "src/services/ProvinceService";
import * as TownService from "src/services/TownService";
import { DOCUMENT_TYPE_CIF, DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIF } from "./config";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";

interface IPersonForm {
  data?: any;
  onSuccess: (object: any) => void;
}

export const PersonForm = ({ data, onSuccess }: IPersonForm) => {
  const alerts = useAlerts();
  const form = React.useRef<any>(data || {});
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(true);
  const [provinces, setProvinces] = React.useState<any[]>([]);
  const [towns, setTowns] = React.useState<any[]>([]);
  const [townId, setTownId] = React.useState<string | number>("");

  const setName = (value: string) => (form.current.name = value);
  const setSurname = (value: string) => (form.current.surname = value);
  const setContactEmail = (value: string) => (form.current.contactEmail = value);
  const setAddress = (value: string) => (form.current.adress = value);
  const setIdDocType = (value: string) => (form.current.idDocType = parseInt(value));
  const setIdDoc = (value: string) => (form.current.idDoc = value);
  const setZipCode = (value: string) => (form.current.zipCode = value);

  const setProvinceId = React.useCallback(async (value: string) => {
    form.current.provinceId = parseInt(value);

    form.current.townId = "";
    setTownId(form.current.townId);

    if (!value) {
      setTowns([]);
      return;
    }

    try {
      const response = await TownService.findByProvinceId(form.current.provinceId);
      setTowns(response.data);
    } catch (error) {
      setTowns([]);
    }
  }, []);

  /**
   *
   */
  const handleTownChange = React.useCallback((value: string) => {
    setTownId(parseInt(value));
    form.current.townId = parseInt(value);
  }, []);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await Promise.all([
        ProvinceService.findAll(),
        data?.provinceId ? TownService.findByProvinceId(data.provinceId) : { data: [] },
      ]);

      setProvinces(response[0].data);
      setTowns(response[1].data);
      handleTownChange(data.townId);
    } catch (error) {
      //
    }

    setLoading(false);
  });

  /**
   * handle submit
   */
  const handleSubmit = React.useCallback(async () => {
    // validate
    const { errors, hasErrors } = validateAll(form.current, [
      { name: "name", type: REQUIRED },
      { name: "surname", type: REQUIRED },
      { name: "contactEmail", type: REQUIRED },
      { name: "adress", type: REQUIRED },
      { name: "zipCode", type: REQUIRED },
      { name: "provinceId", type: REQUIRED },
      { name: "townId", type: REQUIRED },
      { name: "idDocType", type: REQUIRED },
      { name: "idDoc", type: [REQUIRED, DOCUMENT_ID], documentTypeId: form.current.idDocType },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // create / update
    try {
      let response;
      const values = { ...form.current, defaultLang: i18n.getLanguage() || DEFAULT_LANGUAGE };
      if (!data?.id) {
        response = await PeopleService.create(values);
      } else {
        response = await PeopleService.update(values);
      }

      onSuccess(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [validateAll, data, alerts, onSuccess]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const documentTypes = [
    { label: "NIF", value: DOCUMENT_TYPE_NIF },
    { label: "NIE", value: DOCUMENT_TYPE_NIE },
    { label: "CIF", value: DOCUMENT_TYPE_CIF },
  ];

  return (
    <Form>
      <Row flex={[1, 2, 2]}>
        <Input
          label={t("auth.components.personForm.name")}
          defaultValue={form.current.name}
          onChange={setName}
          error={errors.name}
        />
        <Input
          label={t("auth.components.personForm.surname")}
          defaultValue={form.current.surname}
          onChange={setSurname}
          error={errors.surname}
        />
        <Input
          label={t("auth.components.personForm.email")}
          defaultValue={form.current.contactEmail}
          onChange={setContactEmail}
          error={errors.contactEmail}
        />
      </Row>
      <Row flex={[1, 2, 2]}>
        <Picker
          label={t("auth.components.personForm.idDocType")}
          items={documentTypes}
          defaultValue={form.current.idDocType}
          onChange={setIdDocType}
          error={errors.idDocType}
        />
        <Input
          label={t("auth.components.personForm.idDoc")}
          defaultValue={form.current.idDoc}
          onChange={setIdDoc}
          error={errors.idDoc}
        />
        <Input
          label={t("auth.components.personForm.address")}
          defaultValue={form.current.adress}
          onChange={setAddress}
          error={errors.adress}
        />
      </Row>
      <Row flex={[1, 2, 2]}>
        <Input
          label={t("auth.components.personForm.zipCode")}
          defaultValue={form.current.zipCode}
          onChange={setZipCode}
          error={errors.zipCode}
        />
        <Picker
          label={t("auth.components.personForm.province")}
          items={provinces.map((province: any) => ({ label: province.name, value: province.id }))}
          defaultValue={form.current.provinceId}
          onChange={setProvinceId}
          error={errors.provinceId}
        />
        <Picker
          label={t("auth.components.personForm.town")}
          items={towns.map((town: any) => ({ label: town.name, value: town.id }))}
          defaultValue={townId}
          onChange={handleTownChange}
          error={errors.townId}
        />
      </Row>
      <Row>
        <Button label={t("global.save")} onPress={handleSubmit} />
      </Row>
    </Form>
  );
};
