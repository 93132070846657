import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { Card } from 'src/components/Card';
import { Icon } from 'src/components/Icon';
import { IconButton } from 'src/components/IconButton';
import { Separator } from 'src/components/Separator';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission"

interface IRoomCard {
  data: any;
  onEdit: (object: any) => void;
  onDelete: (object: any) => void;
}

export const RoomCard = ({ data, onEdit, onDelete }: IRoomCard) => {

  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);

  /**
   * on edit
   */
  const handleEdit = React.useCallback(() => {
    onEdit(data);
  }, [onEdit, data]);

  /**
   * on delete
   */
  const handleDelete = React.useCallback(() => {
    onDelete(data);
  }, [onDelete, data]);

  /**
   * render
   */
  return (
    <View style={{ minWidth: 230 }}>
      <Card containerStyle={{ padding: 10 }}>
        <View justifyContent='center' alignItems='center'>
          <Icon fontSize={100} name='bed' />
        </View>

        <View flex={1} justifyContent='center'>
          <Text fontSize={16} bold textAlign='center'>{data.description}</Text>
        </View>

        <View>
          <Separator marginTop={5} />

          <View marginBottom={10}>
            <View flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 5 }}>
              <Text uppercase>{t('admin.hotels.rooms.peopleCapacity')}: </Text>
              <Text textAlign='center' bold>{data.peopleCapacity}</Text>
            </View>

            <View flexDirection='row' justifyContent='center' alignItems='center'>
              <Text uppercase>{t('admin.hotels.rooms.freeRooms')}: </Text>

              <Text textAlign='center' bold>
                {data.availableRooms === undefined || data.availableRooms === null ? '∞' : `${data.freeRooms} / ${data.availableRooms}`}
              </Text>
            </View>
          </View>
          {!readOnly && <View flexDirection='row' justifyContent='flex-end'>
            <IconButton backgroundColor={'transparent'} color={'black'} name='edit' onPress={handleEdit} />
            <IconButton backgroundColor={'transparent'} color={'black'} name='trash' onPress={handleDelete} />
          </View>}

        </View>
      </Card>
    </View>
  );
}