import { IUser } from "src/context/OWCOpenDmrContext";
import jwtDecode from 'jwt-decode';

export const decode = (jwt: string): IUser | undefined => {
  try {
    const decoded: any = jwtDecode(jwt);
    
    if(!decoded) {
      return;
    }

    if(new Date(decoded.exp * 1000) <= new Date()) {
      return;
    }

    return {
      id: decoded.sub,
      username: decoded.data?.username,
      jwt,
      isOrganizer: decoded.data?.isOrganizer,
      permissions: decoded.data?.permissions || [],
      admin: decoded.data?.admin
    };
  } catch(error) {
    //
  }
}