import { Separator, Text, Title, t } from "owc-react-lib-common";
import * as React from 'react';
import { StyleSheet } from "react-native";
import { ButtonLink } from "src/components/ButtonLink";
import { Container } from "src/components/Container";
import { View } from "src/components/View";
import { styling } from "src/config";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { Translation, usePortalTranslations } from "src/hooks/usePortalTranslations";

export const Main = () => {
	const translate = usePortalTranslations();
	const context = React.useContext(OWCOpenDmrContext);
	const cards = [
		{ id: 'competitions5x5', modalityId:1, title: t(translate(Translation.FIVEONFIVE_COMPETITION_TITLE)), description: t(translate(Translation.FIVEONFIVE_COMPETITION_DESCRIPTION)), button: { label: t('global.go'), to: '/auth/competitions' } },
		{ id: 'teams5x5', modalityId:1, title: t(translate(Translation.FIVEONFIVE_TEAM_TITLE)), description: t(translate(Translation.FIVEONFIVE_TEAM_DESCRIPTION)), button: { label: t('global.go'), to: '/auth/teams' } },
		{ id: 'competitions3x3', modalityId:2, title: t(translate(Translation.THREEONTHREE_COMPETITION_TITLE)), description: t(translate(Translation.THREEONTHREE_COMPETITION_DESCRIPTION)), button: { label: t('global.go'), to: '/auth/3x3/competitions' } },
		{ id: 'teams3x3', modalityId:2, title: t(translate(Translation.THREEONTHREE_TEAM_TITLE)), description: t(translate(Translation.THREEONTHREE_TEAM_DESCRIPTION)), button: { label: t('global.go'), to: '/auth/3x3/teams' } },
	];

	return (
		<Container title={t(translate(Translation.PORTAL_TITLE))} description={t(translate(Translation.PORTAL_DESCRIPTION))}>
			<View style={styles.cardContainer}>
				{cards.map((card: any) => {
					if(context.configuration.portal.portal.includes("funda") && card.modalityId === 2 ) return null;

					return (
					<View key={card.id} style={styles.card}>
						<Title text={card.title} justifyContent="center" containerStyle={styles.cardTitle} />
						<Separator height={1} width='100%' backgroundColor='#eee' marginBottom={styling.spacing} />
						<View style={styles.cardDescription}>
							<Text justifyContent='center'>{card.description}</Text>
						</View>
						<ButtonLink {...card.button} type='primary' style={styles.cardButton} justifyContent='center' />
					</View>
				)})}
			</View>
		</Container>
	);
}

const styles = StyleSheet.create({
	cardContainer: {
		// padding: styling.spacing,
		// flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		height: 'auto',
		marginHorizontal: -styling.spacing / 2,
	},
	card: {
		flex: 1,
		borderWidth: 1,
		borderColor: '#eee',
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		padding: styling.spacing,
		maxWidth: 350,
		marginHorizontal: styling.spacing / 2,
	},
	cardTitle: {
		height: 60,
		justifyContent: 'center',
		alignItems: 'center',
	},
	cardDescription: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: styling.spacing,
	},
	cardButton: { 
		marginBottom: 0,
		width: '100%',
	}
});