import dayjs from "dayjs";
import { Checkbox, t } from "owc-react-lib-common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { useParams } from "react-router-dom";
import { Form } from "src/components/Form";
import { Input } from "owc-react-lib-common"
import { Row } from "src/components/Row/Row";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { CUSTOM, IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as SeasonService from "src/services/SeasonService";
import { InputDate } from "src/components/InputDate";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { useEffectChange } from "src/hooks/useEffectChange";
import { Loader } from "src/components/Loader";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const SeasonDetail = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const seasonUuid = useParams().uuid;
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const alerts = useAlerts();
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const form = React.useRef<any>({});

  const setActive = React.useCallback((value: boolean) => (form.current.active = value), []);
  const setName = React.useCallback((value: string) => (form.current.name = value), []);
  const setBeginDate = React.useCallback((value?: string) => (form.current.beginDate = value), []);
  const setEndDate = React.useCallback((value?: string) => (form.current.endDate = value), []);

  useEffectChange(async () => {
    setLoading(true);
    if (seasonUuid) {
      try {
        const response = await SeasonService.findByUuid(seasonUuid);
        setData(response.data);
        form.current = response.data
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
    setLoading(false);
  }, []);

  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "beginDate", type: REQUIRED },
      { name: "endDate", type: REQUIRED },
      {
        name: "endDate",
        type: CUSTOM,
        validate: () => (
          form.current.endDate && dayjs(form.current.beginDate).isAfter(form.current.endDate)
            ? t("admin.seasons.detail.endDateIsBeforeBeginDateError")
            : ""
        ),
      }
    ];
    const validated = validateAll(form.current, fields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  const createSeason = React.useCallback(async () => {
    if (!validate()) return;

    try {
      await SeasonService.createSeason(form.current);
      alerts.create({ text: t("admin.seasons.detail.createSuccess"), type: "success" });
      navigate(`/organizer/seasons/`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, navigate, validate]);

  const updateSeason = React.useCallback(async () => {
    if (!validate()) return;

    try {
      await SeasonService.updateSeason(form.current);
      alerts.create({ text: t("admin.seasons.detail.updateSuccess"), type: "success" });
      navigate(`/organizer/seasons/`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, navigate, validate]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container title={t('admin.seasons.list.title')}>
      <View flexDirection="row">
        {seasonUuid ? (
          <Title text={t('admin.seasons.detail.update')} />
        ) : (
          <Title text={t('admin.seasons.detail.create')} />
        )}
        <FloatingIconButton
          name="list"
          title={t("admin.backToList")}
          color="black"
          backgroundColor="transparent"
          onPress={() => navigate(`/organizer/seasons`)}
          right={10}
        />
      </View>

      <Form>
        <Row flex={[1, 6]} rowStyle={{ justifyContent: 'center' }}>
          <Checkbox
            onChange={setActive}
            defaultValue={data.active}
            label={t("admin.seasons.detail.active")}
            marginBottom={0}
          />
          <Input
            label={t("admin.seasons.detail.name")}
            onChange={setName}
            error={errors.name}
            defaultValue={data.name}
            disabled={readOnly}
            required
          />
        </Row>
        <Row>
          <InputDate
            label={t("admin.seasons.detail.beginDate")}
            required
            onChange={setBeginDate}
            error={errors.beginDate}
            defaultValue={data.beginDate}
            disabled={readOnly}
          />
          <InputDate
            required
            label={t("admin.seasons.detail.endDate")}
            onChange={setEndDate}
            error={errors.endDate}
            defaultValue={data.endDate}
            disabled={readOnly}
          />
        </Row>
        <Row>
          {seasonUuid ? (
            <Button label={t("global.update")} onPress={updateSeason} disabled={readOnly} />
          ) : (
            <Button label={t("global.create")} onPress={createSeason} disabled={readOnly} />
          )}
        </Row>
      </Form>
    </Container>
  );
};
