import * as React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Loader } from './Loader';

export const ImageRenderer = ({ imageUrl }) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  return (
    <View style={styles.container}>
      {loading && (
        <View style={{ position: 'absolute', justifyContent: "center", alignItems: "center", zIndex: 99 }}>
          <Loader />
        </View>
      )}

      <Image
        source={{ uri: imageUrl }}
        style={styles.image}
        onLoad={() => setLoading(false)}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  }
});
