import { BACKEND_URL } from '../config';
import { deleteTyped, getTyped, postTyped } from './RestService';
import { ITeamEventCategoryDTO } from './Team3x3Service';

export interface IShoppingCart {
    id: number;
    uuid: string;
    details: IShoppingCartDetail[];
}

export interface IShoppingCartDetail {
    paid: boolean;
    id?: number;
    uuid?: string;
    total: number;
    teamEventCategory: ITeamEventCategoryDTO;
    created?: Date;
    deleted?: Date;
}

// BASE URLS
const AUTH_URL = BACKEND_URL + '/auth/shopping-cart';

export const find = (): Promise<IShoppingCart> => {
    return getTyped(AUTH_URL);
}

export const addToCart = (shoppingCartDetail: IShoppingCartDetail): Promise<IShoppingCartDetail> => {
    return postTyped(AUTH_URL, shoppingCartDetail);
}

export function removeFromCart(id: number): Promise<number> {
    return deleteTyped(`${AUTH_URL}/${id}`);
}

export const payment = (shoppingCartUuid: string): Promise<any> => {
    return postTyped(`${AUTH_URL}/${shoppingCartUuid}/payment`);
}
