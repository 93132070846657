import * as React from "react";
import { Picker, t } from "owc-react-lib-common";
import { Button } from "src/components/Button";
import { Checkbox } from "src/components/Checkbox";
import { Input } from "owc-react-lib-common"
import { Title } from "src/components/Title";
import { useAlerts } from "src/hooks/useAlerts";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as EventService from "src/services/EventService";
import * as RankingSystemService from "src/services/RankingSystemService";
import { EventContext } from "./EventContext";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { InputDate } from "src/components/InputDate";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { View } from "src/components/View";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "src/components/ModalConfirm";
import { useEffectChange } from "src/hooks/useEffectChange";
import { MODALITY_3x3, MODALITY_5x5 } from "src/config";
import { CompetitionDTO } from "src/services/CompetitionService";

interface IEventDetail {
  competition?: CompetitionDTO;
  onCreate?: (object: any) => void;
}

export const EventDetail = ({ onCreate, competition }: IEventDetail) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [errors, setErrors] = React.useState<any>({});
  const navigate = useNavigate();
  const { validateAll } = useValidation();
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false)
  const [rankingSystems, setRankingSystems] = React.useState<any[]>([]);
  const alerts = useAlerts();
  const { data, setData } = React.useContext(EventContext);
  const isDataEmpty = Object.keys(data).length === 0;
  const form = React.useRef<any>(isDataEmpty ? { competitionId: competition.id } : data);

  useEffectChange(async () => {
    const response = await RankingSystemService.getRankingSystem();
    setRankingSystems(response.data)
  }, [])

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
    ];
    form.current.numNonPlayerBonusRate = form.current.numNonPlayerBonusRate || 0;
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  /**
   * save
   */
  const handleSave = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      const response = await EventService.update(form.current);

      setData({ ...response.data, eventCategories: data.eventCategories });
      alerts.create({ text: t("admin.competitions.eventsCompetition.eventEditData.update"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, alerts, validate, setData]);

  const handleCreate = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      const response = await EventService.create(form.current);
      const newEvent = response.data;
      alerts.create({ text: t("global.success"), type: "success" });
      onCreate(newEvent);
      navigate(`/organizer/events/${newEvent.uuid}`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, navigate, onCreate, validate]);

  const deleteEvent = React.useCallback(async () => {
    try {
      EventService.deleteEvent(data.uuid);
      navigate("/organizer/competitions");
      alerts.create({ text: t("admin.competitions.eventsCompetition.eventEditData.deleteSuccess"), type: "success", });
    } catch (error) {
      alerts.createDSMError(error);
    }

  }, [data, alerts, navigate]);


  /**
   * render
   */
  return (
    <>
      <View flexDirection="row">

        <Title
          text={isDataEmpty ? t("admin.competitions.eventsCompetition.eventEditData.create") : t("admin.competitions.eventsCompetition.eventEditData.detail")}
          containerStyle={{ flex: 1 }}
        />

        {!readOnly && <FloatingIconButton
          name="trash"
          title={t("global.delete")}
          color="black"
          backgroundColor="transparent"
          onPress={() => setOpenDeleteModal(true)}
          containerStyle={{ position: "relative" }}
          right={5}
        />}

      </View>


      <Form>
        <Row flex={[1, 1, 1, 2]}>
          <Input
            label={t("admin.competitions.eventsCompetition.eventEditData.name")}
            error={errors.name}
            defaultValue={form.current.name}
            onChange={(val) => (form.current = { ...form.current, name: val })}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.competitions.eventsCompetition.eventEditData.tpvCode")}
            error={errors.tpvCode}
            defaultValue={form.current.tpvCode}
            onChange={(val) => (form.current = { ...form.current, tpvCode: val })}
            disabled={readOnly}
          />
          <Picker
            label={t("admin.competitions.eventsCompetition.eventEditData.rankingSystem")}
            defaultValue={form.current.rankingSystemId}
            onChange={(val) => (form.current = { ...form.current, rankingSystemId: val })}
            disabled={readOnly}
            items={rankingSystems.map((rs) => ({ label: rs.name, value: rs.id }))}
          />
          <Input
            label={t("admin.competitions.eventsCompetition.eventEditData.description")}
            defaultValue={form.current.description}
            onChange={(val) => (form.current = { ...form.current, description: val })}
            disabled={readOnly}
          />
        </Row>

        <Row>
          <InputDate
            label={t("admin.competitions.eventsCompetition.eventEditData.startInscriptionDate")}
            defaultValue={form.current.startInscriptionDate}
            onChange={(val) => (form.current = { ...form.current, startInscriptionDate: val })}
            disabled={readOnly}
            internalFormat='YYYY-MM-DD HH:mm:ss'
            useTime
          />
          <InputDate
            label={t("admin.competitions.eventsCompetition.eventEditData.limitInscriptionDate")}
            defaultValue={form.current.limitInscriptionDate}
            onChange={(val) => (form.current = { ...form.current, limitInscriptionDate: val })}
            disabled={readOnly}
            internalFormat='YYYY-MM-DD HH:mm:ss'
            required
            useTime
          />
          {data.modalityId === MODALITY_3x3 &&
            <InputDate
              label={t("admin.competitions.eventsCompetition.eventEditData.changeRosterLimitDate")}
              defaultValue={form.current.changeRosterLimitDate}
              onChange={(val) => (form.current = { ...form.current, changeRosterLimitDate: val })}
              disabled={readOnly}
              internalFormat='YYYY-MM-DD HH:mm:ss'
              useTime
            />
          }
        </Row>
        <Row>
          <InputDate
            label={t("admin.competitions.eventsCompetition.eventEditData.beginDate")}
            defaultValue={form.current.beginDate}
            onChange={(val) => (form.current = { ...form.current, beginDate: val })}
            disabled={readOnly}
          />
          <InputDate
            label={t("admin.competitions.eventsCompetition.eventEditData.endDate")}
            defaultValue={form.current.endDate}
            onChange={(val) => (form.current = { ...form.current, endDate: val })}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.competitions.eventsCompetition.eventEditData.numNonPlayerBonusRate")}
            defaultValue={form.current.numNonPlayerBonusRate}
            onChange={(val) => (form.current = { ...form.current, numNonPlayerBonusRate: val })}
            disabled={readOnly}
            numeric
          />
        </Row>

        {data.modalityId === MODALITY_5x5 && <Row>
          <Checkbox
            label={t("admin.competitions.eventsCompetition.eventEditData.allowHotel")}
            defaultValue={form.current.allowHotel}
            onChange={(val) => (form.current = { ...form.current, allowHotel: val })}
            disabled={readOnly}
          />
        </Row>}

        <Row>
          <Button
            label={t("global.save")}
            onPress={isDataEmpty ? handleCreate : handleSave}
            disabled={readOnly} />
        </Row>

      </Form>

      <ModalConfirm
        visible={openDeleteModal}
        text={t("admin.competitions.eventsCompetition.eventEditData.deleteQuestion")}
        onClose={() => setOpenDeleteModal(false)}
        onSuccess={deleteEvent}
      />

    </>
  );
};
