import * as React from "react";
import { Picker, t, useComponentDidMount } from "owc-react-lib-common";

import { useAlerts } from "src/hooks/useAlerts";
import { Loader } from "src/components/Loader";
import { useParams } from "react-router-dom";
import { StyleSheet } from "react-native";
import { View } from "src/components/View";
import { Button } from "src/components/Button";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { ModalConfirm } from "src/components/ModalConfirm";
import { IconButton } from "src/components/IconButton";
import { Modal } from "src/components/Modal";
import { Input } from "owc-react-lib-common"
import * as CourtService from "src/services/CourtService";
import { FacilityContext } from "./FacilityContext";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const FacilityCourtList = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { data: facility } = React.useContext(FacilityContext);
  const alerts = useAlerts();
  const facilityUuid = useParams().uuid;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const [courtToDelete, setCourtToDelete] = React.useState<any>(null);
  const [courtToEdit, setCourtToEdit] = React.useState<any>(null);
  const form = React.useRef<CourtService.ICourtDTO>({});
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "isCovered", type: REQUIRED },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  useComponentDidMount(async () => {
    setLoading(true);
    try {
      const response = await CourtService.getCourtsByFacilityUuid(facilityUuid);
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  });

  const actions = React.useCallback((court: CourtService.ICourtDTO) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          name="edit"
          color="black"
          backgroundColor="transparent"
          onPress={() => {
            form.current = { ...court };
            setCourtToEdit(court);
          }}
        />
        <IconButton
          name="trash"
          color="black"
          backgroundColor="transparent"
          onPress={() => setCourtToDelete(court)} />
      </View>
    );
  }, []);

  const createCourt = React.useCallback(async () => {
    if (!validate()) return;
    try {
      form.current.facilityId = facility.id;
      const response = await CourtService.createCourt(form.current);
      alerts.create({
        text: t("admin.courts.createConfirmation"),
        type: "success",
      });
      setData([...data, { ...response.data }]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setCourtToEdit(null);
    form.current = {};
  }, [data, alerts, facility, validate]);

  const updateCourt = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const response = await CourtService.updateCourt(form.current);
      alerts.create({
        text: t("admin.courts.updateConfirmation"),
        type: "success",
      });
      setData([...data.map(court => court.id === response.data.id ? response.data : court)]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setCourtToEdit(null);
    form.current = {};
  }, [alerts, data, validate]);

  const deleteCourt = React.useCallback(async () => {
    try {
      await CourtService.deleteCourt(courtToDelete.id);
      alerts.create({
        text: t("admin.courts.deleteConfirmation"),
        type: "success",
      });
      setData(data.filter((court: any) => court.id !== courtToDelete.id));
      setCourtToDelete(null);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [courtToDelete, data, alerts]);

  if (loading) {
    return <Loader />;
  }

  const headers: ITableHeader[] = [
    { name: "id", label: t("admin.courts.id"), width: 90 },
    {
      name: "name",
      label: t("admin.courts.name"),
      textProps: { uppercase: true },
    },
    {
      name: "description",
      label: t("admin.courts.description"),
      textProps: { uppercase: true },
    },
    {
      name: "isCovered",
      label: t("admin.courts.isCovered"),
      render: (object: any) => <Text>{object.isCovered ? t("global.yes") : t("global.no")}</Text>,
    },
    { name: "courtLevel", label: t("admin.courts.courtLevel"), width: 90 },
    { name: "actions", label: "", render: actions, width: 90, hidden: readOnly },
  ];

  const selectIsCovered = [
    { label: t("global.yes"), value: "true" },
    { label: t("global.no"), value: "false" },
  ];

  const courtLevels = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
  ]

  return (
    <>
      <View flexDirection="row">
        <Button
          label={t("admin.courts.create")}
          onPress={() => setCourtToEdit({ name: t("admin.courts.new") })}
          disabled={readOnly}
        />
      </View>
      <Table headers={headers} data={data} />

      <Modal
        title={courtToEdit?.name}
        visible={courtToEdit}
        onClose={() => {
          form.current = {};
          setErrors({});
          setCourtToEdit(null);
        }}
      >
        <Form>
          <Row flex={[2, 1]}>
            <Input
              label={t("admin.courts.name")}
              defaultValue={form.current.name}
              onChange={(value) => (form.current.name = value)}
              containerStyle={styles.input}
              error={errors.name}
              required
            />
            <Picker
              label={t("admin.courts.isCovered")}
              defaultValue={form.current.isCovered}
              items={selectIsCovered}
              onChange={(value) => (form.current.isCovered = value)}
              containerStyle={styles.lastInput}
              error={errors.isCovered}
              required
            />
          </Row>
          <Row>
            <Input
              label={t("admin.courts.description") + ` (${t('global.optional')})`}
              defaultValue={form.current.description}
              onChange={(value) => (form.current.description = value)}
              containerStyle={styles.input}
            />
          </Row>
          <Row>
            <Picker
              label={t("admin.courts.courtLevel")}
              defaultValue={form.current.courtLevel}
              items={courtLevels}
              onChange={(value) => (form.current.courtLevel = value)}
              containerStyle={styles.lastInput}
              error={errors.courtLevel}
              required
            />
          </Row>
          <Row>
            {form.current.id ? (
              <Button label={t("global.save")} onPress={updateCourt} />
            ) : (
              <Button label={t("global.create")} onPress={createCourt} />
            )}
          </Row>
        </Form>
      </Modal>

      <ModalConfirm
        visible={courtToDelete}
        text={t("admin.courts.deleteQuestion")}
        onClose={() => setCourtToDelete(null)}
        onSuccess={deleteCourt}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginRight: 5,
  },
  lastInput: {
    flex: 1,
    marginRight: 0,
  },
});
