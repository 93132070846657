import * as React from 'react';
import { ColorValue, ViewProps } from "react-native";
import { LinkProps, useNavigate } from "react-router-dom";
import { Button } from "./Button";
import { Icon, IIcon } from "./Icon";

interface IButtonLink {
  to: string;
  label?: string;
  icon?: IIcon;
  color?: ColorValue;
  target?: '_blank' | '_self';
  onPress?: () => void;
  style?: LinkProps['style'];
  type?: 'primary' | 'secondary' | 'link';
  minHeight?: number;
  contentStyle?: ViewProps['style'];
  textPaddingLeft?: number;
  backgroundColor?: ColorValue;
  textIcon?: IIcon;
  flexDirection?: "column" | "column-reverse" | "row" | "row-reverse" | undefined;
  fontWeight?: number;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  title?: string;
}

export const ButtonLink = ({ title, fontWeight, backgroundColor, to, type = 'link', label, icon, color, target = '_self', onPress = () => { }, style = {}, minHeight, contentStyle, textPaddingLeft = 0, textIcon, flexDirection, justifyContent = 'flex-start' }: IButtonLink) => {
  const navigate = useNavigate();
  return (
    <a title={title} target={target} href={to} style={{ textDecoration: 'none', ...style }} onClickCapture={(event: any) => {
      event.preventDefault();
      navigate(to, { replace: target !== '_blank' })
    }}>
      {!icon ? (
        <Button backgroundColor={backgroundColor} type={type} label={label} onPress={onPress} marginBottom={0} containerStyle={[{ width: '100%' }, contentStyle]} color={color} justifyContent={justifyContent} textAlign='left' minHeight={minHeight} shadow={false} textPaddingLeft={textPaddingLeft} icon={textIcon} fontWeight={fontWeight} flexDirection={flexDirection} />
      ) : (
        <Icon backgroundColor={backgroundColor} {...icon} color={color} />
      )}
    </a>
  );
}