import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IShirtSizeDTO {
  id?: number;
  eventId?: number;
  eventName?: string;
  size?: string;
  position?: number;
}

// BASE URLS
const PUBLIC_URL = BACKEND_URL + '/public/shirt-sizes';
const AUTH_URL = BACKEND_URL + '/auth/shirt-sizes';

// PUBLIC METHODS
export const getAll = () => {
  return RestService.get(PUBLIC_URL);
}

// AUTH METHODS
export const findByEventUuid = (eventUuid: string) => {
    return RestService.get(`${AUTH_URL}/${eventUuid}`);
}

export const createShirtSize = (shirtSizeDTO: IShirtSizeDTO) => {
    return RestService.post(`${AUTH_URL}`, shirtSizeDTO);
}

export const updateShirtSize = (shirtSizeDTO: IShirtSizeDTO) => {
  return RestService.put(`${AUTH_URL}`, shirtSizeDTO);
}

export const deleteShirtSize = (shirtSizeId: number) => {
  return RestService.delete(`${AUTH_URL}/${shirtSizeId}`);
}




