import { BACKEND_URL } from '../config';
import { getTyped, putTyped } from './RestService';
import { Page } from './entities/Page';
import { t } from 'owc-react-lib-common';

// BaseUserMaskDto.ts
export interface BaseUserMask {
  id: number;
  uuid: string;
  email: string;
  defaultLang: string;
  registerStatus: string;
  organizerId: number;
  organizerName: string;
  accountValidated: boolean;
  organizerReadOnly: boolean;
  admin: boolean;
}

export const REGISTER_STATUS_CREATED = 0;
export const REGISTER_STATUS_VALIDATED = 1;
export const REGISTER_STATUS_COMPLETED = 2;



const BASE_URL = BACKEND_URL + '/auth/base-user'

export const getBaseUsers = (page: number, size: number, sort: string = "id", direction: "ASC" | "DESC" = "ASC", searchString: String, selectedOrganizer = null): Promise<Page<BaseUserMask>> => {
  return getTyped(`${BASE_URL}?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}${searchString ? `&searchText=${searchString}` : ''}${selectedOrganizer ? `&organizer=${selectedOrganizer}` : ''}`);
}

export const setOrganizer = (baseUserId: number, organizerId: number): Promise<BaseUserMask> => {
  return putTyped(`${BASE_URL}/${baseUserId}/set-organizer?organizer=${organizerId}`);
}

export const updateReadonly = (baseUserId: number): Promise<BaseUserMask> => {
  return putTyped(`${BASE_URL}/${baseUserId}/update-readonly`);
}

export const findByUuid = (baseUserUuid: string): Promise<BaseUserMask> => {
  return getTyped(`${BASE_URL}/${baseUserUuid}`);
}

export const getRegisterStatus = (registerStatus: string): String => {
  const num = Number.parseInt(registerStatus)
  if(num === REGISTER_STATUS_CREATED){
    return t('global.registerStatus.created')
  }else if(num === REGISTER_STATUS_VALIDATED){
    return t('global.registerStatus.validated')
  }else if(num === REGISTER_STATUS_COMPLETED){
    return t('global.registerStatus.completed')
  }else{
    return t('global.registerStatus.notFound')
  }
}