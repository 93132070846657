import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IgroupTeamDTO {
	id?: number;
	groupId?: number;
	teamId?: number;
	groupName?: string ;
	teamName?: string ;
	position?: number;
}

const BASE_URL = BACKEND_URL + '/auth/group-teams'

/**
 * Cambia la posición de un equipo de un grupo
 */
export const changeTeamPosition = (groupId: number, teamId: number, increase: boolean) => {
	return RestService.put(`${BASE_URL}/change-position/${groupId}/${teamId}?increase=${increase}`);
  }

/**
 * 
 Añade un equipo a un grupo
 */
export const addToGroup = (team: IgroupTeamDTO) => {
	return RestService.post(`${BASE_URL}/`, team);
}

/**
 * 
 Devuelve todos los group-team por id de grupo
 */
export const getByGroupId = (groupId: number) => {
  return RestService.get(`${BASE_URL}/by-group-id/${groupId}`);
}

/**
 * 
 Devuelve todos los group-team por uuid de grupo
 */
 export const getByGroupUuid = (groupUuid: string) => {
	return RestService.get(`${BASE_URL}/by-group-uuid/${groupUuid}`);
  }


 

  export const replaceTeam = (payload: any) => {
	return RestService.put(`${BASE_URL}/replace-team`, payload);
  }