import { Input, t } from 'owc-react-lib-common';

import { ViewProps } from "react-native";
import { Checkbox } from "src/components/Checkbox";
import { IRadio, RadioGroup } from "src/components/RadioGroup";
import { Text } from "src/components/Text";
import { View } from "src/components/View";

interface ICreationQuestion {
  data: {
    type: string;
    description: string;
    options?: string;
  };
  value?: any;
  defaultValue?: string;
  onChange?: (value: any) => void;
  containerStyle?: ViewProps['style'];
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

export const CreationQuestion = ({ data, value, defaultValue, onChange, required, containerStyle, error, disabled = false }: ICreationQuestion) => {
  if (data.type === 'boolean') {
    const items: IRadio[] = [
      { label: t('global.yes'), value: "true" },
      { label: t('global.no'), value: "false" },
    ];

    return (
      <RadioGroup
        label={data.description}
        items={items}
        defaultValue={defaultValue}
        // value={value}
        onChange={onChange}
        containerStyle={containerStyle}
        required={required}
        error={error}
        disabled={disabled}
      />
    );
  }
  else if (data.type === 'radio') {
    let items = [] as any;

    data?.options?.split(";")?.map((item: any) => (
      items.push({ label: item, value: item })
    ))

    return (
      <RadioGroup
        label={data.description}
        items={items}
        defaultValue={defaultValue}
        // value={value ?? items[defaultValue]?.value}
        onChange={onChange}
        containerStyle={containerStyle}
        required={required}
        error={error}
        disabled={disabled}
      />
    )
  }
  else if (data.type === 'check') {
    // let _value = Boolean(value);

    // if (value === null || value === undefined) {
    //   _value = defaultValue !== null && defaultValue !== undefined ? Boolean(data?.options?.split(";")[defaultValue]) : false;
    // }

    return (
      <View>
        <View marginBottom={15}><Text color={error ? '#dc143c' : 'black'} fontSize={14}>{data.description}</Text></View>

        <Checkbox
          label={data.options}
          disabled={disabled}
          error={error}
          required={required}
          containerStyle={{ marginTop: -1 }}
          defaultValue={data?.options === defaultValue}
          onChange={onChange}
        />
      </View>
    )
  }
  else if (data.type === 'text') {
    // let _value: boolean;

    // if (value === null || value === undefined) {
    //   _value = defaultValue !== null && defaultValue !== undefined ? Boolean(data?.options?.split(";")[defaultValue]) : false;
    // }

    return (
      <View>
        <View marginBottom={15}><Text color={error ? '#dc143c' : 'black'} fontSize={14}>{data.description}</Text></View>
        <Input label={data.options} disabled={disabled} error={error} required={required} containerStyle={{ marginTop: -1 }} onChange={onChange} />
      </View>
    )
  }

  return <View />;
}