import { Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet } from 'react-native';
import { useParams } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { useEffectChange } from "src/hooks/useEffectChange";
import * as CategoryService from "src/services/CategoryService";
import * as RankingService from "src/services/RankingService";
import * as RankingSystemService from "src/services/RankingSystemService";
// import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
// import { useUserPermission } from "src/hooks/useUserPermission";
import * as _ from 'lodash';
import { Button } from "src/components/Button";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { Form } from "src/components/Form";
import { IconButton } from "src/components/IconButton";
import { Modal } from "src/components/Modal";
import { Row } from "src/components/Row/Row";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { useColors } from "src/hooks/useColors";

export const CompetitionRankingList = ({ events }) => {
  const alerts = useAlerts();
  // const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [, setRankings] = React.useState<any[]>([]);
  const [groupedRankings, setgroupedRankings] = React.useState<any>(null);
  const [rankingSystems, setRankingSystems] = React.useState<any[]>([]);
  const [teams, setTeams] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<number>(null);
  const [categories, setCategories] = React.useState<any[]>([]);
  const { uuid } = useParams();
  const colors = useColors('primary');
  const [rankingToRefresh, setRankingToRefresh] = React.useState<any>(null);
  const [selectedRankingSystem, setSelectedRankingSystem] = React.useState<any>(null);
  // const [selectedActorRankingSystem, setSelectedActorRankingSystem] = React.useState<any>(null);

  // const { data } = React.useContext(EventContext);


  useEffectChange(async () => {
    setLoading(true);
    try {
      const response = await RankingSystemService.getRankingSystem();
      setRankingSystems(response.data.map(rs => ({ label: rs.name, value: rs.uuid })));

      const responseRanking = await RankingService.getRankingByCompetition(uuid);
      setRankings(responseRanking.data)

      const obj = responseRanking.data.map(d => d.team);
      const filtered = obj.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.name === value.name
        ))
      )
      setTeams(filtered)

      const a = _.groupBy(responseRanking.data, (e) => e.eventCategory.categoryId)
      setgroupedRankings(a)

      const b = await CategoryService.findAllCategories();
      setCategories(b.data.map(cat => ({ label: cat.name, value: cat.id })))

    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  }, []);

  const computeRanking = async () => {
    setLoading(true)

    try {
      await RankingService.computeRanking(rankingToRefresh, selectedRankingSystem)

      const response = await RankingSystemService.getRankingSystem();
      setRankingSystems(response.data.map(rs => ({ label: rs.name, value: rs.uuid })));

      const responseRanking = await RankingService.getRankingByCompetition(uuid);
      setRankings(responseRanking.data)

      const obj = responseRanking.data.map(d => d.team);
      const filtered = obj.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.name === value.name
        ))
      )
      setTeams(filtered)

      const a = _.groupBy(responseRanking.data, (e) => e.eventCategory.categoryId)
      setgroupedRankings(a)

      const b = await CategoryService.findAllCategories();
      setCategories(b.data.map(cat => ({ label: cat.name, value: cat.id })))

    } catch (error) {
      alerts.createDSMError(error);
      setLoading(false);
    }
    setRankingToRefresh(null);
    setLoading(false);
  }

  const findPointsByEvent = (team, event) => {
    return groupedRankings[selectedCategory].find(gr => gr.teamId === team.id && event.id === gr.eventCategory.eventId)?.points
  };
  const calculateSumPoints = (team) => {
    return groupedRankings[selectedCategory].filter(gr => gr.teamId === team.id).reduce((acc, ranking) => acc + ranking.points, 0)
  };

  const getTeams = () => {
    const res = teams.filter((team) => {
      if (!selectedCategory) return false
      return groupedRankings[selectedCategory]?.map(t => t.team.id).includes(team.id)
    });
    res.forEach(t => {
      t.totalPoints = calculateSumPoints(t);
    })
    return res.sort((a, b) => b.totalPoints - a.totalPoints);
  }

  if (loading) {
    return <Loader />;
  }

  // RENDER
  const headers: ITableHeader[] = [
    // { name: "id", label: t("admin.rankingSystems.id"), width: 90 },
    {
      name: "eventCategoryname",
      label: t("admin.rankingSystems.name"),
      textProps: { uppercase: true },
      render: (o) => <Text>{o.name}</Text>
    },
    {
      name: "points",
      label: t("admin.rankingSystems.points"),
      width: 90,
      render: o => {
        return (
          <View>
            <Text>{o.totalPoints}</Text>
          </View>
        )
      }
    },
    ...events.map(event => {
      return {
        name: event.uuid,
        label: event.name,
        textProps: { uppercase: true },
        render: (o) => <Text>{findPointsByEvent(o, event)}</Text>,
        headerRender: (o) => {
          return (
            <>
              <Text style={styles.headerLabel} fontSize={13} bold uppercase >{event.name}</Text>
              <IconButton
                backgroundColor={"white"}
                title={t("admin.rankingSystems.refresh")}
                color={colors.main}
                type='primary'
                name={"backtime"}
                onPress={() => { setRankingToRefresh(o.name) }}
              />
            </>
          )
        }

      }
    })
  ];

  return (
    <View>
      <View>
        <View flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title text={t("admin.competitions.rankingSystems.detail")} containerStyle={{ flex: 1 }} />
          <FloatingIconButton
            name="excel"
            title={t("admin.rankingSystems.refresh")}
            color="black"
            backgroundColor="transparent"
            onPress={() => RankingService.downloadExcel(uuid)}
            containerStyle={{ position: "relative" }}
            right={5}
          />
        </View>
      </View>
      <Picker items={categories} onChange={ec => setSelectedCategory(ec)} />
      <Table pagination={false} headers={headers} data={getTeams()} />
      <Modal title={t("admin.rankingSystems.refresh")} visible={rankingToRefresh} onClose={() => setRankingToRefresh(null)}>
        <Form>
          <Row flex={[3, 1]} containerStyle={{ alignItems: "flex-end" }}>
            <Picker label={t("admin.rankingSystems.teamRankingSystem")} items={rankingSystems} onChange={rs => setSelectedRankingSystem(rs)} />
            <Button label={t("admin.rankingSystems.generateTeamRanking")} onPress={computeRanking} />
          </Row>
        </Form>
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  headerLabel: {
    flex: 1,
    textOverflow: 'ellipsos' as any,
  }
})