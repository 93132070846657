import React from 'react'
import { View } from '../View';
import { Picker, Input } from 'owc-react-lib-common';
import { StyleSheet } from 'react-native';
import { styling } from 'src/config';
import { t } from 'owc-react-lib-common';
import debounce from 'lodash.debounce';


export interface IFilter {
    defaultSize: number;
    sizes?: number[];
    onChangeSize: (size: number) => void;
    search: (searchString: String) => void;
    children?:any;
}

export const Filter = ({ defaultSize, sizes = [5, 10, 25, 50, 100], onChangeSize, search, children }: IFilter) => {

    const [size, setSize] = React.useState<number>(defaultSize);
    const [searchString, setSearchString] = React.useState<String>("");

    React.useEffect(()=>{
        search(searchString);
    },[searchString])

    const handleSearch = debounce((value: string) => {
        setSearchString(value);
    }, 500);

    const changeSize = React.useCallback((pickerValue: number) => {
        setSize(pickerValue);
        onChangeSize(pickerValue)
    }, [size, onChangeSize]);
    
    return (
        <View style={styles.actions}>
            <Picker selected={size} defaultOptionLabel={null} items={sizes.map((size: number) => ({ label: size + '', value: size }))} onChange={changeSize} containerStyle={styles.size} />
            <Input placeholder={t('components.table.search') + '...'} onChange={handleSearch} containerStyle={styles.search} />
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    search: {
        flex: 1,
        marginBottom: 0,
    },
    actions: {
        flexDirection: 'row',
        marginBottom: styling.spacing,
    },
    size: {
        marginRight: 5,
        width: 80,
        marginBottom: 0,
    }
});