import { Alert, t } from "owc-react-lib-common";
import { View } from "src/components/View";
import { ShoppingCart } from "../shoppingCart/ShoppingCart";
import { STATUS } from "./RegisterTeam3x3";

interface IRegisterTeam3x3Payment {
  teamEventCategory: any;
  eventCategory: any;
  rate?: any;
}

export const RegisterTeam3x3Payment = ({ teamEventCategory, eventCategory, rate }: IRegisterTeam3x3Payment) => {

  /**
   * no rates available
   */
  /*if (!eventCategory?.rates?.length) {
    return (
      <Alert type='info' text={t('3x3.register.noRatesAvailable')} />
    );
  }*/

  /**
   * payment successful
   */
  if (teamEventCategory?.status > STATUS.CREATED) {
    return (
      <Alert type='success' text={t('3x3.register.paymentSuccessful')} />
    );
  }

  return (
    <View>
      {!!rate && (
        <ShoppingCart showTitle={false} />
      )}    
    </View>
  );
}
