import * as React from "react";
import { StyleSheet } from "react-native";
import { Navigate, Route, Routes } from "react-router-dom";
import { View } from "src/components/View";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { Organizer } from "src/pages/admin/organizers/Organizer";
import { OrganizerCreate } from "src/pages/admin/organizers/OrganizerCreate";
import { OrganizerList } from "src/pages/admin/organizers/OrganizerList";
import { AuthenticatedNavbar } from "./AuthenticatedNavbar";
import LoginLogsList from "src/pages/admin/loginLogs/LoginLogsList";
import UsersList from "src/pages/admin/users/UsersList";
import { UserDetail } from "src/pages/admin/users/UserDetail";

export const Admin = () => {
  const context = React.useContext(OWCOpenDmrContext);

  if (!context.user) {
    return <Navigate to="/login" />;
  }

  if (!context.user.admin) {
    return <Navigate to="/auth" />;
  }

  return (
    <View style={styles.container}>
      <AuthenticatedNavbar />

      <View style={styles.content}>
        <Routes>
          <Route path="organizers" element={<OrganizerList />} />
          <Route path="organizers/create" element={<OrganizerCreate />} />
          <Route path="organizers/:uuid/*" element={<Organizer />} />
          <Route path="login-logs" element={<LoginLogsList />} />
          <Route path="users" element={<UsersList />} />
          <Route path="users/:uuid/*" element={<UserDetail />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#eee",
  },
  content: {
    flex: 1,
  },
});
