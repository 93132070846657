import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { REGISTER_STATUS_COMPLETED, REGISTER_STATUS_CREATED, REGISTER_STATUS_PAYMENT_REJECTED, REGISTER_STATUS_TEXT_SIGNATURE_DECLINED } from 'src/pages/public/actor/IRegistrationProcess';

export const useRegistrationStatus = () => {

  /**
   * STATUS
   */
  //TODO: Cambiar Ids por constantes
  const STATUS = React.useMemo(() => [
    { id: 0, label: t('admin.teams.teamActorsList.status.sent'), description: t('admin.teams.teamActorsList.status.sentDescription') }, // 0
    { id: 10, label: t('admin.teams.teamActorsList.status.questions'), description: t('admin.teams.teamActorsList.status.questionsDescription') }, // 1
    { id: 20, label: t('admin.teams.teamActorsList.status.signature'), description: t('admin.teams.teamActorsList.status.signatureDescription') }, // 2
    // { id: 3, label: t('admin.teams.teamActorsList.status.signed'), description: t('admin.teams.teamActorsList.status.signedDescription') }, // 3
    { id: 40, label: t('admin.teams.teamActorsList.status.declined'), description: t('admin.teams.teamActorsList.status.declinedDescription') }, // 4
    { id: 50, label: t('admin.teams.teamActorsList.status.acceptance'), description: t('admin.teams.teamActorsList.status.acceptanceDescription') }, // 5
    // { id: 6, label: t('admin.teams.teamActorsList.status.accepted'), description: t('admin.teams.teamActorsList.status.acceptedDescription') }, // 6
    { id: 70, label: t('admin.teams.teamActorsList.status.payment'), description: t('admin.teams.teamActorsList.status.paymentDescription') }, // 7
    { id: 80, label: t('admin.teams.teamActorsList.status.rejected'), description: t('admin.teams.teamActorsList.status.rejectedDescription') }, // 8
    { id: 90, label: t('admin.teams.teamActorsList.status.complete'), description: t('admin.teams.teamActorsList.status.completeDescription') }, // 9 
    { id: 100, label: t('admin.teams.teamActorsList.status.apiComplete'), description: t('admin.teams.teamActorsList.status.apiCompleteDescription') }, // 10
  ], []);

  /**
   * get registration status
   */
  const getRegistrationStatus = React.useCallback((id: number, date: any) => {
    const status = STATUS.find((status: any) => status.id === id);

    if (!status) {
      return {};
    }

    let text = status.label;
    let color = '#333';
    let bold = true;

    if (id === REGISTER_STATUS_CREATED) {
      if (!date) {
        text = t('admin.teams.teamActorsList.status.pending');
        color = 'orange';
      }
      else {
        text = t('admin.teams.teamActorsList.status.sent');
      }

      bold = false;
    }
    else if (id === REGISTER_STATUS_TEXT_SIGNATURE_DECLINED || id === REGISTER_STATUS_PAYMENT_REJECTED) {
      color = 'crimson';
    }
    else if (id >= REGISTER_STATUS_COMPLETED) {
      color = 'teal';
    }
    else {
      text = status.label;
      bold = true;
    }

    return { text, color, bold };
  }, [STATUS]);

  /**
   * render registration status
   */
  const renderRegistrationStatus = React.useCallback((object: any, onPress = () => { }) => {
    const { text, color, bold } = getRegistrationStatus(object.registrationStatus, object.notificationDate);
    
    return (
      <TouchableOpacity onPress={onPress}>
        <Text bold={bold} color={color} fontSize={10} uppercase>{text}</Text>
      </TouchableOpacity>
    );
  }, [getRegistrationStatus]);

  /**
   * render legend
   */
  const renderLegend = React.useCallback(() => {
    return (
      <View style={styles.legend}>
        <View style={styles.legendItem}>
          <Text color='orange' uppercase style={styles.legendLabel}>{t('admin.teams.teamActorsList.status.pending')}</Text>
          <Text style={styles.legendDescription}>{t('admin.teams.teamActorsList.status.pendingDescription')}</Text>
        </View>

        {STATUS.map((status: any) => {
          const { text, color, bold } = getRegistrationStatus(status.id, true);

          return (
            <View key={status.id} style={styles.legendItem}>
              <Text color={color} bold={bold} uppercase style={styles.legendLabel}>{text}</Text>
              <Text style={styles.legendDescription}>{status.description}</Text>
            </View>
          )
        })}
      </View>
    );
  }, [STATUS, getRegistrationStatus]);

  return ({ STATUS, getRegistrationStatus, renderRegistrationStatus, renderLegend });
}

const styles = StyleSheet.create({
  legend: {
    // marginTop: styling.spacing,
    borderTopWidth: 1,
    borderColor: '#eee',
  },
  legendItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingBottom: 2,
    borderColor: '#eee',
    alignItems: 'center',
  },
  legendLabel: {
    width: 200,
    borderRightWidth: 1,
    borderColor: '#eee',
    paddingRight: 10,
    fontSize: 10,
  },
  legendDescription: {
    flex: 1,
    paddingLeft: 10,
    fontSize: 10,
  },
});