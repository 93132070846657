export const ROLE_PLAYER = 1;
export const ROLE_COACH = 2;
export const ROLE_ASSISTANT = 3;

export const SETTING_TYPE_NAME = 1;
export const SETTING_TYPE_SURNAME = 2;
export const SETTING_TYPE_GENDER = 3;
export const SETTING_TYPE_BIRTHDATE = 4;
export const SETTING_TYPE_EMAIL = 5;
export const SETTING_TYPE_PHONE = 6;
export const SETTING_TYPE_DOCUMENTATION = 7;
export const SETTING_TYPE_RATE = 8;
export const SETTING_TYPE_NUMBER = 9;
export const SETTING_TYPE_SHIRTSIZE = 10;
export const SETTING_TYPE_OBSERVATION = 11;


export const GENDER_FEMALE = 'F';
export const GENDER_MALE = 'M';
export const GENDER_NON_BINARY = 'X';

export const DOCUMENT_TYPE_NIF = 1;
export const DOCUMENT_TYPE_PASSPORT = 2;
export const DOCUMENT_TYPE_CATSALUT = 3;
export const DOCUMENT_TYPE_NIE = 4;
export const DOCUMENT_TYPE_CIF = 5;

export const TEAM_STATUS_PENDING = 0;
export const TEAM_STATUS_PROCESSED = 1;
export const TEAM_STATUS_VALIDATED = 2;
export const TEAM_STATUS_VALIDATED_API = 3;
export const TEAM_STATUS_COMPLETED = 4;
export const TEAM_STATUS_INVITE = 5;