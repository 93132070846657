import { t } from 'owc-react-lib-common';
import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { Navbar } from "src/components/Navbar";
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { Translation, usePortalTranslations } from 'src/hooks/usePortalTranslations';
import { getLangName } from 'src/utils/StringUtils';

export const AuthenticatedNavbar = () => {
  const translate = usePortalTranslations();
  const context = React.useContext(OWCOpenDmrContext);
  const navigate = useNavigate();

  /**
   * logout
   */
  const logout = React.useCallback(() => {
    context.setUser(undefined);
  }, [context]);

  /**
   * settings
   */
  const settings = React.useCallback(() => {
    navigate('/auth/settings');
  }, [navigate]);

  const getPublicNavbarLinks = React.useCallback(() => {
    if (context.configuration.portal?.portal.includes('inscripcionsfundacio')) {
      return [
        { label: t(translate(Translation.FIVEONFIVE_COMPETITION_TITLE)), to: '/auth/competitions' },
        { label: t(translate(Translation.FIVEONFIVE_TEAM_TITLE)), to: '/auth/teams' },
      ]
    }
    return [
      { label: t(translate(Translation.FIVEONFIVE_COMPETITION_TITLE)), to: '/auth/competitions' },
      { label: t(translate(Translation.FIVEONFIVE_TEAM_TITLE)), to: '/auth/teams' },
      { label: t(translate(Translation.THREEONTHREE_COMPETITION_TITLE)), to: '/auth/3x3/competitions' },
      { label: t(translate(Translation.THREEONTHREE_TEAM_TITLE)), to: '/auth/3x3/teams' },
    ]
  }, [translate, context.configuration.portal?.portal])

  /**
   * render
   */
  return (
    <Navbar
      data={getPublicNavbarLinks()}
      admin={[
        { label: t('components.navbar.organizers'), to: '/admin/organizers' },
        { label: t('components.navbar.loginLog'), to: `/admin/login-logs` },
        { label: t('components.navbar.users'), to: `/admin/users` },
      ]}
      organizer={[
        // { label: t('components.navbar.competitions'), to: `/organizer/${context.user?.id}/competitions` },
        // { label: t('components.navbar.seasons'), to: '/organizer/seasons' },
        // { label: t('components.navbar.facilities'), to: '/organizer/facilities' },
        // { label: t('components.navbar.rankingSystems'), to: '/organizer/ranking-systems' },
        // { label: t('components.navbar.organizer'), to: '/organizer/me' },
      ]}
      settings={[
        { label: t('components.navbar.settings'), onPress: settings },
        { label: t('components.navbar.logout'), onPress: logout },
      ]}
      languages={
        context.configuration.portal?.availableLanguages?.map((lang) => {
          return { label: getLangName(lang), onPress: () => context.changeLanguage(lang) }
        })  
      }
    />
  );
}