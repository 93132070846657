import { Checkbox, CustomPicker, Input, Picker, t } from 'owc-react-lib-common';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { ButtonLink } from 'src/components/ButtonLink';
import { Form } from 'src/components/Form';
import { Row } from 'src/components/Row/Row';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useEffectChange } from 'src/hooks/useEffectChange';
import * as EventService from 'src/services/EventService';
import * as GroupService from 'src/services/GroupService';
import * as TeamService from 'src/services/TeamService';
import { EventContext } from '../../EventContext';


export interface IFicticiousTeamCreation {
    currentGroupId: any
    onCancel: (object?: any) => void;
    onSave: (object?: any) => void;
}

export const FicticiousTeamCreation = ({ currentGroupId, onCancel, onSave }: IFicticiousTeamCreation) => {
    const { data: event } = React.useContext(EventContext);

    const form = React.useRef<any>({ groupIds: [] });

    const [events, setEvents] = React.useState<any>([]);
    const [eventCategories, setEventCategories] = React.useState<any>([]);
    const [groups, setGroups] = React.useState<any>([]);
    const [positionArr, setPositionArr] = React.useState<any>([]);
    const [isBestOf, setIsBestOf] = React.useState<boolean>(false);
    const [currentGroup, setCurrentGroup] = React.useState<number>(currentGroupId);
    

    useComponentDidMount(async () => {
        const [events] = await Promise.all([
            EventService.findByCompetitionUuid(event.competitionUuid)
        ]);
        await setEvents(events.data);
        await setEventUuid(event.id)
        setEventCategoryId(window.location.pathname.split('/')[5])
    })

    const setBestOfGroups = React.useCallback(async ({ value }) => {
        if (form.current.groupIds.includes(value)) {
            form.current.groupIds = form.current.groupIds.filter(v => v !== value).filter(v => v !== null);
        } else {
            form.current.groupIds.push(value);
        }
        if (form.current.groupIds.length > 0) {
            const numberOfTeams = (await Promise.all(form.current.groupIds.map(id => TeamService.findbyGroupId(id)))).map(r => r.data.length);
            setPositionArr(Array.from({ length: Math.max.apply(Math, numberOfTeams) }, (_, i) => i + 1))
        } else {
            setPositionArr([])
        }
    }, [])

    useEffectChange(() => {
        form.current.groupIds = groups.filter((g) => !g.isKnockout).map(g => g.id).filter(e => e !== null)
        groups.filter((g) => !g.isKnockout).forEach(element => {
            if (element) setBestOfGroups(element);
        });
    }, [isBestOf])

    const setName = React.useCallback((value) => {
        form.current.name = value
    }, [])

    const setEventUuid = React.useCallback(async (value) => {
        form.current.eventUuid = value;
        if (value) {
            const response = await EventService.findEventCategoriesforEvent(value);
            setEventCategories(response.data);
            setGroups([])
            setPositionArr([])
        } else {
            setEventCategories([]);
            setGroups([])
            setPositionArr([])
        }
    }, [])

    const setEventCategoryId = React.useCallback(async (value) => {
        form.current.eventCategoryId = value;
        if (value) {
            const response = await GroupService.getByCategoryId(value);
            setGroups(response.data.filter(res => !res.isKnockout))
            setPositionArr([])
        } else {
            setGroups([])
            setPositionArr([])
        }
    }, []);

    const setGroupId = React.useCallback(async ({ value }) => {
        form.current.groupId = value;
        if (value) {
            const response = await TeamService.findbyGroupId(value);
            setPositionArr(Array.from({ length: response.data.length }, (_, i) => i + 1))
        } else {
            setPositionArr([])
        }
    }, []);

    const setPosition = React.useCallback((value) => {
        form.current.position = value;
    }, [])

    const setBestOf = React.useCallback((value) => {
        form.current.bestOf = value;
    }, [])


    const getBestOfLabel = (position) => {
        if (position <= 5) {
            return t('admin.events.team.bestOfLabel.' + position, {position: position})
        }
        else {
            return t('admin.events.team.bestOfLabel.default', {position: position})
        }
    }
    

    return (
        <React.Fragment>
            <Form containerStyle={{ minHeight: 500 }}>
                <Row>
                    <Input
                        label={t('admin.events.team.name')}
                        onChange={setName}
                    />
                </Row>
                <Row>
                    <Checkbox label={t('admin.events.team.bestof')} onChange={(v) => setIsBestOf(v)} />
                </Row>
                <Row>
                    <Picker
                        label={t('admin.events.team.event')}
                        defaultValue={event.id}
                        items={events.map(({ name, id }) => ({ label: name, value: id }))}
                        onChange={setEventUuid}
                    />
                    <Picker
                        label={t('admin.events.team.category')}
                        defaultValue={window.location.pathname.split('/')[5]}
                        items={eventCategories.map(({ name, id }) => ({ label: name, value: id }))}
                        onChange={setEventCategoryId}
                    />
                </Row>

                <Row>
                    <CustomPicker
                        label={t('admin.events.team.group')}
                        items={groups.filter((g) => g.id !== currentGroup && !g.isKnockout).map(({ name, id }) => ({ label: name, value: id }))}
                        onChange={isBestOf ? setBestOfGroups : setGroupId}
                        multiple={isBestOf}
                        defaultValue={isBestOf ? groups.filter((g) => g.id !== currentGroup && !g.isKnockout).map(g => g.id) : null}
                        renderItem={(item) =>
                            <View>
                                <Text>{item.label}</Text>
                            </View>
                        }
                        renderLabel={(item) => <Text>{item?.label} </Text>}
                    />
                    {isBestOf &&
                        <Picker
                            label={t('admin.events.team.bestof')}
                            items={Array.from({ length: form.current.groupIds.length }, (_, i) => ({ label: getBestOfLabel(i+1), value: i + 1 }))}
                            onChange={setBestOf}
                        />
                    }
                    <Picker
                        label={t('admin.events.team.position')}
                        items={positionArr.map(p => ({ label: p, value: p }))}
                        onChange={setPosition}
                    />
                    

                </Row>
                    <View flexDirection='row' justifyContent='flex-end' style={{ gap: 10 }}>
                        <ButtonLink to={''} onPress={() => onCancel()} label={t('global.cancel')} />
                        <Button marginBottom={0} onPress={() => {
                            form.current.groupIds = form.current.groupIds.filter(a => a != null)
                            onSave(form.current)
                        }} label={t('global.save')} />
                    </View>
            </Form>

        </React.Fragment>
    )
}

