import * as React from 'react'
import { Button, Input, Picker, t, useComponentDidMount } from 'owc-react-lib-common';
import { Container } from 'src/components/Container';
import { View } from 'src/components/View';
import { Loader } from 'src/components/Loader';
import { useUserPermission } from 'src/hooks/useUserPermission';
import { ORGANIZER_READ_ONLY } from 'src/context/OWCOpenDmrContext';
import { useAlerts } from 'src/hooks/useAlerts';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from 'src/components/Title';
import { FloatingIconButton } from 'src/components/FloatingIconButton';
import { Form } from 'src/components/Form';
import { Row } from 'src/components/Row/Row';
import { Checkbox } from 'src/components/Checkbox';
import { ColorPicker } from 'src/components/ColorPicker';
import { IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import { ICategory, createCategoryEntity, findCategoryByUuid, updateCategoryEntity } from 'src/services/CategoryService';
import { GENDER_FEMALE, GENDER_MALE, GENDER_MIXED } from 'src/config';


const GENDERS = [
    { label: t("global.gender.female"), value: GENDER_FEMALE },
    { label: t("global.gender.male"), value: GENDER_MALE },
    { label: t("global.gender.mixed"), value: GENDER_MIXED },
  ];

export const CategoriesDetail = () => {
    const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
    const alerts = useAlerts();
    const navigate = useNavigate();
    const form = React.useRef<ICategory>({} as any);
    const { uuid } = useParams();
    const { validateAll } = useValidation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errors, setErrors] = React.useState<any>({});
    const [category, setCategory] = React.useState<ICategory>();

    useComponentDidMount(async () => {
        if (uuid) {
            try {
                const response = await findCategoryByUuid(uuid);
                setCategory(response);
                form.current = response;
            } catch (error) {
                alerts.createDSMError(error);
            }
        }


        setLoading(false);
    });

    const setName = React.useCallback((value: string) => (form.current.name = value), []);
    const setMinAge = React.useCallback((value: string) => (form.current.minAge = parseInt(value)), []);
    const setMaxAge = React.useCallback((value: string) => (form.current.maxAge = parseInt(value)), []);
    const setMaxPlayers = React.useCallback((value: string) => (form.current.maxPlayers = parseInt(value)), []);
    const setMaxCoaches = React.useCallback((value: string) => (form.current.maxCoaches = parseInt(value)), []);
    const setGender = React.useCallback((value: string) => (form.current.gender = value), []);
    const setMinPlayers = React.useCallback((value: string) => (form.current.minPlayers = parseInt(value)), []);
    const setVisibleRecords = React.useCallback((value: boolean) => (form.current.visibleRecords = value), []);
    const setWithMatchRecord = React.useCallback((value: boolean) => (form.current.withMatchRecord = value), []);
    const setAutomaticAssignationCalendarColor = React.useCallback((value: string) => (form.current.automaticAssignationCalendarColor = value), []);

    const validate = React.useCallback(() => {
        const fields: IValidationParams[] = [
            { name: "name", type: REQUIRED },
            { name: "gender", type: REQUIRED },
        ];
        const validated = validateAll(form.current, fields);
        setErrors(validated.errors);
        return !validated.hasErrors;
    }, [validateAll]);

    const createCategory = React.useCallback(async () => {
        if (!validate()) return;
        try {
            form.current.isOwbasket = true;
            await createCategoryEntity(form.current);
            alerts.create({ text: t("global.success"), type: "success" });
            navigate(`/organizer/categories`);
        } catch (error) {
            alerts.createDSMError(error);
        }
    }, [form]);

    const updateCategory = React.useCallback(async () => {
        try {
            form.current.isOwbasket = true;
            await updateCategoryEntity(form.current);
            alerts.create({ text: t("global.success"), type: "success" });
            navigate(`/organizer/categories`);
        } catch (error) {
            alerts.createDSMError(error);
        }
    }, [form])


    if (loading) {
        return <Loader />;
    }

    return (
        <Container title={t("admin.categories.detailTitle")}>
            <View flexDirection="row">
                {uuid ? (
                    <Title text={t('admin.categories.update')} />
                ) : (
                    <Title text={t('admin.categories.create')} />
                )}
                <FloatingIconButton
                    name="list"
                    title={t("admin.backToList")}
                    color="black"
                    backgroundColor="transparent"
                    onPress={() => navigate(`/organizer/categories`)}
                    right={10}
                />
            </View>

            <Form>
                <Row flex={[1, 3]}>
                    <Checkbox onChange={setWithMatchRecord} label={t("admin.categories.withMatchRecords")} defaultValue={category?.withMatchRecord} disabled={readOnly} />
                    <Checkbox onChange={setVisibleRecords} label={t("admin.categories.visibleRecords")} defaultValue={category?.visibleRecords} disabled={readOnly} />
                </Row>
                <Row flex={[3]}>
                    <Input error={errors.name} label={t("admin.categories.name")} onChange={setName} defaultValue={category?.name} disabled={readOnly} required />
                    <Input label={t("admin.categories.minAge")} onChange={setMinAge} defaultValue={category?.minAge} disabled={readOnly} />
                    <Input label={t("admin.categories.maxAge")} onChange={setMaxAge} defaultValue={category?.maxAge} disabled={readOnly} />
                </Row>
                <Row flex={[]}>
                    <Input label={t("admin.categories.maxPlayers")} onChange={setMaxPlayers} defaultValue={category?.maxPlayers} disabled={readOnly} />
                    <Input label={t("admin.categories.minPlayers")} onChange={setMinPlayers} defaultValue={category?.minPlayers} disabled={readOnly} />
                    <Input label={t("admin.categories.maxCoaches")} onChange={setMaxCoaches} defaultValue={category?.maxCoaches} disabled={readOnly} />
                </Row>
                <Row>
                    <Picker items={GENDERS} error={errors.gender} label={t("admin.categories.gender")} onChange={setGender} defaultValue={category?.gender} disabled={readOnly} required />
                    <ColorPicker label={t("admin.categories.color")} onChange={setAutomaticAssignationCalendarColor} defaultValue={category?.automaticAssignationCalendarColor} disabled={readOnly} />
                </Row>

                <Row>
                    {uuid ? (
                        <Button text={t("global.update")} onPress={updateCategory}  disabled={readOnly} />
                    ) : (
                        <Button text={t("global.create")} onPress={createCategory} disabled={readOnly} />
                    )}
                </Row>
            </Form>
        </Container>
    )
}
