import moment from 'moment';
import { Checkbox, Icon, Input, ModalComponent, t } from 'owc-react-lib-common';
import React from 'react';
import { StyleSheet } from "react-native";
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Calendar } from 'src/components/Calendar/Calendar';
import { ColorHelper } from 'src/components/Calendar/ColorHelper';
import { GroupSelector } from 'src/components/Calendar/GroupSelector';
import { Form } from 'src/components/Form';
import { IconButton } from 'src/components/IconButton';
import { InputDate } from 'src/components/InputDate';
import { Loader } from 'src/components/Loader';
import { MatchCard } from 'src/components/MatchCard';
import { Row } from 'src/components/Row/Row';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { ORGANIZER_READ_ONLY, OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useEffectChange } from 'src/hooks/useEffectChange';
import { OutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { useUserPermission } from "src/hooks/useUserPermission";
import * as CombinationTableService from 'src/services/CombinationTableService';
import * as CourtService from 'src/services/CourtService';
import * as EventService from 'src/services/EventService';
import * as MatchService from 'src/services/MatchService';
import * as TeamService from 'src/services/TeamService';
import * as ColorUtils from 'src/utils/ColorUtils';

const _ = require("lodash");

const REASON_REPEAT_COURT = "REASON_REPEAT_COURT";
const REASON_FIRST_PLACE_FOUND = "REASON_FIRST_PLACE_FOUND";
const REASON_NOT_ENOUGH_TIME_BETWEEN_GAMES = "REASON_NOT_ENOUGH_TIME_BETWEEN_GAMES";
const REASON_NOT_GOOD_COURT = "REASON_NOT_GOOD_COURT";
const REASON_NOT_ENOUGH_TIME_BEFORE_GAME = "REASON_NOT_ENOUGH_TIME_BEFORE_GAME";
const REASON_NOT_ENOUGH_TIME_AFTER_GAME = "REASON_NOT_ENOUGH_TIME_AFTER_GAME";
const REASON_SAME_HOUR = "REASON_SAME_HOUR";
const REASON_OUTSIDE_TIME_RANGE = "REASON_OUTSIDE_TIME_RANGE";

export const CalendarDetail = () => {
  const [slotTimes, setSlotTimes] = React.useState({
    slotDuration: '00:05:00',
    slotMinTime: '08:00:00',
    slotMaxTime: '24:00:00',
  });

  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { createDSMError } = useAlerts();
  const [externalEvents, setExternalEvents] = React.useState([]);
  const [groupedExternalEvents, setGroupedExternalEvents] = React.useState([]);
  const [calendarEvents, setCalendarEvents] = React.useState([]);
  const [courts, setCourts] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [calendarLoading, setCalendarLoading] = React.useState(false);
  const [colorHelper, setColorHelper] = React.useState(null);
  const { eventUuid } = useParams();
  const [searchParams] = useSearchParams({});
  const context = React.useContext(OWCOpenDmrContext);
  const [selected, setSelected] = React.useState<any>();
  // const generateTempId = () => '_' + Math.random().toString(36).substr(2, 9);
  const form = React.useRef<any>({
    groupIds: [],
    start: (searchParams.get('date') ?? new Date().toISOString()).substring(0, 19).replace('T', ' '),
    end: (searchParams.get('date') ?? new Date().toISOString()).substring(0, 19).replace('T', ' '),
    checkCourtLevel: true,
    checkRepeatCourt: true,
    checkNumberOfRests: true,
    minimumNumberOfRests: 2,
  });

  /**
   * 
   */
  useEffectChange(() => {
    loadData()
  }, []);
    
    
    
  const loadData = async () => {
    setExternalEvents([])
    setGroupedExternalEvents([])
    setCalendarEvents([])

    const [matches, teams, courts, ficticiousTeams] = await Promise.all([
      MatchService.getByEventUuid(eventUuid),
      TeamService.findTeamsByEvent(eventUuid),
      CourtService.getCourtsByEventUuid(eventUuid),
      TeamService.findFicticiousTeamsByEvent(eventUuid)
      // GroupTeamService.getByGroupUuid(groupUuid),
    ]);

    setLoading(false)

    // Set courts
    const resourcesCourts = courts.data.map((court) => {
      return {
        id: court.id,
        title: `${court.facilityName} - ${court.name} (${court.courtLevel})`,
        facilityName: court.facilityName,
        // Neccesary for the event custom style
        eventColor: 'transparent'
      }
    });
    // resourcesCourts.sort((a,b) => a.id - b.id);
    setCourts([...resourcesCourts]);

    const allTeams = [...teams.data, ...ficticiousTeams.data]
    setColorHelper(new ColorHelper(matches.data, allTeams));

    calculateSlotDurations(matches.data);
    // Set external events
    const matchExternalEvents = matches.data.map((match) => {
      if (match.courtId && match.matchDateHour) return null;
      const homeTeam = allTeams.find((team) => team.id === match.homeTeamId);
      const awayTeam = allTeams.find((team) => team.id === match.awayTeamId);

      const hours = Math.floor(match.matchTime / 60);
      const minutes = match.matchTime % 60;
      const duration = `${hours}:${minutes}`

      return {
        awayName: awayTeam?.name,
        awayId: awayTeam?.id,
        categoryName: homeTeam?.eventCategoryName,
        duration: duration,
        id: match.id,
        localName: homeTeam?.name,
        localId: homeTeam?.id,
        roundNumber: match.roundNumber,
        roundId: match.roundId,
        numPlayOff: match?.numPlayoff,
        matchGroupId: match.groupId,
        matchGroupName: match.groupName,
        errorAutomaticAssignation: match.errorAutomaticAssignation,
        categoryInitials: match.categoryInitials,
        // automaticAssignationCalendarColor: match.automaticAssignationCalendarColor,
        automaticAssignationCalendarColor: match.color,
        categoryColor: match.eventCategoryColor || match.categoryColor,
        errorAutomaticAssignationReason: match.errorAutomaticAssignationReason,
      }
    }).filter(r => r);

    setExternalEvents([...matchExternalEvents]);
    groupExternalEvents(matchExternalEvents);

    const matchCalendarEvents = matches.data.map((match) => {
      if (!match.courtId || !match.matchDateHour) return null;
      const homeTeam = allTeams.find((team) => team.id === match.homeTeamId);
      const awayTeam = allTeams.find((team) => team.id === match.awayTeamId);

      return {
        awayName: awayTeam.name,
        awayId: awayTeam.id,
        categoryName: homeTeam.eventCategoryName,
        id: match.id,
        duration: match.matchTime,
        localName: homeTeam.name,
        localId: homeTeam.id,
        roundNumber: match.roundNumber,
        roundId: match.roundId,
        matchGroupId: match.groupId,
        matchGroupName: match.groupName,
        numPlayOff: match?.numPlayoff,
        start: moment(match.matchDateHour).format('YYYY-MM-DD HH:mm'),
        end: moment(match.matchDateHour).add(match.matchTime, 'minutes').format('YYYY-MM-DD HH:mm'),
        resourceId: match.courtId,
        errorAutomaticAssignation: match.errorAutomaticAssignation,
        categoryInitials: match.categoryInitials,
        // automaticAssignationCalendarColor: match.automaticAssignationCalendarColor,
        automaticAssignationCalendarColor: match.color,
        categoryColor: match.eventCategoryColor || match.categoryColor,
        errorAutomaticAssignationReason: match.errorAutomaticAssignationReason,
      }
    }).filter(r => r);

    setCalendarEvents([...matchCalendarEvents]);
  }

  /**
   * 
   */
  const calculateSlotDurations = (matches) => {
    const min = Math.min(...matches.map(m => m.matchTime))

    if (min >= 60) setSlotTimes({ ...slotTimes, slotDuration: '00:20:00' })
    if (min > 30) setSlotTimes({ ...slotTimes, slotDuration: '00:10:00' })
    if (min <= 30) setSlotTimes({ ...slotTimes, slotDuration: '00:05:00' })
  }

  /**
   * 
   */
  const groupEvents = events => {
    // Group the matches first by category name then match group and for last round number
    events.forEach(e => {
      e.roundIdRef = e.roundId;
      if (e.numPlayOff) {
        e.roundIdRef = e.numPlayOff
      }
    })

    const groupedByCategory = _.groupBy(events, 'categoryName');
    Object.keys(groupedByCategory).forEach((key) => {
      groupedByCategory[key] = _.groupBy(groupedByCategory[key], 'matchGroupName');
      Object.keys(groupedByCategory[key]).forEach((key2) => {
        groupedByCategory[key][key2] = _.groupBy(groupedByCategory[key][key2], 'roundIdRef');
      })
    });
    const groupExternalEvents = Object.entries(groupedByCategory).map(([key, value]) => ({ [`${key}`]: value }))
    return [...groupExternalEvents]
  }

  /**
   * 
   */
  const groupExternalEvents = (events) => {
    const groupExternalEvents = groupEvents(events);
    setGroupedExternalEvents([...groupExternalEvents]);
    return [...groupExternalEvents]
  }

  /**
   * 
   */
  const updateMatch = async (e) => {
    try {
      const payload = {
        id: Number.parseInt(e.event.id),
        awayTeam: Number.parseInt(e.event.extendedProps.awayId),
        homeTeam: Number.parseInt(e.event.extendedProps.localId),
        roundNumber: Number.parseInt(e.event.extendedProps.roundNumber),
        roundId: Number.parseInt(e.event.extendedProps.roundId),
        gameDate: moment(e.event.start, "DD/MM/YYYY hh:mm").format("YYYY-MM-DDTHH:mm:ss"),
        courtId: !e.newResource ? Number.parseInt(e.event.getResources()[0].id) : Number.parseInt(e.newResource.id),
        groupId: Number.parseInt(e.event.extendedProps.matchGroupId),
      }

      await CombinationTableService.updateMatch(payload);

      const event = calendarEvents.find(e => e.id === payload.id);

      if (!event) {
        return;
      }
      
      event.start = moment(e.event.start).format('YYYY-MM-DD HH:mm');
      // event.end = moment(e.event.start).add(15, 'minutes').format('YYYY-MM-DD HH:mm');
      event.resourceId = payload.courtId;
      event.errorAutomaticAssignation = false;
      event.errorAutomaticAssignationReason = '';
  }
  catch(error) {
    createDSMError(error);
    setTimeout(() => window.location.reload(), 2000);
  }
}

  /**
   * 
   */
  const deleteMatch = async (e) => {

    try {
      const payload = {
        id: Number.parseInt(e.event.id),
        awayTeam: Number.parseInt(e.event.extendedProps.awayId),
        homeTeam: Number.parseInt(e.event.extendedProps.localId),
        roundNumber: Number.parseInt(e.event.extendedProps.roundNumber),
        roundId: Number.parseInt(e.event.extendedProps.roundId),
        gameDate: null,
        courtId: null,
        groupId: Number.parseInt(e.event.extendedProps.matchGroupId),
      }


      await CombinationTableService.updateMatch(payload);

      setCalendarEvents([...calendarEvents.filter(e => e.id !== payload.id)]);
      setExternalEvents([...externalEvents, ...calendarEvents.filter(e => e.id === payload.id)]);
      groupExternalEvents([...externalEvents, ...calendarEvents.filter(e => e.id === payload.id)]);
    }
    catch(error) {
      createDSMError(error);
      setTimeout(() => window.location.reload(), 2000);
    }
  }

  /**
   * 
   */
  const eventAdd = async (e) => {

    try {
      const payload = {
        id: e.id,
        awayTeam: e.awayId,
        homeTeam: e.localId,
        roundNumber: e.roundNumber,
        roundId: e.roundNumber,
        gameDate: moment(e.start).format("YYYY-MM-DDTHH:mm:ss"),
        courtId: Number.parseInt(e.resourceId),
        groupId: Number.parseInt(e.matchGroupId),
      }


      await CombinationTableService.updateMatch(payload);
      externalEvents.find(e => e.id === payload.id).start = moment(e.start).format('YYYY-MM-DD HH:mm');
      // externalEvents.find(e => e.id === payload.id).end = moment(e.start).add(15, 'minutes').format('YYYY-MM-DD HH:mm');
      externalEvents.find(e => e.id === payload.id).resourceId = payload.courtId;

      setCalendarEvents([...calendarEvents, ...externalEvents.filter(e => e.id === payload.id)]);
      setExternalEvents([...externalEvents.filter(e => e.id !== payload.id)]);
      groupExternalEvents([...externalEvents.filter(e => e.id !== payload.id)]);
    }
    catch(error) {
      createDSMError(error);
      setTimeout(() => window.location.reload(), 2000);
    }
  }

  /**
   * 
   */
  const eventContent = React.useCallback((event) => {
    if (!event.event.extendedProps.categoryName) {
      return
    }

    // const hex = colorHelper.getGroupColorByKey(event.event.extendedProps.categoryName, event.event.extendedProps.matchGroupName)
    const color = event.event.extendedProps.automaticAssignationCalendarColor;
    const errorAutomaticAssignation = (event.event.extendedProps.errorAutomaticAssignation + '') === "true";

    return (
      <View style={{ overflow: 'hidden', padding: 3, backgroundColor: color, borderStyle: 'solid', borderWidth: 3, borderColor: errorAutomaticAssignation ? 'red' : 'transparent', borderRadius: 5, height: '100%', justifyContent: 'center' }}>
        {event?.draggedEl?.dataset?.id ? (
          <MatchCard localName={event?.draggedEl?.dataset.localName} awayName={event?.draggedEl?.dataset.awayName} categoryName={event?.draggedEl?.dataset.categoryInitials} matchGroupId={event?.draggedEl?.dataset.matchGroupName} color={ColorUtils.isDarkColor(color) ? '#eee' : '#333'} />
        ) : (
          <MatchCard time={event.timeText.split(' - ')[0]} localName={event.event.extendedProps.localName} awayName={event.event.extendedProps.awayName} categoryName={event.event.extendedProps.categoryInitials} matchGroupId={event.event.extendedProps.matchGroupName} color={ColorUtils.isDarkColor(color) ? '#eee' : '#333'} />
        )}

        {errorAutomaticAssignation && (
          <View style={styles.selectedErrorRibbon}>
            <Text uppercase bold color='white'>{t('calendar.error')}</Text>
          </View>
        )}
      </View>
    )
  }, [])



  const showCalendar = React.useCallback(() => {
  
    return (
        <Calendar
                slotDuration={slotTimes.slotDuration}
                slotMinTime={slotTimes.slotMinTime}
                slotMaxTime={slotTimes.slotMaxTime}
                eventContent={eventContent}
                customButtons={customButtons}
                externalEvents={externalEvents}
                groupedExternalEvents={groupedExternalEvents}
                calendarEvents={calendarEvents}
                resources={courts}
                eventDrop={updateMatch}
                eventRemove={deleteMatch}
                eventAdd={eventAdd}
                groupExternalEvents={groupExternalEvents}
                colorHelper={colorHelper}
                editable={!readOnly}
                eventClick={({ event: { extendedProps } }: any) => { 
                  setSelected({ ...extendedProps, errorAutomaticAssignation: (extendedProps.errorAutomaticAssignation + '' === 'true'), color: ColorUtils.isDarkColor(extendedProps.automaticAssignationCalendarColor) ? 'white' : '#333' })
                }}
              />
    )
  }, [calendarEvents])

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const customButtons = {
    openModalButton: {
      icon: !openModal ? 'plus-square' : 'minus-square',
      hint: t('calendar.openModal'),
      click: () => setOpenModal(!openModal),
    }
  }

  const errors = {
    [REASON_REPEAT_COURT]: t('calendar.errors.repeatedCourt'),
    [REASON_FIRST_PLACE_FOUND]: t('calendar.errors.unableToFindASpot'),
    [REASON_NOT_GOOD_COURT]: t('calendar.errors.courtLevelTooLow'),
    [REASON_NOT_ENOUGH_TIME_BETWEEN_GAMES]: t('calendar.errors.timeBetweenGames'),
    [REASON_NOT_ENOUGH_TIME_BEFORE_GAME]: t('calendar.errors.notEnoughTimeBeforeGame'),
    [REASON_NOT_ENOUGH_TIME_AFTER_GAME]: t('calendar.errors.notEnoughTimeAfterGame'),
    [REASON_SAME_HOUR]: t('calendar.errors.sameHour'),
    [REASON_OUTSIDE_TIME_RANGE]: t('calendar.errors.outisdeTimeRange'),
  };

  return (
    <View style={[openModal ? styles.container : {}]}>
      <View flexDirection='row'>
        <IconButton containerStyle={{ maxWidth: 30 }} backgroundColor={"transparent"} color={context.configuration.colors.primary.main} name={'wand'} onPress={() => setModalVisible(true)} />
        <IconButton containerStyle={{ maxWidth: 30 }} backgroundColor={"transparent"} color={context.configuration.colors.primary.main} name={'download'} onPress={() => EventService.downloadCalendar(eventUuid)} />
      </View>

      <ModalComponent header={false} minWidth={'70vw'} visible={modalVisible} close={() => setModalVisible(false)}>
        {calendarLoading ? (
          <View>
            <Text bold textAlign='center' fontSize={20} style={{ padding: 50 }} >{t('calendar.generating')}</Text>
            <Loader color={context.configuration.colors.primary.main} />
          </View>
        ) : (
          <Form>
            <Row flex={[1, 1]}>
              <InputDate label={t('calendar.startDate')} useTime defaultValue={form.current.start} internalFormat='YYYY-MM-DD HH:mm:ss' onChange={(v) => form.current.start = v} />
              <InputDate label={t('calendar.endDate')} useTime defaultValue={form.current.end} internalFormat='YYYY-MM-DD HH:mm:ss' onChange={(v) => form.current.end = v} />
            </Row>

            <View>
              <Checkbox label={t('calendar.options.checkCourtLevel')} defaultValue={form.current.checkCourtLevel} onChange={(value: boolean) => form.current.checkCourtLevel = value} />
              <Checkbox label={t('calendar.options.checkRepeatCourt')} defaultValue={form.current.checkRepeatCourt} onChange={(value: boolean) => form.current.checkRepeatCourt = value} />
              <Checkbox label={t('calendar.options.checkNumberOfRests')} defaultValue={form.current.checkNumberOfRests} onChange={(value: boolean) => form.current.checkNumberOfRests = value} containerStyle={{ flex: 1 }} />
              <Input label={t('calendar.options.minimumNumberOfRests')} defaultValue={form.current.minimumNumberOfRests} onChange={(value: string) => form.current.minimumNumberOfRests = value} />
            </View>

            <Row rowStyle={{ overflow: 'scroll', marginBottom: 15 }}>
              <GroupSelector
                groupedExternalEvents={groupEvents([...calendarEvents, ...externalEvents])}
                onRemove={(groupId) => form.current.groupIds = form.current.groupIds.filter(id => id !== groupId)}
                onAdd={(groupId) => {
                  if (!form.current.groupIds) form.current.groupIds = [];
                  form.current.groupIds.push(groupId);
                }}
              />
            </Row>

            <Row>
              <Button label={t('calendar.generate')} marginBottom={0} onPress={async () => {
                setCalendarLoading(true)

                await MatchService.initializeHoursAndCourts({
                  ...form.current,
                  minimumNumberOfRests: form.current.minimumNumberOfRests,
                });

                window.location.href = window.location.href + ''
              }} />
            </Row>
          </Form>
        )}
      </ModalComponent>

      {showCalendar()}

      {!!selected && (
        <OutsideAlerter handleClick={() => setSelected(undefined)}>
          <View style={[styles.selected, { backgroundColor: selected.automaticAssignationCalendarColor, borderColor: selected.color }, !!selected.errorAutomaticAssignation && { borderColor: 'red' }]}>
            <Text color={selected.color} uppercase><Text bold color={selected.color}>{t('calendar.category')}:</Text> {selected.categoryName}</Text>
            <Text color={selected.color} uppercase><Text bold color={selected.color}>{t('calendar.group')}:</Text> {selected.matchGroupName}</Text>
            <Text color={selected.color} uppercase><Text bold color={selected.color}>{t('calendar.round')}:</Text> {selected.roundNumber}</Text>
            <Text color={selected.color} uppercase><Text bold color={selected.color}>{t('calendar.localTeam')}:</Text> {selected.localName}</Text>
            <Text color={selected.color} uppercase><Text bold color={selected.color}>{t('calendar.awayTeam')}:</Text> {selected.awayName}</Text>

            {selected.errorAutomaticAssignationReason && <Text color={selected.color} uppercase style={{ marginTop: 15 }}><Text bold color={selected.color}>{t('calendar.error')}:</Text> {errors[selected.errorAutomaticAssignationReason]}</Text>}

            {selected.errorAutomaticAssignation && (
              <View style={styles.selectedErrorRibbon}>
                <Text uppercase bold color='white'>{t('calendar.error')}</Text>
              </View>
            )}

            <Icon type='close' onPress={() => setSelected(undefined)} containerStyle={styles.closeSelected} color={selected.color} />
          </View>
        </OutsideAlerter>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: ('fixed' as any),
    top: 80,
    left: 0,
    bottom: 10,
    paddingBottom: 10,
    width: '100vw',
    overflow: 'hidden',
    height: '92vh',
    backgroundColor: '#FFF',
  },
  selected: {
    position: 'fixed' as any,
    width: 500,
    left: 5,
    bottom: 5,
    borderRadius: 5,
    borderWidth: 2,
    // backgroundColor: 'white',
    padding: 15,
    overflow: 'hidden',
  },
  selectedErrorRibbon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 200,
    height: 30,
    backgroundColor: 'rgba(255, 0, 0, .8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: [
      { translateX: 100 },
      { translateY: -15 },
      { rotate: '45deg' },
      { translateY: 50 },
    ],
  },
  closeSelected: {
    position: 'absolute',
    top: 5,
    right: 0,
  },
});