import dayjs from 'dayjs';
import moment from 'moment';
import { Input, Picker, t } from 'owc-react-lib-common';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox';
import { Form } from 'src/components/Form';
import { InputDate } from 'src/components/InputDate';
import { RadioGroup } from 'src/components/RadioGroup';
import { Row } from 'src/components/Row/Row';
import { Stepper } from "src/components/Stepper";
import { Text } from 'src/components/Text';
import { View } from "src/components/View";
import { LeagueStepperTeams } from 'src/components/draggable-list/LeagueStepperTeams';
import { useAlerts } from 'src/hooks/useAlerts';
import { IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import * as CombinationTableService from 'src/services/CombinationTableService';
import { Combination } from './Combination';
import { KnockOff } from './KnockOff';
import { ManualLeague } from './ManualLeague';
import { OneRound } from './OneRound';

const COLORS = [
  { id: 1, hex: '#bb4430' },
  { id: 2, hex: '#231f20' },
  { id: 3, hex: '#7ebdc2' },
  { id: 4, hex: '#f3dfa2' },
  { id: 5, hex: '#efe6dd' },
  { id: 6, hex: '#aed4e6' },
  { id: 7, hex: '#1ac8ed' },
  { id: 8, hex: '#af7595' },
  { id: 9, hex: '#5c1a1b' },
  { id: 10, hex: '#00b295' },
  { id: 11, hex: '#d926ff' },
  { id: 12, hex: '#d96638' },
  { id: 13, hex: '#c9ddff' },
  { id: 14, hex: '#2cf6b3' },
  { id: 15, hex: '#fff05a' },
  { id: 16, hex: '#ff785a' }

]

const ROUND_NUMBER_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 }
]

const COMBINATION_TABLES = [
  { id: 1, numTeams: 4, thirdPlace: false },
  { id: 2, numTeams: 4, thirdPlace: true },
  { id: 3, numTeams: 8, thirdPlace: false },
  { id: 4, numTeams: 8, thirdPlace: true },
  { id: 5, numTeams: 16, thirdPlace: false },
  { id: 6, numTeams: 16, thirdPlace: true },
]

enum COMPETITION_SYSTEM {
  LEAGUE = "1",
  KNOCKOFF = "2",
  MANUAL_LEAGUE = "3",
  ONE_ROUND = "4"
}

interface ILeagueStepper {
  teams: any,
  groupId: any,
  onGenerate: (value?: boolean) => void;
  onChange: (value: number, increase: boolean) => void;
  // renderItemContent: (element: any) => any
}

export const LeagueStepper = ({ teams, groupId, onGenerate, onChange }: ILeagueStepper) => {

  const [matchTable, setMatchTable] = React.useState([]);
  const [positionTable, setPositionTable] = React.useState([]);
  const [groupTeams, setGroupTeams] = React.useState([])
  const [knockOff, setKnockOff] = React.useState<any>({})
  const [selected, setSelected] = React.useState(0)
  const [step, setStep] = React.useState(0)
  const [bestOf, setBestOf] = React.useState([]);

  const form = React.useRef<any>({ thirdPlace: false });
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();

  const setBestOfForm = React.useCallback((value: any) => form.current.bestOf = value, []);
  const setCompetitionSystem = React.useCallback((value: string) => form.current.competitionSystem = value, []);
  const setHomeAwayLegs = React.useCallback((value: string) => form.current.roundNumber = value, []);
  const setNumberOfTeams = React.useCallback((value: string) => form.current.teamsInGroup = value, [])
  const setStartDate = React.useCallback((value: string) => form.current.firstRoundDate = value, []);
  const setPeriodicityQuantity = React.useCallback((value: string) => form.current.repeat = value, []);
  const setPeriodicityTime = React.useCallback((value: string) => form.current.repeatType = value, []);

  React.useEffect(() => setGroupTeams(teams.sort((a, b) => a.position - b.position)), [teams])

  const alerts = useAlerts();


  const CompetitionSystemFields: IValidationParams[] = [
    { name: "competitionSystem", type: REQUIRED }
  ];

  const tournamentFields: IValidationParams[] = [
    { name: "bestOf", type: REQUIRED },
    { name: "teamsInGroup", type: REQUIRED }
  ];

  const leagueFields: IValidationParams[] = [
    { name: "roundNumber", type: REQUIRED },
    { name: "firstRoundDate", type: REQUIRED },
    { name: "repeat", type: REQUIRED },
    { name: "repeatType", type: REQUIRED },
  ];

  const oneRoundFields: IValidationParams[] = [
    { name: "firstRoundDate", type: REQUIRED }
  ]

  const validate = React.useCallback((formRequiredFields: IValidationParams[]) => {
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  const REPEAT_TYPE = [
    { label: t('admin.events.league.repeatTimeDaily'), value: 1 },
    { label: t('admin.events.league.repeatTimeHourly'), value: 2 },
    { label: t('admin.events.league.repeatTimeMinutes'), value: 3 },
  ]

  const stepperHeaders = [
    { label: t('admin.events.league.options'), value: t('admin.events.league.options') },
    { label: t('admin.events.league.config'), value: t('admin.events.league.config') },
    { label: t('admin.events.league.calendar'), value: t('admin.events.league.calendar') },
  ]

  const updateRound = (newDate, round) => round.startDate = newDate;

  const getHex = (id) => COLORS.find(e => e.id === id)?.hex ?? '#FFFFFF'

  const createLeague = async () => {
    if (!validate(leagueFields)) return;
    try {
      const matches = await CombinationTableService.generate(
        groupId,
        form.current.roundNumber,
        true,
        form.current.firstRoundDate,
        form.current.repeatType,
        form.current.repeat,
        // form.current.teamsInGroup
        teams.length
      );
      setMatchTable(matches.data);
      generatePositionTable(matches.data);
      setSelected(selected + 1);
      setStep(selected + 1)
    } catch (error) {
      alerts.createDSMError(error);
    }

  }

  const saveLeague = React.useCallback(async (rounds) => {
    const payload = {
      matches: [],
      rounds: [...rounds ?? matchTable]
    };
    payload.rounds.forEach(round => {
      round.matches.forEach(match => {
        const homeTeamId = groupTeams.find(team => parseInt(team.id) === parseInt(match.homeTeam))
        const awayTeamId = groupTeams.find(team => parseInt(team.id) === parseInt(match.awayTeam))
        const formattedDate = moment(match.gameDate, "DD/MM/YYYY hh:mm").format("YYYY-MM-DDTHH:mm:ss");
        match.gameDate = match.gameDate ? formattedDate : null;

        if (match.gameDate === 'Invalid Date')
          match.gameDate = null;

        if (awayTeamId?.id && homeTeamId?.id) {
          payload.matches.push({
            awayTeam: awayTeamId?.id ?? null,
            courtId: null,
            gameDate: match.gameDate,
            homeTeam: homeTeamId?.id ?? null,
            roundNumber: round.roundNumber
          })
        }

      })
    })
    try {
      await CombinationTableService.saveCalendar(payload);
      onGenerate(true);
    } catch (error) {
      alerts.createDSMError(error);
    }

  }, [alerts, groupTeams, matchTable, onGenerate])

  const saveKnockOff = async () => {
    const payload = {
      ...knockOff
    }
    try {
      await CombinationTableService.saveKnockout(payload);
      onGenerate(true);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }

  const regroupWhenTeamChangesPosition = (teamId1: number, increase: boolean) => {
    const teamPosition1 = groupTeams.find(gt => gt.id === teamId1).position;
    const teamName1 = groupTeams.find(gt => gt.id === teamId1).name;
    const teamId2 = groupTeams[increase ? teamPosition1 : teamPosition1 - 2].id;
    const teamName2 = groupTeams[increase ? teamPosition1 : teamPosition1 - 2].name;
    if (form.current.competitionSystem === COMPETITION_SYSTEM.KNOCKOFF) {
      const newCombinations = knockOff.knockoutCombinations.map(combination => {
        
        if(combination.firstTeamId == teamId1){
          combination.firstTeamId = teamId2;  
          combination.firstTeamName = teamName2;  
        }else if(combination.firstTeamId == teamId2){
          combination.firstTeamId = teamId1;
          combination.firstTeamName = teamName1;  
        }

        if(combination.secondTeamId == teamId1){
          combination.secondTeamId = teamId2
          combination.secondTeamName = teamName2;  
        }else if(combination.secondTeamId == teamId2){
          combination.secondTeamId = teamId1;
          combination.secondTeamName = teamName1;  

        }

        return combination;
      })
      setKnockOff({...knockOff,knockoutCombinations: newCombinations})
    } else {
      const newMatchTable = matchTable.map(round => ({
        ...round, matches: round.matches.map(match => {
          if (match.homeTeam === teamId1) {
            match.homeTeam = teamId2
          } else if (match.homeTeam === teamId2) {
            match.homeTeam = teamId1
          }

          if (match.awayTeam === teamId1) {
            match.awayTeam = teamId2
          } else if (match.awayTeam === teamId2) {
            match.awayTeam = teamId1
          }

          return match;
        })
      }));

      setMatchTable(newMatchTable)
    }



    onChange(teamId1, increase);
  }

  const renderTeams = () => {
    groupTeams.forEach(element => {
      element.bgColor = getHex(element.position);
    });
    return (
      <LeagueStepperTeams
        data={groupTeams}
        colors={COLORS}
        onChange={regroupWhenTeamChangesPosition}
      />
    )
  }

  const generatePositionTable = React.useCallback((league: any) => {
    setPositionTable(league.map((round: any) => ({
      roundNumber: round.roundNumber,
      matches: [...round.matches.map((match: any, i: number) => {
        const homeTeam = groupTeams.find(gt => gt.id === match.homeTeam);
        const awayTeam = groupTeams.find(gt => gt.id === match.awayTeam);
        return {
          homeTeam: { position: homeTeam.position, bgColor: homeTeam.bgColor, },
          awayTeam: { position: awayTeam.position, bgColor: awayTeam.bgColor, }
        }
      })
      ],
    })));
  }, [groupTeams])

  const renderCalendarForm = () => {
    return (
      <Form>
        <Row>
          <Text bold fontSize={15} style={{ paddingVertical: 5 }}>{t('admin.events.league.calendarFormat')}</Text>
        </Row>
        <Row>
          <Picker
            required
            error={errors.roundNumber}
            label={t('admin.events.league.numberOfRound')}
            items={ROUND_NUMBER_OPTIONS}
            onChange={setHomeAwayLegs}
          />
        </Row>
        <Row>
          <Text bold fontSize={15} style={{ paddingVertical: 5 }}>{t('admin.events.league.dateConfiguration')}</Text>
        </Row>
        <Row>
          <InputDate
            required
            error={errors.firstRoundDate}
            label={t('admin.events.league.startDateFirstRound')}
            onChange={setStartDate}
            useTime
            internalFormat='ISOString'
          />
          <Input
            required
            error={errors.repeat}
            label={t('admin.events.league.roundRepeatTime')}
            onChange={setPeriodicityQuantity} />
          <Picker
            label='&nbsp;'
            error={errors.repeatType}
            items={REPEAT_TYPE}
            onChange={setPeriodicityTime}
          />
        </Row>
        <Row>
          <Button
            onPress={createLeague}
            label={t('admin.events.league.nextStep')}
          />
        </Row>
      </Form>
    )
  }

  const renderKnockOffForm = () => {
    return (
      <Form>
        <Row>
          <Picker
            required
            error={errors.bestOf}
            label={t('admin.events.league.bestOf')}
            items={[{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]}
            onChange={(value) => {
              const bestOfArr = []
              for (let i = 0; i < value; i++) {
                bestOfArr.push(((i + 1) % 2 === 0) ? 'V' : 'L');
              }
              setBestOf(bestOfArr)
              setBestOfForm(bestOfArr)
              form.current.gamesOrder = bestOfArr;
            }}
          />
          <Picker
            error={errors.teamsInGroup}
            required
            items={[
              { label: t('admin.events.league.final'), value: 2 },
              { label: t('admin.events.league.semi'), value: 4 },
              { label: t('admin.events.league.quarter'), value: 8 },
              { label: t('admin.events.league.eighth'), value: 16 },
              { label: t('admin.events.league.sixteenh'), value: 16 },
              { label: t('admin.events.league.thirysecons'), value: 32 },
            ]}
            label={t('admin.events.league.numberOfTeams')}
            onChange={setNumberOfTeams}
          />
        </Row>
        <Row>
          <View flexDirection='row'>
            {
              bestOf.map((e, i) => {
                return (
                  <RadioGroup
                    label={`${t('admin.events.calendar.round')} ${i + 1}`}
                    key={i}
                    value={e}
                    items={[
                      { label: 'L', value: 'L' },
                      { label: 'V', value: 'V' },
                    ]}
                    onChange={(value) => {
                      bestOf[i] = value;
                      form.current.gamesOrder[i] = value;
                    }}
                  />
                )
              })
            }
          </View>
          <Checkbox label={t('admin.events.league.thirdPlace')} onChange={(value) => { form.current.thirdPlace = value }} />
        </Row>
        <Row>
          <Button
            label={t('admin.events.knockoff.generate')}
            onPress={async () => {
              if (!validate(tournamentFields)) return;
              // const notFicticiousTeams = teams.filter(t => !t.isFicticious)
              const combinationTableId = COMBINATION_TABLES.find((ct) => {
                return ct.numTeams === teams.length && ct.thirdPlace === form.current.thirdPlace
              })?.id;

              const payload = {
                ...form.current,
                combinationTableId: combinationTableId,
                groupId: groupId,
              }

              try {
                const response = await CombinationTableService.generateKnockout(payload);
                setKnockOff(response.data)
                setSelected(selected + 1);
                setStep(selected + 1);

              } catch (error) {
                alerts.createDSMError(error);
              }

            }}
          />
        </Row>
      </Form>
    )
  }

  const renderOneRoundForm = () => {
    return (
      <Form>
        <Row>
          <InputDate
            required
            error={errors.firstRoundDate}
            label={t('admin.events.league.startDateFirstRound')}
            onChange={setStartDate}
            useTime
            internalFormat='ISOString'
          />
        </Row>
        <Row>
          <Button
            onPress={() => {
              if (!validate(oneRoundFields)) return;
              setSelected(selected + 1);
              setStep(selected + 1);
            }}
            label={t('admin.events.league.nextStep')}
          />
        </Row>
      </Form>
    )
  }

  return (
    <View style={[styles.container]}>
      <Stepper headers={stepperHeaders} step={step} selected={selected} onPress={setSelected}>
        <View>
          <Picker
            label={t('admin.events.league.competitionSystem')}
            required
            error={errors.competitionSystem}
            defaultOptionLabel={t('admin.events.league.selectOption')}
            items={[
              { label: t('admin.events.league.league'), value: COMPETITION_SYSTEM.LEAGUE },
              { label: t('admin.events.league.manualLeague'), value: COMPETITION_SYSTEM.MANUAL_LEAGUE },
              { label: t('admin.events.league.oneRound'), value: COMPETITION_SYSTEM.ONE_ROUND },
              { label: t('admin.events.league.knockoff'), value: COMPETITION_SYSTEM.KNOCKOFF }
            ]}
            onChange={(value) => {
              setCompetitionSystem(value)
            }} />

          <Button onPress={() => {
            if (!validate(CompetitionSystemFields)) return;
            setSelected(selected + 1);
            setStep(selected + 1);
          }} label={t('admin.events.league.nextStep')}></Button>

        </View>
        <View>
          {(form.current.competitionSystem === COMPETITION_SYSTEM.LEAGUE) && renderCalendarForm()}
          {(form.current.competitionSystem === COMPETITION_SYSTEM.MANUAL_LEAGUE) && <ManualLeague teams={teams} groupId={groupId} onSave={(rounds) => {
            setMatchTable(rounds);
            saveLeague(rounds)
          }}
          />}
          {(form.current.competitionSystem === COMPETITION_SYSTEM.ONE_ROUND) && renderOneRoundForm()}
          {(form.current.competitionSystem === COMPETITION_SYSTEM.KNOCKOFF) && renderKnockOffForm()}
        </View>

        <View style={{ height: '100%' }}>
          <View justifyContent='center'>{renderTeams()}</View>

          {form.current.competitionSystem === COMPETITION_SYSTEM.LEAGUE && (
            <React.Fragment>
              <View flexDirection='row' style={{ marginBottom: 20 }}>
                <View flexDirection='row' style={[{ width: '100%', flexWrap: 'wrap', gap: 20 }]}>
                  <Combination matchTable={matchTable} groupTeams={groupTeams} positionTable={positionTable} updateRound={updateRound} />
                </View>
              </View>

              <Button onPress={saveLeague} label={t('admin.events.league.saveLeague')} />
            </React.Fragment>
          )}

          {(form.current.competitionSystem === COMPETITION_SYSTEM.ONE_ROUND) && <OneRound groupId={groupId} groupTeams={groupTeams} updateRound={updateRound} onSave={(rounds) => {
            rounds[0].startDate = dayjs(form.current.firstRoundDate).format("YYYY-MM-DD mm:HH:ss");
            setMatchTable(rounds);
            saveLeague(rounds)
          }} />}

          {form.current.competitionSystem === COMPETITION_SYSTEM.KNOCKOFF && knockOff.knockoutCombinations && (
            <React.Fragment>
              <View style={{ marginBottom: 20 }}>
                <KnockOff data={knockOff.knockoutCombinations} hasThirdPlace={form.current.thirdPlace} teams={teams} courts={[]} />
              </View>

              <Button onPress={saveKnockOff} label={t('admin.events.knockoff.saveKnockOff')} />
            </React.Fragment>
          )}
        </View>
      </Stepper>
    </View>
  );

}
const styles = StyleSheet.create({
  container: {

  },
  renderContainer: {
    display: 'flex',
    padding: 20,
    gap: 50,
  },
  combination: {
    borderColor: '#dbdbdb',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
    padding: 8,
    display: 'flex',
    shadowColor: 'black',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    width: 350

  },
  combinationContainer: {
    display: 'flex',
    // gap: 30,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // flexShrink: 1,
  },
  colorBox: {
    padding: 6,
    paddingHorizontal: 12,
    // marginVertical: 10,
    color: 'white',
    fontWeight: '800',
    borderRadius: 5
  },
  teams: {
    width: '100%',
    padding: 10,
    shadowColor: 'black',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0,
    shadowRadius: 3,
    borderColor: '#dbdbdb',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  teamsContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  gap: {
    display: 'flex',
    gap: 50
  }
});
