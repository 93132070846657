import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * 
 * @param key 
 * @returns 
 */
export const getString = (key: string) => AsyncStorage.getItem(key);

/**
 * 
 * @param key 
 * @param value 
 * @returns 
 */
export const setString = (key: string, value: string) => AsyncStorage.setItem(key, value);

/**
 * 
 * @param key 
 * @returns 
 */
export const get = async (key: string) => {
    const item = await AsyncStorage.getItem(key);

    if (item === null) {
        return null;
    }

    return JSON.parse(item);
}

/**
 * 
 * @param key 
 * @param value 
 * @returns 
 */
export const set = (key: string, value: any) => {
    return AsyncStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
}

/**
 * jwt
 */
export const setJwt = (jwt: string) => {
    setString('jwt', jwt);
}

export const getJwt = () => {
    return getString('jwt');
}

/**
 * language
 */
export const setLanguage = (code: string) => {
    setString('language', code);
}

export const getLanguage = () => {
    return getString('language');
}

/**
 * redirect
 */
export const setRedirect = (redirect: string) => {
    setString('redirect', redirect)
}

export const getRedirect = () => {
    return getString('redirect');
}