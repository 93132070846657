import dayjs from "dayjs";
import { Button, Icon, Input, Modal, ModalConfirm, Picker, t } from "owc-react-lib-common";
import React from 'react';
import { StyleSheet } from "react-native";
import { Checkbox } from "src/components/Checkbox";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { Form } from "src/components/Form";
import { ImageRenderer } from "src/components/ImageRenderer";
import { InputDate } from "src/components/InputDate";
import { Loader } from 'src/components/Loader';
import { Row } from "src/components/Row/Row";
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from "src/components/Text";
import { Title } from 'src/components/Title';
import { View } from "src/components/View";
import { MODALITY_3x3 } from "src/config";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useUserPermission } from "src/hooks/useUserPermission";
import * as CourtService from "src/services/CourtService";
import * as MatchCallService from "src/services/MatchCallService";
import * as MatchResultService from "src/services/MatchResultService";
import * as MatchService from "src/services/MatchService";
import * as StringUtils from 'src/utils/StringUtils';
import { EventContext } from '../EventContext';

export const MatchesList = () => {
  const [loading, setLoading] = React.useState(true);
  const { create: createAlert, createDSMError } = useAlerts();
  const { data: event } = React.useContext(EventContext);
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const form = React.useRef<any[]>();
  const inputRefs = React.useRef<any>({});
  const [matchToSanction, setMatchToSanction] = React.useState<any>(null);
  const [matchToUnsanction, setMatchToUnsanction] = React.useState<any>(null);
  const [matchCallIdToUpdate, setMatchCallIdToUpdate] = React.useState<number | undefined>(null);
  const [courts, setCourts] = React.useState<any[]>();
  const [courtSelected, setCourtSelected] = React.useState<any>(null);
  const isModality3x3 = event.modalityId === MODALITY_3x3;
  const [matchRecordModal, setMatchRecordModal] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const [response, courtResponse] = await Promise.all([
        MatchService.getByEventId(event.id),
        CourtService.getCourtsByEventUuid(event.uuid),
      ]);

      form.current = response.data;
      setCourts(courtResponse.data);
    } catch (error) {
      createDSMError(error);
    }
    setLoading(false);
  });

  /**
   * score input
   */
  const scoreInput = (match: any, isHome: boolean) => {
    const sanctionedMatch = match.homeSanction || match.awaySanction;
    const homeScore = sanctionedMatch ? (match.homeSanction ? 0 : "V") : match.homeScore;
    const awayScore = sanctionedMatch ? (match.awaySanction ? 0 : "V") : match.awayScore;

    return <Input
      key={match.id}
      ref={ref => inputRefs.current[`${isHome ? 'L' : 'V'}-${match.id}`] = ref}
      defaultValue={isHome ? homeScore : awayScore}
      onBlur={(event) => {
        readOnly || sanctionedMatch || setScore(event, match, isHome);
      }}
      style={{ textAlign: 'center', paddingLeft: 0 }}
      containerStyle={styles.scoreInput}
      disabled={readOnly || sanctionedMatch}
      numeric
    />
  }

  const setScore = React.useCallback(async (event: any, match: any, isHome: boolean) => {
    const matchResultId = match.matchResultId;
    const indexOfMatch = form.current.indexOf(match);
    const inputScore = parseInt(event.nativeEvent.text);

    const currentHomeScore = form.current[indexOfMatch].homeScore;
    const currentAwayScore = form.current[indexOfMatch].awayScore;

    // CHECK IF INPUT HASN'T BEEN MODIFIED
    if (inputScore === (isHome ? currentHomeScore : currentAwayScore)) {
      return;
    }

    // CHECK IF THERE'S A DRAW
    if (inputScore === (isHome ? currentAwayScore : currentHomeScore)) {
      createAlert({ type: "warning", text: t("admin.events.matches.drawWarning") })
      form.current[indexOfMatch][isHome ? "homeScore" : "awayScore"] = Number.isNaN(inputScore) ? null : inputScore;
      return;
    }

    // SET INPUT VALUE TO FORM
    form.current[indexOfMatch][isHome ? "homeScore" : "awayScore"] = Number.isNaN(inputScore) ? null : inputScore;
    const homeScore = form.current[indexOfMatch].homeScore;
    const awayScore = form.current[indexOfMatch].awayScore;

    const isHomeScore = homeScore || homeScore === 0;
    const isAwayScore = awayScore || awayScore === 0;

    // CHECK IF BOTH TEAMS HAVE A SCORE
    if ((isHomeScore && !isAwayScore) || (isAwayScore && !isHomeScore)) {
      return;
    }

    // CREATE OR UPDATE RESULTS
    if (isHomeScore && isAwayScore) {
      const payload: MatchResultService.IMatchResultsDTO = {
        ...(matchResultId && { id: matchResultId }),
        matchCallId: match.matchCallId,
        homeScore: homeScore,
        awayScore: awayScore,
      };

      if (!matchResultId) {

        try {
          const response = await MatchResultService.addMatchResult(payload);
          createAlert({ type: "success", text: t("admin.events.matches.createSuccess") });
          form.current[indexOfMatch].matchResultId = response.data.id;
        } catch (error) {
          createDSMError(error);
        }

      } else {

        try {
          await MatchResultService.updateMatchResult(payload);
          createAlert({ type: "success", text: t("admin.events.matches.updateSuccess") });
        } catch (error) {
          createDSMError(error);
        }

      }
    }

    // DELETE RESULTS
    if (!isHomeScore && !isAwayScore && matchResultId) {

      try {
        await MatchResultService.deleteMatchResult(matchResultId);
        createAlert({ type: "success", text: t("admin.events.matches.deleteSuccess") });
        form.current[indexOfMatch].matchResultId = null;
      } catch (error) {
        createDSMError(error);
      }

    }
  }, [createAlert, createDSMError]);

  /**
   * sanction match
   */
  const sanctionMatch = React.useCallback(async () => {
    const indexOfMatch = form.current.findIndex((match: any) => match.matchCallId === matchToSanction.matchCallId)
    const matchResultId = matchToSanction.matchResultId;

    const payload: MatchResultService.IMatchResultsDTO = {
      matchCallId: matchToSanction.matchCallId,
      homeScore: 0,
      awayScore: 0,
      homeSanction: !!matchToSanction.homeSanction,
      awaySanction: !!matchToSanction.awaySanction
    }

    if (!matchResultId) {
      try {
        const response = await MatchResultService.addMatchResult(payload);

        form.current[indexOfMatch].matchResultId = response.data.id;
        form.current[indexOfMatch].matchCallId = payload.matchCallId;
        form.current[indexOfMatch].homeScore = 0;
        form.current[indexOfMatch].awayScore = 0;
        form.current[indexOfMatch].homeSanction = !!payload.homeSanction;
        form.current[indexOfMatch].awaySanction = !!payload.awaySanction;

        inputRefs.current[`L-${form.current[indexOfMatch].id}`].setValue(!!form.current[indexOfMatch].homeSanction ? '0' : 'V');
        inputRefs.current[`V-${form.current[indexOfMatch].id}`].setValue(!!form.current[indexOfMatch].awaySanction ? '0' : 'V');

        createAlert({ type: "success", text: t("admin.events.matches.createSuccess") });
      } catch (error) {
        createDSMError(error);
      }
    }
    else {
      try {
        await MatchResultService.updateMatchResult({ ...payload, id: matchResultId });

        form.current[indexOfMatch].matchCallId = payload.matchCallId;
        form.current[indexOfMatch].homeScore = 0;
        form.current[indexOfMatch].awayScore = 0;
        form.current[indexOfMatch].homeSanction = !!payload.homeSanction;
        form.current[indexOfMatch].awaySanction = !!payload.awaySanction;

        inputRefs.current[`L-${form.current[indexOfMatch].id}`].setValue(!!form.current[indexOfMatch].homeSanction ? '0' : 'V');
        inputRefs.current[`V-${form.current[indexOfMatch].id}`].setValue(!!form.current[indexOfMatch].awaySanction ? '0' : 'V');

        createAlert({ type: "success", text: t("admin.events.matches.updateSuccess") });
      } catch (error) {
        createDSMError(error);
      }
      form.current[indexOfMatch] = { ...form.current.find((match: any) => match.matchCallId === matchToSanction.matchCallId), ...payload }
      createAlert({ type: "success", text: t("admin.events.matches.updateSuccess") });
    }

    setMatchToSanction(null);
  }, [createAlert, createDSMError, matchToSanction]);

  /**
   * unsanction match
   */
  const unsanctionMatch = React.useCallback(async () => {
    try {
      await MatchResultService.deleteMatchResult(matchToUnsanction.matchResultId);

      const indexOfMatch = form.current.findIndex((match: any) => match.matchCallId === matchToUnsanction.matchCallId);
      form.current[indexOfMatch].matchResultId = undefined;
      form.current[indexOfMatch].homeScore = undefined;
      form.current[indexOfMatch].awayScore = undefined;
      form.current[indexOfMatch].homeSanction = undefined;
      form.current[indexOfMatch].awaySanction = undefined;

      inputRefs.current[`L-${form.current[indexOfMatch].id}`].setValue(undefined);
      inputRefs.current[`V-${form.current[indexOfMatch].id}`].setValue(undefined);

      createAlert({ type: "success", text: t("admin.events.matches.deleteSuccess") });
    } catch (error) {
      createDSMError(error);
    }

    setMatchToUnsanction(null);
  }, [createAlert, createDSMError, matchToUnsanction]);

  const getMatchPdf = React.useCallback(async (match: any) => {
    try {
      await MatchCallService.getMatchPdf(match.matchCallId);
    } catch (error) {
      createDSMError(error);
    }
  }, [createDSMError]);

  const getMatchesPdf = React.useCallback(async () => {
    try {
      if (!!courtSelected) {
        await MatchCallService.getMatchesPdfByCourt(event.id, courtSelected);
      } else {
        await MatchCallService.getMatchesPdf(event.id);
      }
    } catch (error) {
      createDSMError(error);
    }
  }, [createDSMError, event, courtSelected]);

  /**
   * on save match call
   */
  const onSaveMatchCall = (object: any) => {
    const matchCall = form.current.find((match: any) => match.matchCallId === object.matchCallId);
    matchCall.formattedMatchDateHour = object.formattedMatchDateHour || matchCall.formattedMatchDateHour;
    matchCall.matchDateHour = object.matchDateHour || matchCall.matchDateHour;
    matchCall.courtId = object.courtId || matchCall.courtId;
    matchCall.courtName = object.courtName || matchCall.courtName;
    matchCall.facilityId = object.facilityId || matchCall.facilityId;
    matchCall.facilityName = object.facilityName || matchCall.facilityName;

    setMatchCallIdToUpdate(null);
  }

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    {
      name: "matchCallId",
      label: t("admin.events.matches.id"),
      width: 40,
    },
    {
      name: "eventCategoryName",
      label: t("admin.events.matches.category"),
      textProps: { uppercase: true },
    },
    {
      name: "groupName",
      label: t("admin.events.matches.group"),
      textProps: { uppercase: true },
    },
    {
      name: "facilityName",
      label: t("admin.events.matches.facility"),
      textProps: { uppercase: true },
    },
    {
      name: "courtName",
      label: t("admin.events.matches.court"),
      textProps: { uppercase: true },
    },
    {
      name: "formattedMatchDateHour",
      label: t("admin.events.matches.date"),
      width: 80,
      sort: (object) => object.matchDateHour,
    },
    {
      name: "homeTeamName",
      label: t("admin.events.matches.homeTeam"),
      headerTextProps: { textAlign: "right" },
      render: (object: any) => {
        if (!!object.homeTeamRef) {
          return (
            <Text textAlign="right">[{StringUtils.minifyTeamName(object.homeTeamRef)?.toUpperCase()}] {object.homeTeamName}</Text>
          );
        }

        return (
          <Text textAlign="right">{object.homeTeamName}</Text>
        );
      },
    },
    {
      name: "homeScore",
      label: "",
      width: 60,
      render: (match) => scoreInput(match, true)
    },
    {
      name: "awayScore",
      label: "",
      width: 60,
      render: (match) => scoreInput(match, false)
    },
    {
      name: "awayTeamName",
      label: t("admin.events.matches.awayTeam"),
      textProps: { uppercase: true },
      render: (object: any) => {
        if (!!object.awayTeamRef) {
          return <Text>[{StringUtils.minifyTeamName(object.awayTeamRef)?.toUpperCase()}] {object.awayTeamName}</Text>;
        }

        return <Text>{object.awayTeamName}</Text>;
      },
    },
    {
      name: "actions",
      label: "",
      width: 110,
      render: (object: any) => {
        return (
          <View style={{ flexDirection: "row", alignItems: 'center' }}>
            {object.homeSanction || object.awaySanction ? (
              <Icon type="text" text="NP" color="red" onPress={() => setMatchToUnsanction(object)} style={{ marginBottom: 5 }} textStyle={{ marginBottom: 0 }} />
            ) : (
              <Icon type="text" text="NP" onPress={() => setMatchToSanction(object)} style={{ marginBottom: 5 }} textStyle={{ marginBottom: 0 }} />
            )}

            <Icon type="eye" onPress={() => setMatchCallIdToUpdate(object.matchCallId)} />

            {isModality3x3 && (
              <Icon
                title={t('admin.events.matches.pdf')}
                type="pdf"
                onPress={() => !!object?.matchRecordUrl ? setMatchRecordModal(object) : getMatchPdf(object)}
              />
            )}
          </View>
        )
      },
    },
  ];

  return (
    <View>
      <View flexDirection='row' alignItems='center' marginBottom={20}>
        <Title text={t("admin.events.matches.title")} containerStyle={{ flex: 1, marginBottom: 0 }} />
        {isModality3x3 && <View flexDirection='row'>
          <Picker
            items={courts.map((court: any) => ({ label: `${court.facilityName} - ${court.name}`, value: court.id }))}
            defaultOptionLabel={t("admin.events.matches.courtPdf")}
            onChange={(court) => setCourtSelected(court)}
            stylesContainer={{ right: 50, top: 5 }}
          />
          <FloatingIconButton
            backgroundColor='transparent'
            color='black'
            name='pdf'
            right={30}
            title={t('admin.events.matches.multiPagePdf')}
            onPress={getMatchesPdf}
            containerStyle={{ position: 'relative' }}
          />
        </View>}
      </View>

      <Table headers={headers} data={form.current} />

      <Modal title={t("admin.events.matches.sanction")} visible={!!matchToSanction} close={() => setMatchToSanction(null)}>
        <Checkbox
          label={`${t("admin.events.matches.homeTeam")}: ${matchToSanction?.homeTeamName}`}
          onChange={value => setMatchToSanction({ ...matchToSanction, homeSanction: value })}
        />

        <Checkbox
          label={`${t("admin.events.matches.awayTeam")}: ${matchToSanction?.awayTeamName}`}
          onChange={value => setMatchToSanction({ ...matchToSanction, awaySanction: value })}
        />
        <Button text={t("global.save")} onPress={sanctionMatch} />
      </Modal>

      <ModalConfirm
        text={t("admin.events.matches.unsanctionText")}
        title={t("admin.events.matches.unsanctionTitle")}
        visible={matchToUnsanction}
        accept={unsanctionMatch}
        close={() => setMatchToUnsanction(null)}
      />

      <Modal title={`${t("admin.events.matches.match")} #${matchCallIdToUpdate}`} visible={!!matchCallIdToUpdate} close={() => setMatchCallIdToUpdate(null)}>
        <MatchCall matchCallId={matchCallIdToUpdate} courts={courts} onSave={onSaveMatchCall} />
      </Modal>

      <Modal header={false} visible={!!matchRecordModal} close={() => setMatchRecordModal(undefined)} minHeight={900} maxWidth={700}>
        <ImageRenderer imageUrl={matchRecordModal?.matchRecordUrl} />
      </Modal>
    </View>
  )
}

const MatchCall = ({ matchCallId, courts, onSave }) => {
  const { createDSMError } = useAlerts();
  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [court, setCourt] = React.useState<any>();
  const [matchDateHour, setMatchDateHour] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await MatchService.getByMatchCallId(matchCallId)
      setData(response.data);
      setCourt({ id: response.data.courtId, name: response.data.courtName });
      setMatchDateHour(!!response.data.matchDateHour ? dayjs(response.data.matchDateHour).format('DD/MM/YYYY HH:mm') : '');
    } catch (error) {
      createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * save
   */
  const save = React.useCallback(async () => {
    try {
      const payload = {
        matchCallId,
        courtId: court?.id,
        courtName: court?.name,
        facilityId: court?.facilityId,
        facilityName: court?.facilityName,
        formattedMatchDateHour: matchDateHour,
        matchDateHour: !!matchDateHour ? dayjs(matchDateHour, 'DD/MM/HHHH HH:mm').format('YYYY-MM-DD HH:mm:00') : undefined,
      };

      await MatchService.updateCourtAndDate(payload);
      onSave(payload);
    } catch (error) {
      createDSMError(error);
    }
  }, [court, matchDateHour, matchCallId, onSave, createDSMError]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  return (
    <Form>
      <Row>
        <Input label={t("admin.events.matches.category")} value={data?.eventCategoryName} containerStyle={{ flex: 1 }} disabled />
        <Input label={t("admin.events.matches.group")} value={data?.groupName} containerStyle={{ flex: 1, marginLeft: 10 }} disabled />
      </Row>

      <Row>
        <Input label={t("admin.events.matches.homeTeam")} value={data?.homeTeamName} containerStyle={{ flex: 1 }} disabled />
        <Input label={t("admin.events.matches.awayTeam")} value={data?.awayTeamName} containerStyle={{ flex: 1, marginLeft: 10 }} disabled />
      </Row>

      <Row>
        <Picker
          label={t("admin.events.matches.court")}
          defaultValue={data?.courtId || ''}
          items={courts.map((court: any) => ({ label: `${court.facilityName} - ${court.name}`, value: court.id }))}
          onChange={(value: string) => {
            const selected = courts.find((court: any) => court.id === parseInt(value));
            setCourt({ id: parseInt(value), name: selected?.name, facilityId: selected?.facilityId, facilityName: selected?.facilityName });
          }}
        />
        <InputDate
          useTime
          internalFormat="DD/MM/YYYY HH:mm"
          defaultValue={matchDateHour}
          onChange={(value: string) => setMatchDateHour(value)}
        />
      </Row>

      <Button text={t('global.save')} onPress={save} />
    </Form>
  );
}

const styles = StyleSheet.create({
  scoreInput: {
    top: 8,
    width: 45,
  },
});