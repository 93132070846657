import * as React from 'react';
import { StyleSheet, View as RNView } from 'react-native';

interface IView {
  flexDirection?: 'column' | 'row';
  alignItems?: 'center' | 'flex-end' | 'flex-start';
  justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around' | 'space-evenly';
  flex?: number;
  marginRight?: number;
  marginBottom?: number;
  onLayout?: (event: any) => void;
};

export const View = ({ children, style, flexDirection = 'column', alignItems, justifyContent, flex, marginRight, marginBottom, onLayout }: IView & RNView['props']) => {
  return (
    <RNView style={[styles.container, { alignItems, justifyContent, flexDirection, flex, marginRight, marginBottom }, style]} onLayout={onLayout}>
      {children}
    </RNView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    display: 'flex',
    // width: '100%',
  },
});
