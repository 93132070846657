import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { StyleSheet } from 'react-native';
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { IconButton } from 'src/components/IconButton';
import { BackgroundLoader } from "src/components/BackgroundLoader";
import { Modal } from "src/components/Modal";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useAlerts } from 'src/hooks/useAlerts';
import * as TeamActorService from 'src/services/TeamActorService';
import { REGISTER_STATUS_PENDING_TEXT_SIGNATURE } from './IRegistrationProcess';

interface IStep02 {
  data2: any;
  onSuccess: () => void;
};

export const Step02 = ({ data2, onSuccess }: IStep02) => {
  const alerts = useAlerts();
  const [iframeLoading, setIframeLoading] = React.useState<boolean>(true);
  const [data] = React.useState(data2);

  const [errorModal, setErrorModal] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [helpModal, setHelpModal] = React.useState<boolean>(false);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    try {
      setBtnLoading(true)
      const response = await TeamActorService.registration(data?.teamActor?.uuid)
      if (response.data.teamActor.registrationStatus > REGISTER_STATUS_PENDING_TEXT_SIGNATURE) {
        /**
        * update
        */
        onSuccess()
      } else {
        setErrorModal(true)
      }
    } catch (error) {
      alerts.createDSMError(error);
    } finally {
      setBtnLoading(false)
    }
  }, [alerts, data, onSuccess]);


  /**
   * check save
   */
  const next = React.useCallback(() => {
    if (data.teamActor.registrationStatus === REGISTER_STATUS_PENDING_TEXT_SIGNATURE) {
      save()
    }
  }, [data, save]);

  return (
    <View>
      <View style={{ alignItems: 'flex-end' }}>
        <View style={{ width: 60, justifyContent: 'center', alignItems: 'center' }}>
          <Text bold style={{ marginBottom: -8 }}>{t('public.actorRegistration.step02.help')}</Text>
          <IconButton onPress={() => setHelpModal(true)} name='help' />
        </View>
      </View>
      <View style={{ height: 800, width: '100%', marginBottom: 25 }}>
        {iframeLoading ? <BackgroundLoader /> : null}
        <iframe
          title='signature'
          onLoad={() => setIframeLoading(false)}
          style={{ borderWidth: 0, height: '100%', width: '100%', marginBottom: 25 }}
          src={data.documentation.signatureUrl}
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* <View style={{ marginRight: 5 }}><Button label={t('global.previous')} onPress={() => navigation('back')}></Button></View> */}
        <View><Button disabled={btnLoading} label={t('global.next')} onPress={() => next()}></Button></View>
      </View>

      <Modal visible={errorModal} title={t('public.actorRegistration.step02.error')} onClose={() => setErrorModal(false)}>
        <Container shadow={false}>
          <Alert text={t('public.actorRegistration.step02.errorDescription')} type={'error'}></Alert>
          <Tutorial />
        </Container>
      </Modal>

      <Modal visible={helpModal} title={t('public.actorRegistration.step02.help')} onClose={() => setHelpModal(false)}>
        <Container shadow={false}>
          <Tutorial />
        </Container>
      </Modal>
    </View>
  );
}


export const Tutorial = () => {


  return (
    <View>
      <Title size="small" text={t('public.actorRegistration.step02.tutorial.title')} />
      <View style={styles.cards}>
        <View style={[styles.cardContent]}>
          <View style={styles.cardHeader}>
            <Title size="small" text={t('public.actorRegistration.step02.tutorial.step1title')} />
            <Text>
              {t('public.actorRegistration.step02.tutorial.step1description')}
            </Text>
          </View>
          <img width={'100%'} alt={'signaturit1'} src='https://owbasket-back-prod.s3.eu-west-1.amazonaws.com/signaturitTutorial/signaturit1.png'></img>
        </View>

        <View style={[styles.cardContent]}>
          <View style={styles.cardHeader}>
            <Title size="small" text={t('public.actorRegistration.step02.tutorial.step2title')} />
            <Text>
              {t('public.actorRegistration.step02.tutorial.step2description')}
            </Text>
          </View>
          <img width={'100%'} alt={'signaturit2'} src='https://owbasket-back-prod.s3.eu-west-1.amazonaws.com/signaturitTutorial/signaturit2.png'></img>
        </View>

        <View style={[styles.cardContent]}>
          <View style={styles.cardHeader}>
            <Title size="small" text={t('public.actorRegistration.step02.tutorial.step3title')} />
            <Text>
              {t('public.actorRegistration.step02.tutorial.step3description')}
            </Text>
          </View>
          <img width={'100%'} alt={'signaturit3'} src='https://owbasket-back-prod.s3.eu-west-1.amazonaws.com/signaturitTutorial/signaturit3.png'></img>
        </View>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  cardContent: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    margin: 5,
    borderColor: '#bdbdbd',
    borderRadius: 5
  },
  cards: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardHeader: {
    padding: 15,
    marginBottom: 15,
    height: 140
  },
});