import { Picker, t } from 'owc-react-lib-common';
import * as React from 'react';

import { StyleSheet } from "react-native";
import { Button } from 'src/components/Button';
import { FloatingIconButton } from 'src/components/FloatingIconButton';
import { IconButton } from 'src/components/IconButton';
import { Loader } from 'src/components/Loader';
import { Modal } from 'src/components/Modal';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { ITableHeader, Table } from "src/components/Table";
import { Text } from 'src/components/Text';
import { View } from "src/components/View";
import { styling } from 'src/config';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useRegistrationStatus } from 'src/hooks/useRegistrationStatus';
import { useUserPermission } from "src/hooks/useUserPermission";
import { ActorCreationModal } from 'src/pages/auth/components/ActorCreationModal';
import { GENDER_NON_BINARY } from 'src/pages/auth/components/config';
import * as ActorService from 'src/services/ActorService';
import * as EventService from 'src/services/EventService';
import * as RoleService from 'src/services/RoleService';
import * as ShirtSizeService from 'src/services/ShirtSizeService';
import * as TeamService from 'src/services/TeamService';
import * as LicenceUtils from 'src/utils/LicenceUtils';
// import { ActorCreationModalOld } from 'src/pages/auth/components/ActorCreationModalOld';

interface ITeamActorsList {
  category: any;
  eventUuid: string;
  dataActors: any;
  dataTeamEventCategoryActors: any;
  team: any;
  questions?: any[];
}

export const TeamActorsList = ({ team, category, eventUuid, dataActors, dataTeamEventCategoryActors, questions = [] }: ITeamActorsList) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { eventCategoryId } = useQueryParams();
  const [data, setData] = React.useState(dataActors);
  const [teamEventCategoryActors, setTeamEventCategoryActors] = React.useState(dataTeamEventCategoryActors);
  const [actor, setActor] = React.useState(null as any);
  const [sendAllModal, setSendAllModal] = React.useState(false);
  const [sendToActor, setSendToActor] = React.useState<any>();
  const [actorToDelete, setActorToDelete] = React.useState<any>();
  const [actorToRestore, setActorToRestore] = React.useState<any>();
  const [actorStatusToUpdate, setActorStatusToUpdate] = React.useState<any>(undefined);
  const [sizes, setSizes] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const alerts = useAlerts();
  const [loading, setLoading] = React.useState(true);
  const { STATUS, renderLegend, renderRegistrationStatus } = useRegistrationStatus();

  const isTeamModality3x3 = team.modalityId === 2;
  const teamEventCategoryId = isTeamModality3x3 ? team.teamEventCategories.find(((tec: any) => tec?.eventCategory?.id === parseInt(eventCategoryId)))?.id : null;

  const [add3x3ActorModal, set3x3AddActorModal] = React.useState<boolean>(false);
  const open3x3AddActorModal = React.useCallback(() => set3x3AddActorModal(true), []);
  const close3x3AddActorModal = React.useCallback(() => set3x3AddActorModal(false), []);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const [sizes, roles] = await Promise.all([
        isTeamModality3x3 ? ShirtSizeService.getAll() : EventService.findShirtSizes(eventUuid),
        RoleService.findAll(),
      ]);

      setSizes(sizes.data);
      setRoles(roles.data);
      isTeamModality3x3 && setData(data.filter(actor => !teamEventCategoryActors.find(addedActor => addedActor.id === actor.id)))
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  // const restoreActor = React.useCallback(async (actor: any) => {
  //   try {
  //     alerts.create({ type: 'success', text: t('global.success') });
  //   } catch (error) {
  //     alerts.createDSMError(error);
  //   }
  // }, [alerts])

  const add3x3Actor = React.useCallback(async (teamActor: ActorService.ITeamActorDTO) => {
    try {
      await ActorService.addTeamEventCategoryActor(teamEventCategoryId, teamActor);
      setTeamEventCategoryActors([...teamEventCategoryActors, teamActor]);
      setData([...data.filter((actor: any) => actor.id !== teamActor.id)]);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [teamEventCategoryId, data, teamEventCategoryActors, alerts]);

  const remove3x3Actor = React.useCallback(async (teamActor: ActorService.ITeamActorDTO) => {
    try {
      await ActorService.removeTeamEventCategoryActor(teamEventCategoryId, teamActor.id);
      setData([...data, teamActor]);
      setTeamEventCategoryActors([...teamEventCategoryActors.filter((actor: any) => actor.id !== teamActor.id)]);

    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [teamEventCategoryActors, teamEventCategoryId, data, alerts]);

  /**
   * open send all modal
   */
  const openSendAllModal = React.useCallback(() => {
    setSendAllModal(true);
  }, []);

  /**
   * open send all modal
   */
  const closeSendAllModal = React.useCallback(() => {
    setSendAllModal(false);
  }, []);

  /**
   * send all
   */
  const sendAll = React.useCallback(async () => {
    try {
      await TeamService.validate(team.uuid);

      setData(data.map((actor: any) => {
        if (!actor.notificationDate) {
          actor.notificationDate = new Date();
        }

        return actor;
      }));

      closeSendAllModal();
      alerts.create({ type: 'success', text: t('global.success') });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, team.uuid, data, closeSendAllModal]);

  /**
   * close send modal
   */
  const closeSendModal = React.useCallback(() => {
    setSendToActor(undefined);
  }, []);

  /**
   * send
   */
  const send = React.useCallback(async () => {
    try {
      await TeamService.notifyActorInscriptionReady(team.uuid, sendToActor.uuid);

      setData(data.map((actor: any) => {
        if (actor.uuid === sendToActor.uuid && !actor.notificationDate) {
          actor.notificationDate = new Date();
        }

        return actor;
      }));

      closeSendModal();
      alerts.create({ type: 'success', text: t('global.success') });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, team.uuid, sendToActor, alerts, closeSendModal]);

  const closeDeleteModal = React.useCallback(() => setActorToDelete(null), []);
  const closeRestoreModal = React.useCallback(() => setActorToRestore(null), []);

  /**
   * delete actor
   */
  const deleteActor = React.useCallback(async () => {
    try {
      await ActorService.deleteActor(actorToDelete.uuid);
      if (isTeamModality3x3) {
        setData(data.filter((actor: any) => actor.uuid !== actorToDelete.uuid));
      } else {
        setData(data.map((object: any) => object.id === actorToDelete.id ? { ...object, deleted: true } : object));
      }
      closeDeleteModal();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, actorToDelete, closeDeleteModal, alerts, isTeamModality3x3]);

  const restoreActor = React.useCallback(async () => {
    try {
      await ActorService.restoreActor(actorToRestore.uuid);
      setData(data.map((object: any) => object.id === actorToRestore.id ? { ...object, deleted: null } : object));
      closeRestoreModal();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, closeRestoreModal, alerts, actorToRestore]);

  /**
   * render actor gender
   */
  const renderActorGender = React.useCallback((object: any) => {
    if (object.gender === GENDER_NON_BINARY) {
      return 'NB';
    }

    return object.gender;
  }, []);

  /**
  * actions
  */
  const actions = React.useCallback((object: any) => {
    if (!!object.deleted) {
      return (
        <View style={styles.actions}>
          {!readOnly && <IconButton
            backgroundColor={'transparent'}
            color={'red'}
            name={"trash-restore"}
            onPress={() => setActorToRestore(object)}
            fontSize={20}
            title={t('global.restore')}
          />}
        </View>
      )
    }
    return (
      <View style={styles.actions}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name={readOnly ? "eye" : "edit"}
          onPress={() => setActor({ ...object })}
          fontSize={20}
          title={t('global.edit')}
        />
        {!readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name='send'
          onPress={() => setSendToActor(object)}
          fontSize={20}
          title={t('global.send')}
        />}

        {isTeamModality3x3 && !readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name='ko'
          onPress={() => remove3x3Actor(object)}
          fontSize={20}
          title={t('global.quit')}
        />}

        {!isTeamModality3x3 && !readOnly && <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name='trash'
          onPress={() => setActorToDelete(object)}
          fontSize={20}
          title={t('global.delete')}
        />}
      </View>
    );
  }, [readOnly, isTeamModality3x3, remove3x3Actor]);

  const openCreateModal = React.useCallback(() => {
    setActor({});
  }, []);

  /**
   * close create/edit modal
   */
  const close = React.useCallback(() => {
    setActor(undefined);
    // setData(data.sort(LicenceUtils.sort));
  }, []);

  /**
   * on save actor
   */
  const onSaveActor = React.useCallback(async (object: any) => {
    object = {
      ...object,
      _id: object._id || Date.now(),
      number: object.number === '00' ? '100' : object.number,
      teamId: team.id,
    };

    if (!object.id) {
      const response = await ActorService.addToTeam(object);
      setData([...data, {
        ...object,
        ...response.data,
        formattedBirthDate: response.data.peopleBirthdate,
        registerAnswers: object.registerAnswers,
      }].sort(LicenceUtils.sort));
    }
    else {
      const response = await ActorService.update(object);
      setData(data.map((actor: any) => actor.id === object.id ? {
        ...object,
        ...response.data,
        formattedBirthDate: response.data.peopleBirthdate,
        registerAnswers: object.registerAnswers,
      } : actor).sort(LicenceUtils.sort));
    }
  }, [data, team.id]);

  /**
   * close actor status to update modal
   */
  const closeActorStatusToUpdateModal = React.useCallback(() => {
    setActorStatusToUpdate(undefined);
  }, []);

  /**
   * update actor status modal
   */
  const updateActorStatus = React.useCallback(async () => {
    try {
      await ActorService.changeRegistrationStatus(actorStatusToUpdate.uuid, parseInt(actorStatusToUpdate.registrationStatus));
      setData(data.map((actor: any) => parseInt(actorStatusToUpdate.id) === parseInt(actor.id) ? actorStatusToUpdate : actor));
      closeActorStatusToUpdateModal();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [data, actorStatusToUpdate, alerts, closeActorStatusToUpdateModal]);

  const AddActorModalActions = React.useCallback((object: any) => {
    return (
      <View flexDirection='row' alignItems='center'>
        <IconButton
          title={t('global.add')}
          name='add'
          backgroundColor={'transparent'}
          color={'black'}
          onPress={() => add3x3Actor(object)}
        />
      </View>
    )
  }, [add3x3Actor]);

  const setColor = React.useCallback((object: any) => !!object?.deleted ? "red" : "", [])
  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }


  /**
   * render
   */
  let headers: ITableHeader[] = [
    { name: 'peopleName', label: t('admin.teams.teamActorsList.name'), textProps: (object: any) => ({ color: setColor(object), uppercase: true, fontSize: 10 }) },
    { name: 'peopleSurname', label: t('admin.teams.teamActorsList.surname'), textProps: (object: any) => ({ color: setColor(object), uppercase: true, fontSize: 10 }) },
    { name: 'gender', label: t('admin.teams.teamActorsList.gender'), textProps: (object: any) => ({ color: setColor(object), }), render: (object: any) => <Text color={setColor(object)}>{renderActorGender(object)}</Text>, sort: (object: any) => renderActorGender(object), width: 70 },
    { name: 'peopleEmail', label: t('admin.teams.teamActorsList.email'), textProps: (object: any) => ({ color: setColor(object), fontSize: 12, overflow: true, title: object.peopleEmail }) },
    { name: 'formattedPeopleBirthdate', label: t('admin.teams.teamActorsList.birthDate'), textProps: (object: any) => ({ color: setColor(object), fontSize: 12 }), sort: (object: any) => object.peopleBirthdate, width: 100 },
    { name: 'roleDescription', label: t('admin.teams.teamActorsList.role'), textProps: (object: any) => ({ color: setColor(object), uppercase: true, fontSize: 10, overflow: true }) },
    { name: 'number', label: t('admin.teams.teamActorsList.number'), textProps: (object: any) => ({ color: setColor(object), }), render: (object: any) => <Text color={setColor(object)}>{parseInt(object.number) === 100 ? '00' : object.number}</Text>, sort: (object: any) => object.number, width: 70 },
    { name: 'shirtSize', label: t('admin.teams.teamActorsList.size'), textProps: (object: any) => ({ color: setColor(object), }), width: 70 },
    { name: 'rateName', label: t('admin.teams.teamActorsList.rate'), textProps: (object: any) => ({ color: setColor(object), fontSize: 10 }) },
    { name: 'price', label: t('admin.teams.teamActorsList.price'), textProps: (object: any) => ({ color: setColor(object), }), render: (object: any) => <Text color={setColor(object)}>{LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data)} €</Text>, sort: (object: any) => parseFloat(LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data)), width: 70 },
    { name: 'status', label: t('admin.teams.teamActorsList.status.title'), render: (object: any) => renderRegistrationStatus(object, () => setActorStatusToUpdate(object)), sort: (object: any) => object.registrationStatus },
    { name: 'actions', label: ' ', render: actions, width: 150 },
  ];

  questions.forEach((question: any) => {
    headers.splice(headers.length - 1, 0, {
      name: 'question-' + question.id,
      label: question.listHeader,
      search: (object: any) => object.registerAnswers?.find((answer: any) => answer.questionId === question.id)?.answer,
      sort: (object: any) => object.registerAnswers?.find((answer: any) => answer.questionId === question.id)?.answer,
      render: (object: any) => {
        return (
          <Text color={setColor(object)} fontSize={10}>{object.registerAnswers?.find((answer: any) => answer.questionId === question.id)?.answer}</Text>
        )
      },
    });
  });

  if(isTeamModality3x3){
    
    headers = headers.filter(h => h.name !== "rateName" && h.name !== "price")
  }

  const footer = {
    price: data.reduce((total: number, object: any) => total + LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data), 0) + ' €',
  };



  const AddActorModalHeaders: ITableHeader[] = [
    { name: 'peopleName', label: t('admin.teams.teamActorsList.name'), textProps: { uppercase: true, fontSize: 10 } },
    { name: 'peopleSurname', label: t('admin.teams.teamActorsList.surname'), textProps: { uppercase: true, fontSize: 10 } },
    { name: 'gender', label: t('admin.teams.teamActorsList.gender'), render: (object: any) => <Text>{renderActorGender(object)}</Text>, sort: (object: any) => renderActorGender(object), width: 70 },
    { name: 'peopleEmail', label: t('admin.teams.teamActorsList.email'), textProps: (object: any) => ({ fontSize: 12, overflow: true, title: object.peopleEmail }) },
    { name: 'formattedBirthDate', label: t('admin.teams.teamActorsList.birthDate'), sort: (object: any) => object.peopleBirthdate, textProps: { fontSize: 12 }, width: 100 },
    { name: 'roleDescription', label: t('admin.teams.teamActorsList.role'), textProps: { uppercase: true, fontSize: 10, overflow: true } },
    { name: 'number', label: t('admin.teams.teamActorsList.number'), render: (object: any) => <Text>{parseInt(object.number) === 100 ? '00' : object.number}</Text>, sort: (object: any) => object.number, width: 70 },
    { name: 'shirtSize', label: t('admin.teams.teamActorsList.size'), width: 70 },
    { name: 'rateName', label: t('admin.teams.teamActorsList.rate'), textProps: { fontSize: 10 } },
    { name: 'price', label: t('admin.teams.teamActorsList.price'), render: (object: any) => <Text>{LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data)} €</Text>, sort: (object: any) => parseFloat(LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data)), width: 70 },
    { name: 'status', label: t('admin.teams.teamActorsList.status.title'), render: (object: any) => renderRegistrationStatus(object, () => setActorStatusToUpdate(object)), sort: (object: any) => object.registrationStatus },
    { name: 'actions', label: ' ', render: AddActorModalActions, width: 90 },
  ];

  return (
    <React.Fragment>
      <View flexDirection="row">
        <View flex={1} flexDirection='row' style={{ gap: 5 }}>
          <Button label={t('admin.teams.teamActorsList.create')} onPress={openCreateModal} disabled={readOnly} />
          {isTeamModality3x3 && <Button label={t('admin.teams.teamActorsList.add')} onPress={open3x3AddActorModal} disabled={readOnly} />}
        </View>
        {!readOnly && <FloatingIconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="send"
          onPress={openSendAllModal}
          containerStyle={{ position: "relative" }}
          // right={15}
          title={t('global.send')}
        />}
      </View>


      <Table
        pagination={false}
        defaultSize={25}
        headers={headers}
        headerSize={12}
        footer={!isTeamModality3x3 && footer}
        data={isTeamModality3x3 ? teamEventCategoryActors : data}
      />



      <Modal
        minWidth={1200}
        visible={add3x3ActorModal}
        onClose={close3x3AddActorModal}
        title={t('admin.teams.teamActorsList.add')}
        children={<Table headers={AddActorModalHeaders} data={data} />}
      />




      {renderLegend()}

      {!!actor && (
        <ActorCreationModal
          close={close}
          category={category}
          actor={actor}
          data={data}
          onSave={onSaveActor}
          shirtSizes={sizes}
          roles={roles}
          isOrganizer
          is3x3Actor={isTeamModality3x3}
        />
      )}

      <Modal visible={!!actorStatusToUpdate} title={t('global.confirmation')} onClose={closeActorStatusToUpdateModal} width={600}>
        <View flex={1} flexDirection='row' style={{ padding: styling.spacing }}>
          <Picker
            label=''
            items={STATUS.map((status: any) => ({ label: status.label, value: status.id }))}
            defaultValue={actorStatusToUpdate?.registrationStatus}
            onChange={(value: string) => setActorStatusToUpdate({ ...actorStatusToUpdate, registrationStatus: parseInt(value) || 0 })}
            containerStyle={{ flex: 1, marginBottom: 0 }}
          />

          <Button label={t('global.save')} onPress={updateActorStatus} marginBottom={0} shadow={false} />
        </View>
      </Modal>

      <ModalConfirm
        visible={sendAllModal}
        text={t('admin.teams.teamActorsList.sendAllConfirmation')}
        onSuccess={sendAll}
        onClose={closeSendAllModal}
      />

      <ModalConfirm
        visible={!!sendToActor}
        text={t('admin.teams.teamActorsList.sendConfirmation')}
        onSuccess={send}
        onClose={closeSendModal}
      />

      <ModalConfirm
        visible={!!actorToRestore}
        text={t('admin.teams.teamActorsList.restoreConfirmation')}
        onSuccess={restoreActor}
        onClose={closeRestoreModal}
      />

      <ModalConfirm
        visible={!!actorToDelete}
        text={t('admin.teams.teamActorsList.deleteConfirmation')}
        onSuccess={deleteActor}
        onClose={closeDeleteModal}
      />
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  actions: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  legend: {
    // marginTop: styling.spacing,
    borderTopWidth: 1,
    borderColor: '#eee',
  },
  legendItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingBottom: 2,
    borderColor: '#eee',
    alignItems: 'center',
  },
  legendLabel: {
    width: 200,
    borderRightWidth: 1,
    borderColor: '#eee',
    paddingRight: 10,
    fontSize: 10,
  },
  legendDescription: {
    flex: 1,
    paddingLeft: 10,
    fontSize: 10,
  },
});