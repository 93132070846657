import * as React from 'react';
import { Tabs as OWCTabs } from "owc-react-lib-common";
import { View } from "./View";
import { StyleSheet } from 'react-native';
import { styling } from 'src/config';

interface ITabs {
  headers: string[];
  children: any[];
  initialTab?: number;
  onChangeTab?: (tab: number) => void;
}

export const Tabs = ({ headers, children, initialTab = 0, onChangeTab }: ITabs) => {
  const [selected, setSelected] = React.useState(initialTab);

  /**
   * handle change
   */
  const handleChange = React.useCallback((index: number) => {
    setSelected(index);
    onChangeTab?.(index);
  }, [onChangeTab]);

  /**
   * render
   */
  return (
    <View>
      <View style={{ width: '100%' }}>
        <OWCTabs tabnames={headers} onChangeTab={handleChange}>
          {headers.map((_header: any, idx: number) => <View key={idx} />)}
        </OWCTabs>
      </View>

      {children?.map((child: any, idx: number) => (
        <View key={idx} style={[styles.tab, idx !== selected && styles.hidden]}>
          {child}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  hidden: {
    display: 'none',
  },
  tab: {
    paddingTop: styling.spacing,
  },
});