import { BACKEND_URL } from '../config';
import { getTyped } from './RestService';
import { Page } from './entities/Page';


// LoginLogDto.ts
export interface LoginLog {
  id: number;
  baseUser: BaseUserMask;
  loginTime: string; // Assuming LocalDateTime is serialized as a string
  loginSuccess: boolean;
  loginMethod: string;
}

// BaseUserMaskDto.ts
export interface BaseUserMask {
  id: number;
  uuid: string;
  email: string;
  defaultLang: string;
  registerStatus: string;
  organizerId: number;
  organizerName: string;
  accountValidated: boolean;
  organizerReadOnly: boolean;
  admin: boolean;
}


const BASE_URL = BACKEND_URL + '/auth/login-log'

export const getLoginLogs = (page: number, size: number, sort: string = "id", direction: "ASC" | "DESC" = "ASC", searchString: String): Promise<Page<LoginLog>> => {
  return getTyped(`${BASE_URL}?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}${searchString ? `&searchText=${searchString}` : ''}`);
}

export const findByBaseUserUuid = (baseUserUuid: string): Promise<LoginLog[]> => {
  return getTyped(`${BASE_URL}/${baseUserUuid}`);
}