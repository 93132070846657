import * as React from 'react';
import { View } from '../View';
import { Text } from '../Text';
import { StyleSheet } from 'react-native';
import { IconButton } from '../IconButton';
import { t } from 'owc-react-lib-common';
import { Page } from 'src/services/entities/Page';

export interface IPagination<T> {
    data: Page<T>;
    changePage: (pageNumber: number) => void;
}
export const Pagination = <T,>({ data, changePage }: IPagination<T>) => {
    const [, setInfo] = React.useState<Page<T>>(data);

    React.useEffect(() => {
        setInfo(data);
    }, [data])

    return (
        <View style={styles.infoContainer} justifyContent='space-between'>
            <View style={styles.pagination}>
                <IconButton containerStyle={{ borderRadius: 10 }} backgroundColor={'transparent'} color={'black'} name={'arrowleft'} onPress={() => changePage(Math.max(data?.number - 1, 0))}></IconButton>
                <Text style={styles.pinfo}>{t('components.table.pagination', { page: data?.number + 1, pages: data?.totalPages })}</Text>
                <IconButton containerStyle={{ borderRadius: 10 }} backgroundColor={'transparent'} color={'black'} name={'arrowright'} onPress={() => changePage(Math.min(data?.number + 1, data.totalPages - 1))}></IconButton>
            </View>

            <View>
                <Text>{t('components.table.filtered', { filtered: data?.numberOfElements, total: data?.totalElements })}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 40,
    },
    pagination: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    pinfo: {
        marginHorizontal: 5,
    }
});
