import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { StyleSheet } from 'react-native';
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Icon } from "src/components/Icon";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { styling } from "src/config";
import { useAlerts } from 'src/hooks/useAlerts';
import * as TeamActorService from 'src/services/TeamActorService';
import { REGISTER_STATUS_PAYMENT_REJECTED } from './IRegistrationProcess';


interface IStep07 {
  data2: any;
};

export const Step07 = ({ data2 }: IStep07) => {

  const alerts = useAlerts();

  const submitForm = (formData: any) => {
    let myform = document.createElement("form")
    myform.action = formData.urlAutorizacionTpv
    myform.method = "post"
    document.body.appendChild(myform);

    let signatureV = document.createElement("input");
    signatureV.value = formData.dsSignatureVersion;
    signatureV.name = "Ds_SignatureVersion";
    signatureV.type = "hidden";
    myform.appendChild(signatureV);

    let params = document.createElement("input");
    params.value = formData.dsMerchantParameters;
    params.name = "Ds_MerchantParameters";
    params.type = "hidden"
    myform.appendChild(params);

    let signature = document.createElement("input");
    signature.value = formData.dsSignature;
    signature.name = "Ds_Signature";
    signature.type = "hidden"
    myform.appendChild(signature);

    setTimeout(() => {
      myform.submit();
    }, 500)

    return null;
  }


  /**
   * pay data 
   */
  const payData = React.useCallback(async () => {
    if (data2) {
      try {
        const response = await TeamActorService.iniPaymentProcess(data2)
        /**
         * form pay
         */
        submitForm(response.data)
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
  }, [alerts, data2]);

  return (
    <View>
      <View>
        <Title text={t('public.actorRegistration.step07.title')} size="small"></Title>
        {data2.teamActor.registrationStatus === REGISTER_STATUS_PAYMENT_REJECTED ?
          <Alert text={t('public.actorRegistration.step07.rejectedPayment')} /> : null}
      </View>

      <View style={{ flexDirection: 'row' }}>
        <View style={styles.summaryContent}>
          <View style={styles.summaryBox}>
            <Text style={styles.fontSizeAccent} bold>{t('public.actorRegistration.step07.person')}</Text>
            <Text style={styles.fontSizeText}> {data2.teamActor.fullName}</Text>
          </View>
          <View style={styles.summaryBox}>
            <Text style={styles.fontSizeAccent} bold >{t('public.actorRegistration.step07.rate')}</Text>
            <Text style={styles.fontSizeText}>{data2.teamActor.rateName}</Text>
            <Text fontSize={10} color='#333'>({data2.teamActor.rateDescription})</Text>
          </View>
          <View style={styles.summaryBox}>
            <Text style={styles.fontSizeAccent} bold>{t('public.actorRegistration.step07.team')}</Text>
            <Text style={styles.fontSizeText}>{data2.teamActor.teamName}</Text>
          </View>
        </View>

        <View style={styles.summaryPayContent}>
          <View style={styles.summaryPayBox}>
            <View style={{ borderBottomWidth: 1, marginBottom: styling.spacing }}>
              <Title text={t('public.actorRegistration.step07.paymentType')} size="small"></Title>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Icon name='creditCard' fontSize={30} style={{ marginRight: 10 }}></Icon>
              <Text style={styles.fontSizeText} >{t('public.actorRegistration.step07.creditCard')}</Text>
            </View>
          </View>
          <View style={[styles.summaryPayBox, { backgroundColor: '#1d1d1d' }]}>
            <View style={styles.summaryPayTitle}>
              <Text fontSize={20} bold uppercase style={{ marginBottom: 16 }} color='white'>{t('public.actorRegistration.step07.paymentSummary')}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}><Text color={'white'} fontSize={18}>{t('public.actorRegistration.step07.total')}:</Text></View>
              <View style={{ flex: 1, alignItems: 'flex-end' }}><Text color={'#ffffffb3'} bold fontSize={18}>{data2.teamActor.amountToPay}€</Text></View>
            </View>
          </View>
          <Button containerStyle={{ width: '100%' }} label={t('public.actorRegistration.step07.pay')} onPress={() => payData()}></Button>
        </View>
      </View>
    </View>
  );
}


/**
 * styles
 */
const styles = StyleSheet.create({
  fontSizeText: {
    fontSize: 14
  },
  fontSizeAccent: {
    fontSize: 16,
    marginBottom: 5
  },

  summaryContent: {
    marginBottom: styling.spacing,
    flex: 3,
    borderRadius: 5,
    paddingRight: styling.spacing
  },

  summaryBox: {
    borderBottomWidth: 1,
    borderColor: '#bdbdbd',
    paddingVertical: styling.spacing
  },

  summaryPayContent: {
    flex: 2,
    marginHorizontal: 3
  },

  summaryPayBox: {
    marginBottom: styling.spacing,
    borderWidth: 1,
    borderColor: '#bdbdbd',
    borderRadius: 5,
    padding: styling.spacing
  },

  summaryPayTitle: {
    borderBottomWidth: 1,
    marginBottom: styling.spacing,
    borderBottomColor: '#ffffff47'
  },


});


