import { Navigate, Route, Routes } from "react-router-dom";
import { MODALITY_3x3 } from "src/config";
import { CompetitionList } from "../competitions/CompetitionList";
import { Team3x3 } from "./teams/Team3x3";
import { Teams3x3 } from "./teams/Teams3x3";

export const ThreeOnThree = () => {
	return (
		<Routes>
			<Route path='team-event-categories/:uuid' element={<Team3x3 />} />
			<Route path='teams/*' element={<Teams3x3 />} />
			{/* <Route path='competitions/*' element={<Competitions3x3 />} /> */}
			<Route path='competitions/*' element={<CompetitionList modalityId={MODALITY_3x3} />} />
			<Route path='*' element={<Navigate to='teams' />} />
		</Routes>
	);
}