import { Alert, Button, Icon, Picker, t, Title } from "owc-react-lib-common";
import * as React from "react";
import { Text } from "src/components/Text";
import { Title as OWCTitle } from "src/components/Title";
import { View } from "src/components/View";
import { CompetitionDTO } from "src/services/CompetitionService";

import { StyleSheet, TouchableOpacity } from "react-native";
import { Checkbox } from "src/components/Checkbox";
import { Form } from "src/components/Form";
import { Loader } from "src/components/Loader";
import { Row } from "src/components/Row/Row";
import { Stepper } from "src/components/Stepper";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useEffectChange } from "src/hooks/useEffectChange";
import * as CompetitionService from "src/services/CompetitionService";
import * as EventService from "src/services/EventService";
import { IEventCategoryDTO, IEventDTO } from "src/services/EventService";
import * as RankingSystemService from "src/services/RankingSystemService";
import * as SeasonService from "src/services/SeasonService";

interface ICloneEvent {
  selectedCompetition?: CompetitionDTO;
  competition?: CompetitionDTO;
  goBack?: any;
  onClone?: any;
}

export enum STEPS {
  SEASON,
  EVENT
};

export enum CLONE_TYPE {
  RATING,
  GROUP,
  QUESTION
}

export const CloneEvent = ({ competition, onClone }) => {
  const [step, setStep] = React.useState<STEPS>(STEPS.SEASON);
  const [selected, setSelected] = React.useState<STEPS>(STEPS.SEASON);
  const [selectedCompetition, setSelectedCompetition] = React.useState<CompetitionDTO>();

  const headers = [
    { value: STEPS.SEASON, label: t('3x3.register.categorySelectionStep') },
    { value: STEPS.EVENT, label: t('3x3.register.categorySelectionStep') },

  ];

  const onSelectCompetition = async (uuid) => {
    const response = await CompetitionService.findByUuid(uuid);
    setSelectedCompetition(response.data);
    setStep(STEPS.EVENT);
    setSelected(STEPS.EVENT);
  }
  
  return (
    <Stepper headers={headers} step={step} selected={selected} onPress={setSelected} >
      <SeasonSelector onSelectCompetition={onSelectCompetition} />
      <CloneEventSelector selectedCompetition={selectedCompetition} goBack={() => {
        setSelected(STEPS.SEASON);
        setStep(STEPS.SEASON);
      }} 
      onClone={onClone}
      competition={competition}
      />
    </Stepper>
  )
}

const SeasonSelector = ({ onSelectCompetition }) => {
  const { createDSMError } = useAlerts();
  const [seasons, setSeasons] = React.useState<any[]>([]);
  const [currentSeasonId, setCurrentSeasonId] = React.useState<number>();
  const [competitions, setCompetitions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const alerts = useAlerts();


  /**
 * component did mount
 */
  useComponentDidMount(async () => {
    try {
      // load seasons
      const response = await SeasonService.findSeasons();
      setSeasons(
        response.data.map((season: any) => ({
          label: season.name,
          value: season.id,
        }))
      );

      // load competitions
      if (!!response.data?.length) {
        const seasonId = response.data[0].id;
        await onChangeSeason(seasonId);
      }
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  /**
 * on change
 */
  const onChangeSeason = React.useCallback(
    async (value: string) => {
      // parse value
      const seasonId = parseInt(value);
      setCurrentSeasonId(seasonId);

      // if empty
      if (!value) {
        setCompetitions([]);
        return;
      }

      // fetch competitions
      try {
        const response = await CompetitionService.findBySeason(seasonId);
        setCompetitions(response.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [alerts]
  );

  const selectCompetition = async (uuid) =>{
    try {
      setLoading(true)
      await onSelectCompetition(uuid)
      setLoading(false)
    } catch (error){
      createDSMError(error)
      setLoading(false)
    }
  }

  /**
 * loading
 */
  if (loading) {
    return <Loader />;
  }

  return (
    <View>
      <View style={styles.seasonContainer}>
        <Picker
          label={t("admin.competitions.list.season")}
          items={seasons}
          onChange={onChangeSeason}
          containerStyle={styles.season}
          defaultValue={currentSeasonId}
        />
      </View>
      {competitions.map(competition => {
        return (
          <View id={competition.id} style={{ padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon type={"arrowright"} text="text" onPress={() => selectCompetition(competition.uuid)} />

            <TouchableOpacity onPress={() => selectCompetition(competition.uuid)}>
              <Title containerStyle={{ padding: 0 }} fontSize={15} fontWeight={500} text={competition.name} marginBottom={0} />
            </TouchableOpacity>
          </View>
        )
      })
      }
    </View>
  )
}

const CloneEventSelector = ({ selectedCompetition, goBack, onClone, competition }: ICloneEvent) => {
  const { createDSMError } = useAlerts();
  const [rankingSystems, setRankingSystems] = React.useState<any[]>([]);
  const [selectedEvents, setSelectedEvents] = React.useState<IEventDTO[]>([]);
  const [selectedEventCategories, setSelectedEventCategories] = React.useState<IEventCategoryDTO[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = React.useState<any[]>([]);

  useEffectChange(async () => {
    const response = await RankingSystemService.getRankingSystem();
    setRankingSystems(response.data)
  }, []);

  const clone = async () => {

    const request = selectedEvents.map(se => ({
      id: se.id,
      eventCategories: selectedEventCategories.filter(sec => sec.eventId === se.id).map(sec => ({
        id: sec.id,
        copyTypes: selectedCheckbox.filter(sc => sc.eventCategoryId === sec.id).map(sc => sc.type)
      }))
    }))
    try {
      const response = await EventService.clone(competition.uuid, request);
      response.data.forEach(ev=>onClone(ev))
    } catch (error) {
      createDSMError(error);
    }
  }

  const handleSelected = (event) => {
    if (selectedEvents.filter(se => se.id === event.id).length === 0) {
      setSelectedEvents([...selectedEvents, event]);
      setSelectedEventCategories([...selectedEventCategories, ...event.eventCategories]);
    } else {
      setSelectedEvents([...selectedEvents.filter(e => e.id !== event.id)]);
      setSelectedEventCategories([...selectedEventCategories.filter(ec => ec.eventId !== event.id)]);
    }
  }

  const handleEventCategoryPress = (eventCategory) => {
    if (selectedEventCategories.filter(ec => ec.id === eventCategory.id).length === 0) {
      setSelectedEventCategories([...selectedEventCategories, eventCategory])
    } else {
      setSelectedEventCategories([...selectedEventCategories.filter(ec => ec.id !== eventCategory.id)])
    }
  }

  const handleChecboxPress = (eventCategory: IEventCategoryDTO, value: boolean, type: CLONE_TYPE) => {
    if (value) {
      setSelectedCheckbox([...selectedCheckbox, { eventCategoryId: eventCategory.id, type: type }]);
    } else {
      const filtered = selectedCheckbox.filter(sc => sc.eventCategoryId !== eventCategory.id);
      setSelectedCheckbox(filtered);
    }
  }

  const isEventSelected = (id) => selectedEvents.filter((e) => e.id === id).length === 1;
  const isEventCategorySelected = (id) => selectedEventCategories.filter((ec) => ec.id === id).length === 1;

  if (selectedCompetition?.events?.length === 0 || !selectedCompetition) {
    return (
      <>
        <Alert text={t("admin.competitions.events.eventHasNoCategories")} />
        <Button text={t('global.back')} onPress={goBack} />
      </>
    )
  }


  /**
   * render
   */
  return (
    <View style={{ gap: 10 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <OWCTitle
          text={t("admin.competitions.clone.cloneEvent")}
          containerStyle={{ flex: 1 }}
        />
        <Button disabled={selectedEvents.length === 0} text={t("admin.competitions.clone.clone")} onPress={clone} />
      </View>

      {selectedCompetition.events.map((event) => {
        return (
          <View key={event.uuid} style={[styles.card, styles.spacing, isEventSelected(event.id) && styles.selected]}>
            <Selector containerStyle={{ position: 'absolute', right: 10 }} onPress={() => handleSelected(event)} defaultValue={false} />
            <Title text={event.name} uppercase fontWeight={700} />
            <Form containerStyle={{ padding: 10, width: '100%' }}>
              <Row flex={[2, 1, 1, 1]}>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.tpvCode")}:</Text> {event.tpvCode}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.rankingSystem")}:</Text> {rankingSystems.find(rs => rs.id === event.rankingSystemId)?.name}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.description")}:</Text> {event.description}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.startInscriptionDate")}:</Text> {event.startInscriptionDate}</Text>
              </Row>
              <Row flex={[2, 1, 1, 1]}>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.limitInscriptionDate")}:</Text> {event.limitInscriptionDate}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.beginDate")}:</Text> {event.beginDate}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.endDate")}:</Text> {event.endDate}</Text>
                <Text><Text bold>{t("admin.competitions.eventsCompetition.eventEditData.numNonPlayerBonusRate")}:</Text> {event?.numNonPlayerBonusRate}</Text>
              </Row>
            </Form>


            <View flexDirection="row" style={{ gap: 10, padding: 10, flexWrap: 'wrap' }}>
              {event?.eventCategories.map(ec => {
                return (
                  <View style={[{ borderWidth: 1, borderColor: 'black', borderRadius: 8, padding: 15, width: 289, }, isEventCategorySelected(ec.id) ? styles.selected : { backgroundColor: 'white' }]}>
                    {isEventSelected(event.id) && <Selector containerStyle={{ position: 'absolute', right: 10 }} onPress={() => handleEventCategoryPress(ec)} defaultValue={isEventCategorySelected(ec.id)} />}
                    <Title text={ec.name} fontSize={18} fontWeight={500} marginBottom={0} />
                    <Checkbox onChange={(v) => handleChecboxPress(ec, v, CLONE_TYPE.RATING)} disabled={!isEventCategorySelected(ec.id)} marginBottom={2} label={t('admin.competitions.clone.copyRates')} />
                    <Checkbox onChange={(v) => handleChecboxPress(ec, v, CLONE_TYPE.GROUP)} disabled={!isEventCategorySelected(ec.id)} marginBottom={2} label={t('admin.competitions.clone.copyGroups')} />
                    <Checkbox onChange={(v) => handleChecboxPress(ec, v, CLONE_TYPE.QUESTION)} disabled={!isEventCategorySelected(ec.id)} marginBottom={2} label={t('admin.competitions.clone.copyQuestions')} />
                  </View>
                )
              })}
            </View>
          </View>
        )
      })}
    </View>
  );
};

const Selector = ({ containerStyle, onPress, defaultValue }) => {
  const context = React.useContext(OWCOpenDmrContext);

  const [selected, setSelected] = React.useState(defaultValue);

  const handlePress = () => {
    setSelected(!selected);
    onPress(!selected);
  };

  return (
    <View style={[containerStyle, styles.container]}>
      <TouchableOpacity
        style={[styles.radioButton, selected && { backgroundColor: context.configuration.colors.primary.main }]}
        onPress={handlePress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 99
  },
  radioButton: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#888',
  },
  radioButtonSelected: {
    backgroundColor: '#00AAFF',
  },
  season: {
    maxWidth: 300,
  },
  seasonContainer: {
    flex: 1,
  },
  card: {
    borderColor: 'black',
    borderWidth: 1,
    borderRadius: 5
  },
  spacing: {
    padding: 15
  },
  selected: {
    backgroundColor: '#5BF2'
  }
});
