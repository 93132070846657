import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { useParams } from 'react-router-dom';
import { Alert } from "src/components/Alert";
import { ButtonLink } from 'src/components/ButtonLink';
import { Loader } from 'src/components/Loader';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import * as UserService from 'src/services/UserService';
import { UserActionCard } from './components/UserActionCard';

export const Validate = () => {
  const { code } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      await UserService.confirmRegister(code!);

      setAlert({
        type: 'success',
        text: t('public.users.validate.success'),
      });
    } catch (error: any) {
      setAlert({
        type: 'error',
        text: (error?.response?.data?.issuer === 'optimalway' && error?.response?.data?.message) || t('public.users.validate.error')
      });
    }

    setLoading(false);
  });

  /**
   * render
   */
  return (
    <UserActionCard>
      {loading ? <Loader /> : <Alert {...alert} />}
      <ButtonLink to='/login' label={t('public.users.validate.login')} />
    </UserActionCard>
  );
}