import * as React from "react";
import { Picker, t } from "owc-react-lib-common";

import { StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { IconButton } from "src/components/IconButton";
import { Loader } from "src/components/Loader";
import { ModalConfirm } from "src/components/ModalConfirm";
import { ITableHeader, Table } from "src/components/Table";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as CompetitionService from "src/services/CompetitionService";
import * as SeasonService from "src/services/SeasonService";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const CompetitionList = () => {

  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [seasons, setSeasons] = React.useState<any[]>([]);
  const [currentSeasonId, setCurrentSeasonId] = React.useState<number>();
  const [competitions, setCompetitions] = React.useState<any[]>([]);
  const [competitionToDelete, setCompetitionToDelete] = React.useState<any>(undefined);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      // load seasons
      const response = await SeasonService.findSeasons();
      setSeasons(
        response.data.map((season: any) => ({
          label: season.name,
          value: season.id,
        }))
      );

      // load competitions
      if (!!response.data?.length) {
        const seasonId = response.data[0].id;
        setCurrentSeasonId(seasonId);
        await onChangeSeason(seasonId);
      }
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  /**
   * on change
   */
  const onChangeSeason = React.useCallback(
    async (value: string) => {
      // parse value
      const seasonId = parseInt(value);
      setCurrentSeasonId(seasonId);

      // if empty
      if (!value) {
        setCompetitions([]);
        return;
      }

      // fetch competitions
      try {
        const response = await CompetitionService.findBySeason(seasonId);
        setCompetitions(response.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [alerts]
  );

  /**
   * go to create
   */
  const goToCreate = React.useCallback(() => {
    navigate("create");
  }, [navigate]);

  /**
   * go to create
   */
  const goToSeasonCreate = React.useCallback(() => {
    navigate("/organizer/seasons/create");
  }, [navigate]);

  const onDeleteCompetition = React.useCallback(async () => {
    try {
      await CompetitionService.deleteCompetition(competitionToDelete.uuid);
      setCompetitions(competitions.filter((comp: any) => comp.uuid !== competitionToDelete.uuid));
      setCompetitionToDelete(undefined);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [competitions, competitionToDelete, alerts]);

  /**
   * get table row actions
   */
  const actions = React.useCallback(
    (competition: any) => {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name={readOnly ? "eye" : "edit"}
            onPress={() => navigate(`${competition.uuid}`)}
          />

          {!readOnly && <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="trash"
            onPress={() => setCompetitionToDelete(competition)}
          />}

        </View>
      );
    },
    [navigate, readOnly]
  );

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    { name: "id", label: t("admin.competitions.list.id"), width: 100 },
    {
      name: "name",
      label: t("admin.competitions.list.name"),
      width: 300,
      textProps: { uppercase: true },
    },
    {
      name: "description",
      label: t("admin.competitions.list.description"),
      flex: 1,
    },
    {
      name: "actions",
      label: " ",
      render: actions,
      width: 100,
    },
  ];

  return (
    <Container title={t("admin.competitions.list.title")}>
      {!seasons?.length ? (
        <View>
          <Alert text={t("admin.competitions.list.seasonsNotFound")} type="info" />
          <Button label={t("admin.competitions.list.createSeason")} onPress={goToSeasonCreate} disabled={readOnly} />
        </View>
      ) : (
        <View>
          <View flexDirection="row">
            <View style={styles.seasonContainer}>
              <Picker
                label={t("admin.competitions.list.season")}
                items={seasons}
                onChange={onChangeSeason}
                containerStyle={styles.season}
                defaultValue={currentSeasonId}
              />
            </View>

            <Button label={t("admin.competitions.list.create")} onPress={goToCreate} disabled={readOnly} />
          </View>

          <Table headers={headers} data={competitions} containerStyle={{ marginBottom: 0 }} />
        </View>
      )}

      {!!competitionToDelete && (
        <ModalConfirm
          visible
          text={t('admin.competitions.list.deleteConfirmation', { competitionName: competitionToDelete.name })}
          onClose={() => setCompetitionToDelete(undefined)}
          onSuccess={onDeleteCompetition}
        />
      )}
    </Container>

  );
};

const styles = StyleSheet.create({
  header: {},
  seasonContainer: {
    flex: 1,
  },
  season: {
    maxWidth: 300,
  },
  create: {
    width: "auto",
    maxHeight: 40
  },
  button: {
    maxWidth: 50,
    marginBottom: 0,
  },
});
