import dayjs from "dayjs";
import * as React from "react";
import { t } from "owc-react-lib-common";
import { useParams } from "react-router-dom";
import { Button } from "src/components/Button";
import { Card } from "src/components/Card";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { IconButton } from "src/components/IconButton";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { Modal } from "src/components/Modal";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Separator } from "src/components/Separator";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useEffectChange } from "src/hooks/useEffectChange";
import { CUSTOM, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as HotelService from "src/services/HotelService";
import { IHotelDTO } from "src/services/HotelService";
import * as RoomTypeService from "src/services/RoomTypeService";
import { EventContext } from "../EventContext";
import { RoomCard } from "./components/RoomCard";
import { Row } from "src/components/Row/Row";
import { Form } from "src/components/Form";
import { InputDate } from "src/components/InputDate";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

interface IHotelsEdit {
  onSuccess: (object: any) => void;
  onDelete: (id: number) => void;
}

export const HotelDetail = ({ onSuccess, onDelete }: IHotelsEdit) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { hotelId } = useParams();
  const alerts = useAlerts();

  const [loading, setLoading] = React.useState(true);
  const { validateAll, email, number } = useValidation();
  const eventContext = React.useContext(EventContext);
  // form: hotel
  const form = React.useRef<any>({});
  const [errors, setErrors] = React.useState<IHotelDTO>({});
  // send hotel pdf
  const [sendPdfModal, setSendPdfModal] = React.useState(false);
  // delete hotel
  const [deleteHotelModal, setDeleteHotelModal] = React.useState(false);
  // rooms
  const [rooms, setRooms] = React.useState<any[]>([]);
  // form: room
  const roomForm = React.useRef<any>();
  const [roomModal, setRoomModal] = React.useState(false);
  const [roomError, setRoomError] = React.useState<any>({});
  // delete room modal
  const [deleteRoomModal, setDeleteRoomModal] = React.useState<number>();

  /**
   *
   */
  useEffectChange(async () => {
    if (hotelId === undefined || hotelId === null) {
      return;
    }

    setLoading(true);
    setErrors({})
    if (parseInt(hotelId) !== 0) {
      try {
        const response = await HotelService.getById(parseInt(hotelId));

        form.current = {
          ...response.data,
          formattedInDate: response.data.inDate ? dayjs(response.data.inDate).format("DD/MM/YYYY") : undefined,
          formattedOutDate: response.data.outDate ? dayjs(response.data.outDate).format("DD/MM/YYYY") : undefined,
        };

        setRooms(response.data.rooms);
      } catch (error) {
        alerts.createDSMError(error);
      }
    } else {
      await new Promise((res: any) => setTimeout(() => res(), 4)); // xd
      form.current = {};
      setErrors({})
    }

    setLoading(false);
  }, [hotelId]);

  /**
   * update hotel data
   */
  const updateHotel = React.useCallback(async () => {
    const { errors, hasErrors } = validateAll(form.current, [
      { name: "name", type: REQUIRED },
      { name: "email", type: CUSTOM, validate: () => !form.current.email || email(form.current.email) },
      { name: "phone", type: CUSTOM, validate: () => !form.current.phone || number(form.current.phone) },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    try {
      // update
      if (!!form.current.id) {
        const response = await HotelService.update({
          id: form.current?.id,
          name: form.current?.name,
          email: form.current?.email,
          phone: form.current?.phone,
          eventId: form.current?.eventId,
          contactName: form.current?.contactName,
          inDate: form.current?.inDate,
          outDate: form.current?.outDate,
        });

        onSuccess(response.data);
        alerts.create({ text: t("admin.hotels.editHotel"), type: "success" });
      }
      // create
      else {
        const response = await HotelService.create({
          ...form.current,
          eventId: eventContext.data?.id,
        });

        onSuccess(response.data);
        alerts.create({ text: t("global.success"), type: "success" });
      }
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, onSuccess, validateAll, email, number, eventContext]);

  /**
   * delete hotel data
   */
  const deleteHotel = React.useCallback(async () => {
    if (!form.current.id) {
      return;
    }

    try {
      await HotelService.deleteHotel(form.current.id);

      onDelete(form.current.id);
      setDeleteHotelModal(false);
      alerts.create({ text: t("admin.hotels.deleteHotel"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, onDelete]);

  /**
   * open create room modal
   */
  const openCreateRoomModal = React.useCallback(() => {
    roomForm.current = { type: "create" };
    setRoomError({})
    setRoomModal(true);
  }, []);

  /**
   * open edit room modal
   */
  const openEditRoomModal = React.useCallback((object: any) => {
    roomForm.current = { ...object, type: "edit" };
    setRoomError({})
    setRoomModal(true);
  }, []);

  /**
   * close edit room modal
   */
  const closeEditRoomModal = React.useCallback(() => {
    roomForm.current = undefined;
    setRoomModal(false);
  }, []);

  /**
   * update or create room
   */
  const saveRoom = React.useCallback(async () => {
    const { errors, hasErrors } = validateAll(roomForm.current, [
      { name: "description", type: [REQUIRED] },
      { name: "availableRooms", type: [REQUIRED] },
      { name: "peopleCapacity", type: [REQUIRED] },
    ]);

    setRoomError(errors);

    if (hasErrors) {
      return;
    }

    try {
      const dataPost = {
        id: roomForm.current?.id,
        description: roomForm.current?.description,
        availableRooms: roomForm.current?.availableRooms,
        peopleCapacity: parseInt(roomForm.current?.peopleCapacity),
        freeRooms: roomForm.current?.freeRooms,
        hotelId: form.current?.id,
        hotelName: form.current?.name,
      };

      // update
      if (roomForm.current?.type === "edit") {
        const response = await RoomTypeService.update(dataPost);
        setRooms(rooms.map((room) => (room.id === response.data.id ? response.data : room)));
        alerts.create({ text: t("admin.hotels.rooms.editRoom"), type: "success" });
      }
      //create
      else {
        const response = await RoomTypeService.create(dataPost);
        setRooms([...rooms, response.data]);
        alerts.create({ text: t("admin.hotels.rooms.createRoom"), type: "success" });
      }

      setRoomModal(false); // modal close
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validateAll, rooms]);

  /**
   * open delete room modal
   */
  const openDeleteRoomModal = React.useCallback((object: any) => {
    setDeleteRoomModal(object.id);
  }, []);

  /**
   * delete room
   */
  const deleteRoom = React.useCallback(
    async (id: any) => {
      try {
        await RoomTypeService.deleteRoomType(id);
        setRooms(rooms.filter((room: any) => room.id !== id));
        alerts.create({ text: t("admin.hotels.rooms.deleteRoom"), type: "success" });
      } catch (error) {
        alerts.createDSMError(error);
      }

      setDeleteRoomModal(undefined);
    },
    [alerts, rooms]
  );

  /**
   * show pdf
   */
  const showPdf = React.useCallback(async () => {
    try {
      await HotelService.getActorsCheckIn(hotelId!, form.current.name);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [hotelId, alerts]);

  /**
   * open send pdf modal
   */
  const openSendPdf = React.useCallback(() => {
    setSendPdfModal(true);
  }, []);

  const closeSendPdfModal = React.useCallback(() => {
    setSendPdfModal(false);
  }, []);

  /**
   * send pdf
   */
  const sendPdf = React.useCallback(async () => {
    try {
      await HotelService.sendActorsCheckIn(hotelId!);
      closeSendPdfModal();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [hotelId, alerts, closeSendPdfModal]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  return (
    <View>
      <View flexDirection="row" alignItems="center" marginBottom={styling.spacing}>
        <Title
          text={!!form.current.id ? form.current.name : t("admin.hotels.create")}
          containerStyle={{ flex: 1, marginBottom: 0 }}
        />

        {form.current.id > 0 && (
          <View flexDirection="row">
            <FloatingIconButton
              backgroundColor='transparent'
              color='black'
              size="medium"
              name="file"
              title="Pdf"
              onPress={showPdf}
              containerStyle={{ position: "relative", paddingHorizontal: "0.10em" }}
            />
            {!readOnly &&
              <>
                <FloatingIconButton
                  backgroundColor='transparent'
                  color='black'
                  size="medium"
                  name="email"
                  title="Email"
                  onPress={openSendPdf}
                  containerStyle={{ position: "relative", paddingHorizontal: "0.10em" }}
                />
                <FloatingIconButton
                  backgroundColor='transparent'
                  color='black'
                  size="medium"
                  name="trash"
                  title={t("global.delete")}
                  onPress={() => setDeleteHotelModal(true)}
                  containerStyle={{ position: "relative", paddingHorizontal: "0.10em" }}
                />
              </>
            }
          </View>
        )}
      </View>

      <Form>
        <Row flex={[2, 1, 1]}>
          <Input
            label={t("admin.hotels.name")}
            defaultValue={form.current?.name}
            onChange={(val) => (form.current = { ...form.current, name: val })}
            error={errors.name}
            containerStyle={{ flex: 1 }}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.hotels.email")}
            defaultValue={form.current?.email}
            onChange={(val) => (form.current = { ...form.current, email: val })}
            error={errors.email}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.hotels.phone")}
            defaultValue={form.current?.phone}
            onChange={(val) => (form.current = { ...form.current, phone: val })}
            error={errors.phone}
            disabled={readOnly}
            required
          />
        </Row>

        <Row flex={[2, 1, 1]}>

          <Input
            label={t("admin.hotels.contactName")}
            defaultValue={form.current?.contactName}
            onChange={(val) => (form.current = { ...form.current, contactName: val })}
            error={errors.contactName}
            containerStyle={{ flex: 1 }}
            disabled={readOnly}
          />
          <InputDate
            label={t("admin.hotels.inDate")}
            defaultValue={form.current?.inDate}
            onChange={(val) => form.current.inDate = val}
            error={errors.inDate}
            disabled={readOnly}
          />
          <InputDate
            label={t("admin.hotels.outDate")}
            defaultValue={form.current?.outDate}
            onChange={(val) => form.current.outDate = val}
            error={errors.outDate}
            disabled={readOnly}
          />
        </Row>

        <Row>
          <Button label={t("global.save")} onPress={updateHotel} marginBottom={0} disabled={readOnly} />
        </Row>

      </Form>

      {form.current.id > 0 && (
        <View>
          <Separator marginTop={styling.spacing} color={"black"} />

          <Title text={t("admin.hotels.rooms.title")} />

          <View flexDirection="row" alignItems="center" style={{ flexWrap: "wrap" }}>
            {rooms
              .sort((a: any, b: any) => a.peopleCapacity - b.peopleCapacity)
              .map((room: any) => (
                <RoomCard key={room.id} data={room} onEdit={openEditRoomModal} onDelete={openDeleteRoomModal} />
              ))}

            {!readOnly && (
              <Card containerStyle={{ minWidth: 210, minHeight: 261 }}>
                <View alignItems="center" justifyContent="center" flex={1}>
                  <IconButton onPress={openCreateRoomModal} fontSize={150} name="add" />
                </View>
              </Card>
            )}

          </View>

          <Modal
            visible={roomModal}
            title={roomForm.current?.description || t("admin.hotels.rooms.add")}
            onClose={closeEditRoomModal}
          >
            <Form>
              <Row>
                <Input
                  label={t("admin.hotels.rooms.description")}
                  defaultValue={roomForm.current?.description}
                  error={roomError.description}
                  onChange={(val) => (roomForm.current = { ...roomForm.current, description: val })}
                  required
                />
              </Row>

              <Row>
                <Input
                  label={t("admin.hotels.rooms.availableRooms")}
                  defaultValue={roomForm.current?.availableRooms}
                  error={roomError.availableRooms}
                  onChange={(val) => (roomForm.current = { ...roomForm.current, availableRooms: val })}
                  numeric
                  required
                />
                <Input
                  containerStyle={{ marginHorizontal: styling.spacing }}
                  label={t("admin.hotels.rooms.peopleCapacity")}
                  defaultValue={roomForm.current?.peopleCapacity}
                  error={roomError.peopleCapacity}
                  onChange={(val) => (roomForm.current = { ...roomForm.current, peopleCapacity: val })}
                  numeric
                  required
                />
                {/* <Input
                  disabled
                  label={t("admin.hotels.rooms.freeRooms")}
                  defaultValue={roomForm.current?.freeRooms}
                  onChange={(val) => (roomForm.current = { ...roomForm.current, freeRooms: val })}
                /> */}
              </Row>
              <Row>
                <Button label={t("global.save")} onPress={() => saveRoom()} marginBottom={0} />
              </Row>
            </Form>
          </Modal>

          <ModalConfirm
            visible={!!deleteRoomModal}
            text={t("admin.hotels.rooms.deleteConfirmation")}
            onSuccess={() => deleteRoom(deleteRoomModal)}
            onClose={() => setDeleteRoomModal(undefined)}
          />

          <ModalConfirm
            visible={!!deleteHotelModal}
            text={t("admin.hotels.deleteConfirmation")}
            onSuccess={() => deleteHotel()}
            onClose={() => setDeleteHotelModal(false)}
          />

        </View>
      )}

      <ModalConfirm
        visible={sendPdfModal}
        text={t("admin.hotels.rooms.sendPdfConfirmation")}
        onSuccess={sendPdf}
        onClose={closeSendPdfModal}
      />
    </View>
  );
};
