import * as React from 'react';
import { Picker, t } from 'owc-react-lib-common';

import { StyleSheet } from "react-native";
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { IImageUploaderData, ImageUploader } from 'src/components/ImageUploader';
import { Input } from "owc-react-lib-common"
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { styling } from 'src/config';
import { CUSTOM, EMAIL, IValidationParams, PHONE, REQUIRED, useValidation } from 'src/hooks/useValidation';
import { TeamCreationQuestion } from './TeamCreationQuestion';

interface IEventTeam {
  back: () => void;
  onSuccess: (object: any) => void;
  category?: any;
  levels: any[];
  // questions: any[];
  // answers: any[];
  initialData?: any;
  update?: boolean;
}

export const TeamCreationData = ({ initialData = {}, back, onSuccess, category, levels = []/*, questions = [], answers = []*/, update = false }: IEventTeam) => {
  // form
  const form = React.useRef<any>(initialData);
  const { required, validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const setName = React.useCallback((value: string) => form.current.name = value, []);
  const setContactName = React.useCallback((value: string) => form.current.contactName = value, []);
  const setContactSurname = React.useCallback((value: string) => form.current.contactSurname = value, []);
  const setContactPhone = React.useCallback((value: string) => form.current.contactPhone = value, []);
  const setContactEmail = React.useCallback((value: string) => form.current.contactEmail = value.toLocaleLowerCase(), []);
  const setObservations = React.useCallback((value: string) => form.current.observations = value, []);

  const setLevel = React.useCallback((value: any) => {
    form.current.teamLevelId = value;
    form.current.teamLevelName = levels.find(lv => parseInt(lv.id) === parseInt(value))?.name
  }, [levels]);

  const setLogo = React.useCallback((object: IImageUploaderData) => {
    form.current.originalLogoFilename = object.name;
    form.current.teamLogoFile = object.base64;
  }, []);

  const setPhoto = React.useCallback((object: IImageUploaderData) => {
    form.current.originalImgFilename = object.name;
    form.current.teamImageFile = object.base64;
  }, []);

  /**
   * on change answer
   */
  const onChangeAnswer = React.useCallback((question: any, value: string) => {
    if (!form.current.extraInformation) {
      form.current.extraInformation = [];
    }

    const index = form.current.extraInformation.findIndex((answer: any) => answer.questionId === question.id);

    if (index === undefined || index === -1) {
      form.current.extraInformation.push({ questionId: question.id, value });
    }
    else {
      form.current.extraInformation[index].value = value;
    }
  }, []);

  /**
   * validate form
   */
  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: 'name', type: REQUIRED },
      { name: 'teamLevelId', type: levels.length > 1 ? REQUIRED : null },
      { name: 'contactName', type: REQUIRED },
      { name: 'contactSurname', type: REQUIRED },
      { name: 'contactPhone', type: [REQUIRED, PHONE] },
      { name: 'contactEmail', type: [REQUIRED, EMAIL] },
    ];
    if (category?.logoRequired) fields.push({ name: 'originalLogoFilename', type: REQUIRED });
    if (category?.pictureRequired) fields.push({ name: 'originalImgFilename', type: REQUIRED });

    category?.extraInformationQuestions?.forEach((question: any) => {
      fields.push({
        name: `question-${question.id}`, type: CUSTOM, validate: () => {
          const answer = form.current?.extraInformation?.find((answer: any) => answer.questionId === question.id);
          return required(answer?.value);
        }
      });
    });

    const { errors, hasErrors } = validateAll(form.current, fields);

    setErrors(errors);
    return !hasErrors;
  }, [category, required, validateAll, levels.length]);

  /**
   * on success
   */
  const next = React.useCallback(async () => {
    // validate
    if (!validate()) {
      return;
    }

    // success
    await Promise.resolve(onSuccess(form.current));
  }, [validate, onSuccess]);

  /**
   * render
   */
  return (
    <View style={styles.container}>
      <Alert type='info' text={t('auth.competitions.teamCreation.team.step')} />

      <View style={styles.content}>
        <Text bold uppercase style={styles.text}>{t('auth.competitions.teamCreation.team.title')}</Text>

        <View flexDirection='row'>
          <Input label={t('auth.competitions.teamCreation.team.name')} required defaultValue={form.current.name} onChange={setName} error={errors.name} containerStyle={styles.name} />

          {levels.length > 0 &&
            <Picker
              defaultValue={t('auth.competitions.teamCreation.team.levelChoose')}
              label={t('auth.competitions.teamCreation.team.level')}
              items={levels.map((level: any) => ({ label: level.name, value: level.id }))}
              onChange={setLevel}
              error={errors.teamLevelId}
              containerStyle={styles.level}
              required
            />
          }

        </View>


      </View>

      {!!category?.extraInformationQuestions?.length && (
        <View style={styles.content}>
          {category?.extraInformationQuestions.map((question: any) => {
            const answer = form.current?.extraInformation?.find((answer: any) => answer.questionId === question.id);

            return (
              <TeamCreationQuestion
                key={question.id}
                data={question}
                defaultValue={answer?.value}
                onChange={(value: string) => onChangeAnswer(question, value)}
                containerStyle={{ maxWidth: 300 }}
                required
                error={errors[`question-${question.id}`]}
              />
            )
          })}
        </View>
      )}

      <View style={styles.content}>
        <Text bold uppercase style={styles.text}>{t('auth.competitions.teamCreation.team.contact')}</Text>

        <View flexDirection='row'>
          <View flexDirection='row' flex={3} marginRight={styling.spacing}>
            <Input label={t('auth.competitions.teamCreation.team.contactName')} required defaultValue={form.current.contactName} onChange={setContactName} error={errors.contactName} containerStyle={styles.contactName} />
            <Input label={t('auth.competitions.teamCreation.team.contactSurname')} required defaultValue={form.current.contactSurname} onChange={setContactSurname} error={errors.contactSurname} containerStyle={styles.contactSurname} />
            <Input label={t('auth.competitions.teamCreation.team.contactEmail')} required defaultValue={form.current.contactEmail} onChange={setContactEmail} error={errors.contactEmail} containerStyle={styles.contactEmail} />
          </View>

          <Input label={t('auth.competitions.teamCreation.team.contactPhone')} required defaultValue={form.current.contactPhone} onChange={setContactPhone} error={errors.contactPhone} containerStyle={styles.contactPhone} />
        </View>

        <Input label={t('auth.competitions.teamCreation.team.observations') + ` (${t('global.optional')})`} defaultValue={form.current.observations} onChange={setObservations} error={errors.observations} containerStyle={styles.observations} />
      </View>

      <View style={styles.content}>
        <Text bold uppercase style={styles.text}>{t('auth.competitions.teamCreation.team.image')}</Text>

        <View flexDirection='row'>
          <ImageUploader required={category?.logoRequired} label={t('auth.competitions.teamCreation.team.logo')} defaultValue={form.current.teamLogoUrl} onChange={setLogo} placeholder='https://dieselpunkcore.com/wp-content/uploads/2014/06/logo-placeholder.png' containerStyle={styles.logo} error={errors.originalLogoFilename} /*required*/ />
          <ImageUploader required={category?.pictureRequired} label={t('auth.competitions.teamCreation.team.photo')} defaultValue={form.current.teamImageUrl} onChange={setPhoto} placeholder='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYA14VTh0gOZ8Do8KWTkb3VIfuS-3RzX5LgKsGXwSM5EwnPlI_FS82s9BE2XmUP_q-VEk&usqp=CAU' error={errors.originalImgFilename} /*required*/ />
        </View>
      </View>

      {update ? (
        <View flexDirection='row' justifyContent='flex-end'>
          <Button type='primary' label={t('global.save')} onPress={next} />
        </View>
      ) : (
        <View flexDirection='row' justifyContent='space-between'>
          <Button type='primary' label={t('global.back')} onPress={back} />
          <Button type='primary' label={t('global.next')} onPress={next} />
        </View>
      )
      }
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
  },
  content: {
    // marginBottom: styling.spacing,
  },
  text: {
    marginBottom: styling.spacing,
  },
  name: {
    flex: 1,
    marginRight: styling.spacing,
    // marginBottom: 0,
  },
  level: {
    width: 150,
    // marginBottom: 0,
  },
  contactName: {
    flex: 1,
    marginRight: styling.spacing,
  },
  contactSurname: {
    flex: 1,
    marginRight: styling.spacing,
  },
  contactEmail: {
    flex: 1,
  },
  contactPhone: {
    width: 150,
  },
  observations: {
    // marginBottom: 0,
  },
  logo: {
    marginRight: styling.spacing,
  },
});