import { BACKEND_URL } from "../config";
import * as RestService from "./RestService";

const BASE_URL = BACKEND_URL + "/auth/ranking-system";

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const getRankingSystem = () => {
  return RestService.get(`${BASE_URL}`);
};

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const getRankingSystemByUuid = (uuid: string) => {
  return RestService.get(`${BASE_URL}/${uuid}`);
};

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const findRankingSystemDetailsByRankingSystemUuid = (uuid: string) => {
  return RestService.get(`${BASE_URL}/detail/${uuid}`);
};


/**
 * @returns Devuelve el sistema creado
 */
export const createRankingSystem = (payload) => {
  return RestService.post(`${BASE_URL}`, payload);
};

/**
 * @returns Devuelve el detalle creado
 */
export const createRankingSystemDetail = (payload) => {
  return RestService.post(`${BASE_URL}/detail`, payload);
};

/**
 * @returns void
 */
export const deleteRankingSystem = (id: number) => {
  return RestService.delete(`${BASE_URL}/${id}`);
};

/**
 * @returns void
 */
export const deleteRankingSystemDetail = (id: number) => {
  return RestService.delete(`${BASE_URL}/detail/${id}`);
};

/**
 * @returns Devuelve el sistema modificado
 */
export const updateRankingSystem = (payload: any) => {
  return RestService.put(`${BASE_URL}`, payload);
};
/**
 * @returns Devuelve el detalle modificado
 */
export const updateRankingSystemDetail = (payload: any) => {
  return RestService.put(`${BASE_URL}/detail`, payload);
};



