import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { ITableHeader, Table } from "src/components/Table";
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { IconButton } from 'src/components/IconButton';
import { useSearchParams } from 'react-router-dom';

interface IEventCategory {
  categories: any[];
  onSuccess: (object: any) => void;
  editable: boolean;
}

export const TeamCreationCategory = ({ categories, onSuccess, editable }: IEventCategory) => {
  const [, setParams] = useSearchParams();
  const addParams = React.useCallback((id: any) => {
    onSuccess(id)
    setParams({ 'eventCategoryId': id })
  }, [onSuccess, setParams])
  /**
   * actions
   */
  const actions = React.useCallback((object: any) => {
    if (!editable) {
      return null;
    }
    if (!object?.inscriptionOpen) {
      return (
        <View flexDirection='row' alignItems='center'>
          <Text uppercase textAlign='center' color='crimson'>
            {t('auth.competitions.teamCreation.category.enrollmentClosed')}
          </Text>
          <IconButton containerStyle={{ width: 30, height: 30 }} onPress={() => { addParams(object.id) }} name="info-circle" />
        </View>
      );
    }
    return (
      <View flexDirection='row' justifyContent='center'>
        <Button type='primary' label={t('auth.competitions.teamCreation.category.register')} onPress={() => onSuccess(object)} containerStyle={{ marginBottom: 0 }} />
      </View>
    );
  }, [onSuccess, editable, addParams]);

  /**
   * render
   */
  const genders: any = {
    'F': t('auth.competitions.teamCreation.category.genderF'),
    'M': t('auth.competitions.teamCreation.category.genderM'),
    'X': t('auth.competitions.teamCreation.category.genderX'),
  };

  const headers: ITableHeader[] = [
    { name: 'name', label: t('auth.competitions.teamCreation.category.name') },
    { name: 'gender', label: t('auth.competitions.teamCreation.category.gender'), render: (object: any) => <Text>{genders[object.gender]}</Text>, width: 100 },
    { name: 'age', label: t('auth.competitions.teamCreation.category.age'), render: (object: any) => <Text>{!!object.maxAge ? t('auth.competitions.teamCreation.category.maxAge', { amount: object.maxAge }) : t('global.undefined')}</Text>, width: 100 },
    { name: 'players', label: t('auth.competitions.teamCreation.category.players'), render: (object: any) => <Text>{object.minPlayers || 1} - {object.maxPlayers || 99}</Text>, width: 120 },
    { name: 'coaches', label: t('auth.competitions.teamCreation.category.coaches'), render: (object: any) => <Text>1 - {object.maxCoaches}</Text>, width: 130 },
    { name: 'actions', label: ' ', render: actions, width: 190 }
  ];

  return (
    <React.Fragment>
      <Alert type='info' text={t('auth.competitions.teamCreation.category.step')} />
      <Table pagination={false} headers={headers} data={categories.filter(c=>c.visibility || c.isInvitation)} />
    </React.Fragment>
  );
}