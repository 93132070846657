import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


export interface ITeamActorDTO {
  id?: number;
  uuid?: string;
  number?: number;
  rateId?: number;
  finalPrice?: number;
  roleId?: number;
  teamId?: number;
  teamUuid?: string;
  teamName?: string;
  peopleId?: number;
  peopleName?: string;
  peopleUuid?: string;
  peopleEmail?: string;
  peopleIdDocType?: number;
  peopleIdDoc?: string;
  peopleAdress?: string;
  peopleExternalUuid?: string;
  peopleTownId?: number;
  pepoleBirthdate?: any;
  peopleZipCode?: string;
  peoplePhone?: string;
  roleDescription?: string;
  gender?: string;
  deleted?: any;
  observations?: string;
  shirtSize?: string;
  notificationDate?: any;
  registrationStatus?: number;
}

//onst AUTH_BASE_URL = BACKEND_URL + '/auth/actors'
const PUBLIC_BASE_URL = BACKEND_URL + '/public/actors'

/**
* 
Gestiona todo el proceso de inscripción, acceptación y firma de documentos... por parte de los representantes
*/
export const registration = (actorUuid: string) => {
  return RestService.get(`${PUBLIC_BASE_URL}/${actorUuid}/registration`);
}

export const start = (actorUuid: string) => {
  return RestService.post(`${PUBLIC_BASE_URL}/${actorUuid}/registration`);
}

export const update = (actorUuid: string, teamActorDTO: ITeamActorDTO) => {
  return RestService.post(`${PUBLIC_BASE_URL}/${actorUuid}/registration/update`, teamActorDTO);
}

export const textAccepted = (registration: any) => {
  return RestService.post(`${PUBLIC_BASE_URL}/registration/text-accepted`, registration);
}

export const questionsAnswered = (registration: any) => {
  return RestService.post(`${PUBLIC_BASE_URL}/registration/questions-answered`, registration);
}

export const restart = (actor: any) => {
  return RestService.post(`${PUBLIC_BASE_URL}/registration/restart  `, actor);
}

export const actor = (actorUuid: string) => {
  return RestService.get(`${PUBLIC_BASE_URL}/${actorUuid}`);
}

export const iniPaymentProcess = (actor: any) => {
  return RestService.post(`${PUBLIC_BASE_URL}/registration/ini-payment-process  `, actor);
}

export const familyRelations = () => {
  return RestService.get(`${BACKEND_URL}/public/family-relations`);
}
