import { Button, Checkbox, Input, Picker, t, Text } from 'owc-react-lib-common';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Form } from 'src/components/Form';
import { IImageUploaderData, ImageUploader } from 'src/components/ImageUploader';
import { Row } from 'src/components/Row/Row';
import { View } from 'src/components/View';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { useAlerts } from 'src/hooks/useAlerts';
import { EMAIL, PHONE, REQUIRED, useValidation } from 'src/hooks/useValidation';
import * as Team3x3Service from 'src/services/Team3x3Service';

interface ITeam3x3Data {
  initialData: any;
  onSave: (object: any) => void;
  onCancel: () => void;
}

export const Team3x3Data = ({ initialData, onSave, onCancel }: ITeam3x3Data) => {
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const form = React.useRef(initialData);
  const { createDSMError } = useAlerts();
  const [accepted, setAccepted] = React.useState<boolean>(false);
  const context = React.useContext(OWCOpenDmrContext);

  /**
   * setters
   */
  const setName = React.useCallback((value: string) => form.current.name = value, []);
  const setGender = React.useCallback((value: string) => form.current.gender = value, []);
  const setContactName = React.useCallback((value: string) => form.current.contactName = value, []);
  const setcontactSurname = React.useCallback((value: string) => form.current.contactSurname = value, []);
  const setContactEmail = React.useCallback((value: string) => form.current.contactEmail = value, []);
  const setContactPhone = React.useCallback((value: string) => form.current.contactPhone = value, []);
  const setObservations = React.useCallback((value: string) => form.current.observations = value, []);

  const setLogo = React.useCallback((object: IImageUploaderData) => {
    form.current.originalLogoFilename = object.name;
    form.current.teamLogoFile = object.base64;
  }, []);

  const setImage = React.useCallback((object: IImageUploaderData) => {
    form.current.originalImgFilename = object.name;
    form.current.teamImageFile = object.base64;
  }, []);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    // validate
    const { errors, hasErrors } = validateAll(form.current, [
      { name: 'name', type: REQUIRED },
      { name: 'gender', type: REQUIRED },
      { name: 'contactName', type: REQUIRED },
      { name: 'contactSurname', type: REQUIRED },
      { name: 'contactEmail', type: [REQUIRED, EMAIL] },
      { name: 'contactPhone', type: [REQUIRED, PHONE] },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // save request
    try {
      const response = await Team3x3Service.save(form.current);
      onSave(response.data)
    } catch (error) {
      createDSMError(error);
    }
  }, [onSave, createDSMError, validateAll]);

  /**
   * render
   */
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.data}>
          {/* <Title text={t('3x3.teams.data.title')} /> */}

          <Form>
            <Row flex={[3, 1]}>
              <Input label={t('3x3.teams.data.teamName')} required defaultValue={initialData.name} onChange={setName} containerStyle={styles.input} error={errors.name} />
              <Picker label={t('3x3.teams.data.gender')} required defaultValue={initialData.gender} items={[{ label: t('global.female'), value: 'F' }, { label: t('global.male'), value: 'M' }, { label: t('global.gender.mixed'), value: 'X' }]} onChange={setGender} containerStyle={styles.input} error={errors.gender} />
            </Row>

            <Row>
              <Input label={t('3x3.teams.data.contactName')} required defaultValue={initialData.contactName} onChange={setContactName} containerStyle={styles.input} error={errors.contactName} />
              <Input label={t('3x3.teams.data.contactSurname')} required defaultValue={initialData.contactSurname} onChange={setcontactSurname} containerStyle={styles.input} error={errors.contactSurname} />
              <Input label={t('3x3.teams.data.contactEmail')} required defaultValue={initialData.contactEmail} onChange={setContactEmail} containerStyle={styles.input} error={errors.contactEmail} />
              <Input label={t('3x3.teams.data.contactPhone')} required defaultValue={initialData.contactPhone} onChange={setContactPhone} containerStyle={styles.input} error={errors.contactPhone} />
            </Row>

            <Row>
              <Input label={t('3x3.teams.data.observations')} defaultValue={initialData.observations} numberOfLines={8} onChange={setObservations} containerStyle={styles.input} error={errors.observations} />
            </Row>

            <View flexDirection='row' justifyContent='flex-start' style={{ width: '100%' }}>
              <ImageUploader label={t('3x3.teams.data.logo')} defaultValue={initialData.teamLogoUrl} onChange={setLogo} containerStyle={{ marginRight: 6 }} error={errors.teamLogoFile} />
              <ImageUploader label={t('3x3.teams.data.teamPhoto')} defaultValue={initialData.teamImageUrl} onChange={setImage} error={errors.teamImageFile} />
            </View>

            <View flexDirection='row' alignItems='center' style={{ marginBottom: 15 }}>
              <Checkbox marginBottom={0} onChange={setAccepted} containerStyle={{ maginBottom: 0, marginRight: 5 }} />
              <Text>{t('3x3.teams.data.lopd')}</Text>
            </View>
            {
              context.configuration.portal.teamLopd &&
              <View>
                <a href={context.configuration.portal.teamLopd} target="_blank" rel="noreferrer">{t('3x3.teams.data.downloadLopd')}</a>
              </View>
            }
          </Form>
        </View>
      </View>

      <View style={styles.buttons} alignItems='center' flexDirection='row'>
        <Button text={t('global.save')} onPress={save} containerStyle={styles.button} disabled={!accepted} />
        <Button type='link' text={t('global.cancel')} onPress={onCancel} color='#333' marginBottom={0} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    // maxWidth: 800,
    // width: '100%',
    // marginHorizontal: 'auto',
  },
  data: {
    flex: 1,
  },
  form: {
    alignItems: 'center',
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    // padding: styling.spacing,
    borderTopWidth: 1,
    borderColor: '#eee',
    paddingTop: 15,
  },
  button: {
    marginRight: 0,
    marginBottom: 0,
  },
  input: {
    flex: 1,
    margin: 0,
    padding: 0,
    // width: '100%',
  },
});