import * as React from 'react';
import { t } from "owc-react-lib-common";
import { View } from "src/components/View";
import { ITableHeader, Table } from "src/components/Table";
import { IconButton } from 'src/components/IconButton';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Form } from 'src/components/Form';
import { Row } from 'src/components/Row/Row';
import { Input } from 'owc-react-lib-common';
import { IValidationParams, NUMBER, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as TeamLevelService from 'src/services/TeamLevelService';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { useAlerts } from 'src/hooks/useAlerts';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

interface IEventCategoryTeamLevels {
  data: any;
}

export const EventCategoryTeamLevels = ({ data }: IEventCategoryTeamLevels) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [teamLevelToUpdate, setTeamLevelToUpdate] = React.useState<any>({});
  const [teamLevelToDelete, setTeamLevelToDelete] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();
  const alerts = useAlerts();
  const form = React.useRef<any>({});

  const setName = React.useCallback((value: string) => form.current.name = value, []);
  const setOrder = React.useCallback((value: string) => form.current.order = value, []);
  const setEventCategoryId = React.useCallback((value: string) => form.current.eventCategoryId = value, []);

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "order", type: [REQUIRED, NUMBER] }
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);


  const createTeamLevel = React.useCallback(async () => {
    if (!validate()) return;

    const payload = {
      eventCategoryId: form.current.eventCategoryId,
      name: form.current.name,
      order: form.current.order
    }

    try {
      const response = await TeamLevelService.createTeamLevel(payload);
      alerts.create({ text: t("admin.events.teamLevel.createConfirmation"), type: "success", });
      if (!data.teamLevels) {
        data.teamLevels = [];
      }
      data.teamLevels.push(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setOpenModal(false)
  }, [alerts, data, validate]);

  const updateTeamLevel = React.useCallback(async () => {
    if (!validate()) return;

    const payload = {
      id: teamLevelToUpdate.id,
      eventCategoryId: form.current.eventCategoryId,
      name: form.current.name,
      order: form.current.order
    }

    try {
      const response = await TeamLevelService.updateTeamLevel(payload);
      alerts.create({ text: t("admin.events.teamLevel.updateConfirmation"), type: "success", });
      data.teamLevels = data.teamLevels.map(teamLevel => {
        if (teamLevel.id === response.data.id) {
          teamLevel = response.data;
        }
        return teamLevel;
      });
      setTeamLevelToUpdate({});
      setOpenModal(false);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [teamLevelToUpdate, alerts, data, validate]);

  const deleteTeamLevel = React.useCallback(async () => {
    try {
      await TeamLevelService.deleteTeamLevel(teamLevelToDelete.id);
      alerts.create({ text: t("admin.events.teamLevel.deleteConfirmation"), type: "success", });
      data.teamLevels = data.teamLevels.filter(teamLevel => teamLevel.id !== teamLevelToDelete.id)
      setTeamLevelToDelete({});
      setDeleteVisible(false);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [teamLevelToDelete, alerts, data])

  const actions = React.useCallback(
    (teamLevel: any) => {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="edit"
            onPress={() => {
              form.current = teamLevel
              setErrors({})
              setEventCategoryId(data.id);
              setTeamLevelToUpdate(teamLevel);
              setOpenModal(true);
            }}
          />
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="trash"
            onPress={() => {
              setTeamLevelToDelete(teamLevel);
              setDeleteVisible(true);
            }}
          />
        </View>
      );
    },
    [data, setEventCategoryId]
  );

  const headers: ITableHeader[] = [
    {
      name: "order",
      label: t("admin.events.teamLevel.order"),
      width: 100,
    },
    {
      name: "name",
      label: t("admin.events.teamLevel.name"),
    },
    { name: "actions", label: " ", render: actions, width: 100, hidden: readOnly },
  ];

  return (
    <View>
      <Button
        label={t('admin.events.teamLevel.create')}
        disabled={readOnly}
        onPress={() => {
          form.current = {}
          setErrors({})
          setEventCategoryId(data.id);
          setOpenModal(true);
          setTeamLevelToUpdate({});
        }}
      />

      <Table data={data.teamLevels} headers={headers} />

      <Modal
        title={teamLevelToUpdate.id ? t('admin.events.teamLevel.update') : t('admin.events.teamLevel.create')}
        visible={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Form>
          <Row flex={[3, 1]}>
            <Input onChange={setName} label={t('admin.events.teamLevel.name')} error={errors.name} defaultValue={form.current.name} required />
            <Input onChange={setOrder} label={t('admin.events.teamLevel.order')} error={errors.order} defaultValue={form.current.order} numeric required />
          </Row>
          <Row>
            {teamLevelToUpdate.id
              ? <Button label={t("global.update")} onPress={updateTeamLevel} />
              : <Button label={t("global.create")} onPress={createTeamLevel} />
            }
          </Row>
        </Form>
      </Modal>

      <ModalConfirm visible={deleteVisible} onSuccess={deleteTeamLevel} onClose={() => { setDeleteVisible(false) }} text={t('admin.events.teamLevel.deleteQuestion')} />
    </View>
  );
};
