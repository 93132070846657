import { StyleSheet } from "react-native";
import { View } from "./View";
import { Loader } from "./Loader";

export const BackgroundLoader = () => {
  return (
    <View style={styles.container}>
      <View style={styles.background}>
        <Loader color={styles.background} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 30,
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  background: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
  },
});