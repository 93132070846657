import { BACKEND_URL } from "src/config";
import * as RestService from "src/services/RestService";


const PUBLIC_BASE_URL = BACKEND_URL + "/public/portal";
const PRIVATE_BASE_URL = BACKEND_URL + "/auth/portals";

export interface IPortal {
    id: number;
    portal: string;
    logo: string;
    secondarylogo: string;
    background: string;
    teamLopd: string;
    actorLopd: string;
    colors: {
        default: {
            main: string;
            light: string;
            dark: string;
            accent: string;
            text: string;
        };
        primary: {
            main: string;
            light: string;
            dark: string;
            accent: string;
            text: string;
        };
        secondary: {
            main: string;
            light: string;
            dark: string;
            accent: string;
            text: string;
        };
        error: {
            main: string;
            light: string;
            dark: string;
            accent: string;
            text: string;
        };
        success: {
            main: string;
            light: string;
            dark: string;
            accent: string;
            text: string;
        };
    };
    availableLanguages: string[];
    modalities: IModality[];
}

export interface IModality{
    id: number,
    name: string,
    uuid: string
}

export const getPortal = () => {
    return RestService.get(`${PUBLIC_BASE_URL}`);
};

export const getPortals = () => {
    return RestService.getTyped<IPortal[]>(`${PRIVATE_BASE_URL}`);
};
