import { Route, Routes as RRDRoutes } from 'react-router-dom';
import { ActorRegistration } from 'src/pages/public/actor/ActorRegistration';
import { Organizer } from './Organizer';
import { Auth } from './Auth';
import { Public } from './Public';
import { Admin } from './Admin';

export const Routes = () => {
	return (
		<RRDRoutes>
			<Route path='public/actor/:uuid' element={<ActorRegistration />} />
			<Route path='auth/*' element={<Auth />} />
			<Route path='organizer/*' element={<Organizer />} />
			<Route path='admin/*' element={<Admin />} />
			<Route path='*' element={<Public />} />
		</RRDRoutes>
	)
}