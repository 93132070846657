import * as React from "react";
import { t, useComponentDidMount } from "owc-react-lib-common";
import { Title } from "src/components/Title";
import { EventContext } from "../EventContext";
import { useAlerts } from "src/hooks/useAlerts";
import * as CourtService from "src/services/CourtService";
import * as CourtEventService from "src/services/CourtEventService";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import { IconButton } from "src/components/IconButton";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const EventCourts = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const { data: event } = React.useContext(EventContext);
  const [data, setData] = React.useState<any[]>([]);
  const [organizerCourts, setOrganizerCourts] = React.useState<any[]>([]);
  const [addCourtModal, setAddCourtModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);

  useComponentDidMount(async () => {
    setLoading(true);
    try {
      const [eventCourtsData, organizerCourtsData] = await Promise.all([
        CourtEventService.getCourtEventsByEventId(event.id),
        CourtService.getCourtsByOrganizerId()
      ]);
      setData(eventCourtsData.data);
      const eventCourtsIds = eventCourtsData.data.map((eventCourt: any) => eventCourt.courtId);
      setOrganizerCourts(organizerCourtsData.data.filter((organizerCourt: any) => !eventCourtsIds.includes(organizerCourt.id)));
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  });

  const addCourtEvent = React.useCallback(
    async (organizerCourtToAdd: any) => {
      const courtEvent: CourtEventService.ICourtEventDTO = {
        eventId: event.id,
        courtId: organizerCourtToAdd.id,
      };
      try {
        const response = await CourtEventService.createCourtEvent(courtEvent);
        alerts.create({ text: t("admin.events.court.addConfirmation"), type: "success", });
        setData([...data, response.data]);
        setOrganizerCourts(organizerCourts.filter((organizerCourt: any) => organizerCourt.id !== response.data.courtId));
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [data, organizerCourts, alerts, event]
  );

  const deleteCourtEvent = React.useCallback(async (courtEventToDelete: any) => {
    try {
      await CourtEventService.deleteCourtEvent(courtEventToDelete.id);
      alerts.create({ text: t("admin.events.court.deleteConfirmation"), type: "success", });

      setData(data.filter((courtEvent: any) => courtEvent.id !== courtEventToDelete.id));
      const organizerCourt = { ...courtEventToDelete };
      delete Object.assign(organizerCourt, { id: organizerCourt.courtId }).courtId;
      delete Object.assign(organizerCourt, { name: organizerCourt.courtName }).courtName;
      delete Object.assign(organizerCourt, { description: organizerCourt.courtDescription }).courtDescription;
      delete Object.assign(organizerCourt, { isCovered: organizerCourt.courtIsCovered }).courtIsCovered;
      setOrganizerCourts([...organizerCourts, organizerCourt]);
    } catch (error) {
      alerts.createDSMError(error);
    }
  },
    [data, organizerCourts, alerts]
  );

  if (loading) {
    return <Loader />;
  }

  const eventCourtsHeaders: ITableHeader[] = [
    { name: "courtId", label: t("admin.courts.id"), width: 90 },
    {
      name: "facilityName",
      label: t("admin.courts.facility"),
      textProps: { uppercase: true },
      width: 300,
    },
    {
      name: "courtName",
      label: t("admin.courts.name"),
      textProps: { uppercase: true },
    },
    {
      name: "courtDescription",
      label: t("admin.courts.description"),
      textProps: { uppercase: true },
    },
    {
      name: "courtIsCovered",
      label: t("admin.courts.isCovered"),
      render: (object: any) => <Text>{object.courtIsCovered ? t("global.yes") : t("global.no")}</Text>,
    },
    {
      name: "actions",
      label: "",
      render: (object: any) =>
        <IconButton
          backgroundColor={'white'}
          color={'black'}
          name="ko"
          title={t('global.quit')}
          onPress={() => deleteCourtEvent(object)}
        />,
      width: 90,
      hidden: readOnly
    },
  ];

  const organizerCourtsHeaders: ITableHeader[] = [
    { name: "id", label: t("admin.courts.id"), width: 90 },
    {
      name: "facilityName",
      label: t("admin.courts.facility"),
      textProps: { uppercase: true },
      width: 300,
    },
    {
      name: "name",
      label: t("admin.courts.name"),
      textProps: { uppercase: true },
    },
    {
      name: "description",
      label: t("admin.courts.description"),
      textProps: { uppercase: true },
    },
    {
      name: "isCovered",
      label: t("admin.courts.isCovered"),
      render: (object: any) => <Text>{object.isCovered ? t("global.yes") : t("global.no")}</Text>,
    },
    {
      name: "actions",
      label: "",
      render: (object: any) =>
        <IconButton
          backgroundColor={'white'}
          color={'black'}
          name="add"
          onPress={() => addCourtEvent(object)}
        />,
      width: 90
    },
  ];

  return (
    <>
      <Title text={t("admin.events.court.title")}></Title>
      <Button
        label={t("admin.events.court.add")}
        onPress={() => setAddCourtModal(true)}
        disabled={readOnly}
      />
      <Table headers={eventCourtsHeaders} data={data} />
      <Modal
        title={t("admin.events.court.add")}
        visible={addCourtModal}
        maxWidth={1000}
        onClose={() => {
          setAddCourtModal(false);
        }}
      >
        <Table headers={organizerCourtsHeaders} data={organizerCourts} />
      </Modal>
    </>
  );
};
