import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { StyleSheet } from "react-native";
import { Button } from "./Button";
import { IModal, Modal } from "./Modal";
import { Text } from "./Text";
import { View } from "./View";

interface IModalConfirm extends IModal {
  onSuccess: () => void;
  text: string;
  successLabel?: string;
  closeLabel?: string;
}

export const ModalConfirm = ({ visible, title, successLabel, onSuccess, closeLabel, onClose, text }: IModalConfirm) => {

  return (
    <Modal visible={visible} title={title || t('global.confirmation')} onClose={onClose} maxWidth={400}>
      <View style={styles.content}>
        <Text>{text}</Text>
      </View>

      <View style={styles.buttons}>
        <Button type='link' label={closeLabel || t('global.cancel')} onPress={onClose} containerStyle={styles.button} marginBottom={0} />
        <Button type='primary' label={successLabel || t('global.accept')} onPress={onSuccess} containerStyle={[styles.button, styles.accept]} marginBottom={0} />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingVertical: 10,
    marginBottom: 15,
    justifyContent: 'center',
  },
  buttons: {
    borderTopWidth: 1,
    borderColor: '#ddd',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 15,
  },
  button: {
    marginBottom: 0,
    // flex: 1,
  },
  accept: {
    marginLeft: 20,
  },
});