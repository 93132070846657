import { Checkbox as OWCCheckbox, t } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet, ViewProps } from "react-native";
import { View } from "./View";

interface ICheckbox {
  label?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  value?: boolean;
  containerStyle?: ViewProps["style"];
  required?: boolean;
  error?: string;
  marginBottom?: number;
}

export const Checkbox = ({
  label,
  value,
  defaultValue,
  disabled = false,
  onChange,
  containerStyle,
  required = false,
  error,
  marginBottom
}: ICheckbox) => {
  const handleChange = React.useCallback(
    (checked: boolean) => {
      onChange?.(checked);
    },
    [onChange]
  );

  const finalLabel = !label
    ? ""
    : `${label} ${required !== undefined ? (required ? "*" : `(${t("global.optional").toLowerCase()})`) : ""}`;

  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={[styles.container, containerStyle]}>
        <OWCCheckbox
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={handleChange}
          value={value}
          label={finalLabel}
          error={error}
          marginBottom={marginBottom}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // marginBottom: styling.spacing,
    alignItems: "center",
    flexDirection: "row",
  },
});
