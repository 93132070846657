import * as React from "react";
import { Checkbox } from "owc-react-lib-common";
import { View } from "../View";

export const GroupSelector = ({ groupedExternalEvents, onAdd, onRemove }) => {
    return (
        <View flexDirection="row" style={{}}>
            {groupedExternalEvents.sort((a: any, b: any) => Object.keys(a)[0].localeCompare(Object.keys(b)[0])).map(event => {
                return Object.entries(event).map((key, value) => {
                    const category = key[value];
                    const groups = event[key[value] as any];
                    const groupEntries = Object.entries(groups).map((entry) => {
                        const id = Object.values(entry[1]).map((entryValue) => {
                            return entryValue[0]?.matchGroupId;
                        })[0]
                        return {
                            id: id,
                            name: entry[0]
                        }
                    })

                    groupEntries.sort((a, b) => a.name.localeCompare(b.name))


                    return (
                        <Selector groupEntries={groupEntries} category={category} onAdd={onAdd} onRemove={onRemove} />
                    )
                })
            })}
        </View>

    )
}

export const Selector = ({ groupEntries, category, onAdd, onRemove }) => {
    const [selected, setSelected] = React.useState<any>([]);

    const toggleAll = React.useCallback((isCheck) => {
        isCheck ? setSelected(groupEntries) : setSelected([]);

        groupEntries.forEach(entry => {
            if (isCheck) {
                if (!selected.includes(entry)) {
                    onAdd(entry.id)
                }
            } else {
                onRemove(entry.id)
            }
        })

    }, [onAdd, onRemove, groupEntries, selected]);

    const add = (groupEntry) => {
        setSelected([...selected, groupEntry])
        onAdd(groupEntry.id);
    }

    const remove = (groupEntry) => {
        setSelected([...selected.filter(e => e.id !== groupEntry.id)])
        onRemove(groupEntry.id);
    }

    return (
        <View style={{ padding: 10, flex: 1, minWidth: 250 }}>
            <Checkbox labelStyle={{ fontWeight: 800 }} value={groupEntries.length === selected.length} label={category as any} onChange={(isCheck) => toggleAll(isCheck)} />
            {groupEntries.map(entry => {
                return <Checkbox value={selected.includes(entry)} label={entry.name} key={entry.id} onChange={(isCheck) => { isCheck ? add(entry) : remove(entry) }} />
            })}
        </View>
    );
}