import * as React from "react";
import { Card as OWCCard, Image } from 'owc-react-lib-common';
import { ViewProps } from 'react-native';
import { View } from 'src/components/View';


export interface ICardMedia {
  height?: string;
  image?: string;
  alt?: string;
};

export interface ICard {
  children?: any;
  cardMedia?: ICardMedia;
  title?: string;
  description?: string;
  containerStyle?: ViewProps['style'];
  contentStyle?: ViewProps['style'];
};

export const Card = ({ children, cardMedia, title, description, containerStyle, contentStyle }: ICard) => {
  return (
    <View style={[{ padding: 10 }]}>
      <OWCCard containerStyle={
        [containerStyle, {
          flex: 1,
          display: 'flex',
          alignItems: 'normal',
          justifyContent: 'normal',
          backgroundColor: 'rgb(250, 250, 250)',
          border: 'none',
          boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px"
        }]
      }>
        {!!cardMedia && (
          <Image url={cardMedia?.image} height={cardMedia?.height} ></Image>
        )}
        <View style={[{ margin: "auto" }, contentStyle]}>
          {!!title && <View >{title}</View>}
          {!!description && <View >{description}</View>}

          <View style={{ flex: 1 }}>
            {children}
          </View>
        </View>
      </OWCCard>
    </View>
  );
}
