import * as React from 'react';
import { Checkbox, Picker, Separator, t } from 'owc-react-lib-common';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { Modal } from 'src/components/Modal';
import { RadioGroup } from 'src/components/RadioGroup';
import { MatchResult } from './MatchResult';
import * as MatchResultService from 'src/services/MatchResultService';
import * as _ from 'lodash';
import { useAlerts } from 'src/hooks/useAlerts';
import { ITableHeader, Table } from 'src/components/Table';
import { PhotoCircle } from 'src/components/PhotoCircle';
import { IconButton } from 'src/components/IconButton';
import * as StringUtils from 'src/utils/StringUtils';

export const KnockOff = ({ data, hasThirdPlace, teams, onUpdateMatch = () => undefined, onReplaceTeam = (e) => undefined, courts }) => {
	const [hoveredItem, setHoveredItem] = React.useState(null);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [resultModalVisible, setResultModalVisible] = React.useState(false);
	const [thirdPlace, setThirdPlace] = React.useState<any>({ gamesOrder: [] });
	const [isThirdUpdating] = React.useState<any>()
	const [phaseToUpdate, setPhaseToUpdate] = React.useState(null);
	const [updateGameOrder, setUpdateGameOrder] = React.useState([]);
	const [matchToUpdate, setMatchToUpdate] = React.useState({});
	const [matchCallToUpdate, setMatchCallToUpdate] = React.useState({});
	const alerts = useAlerts();
	const [phases, setPhases] = React.useState([]);

	const [teamToReplace, setTeamToReplace] = React.useState<any>({});
	const [replaceModal, setReplaceModal] = React.useState<boolean>(false);

	const [updateResultModal, setUpdateResultModal] = React.useState<boolean>(false);
	const form = React.useRef<any>({});



	/**
	 * on change data
	 */
	React.useEffect(() => {
		const copy = data.map((ko: any) => {
			return {
				...ko,
				gamesOrder: ko.gamesOrder || ko.gamesOrderArray,
				phaseNum: ko.phaseNum || ko.numPhase,
			}
		})

		if (hasThirdPlace) {
			setThirdPlace(copy.pop());
		}

		//@ts-ignore
		setPhases(_.groupBy(copy, 'phaseNum'))
	}, [data, hasThirdPlace]);

	/**
	 * add result
	 */
	const addResult = async (matchResult: any) => {
		setResultModalVisible(false);

		try {
			await MatchResultService.addMatchResult(matchResult);
			onUpdateMatch?.();
		} catch (error) {
			alerts.createDSMError(error);
		}
	}

	const updateResult = async (matchResult: any) => {
		setUpdateResultModal(false)

		try {
			await MatchResultService.updateMatchResult(matchResult);
			onUpdateMatch?.();
		} catch (error) {
			alerts.createDSMError(error);
		}

	}

	/**
	 * render playoff
	 */
	const renderPlayoff = (playoff: any, index: number, phase: number) => {
		const firstTeamId = playoff?.firstTeamId || playoff?.matches?.[0]?.homeTeamId;
		const firstTeamName = `${playoff?.matches?.[0]?.homeTeamRef ? '[' + StringUtils.minifyTeamName(playoff?.matches?.[0]?.homeTeamRef)?.toUpperCase() + '] ' : ''}${playoff?.firstTeamName || playoff?.matches?.[0]?.homeTeamName}`;
		const secondTeamId = playoff?.secondTeamId || playoff?.matches?.[0]?.awayTeamId;
		const secondTeamName = `${playoff?.matches?.[0]?.awayTeamRef ? '[' + StringUtils.minifyTeamName(playoff?.matches?.[0]?.awayTeamRef)?.toUpperCase() + '] ' : ''}${playoff?.secondTeamName || playoff?.matches?.[0]?.awayTeamName}`;

		return (
			<View key={index} flexDirection='row' alignItems='center' style={styles.playoffContainer}>
				<View flexDirection='row' style={[styles.playoff, styles.bordering]} >
					<View flex={1} justifyContent='center' style={[{ borderRightWidth: 1, borderColor: '#cccccc' }]}>
						<Hoverable style={[{ flex: 1 }, hoveredItem === firstTeamId && firstTeamId !== null ? { backgroundColor: '#eeeeee' } : { backgroundColor: 'transparent' }]}>
							{({ hovered }) => {
								(hovered) && setHoveredItem(firstTeamId);

								return (
									<TouchableOpacity
										style={{ flexDirection: 'row', alignItems: 'center', height: '100%' }}
										onPress={() => {
											setTeamToReplace({
												presentTeam: firstTeamId,
												knockoutId: playoff.id,
												rival: secondTeamId
											})
											setReplaceModal(true)
										}}
									>
										<Text style={[styles.spacing, { marginTop: 10, padding: 5 }]}>{firstTeamName}</Text>
									</TouchableOpacity>
								)
							}}
						</Hoverable>

						<Separator height={1} backgroundColor='#ccc' />

						<Hoverable style={[{ flex: 1 }, hoveredItem === secondTeamId && secondTeamId !== null ? { backgroundColor: '#eeeeee' } : { backgroundColor: 'transparent' }]}>
							{({ hovered }) => {
								(hovered) && setHoveredItem(secondTeamId)

								return (
									<TouchableOpacity
										style={{ flexDirection: 'row', alignItems: 'center', height: '100%' }}
										onPress={() => {
											setTeamToReplace({
												presentTeam: secondTeamId,
												knockoutId: playoff.id,
												rival: firstTeamId
											})
											setReplaceModal(true)
										}}
									>
										<Text style={[styles.spacing, { marginTop: 10, padding: 5 }]}>{secondTeamName}</Text>
									</TouchableOpacity>
								)
							}}
						</Hoverable>
					</View>

					<View flexDirection='row'>
						{playoff?.matches?.map((go: any, i: number) => {
							const inverse = firstTeamId === go.awayTeamId;

							const values = [
								{ score: go.homeSanction ? 0 : (go.awaySanction ? 'V' : go.homeScore), sanction: go.homeSanction }, 
								{ score: go.awaySanction ? 0 : (go.homeSanction ? 'V' : go.awayScore), sanction: go.awaySanction },
							];

							if(inverse) {
								values.reverse();
							}

							const [home, away] = values;

							return (
								<View key={i}>
									<View alignItems='center' style={[styles.playoffScoreContainer, (i + 1) >= playoff?.matches?.length && { borderRightWidth: 0 }]}>
										{!go.matchResultId ? (
											<Icon name={'add'} style={{ marginRight: 0 }} fontSize={20} onPress={() => {
												setMatchToUpdate(playoff);
												setMatchCallToUpdate(go);
												setResultModalVisible(true);
											}}
											/>
										) : (
											<View flex={1}>
												<Text
													onPress={() => {
														setMatchToUpdate(playoff);
														setMatchCallToUpdate(go);
														setUpdateResultModal(true);
													}}
													marginTop={0} bold style={[styles.playoffScore, { backgroundColor: `${(home.score === 'V' || parseInt(home.score) > parseInt(away.score)) && '#aaffaa'}` }]}>{home.score}</Text>
												<Separator height={1} backgroundColor='#ccc' />
												<Text
													onPress={() => {
														setMatchToUpdate(playoff);
														setMatchCallToUpdate(go);
														setUpdateResultModal(true);
													}}
													marginTop={0} bold style={[styles.playoffScore, { backgroundColor: `${(away.score === 'V' || parseInt(home.score) < parseInt(away.score)) && '#aaffaa'}` }]}>{away.score}</Text>
											</View>
										)}
									</View>
								</View>
							)
						})}

						{playoff?.gamesOrder && !playoff.matches && playoff?.gamesOrder?.map((_: any, i: number) => {
							return (
								<View key={i} flexDirection='column' justifyContent='space-around' style={{ height: '100%' }}>
									<Icon name='add' fontSize={20} />
								</View>
							)
						})}
					</View>
				</View>

				{phase < (Object.keys(phases).length) && (
					<View style={{ height: 1, backgroundColor: '#ccc', width: 25, overflow: 'hidden' }}>
						{/* <Text>HOIZONTAL BRACKET LINE</Text> */}
					</View>
				)}
			</View>
		)
	}

	/**
	 * actions
	 */
	const actions = React.useCallback((object: any) => {
		return (
			<View flexDirection='row' alignItems='center'>
				<IconButton backgroundColor={'transparent'} color={'black'} name='right-left' title={t('global.replace')} onPress={() => {
					setTeamToReplace({ ...teamToReplace, newTeam: object.id })
					onReplaceTeam({ ...teamToReplace, newTeam: object.id, ...form.current })
					setReplaceModal(false)
				}} />
			</View>
		)
	}, [onReplaceTeam, teamToReplace]);

	/**
	 * logo
	 */
	const logo = React.useCallback((object: any) => {
		return <PhotoCircle alt={object.name} url={object.teamLogoUrl} size={60} />;
	}, []);

	/**
	 * render
	 */
	const modalHeaders: ITableHeader[] = [
		{ name: "logo", label: t("admin.competitions.eventsCompetition.eventTeams.name"), render: logo, width: 70 },
		{ name: "name", label: "", textProps: { uppercase: true }, width: 550 },
		{ name: "actions", label: t("global.actions"), render: actions },
	];

	return (
		<View style={styles.container}>
			<View flexDirection='row'>
				{Object.entries(phases).map(([key, phase], i) => {
					return (
						<View>
							<View key={i} style={[styles.spacing, styles.sizing, { marginBottom: 15 }]}>
								<Button
									icon={{ name: 'edit', color: 'white', fontSize: 14 }}
									type='primary'
									color='white'
									label={`${t('admin.events.knockoff.phase')} ${i + 1}`}
									marginBottom={0}
									onPress={() => {
										setModalVisible(true);
										setPhaseToUpdate(key);
										setUpdateGameOrder(phase[0].gamesOrder)
									}}
								/>
							</View>

							<View flex={1} flexDirection='row' >
								<View flex={1} justifyContent='space-around'>
									{phase.map((playoff: any, index: number) => renderPlayoff(playoff, index, parseInt(key)))}
								</View>

								<View flexDirection='column' justifyContent='space-around' style={{ overflow: 'hidden', height: '100%' }}>
									{Array(Math.floor(phase.length / 2)).fill(1).map(() => (
										<View style={{ width: 1, height: 101 * parseInt(key), backgroundColor: '#ccc', overflow: 'hidden' }}>
											{/* <Text>VERTICAL BRACKET</Text> */}
										</View>
									))}
								</View>

								<View flexDirection='column' justifyContent='space-around' style={{ overflow: 'hidden', height: '100%' }}>
									{Array(Math.floor(phase.length / 2)).fill(1).map(() => (
										<View style={{ width: 25, height: 1, backgroundColor: '#ccc', overflow: 'hidden' }}>
											{/* <Text>HORIZONTAL BRACKET</Text> */}
										</View>
									))}
								</View>
							</View>
						</View>
					);
				})}

				{hasThirdPlace && (
					<View style={{ position: 'absolute', right: 10, top: '50%', marginTop: 100 }}>
						{renderPlayoff(thirdPlace, 0, 99)}
					</View>
				)}
			</View>

			<Modal header maxWidth={400} minHeight={400} title={t('admin.events.knockoff.phaseupdate')} visible={modalVisible} onClose={() => { setModalVisible(false) }}>
				<View style={{ height: '100%' }}>
					<Picker label={t('admin.events.league.bestOf')}
						items={[
							{ label: 1, value: 1 },
							{ label: 2, value: 2 },
							{ label: 3, value: 3 },
							{ label: 4, value: 4 },
							{ label: 5, value: 5 }
						]}
						defaultValue={updateGameOrder?.length}
						onChange={(value) => {
							const bestOfArr = [];

							for (let i = 0; i < value; i++) {
								bestOfArr.push(((i + 1) % 2 === 0) ? 'V' : 'L');
							}

							setUpdateGameOrder(bestOfArr)
						}}
					/>

					<View flexDirection='row'>
						{updateGameOrder?.map((e, i) => {
							e = ((i + 1) % 2 === 0) ? 'V' : 'L';
							return (
								<RadioGroup
									key={i}
									label={`${t('admin.events.calendar.round')} ${i + 1}`}
									value={((i + 1) % 2 === 0) ? 'V' : 'L'}
									items={[
										{ label: 'L', value: 'L' },
										{ label: 'V', value: 'V' },
									]}
									onChange={(value) => {
										const ugo = [...updateGameOrder];
										ugo[i] = value;
										setUpdateGameOrder(ugo);
									}}
								/>
							)
						})}
					</View>

					<View style={{ alignSelf: 'flex-end' }}>
						<Button label={t('global.save')} onPress={() => {
							if (!isThirdUpdating) {
								phases[phaseToUpdate].forEach(column => column.gamesOrder = updateGameOrder);
								setPhases({ ...phases });
							} else {
								thirdPlace.gamesOrder = updateGameOrder
								setThirdPlace({ ...thirdPlace })
							}
							setModalVisible(false);
						}} />
					</View>
				</View>
			</Modal>

			<Modal maxWidth={500} minHeight={150} header title={t('admin.events.knockoff.addResult')} visible={resultModalVisible} onClose={() => setResultModalVisible(false)}>
				<MatchResult match={matchToUpdate} matchCall={matchCallToUpdate} teams={teams} onSave={addResult} courts={courts} />
			</Modal>

			<Modal visible={replaceModal} header title={t('admin.events.knockoff.replaceTeam')} onClose={() => { setReplaceModal(false) }}>
				<Checkbox label={t('admin.events.knockoff.replaceOnlyTeam')} onChange={(v)=>{form.current.onlyKnockout = v}} />
				<Table headers={modalHeaders} data={teams.filter(t => t.id !== teamToReplace.presentTeam && t.id !== teamToReplace.rival)} />
			</Modal>

			<Modal maxWidth={500} minHeight={150} header title={t('admin.events.knockoff.updateResult')} visible={updateResultModal} onClose={() => setUpdateResultModal(false)}>
				<MatchResult match={matchToUpdate} matchCall={matchCallToUpdate} teams={teams} onSave={updateResult} courts={courts} />
			</Modal>
		</View>
	)
}
const styles = StyleSheet.create({
	container: {
		marginHorizontal: 'auto',
		overflow: 'auto' as any,
		minHeight: 550,
	},
	playoffContainer: {
	},
	playoff: {
		marginVertical: 5,
		width: 250,
		// maxWidth: 250,
		// width: '100%',
		height: 90,
	},
	spacing: {
		margin: 5,
	},
	sizing: {
		minWidth: 252,
	},
	bordering: {
		borderWidth: 1,
		borderColor: '#cccccc',
		borderStyle: 'solid',
		borderRadius: 5,
		overflow: 'hidden',
	},
	playoffScoreContainer: {
		height: '100%',
		borderRightWidth: 1,
		borderColor: '#ccc',
		justifyContent: 'center',
		alignItems: 'center',
	},
	playoffScore: {
		width: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		borderColor: '#ccc',
		flex: 1,
		margin: 0
	},
});