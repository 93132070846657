import { CustomPicker, Icon, Input, Loader, Picker, t, Table, Text } from 'owc-react-lib-common';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'src/components/Button';
import { IImageUploaderData, ImageUploader } from 'src/components/ImageUploader';
import { InputDate } from 'src/components/InputDate';
import { Modal } from 'src/components/Modal';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { Row } from 'src/components/Row/Row';
import { View } from 'src/components/View';
import { styling } from 'src/config';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { Translation, usePortalTranslations } from 'src/hooks/usePortalTranslations';
import { CUSTOM, EMAIL, IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import { DOCUMENT_TYPE_CATSALUT, DOCUMENT_TYPE_CIF, DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIF, DOCUMENT_TYPE_PASSPORT, ROLE_PLAYER, SETTING_TYPE_BIRTHDATE, SETTING_TYPE_DOCUMENTATION, SETTING_TYPE_EMAIL, SETTING_TYPE_GENDER, SETTING_TYPE_NAME, SETTING_TYPE_NUMBER, SETTING_TYPE_OBSERVATION, SETTING_TYPE_PHONE, SETTING_TYPE_SHIRTSIZE, SETTING_TYPE_SURNAME } from 'src/pages/auth/components/config';
import {
  REGISTER_STATUS_COMPLETED
} from 'src/pages/public/actor/IRegistrationProcess';
import * as SettingService from 'src/services/SettingService';
import { ISettingDTO } from 'src/services/SettingService';
import * as Team3x3Service from 'src/services/Team3x3Service';

interface ITeam3x3Actors {
  initialData: any;
  onSave: (object: any) => void;
  onDelete: (object: any) => void;
  onCancel: () => void;
  shirtSizes: any[];
}

export const Team3x3Actors = ({ initialData, onSave, onDelete, onCancel, shirtSizes }: ITeam3x3Actors) => {
  const translate = usePortalTranslations();
  const context = React.useContext(OWCOpenDmrContext);
  const [actors, setActors] = React.useState<any[]>(initialData.actors || []);
  const [actorToSave, setActorToSave] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [, setSettings] = React.useState<any[]>([]);
  const [formElements, setFormElements] = React.useState<any[]>([]);
  const [actorToSend, setActorToSend] = React.useState<any>();
  const [actorToDelete, setActorToDelete] = React.useState<any>();
  const form = React.useRef<any>({});
  const { validateAll, phone, number, nif, nie, cif, required } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const alerts = useAlerts();
  const { createDSMError } = useAlerts();
  const maxFormRowSize = 6;

  /**
   * setters
   */
  const setName = React.useCallback((value: string) => form.current.peopleName = value, []);
  const setLastName = React.useCallback((value: string) => form.current.peopleSurname = value, []);
  const setDocumentIdentifierType = React.useCallback((value: string) => form.current.peopleIdDocType = value, []);
  const setDocumentIdentifier = React.useCallback((value: string) => form.current.peopleIdDoc = value?.toUpperCase(), []);
  const setBirthDate = React.useCallback((value: any) => form.current.peopleBirthdate = value, []);
  const setGender = React.useCallback((value: string) => form.current.gender = value, []);
  const setEmail = React.useCallback((value: string) => form.current.peopleEmail = value, []);
  const setPhone = React.useCallback((value: string) => form.current.peoplePhone = value, []);
  const setNumber = React.useCallback((value: string) => form.current.number = value, []);
  const setShirtSize = React.useCallback((value: string) => form.current.shirtSizeId = value, []);
  const setObservations = React.useCallback((value: string) => form.current.observations = value, []);

  const setPhoto = React.useCallback((object: IImageUploaderData) => {
    form.current.peopleOriginalPhotoFilename = object.name;
    form.current.peoplePhotoFile = object.base64;
  }, []);

  useComponentDidMount(async () => {
    try {
      const response = await SettingService.findByPortalId(context.configuration.portal.id);
      setSettings(response.data.filter(data => data.roleId === ROLE_PLAYER));
      setFormElements(getFormElements(response.data.filter(data => data.roleId === ROLE_PLAYER)));

    } catch (error) {
      createDSMError(error);
    }
    setLoading(false);

  })

  const customValidation = React.useMemo(() => ({
    gender: () => {
      if (form.current.roleId === ROLE_PLAYER) {
        return (
          required(form.current.gender)
        );
      }
    },
    peopleBirthdate: () => {
      return (
        required(form.current.peopleBirthdate)
      );
    },
    peopleIdDocType: () => {
      switch (parseInt(form.current.peopleIdDocType)) {
        case DOCUMENT_TYPE_NIF:
          return required(form.current.peopleIdDoc) || nif(form.current.peopleIdDoc);
        case DOCUMENT_TYPE_NIE:
          return required(form.current.peopleIdDoc) || nie(form.current.peopleIdDoc);
        case DOCUMENT_TYPE_CIF:
          return required(form.current.peopleIdDoc) || cif(form.current.peopleIdDoc);
      }
    },
  }), [cif, nie, nif, phone, required]);

  const getFormElements = (settings: ISettingDTO[]) => {

    const settingProperties = [];

    let key = 0;

    settings.forEach((setting: ISettingDTO) => {

      if (!setting.visible) {
        return;
      }

      const defaultProperties = {
        key: key,
        roleId: setting.roleId,
        size: 2,
        inputType: "Input",
        validate: null,
        email: false,
        required: setting.required,
      };

      key += 1;

      switch (setting.typeId) {
        case SETTING_TYPE_NAME:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleName",
            priority: 1,
            label: t("auth.competitions.teamCreation.actors.name"),
            onChange: setName,
          });
          break;

        case SETTING_TYPE_SURNAME:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleSurname",
            priority: 2,
            label: t("auth.competitions.teamCreation.actors.surname"),
            onChange: setLastName,
          });
          break;

        case SETTING_TYPE_GENDER:
          settingProperties.push({
            ...defaultProperties,
            name: "gender",
            priority: 3,
            validate: customValidation.gender,
            label: t("auth.competitions.teamCreation.actors.gender"),
            onChange: setGender,
            inputType: "Picker",
            items: genders,
          });
          break;

        case SETTING_TYPE_BIRTHDATE:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleBirthdate",
            priority: 4,
            validate: customValidation.peopleBirthdate,
            label: t("auth.competitions.teamCreation.actors.birthDate"),
            onChange: setBirthDate,
            inputType: "InputDate",
          });
          break;

        case SETTING_TYPE_EMAIL:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleEmail",
            priority: 5,
            label: t("auth.competitions.teamCreation.actors.email"),
            onChange: setEmail,
            email: true
          });
          break;

        case SETTING_TYPE_PHONE:
          settingProperties.push({
            ...defaultProperties,
            name: "peoplePhone",
            priority: 6,
            label: t("auth.competitions.teamCreation.actors.phone"),
            onChange: setPhone,
          });
          break;

        case SETTING_TYPE_DOCUMENTATION:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleIdDocType",
            priority: 7,
            validate: customValidation.peopleIdDocType,
            label: t("auth.competitions.teamCreation.actors.documentType"),
            onChange: setDocumentIdentifierType,
            inputType: "Picker",
            items: documentTypes,
          });

          settingProperties.push({
            ...defaultProperties,
            name: "peopleIdDoc",
            priority: 8,
            label: t("auth.competitions.teamCreation.actors.document"),
            onChange: setDocumentIdentifier,
          });
          break;

        case SETTING_TYPE_NUMBER:
          settingProperties.push({
            ...defaultProperties,
            name: "number",
            priority: 9,
            label: t("auth.competitions.teamCreation.actors.number"),
            onChange: setNumber,
          });
          break;

        case SETTING_TYPE_SHIRTSIZE:
          settingProperties.push({
            ...defaultProperties,
            name: "shirtSizeId",
            priority: 12,
            label: t("auth.competitions.teamCreation.actors.size"),
            onChange: setShirtSize,
            inputType: "Picker",
            items: shirtSizes.map(ss => ({label: ss.size, value: ss.id})),
          });
          break;

        case SETTING_TYPE_OBSERVATION:
          settingProperties.push({
            ...defaultProperties,
            name: "observations",
            size: 6,
            priority: 13,
            label: t("auth.competitions.teamCreation.actors.observations"),
            onChange: setObservations
          });
      }
    })

    return settingProperties;

  }


  const printFormElements = React.useCallback(() => {
    const formRows: any[][] = [[]];
    let rowIndex: number = 0;
    let rowSize: number = 0;

    formElements?.forEach((formElement: any) => {
      if (formElement.size + rowSize > maxFormRowSize) {
        // ADD ROW
        formRows.push([])
        rowSize = 0
        rowIndex += 1;
      }
      formRows[rowIndex]?.push(formElement);
      rowSize += formElement.size;

    });

    const f = formRows?.map((formRow, index) => {
      return (
        <Row key={index} flex={[...formRow?.map(rowElement => rowElement.size)]} containerStyle={{ zIndex: 9999 - index }}>

          {formRow?.map(element => {

            switch (element.inputType) {

              case "Input":
                return <Input
                  key={element.key + index}
                  label={element.label}
                  defaultValue={form.current[element.name]}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  required={element.required}
                />

              case "InputDate":
                return <InputDate
                  key={element.key}
                  label={element.label}
                  defaultValue={form.current[element.name]}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  required={element.required}
                  minDate={element.minDate}
                  maxDate={element.maxDate}
                />

              case "Picker":
                return <Picker
                  key={element.key}
                  label={element.label}
                  defaultValue={form.current[element.name]}
                  items={element.items}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  required={element.required}
                />

              case "CustomPicker":
                return <CustomPicker
                  key={element.key}
                  label={element.label}
                  defaultValue={form.current[element.name]}
                  items={element.items}
                  renderItem={element.renderItem}
                  renderLabel={element.renderLabel}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  containerStyle={{ zIndex: 99999 }}
                />

              default: return <></>;
            }
          })}
        </Row>
      );
    });
    return f;
  }, [errors, formElements]);

  /**
   * open actor modal
   */
  const open = React.useCallback((object: any) => {
    form.current = object;
    setActorToSave(object);
    setErrors({});
  }, []);

  /**
   * send actor registration mail confirmation
   */
  const openSendModal = React.useCallback((object: any) => {
    setActorToSend(object);
  }, []);

  /**
   * send actor registration mail
   */
  const send = React.useCallback(async () => {
    await Team3x3Service.sendActorRegistrationMail(actorToSend.teamUuid, actorToSend.uuid);
    setActorToSend(undefined);
  }, [actorToSend]);

  /**
   * delete actor
   */
  const deleteActor = React.useCallback(async () => {
    try {
      await Team3x3Service.deleteActor(actorToDelete.teamUuid, actorToDelete.uuid);
      setActorToDelete(undefined);
      setActors(actors.filter((actor: any) => actor.id !== actorToDelete.id));
      onDelete(actorToDelete);
    } catch (error) {
      createDSMError(error);
    }
  }, [actors, actorToDelete, createDSMError, onDelete]);

  /**
   * save actor
   */
  const save = React.useCallback(async () => {
    // validate
    // const { errors, hasErrors } = validateAll(form.current, [
    //   { name: 'peopleName', type: REQUIRED },
    //   { name: 'peopleSurname', type: REQUIRED },
    //   { name: 'peopleIdDocType', type: REQUIRED },
    //   {
    //     name: 'peopleIdDoc', type: [REQUIRED, CUSTOM], validate: (value: string) => {
    //       switch (parseInt(form.current.peopleIdDocType)) {
    //         case DOCUMENT_TYPE_NIF: return nif(value);
    //         case DOCUMENT_TYPE_NIE: return nie(value);
    //         case DOCUMENT_TYPE_CIF: return cif(value);
    //       }
    //     }
    //   },
    //   { name: 'peopleBirthdate', type: REQUIRED },
    //   { name: 'gender', type: REQUIRED },
    //   { name: 'peopleEmail', type: [REQUIRED, EMAIL] },
    //   { name: 'peoplePhone', type: CUSTOM, validate: (value: string) => !!value && phone(value) },
    //   { name: 'number', type: CUSTOM, validate: (value: string) => !!value && number(value) },
    // ]);
    const params: IValidationParams[] = [];

    formElements.forEach((formElement: any) => {
      if (!formElement.required) {
        return;
      }

      const types = [REQUIRED];
      !!formElement.validate && types.push(CUSTOM)
      formElement.email && types.push(EMAIL);

      params.push({
        name: formElement.name,
        type: types,
        ...(!!formElement.validate && { validate: formElement.validate })
      })

    })

    const { errors, hasErrors } = validateAll(form.current, params);
    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // save request
    try {
      const response = await Team3x3Service.createActor(initialData.uuid, { ...form.current, teamId: initialData.id, teamUuid: initialData.uuid });
      alerts.create({ text: t("global.success"), type: "success" })
      if (!!actorToSave.id) {
        setActors(actors.map((actor: any) => actor.id === response.data.id ? response.data : actor));
      }
      else {
        setActors([...actors, response.data]);
      }

      // notify
      onSave?.(response.data);
      // close modal
      setActorToSave(undefined);
    } catch (error) {
      alerts.createDSMError(error);
    }


  }, [actors, actorToSave, onSave, cif, initialData, nie, nif, number, phone, validateAll, alerts]);

  /**
   * render
   */
  const documentTypes = [
    { label: 'NIF', value: DOCUMENT_TYPE_NIF },
    { label: t('auth.competitions.teamCreation.actors.passport'), value: DOCUMENT_TYPE_PASSPORT },
    { label: 'CATSALUT', value: DOCUMENT_TYPE_CATSALUT },
    { label: 'NIE', value: DOCUMENT_TYPE_NIE },
    { label: 'CIF', value: DOCUMENT_TYPE_CIF },
  ];

  const genders = [
    { label: t('global.female'), value: 'F' },
    { label: t('global.male'), value: 'M' }
  ];

  const headers = [
    t('3x3.teams.actors.name'),
    t('3x3.teams.actors.lastName'),
    t('3x3.teams.actors.email'),
    { label: t('3x3.teams.actors.gender'), width: 120 },
    { label: t('3x3.teams.actors.birthDate'), width: 120 },
    { label: t('3x3.teams.actors.status'), width: 120 },
    { key: 'actions', width: 150 }
  ];

  if (loading) {
    return (<Loader />)
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.actors}>

          <View style={{ marginBottom: styling.spacing }}>
            <Text bold>{t('3x3.teams.actors.title')}</Text>
            <Text >{t(translate(Translation.THREEONTHREE_ACTOR_HELP))}</Text>
          </View>

          <Table headers={headers} data={actors.map((actor: any) => {
            const complete = actor.registrationStatus >= REGISTER_STATUS_COMPLETED;

            return [
              actor.peopleName,
              actor.peopleSurname,
              actor.peopleEmail,
              actor.gender,
              actor.formattedPeopleBirthdate,
              <Text color={complete && 'teal'} bold={complete} uppercase>{complete ? t('3x3.teams.actors.complete') : t('3x3.teams.actors.pending')}</Text>,
              <View flexDirection='row' justifyContent='flex-end' flex={1}>
                {actor.registrationStatus < REGISTER_STATUS_COMPLETED && <Icon type='edit' onPress={() => open(actor)} title={t('global.edit')} />}
                {actor.registrationStatus < REGISTER_STATUS_COMPLETED && <Icon type='paper-plane' onPress={() => openSendModal(actor)} title={t('global.send')} />}
                {<Icon type='trash' onPress={() => setActorToDelete(actor)} title={t('global.delete')} />}
              </View>
            ]
          })} />
        </View>

        <View flexDirection='row'>
          <Button label={t('3x3.teams.actors.create')} onPress={() => open({})} marginBottom={0} containerStyle={{ marginRight: 10 }} />
          <Button type='link' label={t('global.cancel')} onPress={onCancel} marginBottom={0} />
        </View>
      </View>
      <Modal maxWidth={900} visible={!!actorToSave} title={actorToSave?.peopleName || t('3x3.teams.actors.create')} onClose={() => setActorToSave(undefined)}>
        {printFormElements()}
        <Row>
          <ImageUploader label={t('3x3.teams.actors.photo')} defaultValue={actorToSave?.peoplePhotoUrl} onChange={setPhoto} error={errors.photo} />
        </Row>
        <Row>
          <Button label={t('global.save')} onPress={save} disabled={actors?.length >= 8} />
        </Row>
      </Modal>
      <ModalConfirm text='' visible={!!actorToSend} onSuccess={send} onClose={() => setActorToSend(undefined)} />
      <ModalConfirm text='' visible={!!actorToDelete} onSuccess={deleteActor} onClose={() => setActorToDelete(undefined)} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  data: {
    flex: 1,
  },
  actors: {
    flex: 1,
  },

});