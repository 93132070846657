import * as React from "react";
import { t } from "owc-react-lib-common";
import { Input as OWCInput } from "owc-react-lib-common";
import { ViewProps } from "react-native";
import { View } from "./View";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

interface IInputDate {
  label?: string;
  value?: string;
  defaultValue?: string | Date;
  internalFormat?: string | "ISOString";
  onChange: (value?: string) => void;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  useTime?: boolean;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  containerStyle?: ViewProps["style"];
}

export const InputDate = ({ label, value, defaultValue, internalFormat, onChange, placeholder, minDate, maxDate, useTime, error, required, disabled, containerStyle }: IInputDate) => {
  const [dateError, setDateError] = React.useState();
  const isoString = internalFormat === "ISOString";
  const finalLabel = label ? `${label} ${required ? "*" : ""}` : ' ';
  const dateFormat = useTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  const formattedDefaultValue = defaultValue && (isoString ? dayjs(defaultValue).format(dateFormat) : dayjs(defaultValue, internalFormat).format(dateFormat));
  internalFormat || (internalFormat = useTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD");
  placeholder || (placeholder = dateFormat.toLowerCase());

  const regExp = React.useMemo(() => ({
    // COMPLETE EXPRESSIONS AND VALIDATOR
    date: (value: string) => value.match(/^\d{2}\/\d{2}\/\d{4}$/g), // DD/MM/YYYY
    time: (value: string) => value.match(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/g), // DD/MM/YYYY HH:mm

    isComplete: (value: string) => useTime ? regExp.time(value) : regExp.date(value),

    // INCOMPLETE EXPRESSIONS AND VALIDATOR
    day: (value: string) => value.match(/^\d{0,2}$/g),
    month: (value: string) => value.match(/^\d{2}\/\d{0,2}$/g),
    year: (value: string) => value.match(/^\d{2}\/\d{2}\/\d{0,4}$/g),
    hour: (value: string) => value.match(/^\d{2}\/\d{2}\/\d{4} \d{0,2}$/g),
    minute: (value: string) => value.match(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{0,2}$/g),

    hasErrors: (value: string) => {
      const isBetween = (num: number, start: number, end: number) => (num >= start) && (num <= end);
      const lenght = value.length;
      return (
        // DATE VALIDATION
        (!useTime && lenght > 10)
        || (isBetween(lenght, 0, 2) && !regExp.day(value)) // __
        || (isBetween(lenght, 3, 5) && !regExp.month(value)) // DD/__
        || (isBetween(lenght, 6, 10) && !regExp.year(value)) // DD/MM/____
        // TIME VALIDATION
        || (useTime && lenght > 16)
        || (useTime && isBetween(lenght, 11, 13) && !regExp.hour(value)) // DD/MM/YYYY __
        || (useTime && isBetween(lenght, 14, 16) && !regExp.minute(value)) // DD/MM/YYYY HH:__
      );
    },
  }), [useTime]);

  const handleChange = React.useCallback((value: string) => {
    setDateError(null);
    onChange(null);

    if (regExp.isComplete(value)) {
      const dayJsDate = dayjs(value, dateFormat, true);
      const dayJsMaxDate = dayjs(maxDate);
      const dayJsMinDate = dayjs(minDate);

      if (!dayJsDate.isValid()) {
        setDateError(t("errors.inputDate.invalid"));
        return;
      }

      if (maxDate && dayJsDate.isAfter(dayJsMaxDate)) {
        setDateError(t("errors.inputDate.maxDateExceeded", { date: dayJsMaxDate.format(dateFormat) }));
        return;
      }

      if (minDate && dayJsDate.isBefore(dayJsMinDate)) {
        setDateError(t("errors.inputDate.minDateNotReached", { date: dayJsMinDate.format(dateFormat) }));
        return;
      }

      onChange(isoString ? `${dayJsDate.format("YYYY-MM-DD")}T${dayJsDate.format("HH:mm:ss")}.000Z` : dayJsDate.format(internalFormat));
      return;
    }

    if (regExp.hasErrors(value)) {
      setDateError(t("errors.inputDate.format", { format: placeholder }));
    }

  }, [onChange, regExp, dateFormat, internalFormat, maxDate, minDate, placeholder, isoString]);

  const handleBlur = React.useCallback((event: any) => {
    const value = event.target.value;
    if (value && (!regExp.isComplete(value))) {
      setDateError(t("errors.inputDate.format", { format: placeholder }));
    }
  }, [placeholder, regExp]);

  return (
    <View style={[containerStyle]}>
      <OWCInput
        label={finalLabel}
        value={value}
        defaultValue={formattedDefaultValue}
        placeholder={placeholder}
        error={error || dateError}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </View>
  );
};
