import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IMatchCallDTO {

}

const BASE_URL = BACKEND_URL + '/auth/matchCall'

/**
 * Recupera todos los partidos de un evento
 */
export const forceCloseProcess = (matchCallUuid: number) => {
	return RestService.get(`${BASE_URL}/force-ad-close-process/${matchCallUuid}`);
}

/**
 * Recupera todos los partidos de un evento
 */
export const forceReCalcStats = (matchCallUuid: string) => {
	return RestService.get(`${BASE_URL}/force-recalc-stats/${matchCallUuid}`);
}

/**
 * Get a pdf with match information
 */
export const getMatchPdf = (matchCallId: number) => {
	return RestService.requestPdf(`${BASE_URL}/pdf/get-match/${matchCallId}`, '_blank');
  }

  /**
 * Get a pdf with all matches information from an event
 */
export const getMatchesPdf = (eventId: number ) => {
	return RestService.requestPdf(`${BASE_URL}/pdf/get-matches/${eventId}`, '_blank');
  }

    /**
 * Get a pdf with all matches information from an event filtered by court
 */
export const getMatchesPdfByCourt = (eventId: number, courtId: number ) => {
	return RestService.requestPdf(`${BASE_URL}/pdf/get-matches-by-court/${eventId}/${courtId}`, '_blank');
  }
