import * as _ from 'lodash';
import { Input, Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { Form } from "src/components/Form";
import { IconButton } from "src/components/IconButton";
import { Loader } from "src/components/Loader";
import { Modal } from "src/components/Modal";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Row } from "src/components/Row/Row";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as EventService from "src/services/EventService";
import * as RankingService from "src/services/RankingService";
import * as RankingSystemService from "src/services/RankingSystemService";

export const TeamActorRankingList = () => {
  const alerts = useAlerts();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rankings, setRankings] = React.useState<any[]>([]);
  const [groupedRankings, setgroupedRankings] = React.useState<any>(null);
  const [rankingSystems, setRankingSystems] = React.useState<any[]>([]);
  const [, setSelectedCategory] = React.useState<number>(null);
  const [rankingToShow, setRankingToShow] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const { eventUuid } = useParams();
  const [rankingToRefresh, setRankingToRefresh] = React.useState<any>(false);
  const [selectedPlayerRankingSystem, setSelectedPlayerRankingSystem] = React.useState<any>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [rankingToDelete, setRankingToDelete] = React.useState<any>(null);

  useComponentDidMount(async () => {
    await getRankings();
  })

  const computeRanking = async () => {
    setLoading(true);
    try {
      await RankingService.computeActorRanking(eventUuid, selectedPlayerRankingSystem)
      await getRankings();
      setLoading(false)
    } catch (error) {
      alerts.createDSMError(error);
      setLoading(false);
    }
  }

  const getRankings = async () => {
    setLoading(true);
    try {
      const response = await RankingSystemService.getRankingSystem();
      setRankingSystems(response.data.map(rs => ({ label: rs.name, value: rs.uuid })));

      const responseRanking = await RankingService.getTeamActorRanking(eventUuid);
      setRankings(responseRanking.data)

      const a = _.groupBy(responseRanking.data, (e) => {

        return e?.teamEventCategoryActor?.teamEventCategory?.eventCategory?.id
      })

      setgroupedRankings(a)
      EventService.findEventCategoriesforEvent(eventUuid).then(response => {
        const map = response.data.map(eventCategory => ({ label: eventCategory.name, value: eventCategory.id }))
        setCategories(map)
      })

      
    } catch (error) {
      alerts.createDSMError(error);
      setLoading(false);
    }
    setRankingToRefresh(false);
    setLoading(false);
  }

  const categoryChanged = (newCategory: number) => {
    const rankingToShow = groupedRankings[newCategory];
    setRankingToShow(!rankingToShow ? [] : rankingToShow)
    setSelectedCategory(newCategory);
  }

  const updateRanking = (ranking, newPoints) => {
    ranking.points = newPoints;
    RankingService.updateTeamActorRanking(ranking);
  }

  const actions = (o) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="trash"
          onPress={() => {
            setOpenModal(true);
            setRankingToDelete(o.id);
          }}
        />
      </View>
    )
  }

  if (loading) {
    return <Loader />;
  }

  // RENDER
  const headers: ITableHeader[] = [
    // { name: "id", label: t("admin.rankingSystems.id"), width: 90 },
    {
      name: "points", label: t("admin.rankingSystems.points"), width: 90,
      render: o => {
        return (
          <View>
            <Input containerStyle={{ marginTop: 15 }} key={o.id} defaultValue={o.points} onBlur={(e) => { updateRanking(o, e.nativeEvent.text) }}></Input>
          </View>
        )
      }
    },
    {
      name: "eventCategoryname",
      label: t("admin.rankingSystems.name"),
      textProps: { uppercase: true },
      render: (o) => <Text>{o.teamEventCategoryActor.teamActor.fullName}</Text>
    },
    { name: "actions", label: " ", render: actions, width: 100 },

  ];

  return (
    <View>
      <View flexDirection="row" justifyContent="space-between" alignItems="center">
        <Title text={t("admin.rankingSystems.teamActorRankingSystem")} containerStyle={{ flex: 1 }} />
        <FloatingIconButton
          name="backtime"
          title={t("admin.rankingSystems.refresh")}
          color="black"
          backgroundColor="transparent"
          onPress={() => { setRankingToRefresh(true) }}
          containerStyle={{ position: "relative" }}
          right={5}
        />
      </View>
      {
        rankings.length > 0
          ?
          <>
            <Picker items={categories} onChange={ec => categoryChanged(ec)} />
            <Table defaultSize={100} defaultSortDirection={-1} defaultSort={"points"} headers={headers} data={rankingToShow} />
          </>
          :
          <>
            <Form>
              {
                rankingSystems.length === 0 ?
                  <>
                    <Row isColumn>
                      <View>
                        <Alert containerStyle={{ marginBottom: 0 }} text={t("admin.rankingSystems.noRankingSystem")} />
                        <ButtonLink style={{ textDecoration: 'underline' }} type="link" to={"/organizer/ranking-systems"} label={t("admin.rankingSystems.redirectToRankingSystems")} />
                      </View>
                    </Row>
                  </>
                  :
                  <>
                    <Row>
                      <Picker label={t("admin.rankingSystems.teamRankingSystem")} items={rankingSystems} onChange={rs => setSelectedPlayerRankingSystem(rs)} />
                    </Row>
                    <Row>
                      <Button label={t("admin.rankingSystems.generateTeamRanking")} onPress={computeRanking} />
                    </Row>
                  </>
              }
            </Form>
          </>
      }

      <Modal title={t("admin.rankingSystems.refresh")} visible={rankingToRefresh} onClose={() => setRankingToRefresh(false)}>
        <Form>
          <Row flex={[3, 3, 1]} containerStyle={{ alignItems: "flex-end" }}>
            <Picker label={t("admin.rankingSystems.rankingPlayerSystem")} items={rankingSystems} onChange={rs => setSelectedPlayerRankingSystem(rs)} />
          </Row>
          <Row>
            <Button label={t("global.accept")} onPress={computeRanking} />
          </Row>
        </Form>
      </Modal>
      <ModalConfirm
        onSuccess={async () => {
          await RankingService.deleteTeamActorRanking(rankingToDelete);
          setOpenModal(false);
          setRankingToDelete(false);
          getRankings();
        }}
        text={t("admin.rankingSystems.deleteRankingConfirm")}
        visible={openModal}
        onClose={() => { setOpenModal(false) }}
      />
    </View>
  );
};
