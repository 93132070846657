import * as React from 'react';
import { Icon } from 'owc-react-lib-common';
import { StyleSheet } from "react-native";
import { Hoverable } from 'react-native-web-hover';
import { useLocation } from 'react-router-dom';
import { useColors } from 'src/hooks/useColors';
import { ButtonLink } from '../ButtonLink';
import { SimpleAccordion } from '../SimpleAccordion/SimpleAccordion';
import { View } from '../View';
import { ISidebarItemLink, ISidebarItemTitle, Type } from './types';

export const SidebarItem = ({ id, type, label, to, icon, children, color, backgroundColor, selectedColor, selectedBackgroundColor = '#eee', title, exact }: ISidebarItemLink | ISidebarItemTitle) => {
	const location = useLocation();
	const colors = useColors('primary');
	const [useColor, setUseColors] = React.useState({
		color: color,
		backgroundColor: backgroundColor
	});
	React.useEffect(() => {
		if(location.pathname.includes(to) && !exact){
			setUseColors({
				color: selectedColor ? selectedColor : colors.main,
				backgroundColor: selectedBackgroundColor ? selectedBackgroundColor : colors.light
			})
		}
		else if (location.pathname === to && exact) {
			setUseColors({
				color: selectedColor ? selectedColor : colors.main,
				backgroundColor: selectedBackgroundColor ? selectedBackgroundColor : colors.light
			})
		} else {
			setUseColors({
				color: color,
				backgroundColor: backgroundColor
			})
		}
	}, [location.pathname, to]);
	switch (type) {
		case Type.LINK:
			return (
				<View key={id} style={[styles.spacing]}>
					<Hoverable>
						{({ hovered }) => (
							<ButtonLink
								title={title.toUpperCase()}
								to={to}
								label={label.toUpperCase()}
								backgroundColor={useColor.backgroundColor}
								color={useColor.color}
								// backgroundColor={(pathname === to || hovered) && (selectedBackgroundColor ? selectedBackgroundColor : colors.light)}
								// color={color ? (pathname === to || hovered) ? selectedColor : color : colors.main}
								style={{}}
								textIcon={!!icon && { name: icon }}
								flexDirection={'row-reverse'}
							// fontWeight={!!icon ? 600 : 0}
							/>
						)}
					</Hoverable>
				</View>
			);
		case Type.TITLE:
			return (
				<SimpleAccordion icon={icon} title={label.toUpperCase()} >
					{children?.map((child, idx) => {
						if (typeof child === 'boolean') {
							return null;
						}

						return (
							<Hoverable key={idx}>
								{({ hovered }) => (
									<View>
										<ButtonLink
											to={child.to}
											label={child.label.toUpperCase()}
											backgroundColor={(location.pathname === child.to || hovered) && (selectedBackgroundColor ? selectedBackgroundColor : colors.light)}
											style={{ flex: 1 }}
											// textIcon={child.icon ? { name: child.icon } : undefined}
											flexDirection='row-reverse'
											color={colors.main}
											contentStyle={{ paddingLeft: 43 }}
										// fontWeight={!!icon ? 600 : 0}
										/>

										<View style={{ marginRight: 0, position: 'absolute', left: 11, top: 11, zIndex: 9999 }} >
											<Icon containerStyle={{ rotate: (!child.icon) && '90deg' }} type={child.icon || 'arrow-turn-up'} fontSize={12} />
										</View>
									</View>
								)}
							</Hoverable>
						)
					})}
				</SimpleAccordion>
			);
	}
}
const styles = StyleSheet.create({
	spacing: {

	},

});
