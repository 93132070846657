import dayjs from 'dayjs';
import * as React from 'react';
import { useParams } from "react-router-dom";
import { Container } from 'src/components/Container';
import { Loader } from 'src/components/Loader';
import { Title } from 'src/components/Title';
import { View } from 'src/components/View';
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as ActorService from 'src/services/ActorService';
import * as EventService from 'src/services/EventService';
import * as TeamService from 'src/services/TeamService';
import * as LicenceUtils from 'src/utils/LicenceUtils';
import { TEAM_STATUS_PENDING } from '../components/config';
import { TeamCreation } from '../components/TeamCreation';
import { TeamCreationSummary } from '../components/TeamCreationSummary';

export const TeamDetail = () => {
  const { teamUuid } = useParams();
  const alerts = useAlerts();
  const [data, setData] = React.useState<any>();
  const [category, setCategory] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    // validate
    if (!teamUuid) {
      return;
    }

    // fetch data
    try {
      const [team, actors] = await Promise.all([
        TeamService.findByUuid(teamUuid),
        ActorService.findByTeamUuid(teamUuid),
      ]);

      const event = await EventService.findByUuid(team.data.eventUuid);
      setCategory(event.data.eventCategories.find((category: any) => category.id === team.data.eventCategoryId));

      setData({
        teamDTO: team.data,
        actors: actors.data.map((actor: any) => ({ ...actor, _id: actor.id, formattedBirthDate: dayjs(actor.peopleBirthdate).format('DD/MM/YYYY') })).sort(LicenceUtils.sort),
      });
    } catch (error) {
      alerts.createDSMError(error);
    }

    // loading
    setLoading(false);
  });

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * no data
   */
  if (!teamUuid || !data) {
    return null;
  }

  /**
   * render
   */
  return (
    <Container>
      {data?.teamDTO?.registerStatus === TEAM_STATUS_PENDING ? (
        <TeamCreation initialData={data} initialStep={3} update />
      ) : (
        <View>
          <Title text={data.teamDTO.name} right={data.teamDTO.eventCategoryName} />
          <TeamCreationSummary data={data} category={category} />
        </View>
      )}
    </Container>
  );
}