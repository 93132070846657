import { DEFAULT_LANGUAGE } from 'src/config';
import { i18n, translation as commonTranslation } from 'owc-react-lib-common';

export const init = async () => {
  const translations = {
      es: { common: commonTranslation.es, translation: require('./es.json') },
      ca: { common: commonTranslation.ca, translation: require('./ca.json') },
      en: { common: commonTranslation.en, translation: require('./en.json') },
    }
  
  await i18n.init(DEFAULT_LANGUAGE, translations);
}
