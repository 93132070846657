import { StyleSheet, ViewProps } from 'react-native';
import { OWCStep, OWCStepper } from 'src/components/OWCStepper';
import { styling } from 'src/config';
import { View } from './View';

interface IStepper {
  step: number;
  selected: number;
  headers: { label: string, value?: string | number }[];
  children?: any[];
  onPress?: (index: number) => void;
  containerStyle?: ViewProps['style'];
}

export const Stepper = ({ step = 0, selected = 0, headers, children, onPress, containerStyle }: IStepper) => {
  /**
   * render
   */
  return (
    <View style={styles.container}>
      <View style={[styles.stepper, containerStyle]}>
        <OWCStepper activeStep={step}>
          {headers.map((header, idx) => (
            <OWCStep
              key={header.label}
              onPress={() => idx <= step && onPress?.(idx)}
              label={header.label?.toUpperCase()}
              activeStep={false}
              completed={step > idx}
            />
          ))}
        </OWCStepper>
      </View>

      {children?.map((child: any, idx: number) => (
        <View key={idx} style={[styles.content, idx !== selected && styles.hidden]}>
          {child}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
  },
  stepper: {
    marginBottom: styling.spacing,
  },
  content: {
  },
  hidden: {
    display: 'none',
  }
});