import { t } from "owc-react-lib-common";
import React from 'react';
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { IconButton } from 'src/components/IconButton';
import { ImageRenderer } from 'src/components/ImageRenderer';
import { Loader } from 'src/components/Loader';
import { Modal } from 'src/components/Modal';
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from "src/components/Text";
import { Title } from 'src/components/Title';
import { View } from "src/components/View";
import { ORGANIZER_READ_ONLY } from 'src/context/OWCOpenDmrContext';
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useUserPermission } from 'src/hooks/useUserPermission';
import * as CourtService from "src/services/CourtService";
import * as EventService from 'src/services/EventService';
import * as MatchCallService from "src/services/MatchCallService";
import * as MatchService from "src/services/MatchService";
import * as StringUtils from 'src/utils/StringUtils';
import { EventContext } from '../EventContext';

export const ADMatchesList = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const [loading, setLoading] = React.useState(true);
  const { createDSMError } = useAlerts();
  const { data: event } = React.useContext(EventContext);
  const [, setCourts] = React.useState<any[]>();
  const [matches, setMatches] = React.useState<any[]>();
  const [dmrOpen, setDmrOpen] = React.useState<boolean>(false);
  const [selectedDmr, setSelectedDmr] = React.useState<any>({});
  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const [response, courtResponse] = await Promise.all([
        MatchService.getByEventId(event.id),
        CourtService.getCourtsByEventUuid(event.uuid),
      ]);

      setMatches(response.data);
      setCourts(courtResponse.data);
    } catch (error) {
      createDSMError(error);
    }
    setLoading(false);
  });

  /**
  * show excel
  */
  const showExcel = React.useCallback(async () => {
    try {
      await EventService.requestADExcel(event.id, event.name);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, event]);

  const matchForceReCalcStats = React.useCallback(async (object: any) => {
    try {
      await MatchCallService.forceReCalcStats(object.matchCallUuid);
      alerts.create({ text: t("global.success"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts])

  const matchForceClose = React.useCallback(async (object: any) => {
    try {
      await MatchCallService.forceCloseProcess(object.matchCallUuid);
      alerts.create({ text: t("global.success"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts])

  const openDmr = async (object) => {
    setSelectedDmr({
      imageUrl: object.matchRecordUrl,
      imageName: object.uuid
    })
    setDmrOpen(true);
  }


  const actions = React.useCallback((object: any) => {
    return (
      <View style={{ flexDirection: "row" }}>
        {!readOnly && <IconButton
          title={t("admin.events.matches.force-recalc")}
          backgroundColor="transparent"
          color="black"
          name="rotateRight"
          onPress={() => matchForceReCalcStats(object)}
        />}

        {!readOnly && <IconButton
          title={t("admin.events.matches.force-finish")}
          backgroundColor="transparent"
          color="black"
          name="ok"
          onPress={() => matchForceClose(object)}
        />}

        {object.matchRecordUrl ?
          <IconButton
            title={t("admin.events.matches.seeDigitalMatchRecord")}
            backgroundColor="transparent"
            color="black"
            name="pdf"
            onPress={() => openDmr(object)}
          />
          :
          <IconButton
            title={t("admin.events.matches.dmrNotAvailable")}
            backgroundColor="transparent"
            color="gray"
            name="pdf"
            onPress={()=>{}}
          />
        }
      </View>
    );
  }, [readOnly, matchForceClose, matchForceReCalcStats]);


  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    {
      name: "matchCallId",
      label: t("admin.events.matches.id"),
      width: 40,
    },
    {
      name: "eventCategoryName",
      label: t("admin.events.matches.category"),
      textProps: { uppercase: true },
    },
    {
      name: "groupName",
      label: t("admin.events.matches.group"),
      textProps: { uppercase: true },
    },
    {
      name: "facilityName",
      label: t("admin.events.matches.facility"),
      textProps: { uppercase: true },
    },
    {
      name: "courtName",
      label: t("admin.events.matches.court"),
      textProps: { uppercase: true },
    },
    {
      name: "formattedMatchDateHour",
      label: t("admin.events.matches.date"),
      width: 80,
      sort: (object) => object.matchDateHour,
    },
    {
      name: "homeTeamName",
      label: t("admin.events.matches.homeTeam"),
      headerTextProps: { textAlign: "left" },
      render: (object: any) => {
        if (!!object.homeTeamRef) {
          return <Text textAlign="left">[{StringUtils.minifyTeamName(object.homeTeamRef)?.toUpperCase()}] {object.homeTeamName}</Text>;
        }

        return <Text textAlign="left">{object.homeTeamName}</Text>;
      },
    },
    {
      name: "awayTeamName",
      label: t("admin.events.matches.awayTeam"),
      textProps: { uppercase: true },
      render: (object: any) => {
        if (!!object.awayTeamRef) {
          return <Text>[{StringUtils.minifyTeamName(object.awayTeamRef)?.toUpperCase()}] {object.awayTeamName}</Text>;
        }

        return <Text>{object.awayTeamName}</Text>;
      },

    },
    {
      name: "adUser",
      label: t("admin.events.matches.adUser"),
      render: (object: any) => {
        return <Text>{object.adUser}</Text>
      }
    },
    {
      name: "decryptedAdPassword",
      label: t("admin.events.matches.adPassword")
    },
    {
      name: "actions",
      label: "",
      render: actions,
      width: 90,
    },

  ];


  return (
    <View>
      <View flexDirection='row' alignItems='center' marginBottom={20}>
        <Title text={t('admin.events.matches.title')} containerStyle={{ flex: 1, marginBottom: 0 }} />
        <View flexDirection='row'>
          <FloatingIconButton backgroundColor='transparent' color='black' name='excel' title='Excel' onPress={showExcel} containerStyle={{ position: 'relative' }} />
        </View>
      </View>
      <Table headers={headers} data={matches} />
      <Modal header={false} visible={dmrOpen} onClose={() => { setDmrOpen(false) }} minHeight={900} maxWidth={700}>
        <ImageRenderer imageUrl={selectedDmr.imageUrl} />
      </Modal>
    </View>
  )
}


// const styles = StyleSheet.create({
//   scoreInput: {
//     top: 8,
//     width: 45,
//   },
// });