import * as React from 'react';
import * as OrganizerService from 'src/services/OrganizerService';

import * as PaymentService from 'src/services/PaymentService';


import { Input, Loader, t, Text } from 'owc-react-lib-common';
import { Form } from 'src/components/Form';
import { Title } from 'src/components/Title';

import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import { FloatingIconButton } from 'src/components/FloatingIconButton';
import { IconButton } from 'src/components/IconButton';
import { IImageUploaderData, ImageUploader } from 'src/components/ImageUploader';
import { InputDate } from 'src/components/InputDate';
import { Row } from 'src/components/Row/Row';
import { View } from 'src/components/View';
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { EMAIL, IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import { IOrganizerDTO } from 'src/services/OrganizerService';

export const OrganizerDetail = () => {
    const form = React.useRef<any>({});
    const [organizer, setOrganizer] = React.useState<IOrganizerDTO>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errors, setErrors] = React.useState<any>({});
    const { validateAll } = useValidation();
    const currentdate = new Date();
    const currentmonth = String(currentdate.getUTCMonth()+1).padStart(2, '0')
    const currentyear= currentdate.getUTCFullYear();

    const form2 = React.useRef<any>({
        startData: `${currentyear}-${currentmonth}-01`, 
        endData: `${currentyear}-${currentmonth}-${new Date(currentdate.getUTCFullYear(), currentdate.getUTCMonth()+1, 0).getDate()}`
    });


    const alerts = useAlerts();


    const setName = (value: string) => (form.current.name = value);
    const setEmail = (value: string) => (form.current.contactEmail = value);
    const setPhone = (value: string) => (form.current.phoneNum = value);
    const setCif = (value: string) => (form.current.cif = value);
    const setCode = (value: string) => (form.current.code = value);
    const setDescription = (value: string) => (form.current.description = value);
    const onChangeLogo = (value: IImageUploaderData) => {
        form.current.logoFile = value.base64;
        form.current.originalLogoFilename = value.name;
    };

    useComponentDidMount(async () => {
        try {
            setLoading(true);
            const response = await OrganizerService.getMe();
            setOrganizer(response);
            form.current = response;
        } catch (error) {
            alerts.createDSMError(error);
        }
        setLoading(false);
    })

    const copyToClipboard = () => {
        navigator.clipboard.writeText(organizer.token)
        alerts.create({ text: t("global.copySuccess"), type: "success" });
    };

    const refreshToken = async () => {
        try {
            const response = await OrganizerService.refreshToken();
            setOrganizer({ ...organizer, token: response });
            alerts.create({ text: t("global.success"), type: "success" });
        } catch (error) {
            alerts.createDSMError(error);
        }
    }

    const validate = React.useCallback(() => {
        const fields: IValidationParams[] = [
            { name: "name", type: REQUIRED },
            { name: "contactEmail", type: [REQUIRED, EMAIL] },
        ];

        const { errors, hasErrors } = validateAll(form.current, fields);
        setErrors(errors);
        return !hasErrors;
    }, [validateAll]);

    const updateOrganizer = async () => {
        if (!validate()) {
            return;
        }
        try {
            await OrganizerService.update(form.current);
            alerts.create({ text: t("global.success"), type: "success" });
        } catch (error) {
            alerts.createDSMError(error);
        }
    }

    const getExcel3x3 = async () => {
        try {
            await PaymentService.tresxtresExcel(form2.current.startData, form2.current.endData)
            alerts.create({ text: t("global.downloadSuccess"), type: "success" });
        } catch (error) {
            alerts.createDSMError(error);
        }
    }

    if (loading) {
        return (
            <Loader />
        )
    }


    return (
        <Container title={t('admin.organizer.title')}>
            <Form>
                <Row>
                    <View>
                        <Input
                            label={t("admin.portal.token")}
                            value={organizer.token}
                            disabled
                        />
                        <View style={{ position: 'absolute', right: 5, top: '35%', display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <IconButton
                                containerStyle={{}}
                                backgroundColor={'#AAAAAAAA'}
                                color={'#444444'}
                                fontSize={20}
                                name={"clipboard"}
                                onPress={copyToClipboard}
                            />
                            <IconButton
                                containerStyle={{}}
                                backgroundColor={'#AAAAAAAA'}
                                color={'#444444'}
                                fontSize={20}
                                name={"backtime"}
                                onPress={refreshToken}
                            />
                        </View>
                    </View>
                </Row>
                <Row>
                    <Input
                        label={t("admin.organizer.edit.name")}
                        defaultValue={organizer.name}
                        onChange={setName}
                        error={errors.name}
                    />
                    <Input
                        label={t("admin.organizer.edit.contactEmail")}
                        defaultValue={organizer.contactEmail}
                        onChange={setEmail}
                        error={errors.contactEmail}
                    />
                    <Input
                        label={t("admin.organizer.edit.phoneNum")}
                        defaultValue={organizer.phoneNum}
                        onChange={setPhone}
                    />
                </Row>
                <Row>
                    <Input
                        label={t("admin.organizer.edit.cif")}
                        defaultValue={organizer.cif}
                        onChange={setCif}
                        error={errors.cif}
                    />
                    <Input
                        label={t("admin.organizer.edit.code")}
                        defaultValue={organizer.code}
                        onChange={setCode}
                        error={errors.code}
                        numeric
                    />
                    <Input
                        label={t("admin.organizer.edit.description") + ` (${t('global.optional')})`}
                        defaultValue={organizer.description}
                        onChange={setDescription}
                    />
                </Row>
                <Row>
                    <ImageUploader
                        label={t("admin.organizer.edit.selectLogo")}
                        defaultValue={organizer.logoUrl}
                        onChange={onChangeLogo}
                    />
                </Row>
                <Row>
                    <Button label={t("global.save")} onPress={updateOrganizer} />
                </Row>
            </Form>
            <Title text={t("admin.organizer.edit.documents3x3")}></Title>
            <View style={{marginBottom: 10}}>
                <Text>{t("admin.organizer.edit.downloadExcel")}</Text>
            </View>
             
             <View style={{flexDirection:'row'}}>
                <View style={{marginRight: 5}}>
                    <InputDate
                        label={t('Fecha inicio')}
                        internalFormat='YYYY-MM-DD'
                        defaultValue={form2.current.startData}
                        onChange={(val) => (form2.current = { ...form2.current, startData: val })}
                        containerStyle={{maxWidth: 95}}
                    />
                </View>
                <View>
                <InputDate
                    label={t('Fecha final ')}
                    onChange={(val) => (form2.current = { ...form2.current, endData: val })}
                    defaultValue={form2.current.endData}
                    internalFormat='YYYY-MM-DD'
                    containerStyle={{maxWidth: 95}}
                />
                </View>
                <View>
                <FloatingIconButton
                    name="fileDownload"
                    title={t("admin.rankingSystems.refresh")}
                    color="black"
                    backgroundColor="transparent"
                    onPress={() => getExcel3x3()}
                    containerStyle={{ position: "relative" }}
                />
                </View>  
             </View>

        </Container>
    )
}
