import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export type IQuestionTypeDTO =
  | { type?: 'boolean'; values?: "true" | "false" 
  // | { type?: 'sample'; values?: any }
}

export type IQuestionDTO = {
  id?: number;
  eventCategoryId?: number;
  text?: string;
} & IQuestionTypeDTO;

const BASE_URL = BACKEND_URL + '/auth/questions'

export const getQuestions = (eventCategoryId: number) => {
  return RestService.get(`${BASE_URL}/by-event-category/${eventCategoryId}`);
}

export const getQuestion = (questionId: number) => {
  return RestService.get(`${BASE_URL}/${questionId}`);
}

export const createQuestion = (payload: IQuestionDTO) => {
  return RestService.post(BASE_URL, payload);
}

export const deleteQuestion = (questionId: number) => {
  return RestService.delete(`${BASE_URL}/${questionId}`);
}