import { Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Form } from "src/components/Form";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { Row } from "src/components/Row/Row";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as CompetitionService from "src/services/CompetitionService";
import * as SeasonService from "src/services/SeasonService";
import * as ModalityService from "src/services/ModalityService";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { FloatingIconButton } from "src/components/FloatingIconButton";


export const CompetitionCreate = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const { validateAll } = useValidation();

  const [errors, setErrors] = React.useState<any>({});
  const [seasons, setSeasons] = React.useState<any[]>([]);
  const [modalities, setModalities] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const form = React.useRef<any>({});

  useComponentDidMount(async () => {
    try {
      // load seasons
      const response = await SeasonService.findSeasons();
      ModalityService.findAll().then(r => {
        setModalities(r.data);
      })
      setSeasons(
        response.data.map((season: any) => ({
          label: season.name,
          value: season.id,
        }))
      );
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "seasonId", type: REQUIRED },
      { name: "modalityId", type: REQUIRED },
    ];

    const { errors, hasErrors } = validateAll(form.current, fields);
    setErrors(errors);
    return !hasErrors;
  }, [validateAll]);

  const onChangeSeason = React.useCallback(
    async (value: string) => {
      const seasonId = parseInt(value);
      if (!isNaN(seasonId)) {
        form.current.seasonId = seasonId;
      } else {
        form.current.seasonId = undefined;
      }
    },
    [form]
  );

  const onChangeLogo = React.useCallback(
    (value: IImageUploaderData) => {
      form.current.logoFile = value.base64;
      form.current.originalLogoFilename = value.name;
    },
    [form]
  );

  const onCreate = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    const competition: CompetitionService.CompetitionDTO = {
      name: form.current.name,
      description: form.current.description,
      modalityId: form.current.modalityId,
      seasonId: form.current.seasonId,
      logoFile: form.current.logoFile,
      originalLogoFilename: form.current.originalLogoFilename,
    };

    try {
      await CompetitionService.create(competition);
      alerts.create({ text: t("global.success"), type: "success" });
      navigate("/organizer/competitions");
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validate, navigate, form]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container title={t("admin.competitions.title")}>

      <View flexDirection="row">
        <Title text={t("admin.competitions.list.create")} containerStyle={{ flex: 1 }} />
        <FloatingIconButton
          name="list"
          title={t("admin.backToList")}
          color="black"
          backgroundColor="transparent"
          onPress={() => navigate(`/organizer/competitions`)}
          containerStyle={{ position: "relative" }}
          right={15}
        />
      </View>

      <Form>
        <Row flex={[3, 2]}>
          <Input
            label={t("admin.competitions.list.name")}
            onChange={(val) => (form.current.name = val)}
            error={errors.name}
            disabled={readOnly}
            required
          />
          <Picker
            label={t("admin.competitions.edit.modality")}
            items={modalities.map(m => ({ label: m.name, value: m.id }))}
            defaultValue={0}
            onChange={(val) => (form.current.modalityId = val)}
            error={errors.modalityId}
            disabled={readOnly}
            required
          />
          <Picker
            label={t("admin.competitions.list.season")}
            items={seasons}
            onChange={onChangeSeason}
            error={errors.seasonId}
            disabled={readOnly}
            required
          />
        </Row>
        <Row>
          <Input
            label={t("admin.competitions.list.description") + ` (${t('global.optional')})`}
            onChange={(val) => (form.current.description = val)}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <ImageUploader label={t("admin.competitions.edit.selectLogo")} onChange={onChangeLogo} disabled={readOnly} />
        </Row>
        <Row>
          <Button label={t("global.create")} onPress={onCreate} disabled={readOnly} />
        </Row>
      </Form>
    </Container>
  );
};
