import { BACKEND_URL } from 'src/config';
import * as RestService from 'src/services/RestService';


const BASE_URL = BACKEND_URL + '/auth/modality'

/**
 * Devuelve todas las competiciones en las que el usuario tiene un equipo inscrito
 */
export const findAll = () => {
  return RestService.get(`${BASE_URL}`);
}