import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IFullCalendarDTO {
	round: IRoundDTO;
	combination: ICombinationDTO;
}

export interface IRoundDTO {
	id: number;
	uuid: string;
	startDate: Date;
	groupId: number;
	roundNumber: number;
	matches: IMatchDTO[];
}

export interface IMatchDTO {
	awayTeam: number;
	homeTeam: number;
	courtId: number;
	gameDate: Date;
	roundNumber: number;
}

export interface ICombinationDTO {
	homeTeam: number;
	awayTeam: number;
	roundNumber: number;
}

export enum RepeatType {
	REPEAT_DAILY = 1,
	REPEAT_HOURLY = 2
}

const BASE_URL = BACKEND_URL + '/auth/combination-tables'

/**
 * Genera toda la información del calendario sin guardarla en la base de datos
 */
export const generate = (groupId: number, roundNumber: number, symmetrical: boolean, firstRoundDate: string, repeatType: RepeatType, repeat: number, teamsInGroup) => {
	return RestService.get(`${BASE_URL}/generator?teamsInGroup=${teamsInGroup}&groupId=${groupId}&roundNumber=${roundNumber}&symmetrical=${symmetrical}&firstRoundDate=${firstRoundDate}&repeatType=${repeatType}&repeat=${repeat}`);
}

/**
 * Persiste toda la información de un calendario (jornadas y partidos)
 */
export const saveCalendar = (payload) => {
	return RestService.post(`${BASE_URL}/save-all-calendar`, payload);
}

/**
 * Persiste toda la información de un calendario (jornadas y partidos)
 */
export const updateMatch = (payload) => {
	return RestService.post(`${BASE_URL}/update-match?groupId=${payload.groupId}&roundId=${payload.roundId}&matchId=${payload.id}`, payload);
}

export const generateKnockout = (payload) => {
	return RestService.put(`${BASE_URL}/knockout-generator`, payload);
}

export const saveKnockout = (payload) => {
	return RestService.post(`${BASE_URL}/knockouts`, payload);
}

export const getByGroupUuid = (groupUuid) => {
	return RestService.get(`${BASE_URL}/knockout-by-group/${groupUuid}`);
}

export const modifyKnockout = ({ knockoutId, presentTeam, newTeam, onlyKnockout }, groupUuid) => {
	return RestService.put(`${BASE_URL}/modify-knockout?groupUuid=${groupUuid}&knockoutId=${knockoutId}&presentTeam=${presentTeam}&newTeam=${newTeam}&onlyKnockout=${onlyKnockout ?? false}`)
}
