import dayjs from "dayjs";
import moment from "moment";
import { Checkbox, Icon, Input, Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { ColorPicker } from "src/components/ColorPicker";
import { Form } from "src/components/Form";
import { Loader } from "src/components/Loader";
import { MatchCardMinimal } from "src/components/MatchCardMinimal";
import { Modal } from "src/components/Modal";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Row } from "src/components/Row/Row";
import { Tabs } from "src/components/Tabs";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { useColors } from "src/hooks/useColors";
import { useEffectChange } from "src/hooks/useEffectChange";
import * as CombinationTableService from "src/services/CombinationTableService";
import * as CourtService from "src/services/CourtService";
import * as GroupService from "src/services/GroupService";
import * as GroupTeamService from "src/services/GroupTeamService";
import * as MatchResultService from "src/services/MatchResultService";
import * as MatchService from "src/services/MatchService";
import * as TeamService from "src/services/TeamService";
import { EventContext } from "../EventContext";
import { CreateMatchModal } from "../components/CreateMatchModal";
import { KnockOff } from "../components/KnockOff";
import { LeagueStepper } from "../components/LeagueStepper";
import { Standings } from "../components/Standings";
import { EventCategoryForm } from "./components/EventCategoryForm";
const _ = require("lodash");



const courtLevels = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
]

export const CategoryGroup = () => {
  const colors = useColors('primary');
  const form = React.useRef<any>({});
  const { categoryId } = useParams();
  const { groupUuid } = useParams();
  const { data: event } = React.useContext(EventContext);
  const [loading, setLoading] = React.useState(true);
  const [courts, setCourts] = React.useState<any[]>([]);
  const [teams, setTeams] = React.useState<any[]>([]);
  // const [groupTeams, setGroupTeams] = React.useState<any[]>([]);
  const [addedTeams, setAddedTeams] = React.useState<any[]>([]);
  const [availableTeams, setAvailableTeams] = React.useState<any[]>([]);
  const [group, setgroup] = React.useState<any>({});
  const [eventCategoryGroups, setEventCategoryGroups] = React.useState<any>({});
  const [rounds, setRounds] = React.useState<any[]>([]);
  const [knockOut, setKnockOut] = React.useState<any[]>([]);
  const [generate, setGenerate] = React.useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = React.useState<number>(null);
  const [modalVisible, setModalVisible] = React.useState<boolean>(null);
  const [deleteGroupMatchesConfirm, setDeleteGroupMatchesConfirm] = React.useState<boolean>(null);
  const [roundToUpdate, setRoundToUpdate] = React.useState<number>(null);
  const { create: createAlert, createDSMError } = useAlerts();
  const alerts = useAlerts();
  const navigate = useNavigate();
  const tab = React.useRef(0);

  useEffectChange(async () => {
    if (!categoryId) {
      return navigate("/organizer/competitions");
    }

    setLoading(true);

    try {
      const [teams, groupTeams, group, matches, knockout, courts] = await Promise.all([
        TeamService.findTeamsByEvent(event.uuid, parseInt(categoryId), null, null),
        GroupTeamService.getByGroupUuid(groupUuid),
        GroupService.findByUuid(groupUuid),
        MatchService.getByEventAndGroupUuid(event.uuid, groupUuid),
        CombinationTableService.getByGroupUuid(groupUuid),
        CourtService.getCourtsByEventUuid(event.uuid),
      ]);
      const eventCategoryGroups = await GroupService.getByCategoryId(group.data.eventCategoryId);
      setEventCategoryGroups(eventCategoryGroups.data);
      
      setTeams(teams.data.filter(element => !element.isFicticious || (element.isFicticious && element.registerStatus !== 0)));
      setgroup(group.data);
      setKnockOut(knockout.data);
      setCourts(courts.data);
      form.current = group.data;

      const mappedMatches = matches.data.map((match) => {
        const homeTeam = teams.data.find((team) => team.id === match.homeTeamId);
        const awayTeam = teams.data.find((team) => team.id === match.awayTeamId);

        return {
          homeTeam: homeTeam,
          awayTeam: awayTeam,
          matchDateHour: match.matchDateHour,
          roundNumber: match.roundNumber,
          roundId: match.roundId,
          roundDate: moment(match.roundDate).format("DD-MM-YYYY"),
          matchCallId: match.matchCallId,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          matchResultId: match.matchResultId,
          courtId: match.courtId,
        };
      });

      const mappedRounds = Object.values(_.groupBy(mappedMatches, "roundId"));
      setRounds(mappedRounds);

      setAddedAndAvailableTeams(teams.data, groupTeams.data);
    } catch (error) {
      createDSMError(error);
    }

    setLoading(false);
  }, [categoryId, generate]);

  const updateKnockout = React.useCallback(async () => {
    try {
      const response = await CombinationTableService.getByGroupUuid(groupUuid);
      setKnockOut(response.data);
    } catch (error) {
      createDSMError(error);
    }
  }, [createDSMError, groupUuid]);

  const setAddedAndAvailableTeams = React.useCallback((teams: any, groupTeams: any) => {
    const addedTeamsUpdated = teams.map((team: any) => {
      const groupTeam = groupTeams.find((groupTeam: any) => groupTeam.teamId === team.id);
      return groupTeam ? { ...team, position: groupTeam.position } : null;
    })
      .filter((addedTeam: any) => addedTeam)
      .sort((a: any, b: any) => a.position - b.position);

    setAddedTeams(addedTeamsUpdated);
    setAvailableTeams(teams.filter((team: any) => !addedTeamsUpdated.find((addedTeam: any) => addedTeam.id === team.id)));
  }, []);

  const addTeam = React.useCallback(async (team: any) => {
    const payload: GroupTeamService.IgroupTeamDTO = {
      groupId: group.id,
      teamId: team.id,
      groupName: group.name,
      teamName: team.name,
    };

    try {
      await GroupTeamService.addToGroup(payload);
      const teamToAdd = { ...team, position: addedTeams.length > 0 ? Math.max(...addedTeams.map(o => o.position)) + 1 : 1 };

      const newAddedTeams = [...addedTeams, teamToAdd];
      const newAvailableTeams = availableTeams.filter(availableTeam => availableTeam.id !== teamToAdd.id);

      setAvailableTeams(newAvailableTeams);
      setAddedTeams(newAddedTeams);

    } catch (error) {
      createDSMError(error);
    }
  }, [addedTeams, availableTeams, createDSMError, group]);

  const deleteTeam = React.useCallback(async (teamToDelete: any) => {
    try {
      await GroupService.deleteTeamFromGroup(group.id, teamToDelete.id);

      const newAddedTeams = addedTeams
        .filter(addedTeam => addedTeam.id !== teamToDelete.id)
        .map(addedTeam => addedTeam.position > teamToDelete.position ? { ...addedTeam, position: addedTeam.position - 1 } : addedTeam);
      const newAvailableTeams = [...availableTeams, teamToDelete];

      setAvailableTeams(newAvailableTeams);
      setAddedTeams(newAddedTeams);

    } catch (error) {
      createDSMError(error);
    }
  }, [addedTeams, availableTeams, createDSMError, group]);

  const updateTeams = React.useCallback((teams: any[]) => {
    setAddedTeams(teams);
  }, []);

  const addFicticiousTeam = React.useCallback(async (object: any) => {
    try {
      const newEventCategoryId = window.location.pathname.split('/')[5]
      const response = await TeamService.createFicticiousTeam({ ...object, newGroupId: group.id, newEventCategoryId: newEventCategoryId });
      setAddedTeams([...addedTeams, response.data])
    } catch (error) {
      createDSMError(error)
    }
  }, [createDSMError, group, addedTeams])

  const fillFicticious = React.useCallback(async () => {
    try {
      const response = await TeamService.changeFicticious(group.id);
      setAddedTeams(response.data);
      updateKnockout();
    } catch (error) {
      createDSMError(error)
    }
  }, [createDSMError, updateKnockout, group.id])

  const replaceTeam = React.useCallback(async (object: any, teamId: any) => {
    try {
      await GroupTeamService.replaceTeam({ teamToReplaceId: teamId, groupId: group.id, teamId: object.id });

      const newTeams = addedTeams.filter(team => team.id !== teamId);
      newTeams.push(availableTeams.find(team => team.id === object.id));

      const newAvailableTeams = availableTeams.filter(team => team.id !== object.id);
      newAvailableTeams.push(teams.find(team => team.id === teamId))

      setAddedTeams(newTeams);
      setAvailableTeams(newAvailableTeams);
      updateKnockout();
    } catch (error) {
      createDSMError(error)
    }

  }, [group, teams, availableTeams, addedTeams, updateKnockout, createDSMError])

  const replaceKnockoutTeam = React.useCallback(async (object: any) => {
    try {
      await CombinationTableService.modifyKnockout(object, groupUuid)
      updateKnockout();
    } catch (error) {
      createDSMError(error)
    }
  }, [groupUuid, updateKnockout, createDSMError])

  const updateGroup = React.useCallback(async () => {
    try {
      const response = await GroupService.updateGroup(form.current);
      alerts.create({ text: t("global.success"), type: "success" });
      setgroup(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts]);

  const createMatch = async (payload) => {
    try {
      await MatchService.createMatch(payload);
      const matches = await MatchService.getByEventAndGroupUuid(event.uuid, groupUuid);

      const mappedMatches = matches.data.map((match) => {
        const homeTeam = teams.find((team) => team.id === match.homeTeamId);
        const awayTeam = teams.find((team) => team.id === match.awayTeamId);

        return {
          homeTeam: homeTeam,
          awayTeam: awayTeam,
          matchDateHour: match.matchDateHour,
          roundNumber: match.roundNumber,
          roundId: match.roundId,
          roundDate: moment(match.roundDate).format("DD-MM-YYYY"),
          matchCallId: match.matchCallId,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          matchResultId: match.matchResultId
        };
      });

      const mappedRounds = Object.values(_.groupBy(mappedMatches, "roundId"));
      setRounds(mappedRounds);
      setModalVisible(false);

      createAlert({ text: t("global.success"), type: "success" });
    } catch (error) {
      createDSMError(error);
    }
  }

  const addMatchResult = async (payload) => {
    const roundsCopy = [...rounds];

    const matchToUpdate = Object.values(roundsCopy).map(match => {
      return match.find((m: any) => parseInt(m.matchCallId) === parseInt(payload.matchCallId))
    }).filter(f => !!f)[0]

    matchToUpdate.homeScore = payload.homeScore
    matchToUpdate.awayScore = payload.awayScore

    try {
      if (payload.matchResultId) {
        await MatchResultService.updateMatchResult(payload)
      }
      else {
        await MatchResultService.addMatchResult(payload)
      }

      setRounds(roundsCopy);
    } catch (error) {
      createDSMError(error);
    }
  }

  const onUpdateCourtAndDate = async (object: any) => {
    const roundsCopy = [...rounds];

    const matchToUpdate = Object.values(roundsCopy).map(match => {
      return match.find((m: any) => parseInt(m.matchCallId) === parseInt(object.matchCallId))
    }).filter(f => !!f)[0]

    matchToUpdate.courtId = object.courtId;
    matchToUpdate.matchDateHour = !!object.matchDateHourValue ? dayjs(object.matchDateHourValue, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:00') : object.matchDateHour;

    try {
      await MatchService.updateCourtAndDate({
        matchCallId: matchToUpdate.matchCallId,
        courtId: matchToUpdate.courtId,
        matchDateHour: matchToUpdate.matchDateHour
      });

      setRounds(roundsCopy);
    } catch (error) {
      createDSMError(error);
    }
  }



  const deleteGroupMatches = React.useCallback(async (groupUuid: string) => {
    setLoading(true)

    try {
      setDeleteGroupMatchesConfirm(false)
      await MatchService.deleteGroupMatches(groupUuid)
      alerts.create({ text: t("admin.events.group.deleteGroupMatchesOk"), type: "success", });
      setRounds([])
      
    }
    catch(error) {
      setDeleteGroupMatchesConfirm(false)
      createDSMError(error)
    }

    setLoading(false)

  }, [alerts, createDSMError])


  const renderTeams = () => {
    return (
      <View alignItems="center">
        {addedTeams.map(team => {
          return (
            <TouchableOpacity key={team.id} onPress={() => { setSelectedTeam(selectedTeam === team.id ? undefined : team.id) }} style={[styles.teams, { maxWidth: '30%' }]}>
              <Text bold color={team.id === selectedTeam ? colors.accent : ''} >{team.position} - {team.name.toUpperCase()}</Text>
              <Icon type="eye" color={team.id === selectedTeam ? colors.accent as string : ''} />
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  const renderMatches = () => {
    if (!group.isKnockout) {
      return (        
        <View>
          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {rounds.map((matches, i) => {
              return (
                <View key={i} style={{ width: 290, padding: 10 }}>
                  <MatchCardMinimal
                    id={i}
                    roundNumber={i + 1}
                    round={matches}
                    roundDate={matches[0].roundDate}
                    onUpdate={addMatchResult}
                    onUpdateCourtAndDate={onUpdateCourtAndDate}
                    selectedTeam={selectedTeam}
                    courts={courts}
                    onAddMatch={() => {
                      setModalVisible(true);
                      setRoundToUpdate(matches[0].roundId)
                    }}
                  />
                </View>
              )
            })}          
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', paddingTop: 60 }}>
            <Button label={t('admin.events.group.deleteGroupMatches')} onPress={() => setDeleteGroupMatchesConfirm(true)} />
          </View>  
        </View>
        
      )
    }

    return (
      <View>
        <KnockOff
          hasThirdPlace={knockOut.length % 2 === 0}
          data={knockOut}
          teams={addedTeams}
          onUpdateMatch={updateKnockout}
          onReplaceTeam={replaceKnockoutTeam}
          courts={courts}
        />
      
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          <Button label={t('admin.events.group.deleteGroupMatches')} onPress={() => setDeleteGroupMatchesConfirm(true)} />
        </View>
      </View>
  
    )
  }

  const changeTeamPosition = React.useCallback(async (teamId: number, increase: boolean) => {
    const teamToMove = addedTeams.find(team => team.id === teamId);
    const lastPosition = Math.max(...addedTeams.map(team => team.position));

    if (increase && teamToMove.position === lastPosition) {
      alerts.create({ text: t('admin.events.team.lastPositionError'), type: "error" });
      return;
    }

    if (!increase && teamToMove.position === 1) {
      alerts.create({ text: t('admin.events.team.firstPositionError'), type: "error" });
      return;
    }

    try {
      await GroupTeamService.changeTeamPosition(group.id, teamToMove.id, increase);

      const teamsMoved = addedTeams.map(team => {
        if (increase && team.position === teamToMove.position + 1) {
          return { ...team, position: team.position - 1 };
        }
        if (!increase && team.position === teamToMove.position - 1) {
          return { ...team, position: team.position + 1 };
        }

        if (team.id === teamToMove.id) {
          return { ...team, position: (increase ? (team.position + 1) : (team.position - 1)) };
        }
        return team;
      })

      setAddedTeams(teamsMoved);

    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [group, alerts, addedTeams]);

  const tabHeaders = [
    t('admin.events.detail'),
    t('admin.events.teams'),
    rounds.length > 0 ? t('admin.events.matches.title') : t('admin.events.league.generate'),
  ];

  if (!group.isKnockout && rounds.length > 0) {
    tabHeaders.push(t('admin.events.standings.title'));
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <View>
      <Row flex={[1, null]}>
        <Title text={group.name} containerStyle={{ flex: 1 }} />
        <ButtonLink type='link' color={colors.main} to={`/organizer/events/${event.uuid}/categories/${group.eventCategoryId}`} label={`« ${group.eventCategoryName}`} />
      </Row>

      <Tabs
        headers={tabHeaders}
        initialTab={tab.current}
        onChangeTab={(index: number) => tab.current = index}>

        {/* DETAIL TAB */}
        <Form>
          <Row flex={[2, 1, 1]}>
            <Input
              label={t("admin.events.group.name")}
              defaultValue={form.current.name}
              onChange={(val) => form.current.name = val}
            />

            <ColorPicker
              label={t("admin.events.group.color")}
              defaultValue={form.current.color}
              onChange={(val) => form.current.color = val}
            />

            <Picker
              label={t("admin.courts.courtLevel")}
              defaultValue={form.current.minimumCourtLevel}
              items={courtLevels}
              onChange={(value) => (form.current.minimumCourtLevel = value)}
              required
            />
          </Row>
          <Row>
            <Checkbox label={t("admin.events.group.standingVisible")}
              defaultValue={form.current.standingVisible}
              onChange={value => form.current.standingVisible = value}
              disabled={false}
            />
          </Row>
          <Row>
            <Button label={t("global.save")} onPress={updateGroup} />
          </Row>
        </Form>

        {/* TEAMS TAB */}
        <EventCategoryForm
          availableTeams={availableTeams}
          addedTeams={addedTeams}
          updateTeams={updateTeams}
          deleteTeam={deleteTeam}
          onMoveTeam={changeTeamPosition}
          addTeam={addTeam}
          addFicticiousTeam={addFicticiousTeam}
          fillFicticious={fillFicticious}
          replaceTeam={replaceTeam}
          groups={eventCategoryGroups}
          currentGroupId={group.id}
        />

        {/* LEAGUE STEPPER TAB */}
        {rounds.length > 0
          ? (
            <View flexDirection="column" style={{ gap: 20 }}>
              {(!group.isKnockout) && renderTeams()}
              {renderMatches()}
            </View>
          ) : (
            <View>
              <LeagueStepper
                teams={addedTeams}
                groupId={group.id}
                onGenerate={setGenerate}
                onChange={changeTeamPosition}
              />
            </View>
          )
        }

        {/* STANDINGS TAB */}
        {!group.isKnockout && rounds?.length > 0 && <Standings teams={teams} matches={rounds} />}

      </Tabs>

      <Modal visible={modalVisible} onClose={() => { setModalVisible(false) }} title={t('admin.events.team.createMatch')} >
        <CreateMatchModal teams={addedTeams} courts={courts} roundId={roundToUpdate} groupId={group.id} onSave={createMatch} onCancel={() => { setModalVisible(false) }} />
      </Modal>

      <ModalConfirm
        visible={deleteGroupMatchesConfirm}
        text={t("admin.events.group.deleteGroupMatchesConfirmation")}
        onSuccess={() => deleteGroupMatches(group.uuid)}
        onClose={() => setDeleteGroupMatchesConfirm(false)}
        />
    </View>


  );
};

const styles = StyleSheet.create({
  teams: {
    width: '100%',
    padding: 8,
    shadowColor: 'black',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    borderColor: '#dbdbdb',
    borderStyle: 'solid',
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});
