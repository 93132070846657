import * as React from 'react';
import { ColorValue } from 'react-native';
import { IAlert } from 'src/components/Alert';
import { IPortal } from 'src/services/PortalService';
import { IShoppingCart } from 'src/services/ShoppingCartService';

export const ORGANIZER_READ_ONLY = 'ORGANIZER_READ_ONLY';

export interface IUser {
  id?: string;
  jwt?: string;
  username?: string;
  isOrganizer?: boolean;
  permissions?: string[];
  admin?: boolean;
};

export interface IOWCOpenDmrContext {
  user?: IUser;
  setUser: (user?: IUser) => void;
  changeLanguage: (language: string) => void;
  alertItems: IAlert[];
  alerts: {
    create: (data: IAlert) => void;
    delete: (id?: number | string) => void;
  };
  configuration: {
    colors: IOWCOpenDmrContextColors;
    portal: IPortal;
  };
  shoppingCart?: IShoppingCart;
};

export interface IOWCOpenDmrContextColors {
  default: IOWCOpenDmrContextColor,
  primary: IOWCOpenDmrContextColor,
  secondary: IOWCOpenDmrContextColor,
  error: IOWCOpenDmrContextColor,
  success: IOWCOpenDmrContextColor,
};

export interface IOWCOpenDmrContextColor {
  main: ColorValue;
  // background?: ColorValue;
  light: ColorValue;
  dark: ColorValue;
  accent: ColorValue;
  text: ColorValue;
};
const defaultColors = {
  default: {
    main: '#333',
    light: '#666',
    dark: '#000',
    accent: '#000',
    text: '#eee',
  },
  primary: {
    main: '#333',
    light: '#666',
    dark: '#000',
    accent: '#000',
    text: '#333',
  },
  secondary: {
    main: '#333',
    light: '#666',
    dark: '#000',
    accent: '#000',
    text: '#333',
  },
  error: {
    main: 'crimson',
    light: 'lightred',
    dark: 'darkred',
    accent: 'darkred',
    text: 'white',
  },
  success: {
    main: 'teal',
    light: 'lightgreen',
    dark: 'darkgreen',
    accent: 'darkgreen',
    text: 'white',
  }
}
const value: IOWCOpenDmrContext = {
  user: undefined,
  setUser: () => { },
  changeLanguage: () => { },
  alertItems: [],
  alerts: {
    create: () => { },
    delete: () => { },
  },
  configuration: {
    colors: defaultColors,
    portal: {
      id: 0,
      portal: '',
      logo: '',
      secondarylogo: '',
      background: '',
      actorLopd: '',
      teamLopd: '',
      colors: defaultColors,
      availableLanguages: ['es','ca','en'],
      modalities: []
    }
  },
  shoppingCart: undefined
};

export const OWCOpenDmrContext = React.createContext(value);