import * as React from 'react'
import { View } from '../View';
import { Text } from '../Text';
import { ITableHeader } from './Header';
import { StyleSheet } from 'react-native';
import { t } from 'owc-react-lib-common';


export interface IBody<T> {
    content: T[];
    headers: ITableHeader<T>[];
}

export const Body = <T,>({ content, headers }: IBody<T>) => {
    const [filtered, setFiltered] = React.useState<any[]>(content);

    React.useEffect(() => {
        setFiltered(content);
    }, [content])

    const getColumnStyle = (header: ITableHeader<T>) => {
        const { width, flex } = header;
        return { width, flex: flex || !width ? 1 : undefined }
    }



    return (
        <View style={styles.body}>
            {!filtered?.length ? (
                <View style={styles.row} justifyContent='center'>
                    <View style={styles.cell}>
                        <Text>{t('components.table.empty')}</Text>
                    </View>
                </View>
            ) : (
                filtered.map((row: any, index: number) => {
                    return (
                        <View key={row.idx || index} style={styles.row}>
                            {headers.filter((header: ITableHeader<T>) => !header.hidden).map((header: ITableHeader<T>, columnIdx: number) => {
                                const { render, textProps } = header;
                                const props = typeof textProps === 'function' ? textProps(row) : textProps;

                                return (
                                    <View key={header.name} style={[styles.cell, columnIdx === 0 && styles.content, getColumnStyle(header)]}>
                                        {render?.(row) || <Text {...props}>{row[header.name]}</Text>}
                                    </View>
                                );
                            })}
                        </View>
                    );
                }))}
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        overflow: 'hidden',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#ccc',
    },
    cell: {
        paddingHorizontal: 5,
        paddingVertical: 10,
        justifyContent: 'center',
        minHeight: 60,
        overflow: 'hidden',
    },
    content: {
    }
});