import { Checkbox, t } from 'owc-react-lib-common';
import React from 'react';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import { Modal } from 'src/components/Modal';
import { PhotoCircle } from 'src/components/PhotoCircle';
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import {
    TEAM_STATUS_COMPLETED,
    TEAM_STATUS_INVITE,
    TEAM_STATUS_PENDING,
    TEAM_STATUS_PROCESSED,
    TEAM_STATUS_VALIDATED
} from "src/pages/auth/components/config";
import { EventCategoryTeams } from '../../categories/EventCategoryTeams';
import { FicticiousTeamCreation } from './FicticiousTeamCreation';

export interface IEventCategoryForm {
    availableTeams: any[];
    addedTeams: any[];
    addTeam: (object: any) => void;
    addFicticiousTeam: (object: any) => void;
    fillFicticious: () => void;
    deleteTeam: (object: any) => void;
    updateTeams: (addedTeams: any[]) => void;
    onMoveTeam?: (id: number, increase: boolean) => void;
    replaceTeam: (object: any, uuid: string) => void;
    groups: any;
    currentGroupId: any;
}

export const EventCategoryForm = ({ addTeam, addFicticiousTeam, fillFicticious, availableTeams, addedTeams, deleteTeam, updateTeams, onMoveTeam, replaceTeam, groups, currentGroupId }: IEventCategoryForm) => {
    const [showTeams, setShowTeams] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
    const [teamsModal, setTeamsModal] = React.useState<boolean>(false);
    const [teams, setTeams] = React.useState([]);
    const [ficticiousModal, setFicticiousModal] = React.useState<boolean>(false);
    const [currentGroup, setCurrentGroup] = React.useState<number>(currentGroupId);

    const [replaceTeamVisible, setReplaceTeamVisible] = React.useState<boolean>(false);
    const [teamToReplace, setTeamToReplace] = React.useState<string>(null);


    const openModal = React.useCallback(() => setTeamsModal(true), []);
    


    React.useEffect(() => {
        let t = availableTeams.filter(t => !t.isFicticious)

        // Filtre ocultar pendents
        if (completed) {
            t = t.filter(team => team.registerStatus > 0);
        }

        // Filtre mostrar equips sense grup
        if (showTeams) {
            t = t.filter(team => !team.hasGroup)
        }

        setTeams(t)

    }, [availableTeams, showTeams, completed])


    const closeModalActions = React.useCallback(async () => {
        setShowTeams(false);
        setCompleted(false);
        setTeamsModal(false);
    }, []);

    const getStatusName = React.useCallback((object: any) => {
        switch (parseInt(object.registerStatus)) {
            case TEAM_STATUS_PENDING:
                return t("admin.competitions.eventsCompetition.eventTeams.status.pending");
            case TEAM_STATUS_PROCESSED:
                return t("admin.competitions.eventsCompetition.eventTeams.status.processed");
            case TEAM_STATUS_VALIDATED:
                return t("admin.competitions.eventsCompetition.eventTeams.status.validated");
            case TEAM_STATUS_COMPLETED:
                return t("admin.competitions.eventsCompetition.eventTeams.status.completed");
            case TEAM_STATUS_INVITE:
                return t('admin.competitions.eventsCompetition.eventTeams.status.invite');
        }
        return "";
    }, []);

    const status = React.useCallback(
        (object: any) => {
            const statusName = getStatusName(object);

            switch (parseInt(object.registerStatus)) {
                case TEAM_STATUS_PENDING:
                    return (<Text uppercase color="orange">{statusName}</Text>);
                case TEAM_STATUS_PROCESSED:
                    return <Text uppercase>{statusName}</Text>;
                case TEAM_STATUS_VALIDATED:
                case TEAM_STATUS_COMPLETED:
                    return (<Text uppercase color="teal">{statusName}</Text>);
                case TEAM_STATUS_INVITE:
                    return (<Text uppercase bold color="darkgreen">{statusName}</Text>);
            }
        }, [getStatusName]);

    const actions = React.useCallback((object: any) => {
        return (
            <View flexDirection='row' alignItems='center'>
                <IconButton backgroundColor={'transparent'} color={'black'} name='add' title={t('global.add')} onPress={() => addTeam(object)} />
            </View>
        )
    }, [addTeam]);

    const replaceActions = React.useCallback((object: any) => {
        return (
            <View flexDirection='row' alignItems='center'>
                <IconButton backgroundColor={'transparent'} color={'black'} name='right-left' title={t('global.replace')} onPress={() => replaceTeam(object, teamToReplace)} />
            </View>
        )
    }, [replaceTeam, teamToReplace]);

    const logo = React.useCallback((object: any) => {
        return <PhotoCircle alt={object.name} url={object.teamLogoUrl} size={60} />;
    }, []);

    const onReplace = React.useCallback(async (teamId) => {
        setReplaceTeamVisible(true);
        setTeamToReplace(teamId);
    }, [])

    const modalHeaders: ITableHeader[] = [
        { name: "logo", label: t("admin.competitions.eventsCompetition.eventTeams.name"), render: logo, width: 70 },
        { name: "name", label: "", textProps: { uppercase: true } },
        { name: "teamLevelName", label: t("admin.competitions.eventsCompetition.eventTeams.teamLevelName"), width: 200 },
        {
            name: "registerStatus",
            label: t("admin.competitions.eventsCompetition.eventTeams.registerStatus"),
            render: status,
            search: getStatusName,
            width: 150,
        },
        { name: "actions", label: t("global.actions"), render: actions, width: 90 },
    ];

    const replaceHeaders: ITableHeader[] = [
        ...modalHeaders.slice(0, 4),
        { name: "actions", label: t("global.actions"), render: replaceActions, width: 90 },
    ]

    return (
        <View>
            <View flexDirection='row' style={{ gap: 5 }}>
                <Button label={t("admin.events.team.add")} onPress={openModal} />
                <Button label={t("admin.events.team.ficticious")} onPress={() => setFicticiousModal(true)} />
                <Button label={t("admin.events.team.fillFicticious")} onPress={fillFicticious} />
            </View>
            <Modal
                visible={teamsModal}
                header
                title={t("admin.events.team.add")}
                onClose={closeModalActions}
                children={
                    <View>
                        <View flexDirection='row' style={{gap:10}}>
                            <Checkbox label={t("admin.events.team.showNogroup")} onChange={(v) => setShowTeams(v)} defaultValue={false} />
                            <Checkbox label={t("admin.events.team.notFinished")} onChange={(v) => setCompleted(v)} defaultValue={false} />
                        </View>
                        <Table headers={modalHeaders} data={teams} />
                        {/* <Table headers={modalHeaders} data={availableTeams.filter(t => !t.isFicticious && showTeams ? showTeams !== t.hasGroup : true)} /> */}
                    </View>
                }
            />
            <Modal
                visible={ficticiousModal}
                header
                title={t("admin.events.team.add")}
                onClose={() => setFicticiousModal(false)}
            // minHeight={800}
            >
                <FicticiousTeamCreation currentGroupId={currentGroup} onSave={(e) => { addFicticiousTeam(e); setFicticiousModal(false) }} onCancel={() => setFicticiousModal(false)} />
            </ Modal>

            <EventCategoryTeams
                data={addedTeams.filter(element => !element.isFicticious || (element.isFicticious && element.registerStatus !== 0))}
                questions={[]}
                updateTeams={updateTeams}
                onDelete={deleteTeam}
                onMoveTeam={onMoveTeam}
                onReplace={onReplace}
                persist={true}
                allTeamsButton={false}
                groups={groups}
            />

            <Modal visible={replaceTeamVisible} onClose={() => { setReplaceTeamVisible(false) }} title={t('admin.events.team.replace')}>
                {/* <ReplaceTeamModal teamUuid={teamToReplace} teams={availableTeams} /> */}
                <Table headers={replaceHeaders} data={teams} />
            </Modal>
        </View>
    )
}
