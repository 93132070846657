import * as React from "react";
import { t, useComponentDidMount } from "owc-react-lib-common";

import { useAlerts } from "src/hooks/useAlerts";
import { Loader } from "src/components/Loader";
import { useParams } from "react-router-dom";
import { StyleSheet } from "react-native";
import { View } from "src/components/View";
import { Button } from "src/components/Button";
import { ITableHeader, Table } from "src/components/Table";
import { ModalConfirm } from "src/components/ModalConfirm";
import { IconButton } from "src/components/IconButton";
import { Modal } from "src/components/Modal";
import { Input } from "owc-react-lib-common"
import * as RankingSystemService from "src/services/RankingSystemService";
import { RankingSystemContext } from "./RankingSystemContext";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const RankingSystemDetailList = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { data: rankingSystem } = React.useContext(RankingSystemContext);
  const alerts = useAlerts();
  const rankingSystemUuid = useParams().uuid;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const [detailToDelete, setDetailToDelete] = React.useState<any>(null);
  const [detailToEdit, setDetailToEdit] = React.useState<any>(null);
  const form = React.useRef<any>({});
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "position", type: REQUIRED },
      { name: "points", type: REQUIRED },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  useComponentDidMount(async () => {
    setLoading(true);
    try {
      const response = await RankingSystemService.findRankingSystemDetailsByRankingSystemUuid(rankingSystemUuid);
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  });

  const actions = React.useCallback((detail: any) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          name="edit"
          color="black"
          backgroundColor="transparent"
          onPress={() => {
            form.current = { ...detail };
            setDetailToEdit(detail);
          }}
        />
        <IconButton
          name="trash"
          color="black"
          backgroundColor="transparent"
          onPress={() => setDetailToDelete(detail)} />
      </View>
    );
  }, []);

  const createDetail = React.useCallback(async () => {
    if (!validate()) return;
    try {
      form.current.rankingSystemId = rankingSystem.id;
      const response = await RankingSystemService.createRankingSystemDetail(form.current);
      alerts.create({
        text: t("admin.rankingSystems.createConfirmation"),
        type: "success",
      });
      const newData = [...data, { ...response.data }].sort((a, b) => a.position - b.position);
      setData([...newData]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setDetailToEdit(null);
    form.current = {};
  }, [data, alerts, rankingSystem, validate]);

  const updateDetail = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const response = await RankingSystemService.updateRankingSystemDetail(form.current);
      alerts.create({
        text: t("admin.rankingSystems.updateConfirmation"),
        type: "success",
      });
      const newData = [...data.map(detail => detail.id === response.data.id ? response.data : detail)].sort((a, b) => a.position - b.position);
      setData([...newData]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setDetailToEdit(null);
    form.current = {};
  }, [alerts, data, validate]);

  const deleteDetail = React.useCallback(async () => {
    try {
      await RankingSystemService.deleteRankingSystemDetail(detailToDelete.id);
      alerts.create({
        text: t("admin.rankingSystems.deleteConfirmation"),
        type: "success",
      });
      setData(data.filter((court: any) => court.id !== detailToDelete.id));
      setDetailToDelete(null);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [detailToDelete, data, alerts]);

  if (loading) {
    return <Loader />;
  }

  const headers: ITableHeader[] = [
    // { name: "id", label: t("admin.rankingSystems.id"), width: 90 },
    {
      name: "position",
      label: t("admin.rankingSystems.position"),
      textProps: { uppercase: true },
    },
    {
      name: "points",
      label: t("admin.rankingSystems.points"),
      textProps: { uppercase: true },
    },
    { name: "actions", label: "", render: actions, width: 90, hidden: readOnly },
  ];

  return (
    <>
      <View flexDirection="row">
        <Button
          label={t("admin.rankingSystems.create")}
          onPress={() => setDetailToEdit({ name: t("admin.rankingSystems.new") })}
          disabled={readOnly}
        />
      </View>
      <Table headers={headers} data={data} />

      <Modal

        title={`${t("admin.rankingSystems.position")} ${detailToEdit?.position}`}
        visible={detailToEdit}
        onClose={() => {
          form.current = {};
          setErrors({});
          setDetailToEdit(null);
        }}
      >
        <Form>
          <Row >
            <Input
              label={t("admin.rankingSystems.position")}
              defaultValue={form.current.position}
              onChange={(value) => (form.current.position = value)}
              containerStyle={styles.input}
              error={errors.position}
              numeric
              required
            />
          </Row>
          <Row>
            <Input
              label={t("admin.rankingSystems.points")}
              defaultValue={form.current.points}
              onChange={(value) => (form.current.points = value)}
              containerStyle={styles.input}
              error={errors.points}
              numeric
              required
            />
          </Row>
          <Row>
            {form.current.id ? (
              <Button label={t("global.save")} onPress={updateDetail} />
            ) : (
              <Button label={t("global.create")} onPress={createDetail} />
            )}
          </Row>
        </Form>
      </Modal>

      <ModalConfirm
        visible={detailToDelete}
        text={t("admin.rankingSystems.deleteQuestion")}
        onClose={() => setDetailToDelete(null)}
        onSuccess={deleteDetail}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginRight: 5,
  },
  lastInput: {
    flex: 1,
    marginRight: 0,
  },
});
