import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { ViewProps } from "react-native";
import { IRadio, RadioGroup } from "src/components/RadioGroup";
import { View } from "src/components/View";

interface ITeamCreationQuestion {
  data: {
    type: string;
    text: string;
    values?: string;
  };
  value?: string;
  defaultValue?: string;
  onChange?: (value: any) => void;
  containerStyle?: ViewProps['style'];
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

export const TeamCreationQuestion = ({ data, value, defaultValue, onChange, required, containerStyle, error, disabled = false }: ITeamCreationQuestion) => {

  if (data.type === 'boolean') {
    const items: IRadio[] = [
      { label: t('global.yes'), value: "true" },
      { label: t('global.no'), value: "false" },
    ];

    return (
      <RadioGroup
        label={data.text}
        items={items}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        containerStyle={containerStyle}
        required={required}
        error={error}
        disabled={disabled}
      />
      // <Select label={data.text} items={items} defaultValue={defaultValue} onChange={onChange} containerStyle={containerStyle} />

      // <View flexDirection="row">
      //   <Checkbox containerStyle={{ marginTop: -1 }} defaultValue={value === "true"} onChange={onChange} />
      //   <Text fontSize={14}>{data.text}</Text>
      // </View>
    );
  }

  return <View />;
}