import * as React from "react";
import { ViewProps } from "react-native";
import { StyleSheet } from "react-native";
import { View } from "./View";

interface IForm {
  children: any;
  containerStyle?: ViewProps['style'];
}

export const Form = ({ children, containerStyle }: IForm) => {
  children = children instanceof Array ? children : [children]
  
  return <View style={[styles.form, containerStyle]}>{children?.map((c, i)=><View key={i} style={{zIndex: children?.length - i, width: "100%"}}>{c}</View>)}</View>;
};

const styles = StyleSheet.create({
  form: {
    alignItems: "center",
  },
});
