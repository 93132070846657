import { ROLE_PLAYER } from "src/pages/auth/components/config";

/**
 * 
 * @param licence 
 * @param amount 
 * @param rate 
 * @param licences 
 * @returns 
 */
export const isBonus = (licence: any, amount: number, licences: any[]) => {
  if(!amount || !licence || !licences) {
    return false;
  }

  if (licence.roleId !== ROLE_PLAYER && amount > 0) {
    const filtered = licences.filter((object: any) => object.roleId !== ROLE_PLAYER && !!!object.deleted);
    const index = filtered.findIndex((object: any) => object._id === licence?._id);

    if (filtered.length < amount || (index >= 0 && index < amount)) {
      return true;
    }
  }

  return false;
}

/**
 * 
 * @param licence 
 * @param amount 
 * @param rate 
 * @param licences 
 * @returns 
 */
export const getPrice = (licence: any, amount: number, rate: number, licences: any[]) => {
  // deleted doesn't count
if (!!licence.deleted) {
  return 0;
}

  // final price
  if (![undefined, null, ''].includes(licence?.finalPrice)) {
    return licence.finalPrice;
  }

  // player default rate value
  if (licence?.roleId === ROLE_PLAYER) {
    return licence?.rateAmount;
  }

  // bonus non-player value
  if (isBonus(licence, amount, licences)) {
    return rate;
  }

  // default rate value
  return licence?.rateAmount || 0.0;
}

/**
 * 
 * @param a 
 * @param b 
 * @returns 
 */
export const sort = (a: any, b: any) => {
  return ((a.roleId === ROLE_PLAYER || b.roleId === ROLE_PLAYER) && a.roleId - b.roleId)
    || (a.number || 0) - (b.number || 0)
    || a._id - b._id
    || a.peopleName?.toLowerCase().localeCompare(b.peopleName?.toLowerCase())
    || 0
    ;
}