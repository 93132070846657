import { BrowserRouter } from 'react-router-dom';
import { AlertManager } from 'src/components/AlertManager';
import { OWCOpenDmrProvider } from 'src/context/OWCOpenDmrProvider';
import './App.css';
import { Routes } from './routes';

const App = () => {
  return (
    <BrowserRouter>
      <OWCOpenDmrProvider>
        <Routes />
        <AlertManager />
      </OWCOpenDmrProvider>
    </BrowserRouter>
  );
}

export default App;
