import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface Invitation {
    id?: any;
    email: any;
    sentAt: any;
    sender?: any;
    eventCategory: any;
}

const BASE_URL = BACKEND_URL + '/auth/invitation'

/**
 * 
 Devuelve un evento con todas las categorias de evento 
 */
export const massSend = (invitations: Invitation[]) => {
    return RestService.post(`${BASE_URL}`, invitations);
}