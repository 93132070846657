import * as React from 'react';
import { Icon } from "../Icon";
import { View, Animated } from "react-native"; // Importar Animated de react-native
import { SidebarItem } from './SidebarItem';
import { ISidebar } from './types';

export const Sidebar = ({ elements, sidebarBackground, backgroundColor, color, selectedBackgroundColor, selectedColor, style, collapsable=false, exact }: ISidebar) => {
    const [collapsed, setCollapsed] = React.useState<boolean>(false);
    const [sidebarWidth] = React.useState(new Animated.Value(200)); // Ancho inicial del menú lateral

    // Efecto para animar el cambio en la anchura del menú lateral
    React.useEffect(() => {
        if(!collapsable) return;
        Animated.timing(
            sidebarWidth,
            {
                toValue: collapsed ? 66 : 200, // Ancho final del menú lateral (colapsado o expandido)
                duration: 250, // Duración de la animación en milisegundos
                useNativeDriver: false, // Necesario para usar layout animations en web
            }
        ).start();
    }, [sidebarWidth, collapsed]);

    /**
     * render
     */
    return (
        <Animated.View  style={[{ padding: 8, gap: 5, backgroundColor: sidebarBackground, overflow: 'hidden', width: collapsable && sidebarWidth }, style]} >
            {collapsable &&
                <View >
                    <Icon name={"burger"}  style={{ paddingLeft: 10, marginBottom: 44, alignItems: 'start'}} color={color} onPress={() => { setCollapsed(!collapsed) }} />
                </View>
            }
            {elements.map((item, idx) => <SidebarItem
                key={idx}
                {...item}
                backgroundColor={backgroundColor}
                color={color}
                label={collapsed ? '' : item.label}
                title={item.label}
                selectedBackgroundColor={selectedBackgroundColor}
                selectedColor={selectedColor}
                exact={exact}
            />)}
        </Animated.View>
    );
}
