import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'src/components/Loader';
import { Tabs } from "src/components/Tabs";
import { Title } from 'src/components/Title';
import { View } from "src/components/View";
import { useAlerts } from 'src/hooks/useAlerts';
import { useEffectChange } from 'src/hooks/useEffectChange';
import * as TeamService from 'src/services/TeamService';
import * as EventService from 'src/services/EventService';
import * as RegisterQuestionService from 'src/services/RegisterQuestionService';
import * as GroupService from 'src/services/GroupService';
import { EventContext } from '../EventContext';
import { EventCategoryDetail } from "./EventCategoryDetail";
import { EventCategoryTeams } from './EventCategoryTeams';
import { EventCategoryGroups } from './EventCategoryGroups';
import { EventCategoryRates } from './EventCategoryRates';
import { FloatingIconButton } from 'src/components/FloatingIconButton';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { EventCategoryTeamLevels } from './EventCategoryTeamLevels';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { EventCategorySettings } from './EventCategorySettings';
import { MODALITY_5x5 } from 'src/config';
import { EventCategoryQuestions } from './EventCategoryQuestions';

export const EventCategory = () => {
  const { eventcategoryId } = useParams();

  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { data: event, setData: setEvent } = React.useContext(EventContext);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>({});
  const [teams, setTeams] = React.useState<any[]>([]);
  const [groups, setGroups] = React.useState<any[]>([]);
  const [questions, setQuestions] = React.useState<any[]>([]);
  const [deleteEventCategoryModal, setDeleteEventCategoryModal] = React.useState(false);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const tab = React.useRef(0);
  const isModality5x5 = event.modalityId === MODALITY_5x5;

  /**
   * component did mount
   */
  useEffectChange(async () => {
    if (!eventcategoryId) {
      return navigate('/organizer/competitions');
    }

    setLoading(true);
    if (Number.parseInt(eventcategoryId) !== 0) {
      try {
        const [teams, questions, groups] = await Promise.all([
          TeamService.findTeamsByEvent(event.uuid, parseInt(eventcategoryId)),
          RegisterQuestionService.getByCategoryIdAndType(parseInt(eventcategoryId), 2), //El 2 es magico -- Pol - 10-01-2023
          GroupService.getByCategoryId(parseInt(eventcategoryId)),
        ]);

        setData(event.eventCategories.find((eventCategory: any) => eventCategory.id === parseInt(eventcategoryId)));
        setTeams(teams.data.filter(team => !team.isFicticious));
        setQuestions(questions.data);
        setGroups(groups.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
    
    setLoading(false);
  }, [eventcategoryId]);

  /**
   * on delete
   */
  const onDeleteEventCategoryTeams = React.useCallback((teamToDelete: any) => {
    setTeams(teams.filter((team: any) => team.id !== teamToDelete.id));
  }, [teams]);

  const onAdd = React.useCallback(async () => {
    setLoading(true)
    const response = await TeamService.findTeamsByEvent(event.uuid, parseInt(eventcategoryId))
    setTeams(response.data.filter(team => !team.isFicticious));
    setLoading(false)
  }, [event, eventcategoryId]);

  const onDeleteEventCategoryGroups = React.useCallback((uuid: string) => {
    setGroups(groups.filter((group: any) => group.uuid !== uuid));
  }, [groups]);

  const onCreateEventCategoryGroups = React.useCallback((group: any) => {
    setGroups([...groups, group]);
  }, [groups]);

  /**
   * delete event categoryc
   */
  const createEventCategory = React.useCallback(async (object: any) => {
    try {
      const response = await EventService.createEventCategory(object);
      alerts.create({ text: t('admin.events.category.createConfirmation'), type: 'success' });
      setEvent({ ...event, eventCategories: [...event.eventCategories, response.data] });
      navigate(`/organizer/events/${event.uuid}/categories/${response.data.id}`)
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [event, alerts, navigate, setEvent]);

  const deleteEventCategory = React.useCallback(async (eventId: number, eventCategoryId: number) => {
    try {
      await EventService.deleteEventCategory(eventId, eventCategoryId);
      // const ec = event.eventCategories.filter((eventCategory) => eventCategory.id !== eventCategoryId)
      setDeleteEventCategoryModal(false);
      setEvent({ ...event, eventCategories: [...event.eventCategories.filter((eventCategory) => eventCategory.id !== eventCategoryId)] });
      navigate(`/organizer/events/${event.uuid}`)
      alerts.create({ text: t('admin.events.category.deleteConfirmation'), type: 'success' });

    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [event, alerts, navigate, setEvent]);

  const updateTeams = React.useCallback((teams: any[]) => {
    setTeams(teams);
  }, []);

  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers = [
    t('admin.events.detail'),
    t('admin.events.teams'),
    t('admin.events.groups'),
    t('admin.events.rates.tab'),
    t('admin.events.questions'),
    ...(isModality5x5 ? [t('admin.events.teamLevel.tab'), t('admin.events.settings.tab')] : []),
  ];

  return (
    <View>
      {Number.parseInt(eventcategoryId) > 0 ? (
        <React.Fragment>
          <View flexDirection='row'>
            <Title text={data.name} containerStyle={{ flex: 1 }} />
            {!readOnly && <FloatingIconButton backgroundColor='transparent' color='black' size="medium" name='trash' title={t('global.delete')} onPress={() => setDeleteEventCategoryModal(true)} containerStyle={{ position: 'relative', paddingHorizontal: '0.10em' }} />}
          </View>

          <Tabs headers={headers} initialTab={tab.current} onChangeTab={(index: number) => tab.current = index}>
            <EventCategoryDetail data={data} />
            <EventCategoryTeams updateTeams={updateTeams} eventCategory={data} data={teams} questions={questions} onDelete={onDeleteEventCategoryTeams} onAdd={onAdd} />
            <EventCategoryGroups data={groups} eventCategoryId={Number.parseInt(eventcategoryId)} teams={teams} onDelete={onDeleteEventCategoryGroups} onCreate={onCreateEventCategoryGroups} />
            <EventCategoryRates data={data}></EventCategoryRates>
            <EventCategoryQuestions data={data} />
            {isModality5x5 && <EventCategoryTeamLevels data={data}></EventCategoryTeamLevels>}
            {isModality5x5 && <EventCategorySettings data={data}></EventCategorySettings>}
          </Tabs>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <View flexDirection='row'>
            <Title text={t('admin.events.category.create')} containerStyle={{ flex: 1, marginBottom: 0 }} />
          </View>

          <EventCategoryDetail onCreate={createEventCategory} data={{}} />
        </React.Fragment>
      )}

      {!!deleteEventCategoryModal && <ModalConfirm visible={true} text={t('admin.events.category.deleteQuestion')} onSuccess={() => deleteEventCategory(event.id, Number.parseInt(eventcategoryId))} onClose={() => setDeleteEventCategoryModal(false)} />}
    </View >
  );
}