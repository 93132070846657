import * as React from "react";
import { t } from "owc-react-lib-common";

import { useAlerts } from "src/hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "src/components/Container";
import { View } from "src/components/View";
import { Title } from "src/components/Title";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { FacilityContext } from "./FacilityContext";
import { useEffectChange } from "src/hooks/useEffectChange";
import { Loader } from "src/components/Loader";
import { FacilityDetail } from "./FacilityDetail";
import * as FacilityService from "src/services/FacilityService";
import { ModalConfirm } from "src/components/ModalConfirm";
import { FacilityCourtList } from "./FacilityCourts";
import { Tabs } from "src/components/Tabs";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { ButtonLink } from "src/components/ButtonLink";
import { useColors } from "src/hooks/useColors";

export const Facility = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const facilityUuid = useParams().uuid;
  const colors = useColors('primary');
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [deleteFacilityModal, setDeleteFacilityModal] = React.useState<boolean>(false);
  const tab = React.useRef(0);

  useEffectChange(async () => {
    setLoading(true);
    if (facilityUuid) {
      try {
        const response = await FacilityService.getFacilityByUuid(facilityUuid);
        setData(response.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
    setLoading(false);
  }, [facilityUuid]);

  const deleteFacility = React.useCallback(async (facilityId) => {
    if (facilityId) {
      try {
        await FacilityService.deleteFacility(facilityId);
        alerts.create({ text: t("admin.facilities.deleteConfirmation"), type: "success", });
        navigate(`/organizer/facilities`);
      } catch (error) {
        alerts.createDSMError(error);
      }
      setDeleteFacilityModal(false);
    }
  }, [alerts, navigate]);

  if (loading) {
    return <Loader />;
  }
  const headers = [t("admin.facilities.detail"), t("admin.facilities.court")];

  return (
    <FacilityContext.Provider value={{ data }}>
      <Container title={t("admin.facilities.title")}>
        <View flexDirection="row" alignItems="center">
          {data.id ? (
            <Title text={data.name} containerStyle={{ flex: 1 }} />
          ) : (
            <Title text={t("admin.facilities.new")} containerStyle={{ flex: 1 }} />
          )}
          <ButtonLink
            type='link'
            color={colors.main}
            to={`/organizer/facilities`}
            label={`« ${t("admin.facilities.title")}`}
            contentStyle={{ right: 15 }}
          />
          {facilityUuid && !readOnly && <FloatingIconButton
            name="trash"
            title={t("global.delete")}
            color="black"
            backgroundColor="transparent"
            onPress={() => setDeleteFacilityModal(true)}
            containerStyle={{ position: "relative" }}
            right={5}
          />}

        </View>
        {data.id ? (
          <Tabs headers={headers} initialTab={tab.current} onChangeTab={(index: number) => (tab.current = index)}>
            <FacilityDetail />
            <FacilityCourtList />
          </Tabs>
        ) : (
          <FacilityDetail />
        )}
        <ModalConfirm
          visible={deleteFacilityModal}
          text={t("admin.facilities.deleteQuestion")}
          onClose={() => setDeleteFacilityModal(false)}
          onSuccess={() => deleteFacility(data.id)}
        />
      </Container>
    </FacilityContext.Provider>
  );
};
