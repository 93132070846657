import dayjs from "dayjs";
import { t } from "owc-react-lib-common";
import * as React from "react";
import { Image, StyleSheet } from "react-native";
import { ButtonLink } from "src/components/ButtonLink";
import { Container } from "src/components/Container";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { MODALITY_3x3 } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useColors } from "src/hooks/useColors";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { Translation, usePortalTranslations } from "src/hooks/usePortalTranslations";
import * as EventService from "src/services/EventService";

interface ICompetitionList {
  modalityId?: number;
}

export const CompetitionList = ({ modalityId }: ICompetitionList) => {
  const alerts = useAlerts();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const colors = useColors();
	const translate = usePortalTranslations();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await EventService.getVisible(modalityId);
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * actions
   */
  const actions = React.useCallback((object: any) => {
    const today = dayjs(new Date());
    const start = dayjs(object.startInscriptionDate);
    const end = dayjs(object.limitInscriptionDate);

    const startVerification = object.startInscriptionDate ? (today.isAfter(start, 'minute') || start.isSame(today, 'minute')) : true;
    const endVerification = object.limitInscriptionDate ? (today.isBefore(end, 'minute') || end.isSame(today, 'minute')) : true;

    if ((startVerification && endVerification) || object.isInvitation) {
      return (
        <View>
          <ButtonLink
            type='primary'
            label={t("auth.competitions.list.register")}
            to={object.modalityId === MODALITY_3x3 ? `/auth/competitions/3x3/event/${object.uuid}` : `${object.competitionUuid}/event/${object.uuid}`}
            contentStyle={{ minWidth: 0 }}
            justifyContent='center'
          />
        </View>
      );
    } else {
      return (
        <View>
          <Text>{t('auth.competitions.teamCreation.category.enrollmentClosed')}</Text>
        </View>
      )
    }

  }, []);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    {
      name: "logo",
      label: " ",
      render: (object: any) => (
        <Image source={{ uri: object.competitionLogoUrl }} style={[styles.image, { borderColor: colors.main }]} />
      ),
      width: 120,
    },
    { name: "competitionName", label: t("auth.competitions.list.competition"), textProps: { uppercase: true } },
    { name: "name", label: t("auth.competitions.list.event"), textProps: { uppercase: true } },
    {
      name: "beginDate",
      label: t("auth.competitions.list.start"),
      render: (object: any) => <Text>{dayjs(object.beginDate).format("DD/MM/YYYY")}</Text>,
      width: 100,
    },
    {
      name: "endDate",
      label: t("auth.competitions.list.end"),
      render: (object: any) => <Text>{dayjs(object.endDate).format("DD/MM/YYYY")}</Text>,
      width: 100,
    },
    {
      name: "startInscriptionDate",
      label: t("auth.competitions.list.startInscriptionDate"),
      render: (object: any) => (<Text>{object?.startInscriptionDate && dayjs(object?.startInscriptionDate).format("DD/MM/YY HH:mm")}</Text>),
      width: 100,
    },
    {
      name: "endInscriptionDate",
      label: t("auth.competitions.list.endInscriptionDate"),
      render: (object: any) => <Text>{object?.limitInscriptionDate && dayjs(object?.limitInscriptionDate).format("DD/MM/YY HH:mm")}</Text>,
      width: 100,
    },
    {
      name: "actions",
      label: " ",
      render: actions,
      width: 120,
    },
  ];

  return (
    <Container title={t(translate(modalityId === MODALITY_3x3 ?  Translation.THREEONTHREE_COMPETITION_TITLE : Translation.FIVEONFIVE_COMPETITION_TITLE)) }>
      <Table headers={headers} data={data} />
    </Container>
  );
};

const styles = StyleSheet.create({
  image: {
    width: "100%",
    flex: 1,
    resizeMode: "contain",
  },
});
