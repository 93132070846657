import * as React from "react";
import { t } from "owc-react-lib-common";
import * as RankingSystemService from "src/services/RankingSystemService";
import { useAlerts } from "src/hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { RankingSystemContext } from "./RankingSystemContext";
import { Input } from "owc-react-lib-common"
import { Button } from "src/components/Button";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const RankingSystemForm = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { data: rankingSystem } = React.useContext(RankingSystemContext);
  const navigate = useNavigate();
  const alerts = useAlerts();
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const form = React.useRef<any>(rankingSystem);

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  const createRankingSystem = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const response = await RankingSystemService.createRankingSystem(form.current);
      alerts.create({
        text: t("admin.rankingSystems.createConfirmation"),
        type: "success",
      });
      navigate(`/organizer/ranking-systems/${response.data.uuid}`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, navigate, validate]);

  const updateRankingSystem = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const response = await RankingSystemService.updateRankingSystem(form.current);
      alerts.create({
        text: t("admin.rankingSystems.updateConfirmation"),
        type: "success",
      });
      navigate(`/organizer/ranking-systems/${response.data.uuid}`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validate, navigate]);

  return (
    <Form>
      <Row flex={[2, 3]}>
        <Input
          label={t("admin.rankingSystems.name")}
          defaultValue={form.current.name}
          onChange={(value) => form.current.name = value}
          error={errors.name}
          disabled={readOnly}
          required
        />
      </Row>

    
      <Row>
        {rankingSystem.id ? (
          <Button
            label={t("global.save")}
            onPress={updateRankingSystem}
            containerStyle={{ marginBottom: 0 }}
            disabled={readOnly}
          />
        ) : (
          <Button
            label={t("global.create")}
            onPress={createRankingSystem}
            containerStyle={{ marginBottom: 0 }}
            disabled={readOnly}
          />
        )}
      </Row>
    </Form>
  );
};
