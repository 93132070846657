import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface IMatchResultsDTO {
  awaySanction?: boolean,
  awayScore?: number,
  awayStandingsPoints?: number,
  awayTeamId?: number,
  homeSanction?: boolean,
  homeScore?: number,
  homeStandingsPoints?: number,
  homeTeamId?: number,
  id?: number,
  lastReminderDate?: Date,
  matchCallId?: number,
  matchRecordLocalisation?: number,
  resultType?: string
}


const BASE_URL = BACKEND_URL + '/auth/match-results'


  export const addMatchResult = (payload: IMatchResultsDTO) => {
    return RestService.post(`${BASE_URL}`, payload);
  }

  export const deleteMatchResult = (id: number) => {
    return RestService.delete(`${BASE_URL}/${id}`);
  }

  export const updateMatchResult = (payload: IMatchResultsDTO) => {
    return RestService.put(`${BASE_URL}`, payload);
  }
