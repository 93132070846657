export class ColorHelper {

    constructor(matches, teams) {
        matches.map((match) => {
            const homeTeam = teams.find((team) => team.id === match.homeTeamId);
            const awayTeam = teams.find((team) => team.id === match.awayTeamId);
            return {
                awayName: awayTeam?.name,
                awayId: awayTeam?.id,
                categoryName: homeTeam?.eventCategoryName,
                duration: '01:30',
                id: match?.id,
                localName: homeTeam?.name,
                localId: homeTeam?.id,
                roundNumber: match.roundNumber,
                roundId: match.roundId,
                matchGroupId: match?.groupId,
                matchGroupName: match?.groupName,
            }
        })
  

    }
    starterColors: any[] = [
        '#66ff66',
        '#ffff66',
        '#66ffff',
        '#6666ff',
        '#ff99ff',
        '#5599ff',
        '#359920',
        '#ff9955',
        '#999911',
        '#8aa37f',
        '#29bdaf',
        '#98f9b1',
        '#c884fe',
        '#918a01',
        '#e8cd8a',
        '#6b5dce',
        '#f87750',
        '#0ad3cb',
        '#ef91fa',
        '#7c475b',

    ]
    categoryColors: any[] = []
    groupShaders: any[] = []

    getCategoryColor = (categoryKey) => {
        return this.categoryColors.find(cc => cc.key === categoryKey).hex 
    }


    getGroupColorByKey = (categorykey, groupKey) => {
        return this.groupShaders.find(cc => cc.categoryKey === categorykey && cc.matchGroupName === groupKey)?.hex ?? 'red'
    }

    getGroupColorByIndex = (categoryIndex, groupKey) => {
        return this.groupShaders.find(cc => cc.categoryIndex === categoryIndex && cc.matchGroupName === groupKey)?.hex
    }


    lightenDarkenColor = (col, amt) => {
        var usePound = false;
        if(!col) return;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }

    getShader = (color, amount, length = 25) => {
        const res = [];
        const increment = 255 / (amount + length);
        let acc = increment;


        // res.push(this.lightenDarkenColor(color, 20));

        for (let index = 0; index < amount + 1; index++) {
            res.push(this.lightenDarkenColor(color, acc));
            acc += increment;
        }
        res.pop();
        return res;
    }
}