export function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const deepMerge = (target: any, source: any, mergeNulls = true) => {
  let output = { ...target };

  Object.keys(source).forEach(key => {
    if (key in output && !!output[key] === !!mergeNulls) {
      if (isObject(output[key])) {
        output[key] = deepMerge(output[key], source[key]);
      }
    }
    else {
      output[key] = source[key];
    }
  });

  return output;
}