import { t } from 'owc-react-lib-common';

import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { View } from "src/components/View";
import * as React from 'react';
import * as TeamActorService from 'src/services/TeamActorService';
import { useAlerts } from 'src/hooks/useAlerts';

interface IStep04 {
  data2: any;
  onSuccess: () => void;
};

export const Step04 = ({ data2, onSuccess }: IStep04) => {

  const alerts = useAlerts();

  /**
   * restart 
   */
  const save = React.useCallback(async () => {
    try {
      await TeamActorService.restart(data2)
      /**
      * update data
      */
      onSuccess();

    } catch (error) {
      alerts.createDSMError(error);
    } finally {
    }
  }, [alerts, data2, onSuccess]);


  return (
    <View>
      <Alert text={t('public.actorRegistration.step04.rejected')} type="error" />
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button label={t('public.actorRegistration.step04.restart')} onPress={() => save()}></Button>
      </View>
    </View>
  );
}