import * as React from 'react';
import { Loader as OWCLoader } from "owc-react-lib-common";
import { StyleSheet } from "react-native";
import { View } from "./View";

export const Loader = ({ color, size, margin }: any) => {
  return (
    <View style={styles.container}>
      <OWCLoader size={size} color={color} /*margin={margin}*/ />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  }
});