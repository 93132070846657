import { BACKEND_URL } from "../config";
import * as RestService from "./RestService";

export interface ISeasonDTO {
  readonly id?: number;
  readonly uuid?: string;
  externalUuid?: string;
  name?: string;
  beginDate?: any;
  endDate?: any;
  active?: boolean;
  organizerId?: number;
  organizerName?: string;
}

const BASE_URL = BACKEND_URL + "/auth/seasons";

/**
 * @returns Devuelve una temporada por su uuid
 */
export const findByUuid = (seasonUuid: string) => {
  return RestService.get(`${BASE_URL}/${seasonUuid}`);
};

/**
 * @returns Devuelve una temporada por su id
 */
export const findById = (seasonId: string) => {
  return RestService.get(`${BASE_URL}/by-id/${seasonId}`);
};


/**
 * @returns Devuelve todas las temporadas de un organizador
 */
export const findSeasons = () => {
  return RestService.get(`${BASE_URL}`);
};


/**
 * @returns Devuelve la temporada activa de un organizador
 */
export const findActive = () => {
  return RestService.get(`${BASE_URL}/active`);
};


/**
 * @returns Crea una temporada
 */
export const createSeason = (season: ISeasonDTO) => {
  return RestService.post(BASE_URL, season);
};

/**
 * @returns Modifica una temporada
 */

export const updateSeason = (seasonDTO: ISeasonDTO) => {
  return RestService.put(BASE_URL, seasonDTO);
}

/**
 * @returns Elimina una temporada
 */
export const deleteSeason = (seasonId: number) => {
  return RestService.delete(`${BASE_URL}/${seasonId}`);
}