import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { ImageBackground, StyleSheet, ViewProps } from "react-native";
import { styling } from 'src/config';
import { useColors } from 'src/hooks/useColors';
import { FloatingIconButton } from "./FloatingIconButton";
import { Text } from './Text';
import { View } from './View';

interface IImageUploader {
  label: string;
  onChange: (data: any) => void;
  containerStyle?: ViewProps['style'];
  extensions?: string[];
  maxSize?: number;
  placeholder?: any;
  error?: string;
  defaultValue?: string;
  reloadButton?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export interface IImageUploaderData {
  base64: string;
  name: string;
  extension: string;
  size: number;
  result: string;
}

export const ImageUploader = ({ label, onChange, error, extensions = ['png', 'jpg', 'jpeg', 'gif'], maxSize = 1024 * 1024 * 10, defaultValue, reloadButton = false, placeholder = 'https://dallas.findsportsusa.com/wp-content/uploads/2021/02/Basketball.png', containerStyle, required, disabled = false }: IImageUploader) => {
  const errorColors = useColors('error');
  const input = React.useRef<any>();

  const [file, setFile] = React.useState<any>();
  const [internalError, setInternalError] = React.useState<string>();

  /**
   * on press
   */
  const addImage = React.useCallback(() => {
    input.current.click();
  }, []);

  // TODO: NO FUNCIONA CORRECTAMENT
  const reloadImage = React.useCallback(() => {
    setFile(null);
  }, [setFile]);

  /**
   * handle input click
   */
  const handleInputClick = React.useCallback((event: any) => {
    const _file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const name = _file.name;
      const extension = name.split('.').pop();
      const result = reader.result as string;
      const base64 = result.substring(result.indexOf(',') + 1);
      const size = _file.size;

      let internalError = undefined;

      let object: IImageUploaderData | undefined = {
        result,
        base64,
        name,
        extension,
        size
      };

      if (extensions.length && !extensions.includes(extension?.toLowerCase())) {
        internalError = t('errors.extension')
        object = undefined;
      }
      else if (!!maxSize && size > maxSize) {
        internalError = t('errors.size') + ' (' + maxSize / 1024 / 1024 + 'Mb)'
        object = undefined;
      }

      setInternalError(internalError);
      setFile(object);
      onChange(object);
    }

    reader.onerror = (err: any) => {
      setFile(null);
      throw err;
    }

    reader.readAsDataURL(_file);
  }, [onChange, extensions, maxSize]);

  /**
   * render
   */
  const finalLabel = !label ? '' : `${label} ${required !== undefined ? (required ? '*' : `(${t('global.optional').toLowerCase()})`) : ''}`;
  const finalError = internalError || error;

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.imageContainer, !!finalError && { borderColor: errorColors.main }]}>
        <ImageBackground source={{ uri: file?.result || defaultValue || placeholder }} style={styles.image} resizeMode='cover' />
        {!disabled && <FloatingIconButton iconPadding={3} name='add' onPress={addImage} bottom={5} right={5} />}
        {!disabled && reloadButton && <FloatingIconButton iconPadding={3} name='backtime' onPress={reloadImage} bottom={40} right={5} />}
      </View>

      <Text style={[!!finalError && { color: errorColors.main }]}>
        {!!finalError && finalError}
      </Text>

      {!disabled && (
        <input
          ref={input}
          type='file'
          onChange={handleInputClick}
          accept={extensions.map((extension: string) => `.${extension}`).join(',')}
          style={{ display: 'none' }}
        />
      )}

      <Text style={[styles.label, !!finalError && { color: errorColors.main }]}>{finalLabel}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginBottom: styling.spacing,
    width: 150,
  },
  imageContainer: {
    borderWidth: 1,
    borderColor: '#c4c4c4',
    borderRadius: 4,
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 150,
    height: 150,
  },
  label: {
    position: 'absolute',
    top: -8,
    left: 8,
    fontSize: 13,
    backgroundColor: 'white',
    paddingHorizontal: 5,
    pointerEvents: 'none',
  },
});