import { t } from 'owc-react-lib-common';
import { SETTING_TYPE_BIRTHDATE, SETTING_TYPE_DOCUMENTATION, SETTING_TYPE_EMAIL, SETTING_TYPE_GENDER, SETTING_TYPE_NAME, SETTING_TYPE_NUMBER, SETTING_TYPE_OBSERVATION, SETTING_TYPE_PHONE, SETTING_TYPE_RATE, SETTING_TYPE_SHIRTSIZE, SETTING_TYPE_SURNAME } from "src/pages/auth/components/config";

export const getSettingName = (typeId: number) => {
  switch (typeId) {
    case SETTING_TYPE_NAME:
      return t("auth.competitions.teamCreation.actors.name");
    case SETTING_TYPE_SURNAME:
      return t("auth.competitions.teamCreation.actors.surname");
    case SETTING_TYPE_GENDER:
      return t("auth.competitions.teamCreation.actors.gender");
    case SETTING_TYPE_BIRTHDATE:
      return t("auth.competitions.teamCreation.actors.birthDate");
    case SETTING_TYPE_EMAIL:
      return t("auth.competitions.teamCreation.actors.email");
    case SETTING_TYPE_PHONE:
      return t("auth.competitions.teamCreation.actors.phone");
    case SETTING_TYPE_DOCUMENTATION:
      return t("auth.competitions.teamCreation.actors.document");
    case SETTING_TYPE_RATE:
      return t("auth.competitions.teamCreation.actors.rate");
    case SETTING_TYPE_NUMBER:
      return t("auth.competitions.teamCreation.actors.number");
    case SETTING_TYPE_SHIRTSIZE:
      return t("auth.competitions.teamCreation.actors.size");
    case SETTING_TYPE_OBSERVATION:
      return t("auth.competitions.teamCreation.actors.observations");
  }
}

