import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


const PUBLIC_BASE_URL = BACKEND_URL + '/public/health'

export interface IHealth {
  db_version: string;
  response_date?: Date;
  version?: string;
  status?: string;
  start_date?: Date;
}

export const getHealth = (): Promise<IHealth> => {
  return RestService.getTyped(`${PUBLIC_BASE_URL}`);
}
