export const REGISTER_STATUS_CREATED = 0;
export const REGISTER_STATUS_PERSONAL_DATA = 5;
export const REGISTER_STATUS_PENDING_QUESTIONS = 10;
export const REGISTER_STATUS_PENDING_TEXT_SIGNATURE = 20;
//export const REGISTER_STATUS_TEXT_SIGNED = 30;
export const REGISTER_STATUS_TEXT_SIGNATURE_DECLINED = 40;
export const REGISTER_STATUS_PENDING_TEXT_ACCEPTANCE = 50;
//export const REGISTER_STATUS_TEXT_ACCEPTED = 60;
export const REGISTER_STATUS_PENDING_PAYMENT = 70;
export const REGISTER_STATUS_PAYMENT_REJECTED = 80;
export const REGISTER_STATUS_COMPLETED = 90;
export const REGISTER_STATUS_COMPLETED_API = 100;