import { View } from './View'
import { Text } from './Text'
import { minifyTeamName } from 'src/utils/StringUtils'

export const MatchCard = ({ localName = '', awayName = '', categoryName = '', matchGroupId = '', time = '', color = undefined }) => {
    return (
        <>
            <View flexDirection='row' alignItems='center' justifyContent='space-between' style={[{ zIndex: 99, position: 'absolute', top: -8, right: -8, width: '100%' }]}>
                {/* <Text style={{ paddingTop: 10 }} color={color}>{time}</Text> */}
                {/* <Icon style={{paddingTop: 10}} fontSize={18} name='help' onPress={() => { alert(`${localName} - ${awayName} \n${categoryName}`) }}></Icon> */}
            </View>
            <View flexDirection='column' alignItems='center' justifyContent='space-around'>
                <View flexDirection='row' alignItems='center' justifyContent='space-around' style={{ padding: 2, width: '100%' }}>
                    <View>
                        <Text bold color={color}>{minifyTeamName(localName).toLocaleUpperCase()}</Text>
                    </View>
                    <Text>
                        <Text color={color}>-</Text>
                    </Text>
                    <View>
                        <Text bold color={color}>{minifyTeamName(awayName).toLocaleUpperCase()}</Text>
                    </View>
                </View>
                <View style={[{ padding: 3 }]}>
                    <Text bold textAlign='center' color={color}>{categoryName}</Text>
                </View>
            </View>
        </>


    )

}

