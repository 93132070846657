import * as React from 'react';
import { Alert as OWCAlert } from 'owc-react-lib-common';
import { StyleSheet, ViewProps } from 'react-native';
import { styling } from 'src/config';
import { View } from './View';

export interface IAlert {
  id?: number | string;
  text: string;
  title?: string;
  type?: any; // success | info | warning | error
  onClose?: () => void;
  containerStyle?: ViewProps['style'];
  minHeight?: number;
};

export const Alert = ({ type = 'error', text, onClose, containerStyle }: any) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <OWCAlert text={text} onClose={onClose} containerStyle={containerStyle} type={type} ></OWCAlert>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: styling.spacing,
  },
});