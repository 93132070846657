import { Input, t } from "owc-react-lib-common";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Form } from "src/components/Form";
import { IconButton } from "src/components/IconButton";
import { Modal } from "src/components/Modal";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Row } from "src/components/Row/Row";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { ORGANIZER_READ_ONLY, OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useUserPermission } from "src/hooks/useUserPermission";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as GroupService from "src/services/GroupService";

interface IGroups {
  data: any[];
  eventCategoryId: number;
  teams: any[];
  onCreate: (object: any) => void;
  onDelete: (uuid: string) => void;
}

export const Groups = ({ data, eventCategoryId, onCreate, onDelete }: IGroups) => {
  const context = React.useContext(OWCOpenDmrContext);
  const form = React.useRef<any>({ eventCategoryId: eventCategoryId, standingVisible: true });
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [groups, setGroups] = React.useState<any[]>(data);
  const [createGroupModal, setCreateGroupModal] = React.useState(false);
  const [deleteGroupModal, setDeleteGroupModal] = React.useState(false);
  const [groupToDelete, setGroupToDelete] = React.useState();
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const navigate = useNavigate();
  const alerts = useAlerts();

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED }
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  const deleteGroup = React.useCallback(
    async (object) => {
      try {
        setDeleteGroupModal(false);
        await GroupService.deleteGroup(object.uuid);
        alerts.create({ text: t("admin.events.group.deleteConfirmation"), type: "success" });
        onDelete(object.uuid);
        const filteredGroups = groups.filter(g => g.uuid !== object.uuid);
        filteredGroups.forEach((g,i) => {
          g.groupOrder = i +1;
        })
        setGroups(filteredGroups);

        setGroupToDelete(null);
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [onDelete, alerts]
  );

  const createGroup = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const newGroup = await GroupService.createGroup(form.current);
      alerts.create({ text: t("admin.events.group.createConfirmation"), type: "success" });
      setCreateGroupModal(false);
      setGroups([...groups, newGroup.data])
      onCreate(newGroup.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [onCreate, alerts, validate]);

  /**
   * actions
   */
  const actions = React.useCallback((object: any) => {
    const groupUuid = object.uuid;
    return (
      <View flexDirection="row" alignItems="center">
        <IconButton
          backgroundColor={'transparent'} color={'black'}
          name="edit"
          title={t("global.edit")}
          onPress={() => {
            navigate(`groups/${groupUuid}`);
          }}
        />
        <IconButton
          backgroundColor={'transparent'} color={'black'}
          name="trash"
          title={t("global.delete")}
          onPress={() => {
            setGroupToDelete(groupUuid);
            setDeleteGroupModal(true);
            setGroupToDelete({ ...object });
          }}
        />
      </View>
    );
  }, [navigate]);

  const updateGroup = async (group) => {
    await GroupService.updateGroup(group);
  }

  const incrementOrder = async (group) => {
    if (group.groupOrder === 1) {
      alerts.createDSMError(t('global.error'));
      return;
    }
    // let newGroup = null;
    const map = groups.map((g) => {
      if (group.id === g.id) {
        // newGroup = { ...g, "groupOrder": g.groupOrder - 1 };
        return { ...g, "groupOrder": g.groupOrder - 1 }
      }
      if(group.groupOrder - 1 === g.groupOrder){
        return { ...g, "groupOrder": g.groupOrder + 1 }
      }
      return g;
    })
    try{
      await updateGroup(group);
      setGroups(map);
      alerts.create({ text: t('global.success'), type: 'success' });
    }catch(error){
      alerts.createDSMError(error)
    }
  }

  const decrementOrder = async (group) => {
    if (group.groupOrder === groups.length) {
      alerts.createDSMError(t('global.error'));
      return;
    }
    let newGroup = null;
    const map = groups.map((g) => {
      if (group.id === g.id) {
        newGroup = { ...g, "groupOrder": g.groupOrder + 1 };
        return { ...g, "groupOrder": g.groupOrder + 1 }
      }
      if(group.groupOrder + 1 === g.groupOrder){
        return { ...g, "groupOrder": g.groupOrder - 1 }
      }
      return g;
    })
    try{
      await updateGroup(newGroup);
      setGroups(map);
      alerts.create({ text: t('global.success'), type: 'success' });
    }catch(error){
      alerts.createDSMError(error)
    }
  }

  const headers: ITableHeader[] = [
    {
      name: "groupOrder",
      label: t("admin.events.groupOrder"),
      render: (o) => {
        return (
          <View flexDirection="row" style={{ gap: 2, alignItems: 'center' }}>
            <IconButton fontSize={14} backgroundColor={'transparent'} color={context.configuration.colors.primary.main} name={"arrow-up-2"} onPress={() => { incrementOrder(o) }} />
            <Text marginTop={0} style={{ backgroundColor: context.configuration.colors.primary.main, color: 'white', borderRadius: 100, width: 25, height: 25, textAlign: 'center', padding: 3 }} >{o?.groupOrder}</Text>
            <IconButton fontSize={14} backgroundColor={'transparent'} color={context.configuration.colors.primary.main} name={"arrow-down-2"} onPress={() => { decrementOrder(o) }} />
          </View>
        )
      },
      width: 100
    },
    {
      name: "name",
      label: t("admin.events.groups"),
      textProps: { uppercase: true },
    },
    { name: "actions", label: '', render: actions, width: 100, hidden: readOnly },
  ];

  return (
    <View>
      <Button
        label={t("admin.events.group.create")}
        onPress={() => setCreateGroupModal(true)}
        disabled={readOnly}
      />

      <Table defaultSort={"groupOrder"} defaultSize={25} headers={headers} data={groups} />
      <Modal visible={createGroupModal} title={t("admin.events.group.create")} onClose={() => setCreateGroupModal(false)} width={550}>
        <Form>
          <Row>
            <Input label={t("admin.events.group.name")} onChange={(val) => (form.current.name = val)} error={errors.name} required />
          </Row>
          <Row >
            <Button label={t("global.create")} onPress={createGroup} />
          </Row>
        </Form>
      </Modal>
      <ModalConfirm
        visible={deleteGroupModal}
        text={t("admin.events.group.deleteQuestion")}
        onSuccess={() => deleteGroup(groupToDelete)}
        onClose={() => setDeleteGroupModal(false)}
      />
    </View>
  );
};