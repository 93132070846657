import * as React from 'react';
import { StyleSheet, ViewProps } from "react-native";
import { styling } from "src/config";
import { useColors } from "src/hooks/useColors";
import { Text } from "./Text";
import { View } from "./View";

interface ITitle {
  text: string | undefined;
  right?: string;
  containerStyle?: ViewProps['style'];
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'default';
}

export const Title = ({ text, right, containerStyle, size = 'medium' }: ITitle) => {
  const colors = useColors('primary');

  return (
    <View style={[styles.container, { borderColor: colors.main }, containerStyle]}>
      <Text color={colors.main} bold uppercase style={[styles[size], styles.text]}>{text}</Text>
      <Text color={colors.main} bold uppercase style={styles.right}>{right}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // borderBottomWidth: 1,
    marginBottom: styling.spacing,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flex: 1,
  },
  right: {
  },
  xsmall: {
    fontSize: 12,
    lineHeight: 8,
  },
  small: {
    fontSize: 20,
    lineHeight: 16,
  },
  medium: {
    fontSize: 28,
    lineHeight: 28,
  },
  large: {
    fontSize: 30,
    lineHeight: 30,
  },
  xlarge: {
    fontSize: 32,
    lineHeight: 32,
  },
  default: {

  }
});