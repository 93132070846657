import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface ITeamActorDTO {
  id?: number;
  uuid?: string;
  number?: number;
  rateId?: number;
  finalPrice?: number;
  roleId?: number;
  teamId?: number;
  teamUuid?: string;
  teamName?: string;
  peopleId?: number;
  peopleName?: string;
  peopleUuid?: string;
  peopleEmail?: string;
  peopleIdDocType?: number;
  peopleIdDoc?: string;
  peopleAdress?: string;
  peopleExternalUuid?: string;
  peopleTownId?: number;
  pepoleBirthdate?: any;
  peopleZipCode?: string;
  peoplePhone?: string;
  roleDescription?: string;
  gender?: string;
  deleted?: any;
  observations?: string;
  shirtSize?: string;
  notificationDate?: any;
  registrationStatus?: number;
}

export interface ITeamEventCategoryActorDTO {
  id?: number;
  uuid?: string;
  teamEventCategoryId?: number;
  teamActorId?: number;
  number?: number;
}


const BASE_URL = BACKEND_URL + '/auth/actors'

/**
 * 
 Devuelve todos los actores de un equipo 3x3 respecto la event category
 */
 export const findByTeamEventCategoryId = (teamUuid: string, teamEventCategoryId: number) => {
  return RestService.get(`${BASE_URL}/in-team-event-category/${teamUuid}/${teamEventCategoryId}`);
}

/**
 * 
 Añade un actor a un team event category 3x3
 */
 export const addTeamEventCategoryActor = (teamEventCategoryId: number, teamActor: ITeamActorDTO) => {
  return RestService.post(`${BASE_URL}/add-team-event-category-actor/${teamEventCategoryId}`,teamActor);
}


export const checkAddActorToTeam = (teamActorId: number, teamId: number, eventCategoryId: number) => {  
  return RestService.get(`${BASE_URL}/${teamActorId}/can-add-team/${teamId}/in-cat/${eventCategoryId}`);
}


/**
 * 
 Quita un actor a un team event category 3x3
 */
 export const removeTeamEventCategoryActor = (teamEventCategoryId: number, teamActorId: number) => {
  return RestService.delete(`${BASE_URL}/remove-team-event-category-actor/${teamEventCategoryId}/${teamActorId}`);
}


/**
 * 
 Devuelve todos los actores de un equipo 5x5
 */
export const findByTeamUuid = (teamUuid: string, getDeleted = false, eventCategoryId?: number) => {
  const queryEventCategory = eventCategoryId === undefined ? '': '&eventCategoryId=' + eventCategoryId;

  return RestService.get(`${BASE_URL}/in-team/${teamUuid}?getDeleted=${getDeleted}${queryEventCategory}`);
}

/**
 * 
 Añade un participante a un equipo
 */
export const addToTeam = (teamActor: ITeamActorDTO, notifyToOrganizer = false) => {
  return RestService.post(`${BASE_URL}?notifyToOrganizer=${notifyToOrganizer}`, teamActor);
}


/**
 * 
 Modifica un participante a un equipo
 */
export const update = (teamActor: ITeamActorDTO) => {
  return RestService.put(`${BASE_URL}`, teamActor);
}

/**
* 
Elimina un participante a un equipo
*/
export const deleteActor = (teamActorUuid: number) => {
  return RestService.delete(`${BASE_URL}/${teamActorUuid}`);
}


/**
* 
Restaura un participante a un equipo
*/
export const restoreActor = (teamActorUuid: number) => {
  return RestService.put(`${BASE_URL}/restore/${teamActorUuid}`);
}

/**
 * 
 */
export const changeRegistrationStatus = (actorUuid: string, registrationStatus: number) => {
  return RestService.put(`${BASE_URL}/${actorUuid}/change-status/${registrationStatus}`);
}