import { Button, t, Text, Title } from "owc-react-lib-common";
import { StyleSheet } from "react-native";
import { View } from "src/components/View";
import { IEventCategoryDTO } from "src/services/EventService";

interface RegisterTeam3x3PaymentRatings {
  eventCategory: IEventCategoryDTO;
  onSave: any;
}

export const RegisterTeam3x3PaymentRatings = ({ eventCategory, onSave }: RegisterTeam3x3PaymentRatings) => {
  if (!eventCategory?.rates) {
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'center', gap: 20 }}>
        <View style={styles.card}>
          <Title text={t('3x3.register.freeRate')} justifyContent='center' uppercase fontWeight='bold' />
          <Text fontSize={28}>0 €</Text>
          <Button text={t('3x3.register.selectRating')} onPress={() => onSave({ amount: 0 })} />
        </View>
      </View>
    )
  }

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center', gap: 20, flexWrap: 'wrap' }}>
      {eventCategory?.rates.map((rate, idx) => {
        return (
          <View id={idx.toString()} style={styles.card}>
            <Title text={rate.name} justifyContent='center' uppercase fontWeight='bold' />
            <Text fontSize={28}>{rate.amount} €</Text>
            {rate.description && <Text justifyContent='center' style={styles.description}>{rate.description}</Text>}
            <Button text={t('3x3.register.selectRating')} onPress={() => onSave(rate)} />
          </View>
        )
      })}
    </View>
  )
}
const styles = StyleSheet.create({
  card: {

    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 400,
    width: '100%',
    borderWidth: 1,
    borderColor: '#eee',
    borderRadius: 5,
    padding: 15,
  },
  description: {
    marginBottom: 25,
  },
});