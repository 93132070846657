import { Icon, t } from "owc-react-lib-common";
import React from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { IconButton } from "src/components/IconButton";
import { Loader } from "src/components/Loader";
import { ModalConfirm } from "src/components/ModalConfirm";
import { ITableHeader, Table } from "src/components/Table";
import { View } from "src/components/View";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useUserPermission } from "src/hooks/useUserPermission";
import * as SeasonService from "src/services/SeasonService";
import { ISeasonDTO } from "src/services/SeasonService";

export const SeasonList = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [seasonToDelete, setSeasonToDelete] = React.useState<ISeasonDTO | null>(null);

  useComponentDidMount(async () => {
    try {
      const response = await SeasonService.findSeasons();
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  });

  const deleteSeason = React.useCallback(async () => {
    try {
      await SeasonService.deleteSeason(seasonToDelete.id);
      alerts.create({ text: t("admin.seasons.list.deleteSuccess"), type: "success", });
      setData(data.filter((season: any) => season.id !== seasonToDelete.id));
    } catch (error) {
      alerts.createDSMError(error);
    }
    setSeasonToDelete(null);
  }, [seasonToDelete, alerts, data]);

  const actions = React.useCallback((season: ISeasonDTO) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="edit"
          onPress={() => navigate(`${season.uuid}`)}
        />
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="trash"
          onPress={() => setSeasonToDelete(season)}
        />
      </View>
    );
  }, [navigate]);


  if (loading) {
    return <Loader />;
  }
  const headers: ITableHeader[] = [
    {
      name: "id",
      label: "ID",
      width: 50
    },
    {
      name: "active",
      label: t("admin.seasons.list.active"),
      width: 80,
      render: (e) => {
        if(e.active){
          return <Icon type={"ok"} /> 
        }
      }
    },
    {
      name: "name",
      label: t("admin.seasons.list.name"),
      width: 300,
      textProps: { uppercase: true },
    },
    {
      name: "formattedBeginDate",
      label: t("admin.seasons.list.beginDate"),
      flex: 1,
    },
    {
      name: "formattedEndDate",
      label: t("admin.seasons.list.endDate"),
      flex: 1,
    },
    {
      name: "actions",
      label: "",
      render: actions,
      width: 100,
      hidden: readOnly
    }

  ];

  return (
    <Container title={t('admin.seasons.list.title')}>
      <View flexDirection="row">
        <Button label={t('admin.seasons.list.createSeason')} onPress={() => navigate("/organizer/seasons/create")} disabled={readOnly} />
      </View>
      <Table
        headers={headers}
        data={data}
      />
      {seasonToDelete && (
        <ModalConfirm
          visible
          text={t('admin.seasons.list.deleteConfirmation', { seasonName: seasonToDelete.name })}
          onClose={() => setSeasonToDelete(null)}
          onSuccess={deleteSeason}
        />
      )}
    </Container>
  );
};
