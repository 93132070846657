import { Button, Input, Separator, t, Table } from "owc-react-lib-common";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { View } from "src/components/View";

interface IRegisterTeam3x3Validation {
  disabled?: boolean;
  eventCategory: any;
  team: any;
  actors: any[];
  onSave?: () => void;
}

export const RegisterTeam3x3Validation = ({ disabled = false, eventCategory, team, actors, onSave }: IRegisterTeam3x3Validation) => {
  return (
    <View flex={1}>
      <Form containerStyle={{ flex: 1 }}>
        <Row flex={[2, 1, 1]}>
          <Input label={t('3x3.teams.competition')} value={eventCategory?.competitionName} disabled />
          <Input label={t('3x3.teams.event')} value={eventCategory?.eventName} disabled />
          <Input label={t('3x3.teams.category')} value={eventCategory?.name} disabled />
        </Row>

        <Row flex={[3, 1]}>
          <Input label={t('3x3.teams.data.name')} value={team?.name} disabled />
          <Input label={t('3x3.teams.data.gender')} value={team?.gender} disabled />
        </Row>

        <Row>
          <Input label={t('3x3.teams.data.contactName')} value={team?.contactName} disabled />
          <Input label={t('3x3.teams.data.contactSurname')} value={team?.contactSurname} disabled />
          <Input label={t('3x3.teams.data.contactEmail')} value={team?.contactEmail} disabled />
          <Input label={t('3x3.teams.data.contactPhone')} value={team?.contactPhone} disabled />
        </Row>

        <Separator marginTop={15} marginBottom={30} height={1} backgroundColor='#eee' />

        <Row>
          <Table
            searchable={false}
            csvButton={false}
            pagination={false}
            headers={[
              t('3x3.teams.actors.name'),
              t('3x3.teams.actors.lastName'),
              t('3x3.teams.actors.email'),
              { label: t('3x3.teams.actors.number'), width: 80 },
              { label: t('3x3.teams.actors.gender'), width: 120 },
              { label: t('3x3.teams.actors.birthDate'), width: 120 },
            ]}
            data={actors?.map((actor: any) => {
              return [
                actor.peopleName,
                actor.peopleSurname,
                actor.peopleEmail,
                actor.number,
                actor.gender,
                actor.formattedPeopleBirthdate,
              ]
            }) || []}
          />
        </Row>
      </Form>

      {!disabled && (
        <View flexDirection="row" justifyContent="flex-end">
          <Button text={t('global.next')} onPress={onSave} />
        </View>
      )}
    </View>
  );
}