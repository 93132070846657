import * as React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { styling } from 'src/config';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { Alert, IAlert } from './Alert';
import { View } from './View';

export const AlertManager = () => {
  const dimensions = useWindowDimensions();
  const context = React.useContext(OWCOpenDmrContext);

  if(!context.alertItems.length) {
    return null;
  }

  return (
    <View style={[styles.container, { width: Math.min(dimensions.width, 500) }]} pointerEvents='none'>
      {context.alertItems.map((alert: IAlert) => (
        <Alert key={alert.id} {...alert} onClose={() => context.alerts.delete(alert.id)} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 9999999999,
    position: 'fixed' as any,
    padding: styling.spacing,
    paddingBottom: 0,
    right: 0,
    left: 0,
    top: 0,
    marginHorizontal: 'auto',
    backgroundColor: 'transparent',
  },
});