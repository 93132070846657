export const normalize = (value: string) => {
  if(!value) {
    return '';
  }

  return (value + '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const isNumber = (value: string | number = '') => {
  return /^-?[0-9]+(.?[0-9]+)?$/.test(value + '');
}