import * as React from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { useColors } from 'src/hooks/useColors';
import { IIconSize } from './Icon';
import { IconButton } from './IconButton';
import { View } from './View';

interface IFloatingIconButton {
  name: 'clipboard' | 'arrowright' | 'link' | 'file' | 'circle' | 'fileDownload' | 'creditCard' | 'calendar' | 'warning' | 'card' | 'help' | 'phone' | 'email' | 'arrowleft' | 'rotateLeft' | 'rotateRight' | 'map' | 'ball' | 'search' | 'text' | 'ok' | 'setting' | 'backtime' | 'previewtime' | 'ko' | 'add' | 'edit' | 'remove' | 'more' | 'less' | 'check' | 'times' | 'close' | 'plus' | 'minus' | 'arrow-up' | 'arrow-down' | 'download' | 'upload' | 'list' | 'burger' | 'user' | 'at' | 'exclamation' | 'exclamation-circle' | 'question' | 'play-circle' | 'eye' | 'eye-slash' | 'paper-plane' | 'info-circle' | 'trash' | 'trash-restore' | 'clock' | 'cart' | 'stats' | 'bold' | 'italic' | 'underline' | 'image' | 'ul' | 'ol' | 'alignLeft' | 'alignCenter' | 'alignRight' | 'alignJustify' | 'clone' | 'ban' | 'language' | 'user-shield' | 'people-group' | 'bed' | 'send' | 'excel' | 'pdf';
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onPress?: () => void;
  size?: IIconSize;
  containerStyle?: ViewProps['style'];
  title?: string;
  backgroundColor?: string;
  color?: string;
  iconPadding?: number;
}

const getStyle = (size: IIconSize = 'medium') => {
  if (size === 'small') {
    return {
      width: 35,
      height: 35,
    };
  }

  return undefined;
}

export const FloatingIconButton = ({ top, left, right, bottom, name, onPress = () => { }, size, containerStyle, title, backgroundColor, iconPadding, color = 'white' }: IFloatingIconButton) => {
  const colors = useColors('primary');

  return (
    <View style={[styles.container, { top, left, right, bottom }, getStyle(size), containerStyle]}>
      <IconButton padding={iconPadding} name={name} title={title} color={color} backgroundColor={backgroundColor ?? colors.main} onPress={onPress} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 'fit-content',
    width: 'fit-content',
    margin: 'auto',
  },
});