import dayjs from "dayjs";
import { CustomPicker, Input, Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet } from "react-native";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Form } from "src/components/Form";
import { InputDate } from "src/components/InputDate";
import { Loader } from "src/components/Loader";
import { Modal } from "src/components/Modal";
import { Row } from "src/components/Row/Row";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useUserPermission } from "src/hooks/useUserPermission";
import { CUSTOM, EMAIL, IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as SettingService from 'src/services/SettingService';
import { ISettingDTO } from "src/services/SettingService";
import * as LicenceUtils from "src/utils/LicenceUtils";
import { DOCUMENT_TYPE_CATSALUT, DOCUMENT_TYPE_CIF, DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIF, DOCUMENT_TYPE_PASSPORT, GENDER_FEMALE, GENDER_MALE, GENDER_NON_BINARY, ROLE_COACH, ROLE_PLAYER, SETTING_TYPE_BIRTHDATE, SETTING_TYPE_DOCUMENTATION, SETTING_TYPE_EMAIL, SETTING_TYPE_GENDER, SETTING_TYPE_NAME, SETTING_TYPE_NUMBER, SETTING_TYPE_OBSERVATION, SETTING_TYPE_PHONE, SETTING_TYPE_RATE, SETTING_TYPE_SHIRTSIZE, SETTING_TYPE_SURNAME } from "./config";

interface IActorCreationModal {
  actor: any;
  close: () => void;
  category: any;
  data: any[];
  onSave: (object: any) => Promise<void>;
  shirtSizes: any[];
  roles: any[];
  isOrganizer?: boolean;
  is3x3Actor?: boolean;
}

export const ActorCreationModal = ({ actor: initialActor, close, category, data, onSave, shirtSizes, roles, isOrganizer = false, is3x3Actor = false}: IActorCreationModal) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [loading, setLoading] = React.useState(false);

  const alerts = useAlerts();
  const actor = React.useRef<any>(initialActor);
  const [formElementsByRoles, setFormElementsByRoles] = React.useState<any>();
  const { required, phone, validateAll, nif, nie, cif } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [role, setRole] = React.useState<number>(initialActor?.roleId);
  const maxFormRowSize = 6;

  const setName = (value: string) => (actor.current.peopleName = value);
  const setSurname = (value: string) => (actor.current.peopleSurname = value);
  const setDocumentType = (value: string) => (actor.current.peopleIdDocType = value);
  const setDocument = (value: string) => (actor.current.peopleIdDoc = value?.toUpperCase());
  const setEmail = (value: string) => (actor.current.peopleEmail = value?.toLocaleLowerCase());
  const setPhone = (value: string) => (actor.current.peoplePhone = value);
  const setGender = (value: string) => (actor.current.gender = value);
  const setObservations = (value: string) => (actor.current.observations = value);
  const setFinalPrice = (value: string) => (actor.current.finalPrice = parseFloat(value));

  const setNumber = (value: string) => (actor.current.number = value === "00" ? "100" : value);

  const setBirthDate = React.useCallback((value?: string) => {
    actor.current.peopleBirthdate = value;
    actor.current.formattedBirthDate = dayjs(value).format("DD/MM/YYYY");
  }, [])

  const setShirtSize = React.useCallback((value: string) => {
    actor.current.shirtSizeId = value;
    actor.current.shirtSize = shirtSizes.find(s => parseInt(s.id) === parseInt(value))?.size;
  }, [shirtSizes]);

  const setRate = React.useCallback(({ value, label, description, data }) => {
    actor.current.rateId = value;
    actor.current.rateName = label;
    actor.current.rateDescription = description;
    actor.current.rateAmount = data.amount;
  }, []);

  const roleLabels = [
    { label: t("auth.competitions.teamCreation.actors.player"), value: 1 },
    { label: t("auth.competitions.teamCreation.actors.coach"), value: 2 },
    { label: t("auth.competitions.teamCreation.actors.assistant"), value: 3 }
  ]

  const renderRateItem = React.useCallback((object: any) => {
    const amount = LicenceUtils.isBonus(actor.current, category.eventNumNonPlayerBonusRate, data) ? 0 : object.data.amount;

    return (
      <View>
        <Text fontSize={16}>{amount} € - {object.label}</Text>
        <Text fontSize={12}>{object.description}</Text>
      </View>
    );
  }, [category, data]);

  const renderRateLabel = React.useCallback((object: any) => {
    if (!object?.value) {
      return;
    }

    const amount = LicenceUtils.isBonus(actor.current, category.eventNumNonPlayerBonusRate, data) ? 0 : object.data.amount;
    return `${amount} € - ${object.label}`;

  }, [data, category]);

  const onChangeRole = (value: any) => {
    setRole(value ? parseInt(value) : null);
    setErrors({});

    actor.current.roleId = parseInt(value);
    actor.current.roleDescription = roleLabels.find(r => r.value === parseInt(value))?.label

    if (actor.current.roleId !== ROLE_PLAYER) {
      actor.current.number = "";
    }
  };

  useComponentDidMount(async () => {
    setLoading(true);
    const response = await SettingService.findByEventCategory(category.id);
    setFormElementsByRoles(getFormElementsByRoles(response.data));
    setLoading(false);
  })

  const items = React.useMemo(() => ({
    genders: [
      { label: t("auth.competitions.teamCreation.actors.female"), value: GENDER_FEMALE },
      { label: t("auth.competitions.teamCreation.actors.male"), value: GENDER_MALE },
      { label: t("auth.competitions.teamCreation.actors.nonBinary"), value: GENDER_NON_BINARY },
    ],
    documentTypes: [
      { label: "NIF", value: DOCUMENT_TYPE_NIF },
      { label: t("auth.competitions.teamCreation.actors.passport"), value: DOCUMENT_TYPE_PASSPORT },
      { label: "CATSALUT", value: DOCUMENT_TYPE_CATSALUT },
      { label: "NIE", value: DOCUMENT_TYPE_NIE },
      { label: "CIF", value: DOCUMENT_TYPE_CIF },
    ],
    rates: category?.rates?.map((rate: any) => ({ label: rate.name, value: rate.id, description: rate.description, data: { amount: rate.amount } })),
    shirtSizes: shirtSizes?.map((shirtSize: any) => ({ label: shirtSize.size, value: shirtSize.id }))
  }), [category, shirtSizes])

  const customValidation = React.useMemo(() => ({
    gender: () => {
      if (actor.current.roleId === ROLE_PLAYER) {
        return (
          required(actor.current.gender) ||
          (actor.current.gender !== GENDER_NON_BINARY &&
            category.gender !== GENDER_NON_BINARY &&
            actor.current.gender !== category.gender &&
            t("auth.competitions.teamCreation.actors.errors.gender"))
        );
      }
    },
    peopleBirthdate: () => {
      return (
        required(actor.current.peopleBirthdate) ||
        (actor.current.roleId === ROLE_PLAYER &&
          (dayjs(actor.current.peopleBirthdate).isBefore(dayjs(category.earliestBirthdate)) ||
            dayjs(actor.current.peopleBirthdate).isAfter(dayjs(category.latestBirthdate))) &&
          t("auth.competitions.teamCreation.actors.errors.birthDate"))
      );
    },
    peoplePhone: () => {
      if (actor.current.roleId === ROLE_COACH) {
        return required(actor.current.peoplePhone) || phone(actor.current.peoplePhone);
      }
      return !!actor.current.peoplePhone && phone(actor.current.peoplePhone);
    },
    peopleIdDocType: () => {
      switch (parseInt(actor.current.peopleIdDocType)) {
        case DOCUMENT_TYPE_NIF:
          return required(actor.current.peopleIdDoc) || nif(actor.current.peopleIdDoc);
        case DOCUMENT_TYPE_NIE:
          return required(actor.current.peopleIdDoc) || nie(actor.current.peopleIdDoc);
        case DOCUMENT_TYPE_CIF:
          return required(actor.current.peopleIdDoc) || cif(actor.current.peopleIdDoc);
      }
    },
  }), [category, cif, nie, nif, phone, required]);

  const getFormElements = React.useCallback((settings: ISettingDTO[]) => {

    const settingProperties = [];

    let key = 0;

    settings.forEach((setting: ISettingDTO) => {

      if (!setting.visible) {
        return;
      }

      const defaultProperties = {
        key: key,
        roleId: setting.roleId,
        size: 2,
        inputType: "Input",
        validate: null,
        email: false,
        required: setting.required,
      };

      key += 1;

      switch (setting.typeId) {
        case SETTING_TYPE_NAME:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleName",
            priority: 1,
            label: t("auth.competitions.teamCreation.actors.name"),
            onChange: setName,
          });
          break;

        case SETTING_TYPE_SURNAME:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleSurname",
            priority: 2,
            label: t("auth.competitions.teamCreation.actors.surname"),
            onChange: setSurname,
          });
          break;

        case SETTING_TYPE_GENDER:
          settingProperties.push({
            ...defaultProperties,
            name: "gender",
            priority: 3,
            validate: customValidation.gender,
            label: t("auth.competitions.teamCreation.actors.gender"),
            onChange: setGender,
            inputType: "Picker",
            items: items.genders,
          });
          break;

        case SETTING_TYPE_BIRTHDATE:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleBirthdate",
            priority: 4,
            validate: customValidation.peopleBirthdate,
            label: t("auth.competitions.teamCreation.actors.birthDate"),
            onChange: setBirthDate,
            inputType: "InputDate",
            minDate: (role === ROLE_PLAYER) ? category.earliestBirthdate : null,
            maxDate: (role === ROLE_PLAYER) ? category.latestBirthdate : null,
          });
          break;

        case SETTING_TYPE_EMAIL:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleEmail",
            priority: 5,
            label: t("auth.competitions.teamCreation.actors.email"),
            onChange: setEmail,
            email: true
          });
          break;

        case SETTING_TYPE_PHONE:
          settingProperties.push({
            ...defaultProperties,
            name: "peoplePhone",
            priority: 6,
            validate: customValidation.peoplePhone,
            label: t("auth.competitions.teamCreation.actors.phone"),
            onChange: setPhone,
          });
          break;

        case SETTING_TYPE_DOCUMENTATION:
          settingProperties.push({
            ...defaultProperties,
            name: "peopleIdDocType",
            priority: 7,
            validate: customValidation.peopleIdDocType,
            label: t("auth.competitions.teamCreation.actors.documentType"),
            onChange: setDocumentType,
            inputType: "Picker",
            items: items.documentTypes,
          });

          settingProperties.push({
            ...defaultProperties,
            name: "peopleIdDoc",
            priority: 8,
            label: t("auth.competitions.teamCreation.actors.document"),
            onChange: setDocument,
          });
          break;

        case SETTING_TYPE_RATE:
          if (!is3x3Actor) {
            settingProperties.push({
              ...defaultProperties,
              name: "rateId",
              priority: 10,
              size: 5,
              label: `${t("auth.competitions.teamCreation.actors.rate")} ${setting.required ? "*" : ""}`,
              onChange: setRate,
              inputType: "CustomPicker",
              items: items.rates,
              renderItem: renderRateItem,
              renderLabel: renderRateLabel,
            });

            if (isOrganizer) {
              settingProperties.push({
                ...defaultProperties,
                name: "finalPrice",
                priority: 11,
                size: 1,
                label: t("auth.competitions.teamCreation.actors.price"),
                onChange: setFinalPrice,
              });
            }
          }
          break;

        case SETTING_TYPE_NUMBER:
          settingProperties.push({
            ...defaultProperties,
            name: "number",
            priority: 9,
            label: t("auth.competitions.teamCreation.actors.number"),
            onChange: setNumber,
          });
          break;

        case SETTING_TYPE_SHIRTSIZE:
          settingProperties.push({
            ...defaultProperties,
            name: "shirtSizeId",
            priority: 12,
            label: t("auth.competitions.teamCreation.actors.size"),
            onChange: setShirtSize,
            inputType: "Picker",
            items: items.shirtSizes,
          });
          break;

        case SETTING_TYPE_OBSERVATION:
          settingProperties.push({
            ...defaultProperties,
            name: "observations",
            size: 6,
            priority: 13,
            label: t("auth.competitions.teamCreation.actors.observations"),
            onChange: setObservations
          });
      }
    })

    return settingProperties;

  }, [category, customValidation, items, renderRateItem, renderRateLabel, role, setBirthDate, setShirtSize, setRate, isOrganizer])

  const getFormElementsByRoles = React.useCallback((settings: ISettingDTO[]) => {

    const formElements = getFormElements(settings);

    const formElementsByRoles = [
      ...roles.map(role => ({
        roleId: role.id,
        elements: [...formElements
          .sort((a, b) => a.priority - b.priority)
          .filter((formSetting => role.id === formSetting.roleId))
        ],
      }))
    ];

    return formElementsByRoles;

  }, [roles, getFormElements])

  const validate = React.useCallback(() => {

    const params: IValidationParams[] = [];

    formElementsByRoles.find((formElementsByRole: any) => formElementsByRole.roleId === role).elements.forEach((formElement: any) => {
      if (!formElement.required) {
        return;
      }

      const types = [REQUIRED];
      !!formElement.validate && types.push(CUSTOM)
      formElement.email && types.push(EMAIL);

      params.push({
        name: formElement.name,
        type: types,
        ...(!!formElement.validate && { validate: formElement.validate })
      })

    })

    const { errors, hasErrors } = validateAll(actor.current, params);
    setErrors(errors);
    return !hasErrors;
  }, [formElementsByRoles, role, actor, validateAll]);

  const saveActor = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      await onSave({ ...actor.current, finalPrice: isOrganizer ? actor.current.finalPrice : undefined });
      alerts.create({ text: t("global.success"), type: "success" })
      close();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [validate, onSave, close, alerts, isOrganizer]);

  const printFormElements = React.useCallback(() => {
    if (!role) {
      return [];
    }

    const formElements = formElementsByRoles?.find((formElementsByRole: any) => formElementsByRole.roleId === role).elements;
    const formRows: any[][] = [[]];

    let rowIndex: number = 0;
    let rowSize: number = 0;

    formElements?.forEach((formElement: any) => {
      if (formElement.size + rowSize > maxFormRowSize) {
        // ADD ROW
        formRows.push([])
        rowSize = 0
        rowIndex += 1;
      }
      formRows[rowIndex]?.push(formElement);
      rowSize += formElement.size;

    });

    const form = formRows?.map((formRow, index) => {
      return (
        <Row key={index} flex={[...formRow?.map(rowElement => rowElement.size)]} containerStyle={{ zIndex: 9999 - index}}>

          {formRow?.map(element => {

            switch (element.inputType) {

              case "Input":
                return <Input
                  key={element.key + index}
                  label={element.label}
                  defaultValue={actor.current[element.name]}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  disabled={readOnly}
                  required={element.required}
                />

              case "InputDate":
                return <InputDate
                  key={element.key}
                  label={element.label}
                  defaultValue={actor.current[element.name]}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  disabled={readOnly}
                  required={element.required}
                  minDate={element.minDate}
                  maxDate={element.maxDate}
                />

              case "Picker":
                return <Picker
                  key={element.key}
                  label={element.label}
                  defaultValue={actor.current[element.name]}
                  items={element.items}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  disabled={readOnly}
                  required={element.required}
                />

              case "CustomPicker":
                return <CustomPicker
                  key={element.key}
                  label={element.label}
                  defaultValue={actor.current[element.name]}
                  items={element.items}
                  renderItem={element.renderItem}
                  renderLabel={element.renderLabel}
                  onChange={element.onChange}
                  error={errors[element.name]}
                  disabled={readOnly}
                  containerStyle={{ zIndex: 99999 }}
                />

              default: return <></>;
            }
          })}
        </Row>
      );
    });
    return form;
  }, [errors, formElementsByRoles, role, readOnly]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      visible
      minWidth={950}
      title={readOnly ? t("auth.competitions.teamCreation.actors.title") : (!!actor.current?.id ? t("auth.competitions.teamCreation.actors.edit") : t("auth.competitions.teamCreation.actors.add"))}
      onClose={close}
      minHeight={650}
      contentContainerStyle={{ overflow: 'visible' }}
    >
      <Form>

        <Row>
          {role === ROLE_PLAYER && (
            <Alert type="warning" text={t("auth.competitions.teamCreation.actors.underageEmailWarning")} />
          )}
        </Row>

        <Row>
          <Picker
            label={t("auth.competitions.teamCreation.actors.role")}
            items={roles.map((role: any) => ({ label: roleLabels.find(r => r.value === role.id).label, value: role.id }))}
            defaultValue={actor.current?.roleId}
            onChange={onChangeRole}
            error={errors.roleId}
            disabled={readOnly}
          />
        </Row>

        {printFormElements()}

        {role && !readOnly && (
          <Row containerStyle={{ zIndex: -1 }}>
            <View style={[styles.buttonContainer]}>
              <Button type="link" label={t("global.cancel")} onPress={close} disabled={readOnly} marginBottom={0} />
              <Button label={t("global.save")} onPress={saveActor} disabled={readOnly} containerStyle={styles.buttonSave} />
            </View>
          </Row>
        )}

      </Form>

    </Modal>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: "flex-end",
    justifyContent: 'flex-end'
  },
  buttonSave: {
    marginBottom: 0,
    marginLeft: 15,
  }
});
