import React, { useState, useEffect } from 'react';
import { Icon, t } from "owc-react-lib-common";
import { View } from 'src/components/View';
import { ITableHeader } from "src/components/Table";
import { Container } from 'src/components/Container';
import { LoginLog, getLoginLogs } from 'src/services/LoginLogService';
import { Text } from 'src/components/Text';
import { AjaxTable } from 'src/components/AjaxTable/AjaxTable';
import { Page } from 'src/services/entities/Page';
import { Loader } from 'src/components/Loader';
import { useAlerts } from 'src/hooks/useAlerts';
import dayjs from 'dayjs';

const DEFAULT_PAGE_ITEMS = 10;
const DEFAULT_PAGE = 0;
const LoginLogsList = () => {
    const alerts = useAlerts();
    const [loginLogs, setLoginLogs] = useState([]);
    const [loginLogsPage, setLoginLogsPage] = useState<Page<LoginLog>>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageItems, setPageItems] = useState<number>(DEFAULT_PAGE_ITEMS);
    const [page, setPage] = useState<number>(DEFAULT_PAGE);

    useEffect(() => {
        const fetchLoginLogs = async () => {
            setLoading(true);
            fetchLogs(DEFAULT_PAGE, DEFAULT_PAGE_ITEMS);
            setLoading(false);
        };

        fetchLoginLogs();
    }, []); // Run once when the component mounts

    const changePage = React.useCallback((pageNumber) => {
        if (pageNumber === page) return;
        setPage(pageNumber);
        fetchLogs(pageNumber, pageItems);
    }, [alerts, loading, loginLogsPage, loginLogs, page]);

    const changeSize = React.useCallback((size) => {
        if (size === pageItems) return;
        setPageItems(size);
        setPage(0);
        fetchLogs(0, size);
    }, [alerts, loading, loginLogsPage, loginLogs, pageItems, page]);

    const onSort = React.useCallback((sort: string, direction: string) => {
        fetchLogs(page, pageItems, sort, direction, '');
    }, [alerts, loading, loginLogsPage, loginLogs, pageItems, page])

    const onSearch = React.useCallback((searchString) => {
        fetchLogs(page, pageItems, null, null, searchString);
        return loginLogsPage;
    }, []);

    const fetchLogs = React.useCallback(async (pageNumber, numberOfElements, sortItem = null, sortDirection = null, searchString = null) => {
        try {
            const logs = await getLoginLogs(pageNumber, numberOfElements, sortItem, sortDirection, searchString); // Adjust page and size as needed
            setLoginLogs(logs.content);
            setLoginLogsPage(logs);
        } catch (error) {
            alerts.createDSMError(error);
        }
    }, [page, pageItems, alerts, loading, loginLogsPage, loginLogs])

    /**
     * render
     */
    const headers: ITableHeader[] = [
        {
            name: "baseUser.id",
            label: t("admin.login.list.id"),
            width: 60,
            render: (e) => { return (<Text>{e.baseUser.id}</Text>) }
        },
        {
            name: "loginSuccess",
            label: t("admin.login.list.success"),
            width: 100,
            headerTextProps: { textAlign: 'center' },
            render: (e) => {
                return (
                    <View>
                        <Icon color={e.loginSuccess ? 'green' : 'red'} type={'flag'} />
                    </View>
                )
            }
        },
        {
            name: "baseUser.email",
            label: t("admin.login.list.email"),
            width: 300,
            textProps: { uppercase: true },
            render: (e) => { return (<Text>{e.baseUser.email}</Text>) }
        },
        {
            name: "loginMethod",
            label: t("admin.login.list.loginMethod"),
            flex: 1,
        },
        {
            name: "loginTime",
            label: t("admin.login.list.loginTime"),
            flex: 1,
            render: (e) => {
                const dayJsDate = dayjs(e.loginTime);
                return (<Text>{dayJsDate.format("DD-MM-YYYY")} {dayJsDate.format("HH:mm:ss")}</Text>)
            }
        }
    ];
    if (loading) {
        return (<Loader />)
    }
    return (
        <Container title={t("admin.login.list.title")}>
            <AjaxTable
                data={loginLogsPage}
                headers={headers}
                defaultSize={pageItems}
                changePage={changePage}
                changeSize={changeSize}
                onSort={onSort}
                search={onSearch}
            />
        </Container>
    );
};

export default LoginLogsList;
