import * as React from "react";
import { Picker, t } from "owc-react-lib-common";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { CUSTOM, IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import {
  DOCUMENT_TYPE_CIF,
  DOCUMENT_TYPE_NIE,
  DOCUMENT_TYPE_NIF,
  DOCUMENT_TYPE_PASSPORT,
} from "src/pages/auth/components/config";
import * as TeamActorService from "src/services/TeamActorService";
import { CreationQuestion } from "./CreationQuestion";
import { REGISTER_STATUS_PENDING_QUESTIONS } from "./IRegistrationProcess";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";

interface IStep01 {
  data2: any;
  onSuccess: () => void;
};

export const Step01 = ({ data2: data, onSuccess }: IStep01) => {
  const alerts = useAlerts();
  const { required, validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const disabled = data.teamActor.registrationStatus > REGISTER_STATUS_PENDING_QUESTIONS;
  const [familyRelationsData, setFamilyRelationsData] = React.useState({} as any);
  const [loading, setLoading] = React.useState<boolean>(true);

  const actor = React.useRef<any>(data.teamActor);
  const documentation = React.useRef<any>(data.documentation);
  const answers = React.useRef<any>(data.questions.map((question) => {
    let answer = data.answers.find((answer: any) => answer.questionId === question.id);
    const items = question.options?.split(";") || [];

    if (!answer && items.length && question.defaultValue !== null && question.defaultValue !== undefined) {
      answer = {
        answer: items[question.defaultValue],
        questionId: question.id,
        externalId: data.teamActor.id,
        externalTypeId: 1,
      };
    }

    return answer;
  }).filter((answer: any) => !!answer));

  const setRepresenterFamilyRelationTypeId = React.useCallback((value: string) => (documentation.current.representerFamilyRelationTypeId = value), []);
  const setRepresenterIdDoc = React.useCallback((value: string) => (documentation.current.representerIdDoc = value), []);
  const setRepresenterIdDocType = React.useCallback((value: string) => (documentation.current.representerIdDocType = value), []);
  const setRepresenterName = React.useCallback((value: string) => (documentation.current.representerName = value), []);
  const setRepresenterSurname = React.useCallback((value: string) => (documentation.current.representerSurname = value), []);
  const setPeopleIdDoc = React.useCallback((value: string) => (actor.current.peopleIdDoc = value?.toUpperCase()), []);
  const setPeopleIdDocType = React.useCallback((value: string) => (actor.current.peopleIdDocType = value), []);

  const documentTypes = [
    { label: "NIF", value: DOCUMENT_TYPE_NIF },
    { label: t("public.actorRegistration.step01.passport"), value: DOCUMENT_TYPE_PASSPORT },
    { label: "NIE", value: DOCUMENT_TYPE_NIE },
    { label: "CIF", value: DOCUMENT_TYPE_CIF },
  ]

  /**
   * on change answer
   */
  const onChangeAnswer = React.useCallback((question: any, value: any) => {
    const index = answers.current.findIndex((answer: any) => answer.questionId === question.id);

    if (index === undefined || index === -1) {
      answers.current.push({
        answer: value,
        questionId: question.id,
        externalId: actor.current.id,
        externalTypeId: 1,
      });
    }
    else {
      answers.current[index].answer = value;
    }
  }, []);

  /**
   * load
   */
  useComponentDidMount(async () => {
    if (data?.documentation?.subjectUnderAge) {
      const response = await TeamActorService.familyRelations();
      setFamilyRelationsData(response?.data?.map((item: any) => ({ label: item.description, value: item.id })));
    }

    setLoading(false);
  });

  /**
   * validate form
   */
  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [];

    if (documentation?.current.subjectUnderAge) {
      // menor de edad
      fields.push({ name: "representerName", type: REQUIRED });
      fields.push({ name: "representerSurname", type: REQUIRED });
      fields.push({ name: "representerFamilyRelationTypeId", type: REQUIRED });
      fields.push({ name: "representerIdDoc", type: REQUIRED });
      fields.push({ name: "representerIdDocType", type: REQUIRED });
    }
    else {
      // mayor de edad
      fields.push({ name: "peopleIdDocType", type: REQUIRED });
      fields.push({ name: "peopleIdDoc", type: REQUIRED });
    }

    data?.questions?.forEach((question: any) => {
      fields.push({
        name: `question-${question.id}`,
        type: CUSTOM,
        validate: () => {
          if (question.required) {
            const answer = answers?.current.find((answer: any) => answer.questionId === question.id);

            // validamos que el check, este a true y no a false, si esta a false es como si estuviera vacio en caso de requerido
            if (answer) {
              if (question.type === "check") {
                if (answer?.answer === false) {
                  answer.answer = null;
                }
                if (answer?.answer === true) {
                  answer.answer = question.options;
                }
              }
            }
            return required(answer?.answer);
          }
        },
      });
    });

    if (documentation?.current.subjectUnderAge) {
      const { errors, hasErrors } = validateAll(documentation.current, fields);
      setErrors(errors);
      return !hasErrors;
    }

    const { errors, hasErrors } = validateAll(actor.current, fields);
    setErrors(errors);
    return !hasErrors;
  }, [data, required, validateAll]);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    if (data.teamActor.registrationStatus === REGISTER_STATUS_PENDING_QUESTIONS) {
      try {
        await TeamActorService.questionsAnswered({ ...data, answers: answers.current, documentation: documentation.current, teamActor: actor.current });
        onSuccess();
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
  }, [alerts, data, onSuccess, validate]);

  /**
   * ...
   */
  const representer2 = React.useCallback(() => {
    if (data?.documentation?.subjectUnderAge) {
      return;
    }

    return (
      <View>
        <Text style={{ marginBottom: 10 }} uppercase bold fontSize={14}>
          {t("public.actorRegistration.step01.personal")}
        </Text>

        <View style={{ flexDirection: "row" }}>
          <Input
            containerStyle={{ flex: 2 }}
            disabled
            defaultValue={data.teamActor.fullName}
            label={t("public.actorRegistration.step01.fullName")}
          />

          <Picker
            disabled={disabled}
            containerStyle={{ flex: 1, marginHorizontal: styling.spacing }}
            error={errors.peopleIdDocType}
            defaultValue={actor.current.peopleIdDocType}
            label={t("public.actorRegistration.step01.representerIdDocType")}
            items={documentTypes}
            onChange={setPeopleIdDocType}
          />

          <Input
            disabled={disabled}
            containerStyle={{ flex: 1 }}
            defaultValue={actor.current.peopleIdDoc}
            error={errors.peopleIdDoc}
            // required
            label={t("public.actorRegistration.step01.peopleIdDoc")}
            onChange={setPeopleIdDoc}
          />
        </View>
      </View>
    );
  }, [errors, data, documentTypes, disabled]);

  /**
   * ...
   */
  const representer = React.useCallback(() => {
    if (!data?.documentation?.subjectUnderAge) {
      return;
    }

    return (
      <View>
        <Alert
          text={t("public.actorRegistration.step01.description", { NAME: `${data.teamActor.fullName}` })}
          type="info"
        />

        <Text style={{ marginBottom: 10 }} uppercase bold fontSize={14}>
          {t("public.actorRegistration.step01.relation")}
        </Text>

        <Picker
          disabled={disabled}
          error={errors.representerFamilyRelationTypeId}
          label={t("public.actorRegistration.step01.representerFamilyRelationTypeId")}
          items={familyRelationsData}
          defaultValue={documentation.current.representerFamilyRelationTypeId}
          onChange={setRepresenterFamilyRelationTypeId}
        />

        <Form>
          <Row>
            <Input
              disabled={disabled}
              containerStyle={{ flex: 1 }}
              error={errors.representerName}
              label={t("public.actorRegistration.step01.representerName")}
              defaultValue={documentation.current.representerName}
              onChange={setRepresenterName}
            />

            <Input
              disabled={disabled}
              containerStyle={{ flex: 2, marginHorizontal: styling.spacing }}
              error={errors.representerSurname}
              // required
              label={t("public.actorRegistration.step01.representerSurname")}
              defaultValue={documentation.current.representerSurname}
              onChange={setRepresenterSurname}
            />

            <Picker
              disabled={disabled}
              containerStyle={{ flex: 1 }}
              error={errors.representerIdDocType}
              label={t("public.actorRegistration.step01.representerIdDocType")}
              items={documentTypes}
              onChange={setRepresenterIdDocType}
              defaultValue={documentation.current.representerIdDocType}
            />

            <Input
              disabled={disabled}
              containerStyle={{ flex: 1, marginLeft: styling.spacing }}
              error={errors.representerIdDoc}
              // required
              label={t("public.actorRegistration.step01.representerIdDoc")}
              defaultValue={documentation.current.representerIdDoc}
              onChange={setRepresenterIdDoc}
            />
          </Row>
        </Form>
      </View>
    );
  }, [data, errors, disabled, familyRelationsData, documentTypes]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  return (
    <View>
      {representer()}
      {representer2()}

      <View>
        {data?.questions?.map((question: any) => {
          const answer = answers?.current.find((answer: any) => answer.questionId === question.id);

          return (
            <View key={question.id}>
              <Text bold fontSize={14}>
                {question.question}
              </Text>

              <CreationQuestion
                key={question.id}
                data={question}
                defaultValue={answer?.answer}
                // value={answer?.answer}
                onChange={(value: string) => onChangeAnswer(question, value)}
                required={question.required}
                disabled={disabled}
                error={errors[`question-${question.id}`]}
              />
            </View>
          );
        })}

        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <Button onPress={save} label={t("global.next")} />
        </View>
      </View>
    </View>
  );
};
