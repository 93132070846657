import { Container } from "src/components/Container"
import { ShoppingCart } from './ShoppingCart';

export const ShoppingCartView = () => {

    return (
        <Container >
            <ShoppingCart showTitle/>
        </Container>
    )
}

