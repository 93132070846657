import * as React from "react";
import { t } from "owc-react-lib-common";

import { StyleSheet } from "react-native";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { Input } from "owc-react-lib-common"
import { UserActionCard } from "./components/UserActionCard";
import * as UserService from "src/services/UserService";
import { useAlerts } from "src/hooks/useAlerts";
import { EMAIL, REQUIRED, useValidation } from "src/hooks/useValidation";
import { Alert } from "src/components/Alert";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";

export const PasswordRecoveryRequest = () => {
  const alerts = useAlerts();
  // form
  const form = React.useRef<any>({});
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});

  /**
   * on input change
   */
  const setEmail = React.useCallback((value: string) => (form.current.email = value), []);

  /**
   * send
   */
  const send = React.useCallback(async () => {
    // validate
    const { errors, hasErrors } = validateAll(form.current, [{ name: "email", type: [REQUIRED, EMAIL] }]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // request
    try {
      await UserService.sendPass(form.current.email);
      alerts.create({ type: "success", text: t("public.users.passwordRecoveryRequest.success") });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validateAll]);

  /**
   * render
   */
  return (
    <UserActionCard>
      <Alert type="info" text={t("public.users.passwordRecoveryRequest.description")} />
      <Form>
        <Row>
          <Input
            label={t("public.users.passwordRecoveryRequest.email")}
            onChange={setEmail}
            error={errors.email}
          />
        </Row>
        <Row isColumn={false} flex={[1, 1]}>
          <Button label={t("global.send")} onPress={send} containerStyle={styles.button} />
          <ButtonLink to="/login" label={t("public.users.passwordRecoveryRequest.login")} justifyContent='flex-end' />
        </Row>
      </Form>
    </UserActionCard>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 5,
  },
});
