import * as React from 'react';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';

export enum Translation {
    PORTAL_TITLE,
    PORTAL_DESCRIPTION,
    FIVEONFIVE_COMPETITION_TITLE,
    FIVEONFIVE_COMPETITION_DESCRIPTION,
    FIVEONFIVE_TEAM_TITLE,
    FIVEONFIVE_TEAM_DESCRIPTION,
    THREEONTHREE_COMPETITION_TITLE,
    THREEONTHREE_COMPETITION_DESCRIPTION,
    THREEONTHREE_TEAM_TITLE,
    THREEONTHREE_TEAM_DESCRIPTION,
    THREEONTHREE_TEAM_HELP,
    THREEONTHREE_ACTOR_HELP,
    THREEONTHREE_ACTOR_REGISTRATION_WELCOME,
    THREEONTHREE_ACTOR_REGISTRATION_DESCRIPTON
}

export const usePortalTranslations = () => {
    const context = React.useContext(OWCOpenDmrContext);
    const portal = context.configuration.portal.portal;

    const translations = {
        "owbasket": {
            [Translation.PORTAL_TITLE]: "auth.main.owbasket.title",
            [Translation.PORTAL_DESCRIPTION]: "auth.main.owbasket.description",
            [Translation.FIVEONFIVE_COMPETITION_TITLE]: "auth.main.owbasket.5x5Title",
            [Translation.FIVEONFIVE_COMPETITION_DESCRIPTION]: "auth.main.owbasket.5x5Description",
            [Translation.FIVEONFIVE_TEAM_TITLE]: "auth.main.owbasket.5x5TeamTitle",
            [Translation.FIVEONFIVE_TEAM_DESCRIPTION]: "auth.main.owbasket.5x5TeamDescription",
            [Translation.THREEONTHREE_COMPETITION_TITLE]: "auth.main.owbasket.3x3Title",
            [Translation.THREEONTHREE_COMPETITION_DESCRIPTION]: "auth.main.owbasket.3x3Description",
            [Translation.THREEONTHREE_TEAM_TITLE]: "auth.main.owbasket.3x3TeamTitle",
            [Translation.THREEONTHREE_TEAM_DESCRIPTION]: "auth.main.owbasket.3x3TeamDescription",
            [Translation.THREEONTHREE_TEAM_HELP]: "auth.main.owbasket.3x3TeamHelp",
            [Translation.THREEONTHREE_ACTOR_HELP]: "auth.main.owbasket.3x3ActorHelp",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_WELCOME]: "auth.main.owbasket.3x3ActorRegistrationWelcome",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_DESCRIPTON]: "auth.main.owbasket.3x3ActorRegistrationDescription",
        },
        "inscripcionsfundacio.basquetcatala.cat": {
            [Translation.PORTAL_TITLE]: "auth.main.inscripcionsfundacio.title",
            [Translation.PORTAL_DESCRIPTION]: "auth.main.inscripcionsfundacio.description",
            [Translation.FIVEONFIVE_COMPETITION_TITLE]: "auth.main.inscripcionsfundacio.5x5Title",
            [Translation.FIVEONFIVE_COMPETITION_DESCRIPTION]: "auth.main.inscripcionsfundacio.5x5Description",
            [Translation.FIVEONFIVE_TEAM_TITLE]: "auth.main.inscripcionsfundacio.5x5TeamTitle",
            [Translation.FIVEONFIVE_TEAM_DESCRIPTION]: "auth.main.inscripcionsfundacio.5x5TeamDescription",
            [Translation.THREEONTHREE_COMPETITION_TITLE]: "auth.main.inscripcionsfundacio.3x3Title",
            [Translation.THREEONTHREE_COMPETITION_DESCRIPTION]: "auth.main.inscripcionsfundacio.3x3Description",
            [Translation.THREEONTHREE_TEAM_TITLE]: "auth.main.inscripcionsfundacio.3x3TeamTitle",
            [Translation.THREEONTHREE_TEAM_DESCRIPTION]: "auth.main.inscripcionsfundacio.3x3TeamDescription",
            [Translation.THREEONTHREE_TEAM_HELP]: "auth.main.inscripcionsfundacio.3x3TeamHelp",
            [Translation.THREEONTHREE_ACTOR_HELP]: "auth.main.inscripcionsfundacio.3x3ActorHelp",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_WELCOME]: "auth.main.inscripcionsfundacio.3x3ActorRegistrationWelcome",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_DESCRIPTON]: "auth.main.inscripcionsfundacio.3x3ActorRegistrationDescription",
        },
        "torneos.fbcyl.es":{
            [Translation.PORTAL_TITLE]: "auth.main.fbcyl.title",
            [Translation.PORTAL_DESCRIPTION]: "auth.main.fbcyl.description",
            [Translation.FIVEONFIVE_COMPETITION_TITLE]: "auth.main.fbcyl.5x5Title",
            [Translation.FIVEONFIVE_COMPETITION_DESCRIPTION]: "auth.main.fbcyl.5x5Description",
            [Translation.FIVEONFIVE_TEAM_TITLE]: "auth.main.fbcyl.5x5TeamTitle",
            [Translation.FIVEONFIVE_TEAM_DESCRIPTION]: "auth.main.fbcyl.5x5TeamDescription",
            [Translation.THREEONTHREE_COMPETITION_TITLE]: "auth.main.fbcyl.3x3Title",
            [Translation.THREEONTHREE_COMPETITION_DESCRIPTION]: "auth.main.fbcyl.3x3Description",
            [Translation.THREEONTHREE_TEAM_TITLE]: "auth.main.fbcyl.3x3TeamTitle",
            [Translation.THREEONTHREE_TEAM_DESCRIPTION]: "auth.main.fbcyl.3x3TeamDescription",
            [Translation.THREEONTHREE_TEAM_HELP]: "auth.main.fbcyl.3x3TeamHelp",
            [Translation.THREEONTHREE_ACTOR_HELP]: "auth.main.fbcyl.3x3ActorHelp",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_WELCOME]: "auth.main.fbcyl.3x3ActorRegistrationWelcome",
            [Translation.THREEONTHREE_ACTOR_REGISTRATION_DESCRIPTON]: "auth.main.fbcyl.3x3ActorRegistrationDescription",
        },



    }

    return (key: number) => {
        return translations[portal]?.[key] || translations.owbasket[key]
    }

}

