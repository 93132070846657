import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface ITeamLevelDTO {
  id?: number;
  eventCategoryId?: number;
  name?: string;
  order?: 0;
}

const BASE_URL = BACKEND_URL + '/auth/team-levels'

export const findAllByEventCategory = (eventCategoryId: number) => {
  return RestService.get(`${BASE_URL}/${eventCategoryId}`);
}

export const deleteTeamLevel = (teamLevelId: number) => {
  return RestService.delete(`${BASE_URL}/${teamLevelId}`);
}

export const createTeamLevel = (teamLevel: ITeamLevelDTO) => {
  return RestService.post(`${BASE_URL}`, teamLevel);
}

export const updateTeamLevel = (teamLevel: ITeamLevelDTO) => {
  return RestService.put(`${BASE_URL}`, teamLevel);
}

