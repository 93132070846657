import * as React from 'react';
import { IText, Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { styling } from 'src/config';
import { Icon } from '../Icon';



export interface ITableHeader<T> {
    name: string;
    label: string;
    render?: (object: T) => React.ReactNode;
    headerRender?: (object: any) => React.ReactNode;
    width?: number;
    flex?: number;
    hidden?: boolean;
    headerTextProps?: IText;
    textProps?: IText | ((object: any) => IText);
    search?: (object: any) => string | number;
    sort?: (object: any) => string | number | boolean;
}

export interface IHeaders<T> {
    headers: ITableHeader<T>[];
    defaultSort?: string;
    defaultSortDirection?: "ASC" | "DESC";
    onSort: (sort: string, direction: "ASC" | "DESC") => void;
}

export const Header = <T,>({ headers, defaultSort, defaultSortDirection, onSort }: IHeaders<T>) => {

    const [sort, setSort] = React.useState<any>(() => {
        return { name: defaultSort, direction: defaultSortDirection }
    });

    const onHeaderClick = (header: ITableHeader<T>): void => {
        setSort({
            name: header.name,
            direction: sort.direction === "ASC" ? "DESC" : "ASC"
        });
        onSort(header.name, sort.direction === "ASC" ? "DESC" : "ASC");
    }
    const getColumnStyle = (header: ITableHeader<T>) => {
        const { width, flex } = header;
        return { width, flex: flex || !width ? 1 : undefined }
    }

    return (
        <View style={styles.headers}>
            {headers.filter(header => !header.hidden).map((header: ITableHeader<T>, columnIdx: number) => {
                const { label, name: key, headerTextProps } = header;
                return (
                    <TouchableOpacity
                        key={key}
                        onPress={() => onHeaderClick(header)}
                        activeOpacity={1}
                        style={[styles.cell, columnIdx === 0 && styles.cellFirst, styles.header, getColumnStyle(header)]}
                    >
                        {!!header.headerRender ?
                            header.headerRender(header) :
                            <Text fontSize={13} bold uppercase style={styles.headerLabel} {...headerTextProps}>{label}</Text>
                        }

                        {!!(header.name === sort.name && sort.direction) && (
                            <Icon
                                name={sort.direction === "DESC" ? 'arrow-down' : 'arrow-up'}
                                style={{ justifyContent: 'center', position: 'absolute', right: 5, bottom: 0, top: 0, margin: 'auto', paddingBottom: 5, background: '#fff' }}
                            />
                        )}
                    </TouchableOpacity>
                );
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        marginBottom: styling.spacing,
    },
    actions: {
        flexDirection: 'row',
        marginBottom: styling.spacing,
    },
    size: {
        marginRight: 5,
        width: 80,
        marginBottom: 0,
    },
    search: {
        flex: 1,
        marginBottom: 0,
    },
    visibility: {
        padding: styling.spacing,
    },
    checkbox: {
        marginBottom: 0,
    },
    buttons: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    button: {
        // marginLeft: styling.spacing,
        marginLeft: 5,
        marginBottom: 0,

    },
    cell: {
        paddingHorizontal: 5,
        paddingVertical: 10,
        justifyContent: 'center',
        minHeight: 60,
        overflow: 'hidden',
    },
    cellFirst: {
    },
    headers: {
        flexDirection: 'row',
        width: '100%',
        flex: 1,
        alignItems: 'center',
        height: '100%',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#ccc',
        overflow: 'hidden',
    },
    header: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerLabel: {
        flex: 1,
        textOverflow: 'ellipsos' as any,
    },
    body: {
        overflow: 'hidden',
    },
    footer: {
        overflow: 'hidden',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#ccc',
    },
    content: {
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 40,
    },
    pagination: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    pinfo: {
        marginHorizontal: 5,
    },
    pbutton: {
        marginBottom: 0,
    },
    info: {
    },
});