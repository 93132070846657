import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface ITeamEventCategoryDTO {
  id?: number;
  uuid?: string;
  teamId?: number;
  eventCategoryId?: number;
  team?: any;
  eventCategory?: any;
  status?: number;
}

const BASE_URL = BACKEND_URL + '/auth/3x3/teams'

/**
 * Recupera todos los equipos para el principal autorizado
 * @returns 
 */
export const getAll = (eventUuid: string = null) => {
  return RestService.get(BASE_URL + (eventUuid ? "?eventUuid=" + eventUuid : ""));
}

/**
 * Recupera equipo por id
 * @returns 
 */
export const findById = (id: number) => {
  return RestService.get(`${BASE_URL}/${id}`);
}

/**
 * Guarda un equipo
 * @param team 
 * @returns 
 */
export const save = (team: any, eventCategoryIdForStatus?: number) => {
  const url = BASE_URL + (eventCategoryIdForStatus ? '?eventCategoryIdForStatus=' + eventCategoryIdForStatus : '')
  return RestService.post(url, team);
}

/**
 * Crea un actor para un equipo
 * @param uuid 
 * @param actor 
 * @returns 
 */
export const createActor = (uuid: string, actor: any) => {
  return RestService.post(`${BASE_URL}/${uuid}/actors`, actor);
}

/**
 * Recupera los datos de la inscripción de un equipo
 * @param uuid 
 * @returns 
 */
 export const getTeamEventCategory = (uuid: string) => {
  return RestService.get(`${BASE_URL}/team-event-category/${uuid}`);
}

/**
 * Inscribe un equipo en una categoría
 * @param teamUuid 
 * @param eventCategoryId 
 * @param actors 
 * @returns 
 */
export const register = (teamUuid: string, eventCategoryId: string, actors: any[]) => {
  return RestService.post(`${BASE_URL}/register`, { team: { uuid: teamUuid }, eventCategory: { id: eventCategoryId }, actors });
}

/**
 * Inscribe un equipo en una categoría
 * @param teamUuid 
 * @param eventCategoryId 
 * @param actors 
 * @returns 
 */
export const registerByInvitation = (teamUuid: string, eventCategoryId: string) => {
  return RestService.post(`${BASE_URL}/register-by-invitation`, { team: { uuid: teamUuid }, eventCategory: { id: eventCategoryId }, actors: [] });
}

/**
 * Inicia el proceso de pago de la inscripción del equipo
 * @param uuid 
 * @returns 
 */
export const payment = (uuid: string) => {
  return RestService.post(`${BASE_URL}/team-event-category/${uuid}/payment`);
}

export const sendActorRegistrationMail = (teamUuid: string, uuid: string) => {
  return RestService.post(`${BASE_URL}/${teamUuid}/actors/${uuid}/send`);
}

export const deleteActor = (teamUuid: string, uuid: string) => {
  return RestService.delete(`${BASE_URL}/${teamUuid}/actors/${uuid}`); 
}

export const changeStatus = (teamEventCategory: ITeamEventCategoryDTO) => {
  return RestService.put(`${BASE_URL}/team-event-category/change-status`,teamEventCategory); 
}

export const changeEventCategory = (newEventCategoryId: any, teamEventCategory: ITeamEventCategoryDTO) => {
  return RestService.put(`${BASE_URL}/team-event-category/change-event-category/${newEventCategoryId}`,teamEventCategory); 
}