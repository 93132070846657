export const resetBorderColors = (calendar) => {
    // calendar.getEvents().forEach((event) => {
    //     event.setExtendedProp('borderColor', 'transparent');
    // })
    for (const event of calendar.getEvents()) {
        event.setExtendedProp('borderColor', 'transparent');
    }
}
export const isInside = (offsides, jsEvent) => {
    var x1 = offsides.left;
    var x2 = offsides.left + offsides.width;
    var y1 = offsides.top;
    var y2 = offsides.top + offsides.height * 4;

    return jsEvent.pageX >= x1 && jsEvent.pageX <= x2 && jsEvent.pageY >= y1 && jsEvent.pageY <= y2;
}

export const addBlockElements = (calendar, start, end) => {
    calendar.addEvent(
        /*{
            id: 'block-event',
            start: start,
            end: end,
            display: 'background',
            color: 'red',
            editable: false
        }*/
        {
            id: '_' + Math.random().toString(36).substr(2, 9),
            start: start,
            end: end,
            display: 'background',
            color: 'red',
            editable: false,
            groupId: 'blocked-elements'
        },
    )
}

export const removeBlockElements = (calendar) => {
    calendar.getEvents().forEach((event) => {
        /*if(event.id === 'block-event') {
            event.remove();
        }*/
        if (event.groupId === 'blocked-elements') {
            event.remove();
        }
    })
}