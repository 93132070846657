import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


export const RATE_TYPE_PERSON = 1;
export const RATE_TYPE_CLUB = 2;


export interface IRateDTO {
  id?: number;
  name?: string;
  description?: string;
  amount?: number;
  rateType?: number;
  eventCategoryId?: number;
  eventCategoryName?: string;
  eventId?: number;
  eventName?: string;
  deleted?: any;
}


const BASE_URL = BACKEND_URL + '/auth/rates'

  /**
   * 
   * @returns Devuelve todas las tasas que aplican a una categoria
   */
  export const findRatesForEventCategory = (eventCategoryUuid: string, rateType?: number) => {
    return RestService.get(`${BASE_URL}/by-event-category?eventCategoryId=${eventCategoryUuid}` + (!!rateType ? '&rateType=' + rateType : ''));
  }

/**
   * 
   * @returns Devuelve todas las tasas que aplican a un evento
   */
 export const findRatesForEvent = (eventUuid: string, rateType?: number) => {
  return RestService.get(`${BASE_URL}/by-event?eventId=${eventUuid}` + (!!rateType ? '&rateType=' + rateType : ''));
}


/**
   * 
   
   */
   export const deleteRate = (rateId: number) => {
    return RestService.delete(`${BASE_URL}/${rateId}`);
  }


  /**
   * 
   
   */
   export const create = (rate: IRateDTO) => {
    return RestService.post(`${BASE_URL}`, rate);
  }


   /**
   * 
   
   */
    export const update = (rate: IRateDTO) => {
      return RestService.put(`${BASE_URL}`, rate);
    }