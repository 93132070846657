export enum Type {
    LINK = 'link',
    TITLE = 'title',
    ICON = 'icon',
}

export interface ISidebar {
    elements: (ISidebarItemLink | ISidebarItemTitle)[];
    color?: any;
    backgroundColor?: any;
    selectedColor?: any;
    selectedBackgroundColor?: any;
    sidebarBackground?: any;
    style?: any;
    collapsable?: boolean;
    exact?: boolean;
}

export interface IBaseItem {
    id: number | string;
    type: Type;
    label?: string;
    to?: string;
    icon?: any;
    children?: (Partial<IBaseItem> | boolean)[];
    color?: any;
    backgroundColor?: any;
    selectedColor?: any;
    selectedBackgroundColor?: any;
    title?:string;
    exact?:boolean;
}
export interface ISidebarItemLink extends IBaseItem {
    // type: Type.LINK; // no sé per què no funciona... xd
    to: string;
}

export interface ISidebarItemTitle extends IBaseItem {
    type: Type.TITLE;
}