import dayjs from 'dayjs';
import { Button, Icon, Input, Picker, t } from 'owc-react-lib-common';
import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { useColors } from 'src/hooks/useColors';
import { MatchResult } from 'src/pages/organizer/events/components/MatchResult';
import { minifyTeamName } from 'src/utils/StringUtils';
import { Modal } from './Modal';
import { Text } from './Text';
import { View } from './View';

export const MatchCardMinimal = ({ id, roundNumber, round, roundDate, onUpdate, onUpdateCourtAndDate, onAddMatch, selectedTeam, courts }) => {
	const [resultModalVisible, setResultModalVisible] = React.useState(false);
	const [matchToUpdate, setMatchToUpdate] = React.useState({});
	const [highlightedTeam, setHighlightedTeam] = React.useState({});
	const [matchCallToUpdate, setMatchCallToUpdate] = React.useState({ homeScore: null, awayScore: null, matchCallId: null });
	const [courtAndDateModal, setCourtAndDateModal] = React.useState<any>();
	const colors = useColors('primary');

	/**
	 * set highlight team
	 */
	React.useEffect(() => {
		setHighlightedTeam(selectedTeam)
	}, [selectedTeam])

	/**
	 * set to update
	 */
	const setToUpdate = (match) => {
		setMatchToUpdate({ ...match, firstTeamName: match.homeTeam.name, secondTeamName: match.awayTeam.name });
		setMatchCallToUpdate({ matchCallId: match.matchCallId, homeScore: match.homeScore, awayScore: match.awayScore })
		setResultModalVisible(true)
	}

	/**
	 * on save
	 */
	const onSave = (e) => {
		onUpdate(e)
		setResultModalVisible(false)
	};

	/**
	 * open court and date modal
	 */
	const openCourtAndDateModal = React.useCallback((object: any) => {
		setCourtAndDateModal(object);
	}, []);

	/**
	 * render
	 */
	return (
		<View key={id} style={[styles.combination]}>
			<View flexDirection='row' justifyContent='space-between' alignItems='center' style={[{ padding: 8, backgroundColor: colors.main, width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10 }]}>
				<Text bold color={colors.text}>
					{`${t('admin.events.league.round')} ${roundNumber}`}
				</Text>

				<View flexDirection='row' alignItems='center' style={{ gap: 5 }}>
					{roundDate && <Text bold color={colors.text}>
						{`${roundDate}`}
					</Text>}

					<Icon type='add' onPress={onAddMatch} color={colors.text as any} />
				</View>
			</View>

			<View>
				{round?.map((match: any, i: number) => {
					return (
						<View key={match.matchCallId} style={{ borderStyle: 'solid', borderColor: '#ccc', borderBottomWidth: (round.length !== i + 1) ? 1 : 0 }}>
							<Hoverable>
								{({ hovered }) => (
									<TouchableOpacity onPress={() => setToUpdate(match)} style={[{ backgroundColor: hovered ? '#f6f6f6' : 'white', cursor: "pointer", borderBottomWidth: 1, borderBottomColor: '#f6f6f6' } as any]}>
										<View flexDirection='row' style={{ padding: 8 }}>
											<View justifyContent='center' style={[{ padding: 5, width: 50 }]}>
												<Text color={highlightedTeam === match.homeTeam?.id ? colors.accent : ''} textAlign='left' bold>{`${minifyTeamName(match.homeTeam?.name).toUpperCase() ?? 'Descanso'}`}</Text>
											</View>

											<View flexDirection='row' justifyContent='space-around' style={[{ padding: 5, flex: 1 }]}>
												<Text textAlign='center' style={{ flex: 1 }}>{match.homeScore}</Text>
												<Text>-</Text>
												<Text textAlign='center' style={{ flex: 1 }}>{match.awayScore}</Text>
											</View>

											<View justifyContent='center' style={[{ padding: 5, width: 50 }]}>
												<Text color={highlightedTeam === match.awayTeam?.id ? colors.accent : ''} textAlign='right' bold>{`${minifyTeamName(match.awayTeam?.name).toUpperCase() ?? 'Descanso'} `}</Text>
											</View>
										</View>
									</TouchableOpacity>
								)}
							</Hoverable>

							<View>
								<View flexDirection='row' alignItems='center'>
									<Icon type='ball' containerStyle={{ width: 50 }} marginRight={0} onPress={() => openCourtAndDateModal(match)} />
									<Text fontSize={11} style={{ flex: 1 }}>{courts.find((court: any) => parseInt(match.courtId) === parseInt(court.id))?.name || '-'}</Text>
									<View style={{ width: 50 }} />
								</View>

								<View flexDirection='row' alignItems='center'>
									<Icon type='clock' containerStyle={{ width: 50 }} marginRight={0} onPress={() => openCourtAndDateModal(match)} />
									<Text fontSize={11} style={{ flex: 1 }}>{match.matchDateHour ? dayjs(match.matchDateHour).format('DD/MM/YYYY HH:mm') : '-'}</Text>
									<View style={{ width: 50 }} />
								</View>
							</View>
						</View>
					)
				})}
			</View>

			<Modal maxWidth={500} minHeight={150} header title={t('admin.events.knockoff.addResult')} visible={resultModalVisible} onClose={() => setResultModalVisible(false)}>
				<MatchResult match={matchToUpdate} matchCall={matchCallToUpdate} teams={[]} onSave={onSave} courts={courts} />
			</Modal>

			<Modal visible={!!courtAndDateModal} onClose={() => setCourtAndDateModal(undefined)} maxWidth={350}>
				<Picker items={courts.map((court: any) => ({ label: court.name, value: court.id }))} defaultValue={courtAndDateModal?.courtId} onChange={(value: any) => setCourtAndDateModal({ ...courtAndDateModal, courtId: parseInt(value) })} />
				<Input placeholder='DD/MM/YYYY HH:mm' defaultValue={!!courtAndDateModal?.matchDateHour ? dayjs(courtAndDateModal.matchDateHour).format('DD/MM/YYYY HH:mm') : ''} onChange={(value: string) => setCourtAndDateModal({ ...courtAndDateModal, matchDateHourValue: value })} />
				<Button text={t('global.save')} onPress={async () => {
					await onUpdateCourtAndDate(courtAndDateModal)
					setCourtAndDateModal(undefined)
				}} marginBottom={0} />
			</Modal>
		</View>
	)

}

const styles = StyleSheet.create({
	combination: {
		borderColor: '#dbdbdb',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 10,
		// padding: 8,
		display: 'flex',
		shadowColor: 'black',
		shadowOffset: { width: -2, height: 4 },
		shadowOpacity: 0.2,
		shadowRadius: 3,
	}
});