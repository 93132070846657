import * as React from 'react';
import { t } from 'owc-react-lib-common';

import { StyleSheet } from "react-native";
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { Table } from "src/components/Table";
import { Text } from 'src/components/Text';
import { View } from "src/components/View";
import { useAlerts } from 'src/hooks/useAlerts';
import * as LicenceUtils from 'src/utils/LicenceUtils';
import { GENDER_NON_BINARY } from './config';
import { ActorCreationModal } from './ActorCreationModal';

interface IEventActors {
  category: any;
  sizes: any[];
  roles: any[];
  back: () => void;
  onSuccess: () => void;
  data: any[];
  onSaveActor: (object: any) => Promise<void>;
  onDeleteActor: (object: any) => Promise<void>;
  update?: boolean;
}

export const TeamCreationActors = ({ category, data = [], sizes, roles, back, onSuccess, update = false, onSaveActor, onDeleteActor }: IEventActors) => {

  const alerts = useAlerts();
  // modals
  // const [visible, setVisible] = React.useState<any>(false);
  const [actorToDelete, setActorToDelete] = React.useState<any>();
  const [actor, setActor] = React.useState<any>(undefined);

  /**
   * open modal
   */
  const openModal = React.useCallback(() => {
    setActor({});
    // setRole(undefined);
    // setErrors({});
    // setVisible(true);
  }, []);

  /**
   * open edit modal
   */
  const openEditModal = React.useCallback((object: any) => {
    setActor({ ...object });
    // setRole(parseInt(object.roleId));
    // setErrors({});
    // setVisible(true);
  }, []);

  /**
   * close modal
   */
  const closeModal = React.useCallback(() => {
    setActor(undefined);
    // setRole(undefined);
    // setErrors({});
    // setVisible(false);
  }, []);

  /**
   * open delete modal
   */
  const openDeleteModal = React.useCallback((object: any) => {
    setActorToDelete(object);
  }, []);

  /**
   * close delete modal
   */
  const closeDeleteModal = React.useCallback(() => {
    setActorToDelete(undefined);
  }, []);

  /**
   * delete actor
   */
  const deleteActor = React.useCallback(async () => {
    try {
      // on delete actor
      await onDeleteActor(actorToDelete);

      // close modal
      closeDeleteModal();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [actorToDelete, closeDeleteModal, onDeleteActor, alerts]);

  /**
   * actions
   */
  const actions = React.useCallback((object: any) => {
    return (
      <View style={styles.actions}>
        <IconButton backgroundColor={'transparent'} color={'black'} name='edit' onPress={() => openEditModal(object)} />
        <IconButton backgroundColor={'transparent'} color={'black'} name='trash' onPress={() => openDeleteModal(object)} />
      </View>
    );
  }, [openEditModal, openDeleteModal]);

  /**
   * next
   */
  const next = React.useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  /**
   * render actor gender
   */
  const renderActorGender = React.useCallback((object: any) => {
    if (object.gender === GENDER_NON_BINARY) {
      return 'NB';
    }

    return object.gender;
  }, []);
  /**
   * render
   */
  const headers = [
    { name: 'peopleName', label: t('auth.competitions.teamCreation.actors.name'), render: (object: any) => <Text>{object.peopleName} {object.peopleSurname}</Text> },
    // { name: 'peopleSurname', label: t('auth.competitions.teamCreation.actors.surname') },
    { name: 'gender', label: t('auth.competitions.teamCreation.actors.gender'), render: (object: any) => <Text>{renderActorGender(object)}</Text> },
    { name: 'peopleEmail', label: t('auth.competitions.teamCreation.actors.email') },
    { name: 'formattedBirthDate', label: t('auth.competitions.teamCreation.actors.birthDate') },
    { name: 'roleDescription', label: t('auth.competitions.teamCreation.actors.role') },
    { name: 'number', label: t('auth.competitions.teamCreation.actors.number'), render: (object: any) => <Text>{parseInt(object.number) === 100 ? '00' : object.number}</Text> },
    { name: 'shirtSize', label: t('auth.competitions.teamCreation.actors.size') },
    { name: 'rateName', label: t('auth.competitions.teamCreation.actors.rate') },
    { name: 'price', label: t('auth.competitions.teamCreation.actors.price'), render: (object: any) => <Text>{LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data)} €</Text> },
    { name: 'actions', label: t('global.actions'), render: actions },
  ];
  return (
    <View>
      <Alert type='info' text={t('auth.competitions.teamCreation.actors.step')} />

      <View>
        <Button label={t('auth.competitions.teamCreation.actors.add')} onPress={openModal} />
      </View>

      <Table
        pagination={false}
        headers={headers}
        data={data}
        defaultSize={25}
      />

      {!update && (
        <View flexDirection='row' justifyContent='space-between'>
          <Button type='primary' label={t('global.back')} onPress={back} />
          <Button label={t('global.next')} onPress={next} />
        </View>
      )}

      {!!actor && (
        <ActorCreationModal
          close={closeModal}
          category={category}
          actor={actor}
          data={data}
          onSave={onSaveActor}
          shirtSizes={sizes}
          roles={roles}
        />
      )}

      <ModalConfirm
        visible={!!actorToDelete}
        title={t('global.delete')}
        onSuccess={deleteActor}
        onClose={closeDeleteModal}
        text={t('auth.competitions.teamCreation.actors.deleteConfirmation')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  actions: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
});