import React from 'react';
import { Animated, StyleSheet, TouchableOpacity } from "react-native";
import { Hoverable } from 'react-native-web-hover';
import { useColors } from 'src/hooks/useColors';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { View } from '../View';

export const SimpleAccordion = ({ title, icon, backgroundColor = '', children, defaultOpen = true, startHeight = 0 }) => {
	const [showContent, setShowContent] = React.useState(defaultOpen);
	const [animation] = React.useState(new Animated.Value(startHeight))
	const animationController = React.useRef(new Animated.Value(showContent ? 1 : 0)).current;
	const colors = useColors('primary');

	React.useEffect(() => {
		if (showContent) {
			animation.setValue(40 * children.length);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children, animation])

	const rotateConfig = {
		duration: 300,
		toValue: showContent ? 0 : 1,
		useNativeDriver: true,
	};

	const slideConfig = {
		// 40px for each children
		toValue: showContent ? 0 : 40 * children.length,
		duration: 300,
		useNativeDriver: true
	}

	const toggleListItem = () => {
		setShowContent(!showContent);

		Animated.timing(animationController, rotateConfig).start();

		Animated.timing(animation, slideConfig).start(() => {
			// 40px for each children
			animation.setValue(showContent ? 0 : 40 * children.length);
		});
	};

	const arrowTransform = animationController.interpolate({
		inputRange: [0, 1],
		outputRange: ['0deg', '90deg'],
	});

	return (
		<View style={{ backgroundColor }}>
			<View style={{ overflow: 'hidden'/*, borderWidth: 2, borderColor: '#eee'*/ }}>
				<Hoverable>
					{({ hovered }) => (
						<TouchableOpacity style={[styles.rowcenter, { backgroundColor: hovered && colors.light, borderRadius: 5 }]} onPress={toggleListItem}>
							<View flexDirection='row' alignItems='center'>
								<Icon name={icon} />
								<Text style={{ marginTop: 2 }}>{title}</Text>
							</View>

							<Animated.View style={{ transform: [{ rotateZ: arrowTransform }] }}>
								<Icon name={'arrowright'} fontSize={12} style={{ marginRight: 0 }} />
							</Animated.View>
						</TouchableOpacity>
					)}
				</Hoverable>

				<Animated.View style={[styles.list, { height: animation }]}>
					{children}
					{/* <View style={{ position: 'absolute', left: 5, top: '5%', width: 1, height: '90%', backgroundColor: colors.main, pointerEvents: 'none' } as any} /> */}
				</Animated.View>
			</View>
		</View>
	)
}
/**
 * styles
 */
const styles = StyleSheet.create({
	rowcenter: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 8,
		minHeight: 50,
	},
	hidden: {
		height: 0,
	},
	list: {
		overflow: 'hidden'
	},
});
