import * as React from "react";
import { Button } from "src/components/Button";
import { View } from "src/components/View";
import { MatchCardMinimal } from "src/components/MatchCardMinimal";
import { Modal } from "src/components/Modal";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { InputDate } from "src/components/InputDate";
import { t } from "owc-react-lib-common";
import { CreateMatchModal } from "./CreateMatchModal";
import { useEffectChange } from "src/hooks/useEffectChange";
import * as CourtService from "src/services/CourtService";
import { EventContext } from "../EventContext";


export const ManualLeague = ({ teams, groupId, onSave }) => {
    const { data: event } = React.useContext(EventContext);
    const [courts, setCourts] = React.useState([]);
    const [rounds, setRounds] = React.useState([]);
    const [roundtoUpdate, setRoundToUpdate] = React.useState(null);
    const [roundNumber, setRoundNumber] = React.useState(1);
    const [roundModalVisible, setRoundModalVisible] = React.useState(false);
    const [matchModalVisible, setMatchModalVisible] = React.useState(false);
    const form = React.useRef<any>();

    useEffectChange(async () => {
        const response = await CourtService.getCourtsByEventUuid(event.uuid);
        setCourts(response.data);
    }, [])



    const addRound = React.useCallback(() => {
        setRounds([...rounds, { roundDate: form.current.roundDate, roundNumber: roundNumber, matches: [] }]);
        setRoundNumber(roundNumber + 1);
        setRoundModalVisible(false);
    }, [rounds, roundNumber])

    const addMatch = React.useCallback((payload) => {
        const matchess = [
            ...roundtoUpdate?.matches,
            {
                ...payload,
                roundNumber: payload.roundId,
                roundId: null,
                homeTeam: teams.find((team) => parseInt(team.id) === parseInt(payload.homeTeamId)),
                awayTeam: teams.find((team) => parseInt(team.id) === parseInt(payload.awayTeamId))
            }
        ]
        setMatchModalVisible(false);

        const others = rounds.filter(round => round.roundNumber !== payload.roundId);
        const toupdate = rounds.find(round => round.roundNumber === payload.roundId);

        setRounds([...others, { ...toupdate, matches: matchess }])

    }, [roundtoUpdate, rounds, teams])

    const updateMatch = React.useCallback((payload) => {

        // const toupdate = rounds.find(round => round.roundNumber === payload.roundNumber);

        const totalMatches = roundtoUpdate?.matches.filter(match => match.awayTeamId !== payload.awayTeamId && match.homeTeamId !== payload.homeTeamId);

        const matchess = [
            ...(totalMatches) ? totalMatches : [],
            {
                ...payload,
                matchDateHour: payload.matchDateHourValue,
                homeTeam: teams.find((team) => parseInt(team.id) === parseInt(payload.homeTeamId)),
                awayTeam: teams.find((team) => parseInt(team.id) === parseInt(payload.awayTeamId)),
            }
        ]

        setMatchModalVisible(false);
        const others = rounds.filter(round => round.roundNumber !== roundtoUpdate.roundNumber);
        setRounds([...others, { ...roundtoUpdate, matches: matchess }])

    }, [rounds, teams, roundtoUpdate])

    return (
        <React.Fragment>
            <Button onPress={() => setRoundModalVisible(true)} label={t('admin.events.league.addRound')} />
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {rounds.map((round, i) => {
                    return (
                        <View key={i} style={{ width: 290, padding: 10 }}>
                            <MatchCardMinimal
                                id={i}
                                roundNumber={i + 1}
                                round={round.matches}
                                roundDate={round.roundDate}
                                onUpdate={() => { }}
                                onUpdateCourtAndDate={(payload) => {
                                    setRoundToUpdate(round)
                                    updateMatch(payload)
                                }}
                                selectedTeam={{}}
                                courts={courts}
                                onAddMatch={() => {
                                    setMatchModalVisible(true);
                                    setRoundToUpdate(round)
                                }}
                            />
                        </View>
                    )
                })}
            </View>
            <Button onPress={() => {
                const payload = [];
                const copy = [...rounds];
                copy.forEach(round => {
                    const matches = round.matches.map(match => {
                        return {
                            homeTeam: match.homeTeamId,
                            awayTeam: match.awayTeamId,
                            roundNumber: match.roundNumber,
                            gameDate: match.gameDate ?? null,
                            courtId: match.courtId ?? null
                        };
                    })
                    payload.push({roundNumber: round.roundNumber, matches: matches, groupId: groupId, startDate: round.roundDate});

                })
                onSave(payload) 

            }} label={t('global.save')} />
            <Modal visible={roundModalVisible} onClose={() => setRoundModalVisible(false)}>
                <Form>
                    <Row>
                        <InputDate
                            label={t('admin.events.league.startDateFirstRound')}
                            useTime
                            internalFormat="YYYY-MM-DD HH:mm:ss"
                            onChange={(val) => (form.current = { ...form.current, roundDate: val })}
                        />
                    </Row>
                    <Row>
                        <Button onPress={addRound} label={t('global.save')} ></Button>
                    </Row>
                </Form>
            </Modal>
            <Modal visible={matchModalVisible} onClose={() => { setMatchModalVisible(false) }} title={t('admin.events.team.createMatch')} >
                <CreateMatchModal teams={teams} courts={courts} roundId={roundtoUpdate?.roundNumber} groupId={groupId} onSave={addMatch} onCancel={() => { setMatchModalVisible(false) }} />
            </Modal>
        </React.Fragment>
    )
}
