import * as React from 'react';
import { ColorValue, StyleSheet } from "react-native";
import { styling } from "src/config";
import { useColors } from "src/hooks/useColors";
import { Text } from "./Text";
import { View } from "./View";

interface ISeparator {
  text?: string;
  children?: any;
  color?: ColorValue;
  marginTop?: number;
  marginBottom?: number;
}

export const Separator = ({ text, children, color, marginTop = 0, marginBottom = styling.spacing }: ISeparator) => {
  const colors = useColors('primary');

  return (
    <View style={[styles.container, { marginTop, marginBottom, backgroundColor: color || colors.main }]}>
      {!!(text || children) && (
        <View style={styles.content}>
          <Text color={colors.text} fontSize={18} uppercase bold>{text}</Text>
          {children}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 1,
    width: '100%',
  },
  content: {
    padding: styling.spacing,
    backgroundColor: 'transparent',
  }
});