import React, { useState, useEffect, useContext } from 'react';
import { Picker, t } from "owc-react-lib-common";
import { View } from 'src/components/View';
import { Container } from 'src/components/Container';
import { Text } from 'src/components/Text';
import { AjaxTable } from 'src/components/AjaxTable/AjaxTable';
import { Page } from 'src/services/entities/Page';
import { Loader } from 'src/components/Loader';
import { getOrganizers } from 'src/services/OrganizerService';
import { useAlerts } from 'src/hooks/useAlerts';
import { BaseUserMask, getBaseUsers, getRegisterStatus, setOrganizer, updateReadonly } from 'src/services/BaseUserService';
import { Checkbox } from 'src/components/Checkbox';
import { ITableHeader } from 'src/components/AjaxTable/Header';
import { IconButton } from 'src/components/IconButton';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { useNavigate } from 'react-router-dom';

const DEFAULT_PAGE_ITEMS = 10;
const DEFAULT_PAGE = 0;
const UsersList = () => {
    const alerts = useAlerts();
    const navigate = useNavigate();
    const context = useContext(OWCOpenDmrContext);
    const [baseUsersPage, setBaseUsersPage] = useState<Page<BaseUserMask>>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageItems, setPageItems] = useState<number>(DEFAULT_PAGE_ITEMS);
    const [page, setPage] = useState<number>(DEFAULT_PAGE);
    const [sort, setSort] = useState<string>(null);
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">(null);
    const [searchString, setSearchString] = useState<string>(null);
    const [organizers, setOrganizers] = useState<any[]>([]);
    const [selectedOrganizer, setSelectedOrganizers] = useState<any>();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const [organizers] = await Promise.all([getOrganizers(), fetchUsers(DEFAULT_PAGE, DEFAULT_PAGE_ITEMS)]);
            setOrganizers(organizers.map(o => ({ label: o.name, value: o.id })));
            setLoading(false);
        })()
    }, []); // Run once when the component mounts

    const changePage = (pageNumber) => {
        if (pageNumber === page) return;
        setPage(pageNumber);
        fetchUsers(pageNumber, pageItems, sort, sortDirection, searchString, selectedOrganizer);
    }

    const changeSize = (size) => {
        if (size === pageItems) return;
        setPageItems(size);
        setPage(0);
        fetchUsers(0, size, sort, sortDirection, searchString, selectedOrganizer);
    }

    const onSort = (sort: string, direction: "ASC" | "DESC") => {
        setSort(sort);
        setSortDirection(direction);
        fetchUsers(page, pageItems, sort, direction, searchString, selectedOrganizer);
    }

    const onSearch = (searchString) => {
        setPage(0);
        setSearchString(searchString);
        fetchUsers(0, pageItems, sort, sortDirection, searchString, selectedOrganizer);
    }

    const onFindOrganizer = (organizerId) => {
        setPage(0);
        setSelectedOrganizers(organizerId);
        fetchUsers(0, pageItems, sort, sortDirection, searchString, organizerId);
    }

    const fetchUsers = async (pageNumber = page, numberOfElements = pageItems, sortItem = sort, sortDirection_ = sortDirection, searchString_ = searchString, selectedOrganizer = null): Promise<void> => {
        try {
            const baseUsers = await getBaseUsers(pageNumber, numberOfElements, sortItem, sortDirection_, searchString_, selectedOrganizer); // Adjust page and size as needed
            setBaseUsersPage(baseUsers);
        } catch (error) {
            alerts.createDSMError(error);
        }
    }

    const changeBaseUserOrganizer = async (userId: number, organizerId: number) => {
        try {
            await setOrganizer(userId, organizerId);
            alerts.create({ text: t("global.success"), type: "success" });
        } catch (error) {
            alerts.createDSMError(error);
        }

    }

    const changeBaseUserReadOnly = async (user: BaseUserMask) => {
        try {
            await updateReadonly(user.id);
            alerts.create({ text: t("global.success"), type: "success" });
        } catch (error) {
            alerts.createDSMError(error);
        }
    }

    /**
     * render
     */
    const headers: ITableHeader<BaseUserMask>[] = [
        {
            name: "id",
            label: t("admin.users.list.id"),
            width: 50
        },
        {
            name: "email",
            label: t("admin.users.list.email"),

        },
        {
            name: "registerStatus",
            label: t("admin.users.list.registerStatus"),
            render: e => {
                return (
                    <View>
                        <Text>{getRegisterStatus(e.registerStatus)}</Text>
                    </View>
                )
            },
            width: 80
        },
        {
            name: "organizerName",
            label: t("admin.users.list.organizer"),
            render: (e) => {
                return (
                    <View>
                        <Picker key={e.uuid} defaultValue={e.organizerId} marginBottom={0} items={organizers} onChange={(v) => { changeBaseUserOrganizer(e.id, v) }} defaultOptionLabel={t("admin.users.list.organizerNotSelected")} />
                    </View>
                )
            }
        },
        {
            name: "organizerReadOnly",
            label: t("admin.users.list.organizerReadOnly"),
            render: (e) => {
                return (
                    <Checkbox key={e.uuid} marginBottom={0} value={e.organizerReadOnly} defaultValue={e.organizerReadOnly} onChange={() => changeBaseUserReadOnly(e)} />
                )
            },
            width: 100
        },
        {
            name: "actions",
            label: t("admin.users.list.logs"),
            render: (e) => {
                return (
                    <IconButton
                        backgroundColor={"white"}
                        color={context.configuration.colors.primary.main}
                        name={'eye'}
                        onPress={() => navigate(e.uuid)}
                    />
                )
            },
            width: 100
        }

    ];

    const renderOrganizerFilter = () => {
        return (
            <Picker
                containerStyle={{ paddingLeft: 5 }}
                items={organizers}
                onChange={o => onFindOrganizer(o)}
            />
        )
    }

    if (loading) {
        return (<Loader />)
    }
    return (
        <Container title={t("admin.users.list.title")}>
            <AjaxTable
                data={baseUsersPage}
                headers={headers}
                defaultSize={pageItems}
                changePage={changePage}
                changeSize={changeSize}
                onSort={onSort}
                search={onSearch}
                additionalFilters={() => renderOrganizerFilter()}
            />
        </Container>
    );
};

export default UsersList;
