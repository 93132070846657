import * as React from 'react'
import { t, useComponentDidMount } from 'owc-react-lib-common';
import { Container } from 'src/components/Container';
import { View } from 'src/components/View';
import { ITableHeader, Table } from 'src/components/Table';
import { Loader } from 'src/components/Loader';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/IconButton';
import { ORGANIZER_READ_ONLY } from 'src/context/OWCOpenDmrContext';
import { useUserPermission } from 'src/hooks/useUserPermission';
import { ICategory, deleteCategoryEntity, findOrganizerCategories } from 'src/services/CategoryService';
import { Button } from 'src/components/Button';
import { useAlerts } from 'src/hooks/useAlerts';
import { Icon } from 'src/components/Icon';
import { ModalConfirm } from 'src/components/ModalConfirm';

export const CategoriesList = () => {
    const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
    const navigate = useNavigate();
    const alerts = useAlerts();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [categories, setCategories] = React.useState<ICategory[]>([]);
    const [categoryToDelete, setCategoryToDelete] = React.useState<ICategory>();

    useComponentDidMount(async () => {
        try {
            const response = await findOrganizerCategories();
            setCategories(response);

        } catch (error) {
            alerts.createDSMError(error);
        }
        setLoading(false);
    })

    const actions = React.useCallback((category: ICategory) => {
        return (
            <View style={{ flexDirection: "row" }}>
                <IconButton
                    backgroundColor={'transparent'}
                    color={'black'}
                    name={readOnly ? "eye" : "edit"}
                    onPress={() => navigate(`${category.uuid}`)}
                />
                {!readOnly && <IconButton
                    backgroundColor={'transparent'}
                    color={'black'} name="trash"
                    onPress={() => setCategoryToDelete(category)}
                />}


            </View>
        );
    }, [navigate, readOnly]);

    const deleteCategory = React.useCallback(async () => {
        try {
            await deleteCategoryEntity(categoryToDelete.id)
            setCategories(categories.filter(c => c.uuid !== categoryToDelete.uuid));
            alerts.create({ text: t("global.success"), type: "success" });
            setCategoryToDelete(null);
        } catch (error) {
            alerts.createDSMError(error);
        }
    }, [categoryToDelete, alerts, categories])

    // RENDER
    const headers: ITableHeader[] = [
        { name: "id", label: t("admin.categories.id"), width: 90 },
        {
            name: "name",
            label: t("admin.categories.name"),
            textProps: { uppercase: true },
            width: 200
        },
        {
            name: "minAge",
            label: t("admin.categories.minAge"),
            textProps: { uppercase: true },
        },
        {
            name: "maxAge",
            label: t("admin.categories.maxAge"),
            textProps: { uppercase: true },
        },
        {
            name: "maxPlayers",
            label: t("admin.categories.maxPlayers"),
            textProps: { uppercase: true },
        },
        {
            name: "minPlayers",
            label: t("admin.categories.minPlayers"),
            textProps: { uppercase: true },
        },
        {
            name: "maxCoaches",
            label: t("admin.categories.maxCoaches"),
            textProps: { uppercase: true },
        },
        {
            name: "gender",
            label: t("admin.categories.gender"),
            textProps: { uppercase: true },
        },
        {
            name: "name",
            label: t("admin.categories.name"),
            textProps: { uppercase: true },
        },
        {
            name: "visibleRecords",
            label: t("admin.categories.visibleRecords"),
            textProps: { uppercase: true },
            render: (o: ICategory) => {
                return (
                    <View>
                        {
                            o.visibleRecords
                                ? <Icon backgroundColor={"white"} color={"green"} name={"ok"} />
                                : <Icon backgroundColor={"white"} color={"red"} name={"ko"} />
                        }
                    </View>
                )
            }
        },
        {
            name: "withMatchRecords",
            label: t("admin.categories.withMatchRecords"),
            textProps: { uppercase: true },
            render: (o: ICategory) => {
                return (
                    <View>
                        {
                            o.withMatchRecord
                                ? <Icon backgroundColor={"white"} color={"green"} name={"ok"} />
                                : <Icon backgroundColor={"white"} color={"red"} name={"ko"} />
                        }
                    </View>
                )
            }
        },
        {
            name: "actions",
            label: "",
            render: actions,
            width: 90,
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <Container title={t("admin.categories.title")}>
            <Button label={t("admin.categories.create")} onPress={() => navigate(`create`)} disabled={readOnly} />
            <Table headers={headers} data={categories} />
            {categoryToDelete && (
                <ModalConfirm
                    visible
                    text={t('admin.categories.deleteConfirmation', { categoryName: categoryToDelete.name })}
                    onClose={() => setCategoryToDelete(null)}
                    onSuccess={deleteCategory}
                />
            )}
        </Container>
    )
}
