import * as React from 'react';

import { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref: any, updater: any) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        updater(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, updater]);
}

/**
 * Component that alerts if you click outside of it
 */
export const OutsideAlerter = ({ children, handleClick }: any) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleClick);



  return <div ref={wrapperRef}>{children}</div>;


}

