import { BACKEND_URL, MODALITY_5x5 } from '../config';
import { IRateDTO } from './RateService';
import * as RestService from './RestService';

export interface IEventCategoryDTO {
  competitionName?: any;
  id?: number;
  name?: string;
  minAge?: number;
  maxAge?: number;
  externalUuid?: string;
  maxPlayers?: number;
  maxCoaches?: number;
  categoryId?: number;
  eventId?: number;
  eventName?: string;
  categoryName?: string;
  gender?: string;
  rates?: IRateDTO[];
}

export interface IEventDTO {
  numNonPlayerBonusRate: number;
  rankingSystemId: number;
  startInscriptionDate: any;
  id?: number;
  uuid?: string;
  name?: string;
  beginDate?: any;
  endDate?: any;
  externalUuid?: string;
  competitionId?: number;
  competitionName?: string;
  tpvCode?:string;
  allowHotel?: boolean
  eventCategories?: IEventCategoryDTO[];
  deleted?: any;
  limitInscriptionDate?: any;
  description?: string;
}

const BASE_URL = BACKEND_URL + '/auth/events'

/**
 * 
 Devuelve un evento con todas las categorias de evento 
 */
export const findByUuid = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${eventUuid}`);
}

export const findDmrConfig = (eventCategoryId: number) => {
  return RestService.get(`${BASE_URL}/all-dmr-for-organizer/${eventCategoryId}`)
}

/**
 * 
 Devuelve un evento con todas las categorias de evento 
 */
 export const findVisibleByUuid = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${eventUuid}/visible`);
}

/**
 * Devuelve el listado de eventos según competition uuid
 */
export const findByCompetitionUuid = (competitionUuid: string) => {
  return RestService.get(`${BASE_URL}/competition-uuid/${competitionUuid}`)
}
/**
 * 
 Crea un evento en una categoria
 */
export const create = (event: IEventDTO) => {
  return RestService.post(`${BASE_URL}`, event);
}

/**
 * 
 Modifica un evento
 */
export const update = (event: IEventDTO) => {
  return RestService.put(`${BASE_URL}`, event);
}

/**
 * 
 Elimina un evento
 */
export const deleteEvent = (eventUuid: string) => {
  return RestService.delete(`${BASE_URL}/${eventUuid}`);
}

/**
  * 
  Devuelve todas las categorias de un evento
  */
export const findEventCategoriesforEvent = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${eventUuid}/event-categories`);
}


/**
 * 
 Elimina una event category
 */
export const deleteEventCategory = (eventId: number, eventCategoryId: number) => {
  return RestService.delete(`${BASE_URL}/${eventId}/event-categories/${eventCategoryId}`);
}

/**
 * 
 Crea una categoria en un evento
 */
export const createEventCategory = (eventCategory: IEventCategoryDTO) => {
  return RestService.post(`${BASE_URL}/event-categories`, eventCategory);
}

/**
* 
Modifica una categoria en un evento
*/
export const updateEventCategory = (eventCategory: IEventCategoryDTO) => {
  return RestService.put(`${BASE_URL}/event-categories`, eventCategory);
}

/**
 * Devuelve el listado de tallas de camiseta
 */
export const findShirtSizes = (uuid: string) => {
  return RestService.get(`${BASE_URL}/${uuid}/shirt-sizes`);
}

/**
 * Devuelve todos los eventos ens los que el usuario ha inscrito un equipo
 */
export const findAllWithTeams = () => {
  return RestService.get(`${BASE_URL}/user-events`);
}

/**
 * 
 * @param eventUuid 
 * @returns 
 */
export const requestExcel = (eventUuid: string, eventName: string) => {
  return RestService.requestFile(`${BASE_URL}/${eventUuid}/teams-summary-excel`, 'excel', `${eventName}.xlsx`, '_blank');
}

export const requestADExcel = (eventId: string, eventName: string) => {
  return RestService.requestFile(`${BASE_URL}/all-games-excel/${eventId}`, 'excel', `${eventName}.xlsx`, '_blank');
}

export const getActive = (modalityId = MODALITY_5x5) => {
  return RestService.get(`${BASE_URL}/active`, { modalityId });
}

export const getVisible = (modalityId = MODALITY_5x5) => {
  return RestService.get(`${BASE_URL}/visible`, { modalityId });
}

export const downloadCalendar = (eventUuid: any) => {
	return RestService.requestFile(`${BASE_URL}/download-calendar/${eventUuid}`, 'excel', `${eventUuid}.xlsx`, '_blank');
}

export const clone = (competitionUuid, request: any) => {
	return RestService.post(`${BASE_URL}/clone/${competitionUuid}`, request)
}