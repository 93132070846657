import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export const getByCategoryIdAndType = (categoryId: number, type: 1 | 2) => {
  return RestService.get(`${BACKEND_URL}/auth/events/event-categories/${categoryId}/register-questions?type=${type}`);
}

export const getByCompetitionUuid = (competitionUuid: string) => {
  return RestService.get(`${BACKEND_URL}/auth/register-questions/competition/${competitionUuid}`);
}

export const getByPortalId = (id: number) => {
  return RestService.get(`${BACKEND_URL}/auth/register-questions/portal/${id}`);
}

export const save = (option: any) => {
  if (!option.id) {
    return RestService.post(`${BACKEND_URL}/auth/register-questions`, option);
  }

  return RestService.put(`${BACKEND_URL}/auth/register-questions`, option);
}

export const deleteQuestion = (questionId: number) => {
  return RestService.delete(`${BACKEND_URL}/auth/register-questions/${questionId}`);
}