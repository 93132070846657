import * as React from "react";
import { StyleSheet, ViewProps } from "react-native";
import { View } from "../View";

// WARNING: THIS IS NOT PRETTY :-(

interface IRow {
  children: any;
  flex?: number[];
  containerStyle?: ViewProps["style"];
  rowStyle?: ViewProps["style"];
  isColumn?: boolean;
}

export const Row = ({ children, flex = [], containerStyle, isColumn, rowStyle }: IRow) => {
  const [width, setWidth] = React.useState<number>();

  const onLayout = React.useCallback(({ nativeEvent }) => {
    setWidth(nativeEvent.layout.width);
  }, []);

  children = Array.isArray(children) ? children : [children];
  children = children.filter((child: any) => !!child);
  const parts = children.reduce((acc, current, idx) => (acc += flex[idx] || 1), 0);

  isColumn = isColumn !== undefined ? isColumn : width <= 768;

  return (
    <View flexDirection={isColumn ? "column" : "row"} style={[styles.container, containerStyle]} onLayout={onLayout}>
      {children.map((child: any, idx: number) => (
        <View
          key={idx}
          style={[
            rowStyle,
            styles.element,
            { zIndex: children.length - idx },
            isColumn && { flex: 1 },
            flex?.[idx] !== null && !isColumn && { width: ((width - 6) / parts) * (flex[idx] || 1) },
          ]}
        >
          {child}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
    width: "100%",
    justifyContent: "space-between",
    // columnGap: 4,
    marginHorizontal: -3,
    flexWrap: "wrap",
  },
  element: {
    // flex: 1,
    // borderWidth: 1,
    // borderColor: 'red',
    // borderLeftWidth: 4,
    paddingHorizontal: 3,
  },
});
