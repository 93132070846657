import dayjs from 'dayjs';
import { i18n, OWCContextProvider, t, useComponentDidMount } from 'owc-react-lib-common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAlert } from 'src/components/Alert';
import { Loader } from 'src/components/Loader';
import { View } from 'src/components/View';
import { ENVIRONMENT, VERSION } from 'src/config';
import { getHealth, IHealth } from 'src/services/HealthService';
import * as RestService from 'src/services/RestService';
import * as UserService from 'src/services/UserService';
import * as AsyncStorageUtils from 'src/utils/AsyncStorageUtils';
import { deepMerge } from 'src/utils/ObjectUtils';
import useCachedResources from '../hooks/useCachedResources';
import { IOWCOpenDmrContext, IUser, OWCOpenDmrContext } from './OWCOpenDmrContext';

interface IOWCOpenDmrProviderRefs {
  alerts: IAlert[];
};

interface IOWCOpenDmrProvider {
  children: any;
};

export const OWCOpenDmrProvider = ({ children }: IOWCOpenDmrProvider) => {
  const data = useCachedResources();
  const refs = React.useRef<IOWCOpenDmrProviderRefs>({ alerts: [] });
  const [user, setUser] = React.useState<IUser>();
  const [alerts, setAlerts] = React.useState<IAlert[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [, setLanguage] = React.useState('');
  const [health, setHealth] = React.useState<IHealth>();
  const navigate = useNavigate();

  useComponentDidMount(async () => {
    const response = await getHealth();
    setHealth(response);
  });

  /**
   * on load data
   */
  React.useEffect(() => {
    if (data?.loaded) {
      setUser(data?.user);
      setLoading(false);

    }
  }, [data]);

  /**
   * delete alert
   */
  const deleteAlert = React.useCallback((id?: number | string) => {
    if (!id) {
      return;
    }

    refs.current.alerts = refs.current.alerts.filter((alert: IAlert) => alert.id !== id);
    setAlerts(refs.current.alerts);
  }, []);

  /**
   * create alert
   */
  const createAlert = React.useCallback((data: IAlert) => {
    const alert = { id: Math.random(), ...data };
    refs.current.alerts = [...refs.current.alerts, alert];
    setAlerts(refs.current.alerts);
    setTimeout(() => deleteAlert(alert.id), 5000);
  }, [deleteAlert]);

  /**
   * loading
   */
  if (!data || loading) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Loader />
      </View>
    )
  }

  const defaultFonts: any = {
    body: {
      fontSize: 13,
      fontFamily: 'Inter var, Verdana',
    },
  }

  const defaultColorConfig: any = {
    default: {
      main: '#fff',
      light: '#fff',
      dark: '#fafafa',
      accent: '#333',
      text: '#333',
    },
    primary: {
      main: '#0f172a',
      light: '#eee',
      dark: '#0f172a',
      accent: '#eee',
      text: '#fff',
    },
    secondary: {
      main: 'orange',
      light: '#eee',
      dark: '#eee',
      accent: '#fff',
      text: 'orange',
    },
    error: {
      main: 'crimson',
      light: 'lightred',
      dark: 'darkred',
      accent: 'darkred',
      text: 'white',
    },
    success: {
      main: 'teal',
      light: 'lightgreen',
      dark: 'darkgreen',
      accent: 'darkgreen',
      text: 'white',
    },
  }

  const value: IOWCOpenDmrContext = {
    user,
    setUser: (user?: IUser) => {
      setUser(user);
      RestService.setDefaultHeader('Authorization', user?.jwt!);
      AsyncStorageUtils.setJwt(user?.jwt || '');
    },
    changeLanguage: (language: string) => {
      i18n.changeLanguage(language);
      UserService.changeLanguage(language);
      RestService.setDefaultHeader('Accept-Language', language);
      AsyncStorageUtils.setLanguage(language);
      setLanguage(language);
      dayjs.locale(language);
      // Cambiamos el titulo de la web
      document.title = t('global.registration') + " " + data.portal.name.toUpperCase();
    },
    alerts: {
      // items: alerts,
      create: createAlert,
      delete: deleteAlert,
    },
    alertItems: alerts,
    configuration: {
      colors: deepMerge(JSON.parse(data.portal.colors), defaultColorConfig),
      portal: data.portal
    },
    shoppingCart: data.shoppingCart
  };


  /**
 * context value
 */
  const defaultColors: any = {
    primary: { background: value.configuration.colors.primary.main, text: value.configuration.colors.primary.text },
    secondary: { background: value.configuration.colors.secondary.main, text: 'white' },
    link: { background: 'transparent', text: '#0f172a' },
    dark: { background: 'transparent', text: '#4e575d' },
  };

  /**
   * render
   */
  return (
    <OWCContextProvider defaultColors={defaultColors} defaultFonts={defaultFonts} navigate={navigate}>
      <OWCOpenDmrContext.Provider value={value}>
        {children}

        {/* @ts-ignore:next-line*/}
        {ENVIRONMENT !== 'prod' && <div className='ribbon'>{ENVIRONMENT}</div>}

        <div className='version'>
          <div>Version Frontend: {VERSION}</div>
          <div>Version Backend: {health.version}</div>
        </div>
      </OWCOpenDmrContext.Provider>
    </OWCContextProvider>
  );
}