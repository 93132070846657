import { t } from "owc-react-lib-common";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Form } from "src/components/Form";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { Row } from "src/components/Row/Row";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { EMAIL, IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as OrganizerService from "src/services/OrganizerService";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useColors } from "src/hooks/useColors";
import { ButtonLink } from "src/components/ButtonLink";

export const OrganizerCreate = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const colors = useColors('primary');
  const { validateAll } = useValidation();

  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);

  const form = React.useRef<any>({});

  useComponentDidMount(async () => {
    try {
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "contactEmail", type: [REQUIRED, EMAIL] },
    ];

    const { errors, hasErrors } = validateAll(form.current, fields);
    setErrors(errors);
    return !hasErrors;
  }, [validateAll]);

  const onChangeLogo = React.useCallback((value: IImageUploaderData) => {
    form.current.logoFile = value.base64;
    form.current.originalLogoFilename = value.name;
  }, [form]);

  const onCreate = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      await OrganizerService.create({ ...form.current });
      alerts.create({ text: t("global.success"), type: "success" });
      navigate("/admin/organizers");
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validate, navigate, form]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container title={t("admin.organizer.list.title")}>
      <View flexDirection="row">
        <Title text={t("admin.organizer.list.createOrganizer")} containerStyle={{ flex: 1 }} />
        <ButtonLink
          type='link'
          color={colors.main}
          to={`/admin/organizers`}
          label={`« ${t("admin.organizer.list.title")}`}
          contentStyle={{ right: 15 }}
        />
      </View>

      <Form>

        <Row flex={[2, 1, 1]}>
          <Input
            label={t("admin.organizer.edit.name")}
            onChange={(val) => (form.current.name = val)}
            error={errors.name}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.organizer.edit.contactEmail")}
            onChange={(val) => (form.current.contactEmail = val)}
            error={errors.contactEmail}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.organizer.edit.phoneNum")}
            onChange={(val) => (form.current.phoneNum = val)}
            disabled={readOnly}
          />
        </Row>

        <Row flex={[1, 1, 2]}>
          <Input
            label={t("admin.organizer.edit.cif")}
            onChange={(val) => (form.current.cif = val)}
            disabled={readOnly}
          />
          <Input
            label={t("admin.organizer.edit.code")}
            onChange={(val) => (form.current.code = val)}
            disabled={readOnly}
            numeric
          />
          <Input
            label={t("admin.organizer.edit.description") + ` (${t('global.optional')})`}
            onChange={(val) => (form.current.description = val)}
            disabled={readOnly}
          />

        </Row>
        <Row>
          <ImageUploader label={t("admin.organizer.edit.selectLogo")} onChange={onChangeLogo} disabled={readOnly} />
        </Row>
        <Row>
          <Button label={t("global.create")} onPress={onCreate} disabled={readOnly} />
        </Row>
      </Form>
    </Container>
  );
};
