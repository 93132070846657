import dayjs from 'dayjs';
import { Icon, t } from 'owc-react-lib-common';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'src/components/Container';
import { Loader } from 'src/components/Loader';
import { ITableHeader, Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { View } from 'src/components/View';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import * as BaseUserService from 'src/services/BaseUserService';
import * as LoginLogService from 'src/services/LoginLogService';
import { LoginLog } from 'src/services/LoginLogService';
import * as OrganizerService from 'src/services/OrganizerService';
import { IBaseUserDTO } from 'src/services/UserService';

export const UserDetail = () => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [, setUser] = React.useState<IBaseUserDTO>();
    const [, setOrganizers] = React.useState<any[]>([]);

    const [loginLogs, setLoginLogs] = React.useState<LoginLog[]>([]);
    const { uuid } = useParams();

    useComponentDidMount(async () => {
        const userResponse = await BaseUserService.findByUuid(uuid);
        const logs = await LoginLogService.findByBaseUserUuid(uuid);
        const organizers = await OrganizerService.getOrganizers();
        setLoading(false);
        setUser(userResponse);
        setLoginLogs(logs);
        setOrganizers(organizers);
    });

    const headers: ITableHeader[] = [
        {
            name: "loginSuccess",
            label: t("admin.login.list.success"),
            width: 100,
            headerTextProps: { textAlign: 'center' },
            render: (e) => {
                return (
                    <View>
                        <Icon color={e.loginSuccess ? 'green' : 'red'} type={'flag'} />
                    </View>
                )
            }
        },
        {
            name: "baseUser.email",
            label: t("admin.login.list.email"),
            width: 300,
            textProps: { uppercase: true },
            render: (e) => { return (<Text>{e.baseUser.email}</Text>) }
        },
        {
            name: "loginMethod",
            label: t("admin.login.list.loginMethod"),
            flex: 1,
        },
        {
            name: "loginTime",
            label: t("admin.login.list.loginTime"),
            flex: 1,
            render: (e) => {
                const dayJsDate = dayjs(e.loginTime);
                return (<Text>{dayJsDate.format("DD-MM-YYYY")} {dayJsDate.format("HH:mm:ss")}</Text>)
            }
        }
    ];

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <Container title={t("admin.users.detail.title")}>
            {/* <Form>
                <Row>
                    <Input label={t("admin.users.detail.email")} value={user?.email} />
                    <Picker label={t("admin.users.detail.organizer")}
                        key={user.uuid}
                        defaultValue={user.organizerId}
                        marginBottom={0}
                        items={organizers}
                        onChange={(v) => { }}
                        defaultOptionLabel={t("admin.users.list.organizerNotSelected")}
                    />
                    <Input label={t("admin.users.detail.defaultLang")} value={user?.defaultLang} />
                </Row>
            </Form> */}
            <View>
                <Table headers={headers} data={loginLogs}
                />
            </View>
        </Container>
    )
}
