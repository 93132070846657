import axios from 'axios';

/**
 * 
 * @param key 
 * @param value 
 */
const setDefaultHeader = (key: string, value: string) => {
  axios.defaults.headers.common[key] = value;
}

/**
 * 
 * @param key 
 * @param value 
 */
const getDefaultHeader = (key?: string) => {
  if (key) {
    return axios.defaults.headers.common[key];
  } else {
    return axios.defaults.headers.common;
  }

}

/**
 * 
 * @param url 
 * @returns 
 */
const get = (url: string, params?: any) => {
  return axios.get(url, { params });
}

/**
 * 
 * @param url 
 * @param data 
 * @returns 
 */
const post = (url: string, data?: any) => {
  return axios.post(url, data);
}

/**
 * 
 * @param url 
 * @param data 
 * @returns 
 */
const put = (url: string, data?: any) => {
  return axios.put(url, data);
}

/**
 * 
 * @param url 
 * @returns 
 */
export const _delete = (url: string, params?: any) => {
  return axios.delete(url, params)
}

/**
 * 
 */
const requestPdf = (url: string, target = '_self', filename = 'file.pdf', method = 'GET', data = undefined) => {
  return requestFile(url, 'application/pdf', filename, target, method, data);
}

/**
 * 
 * @param url 
 * @param type 
 * @param target 
 * @param filename 
 * @param method 
 * @param data 
 */
const requestFile = async (url: string, type: string, filename: string = '', target = '_self', method = 'GET', data = undefined) => {
  let response;

  try {
    response = await axios.request({
      url,
      method: method.toUpperCase() as any,
      data,
      responseType: 'blob', // important
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
  } catch (error: any) {
    const text = await error?.response?.data?.text?.();
    const obj = { response: { data: JSON.parse(text) } }; // eslint workaround...
    throw obj;
  }

  const link = document.createElement('a');

  if (type === 'unknown' || '' === type.trim()) {
    type = response.headers?.['content-type'];  // si no tenemos header cogemos el que nos envía el back
    type = type === 'application/download' ? 'application/pdf' : type; // si el back no nos envía el formato..., entendemos que es pdf
  }

  // filename = filename || response.headers['Content-disposition'];

  link.href = window.URL.createObjectURL(new Blob([response.data], { type: type }));
  link.target = target;

  // esto solo lo haremos en caso de pdf
  if (type?.includes("pdf")) {
    // si es safari descargamos...
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      link.download = filename;
    }

    // si es android descargamos...
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      link.download = filename;
    }
  } else if (type?.includes("jpg") || type?.includes("jpeg") || type?.includes("JPEG") || type?.includes("JPG")) {
    // no lo descargamos, lo abrimos
  } else {
    link.download = filename;
  }

  link.click();
}

export const getTyped = async <T>(url: string, params?: any): Promise<T> => {
  try {
    const response = await axios.get<T>(url, { params });
    return response.data; // Extract the data property from the Axios response
  } catch (error) {
    throw error;
  }
};

export const putTyped = async <T>(url: string, params?: any): Promise<T> => {
  try {
    const response = await axios.put<T>(url, params);
    return response.data; // Extract the data property from the Axios response
  } catch (error) {
    throw error;
  }
};

export const postTyped = async <T>(url: string, params?: any): Promise<T> => {
  try {
    const response = await axios.post<T>(url, params);
    return response.data; // Extract the data property from the Axios response
  } catch (error) {
    throw error;
  }
};

export const deleteTyped = async <T>(url: string, params?: any): Promise<T> => {
  try {
    const response = await axios.delete<T>(url, params);
    return response.data; // Extract the data property from the Axios response
  } catch (error) {
    throw error;
  }
};

export { setDefaultHeader, getDefaultHeader, get, post, put, _delete as delete, requestPdf, requestFile };
