import { i18n, t } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet } from "react-native";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Checkbox } from "src/components/Checkbox";
import { Form } from "src/components/Form";
import { Input } from "owc-react-lib-common"
import { Row } from "src/components/Row/Row";
import { Text } from "src/components/Text";
import { styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useColors } from "src/hooks/useColors";
import { CUSTOM, EMAIL, PASSWORD, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as UserService from "src/services/UserService";
import { UserActionCard } from "./components/UserActionCard";

export const Register = () => {
  const alerts = useAlerts();
  const { validateAll } = useValidation();
  const colors = useColors("primary");
  const [success, setSuccess] = React.useState<string>();
  // form
  const [errors, setErrors] = React.useState<any>({});
  const form = React.useRef({
    username: "",
    password: "",
    password2: "",
  });

  const [termsOfService, setTermsOfService] = React.useState(false);
  const setUsername = React.useCallback((value: string) => (form.current.username = value), []);
  const setPassword = React.useCallback((value: string) => (form.current.password = value), []);
  const setPassword2 = React.useCallback((value: string) => (form.current.password2 = value), []);

  /**
   * go to login
   */
  // const goToLogin = React.useCallback(() => {
  // 	navigate('/login');
  // }, [navigate]);

  /**
   * register
   */
  const register = React.useCallback(async () => {
    // validate
    let { errors, hasErrors } = validateAll(form.current, [
      { name: "username", type: [REQUIRED, EMAIL] },
      { name: "password", type: [REQUIRED, PASSWORD] },
      {
        name: "password2",
        type: [REQUIRED, CUSTOM],
        validate: () => {
          if (form.current.password !== form.current.password2) {
            return t("errors.password.confirmation");
          }
        },
      },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // request
    try {
      await UserService.register(form.current.username, form.current.password, i18n.getLanguage());
      alerts.create({ text: t("public.users.register.success"), type: "success" });
      // goToLogin();
      setSuccess(t("public.users.register.success"));
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validateAll]);

  /**
   * render
   */
  const tabs = [
    { to: "/login", label: t("public.users.login.title") },
    { to: "/register", label: t("public.users.login.register") },
  ];

  return (
    <UserActionCard tabs={tabs}>
      {!!success && <Alert type="success" text={success} containerStyle={{ marginBottom: 0 }} />}

      <Form>
        <Row>
          <Input
            label={t("public.users.register.username")}
            onChange={setUsername}
            defaultValue={form.current.username}
            error={errors.username}
          />
        </Row>
        <Row>
          <Input
            label={t("public.users.register.password")}
            onChange={setPassword}
            defaultValue={form.current.password}
            error={errors.password}
            secureTextEntry
          />
        </Row>
        <Row>
          <Input
            label={t("public.users.register.passwordConfirmation")}
            onChange={setPassword2}
            defaultValue={form.current.password2}
            error={errors.password2}
            secureTextEntry
          />
        </Row>
        <Row isColumn={false} flex={[1, 9]}>
          <Checkbox onChange={setTermsOfService} value={termsOfService} />

          <Text>
            <a
              href={`https://owbasket-back-prod.s3.eu-west-1.amazonaws.com/privacy/${i18n.getLanguage()}.pdf`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: colors.main as string }}
            >
              {t("public.users.register.termsOfService")}
            </a>
          </Text>
        </Row>
        <Row>
          <Button
            label={t("public.users.register.register")}
            onPress={register}
            disabled={!termsOfService}
            containerStyle={styles.register}
          />
        </Row>
      </Form>
    </UserActionCard>
  );
};

const styles = StyleSheet.create({
  register: {
    marginRight: styling.spacing,
  },
  content: {
    paddingTop: styling.spacing,
  },
});

// function useTranslation(): { i18n: any; } {
// 	throw new Error('Function not implemented.');
// }
