import * as React from 'react';
import { IconButton } from "../IconButton"
import { View } from "../View"
import { Text } from 'src/components/Text';
import { t } from "owc-react-lib-common"
import { StyleSheet } from 'react-native';

interface ILeagueStepperTeams {
    data: any,
    colors: any,
    onChange: (value: number, increase: boolean) => void;
}

export const LeagueStepperTeams = ({ data, colors, onChange }: ILeagueStepperTeams) => {

    return (
        <View style={[styles.card]} >

            {/* TITLE */}
            <View style={{ height: 35 }}>
                <Text bold style={styles.cardTitle}>{t("admin.events.teams")}</Text>
            </View>

            <View flexDirection='row'>

                {/* ARROWS */}
                <View>
                    {data.map((team: any, i: number) => (
                        <View key={i} flexDirection='row' alignItems='center' justifyContent='center' style={{ height: 32 }}>
                            <View flexDirection='row' alignItems='center' >
                                <IconButton
                                    name='arrow-up-2'
                                    title={t('global.up')}
                                    color='black'
                                    backgroundColor='transparent'
                                    fontSize={18}
                                    onPress={() => onChange(team?.id, false)}
                                />
                                <IconButton
                                    name='arrow-down-2'
                                    title={t('global.down')}
                                    color='black'
                                    backgroundColor='transparent'
                                    fontSize={18}
                                    onPress={() => onChange(team?.id, true)}
                                />
                            </View>
                        </View>
                    ))}
                </View>
                {/* COLORED POSITION */}
                <View>
                    {data.map((_: any, i: number) => (
                        <View key={i} style={{ height: 32 }}>
                            <Text
                                key={i}
                                style={[styles.colorBox, { backgroundColor: colors.find((color: any) => color?.id === i + 1)?.hex ?? 'red' }]}
                            >{i + 1}</Text>
                        </View>
                    ))}
                </View>

                {/* TEAM NAME */}
                <View>
                    {data.map((team: any, i: number) => (
                        <View key={i} flexDirection='row' alignItems='flex-start' justifyContent='flex-start' style={{ height: 32 }}>
                            <Text bold>{team?.name.toUpperCase()}</Text>
                        </View>
                    ))}
                </View>

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    card: {
        borderColor: '#dbdbdb',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 10,
        padding: 8,
        display: 'flex',
        shadowColor: 'black',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        // width: 720,
        width: 'fit-content',
        overflow: 'hidden',
        marginBottom: 20
    },
    cardTitle: {
        padding: 5,
        backgroundColor: '#ffccaa',
        position: 'absolute',
        top: -12,
        left: -9,
        width: "150%",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    colorBox: {
        padding: 6,
        paddingHorizontal: 12,
        color: 'white',
        fontWeight: '800',
        marginRight: 15,
        borderRadius: 5,
    }
})
