export const useQueryParams = () => {
  const split = window.location.href.split('?');

  if(!split?.[1]) {
    return {};
  }

  const params: any = {};

  split[1].split('&').forEach((pair: string) => {
    const [key, value] = pair.split('=');
    params[key] = value;
  });

  return params;
};