import { t } from 'owc-react-lib-common';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Container } from "src/components/Container";
import { Loader } from "src/components/Loader";
import { Stepper } from 'src/components/Stepper';
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { MODALITY_3x3, styling } from "src/config";
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { Translation, usePortalTranslations } from "src/hooks/usePortalTranslations";
import * as ShirtSizeService from 'src/services/ShirtSizeService';
import * as TeamActorService from 'src/services/TeamActorService';
import { PublicNavbar } from '../PublicNavbar';

import {
  REGISTER_STATUS_COMPLETED, REGISTER_STATUS_CREATED, REGISTER_STATUS_PAYMENT_REJECTED, REGISTER_STATUS_PENDING_PAYMENT,
  REGISTER_STATUS_PENDING_QUESTIONS, REGISTER_STATUS_PENDING_TEXT_SIGNATURE, REGISTER_STATUS_PERSONAL_DATA, REGISTER_STATUS_TEXT_SIGNATURE_DECLINED
} from './IRegistrationProcess';
import { Step005 } from './Step005';
import { Step01 as Step010 } from './Step01';
import { Step02 as Step020 } from './Step02';
import { Step04 as Step040 } from './Step04';
import { Step07 as Step070 } from './Step07';
import { Step09 as Step090 } from './Step09';

export const ActorRegistration = () => {
  const { uuid } = useParams();
  const { createDSMError } = useAlerts();
  const [data, setData] = React.useState<any>({});
  const [shirtSizes, setShirtSizes] = React.useState<any>([]);
  const [selected, setSelected] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const translate = usePortalTranslations();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    await load();
    setLoading(false)
  });

  /**
   * load
   */
  const load = React.useCallback(async () => {
    if (!uuid) {
      return
    }
    try {
      ShirtSizeService.getAll().then(ss => setShirtSizes(ss.data))
      const response = await TeamActorService.registration(uuid);
      setData(response.data);
      setSelected(response.data.steps.findIndex((step: any) => step.id === response.data.teamActor.registrationStatus));
    } catch (error) {
      createDSMError(error);
    }
  }, [createDSMError, uuid]);

  /**
   * 
   */
  const start = React.useCallback(async () => {
    try {
      await TeamActorService.start(uuid);
      load();
    } catch (error) {
      createDSMError(error);
    }
  }, [createDSMError, uuid, load]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  const stepHeaders = {};
  stepHeaders[REGISTER_STATUS_CREATED] = t('public.actorRegistration.steps.created');
  stepHeaders[REGISTER_STATUS_PERSONAL_DATA] = t('public.actorRegistration.steps.data');
  stepHeaders[REGISTER_STATUS_PENDING_QUESTIONS] = t('public.actorRegistration.steps.questions');
  stepHeaders[REGISTER_STATUS_PENDING_TEXT_SIGNATURE] = t('public.actorRegistration.steps.signature');
  stepHeaders[REGISTER_STATUS_TEXT_SIGNATURE_DECLINED] = t('public.actorRegistration.steps.signatureDeclined');
  stepHeaders[REGISTER_STATUS_PENDING_PAYMENT ] = t('public.actorRegistration.steps.payment');
  stepHeaders[REGISTER_STATUS_PAYMENT_REJECTED ] = t('public.actorRegistration.steps.paymentRejected');
  stepHeaders[REGISTER_STATUS_COMPLETED] = t('public.actorRegistration.steps.completed');

  return (
    <View style={styles.container}>
      <PublicNavbar />

      <Container title={t('public.actorRegistration.title')}>
        <Stepper
          selected={selected}
          step={data.steps.findIndex((step: any) => step.id === data.teamActor.registrationStatus)}
          headers={data.steps.map((step: any) => ({ ...step, label: stepHeaders[step.id] }))}
          onPress={(step: any) => setSelected(step)}
        />

        {data.steps[selected].id === REGISTER_STATUS_CREATED && (
          <View>
            <Title size='small' text={`${t('public.actorRegistration.welcome')} ${data.teamActor.fullName}`} />

            {data.teamActor.modalityId === MODALITY_3x3 ? (
              <View>
                <Text fontSize={16}>{t(translate(Translation.THREEONTHREE_ACTOR_REGISTRATION_WELCOME))}</Text>
                <Text fontSize={16}>{t(translate(Translation.THREEONTHREE_ACTOR_REGISTRATION_DESCRIPTON), {teamName: data.teamActor.teamName, teamContactFullName: data.teamActor.teamContactFullName})}</Text>
                {/* <Text fontSize={16}>Benvingut/da al VI CIRCUIT 3x3 CENTENARI FCBQ</Text>
                <Text fontSize={16}>Has estat inscrit a l’equip “{data.teamActor.teamName}” per part de {data.teamActor.teamContactFullName} i així podràs participar a totes les seus d’aquesta temporada si acceptes la invitació.</Text> */}
                {/* <Text fontSize={16}>Has estat inscrit a l’equip “{data.teamActor.teamName}” per part de {data.teamActor.teamContactFullName} i així podràs participar a totes les seus d’aquest circuit si acceptes la invitació.</Text> */}
              </View>
            ) : (
              <Text>{t('public.actorRegistration.description')}</Text>
            )}

            {data.teamActor.registrationStatus === REGISTER_STATUS_CREATED && (
              <View flexDirection='row' justifyContent='flex-end'>
                <Button onPress={start} label={t('global.start')} containerStyle={{ marginTop: styling.spacing }} />
              </View>
            )}
          </View>
        )}

        {data.steps[selected].id === REGISTER_STATUS_PERSONAL_DATA && <Step005 data={data} onSuccess={load} shirtSizes={shirtSizes} />}
        {data.steps[selected].id === REGISTER_STATUS_PENDING_QUESTIONS && <Step010 data2={data} onSuccess={load} />}
        {data.steps[selected].id === REGISTER_STATUS_PENDING_TEXT_SIGNATURE && <Step020 data2={data} onSuccess={load} />}
        {data.steps[selected].id === REGISTER_STATUS_TEXT_SIGNATURE_DECLINED && <Step040 data2={data} onSuccess={load} />}
        {data.steps[selected].id === REGISTER_STATUS_PENDING_PAYMENT && <Step070 data2={data} />}
        {data.steps[selected].id === REGISTER_STATUS_PAYMENT_REJECTED && <Step070 data2={data} />}
        {data.steps[selected].id === REGISTER_STATUS_COMPLETED && <Step090 />}
      </Container>
    </View>
  );
}

/**
 * styles
 */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f1f5f9',
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: styling.spacing,
    height: 81,
  },
  headerLogo: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  headerImage: {
    resizeMode: 'contain',
    width: 120,
    height: 80,
  },
});