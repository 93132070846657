import * as React from 'react';
import { ColorValue, StyleSheet, Text as RNText } from 'react-native';
import { useColors } from 'src/hooks/useColors';

export interface IText {
  color?: ColorValue,
  bold?: boolean;
  uppercase?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  fontSize?: number;
  overflow?: boolean;
  title?: string;
  marginTop?: number;
  onPress?:()=>void;
};

export const Text = ({ uppercase = false, bold = false, italic = false, strikethrough = false, textAlign = 'left', color, fontSize = 13, children, style, overflow = false, title, marginTop = 3, onPress }: IText & RNText['props']) => {
  const colors = useColors();

  return (
    <RNText
      onPress={onPress}
      style={[
        uppercase && styles.uppercase,
        bold && styles.bold,
        italic && styles.italic,
        strikethrough && styles.strikethrough,
        { fontSize, textAlign, color: color || colors.text },
        overflow && styles.overflow,
        { marginTop: marginTop },
        style,
      ]}
    >
      {children}
    </RNText>
  )
}

const styles = StyleSheet.create({
  uppercase: {
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  strikethrough: {
    textDecorationLine: 'line-through',
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'normal',
  }
})