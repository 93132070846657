import { t } from 'owc-react-lib-common';
import * as React from 'react';

import { StyleSheet } from "react-native";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container } from 'src/components/Container';
import { Loader } from 'src/components/Loader';
import { Type } from 'src/components/Sidebar/types';
import { View } from "src/components/View";
import { MODALITY_3x3, styling } from "src/config";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useUserPermission } from "src/hooks/useUserPermission";
import { CompetitionRegisterQuestions } from 'src/pages/organizer/competitions/CompetitionRegisterQuestions';
import * as CompetitionService from 'src/services/CompetitionService';
import { IEventDTO } from 'src/services/EventService';
import * as ModalityService from 'src/services/ModalityService';
import { CloneEvent } from '../events/CloneEvent';
import { EventDetail } from '../events/EventDetail';
import { CompetitionRankingList } from '../ranking/CompetitionRankingList';
import { CompetitionCreate } from './CompetitionCreate';
import { CompetitionUpdate } from './CompetitionUpdate';

export const Competition = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const alerts = useAlerts();

  const [data, setData] = React.useState<any>();
  const [modalities, setModalities] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    // validate params
    if (!uuid) {
      navigate('/organizer/competitions');
      return;
    }

    // fetch data
    try {
      ModalityService.findAll().then(r => {
        setModalities(r.data);
      })
      const response = await CompetitionService.findByUuid(uuid);
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  const setEvents = React.useCallback((newEvent: IEventDTO) => {
    setData({ ...data, events: [...data.events, newEvent] })
  }, [data])

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  let sidebar = [
    { id: 'competitions', type: Type.LINK, to: `/organizer/competitions`, label: `« ${t('admin.competitions.title')}` },
    { id: 'competition', type: Type.LINK, to: `/organizer/competitions/${data.uuid}`, label: data.name, icon: 'eye' },
    { id: 'register-questions', type: Type.LINK, to: `/organizer/competitions/${data.uuid}/register-questions`, label: t('admin.competitions.registerQuestions.title'), icon: 'question' },
    { id: 'ranking', type: Type.LINK, to: `/organizer/competitions/${data.uuid}/ranking`, label: t('admin.competitions.rankings'), icon: 'ranking-star' },
    {
      id: 'categories', type: Type.TITLE, label: t('admin.events.title'), icon: 'list',
      children: [
        ...data?.events?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((event: any) => ({ id: `event-${event.id}`, type: Type.LINK, to: `/organizer/events/${event.uuid}`, label: event.name })),
        !readOnly && { id: 'competition-event', type: Type.LINK, to: `/organizer/competitions/${data.uuid}/create-event`, label: `+ ${t('admin.events.create')}` },
        !readOnly && { id: 'competition-event', type: Type.LINK, to: `/organizer/competitions/${data.uuid}/clone-event`, label: `+ ${t('admin.events.clone')}` },
      ]
    },
  ];

  // If the competition modality is 3x3 we get rid of the register questions sidebar
  if(data.modalityId === MODALITY_3x3){
    sidebar = sidebar.filter(bar => bar.id !== 'register-questions')
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Container sidebar={sidebar} title={data.name} right={data.seasonName} exactRoute>
          <Routes>
            <Route path='create-event' element={<EventDetail competition={data} onCreate={setEvents} />} />
            <Route path='clone-event' element={<CloneEvent competition={data} onClone={setEvents} />} />

            <Route path='register-questions' element={<CompetitionRegisterQuestions competitionId={data.id} />} />
            <Route path='ranking' element={<CompetitionRankingList events={data.events} />} />
            <Route path="competitions/create" element={<CompetitionCreate />} />
            <Route path="competitions/:uuid/*" element={<Competition />} />
            <Route path='*' element={<CompetitionUpdate data={data} onSave={setData} modalities={modalities} />} />
          </Routes>
        </Container>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  sidebar: {
    width: 300,
    backgroundColor: 'white',
    margin: styling.spacing,
    marginTop: 0,
  },
  content: {
    flex: 1,
    paddingHorizontal: styling.spacing,
  },
  submit: {
    marginBottom: 0,
  },
});