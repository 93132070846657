import * as React from 'react';
import { Button, Checkbox, DateTimePicker, Input, Picker, t, Text } from "owc-react-lib-common";
import { StyleSheet } from "react-native";
import { Form } from "src/components/Form";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Row } from "src/components/Row/Row";
import { View } from "src/components/View";
import { DOCUMENT_TYPE_CATSALUT, DOCUMENT_TYPE_CIF, DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIF, DOCUMENT_TYPE_PASSPORT } from "src/pages/auth/components/config";
import { CUSTOM, EMAIL, IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import * as TeamActorService from 'src/services/TeamActorService';
import { REGISTER_STATUS_PERSONAL_DATA } from './IRegistrationProcess';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { styling } from 'src/config';
import { CreationQuestion } from './CreationQuestion';

interface IStep005 {
  data: any;
  onSuccess: () => void;
  shirtSizes: any[];
  portalQuestions?: any[];
}

export const Step005 = ({ data, onSuccess, shirtSizes }: IStep005) => {
  const form = React.useRef<any>(data.teamActor);
  const { validateAll, phone, number, nif, nie, cif, required } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [accepted, setAccepted] = React.useState<boolean>(false);
  const context = React.useContext(OWCOpenDmrContext);

  /**
   * setters
   */
  const setName = React.useCallback((value: string) => form.current.peopleName = value, []);
  const setLastName = React.useCallback((value: string) => form.current.peopleSurname = value, []);
  const setDocumentIdentifierType = React.useCallback((value: string) => form.current.peopleIdDocType = value, []);
  const setDocumentIdentifier = React.useCallback((value: string) => form.current.peopleIdDoc = value.toUpperCase(), []);
  const setBirthDate = React.useCallback((object: any) => form.current.peopleBirthdate = object.startDateSQL, []);
  const setGender = React.useCallback((value: string) => form.current.gender = value, []);
  const setEmail = React.useCallback((value: string) => form.current.peopleEmail = value, []);
  const setPhone = React.useCallback((value: string) => form.current.peoplePhone = value, []);
  const setNumber = React.useCallback((value: string) => form.current.number = value, []);
  const setShirtSize = React.useCallback((value: string) => form.current.shirtSizeId = value, []);
  const setObservations = React.useCallback((value: string) => form.current.observations = value, []);
  const setMarketing = React.useCallback((value: boolean) => form.current.marketing = value, []);

  const setPhoto = React.useCallback((object: IImageUploaderData) => {
    form.current.peopleOriginalPhotoFilename = object.name;
    form.current.peoplePhotoFile = object.base64;
  }, []);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    const fields: IValidationParams[] = [];

    // validate
    fields.concat([
      { name: 'peopleName', type: REQUIRED },
      { name: 'peopleSurname', type: REQUIRED },
      { name: 'peopleIdDocType', type: REQUIRED },
      {
        name: 'peopleIdDoc', type: [REQUIRED, CUSTOM], validate: (value: string) => {
          switch (parseInt(form.current.peopleIdDocType)) {
            case DOCUMENT_TYPE_NIF: return nif(value);
            case DOCUMENT_TYPE_NIE: return nie(value);
            case DOCUMENT_TYPE_CIF: return cif(value);
          }
        }
      },
      { name: 'peopleBirthdate', type: REQUIRED },
      { name: 'gender', type: REQUIRED },
      { name: 'peopleEmail', type: [REQUIRED, EMAIL] },
      { name: 'peoplePhone', type: CUSTOM, validate: (value: string) => !!value && phone(value) },
      { name: 'number', type: CUSTOM, validate: (value: string) => !!value && number(value) },
      // { name: 'shirtSize', type: REQUIRED },
      // { name: 'observations', type: REQUIRED },
      // { name: 'photo', type: REQUIRED },
    ]);

    data?.questions?.forEach((question: any) => {
      fields.push({
        name: `question-${question.id}`,
        type: CUSTOM,
        validate: () => {
          if (question.required) {
            const answer = data.answers?.find((answer: any) => answer.questionId === question.id);
            // validamos que el check, este a true y no a false, si esta a false es como si estuviera vacio en caso de requerido
            if (answer) {
              if (question.type === "check") {
                if (answer?.answer === false) {
                  answer.answer = null;
                }
                if (answer?.answer === true) {
                  answer.answer = question.options;
                }
              }
            }
            return required(answer?.answer);
          }
        },
      });
    });

    const { errors, hasErrors } = validateAll(form.current, fields);


    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // save request
    await TeamActorService.update(form.current.uuid, form.current);

    // notify
    onSuccess?.();
  }, [data, onSuccess, required, cif, nif, nie, number, phone, validateAll]);

  const onChangeAnswer = React.useCallback(
    (question2: any, value: any) => {
      let _answers = data?.answers;
      const index = _answers.findIndex((answer: any) => answer.questionId === question2.id);

      if (index === undefined || index === -1) {
        question2.answer = value; // value
        question2.questionId = question2.id; // id pregunta
        question2.externalId = data.teamActor.id; // idactor
        question2.externalTypeId = 1;
        _answers.push(question2);
      } else {
        _answers[index].answer = value;
      }
      form.current = {...form.current, registerAnswers: _answers }
    }, [data]);

  /**
   * render
   */
  const disabled = data.teamActor.registrationStatus !== REGISTER_STATUS_PERSONAL_DATA;

  const documentTypes = [
    { label: 'NIF', value: DOCUMENT_TYPE_NIF },
    { label: t('auth.competitions.teamCreation.actors.passport'), value: DOCUMENT_TYPE_PASSPORT },
    { label: 'CATSALUT', value: DOCUMENT_TYPE_CATSALUT },
    { label: 'NIE', value: DOCUMENT_TYPE_NIE },
    { label: 'CIF', value: DOCUMENT_TYPE_CIF },
  ];

  const genders = [
    { label: t('global.female'), value: 'F' },
    { label: t('global.male'), value: 'M' }
  ];

  return (
    <View>
      <Form>
        <Row flex={[1, 3]}>
          <Input label={t('3x3.teams.actors.name') + ' *'} defaultValue={form.current.peopleName} onChange={setName} error={errors.peopleName} disabled={disabled} />
          <Input label={t('3x3.teams.actors.lastName') + ' *'} defaultValue={form.current.peopleSurname} onChange={setLastName} error={errors.peopleSurname} disabled={disabled} />
        </Row>

        <Row>
          <Picker label={t('3x3.teams.actors.documentIdentifierType') + ' *'} items={documentTypes} defaultValue={form.current.peopleIdDocType} onChange={setDocumentIdentifierType} error={errors.peopleIdDocType} disabled={disabled} />
          <Input label={t('3x3.teams.actors.documentIdentifier') + ' *'} defaultValue={form.current.peopleIdDoc} onChange={setDocumentIdentifier} error={errors.peopleIdDoc} disabled={disabled} />
          <View>
            <Text style={{ marginBottom: 4 }} color={errors.peopleBirthdate && 'crimson'}>{t('3x3.teams.actors.birthDate') + ' *'}</Text>
            <DateTimePicker showLabels={false} /*label={t('3x3.teams.actors.birthDate')}*/ startDateValue={form.current.peopleBirthdate} onChange={setBirthDate} showTime={false} error={errors.peopleBirthdate} disabled={disabled} />
          </View>
          <Picker label={t('3x3.teams.actors.gender') + ' *'} items={genders} defaultValue={form.current.gender} onChange={setGender} error={errors.gender} disabled={disabled} />
        </Row>

        <Row>
          <Input label={t('3x3.teams.actors.email') + ' *'} defaultValue={form.current.peopleEmail} onChange={setEmail} error={errors.peopleEmail} disabled />
          <Input label={t('3x3.teams.actors.phone')} defaultValue={form.current.peoplePhone} onChange={setPhone} error={errors.peoplePhone} disabled={disabled} />
          <Input label={t('3x3.teams.actors.number')} defaultValue={form.current.number} onChange={setNumber} error={errors.number} disabled={disabled} />
          <Picker label={t('3x3.teams.actors.shirtSize')} defaultValue={form.current.shirtSizeId} items={shirtSizes.map(ss => ({ label: ss.size, value: ss.id }))} onChange={setShirtSize} error={errors.shirtSize} disabled={disabled} />
        </Row>
        <Row>
          {data?.questions?.map((question: any) => {
            const answer = data?.answers?.find((answer: any) => answer.questionId === question.id);

            return (
              <View key={question.id}>
                <Text bold fontSize={14}>
                  {question.question}
                </Text>

                <CreationQuestion
                  key={question.id}
                  data={question}
                  defaultValue={question?.defaultValue}
                  value={answer?.answer}
                  onChange={(value: string) => onChangeAnswer(question, value)}
                  required={question.required}
                  disabled={disabled}
                  error={errors[`question-${question.id}`]}
                />
              </View>
            );
          })}
        </Row>
        <Row>
          <Input label={t('3x3.teams.actors.observations')} numberOfLines={8} defaultValue={form.current.observations} onChange={setObservations} error={errors.observations} disabled={disabled} />
        </Row>

        <Row>
          <ImageUploader label={t('3x3.teams.actors.photo')} defaultValue={form.current.peoplePhotoUrl} onChange={setPhoto} error={errors.photo} />
        </Row>

        {context.configuration.portal.portal?.includes('inscripcions3x3.basquetcatala.cat') && (
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: styling.spacing, fontSize: 12, fontFamily: 'Verdana' }}>
              <Checkbox
                defaultValue={form.current.marketing}
                onChange={setMarketing}
                marginBottom={0}
                disabled={data.teamActor.registrationStatus > REGISTER_STATUS_PERSONAL_DATA}
              />

              <div>
                {t('3x3.teams.data.marketingEuroleagueInscripcions3x3')}
                <a target='_blank' rel="noreferrer" href="https://www.euroleaguebasketball.net/privacy-policy-ev/" style={{ display: 'block' }}>https://www.euroleaguebasketball.net/privacy-policy-ev/</a>
              </div>
            </div>
          </Row>
        )}

        <div style={{ fontSize: 12, fontFamily: 'Verdana', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: styling.spacing }}>
          <Checkbox defaultValue={data.teamActor.registrationStatus > REGISTER_STATUS_PERSONAL_DATA} onChange={setAccepted} marginBottom={0} disabled={data.teamActor.registrationStatus > REGISTER_STATUS_PERSONAL_DATA} />

          <div>
            {t('3x3.teams.data.lopd')}&nbsp;

            <a href={context.configuration.portal.actorLopd} target="_blank" rel="noreferrer" style={{ display: 'block' }} >
              {t('3x3.teams.data.downloadLopd')}
            </a>
          </div>
        </div>
      </Form>

      {!disabled && (
        <View style={styles.buttons}>
          <Button text={t('global.save')} onPress={save} containerStyle={styles.button} disabled={!accepted} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    // maxWidth: 800,
    // width: '100%',
    // marginHorizontal: 'auto',
  },
  data: {
    flex: 1,
  },
  actors: {
    flex: 1,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: styling.spacing,
    borderTopWidth: 1,
    borderColor: '#eee',
    paddingTop: 15,
  },
  button: {
    marginRight: 0,
    marginBottom: 0,
  },
});