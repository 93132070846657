import { Draggable } from "@fullcalendar/interaction";
import * as React from "react";
import * as ColorUtils from 'src/utils/ColorUtils';
import { Accordion } from "../Accordion/Accordion";
import { MatchCard } from "../MatchCard";
import { View } from "../View";
import * as CalendarHelper from "./CalendarHelper";

export const ExternalEvents = ({ groupedExternalEvents, dashedBorder, eventComponent, calendar, colorHelper, editable }) => {
  const calculateDurationFormat = (minutes) => {
    var m = minutes % 60;

    var h = (minutes - m) / 60;

    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
  }

  React.useEffect(() => {
    let draggableEl = eventComponent.current;
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: (eventEl) => {
        let id = eventEl.dataset.id;

        const formatedDuration = eventEl.dataset.duration.includes(':') ? eventEl.dataset.duration : calculateDurationFormat(eventEl.dataset.duration);
        let duration = formatedDuration ?? '01:30';
        let localName = eventEl.dataset.localname;
        let localId = eventEl.dataset.localid;
        let awayName = eventEl.dataset.awayname;
        let awayId = eventEl.dataset.awayid;
        let categoryName = eventEl.dataset.categoryname;
        let roundNumber = eventEl.dataset.roundnumber;
        let roundId = eventEl.dataset.roundid;
        let matchGroupId = eventEl.dataset.matchgroupid;
        let matchGroupName = eventEl.dataset.matchgroupname;
        let errorAutomaticAssignation = eventEl.dataset.errorautomaticassignation;
        let categoryInitials = eventEl.dataset.categoryinitials;
        let automaticAssignationCalendarColor = eventEl.dataset.automaticassignationcalendarcolor;

        return {
          id: id,
          create: true,
          duration: duration,
          localName: localName,
          localId: localId,
          awayName: awayName,
          awayId: awayId,
          categoryName: categoryName,
          roundNumber: roundNumber,
          roundId: roundId,
          matchGroupId: matchGroupId,
          matchGroupName: matchGroupName,
          errorAutomaticAssignation: errorAutomaticAssignation,
          categoryInitials: categoryInitials,
          automaticAssignationCalendarColor: automaticAssignationCalendarColor,
        };
      }
    });
  }, [eventComponent]);

  const renderItem = (event) => {
    return (
      <div
        className={editable && "fc-event"}
        data-id={event.id}
        data-duration={event.duration}
        data-localname={event.localName}
        data-awayname={event.awayName}
        data-categoryname={event.categoryName}
        data-roundnumber={event.roundNumber}
        data-roundid={event.roundId}
        data-localid={event.localId}
        data-awayid={event.awayId}
        data-matchgroupid={event.matchGroupId}
        data-matchgroupname={event.matchGroupName}
        data-errorautomaticassignation={event.errorAutomaticAssignation}
        data-categoryinitials={event.categoryInitials}
        data-automaticassignationcalendarcolor={event.automaticAssignationCalendarColor}
        key={event.id}
        style={{ cursor: "pointer", padding: 5, borderRadius: 5 }}
        onPointerDown={(e) => {
          const calendarApi = calendar.current.getApi();

          //Limpiamos..
          CalendarHelper.removeBlockElements(calendarApi)

          //Creamos...
          const calendarEvents = calendarApi.getEvents();
          calendarEvents.forEach((calendarEvent) => {
            if (Number.parseInt(calendarEvent.id) === Number.parseInt(event.id)) return;
            if (
              Number.parseInt(calendarEvent.extendedProps.localId) !== Number.parseInt(event.localId) &&
              Number.parseInt(calendarEvent.extendedProps.localId) !== Number.parseInt(event.awayId) &&
              Number.parseInt(calendarEvent.extendedProps.awayId) !== Number.parseInt(event.awayId) &&
              Number.parseInt(calendarEvent.extendedProps.awayId) !== Number.parseInt(event.localId)
            ) {
              return;
            }
            CalendarHelper.addBlockElements(calendarApi, calendarEvent.start, calendarEvent.end)
          });
        }}
        onPointerUp={(e) => {
          // no funciona, no se activa...
          //console.log("POINTER UP")
          //const calendarApi = calendar.current.getApi();
          //CalendarHelper.removeBlockElements(calendarApi)
          
        }}
      >
        <div className="fc-event-main" >
          <View style={{ padding: 3, backgroundColor: event.automaticAssignationCalendarColor, borderRadius: 8, height: '100%', justifyContent: 'center' }}>
            <MatchCard localName={event.localName} awayName={event.awayName} categoryName={event.categoryInitials} matchGroupId={event.matchGroupName} color={ColorUtils.isDarkColor(event.automaticAssignationCalendarColor) ? '#eee' : '#333'} />
          </View>
        </div>
      </div>
    )
  }

  return (
    <div className="external-event" style={{ display: 'flex', flexDirection: 'column', gap: 10, border: `3px dashed ${dashedBorder}`, borderRadius: 8, minHeight: '100%', maxHeight: '92vh', overflow: 'scroll' }} ref={eventComponent}>
      <Accordion data={groupedExternalEvents} renderItem={renderItem} />
    </div>
  )
}