import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


export interface ITeamEventCategoryActorDTO {
  id?: number;
  uuid?: string;
  teamEventCategoryId?: number;
  teamActorId?: number;
  number?: number;
}

export interface ICategory {
  id: number;
  uuid: string;
  externalUuid: string;
  name: string;
  minAge: number;
  maxAge: number;
  maxPlayers: number;
  maxCoaches: number;
  gender: string;
  minPlayers: number;
  deleted: Date | null;
  isOwbasket: boolean;
  visibleRecords: boolean;
  withMatchRecord: boolean;
  automaticAssignationCalendarColor: string;
}


const BASE_URL = BACKEND_URL + '/auth/categories'

/**
 * 
 Devuelve todos los actores de un equipo 3x3 respecto la event category
 */
export const findAllCategories = (isOwbasket: boolean = true) => {
  return RestService.get(`${BASE_URL}?isOwbasket=${isOwbasket}`);
}

export const findOrganizerCategories = (isOwbasket: boolean = true): Promise<ICategory[]> => {
  return RestService.getTyped(`${BASE_URL}?isOwbasket=${isOwbasket}`);
}

export const findCategoryByUuid = (uuid: string): Promise<ICategory> => {
  return RestService.getTyped(`${BASE_URL}/uuid/${uuid}`);
}

export const createCategoryEntity = (category: ICategory): Promise<ICategory> => {
  return RestService.postTyped(`${BASE_URL}`, category);
}

export const updateCategoryEntity = (category: ICategory): Promise<ICategory> => {
  return RestService.putTyped(`${BASE_URL}`, category);
}

export const deleteCategoryEntity = (id: number): Promise<number> => {
  return RestService.deleteTyped(`${BASE_URL}/${id}`);
}
