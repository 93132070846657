import dayjs from "dayjs";
import * as React from "react";
import { t } from "owc-react-lib-common";

import { StyleSheet } from "react-native";
import { Button } from "src/components/Button";
import { Card } from "src/components/Card";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { IconButton } from "src/components/IconButton";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Text } from "src/components/Text";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useColors } from "src/hooks/useColors";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as HotelService from "src/services/HotelService";
import * as TeamService from "src/services/TeamService";
import { ITeamRoomDTO } from "src/services/TeamService";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

interface ITeamRooms {
  dataTeam: any;
  eventUuid: any;
  category: any;
  dataActors: any;
}

export const TeamRooms = ({ dataTeam, eventUuid, category, dataActors }: ITeamRooms) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const colors = useColors("primary");
  const [allRoomsData, setAllRoomsData] = React.useState({} as any);
  const [teamRoomsData, setTeamRoomsData] = React.useState({} as any);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showSummary,] = React.useState(true);
  const [showSendModal, setShowSendModal] = React.useState(false);
  const form = React.useRef(dataTeam.hotelObservations);
  const [selected, setSelected] = React.useState<any>();
  const [hotelNotificationTime, setHotelNotificationTime] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    load();
  });

  /**
   * load
   */
  const load = React.useCallback(async () => {
    try {
      // fetch logged user
      const [allRooms, teamRooms]: any[] = await Promise.all([
        HotelService.findHotelsByEvent(eventUuid),
        TeamService.getRooms(dataTeam.id),
      ]);

      setAllRoomsData(allRooms.data);
      setTeamRoomsData(teamRooms.data);

      const selected = allRooms.data.find((hotel: any) =>
        hotel.rooms?.some((room: any) => teamRooms.data.some((troom: any) => troom.roomTypeId === room.id))
      );
      setSelected(selected);
    } catch (error) {
      alerts.createDSMError(error);
    } finally {
      setLoading(false);
    }
  }, [alerts, eventUuid, dataTeam.id]);

  /**
   * save observations
   */
  const saveObservations = React.useCallback(async () => {
    try {
      await TeamService.update({ ...dataTeam, hotelObservations: form.current });
      alerts.create({ type: "success", text: t("global.save") });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, dataTeam]);

  /** 
   * add new room
   */
  const create = React.useCallback(
    async (room: any) => {
      try {
        const _room = {
          numRooms: 1,
          roomTypeDescription: room.description,
          roomTypeId: room.id,
          teamId: dataTeam.id,
        };

        await TeamService.addRooms(_room);
        load();
        // alerts.create({ type: 'success', text: t('admin.teams.teamRooms.update') });
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [alerts, load, dataTeam.id]
  );

  /**
   * update room (añadir, restar y eliminar habitaciones)
   */
  const update = React.useCallback(
    async (room: any, type: "plus" | "less", indexHotel: number, indexRoom: number) => {
      const _numRooms = type === "plus" ? room.numRooms + 1 : room.numRooms - 1;
      let _teamRoomsData = [] as any;
      let _allRoomsData = allRoomsData;
      let _room = { ...room };
      _room.numRooms = _numRooms;

      try {
        await TeamService.updateRooms(_room);

        // llamamos al servicio para eliminar tipo de habitación
        if (_numRooms === 0) {
          await TeamService.removeRooms(_room.id);
          // alerts.create({ type: 'success', text: t('admin.teams.teamRooms.update') });
          load();
        } else {
          if (_allRoomsData[indexHotel].rooms[indexRoom].freeRooms != null) {
            _allRoomsData[indexHotel].rooms[indexRoom].freeRooms =
              type === "plus"
                ? _allRoomsData[indexHotel].rooms[indexRoom].freeRooms - 1
                : _allRoomsData[indexHotel].rooms[indexRoom].freeRooms + 1;
            setAllRoomsData(_allRoomsData);
          }
          teamRoomsData?.map((a: any) => (a.id === _room.id ? _teamRoomsData.push(_room) : _teamRoomsData.push(a)));

          //set
          setTeamRoomsData(_teamRoomsData);

          //alert
          // alerts.create({ type: 'success', text: t('admin.teams.teamRooms.update') });
        }
      } catch (error) {
        alerts.createDSMError(error);
      }
    },
    [alerts, allRoomsData, load, teamRoomsData]
  );

  /**
   * get pdf
   */
  const getReport = React.useCallback(async () => {
    try {
      await TeamService.getActorsCheckIn(dataTeam.uuid, dataTeam.name);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, dataTeam.uuid, dataTeam.name]);

  /**
   * send email
   */
  const send = React.useCallback(async () => {
    try {
      await TeamService.sendChekInMail(dataTeam.uuid);
      setShowSendModal(false);
      setHotelNotificationTime(new Date());
      alerts.create({ type: "success", text: t("admin.teams.teamRooms.mailSentSuccessfully") });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, dataTeam.uuid]);

  /**
   * close send modal
   */
  const closeSendModal = React.useCallback(() => {
    setShowSendModal(false);
  }, []);

  /**
   * render header actors
   */
  const renderHeaderActors = React.useCallback(() => {

    // prepare data
    if (!category) return;
    const data = category.rates?.map((rate: any) => {
      const participants = dataActors.filter((actor: any) => actor.rateId === rate.id && !actor.deleted);

      return {
        id: rate.id,
        name: rate.name,
        description: rate.description,
        participants,
        roles: dataActors
          .filter((actor: any) => actor.rateId === rate.id)
          .reduce((values: any[], actor: any) => {
            if (values.some((value: any) => value.id === actor.roleId)) {
              return values;
            }

            return [
              ...values,
              {
                id: actor.roleId,
                name: actor.roleDescription,
                amount: participants.filter((participant: any) => participant.roleId === actor.roleId && participant.deleted == null).length,
              },
            ];
          }, [])
          .sort((a: any, b: any) => a.id - b.id),
      };
    });

    // render
    return (
      <View flex={1}>
        <Title text={t("admin.teams.teamRooms.rates")} />

        {data?.map((rate: any) => {
          const activeActorsCount = rate.participants.filter((participant: any) => participant.deleted == null).length
          if (!activeActorsCount) {
            return null;
          }

          return (
            <View key={rate.id} style={styles.containerRates}>
              <Card containerStyle={styles.cardRates} contentStyle={{ flex: 1, width: '100%', paddingHorizontal: 20 }}>
                <View flex={1} flexDirection="row" alignItems="center" justifyContent="center">
                  <Text uppercase bold>{rate.name} </Text>
                  <View style={{ flex: 1, borderBottomWidth: 1, marginLeft: 5, marginRight: 10 }} />
                  <Text bold>{activeActorsCount}</Text>
                </View>

                {!!rate.roles.length && (
                  <View>
                    {rate.roles?.map((role: any) => (
                      <View key={role.id} flexDirection="row" alignItems="center" justifyContent="center" >
                        <Text uppercase>- {role.name}</Text>
                        <View style={{ flex: 1, borderBottomWidth: 1, marginLeft: 5, marginRight: 10 }} />
                        <Text>{role.amount}</Text>
                      </View>
                    ))}
                  </View>
                )}
              </Card>
            </View >
          );
        })}
      </View >
    );
  }, [category, dataActors]);

  /**
   * render header rooms
   */
  const renderHeaderRooms = React.useCallback(() => {
    // prepare data
    const data = allRoomsData?.map((hotel: any) => {
      return {
        id: hotel.id,
        name: hotel.name,
        amount: hotel.rooms.reduce((total: number, room: any) => {
          const assigned = teamRoomsData.find((assigned: any) => room.id === assigned.roomTypeId);
          return total + (assigned?.numRooms || 0);
        }, 0),
        rooms: hotel.rooms
          .sort((a: any, b: any) => a.peopleCapacity - b.peopleCapacity)
          ?.map((room: any) => {
            const assigned = teamRoomsData.find((assigned: any) => room.id === assigned.roomTypeId);

            return {
              id: room.id,
              name: `${room.description} (${room.peopleCapacity}p)`,
              amount: assigned?.numRooms || 0,
            };
          }),
      };
    });

    // render
    return (
      <View flex={1}>
        <Title text={t("admin.teams.teamRooms.rooms")} />

        {data?.map((hotel: any) => {
          if (!hotel.amount) {
            return null;
          }

          return (
            <View key={hotel.id} style={styles.containerRoomSummary}>
              <Card containerStyle={styles.cardRoomSummary} contentStyle={{ flex: 1, width: '100%', paddingHorizontal: 20 }}>
                <View flex={1} flexDirection="row" alignItems="center" justifyContent="center">
                  <Text bold uppercase>{hotel.name} </Text>
                  <View style={{ flex: 1, borderBottomWidth: 1, marginLeft: 5, marginRight: 10 }} />
                  <Text bold>{hotel.amount}</Text>
                </View>

                {hotel.rooms?.map((room: any) => {
                  if (!room.amount) {
                    return null;
                  }

                  return (
                    <View key={room.id} flexDirection="row" alignItems="center">
                      <Text uppercase>- {room.name}</Text>
                      <View style={{ flex: 1, borderBottomWidth: 1, marginLeft: 5, marginRight: 10 }} />
                      <Text>{room.amount}</Text>
                    </View>
                  );
                })}
              </Card>
            </View>
          );
        })}
      </View>
    );
  }, [allRoomsData, teamRoomsData]);

  /**
   * render header
   */
  const renderHeader = React.useCallback(() => {
    const lastNotification = hotelNotificationTime || dataTeam?.hotelNotificationTime;

    return (
      <View flex={1}>
        <Form>
          <Row>
            <Input
              label={t("admin.teams.teamRooms.observations")}
              onChange={(value: string) => (form.current = value)}
              defaultValue={form.current}
              disabled={readOnly}
            />
          </Row>
          <Row>
            <Button label={t("global.save")} onPress={saveObservations} disabled={readOnly} />
          </Row>

          <View style={styles.iconContainer}>
            <Text uppercase>
              <Text bold>{t("admin.teams.teamRooms.lastNotification")}: </Text>
              <Text>{!!lastNotification ? dayjs(lastNotification).format("DD/MM/YYYY HH:mm") : "-"}</Text>
            </Text>

            <View flexDirection='row' alignItems='center' justifyContent='flex-end'>
              {!readOnly && <FloatingIconButton backgroundColor='transparent' color='black' name='email' title='Email' onPress={() => setShowSendModal(true)} containerStyle={[styles.icon]} />}
              <FloatingIconButton backgroundColor='transparent' color='black' name='file' title='Pdf' onPress={getReport} containerStyle={[styles.icon]} />
            </View>
          </View>
        </Form>

        {showSummary && (
          <View flexDirection="row" flex={1} style={{ width: "100%", marginVertical: styling.spacing }}>
            {[...category.rates].length > 0 && renderHeaderActors()}
            {renderHeaderRooms()}
          </View>
        )}
      </View>
    );
  }, [saveObservations, renderHeaderActors, renderHeaderRooms, showSummary, getReport, dataTeam, hotelNotificationTime, readOnly, category]);

  /**
   * render room control
   */
  const renderRoomControl = React.useCallback(
    (object: ITeamRoomDTO, indexHotel: number, indexRoom: number) => {
      const room = teamRoomsData.filter((x: any) => x.roomTypeId === object.id)[0];

      return (
        <View style={styles.roomControlContent}>
          {!readOnly && <IconButton
            fontSize={15}
            backgroundColor='transparent'
            color='black'
            onPress={room && room.numRooms > 0 ? () => update(room, 'less', indexHotel, indexRoom) : () => null}
            name='minus'
            containerStyle={{ minHeight: 0 }}
          />}

          <View style={styles.roomControlNumber}>
            <Text fontSize={15} color={room?.numRooms ? colors.main : "black"} bold>
              {room?.numRooms || 0}
            </Text>
          </View>

          {!readOnly && <IconButton
            fontSize={15}
            backgroundColor='transparent'
            onPress={room && room.numRooms > 0 ? () => update(room, 'plus', indexHotel, indexRoom) : () => create(object)}
            name='plus'
            containerStyle={{ minHeight: 0 }}
            color='black'
          />}
        </View>
      );
    }, [update, create, teamRoomsData, colors.main, readOnly]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  return (
    <View>
      <View style={[styles.header, { borderColor: colors.main }]}>{renderHeader()}</View>



      {allRoomsData?.map((hotel: any, indexHotel: number) => {
        if (!!selected?.id && hotel.id !== selected.id) {
          return null;
        }

        return (
          <View key={indexHotel}>
            <Title text={t("admin.teams.teamRooms.hotels")} />

            <View style={styles.hotelNameContent}>
              <Text bold fontSize={16} color={colors.main} uppercase>
                {hotel.name}
              </Text>
            </View>

            <View style={styles.roomsContent}>
              {hotel.rooms?.map((room: any, indexRoom: number) => {
                return (
                  <Card key={indexRoom}>
                    <View style={styles.roomContent}>
                      <Text bold uppercase>
                        {room.peopleCapacity} {t("admin.teams.teamRooms.peopleCapacity")}
                      </Text>

                      <Text fontSize={10}>
                        {t("admin.teams.teamRooms.availability")}:{" "}
                        {room.freeRooms === null || room.freeRooms < 0 ? "∞" : room.freeRooms}
                      </Text>

                      {renderRoomControl(room, indexHotel, indexRoom)}

                      <Text fontSize={10} textAlign="center">
                        {room.description}
                      </Text>
                    </View>
                  </Card>
                );
              })}
            </View>
          </View>
        );
      })}

      <ModalConfirm
        visible={showSendModal}
        text={t("admin.teams.teamRooms.sendMailConfirmation")}
        onSuccess={send}
        onClose={closeSendModal}
      />
    </View >
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    top: -45,
  },
  icon: {
    position: "relative",
    top: 5,
    margin: 0,
  },
  containerRates: {
  },
  cardRates: {
    paddingVertical: 20,
  },

  containerRoomSummary: {
  },
  cardRoomSummary: {
    paddingVertical: 20

  },

  hotelNameContent: {
    margin: 10,
  },
  roomsContent: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  roomContent: {
    minWidth: 220,
    minHeight: 130,
    justifyContent: "center",
    alignItems: "center",
  },
  roomControlContent: {
    flexDirection: "row",
    backgroundColor: "#f3f3f3",
    alignItems: "center",
    margin: 10,
    padding: 5,
  },
  roomControlNumber: {
    width: 25,
    height: 25,
    alignContent: "center",
    alignItems: "center",
  },
});
