import * as React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "./IconButton";

interface IIconLink {
  to: string;
  name: 'clipboard' | 'arrowright' | 'link' | 'file' | 'circle' | 'fileDownload' | 'creditCard' | 'calendar' | 'warning' | 'card' | 'help' | 'phone' | 'email' | 'arrowleft' | 'rotateLeft' | 'rotateRight' | 'map' | 'ball' | 'search' | 'text' | 'ok' | 'setting' | 'backtime' | 'previewtime' | 'ko' | 'add' | 'edit' | 'remove' | 'more' | 'less' | 'check' | 'times' | 'close' | 'plus' | 'minus' | 'arrow-up' | 'arrow-down' | 'download' | 'upload' | 'list' | 'burger' | 'user' | 'at' | 'exclamation' | 'exclamation-circle' | 'question' | 'play-circle' | 'eye' | 'eye-slash' | 'paper-plane' | 'info-circle' | 'trash' | 'trash-restore' | 'clock' | 'cart' | 'stats' | 'bold' | 'italic' | 'underline' | 'image' | 'ul' | 'ol' | 'alignLeft' | 'alignCenter' | 'alignRight' | 'alignJustify' | 'clone' | 'ban' | 'language' | 'user-shield'| 'people-group' | 'bed';
  title: string;
  backgroundColor?: string;
  color?: string;
}

export const IconLink = ({ to, name, title, backgroundColor, color }: IIconLink) => {
  return (
    <Link to={to} title={title}>
      <IconButton backgroundColor={backgroundColor} color={color} name={name} onPress={() => undefined} />
    </Link>
  );
}