import * as React from 'react';
import * as ShoppingCartService from 'src/services/ShoppingCartService';

import { StyleSheet } from "react-native";
import { useAlerts } from 'src/hooks/useAlerts';
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { Alert, Button, Modal, Separator, t, Text, Title, useComponentDidMount } from "owc-react-lib-common";
import { View } from 'src/components/View';
import { IconButton } from 'src/components/IconButton';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { styling } from "src/config";
import { STATUS } from '../competitions/RegisterTeam3x3';

export interface IShoppingCart {
    showTitle?: boolean;
}
export const ShoppingCart = ({ showTitle }: IShoppingCart) => {
    const context = React.useContext(OWCOpenDmrContext);
    const { createDSMError, create } = useAlerts();
    const navigate = useNavigate();
    const { payment_error: paymentError, payment } = useQueryParams();
    const [paymentErrorModal, setPaymentErrorModal] = React.useState<boolean>(paymentError === 'true');
    const [paymentModal, setPaymentModal] = React.useState<boolean>(payment === 'true');
    const [closedEventCategories, setClosedEventCategories] = React.useState([]);

    useComponentDidMount(() => {
        checkEventCategories();
    })

    const checkEventCategories = () => {
        const details = context.shoppingCart.details.filter(d => d.deleted === null && !d.paid);
        const eventCategories = details.map(d => d.teamEventCategory.eventCategory);

        const closedArray = [];
        for (const eventCategory of eventCategories) {
            if (!eventCategory.inscriptionOpen && eventCategory.maxInscriptionsStatus !== STATUS.CREATED) {
                closedArray.push(eventCategory);
            }
        }
        setClosedEventCategories(closedArray);
    }

    const calculateTotal = () => {
        var acc = context?.shoppingCart.details.reduce((a, b) => {
            if (!b.paid && !b.deleted) {
                return a + b.total
            } else {
                return a
            }
        }, 0)
        return acc;
    }

    const removeFromCart = async (id) => {

        try {
            await ShoppingCartService.removeFromCart(id);
            create({ text: t('3x3.register.removeFromCartSuccess'), type: 'success' });
            const newDetails = context.shoppingCart.details.filter(detail => detail.id !== id);
            context.shoppingCart.details = newDetails;
            checkEventCategories();
        } catch (error) {
            createDSMError(error);
        }
    }

    /**
 * redirect to payment
 */
    const redirectToPayment = React.useCallback(async () => {
        if (context?.shoppingCart.details.filter(d => !d.paid).length === 0 || closedEventCategories.length > 0) {
            create({ text: t('3x3.register.inscriptionLocked'), type: 'error' });
            return;
        }
        try {
            const response = await ShoppingCartService.payment(context.shoppingCart.uuid);

            const myform = document.createElement("form")
            myform.action = response.urlAutorizacionTpv
            myform.method = "post"
            document.body.appendChild(myform);

            const signatureV = document.createElement("input");
            signatureV.value = response.dsSignatureVersion;
            signatureV.name = "Ds_SignatureVersion";
            signatureV.type = "hidden";
            myform.appendChild(signatureV);

            const params = document.createElement("input");
            params.value = response.dsMerchantParameters;
            params.name = "Ds_MerchantParameters";
            params.type = "hidden"
            myform.appendChild(params);

            const signature = document.createElement("input");
            signature.value = response.dsSignature;
            signature.name = "Ds_Signature";
            signature.type = "hidden"
            myform.appendChild(signature);
            myform.submit();
        } catch (error) {
            createDSMError(error);
        }
    }, [createDSMError]);

    const restartRegister = () => {
        navigate('/auth/3x3/competitions');
    };

    const isFree = (): boolean => {
        return context?.shoppingCart.details.filter(d => !d.paid).length > 0 && context?.shoppingCart.details.reduce((a, b) => (!b.paid && (a + b.total)), 0) === 0;
    }

    const payFree = async () => {
        try {
            await ShoppingCartService.payment(context.shoppingCart.uuid);
            navigate('/auth/shopping-cart?payment=true');
            // @ts-ignore
            window.location.reload();
        } catch (error) {
            createDSMError(error);
        }
    }
    // Tienes una inscripcion la cual ya se ha completado su numero maximo de inscripciónes.

    // Si quiere seguir con el proceso de pago de alguna otra inscripcion primero elimine las inscripciones marcadas
    return (
        <View>
            {showTitle && <Title text={t('shoppingCart.title')} uppercase fontWeight={'bold'} />}
            {closedEventCategories.length > 0 &&
                <View style={{ backgroundColor: '#FFDDDD', borderColor: 'red', borderWidth: 1, padding: 20, marginBottom: 10 }}>
                    <Text color={'#FF1111'} uppercase>{t('3x3.register.inscriptionLocked')}</Text>
                    <Text color={'#FF1111'} uppercase>{t('3x3.register.inscriptionLockedText')}</Text>
                </View>
            }
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <View style={styles.card}>
                    <View style={{ alignItems: 'center' }}>
                        <Title text={t('3x3.register.otherElements')} justifyContent="center" uppercase fontWeight='bold' />
                        {context.shoppingCart.details.map((detail) => {
                            if (detail.paid) return null;

                            if (detail.deleted) return null;

                            if (!detail.teamEventCategory.eventCategory.inscriptionOpen && detail.teamEventCategory.eventCategory.maxInscriptionsStatus !== STATUS.CREATED) {
                                return (
                                    <View id={detail.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: 5, backgroundColor: '#FFDDDD', borderColor: 'red', borderWidth: 1 }}>
                                        <IconButton backgroundColor={'white'} color={context.configuration.colors.primary.main} name={"trash"} onPress={() => removeFromCart(detail.id)} />
                                        <View>
                                            <Text color={'#FF1111'} bold>{detail.teamEventCategory.eventCategory.eventName}</Text>
                                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text color={'#FF1111'}>{detail.teamEventCategory.team.name}</Text>
                                                <Text fontSize={10} color={'#FF1111'} >{detail.teamEventCategory.eventCategory.name}</Text>
                                            </View>
                                        </View>
                                        <Text color={'#FF1111'}>{detail.total}€</Text>
                                    </View>
                                )
                            }

                            return (
                                <View id={detail.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: 5 }}>
                                    <IconButton backgroundColor={'white'} color={context.configuration.colors.primary.main} name={"trash"} onPress={() => removeFromCart(detail.id)} />
                                    <View>
                                        <Text bold>{detail.teamEventCategory.eventCategory.eventName}</Text>
                                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Text>{detail.teamEventCategory.team.name}</Text>
                                            <Text fontSize={10} >{detail.teamEventCategory.eventCategory.name}</Text>
                                        </View>
                                    </View>
                                    <Text>{detail.total}€</Text>
                                </View>
                            )
                        })}

                        {context.shoppingCart.details.filter(d => !d.paid && !d.deleted).length === 0 && (
                            <Alert type='success' text={t('3x3.register.noInscriptionsLeft')} />
                        )}
                    </View>
                </View>
                <View style={[styles.card, { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: 200 }]}>
                    <Title text={t("3x3.register.total")} justifyContent="center" uppercase fontWeight='bold' />
                    <Text style={{ marginBottom: 25, textAlign: 'center' }} fontSize={28}>{calculateTotal()} €</Text>
                    <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: 'space-around', gap: 10 }}>
                        {isFree()
                            ? <Button text={t('3x3.register.payFree')} disabled={(context?.shoppingCart.details.filter(d => !d.paid).length === 0 || closedEventCategories.length > 0)} containerStyle={{ borderColor: context.configuration.colors.primary.main, borderWidth: 1 }} onPress={payFree} marginBottom={0} />
                            : <Button text={t('3x3.register.pay')} disabled={context?.shoppingCart.details.filter(d => !d.paid).length === 0 || closedEventCategories.length > 0} containerStyle={{ borderColor: context.configuration.colors.primary.main, borderWidth: 1 }} onPress={redirectToPayment} marginBottom={0} />
                        }
                        <Button type="link" containerStyle={{ borderColor: context.configuration.colors.primary.main, borderWidth: 1 }} text={t('3x3.register.startAnotherInscription')} onPress={restartRegister} marginBottom={0} />
                    </View>
                </View>
            </View>
            <Modal visible={paymentErrorModal} title={t('3x3.register.paymentErrorTitle')} close={() => setPaymentErrorModal(false)}>
                <Alert text={t('3x3.register.paymentErrorAlert')} />
                <Text style={{ marginBottom: styling.spacing }}>{t('3x3.register.paymentErrorDescription')}</Text>
                <Separator height={1} width='100%' backgroundColor="#eee" marginBottom={styling.spacing} />
                <Button text={t('global.accept')} onPress={() => setPaymentErrorModal(false)} marginBottom={0} />
            </Modal>
            <Modal visible={paymentModal} title={t('3x3.register.paymentTitle')} close={() => setPaymentModal(false)}>
                <Alert type='success' text={t('3x3.register.paymentAlert')} />
                <Text style={{ marginBottom: styling.spacing }}>{t('3x3.register.paymentDescription')}</Text>
                <Separator height={1} width='100%' backgroundColor="#eee" marginBottom={styling.spacing} />
                <Button text={t('global.accept')} onPress={() => setPaymentModal(false)} marginBottom={0} />
            </Modal>
        </View>
    )
}
const styles = StyleSheet.create({
    card: {
        width: 500,
        borderWidth: 1,
        borderColor: '#eee',
        borderRadius: 5,
        padding: 15,
    },
    description: {
        marginBottom: 25,
    },
});
