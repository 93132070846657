import { Alert, Loader, t, useComponentDidMount } from "owc-react-lib-common";
import * as React from 'react';
import { useParams } from "react-router-dom";
import { Container } from "src/components/Container";
import { Stepper } from "src/components/Stepper";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import * as EventService from 'src/services/EventService';
import * as ShoppingCartService from 'src/services/ShoppingCartService';
import * as Team3x3Service from 'src/services/Team3x3Service';
import { RegisterTeam3x3Actors } from "./RegisterTeam3x3Actors";
import { RegisterTeam3x3Category } from "./RegisterTeam3x3Category";
import { RegisterTeam3x3Payment } from "./RegisterTeam3x3Payment";
import { RegisterTeam3x3PaymentRatings } from "./RegisterTeam3x3PaymentRatings";
import { RegisterTeam3x3Team } from "./RegisterTeam3x3Team";
import { RegisterTeam3x3Validation } from "./RegisterTeam3x3Validation";

export enum STEPS {
  EVENT_CATEGORY,
  TEAM,
  ACTORS,
  VALIDATION,
  RATINGS,
  TPV,
  SUCCESS,
};

export enum STATUS {
  CREATED = 0,
  COMPLETE = 4,
  INVITE = 5
}

export const RegisterTeam3x3 = () => {
  const context = React.useContext(OWCOpenDmrContext);
  const { eventUuid } = useParams();
  const { createDSMError, create } = useAlerts();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [step, setStep] = React.useState<STEPS>(STEPS.EVENT_CATEGORY);
  const [selected, setSelected] = React.useState<STEPS>(STEPS.EVENT_CATEGORY);
  const [eventCategories, setEventCategories] = React.useState<any>([]);
  const [eventCategory, setEventCategory] = React.useState<any>();
  const [teams, setTeams] = React.useState<any[]>([]);
  const [team, setTeam] = React.useState<any>();
  const [actors, setActors] = React.useState<any[]>([]);
  const [selectedRate, setSelectedRate] = React.useState<any>();
  const [teamEventCategory, setTeamEventCategory] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await Promise.all([
        Team3x3Service.getAll(eventUuid),
        EventService.findByUuid(eventUuid),
        // CompetitionService.findByUuid(competitionUuid),
      ]);


      setTeams(response[0].data);
      setEventCategories(response[1].data.eventCategories);
    } catch (error) {
      createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * select event category
   */
  const selectCategory = React.useCallback((object: any) => {
    setSelected(STEPS.TEAM);

    if (eventCategory?.id === object.id) {
      return;
    }

    setEventCategory(object);
    setTeam(undefined);
    setStep(STEPS.TEAM);
  }, [eventCategory]);

  /**
   * select team
   */
  const selectTeam = React.useCallback((object: any) => {
    setSelected(STEPS.ACTORS);

    if (team?.id === object.id) {
      return;
    }

    setTeam(object);
    setActors([]);
    setStep(STEPS.ACTORS);
  }, [team]);

  /**
   * 
   */
  const selectActors = React.useCallback((objects: any[]) => {
    setActors(objects);
    setStep(STEPS.VALIDATION);
    setSelected(STEPS.VALIDATION);
  }, []);

  /**
   * save
   */
  const save = React.useCallback(async (rate: any) => {
    try {
      setSelectedRate(rate);
      const response = await Team3x3Service.register(team.uuid, eventCategory.id, actors);
      await addToCart(response.data.uuid, rate.amount);
      if (response.data.status === STATUS.CREATED) {
        setStep(STEPS.TPV);
        setSelected(STEPS.TPV);
        setTeamEventCategory(response.data);
      }
      else {
        setStep(STEPS.SUCCESS);
        setSelected(STEPS.SUCCESS);
      }
    } catch (error) {
      createDSMError(error);
    }
  }, [team, eventCategory, actors, createDSMError, selectedRate]);

  const addToCart = async (teamEventCategoryUuid, rate) => {
    try {
      const response = await ShoppingCartService.addToCart(
        {
          teamEventCategory: { uuid: teamEventCategoryUuid },
          total: rate,
          paid: false
        }
      );
      create({ text: t('3x3.register.addToCartSuccess'), type: 'success' })
      context.shoppingCart.details.push(response);
    } catch (error) {
      createDSMError(error);
    }
  }

  const next = () => {
    setStep(step + 1);
    setSelected(selected + 1)
  }

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  const headers = [
    { value: STEPS.EVENT_CATEGORY, label: eventCategory?.name || t('3x3.register.categorySelectionStep') },
    { value: STEPS.TEAM, label: team?.name || t('3x3.register.teamSelectionStep') },
    { value: STEPS.ACTORS, label: t('3x3.register.actorsStep') + (actors.length ? ' (' + actors.length + ')' : '') },
    { value: STEPS.VALIDATION, label: t('3x3.register.validationStep') },
    { value: STEPS.TPV, label: t('3x3.register.ratingsStep') },
    { value: STEPS.TPV, label: t('3x3.register.tpvStep') },
    { value: STEPS.SUCCESS, label: t('3x3.register.successStep') },
  ];

  return (
    <Container title={t('3x3.register.title')}>
      <Stepper headers={headers} step={step} selected={selected} onPress={setSelected}>
        <RegisterTeam3x3Category eventCategories={eventCategories.filter(ec => ec.visibility)} onSelectCategory={selectCategory} />
        <RegisterTeam3x3Team eventCategory={eventCategory} teams={teams} onSelectTeam={selectTeam} />
        <RegisterTeam3x3Actors eventCategory={eventCategory} team={team} actors={actors} onSelectActors={selectActors} />
        <RegisterTeam3x3Validation eventCategory={eventCategory} team={team} actors={actors} onSave={next} />
        <RegisterTeam3x3PaymentRatings eventCategory={eventCategory} onSave={save} />
        <RegisterTeam3x3Payment teamEventCategory={teamEventCategory} eventCategory={eventCategory} rate={selectedRate} />
        <Alert text={t('3x3.register.success')} type='success' />
      </Stepper>
    </Container>
  );
}