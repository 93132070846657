import * as React from "react";
import { t } from "owc-react-lib-common";
import { Alert } from "src/components/Alert";
import { IAlert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Input } from "owc-react-lib-common"
import { ModalConfirm } from "src/components/ModalConfirm";
import { ITableHeader, Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { useAlerts } from "src/hooks/useAlerts";
import { useRegistrationStatus } from "src/hooks/useRegistrationStatus";
import * as LicenceUtils from "src/utils/LicenceUtils";
import { GENDER_NON_BINARY, ROLE_COACH, ROLE_PLAYER, TEAM_STATUS_VALIDATED, TEAM_STATUS_PROCESSED } from "./config";
import { TeamCreationQuestion } from "./TeamCreationQuestion";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { View } from "src/components/View";

interface IEventSummary {
  data: any;
  back?: () => void;
  save?: () => Promise<void> | void;
  process?: () => Promise<void> | void;
  setSelectedStep?: (index: number) => void;
  category?: any;
  update?: boolean;
  questions?: any[];
  answers?: any[];
}

export const TeamCreationSummary = ({
  data,
  back = () => { },
  save = () => { },
  process = () => { },
  setSelectedStep = () => { },
  update = false,
  category = {},
  questions = [],
  answers = [],
}: IEventSummary) => {
  const alerts = useAlerts();
  const [saveModal, setSaveModal] = React.useState(false);
  const [processModal, setProcessModal] = React.useState(false);
  const { renderRegistrationStatus, renderLegend } = useRegistrationStatus();

  /**
   * open save modal
   */
  const openSaveModal = React.useCallback(() => {
    setSaveModal(true);
  }, []);

  /**
   * open process modal
   */
  const openProcessModal = React.useCallback(() => {
    setProcessModal(true);
  }, []);

  /**
   * validate process status
   */
  const validate = React.useCallback(() => {
    const errors = [];

    // team image
    // if (!data.teamDTO.teamImageFile && !data.teamDTO.teamImageUrl) {
    //   errors.push(t('auth.competitions.teamCreation.summary.errors.image'));
    // }

    // team logo
    // if (!data.teamDTO.teamLogoFile && !data.teamDTO.teamLogoUrl) {
    //   errors.push(t('auth.competitions.teamCreation.summary.errors.logo'));
    // }

    // player amount
    if (data.actors.filter((actor: any) => actor.roleId === ROLE_PLAYER).length < (category.minPlayers || 0)) {
      errors.push(t("auth.competitions.teamCreation.summary.errors.minPlayers", { amount: category.minPlayers }));
    } else if (data.actors.filter((actor: any) => actor.roleId === ROLE_PLAYER).length > (category.maxPlayers || 99)) {
      errors.push(t("auth.competitions.teamCreation.summary.errors.maxPlayers", { amount: category.maxPlayers || 99 }));
    }

    // coach amount
    if (data.actors.filter((actor: any) => actor.roleId === ROLE_COACH).length < 1) {
      errors.push(t("auth.competitions.teamCreation.summary.errors.minCoaches", { amount: 1 }));
    } else if (data.actors.filter((actor: any) => actor.roleId === ROLE_COACH).length > (category.maxCoaches || 99)) {
      errors.push(t("auth.competitions.teamCreation.summary.errors.maxCoaches", { amount: category.maxCoaches || 99 }));
    }

    // show errors
    errors.forEach((error: string) => alerts.create({ text: error, type: "error" }));

    // return
    return errors.length === 0;
  }, [data, alerts, category]);

  /**
   * on save
   */
  const onSave = React.useCallback(async () => {
    // if (!validate()) {
    //   return;
    // }

    try {
      await save();
      setSaveModal(false);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [save, alerts]);

  /**
   * on process
   */
  const onProcess = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      // process
      await process();

      // close modal
      setProcessModal(false);

      // reload
      window.location.reload();
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [process, alerts, validate]);

  /**
   * render actor gender
   */
  const renderActorGender = React.useCallback((object: any) => {
    if (object.gender === GENDER_NON_BINARY) {
      return "NB";
    }

    return object.gender;
  }, []);

  /**
   * render
   */
  const headers: ITableHeader[] = [
    { name: "peopleName", label: t("auth.competitions.teamCreation.actors.name") },
    { name: "peopleSurname", label: t("auth.competitions.teamCreation.actors.surname") },
    {
      name: "gender",
      label: t("auth.competitions.teamCreation.actors.gender"),
      render: (object: any) => <Text>{renderActorGender(object)}</Text>,
      width: 70,
    },
    { name: "peopleEmail", label: t("auth.competitions.teamCreation.actors.email") },
    { name: "formattedBirthDate", label: t("auth.competitions.teamCreation.actors.birthDate"), width: 100 },
    { 
      name: "roleDescription", 
      label: t("auth.competitions.teamCreation.actors.role") ,
      render: (object: any) => {
        if(object.licenseTypeShort){
          return(
            <Text>{t("global.licenseTypes."+object.licenseTypeShort)}</Text>
          )
        }
        return(
          <Text>{object.roleDescription}</Text>
        )
      }
    },
    {
      name: "number",
      label: t("auth.competitions.teamCreation.actors.number"),
      render: (object: any) => <Text>{parseInt(object.number) === 100 ? "00" : object.number}</Text>,
      width: 80,
    },
    { name: "shirtSize", label: t("auth.competitions.teamCreation.actors.size"), width: 60 },
    { name: "rateName", label: t("auth.competitions.teamCreation.actors.rate") },
    {
      name: "price",
      label: t("auth.competitions.teamCreation.actors.price"),
      render: (object: any) => (
        <Text>{LicenceUtils.getPrice(object, category.eventNumNonPlayerBonusRate, 0, data.actors)} €</Text>
      ),
      width: 80,
    },
  ];

  if (data?.teamDTO?.registerStatus >= TEAM_STATUS_PROCESSED) {
    headers.push({
      name: "status",
      label: t("auth.competitions.teamCreation.actors.status"),
      render: renderRegistrationStatus,
    });
  }

  const editable = !data?.teamDTO?.registerStatus;
  const alert: IAlert =
    data?.teamDTO?.registerStatus === TEAM_STATUS_PROCESSED
      ? { text: t("auth.competitions.teamCreation.summary.processedInfo"), type: "info" }
      : data?.teamDTO?.registerStatus === TEAM_STATUS_VALIDATED
        ? { text: t("auth.competitions.teamCreation.summary.successInfo"), type: "success" }
        : { text: t("auth.competitions.teamCreation.summary.step"), type: "info" };
  return (
    <>
      {!!alert && <Alert {...alert} />}
      <Form>
        <Row>
          <Input
            label={t("auth.competitions.teamCreation.summary.category")}
            value={data.teamDTO.eventCategoryName || ""}
            disabled
          />
        </Row>
        <Row flex={[3, 1]}>
          <Input label={t("auth.competitions.teamCreation.summary.team")} value={data.teamDTO.name || ""} disabled />
          <Input
            label={t("auth.competitions.teamCreation.summary.level")}
            value={data.teamDTO.teamLevelName || ""}
            disabled
          />
        </Row>
        {!!category?.extraInformationQuestions?.length && (
          <Row>
            {category.extraInformationQuestions.map((question: any) => {
              const answer = data?.teamDTO?.extraInformation?.find((answer: any) => parseInt(answer.questionId) === parseInt(question.id));
              return <TeamCreationQuestion key={question.id} data={question} value={answer?.value} required disabled />;
            })}
          </ Row>
        )}
        {/* <Row>
          {editable && (
            <FloatingIconButton
              name="edit"
              title={t("global.edit")}
              onPress={() => setSelectedStep?.(1)}
              top={0}
              bottom={0}
              right={0}
            />
          )}
        </Row> */}
        <Row>
          <Input
            label={t("auth.competitions.teamCreation.summary.name")}
            value={data.teamDTO.contactName || ""}
            disabled
          />
          <Input
            label={t("auth.competitions.teamCreation.summary.surname")}
            value={data.teamDTO.contactSurname || ""}
            disabled
          />
          <Input
            label={t("auth.competitions.teamCreation.summary.email")}
            value={data.teamDTO.contactEmail || ""}
            disabled
          />
          <Input
            label={t("auth.competitions.teamCreation.summary.phone")}
            value={data.teamDTO.contactPhone || ""}
            disabled
          />
        </Row>
        <Row>
          <Input
            label={t("auth.competitions.teamCreation.summary.observations")}
            value={data.teamDTO.observations || ""}
            disabled
          />
        </Row>
        <Row>
          <Table pagination={false} headers={headers} data={data.actors} defaultSize={25} />
        </Row>
        <Row>{data?.teamDTO?.registerStatus >= TEAM_STATUS_PROCESSED && renderLegend()}</Row>
        {/* <Row>
          {editable && (
            <FloatingIconButton
              name="edit"
              title={t("global.edit")}
              onPress={() => setSelectedStep?.(2)}
              top={0}
              bottom={0}
              right={0}
            />
          )}
        </Row> */}
        <Row>
          {editable &&
            (!update ? (
              <View justifyContent="space-between" flexDirection="row">
                <Button type="primary" label={t("global.back")} onPress={back} />
                <View flexDirection="row" style={{ gap: 10 }}>
                  <Button label={t("global.save")} onPress={openSaveModal} />
                  <Button
                    label={t("auth.competitions.teamCreation.summary.saveAndValidate")}
                    onPress={openProcessModal}
                  />
                </View>
              </View>
            ) : (
              <>
                <Button label={t("auth.competitions.teamCreation.summary.validate")} onPress={openProcessModal} />
              </>
            ))}
        </Row>
      </Form>
      <ModalConfirm
        visible={saveModal}
        text={t("auth.competitions.teamCreation.summary.saveConfirmation")}
        onSuccess={onSave}
        onClose={() => setSaveModal(false)}
      />
      <ModalConfirm
        visible={processModal}
        text={t("auth.competitions.teamCreation.summary.validateConfirmation")}
        onSuccess={onProcess}
        onClose={() => setProcessModal(false)}
      />
    </>
  );
};
