import * as React from 'react';
import { ColorValue, Image, ImageResizeMode, StyleSheet, View } from 'react-native';
import { useColors } from 'src/hooks/useColors';
import { Text } from './Text';
import { Button as OWCButton } from 'owc-react-lib-common';

interface IPhotoCircle {
	url?: any;
	size?: number;
	backgroundColor?: ColorValue;
	borderColor?: ColorValue;
	style?: any;
	onPress?: () => void;
	resizeMode?: ImageResizeMode;
	alt?: string;
	borderRadius?: number;
	borderWidth?: number;
};

export const PhotoCircle = ({ size = 80, borderColor, resizeMode = 'contain', backgroundColor = 'white', style, onPress, url = '', alt, borderRadius = 99, borderWidth = 1 }: IPhotoCircle) => {
	const colors = useColors('primary');
	borderColor = borderColor || colors.main;
	const fontSize = size / 100 * 40;


	/**
	 * render image
	 */
	const renderImage = React.useCallback(() => {
		// render alt
		if (!url?.length && !!alt) {
			return (
				<View style={[styles.container, { height: size, borderWidth, width: size, borderRadius, borderColor: colors.main, backgroundColor: colors.main }, style]}>
					<View style={{ alignSelf: 'center', justifyContent: 'center', flex: 1 }}>
						<Text fontSize={fontSize} uppercase color={colors.text}>{alt?.substring(0, 1)}</Text>
					</View>
				</View>
			)
		}

		// render image
		return (
			<View style={[styles.container, { width: size, height: size, borderRadius: size / 2, borderColor }, style]}>
				<Image source={{ uri: url }} style={[{ backgroundColor, resizeMode }, styles.image]} />
			</View>
		);
	}, [size, borderWidth, borderRadius, style, fontSize, colors.text, borderColor, backgroundColor, resizeMode, url, alt, colors.main]);

	/**
	 * render with touchable condition
	 */
	const render = React.useCallback(() => {
		// without touchable
		if (!onPress) {
			return renderImage();
		}

		// with touchable
		return (
			<OWCButton onPress={onPress} >
				{renderImage()}
			</OWCButton>
		)
	}, [onPress, renderImage]);

	/**
	 * render
	 */
	return (
		render()
	);
}

const styles = StyleSheet.create({
	container: {
		overflow: 'hidden',
		borderWidth: 2,
	},
	image: {
		width: '100%',
		height: '100%',
		alignItems: 'center',
		// resizeMode: 'contain'
	}
});