import { Picker, t } from "owc-react-lib-common";
import * as React from "react";

import { Input } from "owc-react-lib-common";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { Container } from "src/components/Container";
import { Form } from "src/components/Form";
import { IconButton } from "src/components/IconButton";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Row } from "src/components/Row/Row";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useColors } from "src/hooks/useColors";
import { useUserPermission } from "src/hooks/useUserPermission";
import { EMAIL, IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as OrganizerService from "src/services/OrganizerService";
import { IPortal } from "src/services/PortalService";

interface IOrganizerUpdate {
  onSave: (object: any) => void;
  data: any;
  portals: IPortal[];
}

export const OrganizerUpdate = ({ onSave, data, portals }: IOrganizerUpdate) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const colors = useColors('primary');
  const { validateAll } = useValidation();

  const [errors, setErrors] = React.useState<any>({});
  const [newData, setNewData] = React.useState<any>({ ...data });

  /**
   * validate
   */
  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "contactEmail", type: [REQUIRED, EMAIL] },
    ];

    const { errors, hasErrors } = validateAll(newData, fields);
    setErrors(errors);
    return !hasErrors;
  }, [newData, validateAll]);

  /**
   * on change logo
   */
  const onChangeLogo = React.useCallback((value: IImageUploaderData) => {
    setNewData({
      ...newData,
      logoFile: value.base64,
      originalLogoFilename: value.name,
    });
  }, [newData]);

  /**
   * save
   */
  const save = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      await OrganizerService.update(newData);
      alerts.create({ text: t("global.success"), type: "success" });
      onSave(newData);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, newData, validate, onSave]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.token)
    alerts.create({ text: t("global.copySuccess"), type: "success" });
  };

  const refreshToken = async () => {
    try {
      const response = await OrganizerService.refreshTokenByOrganizerUuid(data.uuid);
      setNewData({ ...newData, token: response });
      alerts.create({ text: t("global.success"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }
  /**
   * render
   */
  return (
    <Container title={t("admin.organizer.list.title")}>
      <View flexDirection="row">
        <Title text={t("admin.organizer.list.updateOrganizer")} containerStyle={{ flex: 1 }} />
        <ButtonLink
          type='link'
          color={colors.main}
          to={`/admin/organizers`}
          label={`« ${t("admin.organizer.list.title")}`}
          contentStyle={{ right: 15 }}
        />
      </View>
      <Form>
        <Row>
          <View>
            <Input
              label={t("admin.portal.token")}
              value={newData.token}
              disabled
            />
            <View style={{ position: 'absolute', right: 5, top: '35%', display: 'flex', flexDirection: 'row', gap: 5 }}>
              <IconButton
                containerStyle={{}}
                backgroundColor={'#AAAAAAAA'}
                color={'#444444'}
                fontSize={20}
                name={"clipboard"}
                onPress={copyToClipboard}
              />
              <IconButton
                containerStyle={{}}
                backgroundColor={'#AAAAAAAA'}
                color={'#444444'}
                fontSize={20}
                name={"backtime"}
                onPress={refreshToken}
              />
            </View>
          </View>
        </Row>
        <Row>
          <Input
            label={t("admin.organizer.edit.name")}
            defaultValue={data.name}
            onChange={(val) => setNewData({ ...newData, name: val })}
            error={errors.name}
            disabled={readOnly}
          />
          <Input
            label={t("admin.organizer.edit.contactEmail")}
            defaultValue={data.contactEmail}
            onChange={(val) => setNewData({ ...newData, contactEmail: val })}
            error={errors.contactEmail}
            disabled={readOnly}
          />
          <Input
            label={t("admin.organizer.edit.phoneNum")}
            defaultValue={data.phoneNum}
            onChange={(val) => setNewData({ ...newData, phoneNum: val })}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <Input
            label={t("admin.organizer.edit.cif")}
            defaultValue={data.cif}
            onChange={(val) => setNewData({ ...newData, cif: val })}
            error={errors.cif}
            disabled={readOnly}
          />
          <Input
            label={t("admin.organizer.edit.code")}
            defaultValue={data.code}
            onChange={(val) => setNewData({ ...newData, code: val })}
            error={errors.code}
            disabled={readOnly}
            numeric
          />
          <Input
            label={t("admin.organizer.edit.description") + ` (${t('global.optional')})`}
            defaultValue={data.description}
            onChange={(val) => setNewData({ ...newData, description: val })}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <Picker
            label={t("admin.organizer.edit.portal")}
            items={portals.map(p => ({ label: p.portal, value: p.id }))}
            onChange={(val) => setNewData({ ...newData, portalId: val })}
            defaultValue={data.portalId}
          />
        </Row>
        <Row>
          <ImageUploader
            label={t("admin.organizer.edit.selectLogo")}
            defaultValue={data.logoUrl}
            onChange={onChangeLogo}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <Button label={t("global.save")} onPress={save} disabled={readOnly} />
        </Row>
      </Form>
    </Container>
  );
};
