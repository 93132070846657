import moment from "moment";
import { Input, Picker, t } from "owc-react-lib-common";
import * as React from "react";
import { Button } from "src/components/Button";
import { Checkbox } from "src/components/Checkbox";
import { ColorPicker } from "src/components/ColorPicker";
import { Form } from "src/components/Form";
import { Icon } from "src/components/Icon";
import { IconButton } from "src/components/IconButton";
import { Modal } from "src/components/Modal";
import { Row } from "src/components/Row/Row";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { GENDER_FEMALE, GENDER_MALE, GENDER_MIXED } from "src/config";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import { useUserPermission } from "src/hooks/useUserPermission";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import { TEAM_STATUS_COMPLETED, TEAM_STATUS_PENDING, TEAM_STATUS_PROCESSED, TEAM_STATUS_VALIDATED } from "src/pages/auth/components/config";
import * as CategoryService from 'src/services/CategoryService';
import * as EventService from "src/services/EventService";
import * as InvitationService from "src/services/InvitationService";
import { Invitation } from "src/services/InvitationService";
import { EventContext } from "../EventContext";
interface IEventCategoryDetail {
  data: any;
  onCreate?: (object: any) => void;
}

export const EventCategoryDetail = ({ data, onCreate }: IEventCategoryDetail) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [dmrTemplates, setDmrTemplates] = React.useState<any[]>([]);
  const [dmrAllowed, setDmrAllowedInput] = React.useState<boolean>(data.dmrAllowed);
  const { data: event, setData: setEvent } = React.useContext(EventContext);
  const alerts = useAlerts();
  const { validateAll, email } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [categories, setCategories] = React.useState<any>([]);
  const [massSendModal, setMassSendModal] = React.useState<boolean>(false);
  const [emails, setEmails] = React.useState<any[]>([]);
  const form = React.useRef<any>(data);
  const invitationLink = window.location.hostname + '/auth/competitions/' + event.competitionUuid + '/event/' + event.uuid + '?eventCategoryId=' + form.current.id

  const setName = React.useCallback((value: string) => (form.current.name = value), []);
  const setColor = React.useCallback((value: string) => (form.current.color = value), []);
  const setMatchTime = React.useCallback((value: string) => (form.current.matchTime = value), []);
  const setMinAge = React.useCallback((value: string) => (form.current.minAge = value), []);
  const setMaxAge = React.useCallback((value: string) => (form.current.maxAge = value), []);
  const setMinPlayers = React.useCallback((value: string) => (form.current.minPlayers = value), []);
  const setMaxPlayers = React.useCallback((value: string) => (form.current.maxPlayers = value), []);
  const setMaxCoaches = React.useCallback((value: string) => (form.current.maxCoaches = value), []);
  const setMaxInscriptions = React.useCallback((value: string) => (form.current.maxInscriptions = value), []);
  const setMaxInscriptionsStatus = React.useCallback((value: string) => (form.current.maxInscriptionsStatus = value), []);
  const setWaitingRoomText = React.useCallback((value: string) => (form.current.waitingRoomText = value), []);
  const setComments = React.useCallback((value: string) => (form.current.comments = value), []);
  const setGender = React.useCallback((value: string) => (form.current.gender = value), []);
  const setCategory = React.useCallback((value: string) => (form.current.categoryId = value), []);
  const setEnrollmentOpen = React.useCallback((value: string) => (form.current.inscriptionOpen = value === "true"), []);
  const setVisibility = React.useCallback((value: string) => (form.current.visibility = value === "true"), []);
  const setLogoRequired = React.useCallback((value: string) => (form.current.logoRequired = value === "true"), []);
  const setPictureRequired = React.useCallback((value: string) => (form.current.pictureRequired = value === "true"), []);
  
  const setDmrAllowed = React.useCallback((checked: boolean) => {
    form.current.dmrAllowed = checked;
    if (!checked) {
      form.current.digitalMatchRecordConfigId = null;
    }

    setDmrAllowedInput(checked);
  }, [dmrAllowed]);
  const setDigitalMatchRecordConfigId = React.useCallback((value: string) => (form.current.digitalMatchRecordConfigId = value), []);


  const copyToClipboard = () => {
    navigator.clipboard.writeText(invitationLink)
    alerts.create({ text: t("global.copySuccess"), type: "success" });
  };

  const openMassSendModal = () => {
    setMassSendModal(!massSendModal);
  }

  const addEmails = (emails) => {
    if (emails.length === 0) {
      setEmails([]);
      return;
    }
    const emailsArray = Array.from(new Set(emails.split(',')))
    const a = emailsArray.map((e: string) => {
      return {
        email: e,
        error: email(e)
      }
    })
    setEmails(a);
  }

  const sendInvitations = async () => {
    const payload: Invitation[] = emails.map(email => {
      return {
        email: email.email,
        sentAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        eventCategory: data,
        sender: null
      }
    })
    await InvitationService.massSend(payload).then((response) => {
      if (response.status === 200) {
        alerts.create({ text: t("global.success"), type: "success" });
      }
    })
    setMassSendModal(false)
  }
  /**
   * 
   */
  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "inscriptionOpen", type: REQUIRED },
      // { name: "color", type: REQUIRED },
      { name: "gender", type: REQUIRED },
      { name: "categoryId", type: REQUIRED },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  /**
   * 
   */
  React.useEffect(() => {
    CategoryService.findAllCategories().then(r => {
      const map = r.data.map(cat => ({ label: cat.name, value: cat.id }))
      setCategories(map)
    });

    EventService.findDmrConfig(data.id).then(res => {
      setDmrTemplates(res.data);
    })
  }, []);

  /**
   * save
   */
  const updateEventCategory = React.useCallback(async () => {
    // validate
    if (!validate()) return;

    try {
      // request
      const response = await EventService.updateEventCategory(form.current);

      // update context
      const eventCategories = event.eventCategories.map((eventCategory: any) =>
        eventCategory.id === response.data.id ? response.data : eventCategory
      );
      setEvent({
        ...event,
        eventCategories,
      });

      // update form data
      form.current = response.data;
      alerts.create({ text: t("global.success"), type: "success" });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [event, setEvent, alerts, validate]);

  /**
   * 
   */
  const createEventCategory = React.useCallback(async () => {
    if (!validate()) return;
    form.current.eventId = event.id;
    onCreate(form.current);
  }, [onCreate, event.id, validate]);

  /**
   * render
   */
  const genders = [
    { label: t("global.gender.female"), value: GENDER_FEMALE },
    { label: t("global.gender.male"), value: GENDER_MALE },
    { label: t("global.gender.mixed"), value: GENDER_MIXED },
  ];

  const yesNoOptions = [
    { label: t("global.yes"), value: "true" },
    { label: t("global.no"), value: "false" },
  ];

  const teamStatus = [
    { label: t("admin.teams.status.pending"), value: TEAM_STATUS_PENDING },
    { label: t("admin.teams.status.processed"), value: TEAM_STATUS_PROCESSED },
    { label: t("admin.teams.status.validated"), value: TEAM_STATUS_VALIDATED },
    { label: t("admin.teams.status.completed"), value: TEAM_STATUS_COMPLETED },
  ];

  // pol@optimalwayconsulting.com,oriol.garcia@optimalwayconsulting.com,invitat@optimalwayconsulting.com,notorganizer@optimalwayconsulting.com,alvaro@optimalwayconsulting.com,alvaro@optimalwayconsulting.com

  return (
    <Form>
      <Modal title={t("admin.events.massSendModal")} visible={massSendModal} onClose={() => { setMassSendModal(false); setEmails([]) }}>
        <Form>
          <Row>
            <Input
              label={t("admin.events.emails")}
              numberOfLines={5}
              onChange={addEmails}
            />
          </Row>
          <Row>
            <Text>
              {t("admin.events.numberOfEmails")}: {emails.length}&nbsp;
              {t("admin.events.numberOfWrongEmails")}: {emails.filter(e => e.error).length}&nbsp;
              {t("admin.events.numberOfGoodEmails")}: {emails.filter(e => !e.error).length}
            </Text>
          </Row>
          <Row>
            <View flexDirection="row" style={{ maxHeight: 500, maxWidth: 800, overflow: 'scroll', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {emails.length > 0 && emails.map(e => {
                return (
                  <View flexDirection="row" style={{ alignItems: 'center', flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                    <Text color={e.error ? 'red' : ''}>{e.email}</Text>
                    {
                      e.error
                        ? <><Icon style={{ marginTop: 5 }} name={'ko'} text="hola" /> <Text color={'red'}>{e.error}</Text></>
                        : <Icon style={{ marginTop: 20 }} name={'ok'} />
                    }
                  </View>
                )
              })}
            </View>

          </Row>
          <Row  >
            <Button containerStyle={{ marginTop: 20 }} label={t("global.send")} onPress={sendInvitations} />
          </Row>
        </Form>
      </Modal>
      <Row>
        <View>
          <Input
            label={t("admin.events.invitationLink")}
            defaultValue={invitationLink}
            disabled
          />
          <View style={{ position: 'absolute', right: 5, top: '35%', display: 'flex', flexDirection: 'row', gap: 5 }}>
            <IconButton
              containerStyle={{}}
              backgroundColor={'#AAAAAAAA'}
              color={'#444444'}
              fontSize={20}
              name={"clipboard"}
              onPress={copyToClipboard} />
            <IconButton
              containerStyle={{}}
              backgroundColor={'#AAAAAAAA'}
              color={'#444444'}
              fontSize={20}
              name={"send"}
              onPress={openMassSendModal} />
          </View>
        </View>

      </Row>
      <Row flex={[2, 1, 1]}>
        <Input label={t("admin.events.name")} defaultValue={form.current.name} onChange={setName} error={errors.name} disabled={readOnly} required={!form.current.id} />

        <ColorPicker label={t("admin.events.color")} onChange={setColor} defaultValue={form.current.color} disabled={readOnly} />

        <Picker
          label={t("admin.events.visibility")}
          items={yesNoOptions}
          error={errors.visibility}
          defaultValue={form.current.visibility}
          onChange={setVisibility}
          disabled={readOnly}
          required
        />
        <Picker
          label={t("admin.events.enrollmentOpen")}
          items={yesNoOptions}
          error={errors.inscriptionOpen}
          defaultValue={form.current.inscriptionOpen}
          onChange={setEnrollmentOpen}
          disabled={readOnly}
          required
        />
        <Picker
          label={t("admin.events.logoRequired")}
          items={yesNoOptions}
          error={errors.logoRequired}
          defaultValue={form.current.logoRequired}
          onChange={setLogoRequired}
          disabled={readOnly}
          required
        />
        <Picker
          label={t("admin.events.pictureRequired")}
          items={yesNoOptions}
          error={errors.pictureRequired}
          defaultValue={form.current.pictureRequired}
          onChange={setPictureRequired}
          disabled={readOnly}
          required
        />
      </Row>

      <Row>
        <Picker
          label={t("admin.events.categories")}
          items={categories}
          error={errors.categoryId}
          defaultValue={form.current.categoryId}
          onChange={setCategory}
          disabled={readOnly}
          required
        />

        <Picker
          label={t("admin.events.gender")}
          items={genders}
          error={errors.gender}
          defaultValue={form.current.gender}
          onChange={setGender}
          disabled={readOnly}
          required
        />

        <Input label={t("admin.events.matchTime")} defaultValue={form.current.matchTime} onChange={setMatchTime} disabled={readOnly} numeric />
        <Input label={t("admin.events.maxCoaches")} defaultValue={form.current.maxCoaches} onChange={setMaxCoaches} disabled={readOnly} numeric />
      </Row>

      <Row>
        <Input label={t("admin.events.minPlayers")} defaultValue={form.current.minPlayers} onChange={setMinPlayers} disabled={readOnly} numeric />
        <Input label={t("admin.events.maxPlayers")} defaultValue={form.current.maxPlayers} onChange={setMaxPlayers} disabled={readOnly} numeric />
        <Input label={t("admin.events.minAge")} defaultValue={form.current.minAge} onChange={setMinAge} disabled={readOnly} numeric />
        <Input label={t("admin.events.maxAge")} defaultValue={form.current.maxAge} onChange={setMaxAge} disabled={readOnly} numeric />
      </Row>
      <Row flex={[1, 5]}>
        <Checkbox label={t("admin.events.dmrAllowed")} defaultValue={form.current.dmrAllowed} onChange={setDmrAllowed} />
      </Row>
      {dmrAllowed &&
        <Row >
          <Picker stylesContainer={{ maxWidth: 500 }} label={t("admin.events.dmrTemplate")} defaultValue={form.current.digitalMatchRecordConfigId} items={dmrTemplates.map(t => ({ label: t.templateConfigName, value: t.id }))} onChange={setDigitalMatchRecordConfigId} />
        </Row>
      }
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{ marginBottom: 15 }}>{t("admin.events.closeRegistrationsAt")}</Text>
        <Input placeholder='0' numeric defaultValue={form.current.maxInscriptions} onChange={setMaxInscriptions} error={errors.maxInscriptions} disabled={readOnly} containerStyle={{ marginHorizontal: 10 }} />
        <Text style={{ marginBottom: 15 }}>{t("admin.events.closeRegistrationsAtTeamStatus").toLowerCase()}</Text>
        <Picker items={teamStatus} defaultValue={form.current.maxInscriptionsStatus} onChange={setMaxInscriptionsStatus} error={errors.maxInscriptionsStatus} disabled={readOnly} containerStyle={{ marginHorizontal: 10, minWidth: 203 }} />
      </View>

      <Row>
        <Input label={t("admin.events.comments")} defaultValue={form.current.comments} onChange={setComments} disabled={readOnly} numberOfLines={8} />
      </Row>

      <Row>
        <Input label={t("admin.events.waitingRoomText")} defaultValue={form.current.waitingRoomText} onChange={setWaitingRoomText} disabled={readOnly} numberOfLines={8} />
      </Row>
      <Row>
        {form.current.id > 0 ? (
          <Button label={t("global.save")} onPress={updateEventCategory} disabled={readOnly} />
        ) : (
          <Button label={t("global.create")} onPress={createEventCategory} disabled={readOnly} />
        )}
      </Row>
    </Form>
  );
};
