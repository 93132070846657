import * as React from "react";
import { StyleSheet } from "react-native";
import { Picker, t } from 'owc-react-lib-common';

import { View } from "src/components/View";
import { Container } from "src/components/Container";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { Title } from "src/components/Title";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as UserService from "src/services/UserService";
import * as OrganizerService from "src/services/OrganizerService";

export const UserList = () => {

  const alerts = useAlerts();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [users, setUsers] = React.useState<any[]>([]);
  const [organizers, setOrganizers] = React.useState<any[]>([]);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const [users, o] = await Promise.all([UserService.getAll(), OrganizerService.findOrganizers()]);
      setOrganizers(o.data.map((a: { id: any; name: any; }, i: any) => ({ value: a.id, label: a.name, key: a.name + i })));
      setUsers(users.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  const updateUser = (user: any, value: any) => {
    user.organizerId = value;
    UserService.updateUser(user);
  }

  /**
   * get table row actions
   */
  const actions = React.useCallback(

    (user: any) => {
      return (
        <View key={user.id} style={{ flexDirection: "row" }}>
          <Picker
            label={t('admin.user.list.organizer')}
            onChange={(value) => updateUser(user, value)}
            items={organizers}
            defaultValue={user.organizerId ?? ''} containerStyle={styles.containerStyle}
          />
        </View>
      );
    },
    [organizers]
  );

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    {
      name: "id",
      label: t("admin.user.list.id"),
      width: 300,
      textProps: { uppercase: true },
    },
    {
      name: "email",
      label: t("admin.user.list.email"),
    },
    { name: "actions", label: " ", render: actions, width: 300 },
  ];

  return (
    <Container>
      <View flexDirection="row" style={styles.header}>
        <Title text={t("admin.user.list.title")} />
      </View>

      <Table
        headers={headers}
        data={users}
        containerStyle={{ marginBottom: 0 }}
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  containerStyle: {
    minWidth: "200px",
    maxWidth: "200px"
  }
});

