import * as React from "react";
import { Picker, t } from "owc-react-lib-common";
import { Button } from "src/components/Button";
import { IImageUploaderData, ImageUploader } from "src/components/ImageUploader";
import { Input } from "owc-react-lib-common"
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as CompetitionService from "src/services/CompetitionService";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";


interface ICompetitionUpdate {
  onSave: (object: any) => void;
  data: any;
  modalities: any[];
}

export const CompetitionUpdate = ({ onSave, data, modalities }: ICompetitionUpdate) => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const { validateAll } = useValidation();

  const [errors, setErrors] = React.useState<any>({});
  const [newData, setNewData] = React.useState<any>({ ...data });

  /**
   * validate
   */
  const validate = React.useCallback(() => {
    const fields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "modalityId", type: REQUIRED },
    ];

    const { errors, hasErrors } = validateAll(newData, fields);
    setErrors(errors);
    return !hasErrors;
  }, [newData, validateAll]);

  /**
   * on change logo
   */
  const onChangeLogo = React.useCallback(
    (value: IImageUploaderData) => {
      setNewData({
        ...newData,
        logoFile: value.base64,
        originalLogoFilename: value.name,
      });
    },
    [newData]
  );

  /**
   * save
   */
  const save = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      await CompetitionService.update(newData);
      alerts.create({ text: t("global.success"), type: "success" });
      onSave(newData);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, newData, validate, onSave]);

  /**
   * render
   */
  return (
    <View >
      <Form>
        <Row>
          <Input 
            label={t("admin.competitions.edit.uuid")}
            defaultValue={data.uuid}
            disabled
          />
        </Row>
        <Row flex={[1, 1, 2]}>
          <Input
            label={t("admin.competitions.edit.name")}
            defaultValue={data.name}
            onChange={(val) => setNewData({ ...newData, name: val })}
            error={errors.name}
            disabled={readOnly}
            required
          />
          <Picker
            label={t("admin.competitions.edit.modality")}
            items={modalities?.map(m => ({ label: m.name, value: m.id }))}
            defaultValue={data.modalityId}
            error={errors.modalityId}
            onChange={(val) => setNewData({ ...newData, modalityId: parseInt(val) })}
            disabled={readOnly}
            required
          />
          <Input
            label={t("admin.competitions.edit.description") + ` (${t('global.optional')})`}
            defaultValue={data.description}
            onChange={(val) => setNewData({ ...newData, description: val })}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <ImageUploader
            label={t("admin.competitions.edit.selectLogo")}
            defaultValue={data.logoUrl}
            onChange={onChangeLogo}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <Button label={t("global.save")} onPress={save} disabled={readOnly} />
        </Row>
      </Form>
    </View>
  );
};
