import { Modal as OWCModal } from 'owc-react-lib-common';
import { ViewProps } from 'react-native';
import { useKeypress } from 'src/hooks/useKeyPress';
export interface IModal {
  visible: boolean;
  onClose: () => void;
  children?: any;
  title?: string;
  subtitle?: string;
  width?: number;
  minHeight?: number;
  minWidth?: number;
  maxWidth?: number;
  maxHeight?: number;
  fullscreen?: boolean;
  header?: boolean;
  contentContainerStyle?: ViewProps['style'];
}

export const Modal = ({ visible, title, subtitle, onClose, children, width, minWidth, maxWidth = 820, minHeight, maxHeight, fullscreen = false, header = true, contentContainerStyle }: IModal) => {

  useKeypress('Escape', onClose);

  return (
    <OWCModal
      fullscreen={fullscreen}
      close={onClose}
      children={children}
      title={title}
      subtitle={subtitle}
      width={width}
      visible={visible}
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      header={header}
      contentContainerStyle={contentContainerStyle}
    />
  );
}