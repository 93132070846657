import * as React from 'react';
import { Image, StyleSheet } from "react-native";
import { Link, useLocation } from "react-router-dom";
import { Button } from "src/components/Button";
import { Separator } from 'src/components/Separator';
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { styling } from "src/config";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useColors } from "src/hooks/useColors";
import { getLangName } from 'src/utils/StringUtils';

interface IUserActionCard {
  tabs?: { to: string, label: string }[];
  children: any;
}

export const UserActionCard = ({ tabs, children }: IUserActionCard) => {
  const location = useLocation();
  const colors = useColors('primary');
  const context = React.useContext(OWCOpenDmrContext);

  /**
   * render
   */
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: context.configuration.portal.logo }} style={styles.image} />
      </View>

      <View flexDirection="row">
        {tabs?.map((tab: any) => (
          <Link
            key={tab.to}
            to={tab.to}
            style={{
              flex: 1,
              padding: styling.spacing,
              alignItems: 'center',
              justifyContent: 'center',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              textAlign: 'center',
              borderColor: location.pathname === tab.to ? colors.main as string : '#eee',
              textDecoration: 'none',
            }}
          >
            <Text uppercase>{tab.label}</Text>
          </Link>
        ))}
      </View>

      <View style={styles.form}>
        {children}
      </View>

      <Separator color='#eee' />

      <View flexDirection="row" justifyContent="center" alignItems="center">
        {
          context.configuration.portal?.availableLanguages.map((lang, i) => {
            return (
              <>
                <Button key={lang} type='link' label={getLangName(lang)} onPress={() => context.changeLanguage(lang)} containerStyle={styles.languageButton} />
                {i !== context.configuration.portal?.availableLanguages.length -1 && <Text>|</Text>}
              </>
            )
          })
        }

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    // marginTop: 100,
    maxWidth: 500,
    width: '100%',
    borderRadius: 5,
    elevation: 10,
    borderBottomRadius: 15,
    overflow: 'hidden',
    shadowOpacity: 0.3,
    shadowRadius: 20,
    backgroundColor: 'white',
  },
  imageContainer: {
    minHeight: 70,
    marginVertical: styling.spacing,
    backgroundColor: '#fff',
  },
  footerContainer: {
    minHeight: 45,
    backgroundColor: '#fff',
    // marginBottom: styling.spacing,
  },
  image: {
    width: '100%',
    height: 'auto',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
  },
  form: {
    padding: styling.spacing,
    paddingBottom: 0,
    backgroundColor: 'white',
  },
  login: {
    marginRight: styling.spacing,
  },
  languageButton: {
    marginBottom: 0,
  },
});