const REPLACE = /((\b(y|i|and|el|els|la|los|les|las|un|una|the|d'|l'|de|dels|en|na|grup|team)\b)|"|'|,|-|#|\.)+/g;

export const minifyTeamName = (name: string): string => {
    if (!name) return '-';
    const result = name.toLowerCase().replace(REPLACE, '');
    const words = result.split(' ').filter((word: string) => !!word.length).map((word: string) => word.trim());

    if (words.length === 0) {
        return name.replaceAll(' ', '').substring(0, 3);
    }

    if (words.length < 3) {
        return result.replaceAll(' ', '').substring(0, 3);
    }

    // if(words.length === 2) {
    //     return words[0].substring(0, 1) + words[1].substring(0, 2);
    // }

    return words.map((word: string) => /[0-9]+/.test(word) ? word : word.charAt(0)).join('');
}

export const getLangName = (shortName): string => {
    switch (shortName) {
        case 'es':
            return 'Español';
        case 'ca':
            return 'Català';
        case 'en':
            return 'English';
        default:
            return 'Español';
    }
}