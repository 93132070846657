import { BACKEND_URL } from '../config';
import * as RestService from './RestService';
import { IRoomTypeDTO } from './RoomTypeService';

export interface IHotelDTO {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  eventId?: number;
  eventName?: string;
  rooms?: IRoomTypeDTO | any;
  contactName?: string;
  inDate?: string;
  outDate?: string;
}

const BASE_URL = BACKEND_URL + '/auth/hotels'

/**
 * 
 * Devuelve los hoteles de un evento. En el dto incluye la lista de tipos de habitacion 
 */
export const findHotelsByEvent = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/by-event/${eventUuid}`);
}

/**
 * 
 * @param hotel 
 * @returns 
 */
export const create = (hotel: IHotelDTO) => {
  return RestService.post(`${BASE_URL}`, hotel);
}

/**
 * 
 * @param hotel 
 * @returns 
 */
export const update = (hotel: IHotelDTO) => {
  return RestService.put(`${BASE_URL}`, hotel);
}

/**
 * 
 * @param hotelId 
 * @returns 
 */
export const deleteHotel = (hotelId: number) => {
  return RestService.delete(`${BASE_URL}/${hotelId}`);
}

/**
 * Envia al hotel la información de los equipos del evento que se alojaran en el
 * @param hotelUuid 
 * @param eventUuid 
 * @returns 
 */
export const sendTeamsInfo = (hotelUuid: string, eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${hotelUuid}/send-teams-info/${eventUuid}`);
}

/**
 * Descarrega la información de los equipos del evento que se alojaran en el
 * @param hotelUuid 
 * @param eventUuid 
 * @returns 
 */
export const downloadTeamsInfo = (hotelUuid: string, eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${hotelUuid}/download-teams-info/${eventUuid}`);
}

/**
 * Devuelve la información del hotel y sus habitaciones a partir de su id
 * @param hotelId 
 * @returns 
 */
export const getById = (hotelId: number) => {
  return RestService.get(`${BASE_URL}/${hotelId}`);
}

/**
 * Devuelve un fichero pdf con la lista de miembros de equipos que se alojaran en un hotel
 * @param hotelId 
 * @returns 
 */
export const getActorsCheckIn = (hotelId: string | number, hotelName: any) => {
  return RestService.requestPdf(`${BASE_URL}/pdf/check-in-by-hotel/${hotelId}`, '_blank', `${hotelName}.pdf`);
}

/**
 * 
 * @param hotelId 
 * @returns 
 */
export const sendActorsCheckIn = (hotelId: number | string) => {
  return RestService.get(`${BASE_URL}/mail/check-in-by-hotel/${hotelId}`);
}