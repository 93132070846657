import * as React from "react";
import { ModalConfirm } from "src/components/ModalConfirm";
import { t } from "owc-react-lib-common";

import { IconButton } from "src/components/IconButton";
import { View } from "src/components/View";
import { useNavigate } from "react-router-dom";
import { Container } from "src/components/Container";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as OrganizerService from "src/services/OrganizerService";
import { Button } from "src/components/Button";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const OrganizerList = () => {

  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [organizers, setOrganizers] = React.useState<any[]>([]);
  const [organizerToDelete, setOrganizerToDelete] = React.useState<any>(undefined);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await OrganizerService.findOrganizers();
      setOrganizers(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  const goToOrganizerCreate = React.useCallback(() => {
    navigate("/admin/organizers/create");
  }, [navigate]);

  const onDeleteOrganizer = React.useCallback(async () => {
    try {
      await OrganizerService.deleteOrganizer(organizerToDelete.uuid);
      alerts.create({ text: t("global.success"), type: "success" });
      setOrganizers(organizers.filter((o: any) => o.uuid !== organizerToDelete.uuid));
      setOrganizerToDelete(undefined);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [organizers, organizerToDelete, alerts]);

  /**
   * get table row actions
   */
  const actions = React.useCallback(
    (organizer: any) => {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="edit"
            onPress={() => navigate(`${organizer.uuid}`)}
          />
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="trash"
            onPress={() => setOrganizerToDelete(organizer)}
          />
        </View>
      );
    },
    [navigate]
  );

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    {
      name: "name",
      label: t("admin.organizer.list.name"),
      width: 300,
      textProps: { uppercase: true },
    },
    {
      name: "description",
      label: t("admin.organizer.list.description"),
      flex: 1,
    },
    {
      name: "contactEmail",
      label: t("admin.organizer.list.email"),
      flex: 1,
    },
    {
      name: "actions",
      label: " ",
      render: actions,
      width: 100,
      hidden: readOnly
    },
  ];

  return (
    <Container title={t("admin.organizer.list.title")}>
      <Button label={t("admin.organizer.list.createOrganizer")} onPress={goToOrganizerCreate} disabled={readOnly} />

      <Table headers={headers} data={organizers} containerStyle={{ marginBottom: 0 }} />
      {!!organizerToDelete && (
        <ModalConfirm
          visible
          text={t("admin.organizer.list.deleteConfirmation", { organizerName: organizerToDelete.name })}
          onClose={() => setOrganizerToDelete(undefined)}
          onSuccess={onDeleteOrganizer}
        />
      )}
    </Container>
  );
};

