import { t } from 'owc-react-lib-common';
import * as React from 'react';
import { Container } from 'src/components/Container';
import { Tabs } from 'src/components/Tabs';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { CompetitionRegisterQuestions } from '../competitions/CompetitionRegisterQuestions';
import { InscriptionQuestions } from './InscriptionQuestions';

export const PortalQuestions = () => {
    const context = React.useContext(OWCOpenDmrContext);
    const tab = React.useRef(0);

    /**
     * render
     */
    const headers = [
        t('admin.organizer.registerQuestions'),
        t('admin.organizer.settings'),
    ];
    return (
        <Container title={t('admin.organizer.questions')}>
            <Tabs headers={headers} initialTab={tab.current} onChangeTab={(index: number) => tab.current = index}>
                <CompetitionRegisterQuestions portalId={context.configuration.portal.id} />
                <InscriptionQuestions />
            </Tabs>
        </Container>
    )
}

