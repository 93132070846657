import * as React from "react";
import { Groups } from '../components/Groups';

interface IEventCategoryGroups {
  data: any[];
  eventCategoryId: number;
  teams: any[];
  onCreate: (object: any) => void;
  onDelete: (uuid: string) => void;
}

export const EventCategoryGroups = ({ data, eventCategoryId, teams, onDelete, onCreate }: IEventCategoryGroups) => {
  return (
    <Groups data={data} eventCategoryId={eventCategoryId} teams={teams} onDelete={onDelete} onCreate={onCreate} />
  );
}