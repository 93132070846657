export * from './environment';

export const DEFAULT_LANGUAGE = 'ca';
export const VERSION = '1.0.72'

export const styling = {
	spacing: 20,
};

export const GENDER_FEMALE = 'F';
export const GENDER_MALE = 'M';
export const GENDER_MIXED = 'X';
export const GENDER_NON_BINARY = 'X';

export const MODALITY_5x5 = 1;
export const MODALITY_3x3 = 2;

export const TEAM_INSCRIPTION_PROCESS_CREATED = 0;
export const TEAM_INSCRIPTION_PROCESS_PAYMENT = 90;
export const TEAM_INSCRIPTION_PROCESS_COMPLETE = 100;