import * as React from "react";
import { t } from "owc-react-lib-common";
import { StyleSheet } from "react-native";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container } from "src/components/Container";
import { Loader } from "src/components/Loader";
import { View } from "src/components/View";
import { MODALITY_5x5, styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as EventService from "src/services/EventService";
import * as HotelService from "src/services/HotelService";
import { EventCategory } from "./categories/EventCategory";
import { Team } from "./components/Team";
import { EventContext } from "./EventContext";
import { EventDetail } from "./EventDetail";
import { HotelDetail } from "./hotels/HotelDetail";
import { EventTeams } from "./teams/EventTeams";
import { CategoryGroup } from "./groups/CategoryGroup";
import { CalendarDetail } from "./calendar/CalendarDetail";
import { MatchesList } from "./matches/MatchesList";
import { EventShirtSizes } from "./shirtSizes/EventShirtSizes";
import { EventCourts } from "./courts/EventCourts";
import { ISidebarItemLink, ISidebarItemTitle, Type } from "src/components/Sidebar/types";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { ADMatchesList } from "./admatches/ADMatchesList";
import { RankingList } from "../ranking/RankingList";
import { TeamActorRankingList } from "../ranking/TeamActorRankingList";

export const Event = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const navigate = useNavigate();
  const alerts = useAlerts();
  const { eventUuid } = useParams();
  const [data, setData] = React.useState<any>();
  const [hotels, setHotels] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    if (!eventUuid) {
      return navigate("/auth/competitions");
    }

    try {
      const [event, hotels]: any[] = await Promise.all([
        EventService.findByUuid(eventUuid),
        HotelService.findHotelsByEvent(eventUuid),
      ]);

      setData(event.data);
      setHotels(hotels.data);
    } catch (error) {
      alerts.createDSMError(error);
      navigate("/auth/competitions");
    }

    setLoading(false);
  });


  const getSidebar = React.useCallback(() => {
    const sidebar: (ISidebarItemLink | ISidebarItemTitle)[] = [
      { id: "competitions", type: Type.LINK, to: `/organizer/competitions/${data.competitionUuid}`, label: `« ${data.competitionName}` },
      { id: "event", type: Type.LINK, to: `/organizer/events/${data.uuid}`, label: data.name, icon: 'eye' },
      { id: "ranking", type: Type.LINK, to: `/organizer/events/${data.uuid}/ranking`, label: t("admin.events.ranking.title"), icon: 'ranking-star' },
      { id: "ranking", type: Type.LINK, to: `/organizer/events/${data.uuid}/team-actor-ranking`, label: t("admin.events.ranking.teamActortitle"), icon: 'ranking-star' },
      { id: "matches", type: Type.LINK, to: `/organizer/events/${data.uuid}/matches`, label: t("admin.events.matches.title"), icon: 'ball' },
      { id: "admatches", type: Type.LINK, to: `/organizer/events/${data.uuid}/admatches`, label: t("admin.events.matches.admatches"), icon: 'ball' },
      { id: "event-calendar", type: Type.LINK, to: `/organizer/events/${data.uuid}/calendar`, label: t("admin.events.calendar.detail"), icon: 'calendar' },
      { id: "teams", type: Type.LINK, to: `/organizer/events/${data.uuid}/teams`, label: t("admin.events.teams"), icon: 'people-group' },
      { id: "shirts", type: Type.LINK, to: `/organizer/events/${data.uuid}/shirts`, label: t("admin.events.shirtSizes"), icon: 'shirt' },
      { id: "courts", type: Type.LINK, to: `/organizer/events/${data.uuid}/courts`, label: t("admin.events.courts"), icon: 'map' },
      {
        id: "categories", type: Type.TITLE, label: t("admin.events.categories"), icon: 'card',
        children: [
          ...data?.eventCategories?.sort((a: any, b: any) => a.name.localeCompare(b.name))?.map((category: any) => ({ id: `category-${category.id}`, type: Type.LINK, to: `/organizer/events/${data.uuid}/categories/${category.id}`, label: category.name })),
          !readOnly && { id: "create-category", type: Type.LINK, to: `/organizer/events/${data.uuid}/categories/0`, label: t("admin.events.category.create") },
        ]
      },
    ];

    if (data.modalityId === MODALITY_5x5 && data.allowHotel) {
      sidebar.push(
        {
          id: "hotels", type: Type.TITLE, label: t("admin.events.hotels"), icon: 'bed',
          children: [
            ...hotels?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((hotel: any) => ({ id: `hotel-${hotel.id}`, type: Type.LINK, to: `/organizer/events/${data.uuid}/hotels/${hotel.id}`, label: hotel.name })),
            !readOnly && { id: "create-hotel", type: Type.LINK, to: `/organizer/events/${data.uuid}/hotels/0`, label: t("admin.hotels.create") },
          ],
        }
      )
    }

    return sidebar;

  }, [data, hotels, readOnly]);

  /**
   * on save hotel
   */
  const onSuccess = React.useCallback(
    (object: any) => {
      if (hotels.some((hotel: any) => hotel.id === object.id)) {
        setHotels(hotels.map((hotel: any) => (hotel.id === object.id ? object : hotel)));
      } else {
        setHotels([...hotels, object]);
        navigate("hotels/" + object.id);
      }
    },
    [hotels, navigate]
  );

  /**
   * on delete hotel
   */
  const onDelete = React.useCallback(
    (hotelId: number) => {
      setHotels(hotels.filter((hotel: any) => hotel.id !== hotelId));
      navigate("");
    },
    [hotels, navigate]
  );

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  return (
    <EventContext.Provider value={{ data, setData }}>
      <View flexDirection="row" style={styles.container}>
        <View style={styles.content}>
          <Container sidebar={getSidebar()} title={data.name} right={data.competitionName}>
            <Routes>
              <Route path="" element={<EventDetail />} />
              <Route path="ranking" element={<RankingList />} />
              <Route path="team-actor-ranking" element={<TeamActorRankingList />} />
              <Route path="adMatches" element={<ADMatchesList />} />
              <Route path="matches" element={<MatchesList />} />
              <Route path="calendar" element={<CalendarDetail />} />
              <Route path="categories/:eventcategoryId" element={<EventCategory />} />
              <Route path="categories/:categoryId/groups/:groupUuid" element={<CategoryGroup />} />
              <Route path="hotels/:hotelId" element={<HotelDetail onSuccess={onSuccess} onDelete={onDelete} />} />
              <Route path="teams/:teamUuid" element={<Team />} />
              <Route path="teams" element={<EventTeams />} />
              <Route path="shirts" element={<EventShirtSizes />} />
              <Route path="courts" element={<EventCourts />} />
              <Route path="*" element={<Navigate to={`/organizer/events/${data.uuid}`} />} />
            </Routes>
          </Container>
        </View>
      </View>
    </EventContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: styling.spacing,
  },
});
