import * as React from 'react'
import { StyleSheet } from "react-native";
import { t, useComponentDidMount } from 'owc-react-lib-common';
import { ITableHeader, Table } from 'src/components/Table';
import { ISettingDTO } from 'src/services/SettingService';
import { Text } from 'src/components/Text';
import { Checkbox } from 'src/components/Checkbox';
import { getSettingName } from '../events/categories/EventCategorySettingUtils';
import * as SettingService from 'src/services/SettingService';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { Loader } from 'src/components/Loader';
import { ROLE_PLAYER } from 'src/pages/auth/components/config';
import { useAlerts } from 'src/hooks/useAlerts';

export const InscriptionQuestions = () => {
    const { create, createDSMError } = useAlerts();
    const context = React.useContext(OWCOpenDmrContext);
    const [settings, setSettings] = React.useState<any>();
    const [loading, setLoading] = React.useState<any>();


    useComponentDidMount(async () => {
        setLoading(true);
        const response = await SettingService.findByPortalId(context.configuration.portal.id);
        setSettings(response.data.filter(data => data.roleId === ROLE_PLAYER));

        setLoading(false);
    });

    const updateSettings = React.useCallback(async (newSetting: ISettingDTO) => {
        const currentRequiredSettings = settings.filter((setting: ISettingDTO) => setting.required);
        if (currentRequiredSettings.length <= 1 && newSetting.typeId === currentRequiredSettings[0].typeId) {
            create({ type: "error", text: t("admin.events.settings.nonRequiredError") });
            return;
        }
        
        newSetting.portalId = context.configuration.portal.id;

        try {
            const response = newSetting.id === null ? await SettingService.addSetting(context.configuration.portal.id, newSetting) : await SettingService.updateSetting(context.configuration.portal.id, newSetting);
            const settingFiltered = settings.filter(setting => setting.typeId !== newSetting.typeId);
            setSettings([...settingFiltered, response.data])
            create({ type: "success", text: t("global.success") });
        } catch (error) {
            createDSMError(error);
        }

    }, [context?.configuration?.portal?.id, create, createDSMError, settings]);

    const renderType = React.useCallback((setting: ISettingDTO) => {
        return <Text>{getSettingName(setting.typeId)}</Text>
    }, [])

    const renderCheckboxVisible = React.useCallback((setting: ISettingDTO) => {
        return (
            <Checkbox
                key={setting.id}
                value={setting.visible}
                containerStyle={styles.checkbox}
                onChange={(value) => updateSettings({ ...setting, visible: value, required: value ? setting.required : false })}
            />
        )
    }, [updateSettings]);

    const renderCheckboxRequired = React.useCallback((setting: ISettingDTO) => {
        return (
            <Checkbox
                key={setting.id}
                value={setting.required}
                containerStyle={styles.checkbox}
                disabled={!setting.visible}
                onChange={(value) => updateSettings({ ...setting, required: value })}
            />
        )
    }, [updateSettings]);

    const tableHeaders: ITableHeader[] = [
        {
            name: "typeId",
            label: t("admin.events.settings.table.name"),
            textProps: { uppercase: true },
            render: (setting: ISettingDTO) => renderType(setting),
        },
        {
            name: "visible",
            label: t("admin.events.settings.table.visible"),
            textProps: { uppercase: true, textAlign: "center" },
            width: 90,
            render: (setting: ISettingDTO) => renderCheckboxVisible(setting),
        },
        {
            name: "required",
            label: t("admin.events.settings.table.required"),
            textProps: { uppercase: true, textAlign: "center" },
            width: 90,
            render: (setting: ISettingDTO) => renderCheckboxRequired(setting),
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <Table defaultSize={25} defaultSort={"typeId"} headers={tableHeaders} data={settings} />
    )
}

const styles = StyleSheet.create({
    checkbox: {
        alignSelf: "center",
    },
});
