import dayjs from "dayjs";
import { t } from "owc-react-lib-common";
import * as React from "react";
import { Image, StyleSheet } from "react-native";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { Container } from "src/components/Container";
import { IconButton } from "src/components/IconButton";
import { Loader } from "src/components/Loader";
import { Table } from "src/components/Table";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { Translation, usePortalTranslations } from "src/hooks/usePortalTranslations";
import * as EventService from "src/services/EventService";
import * as TeamService from "src/services/TeamService";
import {
  TEAM_STATUS_COMPLETED,
  TEAM_STATUS_PROCESSED,
  TEAM_STATUS_VALIDATED,
  TEAM_STATUS_VALIDATED_API,
} from "../components/config";

export const TeamList = () => {
  const alerts = useAlerts();

  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>();
  const [teams, setTeams] = React.useState<any[]>([]);

  const translate = usePortalTranslations();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await EventService.findAllWithTeams();
      setEvents(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * on competition change
   */
  const loadTeams = React.useCallback(
    async (object: any) => {
      setSelected(object);

      if (!object?.uuid) {
        setTeams([]);
        return;
      }

      setLoading(true);

      try {
        const response = await TeamService.findMyTeamsByUuid(object.uuid);
        setTeams(response.data.filter(team => !team.isFicticious));
      } catch (error) {
        alerts.createDSMError(error);
      }

      setLoading(false);
    },
    [alerts]
  );

  /**
   *
   */
  const renderStatus = React.useCallback((object: any) => {
    switch (object.registerStatus) {
      case TEAM_STATUS_PROCESSED:
        return <Text uppercase>{t("auth.teams.list.processed")}</Text>;
      case TEAM_STATUS_VALIDATED:
      case TEAM_STATUS_VALIDATED_API:
        return (
          <Text color="teal" uppercase>
            {t("auth.teams.list.validated")}
          </Text>
        );
      case TEAM_STATUS_COMPLETED:
        return (
          <Text color="teal" bold uppercase>
            {t("auth.teams.list.completed")}
          </Text>
        );
    }

    return (
      <Text color="orange" uppercase>
        {t("auth.teams.list.pending")}
      </Text>
    );
  }, []);

  /**
   * render actions
   */
  const actions1 = React.useCallback(
    (object: any) => {
      return <IconButton backgroundColor={'transparent'} color={'black'} name="arrowright" onPress={() => loadTeams(object)} />;
    },
    [loadTeams]
  );

  /**
   * render actions
   */
  const actions = React.useCallback((object: any) => {
    return <ButtonLink backgroundColor={'transparent'} color={'black'} icon={{ name: "arrowright" }} to={`/auth/teams/${object.uuid}`} />;
  }, []);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  const headers1 = [
    {
      name: "logo",
      label: " ",
      render: (object: any) => <Image source={{ uri: object.competitionLogoUrl }} style={styles.image} />,
      width: 120,
    },
    { name: "competitionName", label: t("auth.teams.list.competition"), textProps: { uppercase: true } },
    { name: "name", label: t("auth.teams.list.event"), textProps: { uppercase: true } },
    { name: "actions", label: " ", render: actions1, width: 60 },
  ];

  const headers = [
    { name: "name", label: t("auth.teams.list.name") },
    { name: "eventCategoryName", label: t("auth.teams.list.category"), textProps: { uppercase: true } },
    { name: "teamLevelName", label: t("auth.teams.list.level"), width: 100 },
    {
      name: "created",
      label: t("auth.teams.list.created"),
      render: (object: any) => <Text>{dayjs(object.created).format("DD/MM/YYYY")}</Text>,
      width: 100,
    },
    { name: "status", label: t("auth.teams.list.status"), render: renderStatus, width: 100 },
    { name: "actions", label: " ", render: actions, width: 60 },
  ];

  return (
    <Container title={t(translate(Translation.FIVEONFIVE_TEAM_TITLE))}>

      {!selected ? (
        <Table headers={headers1} data={events} containerStyle={{ marginBottom: 0 }} />
      ) : (
        <React.Fragment>
          <Table headers={headers} data={teams} />

          <View style={styles.imageContainer}>
            <Image source={{ uri: selected.competitionLogoUrl }} style={styles.image} />
          </View>

          <Button type="primary" label={t("global.back")} onPress={() => setSelected(undefined)} marginBottom={0} />
        </React.Fragment>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 200,
    height: 100,
    marginHorizontal: "auto",
  },
  image: {
    flex: 1,
    resizeMode: "contain",
  },
});
