import * as React from 'react';
import { View } from 'src/components/View';
import { Page } from 'src/services/entities/Page';
import { Header, ITableHeader } from './Header';
import { Body } from './Body';
import { Pagination } from './Pagination';
import { Filter } from './Filter';

export interface IAjaxTable<T> {
  data: Page<T>;
  headers: ITableHeader<T>[];
  defaultSize: number;
  changePage: (pageNumber: number) => void;
  changeSize: (size: number) => void;
  onSort: (sort: string, direction: "ASC" | "DESC") => void;
  search: (searchString: string) => void;
  additionalFilters?: any;
}

export const AjaxTable = <T,>({ data, headers: iheaders, defaultSize, changePage, changeSize, onSort, search, additionalFilters }: IAjaxTable<T>) => {
  return (
    <View>
      <Filter defaultSize={defaultSize} onChangeSize={changeSize} search={search} >
        {additionalFilters && additionalFilters()}
      </Filter>
      <Header headers={iheaders} onSort={onSort}/>
      <Body content={data?.content} headers={iheaders} />
      <Pagination data={data} changePage={changePage} />
    </View>
  )
}

