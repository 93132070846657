import { BACKEND_URL } from "../config";
import * as RestService from "./RestService";

export interface IOrganizerDTO {
  logoUrl: any;
  code: string | number;
  cif: string | number;
  phoneNum: string | number;
  readonly id?: number;
  readonly uuid?: string;
  externalUuid?: string;
  name?: string;
  contactEmail?: string;
  description?: string;  
  token?: string;
}

const BASE_URL = BACKEND_URL + "/auth/organizers";

/**
 * @returns Devuelve todos los organizadores
 */
export const findOrganizers = () => {
  return RestService.get(`${BASE_URL}`);
};

/**
 * @returns Devuelve todos los organizadores
 */
export const getOrganizers = (): Promise<IOrganizerDTO[]> => {
  return RestService.getTyped(`${BASE_URL}`);
};

/**
 * @returns Devuelve todos los organizadores
 */
export const getMe = (): Promise<IOrganizerDTO> => {
  return RestService.getTyped(`${BASE_URL}/me`);
};

export const refreshToken = (): Promise<string> => {
  return RestService.postTyped(`${BASE_URL}/token`);
}

export const refreshTokenByOrganizerUuid = (uuid: string): Promise<string> => {
  return RestService.postTyped(`${BASE_URL}/token/${uuid}`);
}

export const findOrganizerByUuid = (uuid: string) => {
  return RestService.get(`${BASE_URL}/id?uuid=${uuid}`)
}

export const update = (data: any) => {
  return RestService.put(`${BASE_URL}`, data)
}

export const create = (data: any) => {
  return RestService.post(`${BASE_URL}`, data)
}

export const deleteOrganizer = (uuid: string) => {
  return RestService.delete(`${BASE_URL}/${uuid}`)
}


