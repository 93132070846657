import React from 'react'
import { Picker, t } from 'owc-react-lib-common'
import { Form } from 'src/components/Form'
import { Row } from 'src/components/Row/Row'
import { Button } from 'src/components/Button';
import { ButtonLink } from 'src/components/ButtonLink';
import { View } from 'src/components/View';
import { CUSTOM, IValidationParams, REQUIRED, useValidation } from 'src/hooks/useValidation';
import dayjs from 'dayjs';
import { InputDate } from 'src/components/InputDate';

export interface ICreateMatchModal {
    teams: any[];
    courts: any[];
    roundId: number;
    groupId: number;
    onSave: (object: any) => void;
    onCancel: (object?: any) => void;
}

export const CreateMatchModal = ({ teams, courts, roundId, groupId, onSave, onCancel }: ICreateMatchModal) => {

    const [errors, setErrors] = React.useState<any>({});
    const form = React.useRef<any>({ roundId: roundId, groupId: groupId });

    const setHomeTeam = React.useCallback((value: string) => (form.current.homeTeamId = value), []);
    const setAwayTeam = React.useCallback((value: string) => (form.current.awayTeamId = value), []);
    const setMatchDateHour = React.useCallback((value: string) => {

        form.current.matchDateHour = dayjs(value).format("YYYY-MM-DD HH:mm:ss")

    }, []);
    const setCourtId = React.useCallback((value: string) => (form.current.courtId = value), []);

    const { validateAll } = useValidation();


    const validate = React.useCallback(() => {
        const formRequiredFields: IValidationParams[] = [
            {
                name: "homeTeamId",
                type: [REQUIRED, CUSTOM],
                validate: () => {
                    if (form.current.homeTeamId === form.current.awayTeamId) {
                        return t('admin.events.team.error.repeatTeam')
                    }
                }

            },
            {
                name: "awayTeamId",
                type: [REQUIRED, CUSTOM],
                validate: () => {
                    if (form.current.homeTeamId === form.current.awayTeamId) {
                        return t('admin.events.team.error.repeatTeam')
                    }
                }

            },
        ];
        const validated = validateAll(form.current, formRequiredFields);
        setErrors(validated.errors);
        return !validated.hasErrors;
    }, [validateAll]);

    const save = React.useCallback(() => {
        if (!validate()) return;
        onSave(form.current)
    }, [onSave, validate])

    return (
        <Form>
            <Row>
                <Picker
                    items={teams.map((team) => ({ label: team.name, value: team.id }))}
                    label={t('admin.events.team.homeTeam')}
                    onChange={setHomeTeam}
                    error={errors.homeTeamId}
                />
                <Picker
                    items={teams.map((team) => ({ label: team.name, value: team.id }))}
                    label={t('admin.events.team.awayTeam')}
                    onChange={setAwayTeam}
                    error={errors.awayTeamId}
                />
            </Row>
            <Row>
                <InputDate
                    label={t('admin.events.team.matchDate')}
                    useTime
                    onChange={setMatchDateHour}
                />
                <Picker
                    items={courts.map((court) => ({ label: court.name, value: court.id }))}
                    label={t('admin.events.team.courts')}
                    onChange={setCourtId}
                />
            </Row>
            <Row>
                <View flexDirection='row' justifyContent='flex-end' style={{ gap: 10 }}>
                    <ButtonLink to={''} onPress={() => { onCancel() }} label={t('global.cancel')} />
                    <Button marginBottom={0} onPress={save} label={t('global.save')} />
                </View>
            </Row>
        </Form>
    )
}
