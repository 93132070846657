import * as React from 'react';
import { Checkbox, Icon, Input, Loader, Picker, t, Text, TextArea, useComponentDidMount } from "owc-react-lib-common";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { useParams } from 'react-router-dom';
import * as RegisterQuestionService from 'src/services/RegisterQuestionService';
import { ITableHeader, Table } from 'src/components/Table';
import { useAlerts } from 'src/hooks/useAlerts';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row/Row';
import { Button } from 'src/components/Button';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { CUSTOM, REQUIRED, useValidation } from 'src/hooks/useValidation';

const PROCESS_TYPE_TEAM_ACTOR = 1;

interface ICompetitionRegisterQuestions {
  competitionId?: number;
  portalId?: number
}

export const CompetitionRegisterQuestions = ({ competitionId, portalId }: ICompetitionRegisterQuestions) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { uuid } = useParams();
  const [data, setData] = React.useState<any[]>([]);
  const { createDSMError } = useAlerts();
  const [selected, setSelected] = React.useState<any>();
  const [questionToDelete, setQuestionToDelete] = React.useState<any>();
  const newOptionRef = React.useRef<any>();
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      if (uuid) {
        const response = await RegisterQuestionService.getByCompetitionUuid(uuid);
        setData(response.data?.filter((question: any) => !question.deleted) || []);
      } else {
        const response = await RegisterQuestionService.getByPortalId(portalId);
        setData(response.data?.filter((question: any) => !question.deleted) || []);
      }
    } catch (error) {
      createDSMError(error)
    }



    setLoading(false);
  });

  /**
   * open selected
   */
  const openSelected = React.useCallback((selected: any) => {
    setSelected(selected);
    newOptionRef?.current?.setValue('');
  }, []);

  /**
   * add option
   */
  const addOption = React.useCallback(() => {
    newOptionRef.current.setValue('');
    if (!newOptionRef.current.getValue()) return;
    setSelected({ ...selected, options: (!!selected.options ? selected.options + ';' : '') + newOptionRef.current.getValue() });
  }, [selected]);

  const addLabel = React.useCallback((value)=>{
    setSelected({ ...selected, options: value });
  }, [selected]);

  /**
   * 
   */
  const defaultOption = React.useCallback((index: number) => {
    setSelected({ ...selected, defaultValue: selected.defaultValue === index ? '' : index });
  }, [selected]);

  /**
   * 
   */
  const deleteOption = React.useCallback((index: number) => {
    const options = selected.options.split(';').filter((_option: any, idx: number) => idx !== index);
    setSelected({ ...selected, defaultValue: index === selected.defaultValue ? '' : selected.defaultValue, options: options.join(';') });
  }, [selected]);

  /**
   * 
   */
  const save = React.useCallback(async () => {
    let object = { ...selected };
    if (competitionId) {
      object = { ...selected, competitionId }
    }
    if (portalId) {
      object = { ...selected, portalId }
    }

    // validate
    const { errors, hasErrors } = validateAll(object, [
      { name: "question", type: REQUIRED },
      { name: "type", type: REQUIRED },
      // { name: "code", type: REQUIRED },
      // { name: "options", type: CUSTOM, validate: () => object.type !== 'text' ? t('errors.required') : undefined },
      { name: "required", type: REQUIRED },
      { name: "processType", type: REQUIRED },
      { name: "contentType", type: REQUIRED },
      // { name: "description", type: REQUIRED },
      { name: "showInList", type: REQUIRED },
      { name: "listHeader", type: CUSTOM, validate: () => object.showInList === 'true' && !object.listHeader ? t('errors.required') : undefined },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // save
    try {
      const response = await RegisterQuestionService.save(object);

      let questions = [...data];

      if (!selected.id) {
        questions.push(response.data);
      }
      else {
        questions = data.map((question: any) => question.id !== response.data.id ? question : response.data);
      }

      setData(questions);
      setSelected(undefined);
    } catch (error) {
      createDSMError(error);
    }
  }, [data, selected, createDSMError, competitionId, portalId, validateAll]);

  /**
   * 
   */
  const deleteQuestion = React.useCallback(async () => {
    try {
      await RegisterQuestionService.deleteQuestion(questionToDelete.id);
      setData(data.filter((question: any) => question.id !== questionToDelete.id));
      setQuestionToDelete(undefined);
    } catch (error) {
      createDSMError(error);
    }
  }, [data, questionToDelete, createDSMError]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  const headers: ITableHeader[] = [
    { name: 'question', label: t('admin.competitions.registerQuestions.question'), width: 200 },
    // { name: 'type', label: t('admin.competitions.registerQuestions.type'), width: 60 },
    { name: 'contentType', label: t('admin.competitions.registerQuestions.type'), width: 120, render: (object: any) => <Text>{contentTypeItems.find((type: any) => type.value === parseInt(object.contentType))?.label}</Text> },
    // { name: 'processType', label: t('admin.competitions.registerQuestions.processType'), width: 60 },
    // { name: 'code', label: t('admin.competitions.registerQuestions.code'), width: 60 },
    { name: 'options', label: t('admin.competitions.registerQuestions.options'), width: 180, render: (object: any) => object.options?.split(';').map((option: any, index: number) => <Text key={index} bold={object.defaultValue === index}>{option}</Text>) },
    // { name: 'defaultValue', label: t('admin.competitions.registerQuestions.defaultValue'), width: 120, render: (object: any) => object.defaultValue && <Text>{object.options?.split(';')[object.defaultValue]}</Text> },
    // { name: 'showInList', label: t('admin.competitions.registerQuestions.showInList'), width: 80 },
    // { name: 'listHeader', label: t('admin.competitions.registerQuestions.listHeader'), width: 80 },
    { name: 'required', label: t('admin.competitions.registerQuestions.required'), width: 120, render: (object: any) => <Text>{object.required ? t('global.yes') : t('global.no')}</Text> },
    { name: 'description', label: t('admin.competitions.registerQuestions.description'), render: (object: any) => <Text>{object.description?.length > 200 ? object.description.substring(0, 200) + '...' : object.description}</Text> },
    {
      name: 'actions', label: '', width: 60, render: (object: any) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon type='edit' onPress={() => openSelected({ description: '', required: false, listHeader: '', ...object })} />
          <Icon type='trash' onPress={() => setQuestionToDelete(object)} />
        </View>
      )
    },
  ];

  const showInListItems = [
    { label: t('global.no'), value: 'false' },
    { label: t('global.yes'), value: 'true' },
  ];

  const contentTypeItems = [
    { label: t('admin.competitions.registerQuestions.generic'), value: 0 },
    { label: t('admin.competitions.registerQuestions.imageRights'), value: 1 },
  ];

  const typeItems = [
    { label: t('global.checkbox'), value: 'check' },
    { label: t('global.radio'), value: 'radio' },
    { label: t('global.text'), value: 'text' },
  ];

  return (
    <View>
      <Title text={t('admin.competitions.registerQuestions.title')} />
      <Table headers={headers} data={data} />
      <Button label={t('global.add')} onPress={() => openSelected({ descriptions: '', processType: PROCESS_TYPE_TEAM_ACTOR, required: false })} />

      <Modal visible={!!selected} title={t('admin.competitions.registerQuestions.title')} onClose={() => setSelected(undefined)}>
        <Input label={t('admin.competitions.registerQuestions.question')} defaultValue={selected?.question} onChange={(value: string) => setSelected({ ...selected, question: value })} error={errors.question} />

        <Row>
          {/* <Input label={t('admin.competitions.registerQuestions.code')} defaultValue={selected?.code} onChange={(value: string) => setSelected({ ...selected, code: value })} error={errors.code} /> */}
          <Picker label={t('admin.competitions.registerQuestions.type')} items={contentTypeItems} defaultValue={selected?.contentType} onChange={(value: string) => setSelected({ ...selected, contentType: value })} error={errors.contentType} />
          <Picker label={t('admin.competitions.registerQuestions.type')} items={typeItems} defaultValue={selected?.type} onChange={(value: string) => setSelected({ ...selected, type: value })} error={errors.type} />
        </Row>

        <TextArea label={t('admin.competitions.registerQuestions.description')} defaultValue={selected?.description} onChange={(value: string) => setSelected({ ...selected, description: value })} rows={10} />

        <Row>
          <Picker label={t('admin.competitions.registerQuestions.showInList')} items={showInListItems} defaultValue={selected?.question} onChange={(value: string) => setSelected({ ...selected, showInList: value })} error={errors.showInList} />
          <Input label={t('admin.competitions.registerQuestions.listHeader')} defaultValue={selected?.listHeader} onChange={(value: string) => setSelected({ ...selected, listHeader: value })} error={errors.listHeader} />
        </Row>
        
        <View>
          {(selected?.type === 'check' || selected?.type === 'text') &&
            <>
              <Text style={{ marginLeft: 5, marginBottom: 5 }}>{t('admin.competitions.registerQuestions.label')}</Text>
              <Input onChange={addLabel} defaultValue={selected.options} containerStyle={{ flex: 1 }} />
            </>
          }
          {selected?.type === 'radio' &&
            <>
              <Text style={{ marginLeft: 5, marginBottom: 5 }}>{t('admin.competitions.registerQuestions.options')}</Text>
              {selected?.options?.split(';').map((option: any, index: number) => {
                if (!option) {
                  return null;
                }

                return (
                  <View key={option} style={{ flexDirection: 'row', alignItems: 'center', borderWidth: 1, borderColor: '#ccc', padding: 5, marginBottom: 2, borderRadius: 5 }}>
                    <Text style={{ flex: 1 }}>{option}</Text>
                    <Icon type='text' text={t('admin.competitions.registerQuestions.defaultValue').toLowerCase()} color={index === selected.defaultValue ? '#333' : '#ccc'} onPress={() => defaultOption(index)} />
                    <Icon type='times' onPress={() => deleteOption(index)} />
                  </View>
                )
              })}

              <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                <Input ref={ref => newOptionRef.current = ref} placeholder={t('admin.competitions.registerQuestions.addNewOption') + '...'} containerStyle={{ flex: 1 }} />
                <Icon type='text' text={t('global.add').toLowerCase()} onPress={addOption} marginBottom={15} containerStyle={{ marginTop: 5 }} />
              </View>
            </>
          }



          {/* {errors.options && <Text color='crimson'>{errors.options}</Text>} */}
        </View>

        <Checkbox label={t('admin.competitions.registerQuestions.required')} defaultValue={selected?.required} onChange={(value: boolean) => setSelected({ ...selected, required: value })} error={errors.required} />
        <Button label={t('global.save')} onPress={save} />
      </Modal>

      <ModalConfirm visible={!!questionToDelete} text={t('admin.competitions.registerQuestions.deleteQuestionConfirmation')} onSuccess={deleteQuestion} onClose={() => setQuestionToDelete(undefined)} />
    </View>
  );
}