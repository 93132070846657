import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export const DOC_ID_TYPE_NIF = 0;
export const DOC_ID_TYPE_NIE = 1;
export const DOC_ID_TYPE_PASSPORT = 2;
export const DOC_ID_TYPE_CATSALUT = 3;
export const DOC_ID_TYPE_CIF = 4;

export const REGISTER_STATUS_CREATED = 0;
export const REGISTER_STATUS_VALIDATED = 1;
export const REGISTER_STATUS_COMPLETED = 2;

export const GENDER_MASC = 'M';
export const GENDER_FEM = 'F';

export interface IPeopleDTO {
  id?: number;
	uuid?: string;
	name?: string;
	contactEmail?: string;
	idDocType?: number;
	idDoc?: string;
	adress?: string;
	externalUuid?: string;
	townId?: number;
	baseUserId?: number;
	baseUserUuid?: string;
	townName?: string;
	birthdate?: any;
	gender?: string;
	zipCode?: string;
	phone?: string;
	defaultLang?: string;
	deleted?: any;
	email?: string;
	registerStatus?: number;
	accountValidated?: boolean;
	provinceId?: number;
	provinceName?: string;
}




const BASE_URL = BACKEND_URL + '/auth/people'

  /**
   * 
   Recupera los datos personales del usuario logonado
   */
  export const findMe = () => {
    return RestService.get(`${BASE_URL}/me`);
  }

  /**
   * 
   Da de alta los datos personales del usuario
   */
   export const create = (people: IPeopleDTO) => {
    return RestService.post(`${BASE_URL}/me`, people);
  }

  /**
   * 
   Modifica los datos personales del usuario
   */
   export const update = (people: IPeopleDTO) => {
    return RestService.put(`${BASE_URL}/me`, people);
  }

