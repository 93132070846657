import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { FloatingIconButton } from 'src/components/FloatingIconButton';
import { Loader } from 'src/components/Loader';
import { Title } from 'src/components/Title';
import { View } from 'src/components/View';
import { styling } from 'src/config';
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import * as EventService from 'src/services/EventService';
import * as TeamService from 'src/services/TeamService';
import { Teams } from '../components/Teams';
import { EventContext } from '../EventContext';

export const EventTeams = () => {

  const alerts = useAlerts();
  const { data: event } = React.useContext(EventContext);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      const response = await TeamService.findTeamsByEvent(event.uuid);
      setData(response.data.filter(team => !team.isFicticious));
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  const updateTeams = React.useCallback((teams: any[]) => {
    setData(teams);
  }, []);
  /**
   * on delete team
   */
  const onDelete = React.useCallback((teamToDelete: any) => {
    setData(data.filter((team: any) => team.id !== teamToDelete.id));
  }, [data]);

  /**
   * show excel
   */
  const showExcel = React.useCallback(async () => {
    try {
      await EventService.requestExcel(event.uuid, event.name);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, event]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  /**
   * render
   */
  return (
    <View>
      <View flexDirection='row' alignItems='center' marginBottom={styling.spacing}>
        <Title text={t('admin.events.teams')} containerStyle={{ flex: 1, marginBottom: 0 }} />
        <View flexDirection='row'>
          <FloatingIconButton backgroundColor='transparent' color='black' name="excel" title='Excel' onPress={showExcel} containerStyle={{ position: 'relative' }} />
        </View>
      </View>

      <Teams teamsData={data} onDelete={onDelete} updateTeams={updateTeams} pendingCheckbox />
    </View>
  )
}