import * as React from "react";
import { Input, t, useComponentDidMount } from "owc-react-lib-common";
import { Title } from "src/components/Title";
import { EventContext } from "../EventContext";
import { Loader } from "src/components/Loader";
import { ITableHeader, Table } from "src/components/Table";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import { IconButton } from "src/components/IconButton";
import * as EventService from "src/services/EventService";
import * as ShirtSizeService from "src/services/ShirtSizeService";
import { View } from "src/components/View";
import { IValidationParams, MAX_LENGTH, NUMBER, REQUIRED, useValidation } from "src/hooks/useValidation";
import { ModalConfirm } from "src/components/ModalConfirm";
import { useAlerts } from 'src/hooks/useAlerts';
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const EventShirtSizes = () => {
  const { data: event } = React.useContext(EventContext);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);

  const [formVisible, setFormVisible] = React.useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = React.useState<boolean>(false);
  const [sizeToUpdate, setSizeToUpdate] = React.useState<any>({});
  const [sizeToDelete, setSizeToDelete] = React.useState<any>({});

  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();
  const alerts = useAlerts();
  const form = React.useRef<any>({});

  const setSize = React.useCallback((value: string) => form.current.size = value, []);
  const setPosition = React.useCallback((value: string) => form.current.position = value, []);
  const setEventId = React.useCallback((value: string) => form.current.eventId = value, []);

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "size", type: [REQUIRED, MAX_LENGTH], max: 5 },
      { name: "position", type: [REQUIRED, NUMBER] },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  useComponentDidMount(async () => {
    setLoading(true);
    try {
      const response = await EventService.findShirtSizes(event.uuid);
      setData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setLoading(false);
  });

  const createShirtSize = React.useCallback(async () => {
    if (!validate()) return;

    const shirtSizeDTO: ShirtSizeService.IShirtSizeDTO = {
      eventId: event.id,
      size: form.current.size,
      position: form.current.position
    };

    try {
      const response = await ShirtSizeService.createShirtSize(shirtSizeDTO);
      alerts.create({ text: t("admin.events.shirtSize.createSuccess"), type: "success" });
      setData([...data, { ...response.data }]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setFormVisible(false)
  }, [data, alerts, event, validate]);

  const updateShirtSize = React.useCallback(async () => {
    if (!validate()) return;

    const shirtSizeDTO: ShirtSizeService.IShirtSizeDTO = {
      id: sizeToUpdate.id,
      eventId: event.id,
      size: form.current.size,
      position: form.current.position
    };

    try {
      const response = await ShirtSizeService.updateShirtSize(shirtSizeDTO);
      alerts.create({ text: t("admin.events.shirtSize.updateSuccess"), type: "success" });
      setData([...data.map(shirtSize => shirtSize.id === response.data.id ? response.data : shirtSize)]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setFormVisible(false)
  }, [data, sizeToUpdate, alerts, event, validate]);

  const deleteShirtSize = React.useCallback(async () => {
    try {
      await ShirtSizeService.deleteShirtSize(sizeToDelete.id);
      alerts.create({ text: t("admin.events.shirtSize.deleteSuccess"), type: "success" });
      setData([...data.filter(shirtSize => shirtSize.id !== sizeToDelete.id)]);
    } catch (error) {
      alerts.createDSMError(error);
    }
    setDeleteVisible(false)
  }, [data, sizeToDelete, alerts]);

  const actions = React.useCallback((shirtSize: any) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="edit"
          onPress={() => {
            form.current = shirtSize
            setErrors({})
            setEventId(event.id);
            setSizeToUpdate(shirtSize);
            setFormVisible(true);
          }}
        />
        <IconButton
          backgroundColor={'transparent'}
          color={'black'}
          name="trash"
          onPress={() => {
            setSizeToDelete(shirtSize);
            setDeleteVisible(true);
          }}
        />
      </View>
    );
  }, [event, setEventId]);

  if (loading) {
    return <Loader />;
  }

  const headers: ITableHeader[] = [
    {
      name: "position",
      label: t("admin.events.shirtSize.position"),
      textProps: { uppercase: true },
      width: 100,
    },
    {
      name: "size",
      label: t("admin.events.shirtSize.size"),
      textProps: { uppercase: true },
    },
    {
      name: "actions",
      label: "",
      render: actions,
      width: 100,
      hidden: readOnly
    },
  ];

  return (
    <>
      <Title text={t("admin.events.shirtSize.title")} />
      <Button
        label={t("admin.events.shirtSize.create")}
        onPress={() => {
          form.current = {}
          setErrors({})
          setSizeToUpdate({});
          setFormVisible(true);
        }}
        disabled={readOnly}
      />
      <Table headers={headers} data={data} />
      <Modal
        title={sizeToUpdate.id ? t('admin.events.shirtSize.update') : t('admin.events.shirtSize.create')}
        visible={formVisible}
        onClose={() => setFormVisible(false)}
      >
        <Form>
          <Row flex={[3, 1]}>
            <Input
              onChange={setSize}
              label={t('admin.events.shirtSize.size')}
              error={errors.size}
              defaultValue={form.current.size}
              required
            />
            <Input
              onChange={setPosition}
              label={t('admin.events.shirtSize.position')}
              error={errors.position}
              defaultValue={form.current.position}
              required
              numeric
            />
          </Row>
          <Row>
            {
              sizeToUpdate.id
                ? <Button label={t("global.update")} onPress={updateShirtSize} />
                : <Button label={t("global.create")} onPress={createShirtSize} />
            }
          </Row>
        </Form>
      </Modal>
      <ModalConfirm
        text={t('admin.events.shirtSize.deleteQuestion')}
        visible={deleteVisible}
        onSuccess={deleteShirtSize}
        onClose={() => { setDeleteVisible(false) }}
      />
    </>
  );
};
