import * as React from "react";
import { t } from 'owc-react-lib-common';
import { StyleSheet } from "react-native";
import { Navigate, Route, Routes } from "react-router-dom";
import { View } from "src/components/View";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { Competition } from "src/pages/organizer/competitions/Competition";
import { CompetitionCreate } from "src/pages/organizer/competitions/CompetitionCreate";
import { CompetitionList } from "src/pages/organizer/competitions/CompetitionList";
import { Event } from "src/pages/organizer/events/Event";
import { Facility } from "src/pages/organizer/facilities/Facility";
import { FacilityList } from "src/pages/organizer/facilities/FacilityList";
import { SeasonDetail } from "src/pages/organizer/seasons/SeasonDetail";
import { SeasonList } from "src/pages/organizer/seasons/SeasonList";
import { TeamDetail } from "src/pages/organizer/teams/TeamDetail";
import { UserList } from "src/pages/organizer/users/UserList";
import { AuthenticatedNavbar } from "./AuthenticatedNavbar";
import { RankingSystemList } from "src/pages/organizer/rankingSystems/RankingSystemList";
import { RankingSystem } from "src/pages/organizer/rankingSystems/RankingSystem";
import { OrganizerDetail } from "src/pages/organizer/organizerDetail/OrganizerDetail";
import { Type } from "src/components/Sidebar/types";
import { Sidebar } from "src/components/Sidebar/Sidebar";
import { CategoriesList } from "src/pages/organizer/categories/CategoriesList";
import { CategoriesDetail } from "src/pages/organizer/categories/CategoriesDetail";
import { MODALITY_3x3 } from "src/config";
import { PortalQuestions } from "src/pages/organizer/portalQuestions/PortalQuestions";

export const Organizer = () => {
  const context = React.useContext(OWCOpenDmrContext);

  if (!context.user) {
    return <Navigate to="/login" />;
  }

  if (!context.user.isOrganizer) {
    return <Navigate to="/auth" />;
  }
  const sidebar = [
    { id: 'competitions', type: Type.LINK, to: `/organizer/competitions`, label: t('components.navbar.competitions'), icon: 'ball' },
    { id: 'facilities', type: Type.LINK, to: `/organizer/facilities`, label: t('components.navbar.facilities'), icon: 'eye' },
    { id: 'seasons', type: Type.LINK, to: `/organizer/seasons`, label: t('components.navbar.seasons'), icon: 'calendar' },
    { id: 'ranking', type: Type.LINK, to: `/organizer/ranking-systems`, label: t('components.navbar.rankingSystems'), icon: 'ranking-star' },
    { id: 'categories', type: Type.LINK, to: `/organizer/categories`, label: t('components.navbar.categories'), icon: 'list' },
    { id: 'me', type: Type.LINK, to: `/organizer/me`, label: t('components.navbar.organizer'), icon: 'house' },
  ];

  if (context.configuration.portal) {
    let has3x3 = false;
    context.configuration.portal.modalities.forEach(modality => {
      if (modality.id === MODALITY_3x3) {
        has3x3 = true;
      }
    })

    if (has3x3) {
      sidebar.push(
        { id: 'register-questions', type: Type.LINK, to: `/organizer/register-questions`, label: t('admin.organizer.questions'), icon: 'question' },
      )
    }

  }

  return (
    <View style={styles.container}>
      <AuthenticatedNavbar />
      <View style={styles.container}>
        <View style={styles.content}>
          <Sidebar
            style={{ paddingTop: 10 }}
            elements={sidebar}
            collapsable
            sidebarBackground={context.configuration.colors.primary.main}
            color={context.configuration.colors.primary.light}
          />
          <View flex={1}>
            <Routes>
              <Route path="me" element={<OrganizerDetail />} />
              <Route path="competitions" element={<CompetitionList />} />
              <Route path="competitions/create" element={<CompetitionCreate />} />
              <Route path="competitions/:uuid/*" element={<Competition />} />
              <Route path="users" element={<UserList />} />
              <Route path="events/:eventUuid/*" element={<Event />} />
              <Route path="teams/:teamUuid" element={<TeamDetail />} />
              <Route path="seasons" element={<SeasonList />} />
              <Route path="seasons/create" element={<SeasonDetail />} />
              <Route path="seasons/:uuid" element={<SeasonDetail />} />
              <Route path="facilities" element={<FacilityList />} />
              <Route path="facilities/create" element={<Facility />} />
              <Route path="facilities/:uuid/*" element={<Facility />} />
              <Route path="ranking-systems" element={<RankingSystemList />} />
              <Route path="ranking-systems/create" element={<RankingSystem />} />
              <Route path="ranking-systems/:uuid/*" element={<RankingSystem />} />
              <Route path="categories/" element={<CategoriesList />} />
              <Route path="categories/create" element={<CategoriesDetail />} />
              <Route path="categories/:uuid" element={<CategoriesDetail />} />
              <Route path="categories/:uuid" element={<CategoriesDetail />} />
              <Route path='register-questions' element={<PortalQuestions />} />
              <Route path="*" element={<Navigate to="competitions" />} />
            </Routes>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#eee",
  },
  content: {
    flex: 1,
    flexDirection: 'row'
  },
});
