import * as React from "react";
import { t, Text } from "owc-react-lib-common";

import { StyleSheet } from "react-native";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Input } from "owc-react-lib-common"
import { Loader } from "src/components/Loader";
import { Title } from "src/components/Title";
import { View } from "src/components/View";
import { BACKEND_URL, styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { CUSTOM, PASSWORD, REQUIRED, useValidation } from "src/hooks/useValidation";
import { PersonForm } from "./components/PersonForm";
import * as PeopleService from "src/services/PeopleService";
import * as UserService from "src/services/UserService";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import QRCode from "react-qr-code";

export const Settings = () => {
  const alerts = useAlerts();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>({});
  const [identityCode, setIdentityCode] = React.useState<string>('');
  // form
  const form = React.useRef<any>({});
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();

  const setCurrentPassword = React.useCallback((value: string) => (form.current.currentPassword = value), []);
  const setPassword = React.useCallback((value: string) => (form.current.password = value), []);
  const setPassword2 = React.useCallback((value: string) => (form.current.password2 = value), []);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    try {
      // const a = await UserService.connect(); 
      const [people, identityCode] = await Promise.all([
        PeopleService.findMe(),
        UserService.getIdentityCode(),
      ]);

      setData(people.data);
      setIdentityCode(JSON.stringify({
        id: Math.random(),
        code: identityCode.data.code,
        url: BACKEND_URL + '/public/users/identity/bind',
      }));
    } catch (error) {
      alerts.createDSMError(error);
    }

    setLoading(false);
  });

  /**
   * on person form save
   */
  const onPersonFormSave = React.useCallback(() => {
    alerts.create({ type: "success", text: t("auth.settings.personFormSuccess") });
  }, [alerts]);

  /**
   * change password
   */
  const changePassword = React.useCallback(async () => {
    // validate
    const { errors, hasErrors } = validateAll(form.current, [
      { name: "currentPassword", type: [REQUIRED] },
      { name: "password", type: [REQUIRED, PASSWORD] },
      {
        name: "password2",
        type: [REQUIRED, CUSTOM],
        validate: () => {
          return form.current.password !== form.current.password2 && t("errors.password.confirmation");
        },
      },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    // request
    try {
      await UserService.changePassword(form.current.currentPassword, form.current.password);
      alerts.create({ type: "success", text: t("auth.settings.changePasswordSuccess") });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validateAll]);

  /**
   * loading
   */
  if (loading) {
    return <Loader />;
  }

  /**
   * render
   */
  return (
    <Container  >
      <View style={styles.container}>
        <View style={[styles.content, styles.personForm]}>
          <Title text={t("auth.settings.personFormTitle")} />

          <Alert type="info" text={t("auth.settings.personFormDescription")} />
          <PersonForm data={data} onSuccess={onPersonFormSave} />
        </View>

        <View style={styles.content}>
          <Title text={t("auth.settings.passwordTitle")} />

          <Alert type="info" text={t("auth.settings.passwordDescription")} />
          <Form>
            <Row>
              <Input
                label={t("auth.settings.currentPassword")}
                onChange={setCurrentPassword}
                error={errors.currentPassword}
              />
            </Row>

            <Row>
              <Input label={t("auth.settings.password")} onChange={setPassword} error={errors.password} />
            </Row>

            <Row>
              <Input label={t("auth.settings.passwordConfirmation")} onChange={setPassword2} error={errors.password2} />
            </Row>

            <Row>
              <Button label={t("global.save")} onPress={changePassword} />
            </Row>
          </Form>

          <View style={styles.qrContainer}>
            <Title text={t('auth.settings.bindIdentity')} />
            <Text style={{ marginBottom: 20 }}>{t('auth.settings.bindIdentityDescription')}</Text>
            <QRCode value={identityCode} />
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  content: {
    flex: 1,
  },
  personForm: {
    marginRight: styling.spacing,
  },
  qrContainer: {
    // marginTop: 37,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 20,
    borderRadius: 5,
    backgroundColor: '#eee',
  },
});
