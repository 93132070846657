import * as React from 'react';
import { StyleSheet } from 'react-native';
import { View } from './View';
import { Text } from './Text';
import { Button } from './Button';
import { IconButton } from "./IconButton";

export const OWCStepper = ({ children, containerStyle, activeStep }: any) => {
  return (
    <View style={[styles.stepper, containerStyle]}>
      {children.map((child: any, idx: any) => (
        <React.Fragment key={idx}>
          <OWCStep label={child.props.label} value={child.props.value} key={idx} id={idx} activeStep={activeStep === idx} onPress={child.props.onPress} completed={child.props.completed} />
          {(idx !== children.length - 1) && <View style={{ flex: 1, height: 1, backgroundColor: '#AAAAAA', paddingHorizontal: 4 }} />}
        </React.Fragment>
      ))}
    </View>
  )
}

export const OWCStep = ({ label, id, activeStep, onPress, completed }: any) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0.5em' }}>
      {completed ? (
        <IconButton name='ok' backgroundColor='transparent' color='green' onPress={onPress} />
      ) : (
        <Button backgroundColor={(!activeStep && 'grey') || ''} onPress={onPress} label={id + 1} containerStyle={styles.button} marginBottom={0} minHeight={0}></Button>
      )}

      <Text bold>{label}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  stepper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  button: {
    minHeight: 20,
    minWidth: 20,
    height: 20,
    width: 20,
    borderRadius: 100,
    marginRight: '0.5em',
    padding: 0,
  }
});
