import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

export interface ISettingDTO {
  id: number;
  eventCategoryId?: number;
  portalId?: number;
  roleId: number;
  typeId: number;
  visible: boolean,
  required: boolean;
}

const BASE_URL = BACKEND_URL + '/auth/settings'

export const findByEventCategory = (eventCategoryId: number) => {
  return RestService.get(`${BASE_URL}/${eventCategoryId}`);
}

export const findByPortalId = (id: number) => {
  return RestService.get(`${BASE_URL}/portal/${id}`);
}

export const addSetting = (eventCategoryId: number, settings: ISettingDTO) => {
  return RestService.post(`${BASE_URL}/${eventCategoryId}`, settings);
}

export const updateSetting = (eventCategoryId: number, settings: ISettingDTO) => {
  return RestService.put(`${BASE_URL}/${eventCategoryId}`, settings);
}

export const replicateEventCategory = (eventCategoryId: number, eventCategoryToRepId: number) => {
  return RestService.put(`${BASE_URL}/replicate/${eventCategoryId}/${eventCategoryToRepId}`);
}





