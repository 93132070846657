import * as React from 'react';
import { t } from "owc-react-lib-common";
import { View } from "src/components/View";
import { Text } from "src/components/Text";
import { ITableHeader, Table } from "src/components/Table";
import { IconButton } from 'src/components/IconButton';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Form } from 'src/components/Form';
import { Row } from 'src/components/Row/Row';
import { Input } from 'owc-react-lib-common';
import { IValidationParams, NUMBER, REQUIRED, useValidation } from 'src/hooks/useValidation';
import * as RateService from 'src/services/RateService';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { useAlerts } from 'src/hooks/useAlerts';
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { EventContext } from '../EventContext';
import { MODALITY_3x3, MODALITY_5x5 } from 'src/config';

interface IEventCategoryRates {
  data: any;
}

const RATE_TYPE_TEAM = 3;

export const EventCategoryRates = ({ data }: IEventCategoryRates) => {
  const context = React.useContext(EventContext);
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [rateToUpdate, setRateToUpdate] = React.useState<any>({});
  const [rateToDelete, setRateToDelete] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>({});
  const { validateAll } = useValidation();
  const alerts = useAlerts();

  const form = React.useRef<any>({});

  const setEventCategoryId = React.useCallback((value: string) => form.current.eventCategoryId = value, []);
  const setAmount = React.useCallback((value: string) => form.current.amount = value, []);
  const setBonusRate = React.useCallback((value: string) => form.current.bonusRate = value, []);
  const setName = React.useCallback((value: string) => form.current.name = value, []);
  const setDescription = React.useCallback((value: string) => form.current.description = value, []);

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "amount", type: [REQUIRED, NUMBER] },
      { name: "description", type: REQUIRED },
    ];
    if(context.data.modalityId == MODALITY_5x5){
      formRequiredFields.push({ name: "bonusRate", type: [REQUIRED, NUMBER] })
    }
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  const createRate = React.useCallback(async () => {
    if (!validate()) return;

    const payload = {
      eventCategoryId: form.current.eventCategoryId,
      amount: parseFloat(form.current.amount),
      name: form.current.name,
      bonusRate: form.current.bonusRate ? parseFloat(form.current.bonusRate) : 0.0,
      description: form.current.description,
      rateType: RATE_TYPE_TEAM,
    }
    try {
      const response = await RateService.create(payload);
      if (!data.rates) {
        data.rates = [];
      }
      alerts.create({ text: t("admin.events.rates.createSuccess"), type: "success" });

      data.rates.push(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }

    setOpenModal(false)
  }, [alerts, data, validate]);

  const updateRate = React.useCallback(async () => {
    if (!validate()) return;
    const payload = {
      id: rateToUpdate.id,
      eventCategoryId: form.current.eventCategoryId,
      amount: parseFloat(form.current.amount),
      bonusRate: parseFloat(form.current.bonusRate),
      name: form.current.name,
      description: form.current.description,
      rateType: RATE_TYPE_TEAM,
    }

    try {
      const response = await RateService.update(payload);
      alerts.create({ text: t("admin.events.rates.updateSuccess"), type: "success" });
      data.rates = data.rates.map(rate => {
        if (rate.id === response.data.id) {
          rate = response.data;
        }
        return rate;
      });
      setRateToUpdate({});
      setOpenModal(false);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [rateToUpdate, data, validate, alerts]);

  const deleteRate = React.useCallback(async () => {
    try {
      await RateService.deleteRate(rateToDelete.id);
      alerts.create({ text: t("admin.events.rates.deleteSuccess"), type: "success" });

      data.rates = data.rates.filter(r => r.id !== rateToDelete.id)
      setRateToDelete({});
      setDeleteVisible(false);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [rateToDelete, alerts, data])
  /**
   * get table row actions
   */
  const actions = React.useCallback(
    (rate: any) => {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="edit"
            onPress={() => {
              setErrors({})
              setEventCategoryId(data.id);
              setName(rate.name);
              setDescription(rate.description);
              setAmount(rate.amount);
              setRateToUpdate(rate);
              setOpenModal(true);
            }}
          />
          <IconButton
            backgroundColor={'transparent'}
            color={'black'}
            name="trash"
            onPress={() => {
              setRateToDelete(rate);
              setDeleteVisible(true);
            }}
          />
        </View>
      );
    },
    [data, setAmount, setDescription, setEventCategoryId, setName]
  );

  const renderAmount = (amount) => {
    return (<View><Text>{amount}€</Text></View>)
  }

  let headers: ITableHeader[] = [
    {
      name: "name",
      label: t("admin.events.rates.name"),
      flex: 1,
    },
    {
      name: "description",
      label: t("admin.events.rates.description"),
      flex: 1,
    },
    {
      name: "amount",
      label: t("admin.events.rates.amount"),
      width: 300,
      textProps: { uppercase: true },
      render: (o) => renderAmount(o.amount)
    },
    {
      name: "bonusRate",
      label: t("admin.events.rates.bonusrate"),
      width: 300,
      textProps: { uppercase: true },
      render: (o) => renderAmount(o.bonusRate)
    },
    { name: "actions", label: " ", render: actions, width: 100, hidden: readOnly },
  ];

  if (context.data.modalityId == MODALITY_3x3) {
    headers = headers.filter((h) => h.name !== 'bonusRate')
  }

  return (
    <View>
      <Button onPress={() => {
        form.current = {};
        setErrors({})
        setEventCategoryId(data.id);
        setOpenModal(true);
        setRateToUpdate({});
      }} label={t('admin.events.rates.create')} disabled={readOnly} />
      <Table data={data.rates} headers={headers} />
      <Modal title={rateToUpdate.id ? t('admin.events.rate.update') : t('admin.events.rate.create')} header visible={openModal} onClose={() => setOpenModal(false)}>
        <Form >
          <Row>
            <Input onChange={setName} label={t('admin.events.rate.name')} error={errors.name} defaultValue={rateToUpdate?.name} required />
            <Input onChange={setAmount} label={t('admin.events.rate.amount')} error={errors.amount} defaultValue={rateToUpdate?.amount} numeric required />
            {context.data.modalityId == MODALITY_5x5 && <Input onChange={setBonusRate} label={t('admin.events.rate.bonusRate')} error={errors.bonusRate} defaultValue={rateToUpdate?.bonusRate} numeric required />}
          </Row>
          <Row>
            <Input onChange={setDescription} label={t('admin.events.rate.description')} error={errors.description} defaultValue={rateToUpdate?.description} required />
          </Row>
          <Row>
            {
              rateToUpdate.id
                ? <Button label={t("global.update")} onPress={updateRate} />
                : <Button label={t("global.create")} onPress={createRate} />
            }
          </Row>
        </Form>
      </Modal>
      <ModalConfirm visible={deleteVisible} onSuccess={deleteRate} onClose={() => { setDeleteVisible(false) }} text={t('admin.events.rate.delete')} />
    </View>
  );
};
