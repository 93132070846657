import { Input, t, useComponentDidMount } from "owc-react-lib-common";
import * as React from "react";
import { Button } from "src/components/Button";
import { ButtonLink } from "src/components/ButtonLink";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useAlerts } from "src/hooks/useAlerts";
import * as UserService from "src/services/UserService";
import * as UserUtils from "src/utils/UserUtils";
import { UserActionCard } from "./components/UserActionCard";

export const Login = () => {
  // const [loading, setLoading] = React.useState<boolean>(false);
  const context = React.useContext(OWCOpenDmrContext);
  const alerts = useAlerts();
  // form fields
  const username = React.useRef("");
  const password = React.useRef("");
  const setUsername = React.useCallback((value: string) => (username.current = value), []);
  const setPassword = React.useCallback((value: string) => (password.current = value), []);
  const [errors, setErrors] = React.useState<any>({});
  const [socketData, setSocketData] = React.useState<string>("");

  useComponentDidMount(async () => {
    // on connect to server
    // Comentado hasta arreglar que no se cierren las conexiones
    // const socketId = Math.floor(Math.random() * 1000000000) + '';

    // const socket = new EventSource(BACKEND_URL + '/public/users/identity?id=' + socketId);

    // socket.addEventListener(socketId, (event: any) => {
    //   const { data, type } = JSON.parse(event.data);

    //   setSocketData(JSON.stringify(data));

    //   if (type === "LOGIN") {
    //     const user = UserUtils.decode("Bearer " + data);

    //     if (!!user) {
    //       context.setUser(user);
    //     }
    //   }
    // });
  });

  /**
   * validate form
   */
  const validate = React.useCallback(() => {
    const errors: any = {};

    if (!username.current?.trim()) {
      errors.username = t("errors.required");
    }

    if (!password.current?.trim()) {
      errors.password = t("errors.required");
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  }, []);

  /**
   * login
   */
  const login = React.useCallback(async () => {
    // validate
    if (!validate()) {
      return;
    }

    try {
      const response = await UserService.login(username.current, password.current);
      const user = UserUtils.decode(response.headers.authorization);

      if (!!user) {
        context.setUser(user);
      }
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [validate, context, alerts]);

  const handleKeyDown = (e) => {
    if (e == "Enter") {
      login();
    }
  }

  /**
   * render
   */
  const tabs = [
    { to: "/login", label: t("public.users.login.title") },
    { to: "/register", label: t("public.users.login.register") },
  ];


  return (
    <UserActionCard tabs={tabs}>
      <Form>
        <Row>
          <Input onKeyPress={handleKeyDown} label={t("public.users.login.username")} onChange={setUsername} defaultValue={username.current} error={errors.username} />
        </Row>

        <Row>
          <Input onKeyPress={handleKeyDown} label={t("public.users.login.password")} onChange={setPassword} defaultValue={password.current} error={errors.password} secureTextEntry />
        </Row>

        <Row isColumn={false} flex={[1, null]}>
          <Button label={t("public.users.login.login")} onPress={login} />
          <ButtonLink to="/password-recovery-request" label={t("public.users.login.passwordRecovery")} />
        </Row>

        {/* <Row>
          <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}>
            {!socketData?.length ? (
              <View style={{ width: 256, height: 256 }} />
            ) : (
              <QRCode value={socketData} />
            )}
          </View>
        </Row> */}
      </Form>
    </UserActionCard>
  );
};

