import { Loader, OWCContext } from 'owc-react-lib-common';
import * as React from 'react';
import { ColorValue, StyleSheet, ViewProps } from 'react-native';
import { Icon } from './Icon';
import { View } from './View';

export interface IIconButton {
  name: 'clipboard' | 'arrowright' | 'link' | 'file' | 'circle' | 'fileDownload' | 'creditCard' | 'calendar' | 'warning' | 'card' | 'help' | 'phone' | 'email' | 'arrowleft' | 'rotateLeft' | 'rotateRight' | 'map' | 'ball' | 'search' | 'text' | 'ok' | 'setting' | 'backtime' | 'previewtime' | 'ko' | 'add' | 'edit' | 'remove' | 'more' | 'less' | 'check' | 'times' | 'close' | 'plus' | 'minus' | 'arrow-up' | 'arrow-down' | 'download' | 'upload' | 'list' | 'burger' | 'user' | 'at' | 'exclamation' | 'exclamation-circle' | 'question' | 'play-circle' | 'eye' | 'eye-slash' | 'paper-plane' | 'info-circle' | 'trash' | 'trash-restore' | 'clock' | 'cart' | 'stats' | 'bold' | 'italic' | 'underline' | 'image' | 'ul' | 'ol' | 'alignLeft' | 'alignCenter' | 'alignRight' | 'alignJustify' | 'clone' | 'ban' | 'language' | 'user-shield' | 'people-group' | 'bed' | 'shirt' | 'arrow-turn-right' | 'arrow-turn-up' | 'send' | 'right-left' | 'arrow-up-2' | 'arrow-down-2' | 'wand' | 'excel' | 'pdf' | 'envelope';
  onPress: (v: any) => void;
  color?: ColorValue;
  type?: 'primary' | 'secondary';
  containerStyle?: ViewProps['style'];
  fontSize?: number;
  disabled?: boolean;
  backgroundColor?: ColorValue;
  text?: string;
  title?: string;
  padding?: number;
}

export const IconButton = ({ name, type = 'primary', color, backgroundColor, onPress, fontSize, containerStyle, disabled = false, text, title, padding = 5 }: IIconButton) => {
  const context = React.useContext(OWCContext);
  const [loading, setLoading] = React.useState(false);

  backgroundColor = backgroundColor || context.colors[type].background;
  color = color || context.colors[type].text;

  /**
   * on press
   */
  const handlePress = React.useCallback(async (args: any) => {
    setLoading(true);

    try {
      await Promise.resolve(onPress(args));
    } catch (error) {
      //
    }

    setLoading(false);

  }, [onPress]);

  if (loading) {
    return <Loader size='small' margin={0} />
  }
  /**
   * render
   */
  return (
    <View style={[styles.container, containerStyle, { backgroundColor }, (loading || disabled) && styles.disabled]}>
      <Icon padding={padding} title={title} text={text} onPress={handlePress} name={name} fontSize={fontSize} style={styles.icon} color={color} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: '50%' as any,
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    backgroundColor: '#eee',
  },
  icon: {
    margin: '0'
  }
});