import { t } from 'owc-react-lib-common';
import { Alert } from "src/components/Alert";
import { View } from "src/components/View";

export const Step09 = () => {
  return (
    <View>
      <Alert text={t('public.actorRegistration.step09.success')} type="success" />
    </View>
  );
}