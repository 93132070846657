import * as React from 'react';
import { Picker, t } from 'owc-react-lib-common';
import { StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import { styling } from 'src/config';
import { Button, IButton } from './Button';
import { Checkbox } from './Checkbox';
import { Icon } from './Icon';
import { Input } from 'owc-react-lib-common';
import { Modal } from './Modal';
import { IText, Text } from './Text';
import { View } from './View';
import * as TextUtils from 'src/utils/TextUtils';
import { IconButton } from './IconButton';

export interface ITable {
  headers: ITableHeader[];
  data: any[];
  footer?: any;
  defaultSize?: number;
  sizes?: number[];
  containerStyle?: ViewProps['style'],
  buttons?: IButton[];
  pagination?: boolean;
  csv?: boolean;
  columnVisibility?: boolean;
  headerSize?: number;
  defaultSort?: any;
  defaultSortDirection?: any;
}

export interface ITableHeader {
  name: string;
  label: string;
  render?: (object: any) => React.ReactNode;
  headerRender?: (object: any) => React.ReactNode;
  width?: number;
  flex?: number;
  hidden?: boolean;
  headerTextProps?: IText;
  textProps?: IText | ((object: any) => IText);
  search?: (object: any) => string | number;
  sort?: (object: any) => string | number | boolean;
}

const getColumnStyle = (header: ITableHeader) => {
  const { width, flex } = header;
  return { width, flex: flex || !width ? 1 : undefined }
}

export const Table = ({ headers: defaultHeaders, data = [], defaultSize = 10, sizes = [5, 10, 25, 50, 100], buttons = [], pagination = true, containerStyle, csv = false, columnVisibility = false, footer, headerSize, defaultSort, defaultSortDirection = 1 }: ITable) => {

  const [headers, setHeaders] = React.useState(defaultHeaders);
  const [filtered, setFiltered] = React.useState<any[]>(data);
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState<any>(() => {
    return { name: defaultSort, direction: defaultSortDirection }
  });
  const [size, setSize] = React.useState(defaultSize);
  const [visibility, setVisibility] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [info, setInfo] = React.useState<any>({ filtered, pages: 1 });

  /**
   * on header change
   */
  React.useEffect(() => {
    setHeaders(prev => prev.map((header: ITableHeader, index: number) => ({ ...defaultHeaders[index], hidden: header.hidden })));
  }, [defaultHeaders]);

  /**
   * filter
   */
  React.useEffect(() => {

    if (!pagination) {
      setFiltered(data);
      return;
    }

    let filtered = data ? [...data] : [];

    // search
    if (!!search) {
      filtered = filtered.filter((object: any) => headers.some(header => {
        const text = TextUtils.normalize(!!header.search ? header.search(object) : object[header.name]);
        return text.includes(TextUtils.normalize(search))
      }));
    }

    // sort
    const header = headers.find((header => header.name === sort.name));

    filtered = filtered.sort((a: any, b: any) => {
      const value1 = !!header?.sort ? header.sort(a) : a[sort.name];
      const value2 = !!header?.sort ? header.sort(b) : b[sort.name];

      if (TextUtils.isNumber(value1) && TextUtils.isNumber(value2)) {
        return (parseFloat(value1) - parseFloat(value2)) * sort.direction;
      }

      return (value1 + '').toLowerCase().localeCompare((value2 + '').toLowerCase()) * sort.direction;
    });

    // info
    const info = {
      filtered: filtered.length,
      pages: Math.max(Math.ceil(filtered.length / size), 1),
    };

    // paginate
    filtered = filtered.slice(page * size, page * size + size);

    // set filtered
    setInfo(info);
    setFiltered(filtered);
  }, [pagination, headers, data, search, sort, size, page]);

  /**
   * on search
   */
  const onSearch = (value: any) => setSearch(value);

  /**
   * on header click
   */
  const onHeaderClick = React.useCallback((header: any) => {
    if (!pagination) {
      return;
    }

    if (sort.name === header.name) {
      setSort({ ...sort, direction: sort.direction === 1 ? -1 : 1 });
    }
    else {
      setSort({ name: header.name, direction: 1 });
    }
  }, [pagination, sort]);

  /**
   * on change size
   */
  const onChangeSize = React.useCallback((value: string) => {
    setSize(parseInt(value));
    setPage(0);
  }, []);

  /**
   * toggle visibility
   */
  const toggleVisibility = React.useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  /**
   * 
   */
  const onHeaderVisibility = React.useCallback((object: ITableHeader, value: boolean) => {
    setHeaders(headers.map((header => {
      if (header.name === object.name) {
        return { ...object, hidden: value };
      }

      return header;
    })));
  }, [headers]);

  /**
   * csv
   */
  const exportCsv = React.useCallback(() => {
  }, []);

  /**
   * render
   */
  return (
    <View style={[styles.container, containerStyle]}>
      {pagination && (
        <View style={styles.actions}>
          <Picker selected={size} defaultOptionLabel={null} items={sizes.map((size: number) => ({ label: size + '', value: size }))} onChange={onChangeSize} containerStyle={styles.size} />
          <Input placeholder={t('components.table.search') + '...'} onChange={onSearch} containerStyle={styles.search} />

          <View style={styles.buttons}>
            {columnVisibility && <Button type='primary' icon={{ name: 'clipboard' }} onPress={toggleVisibility} containerStyle={styles.button} />}
            {csv && <Button type='primary' icon={{ name: 'download' }} onPress={exportCsv} containerStyle={styles.button} />}
            {buttons.map((button: IButton, idx: number) => <Button key={idx} {...button} containerStyle={[button.containerStyle, styles.button]} />)}
          </View>
        </View>
      )}

      <View style={styles.headers}>
        {headers.filter(header => !header.hidden).map((header: ITableHeader, columnIdx: number) => {
          const { label, name: key, headerTextProps } = header;

          return (
            <TouchableOpacity
              key={key}
              onPress={() => onHeaderClick(header)}
              activeOpacity={1}
              style={[styles.cell, columnIdx === 0 && styles.cellFirst, styles.header, getColumnStyle(header)]}
            >
              {!!header.headerRender ?
                header.headerRender(header) :
                <Text fontSize={headerSize || 13} bold uppercase style={styles.headerLabel} {...headerTextProps}>{label}</Text>
              }

              {!!(header.name === sort.name && sort.direction) && (
                <Icon
                  name={sort.direction === 1 ? 'arrow-down' : 'arrow-up'}
                  style={{ justifyContent: 'center', position: 'absolute', right: 5, bottom: 0, top: 0, margin: 'auto', paddingBottom: 5, background: '#fff' }}
                />
              )}
            </TouchableOpacity>
          );
        })}
      </View>

      <View style={styles.body}>
        {!filtered?.length ? (
          <View style={styles.row} justifyContent='center'>
            <View style={styles.cell}>
              <Text>{t('components.table.empty')}</Text>
            </View>
          </View>
        ) : (
          filtered.map((row: any, index: number) => {
            return (
              <View key={row.idx || index} style={styles.row}>
                {headers.filter((header: ITableHeader) => !header.hidden).map((header: ITableHeader, columnIdx: number) => {
                  const { render, textProps } = header;
                  const props = typeof textProps === 'function' ? textProps(row) : textProps;

                  return (
                    <View key={header.name} style={[styles.cell, columnIdx === 0 && styles.cellFirst, styles.content, getColumnStyle(header)]}>
                      {render?.(row) || <Text {...props}>{row[header.name]}</Text>}
                    </View>
                  );
                })}
              </View>
            );
          }))}
      </View>

      {!!footer && (
        <View style={styles.footer}>
          <View style={styles.row}>
            {headers.filter((header: ITableHeader) => !header.hidden).map((header: ITableHeader, columnIdx: number) => {
              const { textProps } = header;

              return (
                <View key={header.name} style={[styles.cell, columnIdx === 0 && styles.cellFirst, styles.content, getColumnStyle(header)]}>
                  {<Text bold {...textProps}>{footer[header.name]}</Text>}
                </View>
              );
            })}
          </View>
        </View>
      )}

      {pagination ? (
        <React.Fragment>
          <View style={styles.infoContainer} justifyContent='space-between'>
            <View style={styles.pagination}>
              <IconButton containerStyle={{ borderRadius: 10 }} backgroundColor={'transparent'} color={'black'} name={'arrowleft'} onPress={() => setPage(Math.max(page - 1, 0))}></IconButton>
              <Text style={styles.pinfo}>{t('components.table.pagination', { page: page + 1, pages: info.pages })}</Text>
              <IconButton containerStyle={{ borderRadius: 10 }} backgroundColor={'transparent'} color={'black'} name={'arrowright'} onPress={() => setPage(Math.min(page + 1, info.pages - 1))}></IconButton>
            </View>

            <View style={styles.info}>
              <Text>{t('components.table.filtered', { filtered: info.filtered, total: data?.length })}</Text>
            </View>
          </View>

          <Modal visible={visibility} onClose={() => setVisibility(false)} minWidth={300}>
            <View style={[styles.visibility, { display: visibility ? 'flex' : 'none' }]}>
              {headers.map((header: ITableHeader) => <Checkbox key={header.name} label={header.label} value={!header.hidden} onChange={(value: boolean) => onHeaderVisibility(header, !value)} containerStyle={styles.checkbox} />)}
            </View>
          </Modal>
        </React.Fragment>
      ) : (
        <View style={styles.infoContainer} justifyContent='flex-end'>
          <View style={styles.info}>
            <Text>{t('components.table.info', { total: data.length })}</Text>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: styling.spacing,
  },
  actions: {
    flexDirection: 'row',
    marginBottom: styling.spacing,
  },
  size: {
    marginRight: 5,
    width: 80,
    marginBottom: 0,
  },
  search: {
    flex: 1,
    marginBottom: 0,
  },
  visibility: {
    padding: styling.spacing,
  },
  checkbox: {
    marginBottom: 0,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    // marginLeft: styling.spacing,
    marginLeft: 5,
    marginBottom: 0,

  },
  cell: {
    paddingHorizontal: 5,
    paddingVertical: 10,
    justifyContent: 'center',
    minHeight: 60,
    overflow: 'hidden',
  },
  cellFirst: {
  },
  headers: {
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#ccc',
    overflow: 'hidden',
  },
  header: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerLabel: {
    flex: 1,
    textOverflow: 'ellipsos' as any,
  },
  body: {
    overflow: 'hidden',
  },
  footer: {
    overflow: 'hidden',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  content: {
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
  },
  pagination: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pinfo: {
    marginHorizontal: 5,
  },
  pbutton: {
    marginBottom: 0,
  },
  info: {
  },
});