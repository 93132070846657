import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Alert, Icon, t, Table } from "owc-react-lib-common";
import { IconButton } from "src/components/IconButton";
import { Text } from "src/components/Text";
import { useQueryParams } from 'src/hooks/useQueryParams';

interface IRegisterTeam3x3Category {
  disabled?: boolean;
  eventCategories: any[];
  onSelectCategory: (category: any) => void;
}

export const RegisterTeam3x3Category = ({ disabled = false, eventCategories, onSelectCategory }: IRegisterTeam3x3Category) => {
  const [, setParams] = useSearchParams();
  const { eventCategoryId } = useQueryParams();

  const addParams = React.useCallback((id: any) => {
    setParams({ 'eventCategoryId': id })
  }, [setParams])

  const headers = [
    t('3x3.register.name'),
    { label: t('3x3.register.age'), width: 120 },
    { label: t('3x3.register.playerAmount'), width: 120 },
    { key: 'actions', width: 200, justifyContent: 'flex-end' }
  ];

  if (eventCategoryId) {
    return (
      <>
        {
          eventCategories.find(ec => ec.id == eventCategoryId)?.waitingRoomText
            ? <Alert type='info' text={eventCategories.find(ec => ec.id == eventCategoryId)?.waitingRoomText} containerStyle={{ marginBottom: 0 }} />
            : <Alert text={t('auth.competitions.teamCreation.category.enrollmentClosedAlert')} containerStyle={{ marginBottom: 0 }} />
        }
      </>


    )
  }

  return (
    <Table searchable={false} csvButton={false} pagination={false} headers={headers} data={eventCategories.map((eventCategory: any) => [
      eventCategory.name,
      `${eventCategory.minAge || 0} - ${eventCategory.maxAge || 99}`,
      `${eventCategory.minPlayers} - ${eventCategory.maxPlayers}`,
      eventCategory.inscriptionOpen ?
        !disabled && <Icon type='arrowright' onPress={() => onSelectCategory(eventCategory)} />
        :
        !disabled &&
        <>
          <Text uppercase textAlign='center' color='crimson'>{t('auth.competitions.teamCreation.category.enrollmentClosed')}</Text>
          <IconButton containerStyle={{ width: 30, height: 30 }} onPress={() => { addParams(eventCategory.id) }} name="info-circle" />
        </>

    ])} />
  );
}