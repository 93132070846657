import { BACKEND_URL } from '../config';
import * as RestService from './RestService';


export interface IRoomTypeDTO {
  id?: number;
  description?: string;
  availableRooms?: number | any;
  peopleCapacity?: number | any;
  freeRooms?: number;
  hotelId?: number;
  hotelName?: string
}




const BASE_URL = BACKEND_URL + '/auth/room-types'

/**
 * 
 * Devuelve los hoteles de un evento. En el dto incluye la lista de tipos de habitacion 
 */
export const findHotelsByEvent = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/by-event/${eventUuid}`);
}


/**
 * 
 
 */
export const create = (roomType: IRoomTypeDTO) => {
  return RestService.post(`${BASE_URL}`, roomType);
}


/**
* 
 
*/
export const update = (roomType: IRoomTypeDTO) => {
  return RestService.put(`${BASE_URL}`, roomType);
}

/**
   * 
   
   */
export const deleteRoomType = (roomTypeId: number) => {
  return RestService.delete(`${BASE_URL}/${roomTypeId}`);
}


