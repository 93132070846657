import { Icon, Loader, Modal, t, Table, useComponentDidMount } from "owc-react-lib-common";
import * as React from 'react';
import { StyleSheet, TouchableOpacity } from "react-native";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Text } from "src/components/Text";
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { Translation, usePortalTranslations } from "src/hooks/usePortalTranslations";
import * as ShirtSizeService from 'src/services/ShirtSizeService';
import * as Team3x3Service from 'src/services/Team3x3Service';
import { STATUS } from "../../competitions/RegisterTeam3x3";
import { Team3x3Actors } from "./Team3x3Actors";
import { Team3x3Data } from "./Team3x3Data";

export const Teams3x3 = () => {
	const navigate = useNavigate();
	const { createDSMError } = useAlerts();
	const [teamToCreate, setTeamToCreate] = React.useState<any>(undefined);
	const [teamToUpdate, setTeamToUpdate] = React.useState<any>(undefined);
	const [actorsToUpdate, setActorsToUpdate] = React.useState<any>(undefined);
	const [data, setData] = React.useState<any[]>([]);
	const [shirtSizes, setShirtSizes] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const translate = usePortalTranslations();

	/**
	 * component did mount
	 */
	useComponentDidMount(async () => {
		try {
			ShirtSizeService.getAll().then(response => {
				setShirtSizes(response.data)
			});

			const response = await Team3x3Service.getAll();
			setData(response.data);
		} catch (error) {
			createDSMError(error);
		}

		setLoading(false);
	});

	/**
	 * on create team
	 */
	const onCreate = React.useCallback((object: any) => {
		// update state
		if (!!teamToCreate?.id) {
			setData(data.map((team: any) => team.id === object.id ? object : team));
		}
		else {
			setData([...data, object]);
		}

		setTeamToCreate(undefined);
		setActorsToUpdate(object);
	}, [teamToCreate, data]);

	/**
	 * on update team
	 */
	const onUpdate = React.useCallback((object: any) => {
		setData(data.map((team: any) => team.id === object.id ? object : team));
		setTeamToUpdate(undefined);
	}, [data]);

	/**
	 * on update actor
	 */
	const onUpdateActor = React.useCallback((object: any) => {
		const teamIdx = data.findIndex((team: any) => team.id === object.teamId);

		if (teamIdx < 0) {
			return;
		}

		const teams = data.map((team: any, idx: number) => {
			if (idx !== teamIdx) {
				return team;
			}

			let found = false;

			const actors = team.actors.map((actor: any) => {
				if (actor.id === object.id) {
					found = true;
					return object;
				}

				return actor;
			})

			if (!found) {
				actors.push(object);
			}

			return { ...team, actors };
		})


		setData(teams);
	}, [data]);

	/**
	 * on delete actor
	 */
	const onDeleteActor = React.useCallback((object: any) => {
		setData(data.map((team: any) => team.id !== object.teamId ? team : {
			...team,
			actors: team.actors.filter((actor: any) => actor.id !== object.id),
		}))
	}, [data]);


	/**
	 * loading
	 */
	if (loading) {
		return <Loader />
	}

	/**
	 * render
	 */
	const headers = [
		t('3x3.teams.name'),
		t('3x3.teams.categories'),
		{ label: t('3x3.teams.actorAmount'), width: 100 },
		{ key: 'actions', width: 100 }
	];

	return (
		<Container title={t(translate(Translation.THREEONTHREE_TEAM_TITLE))} description={t(translate(Translation.THREEONTHREE_TEAM_HELP))}>
			<Table headers={headers} data={data.map((team: any) => [
				team.name,
				<View>
					{team.teamEventCategories.map((teamEventCategory: any) => (
						<TouchableOpacity
							key={teamEventCategory.id}
							onPress={() => { navigate(`/auth/3x3/team-event-categories/${teamEventCategory.uuid}`) }}
							style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}
						>
							<Icon type={teamEventCategory.status === STATUS.CREATED ? 'clock' : 'ok'} color={(teamEventCategory.status === STATUS.COMPLETE || teamEventCategory.status === STATUS.INVITE) ? 'teal' : 'red'} />

							<View>
								{teamEventCategory.status === STATUS.CREATED ?
									<View style={{ backgroundColor: '#FFDDDD', borderColor: 'red', borderWidth: 1, padding: 8 }}>
										<Text color={'#FF1111'} uppercase >{t('3x3.teams.notFinished')}</Text>
										<Text color={'#FF1111'} uppercase >{t('3x3.teams.notFinishedText')}</Text>
										<Text>{`${teamEventCategory.eventCategory.competitionName} - ${teamEventCategory.eventCategory.eventName}, ${teamEventCategory.eventCategory.name}`.toUpperCase()}</Text>
									</View>
									:
									<Text>{`${teamEventCategory.eventCategory.competitionName} - ${teamEventCategory.eventCategory.eventName}, ${teamEventCategory.eventCategory.name}`.toUpperCase()}</Text>
								}
							</View>
						</TouchableOpacity>
					))}
				</View>,
				team.actors.length,
				<View style={styles.actions}>
					<Icon type='edit' onPress={() => setTeamToUpdate(team)} title={t('global.edit')} />
					<Icon type='user' onPress={() => setActorsToUpdate(team)} title={t('3x3.teams.actors.title')} />
				</View>
			])} />

			<Button label={t('3x3.teams.create')} onPress={() => setTeamToCreate({})} />

			<Modal visible={!!teamToCreate} close={() => setTeamToCreate(undefined)} title={t('3x3.teams.create')} header fullscreen>
				<Team3x3Data initialData={teamToCreate} onSave={onCreate} onCancel={() => setTeamToCreate(undefined)} />
			</Modal>

			<Modal visible={!!teamToUpdate} close={() => setTeamToUpdate(undefined)} title={teamToUpdate?.name} header fullscreen>
				<Team3x3Data initialData={teamToUpdate} onSave={onUpdate} onCancel={() => setTeamToUpdate(undefined)} />
			</Modal>

			<Modal visible={!!actorsToUpdate} close={() => setActorsToUpdate(undefined)} title={actorsToUpdate?.name} header fullscreen>
				<Team3x3Actors shirtSizes={shirtSizes} initialData={actorsToUpdate} onSave={onUpdateActor} onDelete={onDeleteActor} onCancel={() => setActorsToUpdate(undefined)} />
			</Modal>
		</Container>
	)
}

const styles = StyleSheet.create({
	actions: {
		flexDirection: 'row',
		aignItems: 'center',
		justifyContent: 'flex-end',
		flex: 1,
	}
});