import { Text, Title } from "owc-react-lib-common";
import * as React from "react";
import { StyleSheet, useWindowDimensions, ViewProps } from "react-native";
import { Link } from "react-router-dom";
import { styling } from "src/config";
import { Sidebar } from "./Sidebar/Sidebar";
import { View } from "./View";

interface IContainer {
  children: any;
  style?: ViewProps["style"];
  shadow?: boolean; // deprecated
  breadcrumbs?: { label: string; url: string }[];
  title?: string;
  sidebar?: any[]; // todo
  right?: string;
  description?: string;
  exactRoute?: boolean;
}

export const Container = ({ children, style, description, breadcrumbs, title, sidebar, right, exactRoute }: IContainer) => {
  const dimensions = useWindowDimensions();

  return (
    <View style={[styles.container, style]}>
      <View style={[styles.content, !!sidebar && styles.contentWithSidebar, dimensions.width <= 768 && { padding: 0 }]}>
        <View style={styles.header}>
          {!!breadcrumbs && (
            <View style={styles.breadcrumbs}>
              {breadcrumbs.map((breadcrumb, index) => {
                const isLast = index === breadcrumbs.length - 1;

                return (
                  <React.Fragment key={breadcrumb.url}>
                    <Link to={breadcrumb.url} style={{ textDecoration: "none" }}>
                      <Text bold={isLast} style={styles.breadcrumb}>
                        {breadcrumb.label}
                      </Text>
                    </Link>
                    {!isLast && <Text style={styles.breadcrumb}>&raquo;</Text>}
                  </React.Fragment>
                );
              })}
            </View>
          )}
          <View style={styles.title}>
            {!!title && <Title containerStyle={{ width: 'auto' }} uppercase fontWeight="bold" text={title} />}
            {!!right && <Text bold uppercase>{right}</Text>}
          </View>

          {!!description && (
            <View style={styles.description}>
              {description.split('<br>').map((description: string) => <Text key={description}>{description}</Text>)}
            </View>
          )}
        </View>

        <View style={styles.body}>
          {!!sidebar &&
            <View style={[styles.sidebar]} >
              <Sidebar elements={sidebar} exact={exactRoute}/>
            </View>
          }

          <View style={styles.children}>{children}</View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  description: {
    marginBottom: styling.spacing,
  },
  content: {
    flex: 1,
    width: "100%",
    maxWidth: 1400,
    marginHorizontal: "auto",
    padding: 10,
    // paddingHorizontal: styling.spacing,
    // paddingVertical: styling.spacing,
    // marginBottom: styling.spacing,

    // borderWidth: 1,
    // borderColor: '#eee',
    // borderRadius: 10,
  },
  contentWithSidebar: {
    maxWidth: 1600,
  },
  header: {
    padding: styling.spacing,
    paddingBottom: 5,
  },
  breadcrumbs: {
    flexDirection: "row",
    marginBottom: styling.spacing,
  },
  breadcrumb: {
    marginRight: 10,
  },
  titleContainer: {
    flex: 1,
    marginBottom: styling.spacing,
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  right: {
    flex: 1,
    textAlign: "right",
  },
  body: {
    flex: 1,
    flexDirection: "row",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
    // borderWidth: 1,

    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowRadius: 24,
    shadowOpacity: 0.15,
    shadowColor: "#000",
  },
  sidebar: {
    width: 300,
    backgroundColor: "#fff",
    borderRightWidth: 1,
    borderColor: '#ccc',
  },
  children: {
    flex: 1,
    padding: styling.spacing,
    // paddingLeft: styling.spacing,
    backgroundColor: "#fff",
  },
});
