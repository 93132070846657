import dayjs from 'dayjs';
import { t } from 'owc-react-lib-common';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/Loader';
import { Tabs } from "src/components/Tabs";
import { Title } from 'src/components/Title';
import { View } from 'src/components/View';
import { MODALITY_3x3, MODALITY_5x5 } from 'src/config';
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { useAlerts } from 'src/hooks/useAlerts';
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { useQueryParams } from 'src/hooks/useQueryParams';
import * as ActorService from 'src/services/ActorService';
import * as EventService from 'src/services/EventService';
import * as RegisterQuestionService from 'src/services/RegisterQuestionService';
import * as TeamService from 'src/services/TeamService';
import * as LicenceUtils from 'src/utils/LicenceUtils';
import { TeamActorsList } from './TeamActorsList';
import { TeamEditData } from './TeamEditData';
import { TeamRooms } from './TeamRooms';

// const MODALITY_3x3 = 2;

export const Team = () => {
  const { eventUuid, teamUuid } = useParams();
  const { eventCategoryId } = useQueryParams();

  const alerts = useAlerts();

  const context = React.useContext(OWCOpenDmrContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [dataActors, setDataActors] = React.useState<any>({});
  const [teamEventCategoryActors, setTeamEventCategoryActors] = React.useState<any>({});
  const [event, setEvent] = React.useState<any>();
  const [category, setCategory] = React.useState<any>();
  const [questions, setQuestions] = React.useState<any[]>([]);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    // validate
    if (!teamUuid) {
      return;
    }

    // fetch data
    try {
      const [team] = await Promise.all([
        TeamService.findByUuid(teamUuid, eventCategoryId),
      ]);

      const [event, questions, actors] = await Promise.all([
        EventService.findByUuid(eventUuid || team.data.eventUuid),
        team.data.modalityId === MODALITY_5x5 ? RegisterQuestionService.getByCategoryIdAndType(eventCategoryId || team.data.eventCategoryId, 1) : RegisterQuestionService.getByPortalId(context.configuration.portal.id),
        ActorService.findByTeamUuid(teamUuid, team.data.modalityId === MODALITY_5x5, eventCategoryId),
      ]);

      if (team.data.modalityId === MODALITY_3x3) {
        const teamEventCategoryId = team.data.teamEventCategories.find(((tec: any) => tec.eventCategory.id === parseInt(eventCategoryId))).id;
        const teamEventCategoryActors = await ActorService.findByTeamEventCategoryId(teamUuid, teamEventCategoryId);
        setTeamEventCategoryActors(teamEventCategoryActors.data);
      }
      setEvent(event.data);
      setCategory(event.data.eventCategories.find((eventCategory: any) => eventCategory.id === (parseInt(eventCategoryId) || team.data.eventCategoryId)));
      setDataActors(actors.data.map((actor: any) => ({ ...actor, _id: actor.id, formattedBirthDate: dayjs(actor.peopleBirthdate).format('DD/MM/YYYY') })).sort(LicenceUtils.sort));
      setData(team.data);
      setQuestions(questions.data);

      

    } catch (error) {
      alerts.createDSMError(error);
    }

    // loading
    setLoading(false);

    
  });

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }

  if (!data.id) {
    return <View />
  }

  /**
   * render
   */
  return (
    <View>
      <Title text={data.name} right={category.name} />

      <Tabs headers={[t('admin.teams.detail'), t('admin.teams.players'), ...(event.modalityId === MODALITY_5x5 && event.allowHotel ? [t('admin.teams.rooms')] : [])]}>
        <TeamEditData
          key={1}
          data={data}
          levels={category?.teamLevels}
          category={category}
          modality={event.modalityId}
        />
        <TeamActorsList key={2} category={category} eventUuid={data.eventUuid} dataActors={dataActors} dataTeamEventCategoryActors={teamEventCategoryActors} team={data} questions={questions} />
        {event.modalityId === MODALITY_5x5 && event.allowHotel && <TeamRooms key={3} dataTeam={data} eventUuid={data.eventUuid} category={category} dataActors={dataActors} />}
      </Tabs>
    </View>
  );
};

