import * as React from "react";
import { Picker, t, useComponentDidMount } from "owc-react-lib-common";

import { useAlerts } from "src/hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { FacilityContext } from "./FacilityContext";
import { Input } from "owc-react-lib-common"
import { Button } from "src/components/Button";
import { Loader } from "src/components/Loader";
import { IValidationParams, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as FacilityService from "src/services/FacilityService";
import * as TownService from "src/services/TownService";
import * as ProvinceService from "src/services/ProvinceService";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";

export const FacilityDetail = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const { data: facility } = React.useContext(FacilityContext);
  const navigate = useNavigate();
  const alerts = useAlerts();
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [provincesData, setProvincesData] = React.useState<any[]>([]);
  const [townsInProvinceData, setTownsInProvinceData] = React.useState<any[]>([]);
  const form = React.useRef<any>(facility);

  const getTownsInProvince = React.useCallback(async (provinceId: number) => {
    if (provinceId) {
      try {
        const response = await TownService.findByProvinceId(provinceId);
        setTownsInProvinceData(response.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    } else {
      setTownsInProvinceData([]);
    }
  }, [alerts]);

  const setProvinceId = React.useCallback((value: string) => {
    form.current.provinceId = value;
    form.current.townId = undefined;
    getTownsInProvince(parseInt(value));
  }, [getTownsInProvince]);

  const validate = React.useCallback(() => {
    const formRequiredFields: IValidationParams[] = [
      { name: "name", type: REQUIRED },
      { name: "adress", type: REQUIRED },
      { name: "provinceId", type: REQUIRED },
      { name: "townId", type: REQUIRED },
      { name: "zipCode", type: REQUIRED },
      { name: "geoX", type: REQUIRED },
      { name: "geoY", type: REQUIRED },
    ];
    const validated = validateAll(form.current, formRequiredFields);
    setErrors(validated.errors);
    return !validated.hasErrors;
  }, [validateAll]);

  useComponentDidMount(async () => {
    try {
      const response = await ProvinceService.findAll();
      setProvincesData(response.data);
    } catch (error) {
      alerts.createDSMError(error);
    }
    if (facility.provinceId) {
      try {
        await getTownsInProvince(facility.provinceId);
      } catch (error) {
        alerts.createDSMError(error);
      }
    }

    setLoading(false);
  });

  const createFacility = React.useCallback(async () => {
    if (!validate()) return;
    try {
      const response = await FacilityService.createFacility(form.current);
      alerts.create({
        text: t("admin.facilities.createConfirmation"),
        type: "success",
      });
      navigate(`/organizer/facilities/${response.data.uuid}`);
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, navigate, validate]);

  const updateFacility = React.useCallback(async () => {
    if (!validate()) return;
    try {
      await FacilityService.updateFacility(form.current);
      alerts.create({
        text: t("admin.facilities.updateConfirmation"),
        type: "success",
      });
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [alerts, validate]);

  if (loading) {
    return <Loader />;
  }

  const selectProvinces = provincesData.map((province: any) => {
    return { label: province.name, value: province.id };
  });

  const selectTowns = townsInProvinceData.map((town: any) => {
    return { label: town.name, value: town.id };
  });

  return (
    <Form>
      <Row flex={[2, 3]}>
        <Input
          label={t("admin.facilities.name")}
          defaultValue={form.current.name}
          onChange={(value) => form.current.name = value}
          error={errors.name}
          disabled={readOnly}
          required
        />
        <Input
          label={t("admin.facilities.address")}
          defaultValue={form.current.adress}
          onChange={(value) => (form.current.adress = value)}
          error={errors.adress}
          disabled={readOnly}
          required
        />
      </Row>

      <Row>
        <Picker
          label={t("admin.facilities.province")}
          defaultValue={form.current.provinceId}
          items={selectProvinces}
          onChange={setProvinceId}
          error={errors.provinceId}
          disabled={readOnly}
          required
        />
        <Picker
          label={t("admin.facilities.town")}
          defaultValue={form.current.townId}
          items={selectTowns}
          onChange={(value) => (form.current.townId = value)}
          error={errors.townId}
          disabled={readOnly}
          required
        />

        <Input
          label={t("admin.facilities.zipCode")}
          defaultValue={form.current.zipCode}
          onChange={(value) => (form.current.zipCode = value)}
          error={errors.zipCode}
          disabled={readOnly}
          required
          numeric
        />
        <Input
          label={t("admin.facilities.geoX")}
          defaultValue={form.current.geoX}
          onChange={(value) => (form.current.geoX = value)}
          error={errors.geoX}
          disabled={readOnly}
          required
          numeric
        />
        <Input
          label={t("admin.facilities.geoY")}
          defaultValue={form.current.geoY}
          onChange={(value) => (form.current.geoY = value)}
          error={errors.geoY}
          disabled={readOnly}
          required
          numeric
        />
      </Row>

      <Row>
        {facility.id ? (
          <Button
            label={t("global.save")}
            onPress={updateFacility}
            containerStyle={{ marginBottom: 0 }}
            disabled={readOnly}
          />
        ) : (
          <Button
            label={t("global.create")}
            onPress={createFacility}
            containerStyle={{ marginBottom: 0 }}
            disabled={readOnly}
          />
        )}
      </Row>
    </Form>
  );
};
