import { BACKEND_URL } from "../config";
import * as RestService from "./RestService";

const BASE_URL = BACKEND_URL + "/auth/ranking";

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const getRanking = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/${eventUuid}`);
};

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const getTeamActorRanking = (eventUuid: string) => {
  return RestService.get(`${BASE_URL}/team-actor-ranking/${eventUuid}`);
};

/**
 * @returns Recupera todos los sistemas de puntuación de un organizador
 */
export const getRankingByCompetition = (competitionUuid: string) => {
  return RestService.get(`${BASE_URL}/competition/${competitionUuid}`);
};


/**
 * @returns Crea un ranking
 */
export const computeRanking = (eventUuid: string, rankingSystemUuid: string) => {
  return RestService.post(`${BASE_URL}/${eventUuid}/${rankingSystemUuid}`);
};

export const computeActorRanking = (eventUuid: string, rankingSystemUuid: string) => {
  return RestService.post(`${BASE_URL}/team-actor/${eventUuid}/${rankingSystemUuid}`);
}

/**
 * @returns Crea un ranking
 */
export const updateRanking = (payload: any) => {
  return RestService.put(`${BASE_URL}`, payload);
};
/**
 * @returns Crea un ranking
 */
export const updateTeamActorRanking = (payload: any) => {
  return RestService.put(`${BASE_URL}/team-actor`, payload);
};
/**
 * @returns Crea un ranking
 */
export const deleteRanking = (rankingId: number) => {
  return RestService.delete(`${BASE_URL}/${rankingId}`);
};
/**
 * @returns Crea un ranking
 */
export const deleteTeamActorRanking = (rankingId: number) => {
  return RestService.delete(`${BASE_URL}/team-actor/${rankingId}`);
};


export const downloadExcel = (eventUuid: any) => {
	return RestService.requestFile(`${BASE_URL}/${eventUuid}/ranking-excel`, 'excel', `${eventUuid}.xlsx`, '_blank');
}




