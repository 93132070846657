import * as React from "react";
import { t } from "owc-react-lib-common";

import { StyleSheet } from "react-native";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "src/components/Alert";
import { Button } from "src/components/Button";
import { Input } from "owc-react-lib-common"
import { useAlerts } from "src/hooks/useAlerts";
import { CUSTOM, PASSWORD, REQUIRED, useValidation } from "src/hooks/useValidation";
import * as UserService from "src/services/UserService";
import { UserActionCard } from "./components/UserActionCard";
import { Form } from "src/components/Form";
import { Row } from "src/components/Row/Row";

export const PasswordRecovery = () => {
  const alerts = useAlerts();
  const { code } = useParams();
  const navigate = useNavigate();
  // form
  const form = React.useRef<any>({});
  const { validateAll } = useValidation();
  const [errors, setErrors] = React.useState<any>({});

  const setPassword = React.useCallback((value: string) => (form.current.password = value), []);
  const setPassword2 = React.useCallback((value: string) => (form.current.password2 = value), []);

  /**
   * send
   */
  const submit = React.useCallback(async () => {
    const { errors, hasErrors } = validateAll(form.current, [
      { name: "password", type: [REQUIRED, PASSWORD] },
      {
        name: "password2",
        type: [REQUIRED, CUSTOM],
        validate: () => {
          return form.current.password !== form.current.password2 && t("errors.password.confirmation");
        },
      },
    ]);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    try {
      await UserService.recoveryPassword(code!, form.current.password);
      alerts.create({ type: "success", text: t("public.users.passwordRecovery.success") });
      navigate("/login");
    } catch (error) {
      alerts.createDSMError(error);
    }
  }, [validateAll, navigate, alerts, code]);

  /**
   * render
   */
  return (
    <UserActionCard>
      <Alert type="info" text={t("public.users.passwordRecovery.description")} />
      <Form>
        <Row>
          <Input
            label={t("public.users.passwordRecovery.password")}
            onChange={setPassword}
            error={errors.password}
            secureTextEntry
          />
        </Row>
        <Row>
          <Input
            label={t("public.users.passwordRecovery.passwordConfirmation")}
            onChange={setPassword2}
            error={errors.password2}
            secureTextEntry
          />
        </Row>
        <Row>
          <Button label={t("global.save")} onPress={submit} containerStyle={styles.button} />
        </Row>
      </Form>
    </UserActionCard>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 5,
  },
});
