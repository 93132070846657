import * as React from 'react';
import { StyleSheet } from 'react-native';
import { styling } from 'src/config';
import { OutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { IconButton } from './IconButton';
import { View } from './View';


export const NavbarDropdown = ({ text, icon, containerStyle, children, position, fontSize, color = 'black', indexSeparator=[] }: any) => {
  const [drop, setDrop] = React.useState(false);

  const toggleDropdown = () => {
    setDrop(!drop);
  }

  return (
    <OutsideAlerter handleClick={setDrop}>
      <View style={[styles.container, containerStyle, { position: 'relative', marginBottom: '0px' }]}>
        <IconButton color={color} fontSize={fontSize} name={icon} text={text} onPress={toggleDropdown}></IconButton>
        <View style={[styles.content, ((drop) ? { display: 'flex' } : { display: 'none' }), position]}>
          {children.map((child, i) => {
            return (
              <View key={i} style={indexSeparator.includes(i) ? styles.separator : {}}>{child}</View>
            );
          })}
        </View>
      </View>
    </OutsideAlerter>
  )
}
const styles = StyleSheet.create({
  container: {
    marginBottom: styling.spacing,
    minHeight: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    zIndex: 9,
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  separator: {
    borderTopColor: '#555',
    borderTopWidth: 2
  }
});