import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Navigate, Route, Routes } from "react-router-dom";
import { View } from 'src/components/View';
import { OWCOpenDmrContext } from "src/context/OWCOpenDmrContext";
import { useComponentDidMount } from 'src/hooks/useComponentDidMount';
import { CompetitionList } from 'src/pages/auth/competitions/CompetitionList';
import { RegisterTeam } from 'src/pages/auth/competitions/RegisterTeam';
import { ThreeOnThree } from 'src/pages/auth/threeonthree/ThreeOnThree';
// import { RegisterPersonalData } from 'src/pages/auth/RegisterPersonalData';
import { Settings } from 'src/pages/auth/Settings';
import { TeamDetail } from 'src/pages/auth/teams/TeamDetail';
import { TeamList } from 'src/pages/auth/teams/TeamList';
// import * as PeopleService from 'src/services/PeopleService';
import { MODALITY_5x5 } from 'src/config';
import { RegisterTeam3x3 } from 'src/pages/auth/competitions/RegisterTeam3x3';
import { Main } from 'src/pages/auth/Main';
import { ShoppingCartView } from 'src/pages/auth/shoppingCart/ShoppingCartView';
import * as AsyncStorageUtils from 'src/utils/AsyncStorageUtils';
import { AuthenticatedNavbar } from "./AuthenticatedNavbar";

export const Auth = () => {
  const context = React.useContext(OWCOpenDmrContext);
  const [loading, setLoading] = React.useState(true);
  // const [person, setPerson] = React.useState<any>();

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    // register
    const [redirect/*, person*/] = await Promise.all([
      AsyncStorageUtils.getRedirect(),
      // PeopleService.findMe().catch(() => {}),
    ]);

    // register person data
    // setPerson(person?.data);

    // redirect
    if (!!redirect && !!context?.user) {
      AsyncStorageUtils.setRedirect('');
      window.location.href = redirect;
      return;
    }

    // loading
    setLoading(false);
  });

  /**
   * on register personal data success
   */
  // const onRegisterPersonalDataSuccess = React.useCallback(() => {
  //   window.location.reload();
  // }, []);

  /**
   * loading
   */
  if (loading) {
    return null;
  }

  /**
   * not logged
   */
  if (!context.user) {
    return <Navigate to='/login' />
  }

  /**
   * render
   */
  return (
    <View style={styles.container}>
      <AuthenticatedNavbar />

      <View style={styles.content}>
        <Routes>
          <Route path='competitions' element={<CompetitionList modalityId={MODALITY_5x5} />} />
          <Route path='competitions/3x3/event/:eventUuid' element={<RegisterTeam3x3 />} />
          <Route path='competitions/:competitionUuid/event/:eventUuid' element={<RegisterTeam />} />
          <Route path='teams' element={<TeamList />} />
          <Route path='teams/:teamUuid' element={<TeamDetail />} />
          <Route path='settings' element={<Settings />} />
          <Route path='3x3/*' element={<ThreeOnThree />} />
          <Route path='shopping-cart/*' element={<ShoppingCartView />} />
          <Route path='*' element={<Main />} />
        </Routes>

        {/* {!person?.id && (
          <View style={styles.form}>
            <RegisterPersonalData onSuccess={onRegisterPersonalDataSuccess} />
          </View>
        )} */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f1f5f9',
  },
  // form: {
  //   position: 'absolute',
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   backgroundColor: '#eee',
  //   marginVertical: styling.spacing,
  // },
  content: {
    flex: 1,
    // paddingTop: 80 + styling.spacing,
    // padding: styling.spacing,
  },
});