import * as React from 'react';

interface IEventContext {
  data: any;
  setData: (object: any) => void;
}

export const EventContext = React.createContext<IEventContext>({
  data: {},
  setData: () => {},
});