import { BACKEND_URL } from '../config';
import * as RestService from './RestService';

const BASE_URL = BACKEND_URL + '/auth/provinces'

export const findAll = () => {
  return RestService.get(BASE_URL);
}

export const findByProvinceId = (provinceId: number) => {
  return RestService.get(`${BASE_URL}/${provinceId}`);
}
