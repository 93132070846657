/* eslint no-control-regex: off */

import * as React from 'react';
import { t } from 'owc-react-lib-common';
import { DOCUMENT_TYPE_CIF, DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIF } from 'src/pages/auth/components/config';

export const REQUIRED = 'required';
export const FORMAT = 'format';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const NUMBER = 'number';
export const MAX_LENGTH = 'max';
export const MIN_LENGTH = 'min';
export const DATE = 'date';
export const NIF = 'nif';
export const NIE = 'nie';
export const CIF = 'cif';
export const DOCUMENT_ID = 'document_id';
export const PASSWORD = 'password';
export const CUSTOM = 'custom';

const types = [REQUIRED, FORMAT, EMAIL, PHONE, NUMBER, MAX_LENGTH, MIN_LENGTH, DATE, NIF, NIE, CIF, DOCUMENT_ID, PASSWORD, CUSTOM];
type Type = typeof types[number];

export interface IValidationParams {
  type: Type | Type[];
  name: string;
  format?: RegExp;
  max?: number;
  min?: number;
  documentTypeId?: number;
  validate?: (value: string) => any;
}

export const useValidation = () => {


  /**
   * required
   */
  const required = React.useCallback((value: any) => {
    if (value === undefined || value === null || (typeof value === 'string' && !value)) {
      return t('errors.required');
    }

    if ((value + '').trim() === '') {
      return t('errors.required');
    }
  }, []);

  /**
   * format
   */
  const format = React.useCallback((value: string | number, format: RegExp) => {
    if (value === undefined || format === undefined) {
      return t('errors.format');
    }

    if (!['string', 'number'].includes(typeof value)) {
      return t('errors.format');
    }

    if (!format.test(value + '')) {
      return t('errors.format');
    }
  }, []);

  /**
   * email
   */
  const email = React.useCallback((value: string) => {
    return format(value, /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i);
  }, [format]);

  /**
   * phone
   */
  const phone = React.useCallback((value: string) => {
    return format(value?.replace(' ', ''), /^\+?[0-9]{6,}$/g);
  }, [format]);

  /**
   * number
   */
  const number = React.useCallback((value: string | number) => {
    return format(value, /^\d*\.?\d*$/g);
  }, [format]);

  /**
   * max
   */
  const max = React.useCallback((value: string | number, size: number) => {
    if (!value) {
      return;
    }

    if ((value + '').length > size) {
      return t('errors.max', { amount: size });
    }
  }, []);

  /**
   * max
   */
  const min = React.useCallback((value: string | number, size: number = 0) => {
    if (!value || (value + '').length < size) {
      return t('errors.min', { amount: size });
    }
  }, []);

  /**
   * date
   */
  const date = React.useCallback((value: string) => {
    return format(value, /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/);
  }, [format]);

  /**
   * nif
   */
  const nif = React.useCallback((value: string) => {
    let numero, letr, letra;
    let expresion_regular_dni = /^\d{5,8}[A-Z]$/;

    const dni = value.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substring(0, dni.length - 1);
      letr = dni.substring(dni.length - 1);
      numero = parseInt(numero) % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);

      if (letra === letr) {
        return;
      }
    }

    return t('errors.format');
  }, []);

  /**
   * nie
   */
  const nie = React.useCallback((value: string) => {
    if(!['X', 'Y', 'Z'].includes(value?.charAt(0))) {
      return t('errors.format');
    }
    
    return nif(value?.replace(/^X/, '0').replace(/^Y/, '1').replace(/^Z/, '2'));
  }, [nif]);

  /**
   * cif
   */
  const cif = React.useCallback((value: string) => {
    if (!value || value.length !== 9) {
      return t('errors.format');
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = value.substr(1, value.length - 2);
    const letter = value.substr(0, 1);
    const control = value.substr(value.length - 1);
    let sum = 0, i, digit;

    if (!letter.match(/[A-Z]/)) {
      return t('errors.format');
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return t('errors.format');
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = Math.floor(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/) && String(digit) === control) {
      return;
    }
    if (letter.match(/[NPQRSW]/) && letters[digit] === control) {
      return;
    }

    if (String(digit) === control || letters[digit] === control) {
      return;
    }

    return t('errors.format');
  }, []);

  /**
   * document id
   */
  const documentId = React.useCallback((value: string, documentTypeId?: number) => {
    if (documentTypeId === DOCUMENT_TYPE_NIF) {
      return nif(value);
    }

    if (documentTypeId === DOCUMENT_TYPE_NIE) {
      return nie(value);
    }

    if (documentTypeId === DOCUMENT_TYPE_CIF) {
      return cif(value);
    }
  }, [nif, nie, cif]);

  /**
   * password
   */
  const password = React.useCallback((value: string) => {
    if (!value) {
      return t('errors.format');
    }

    if (value.length < 6) {
      return t('errors.password.length', { length: 6 });
    }

    if (!value.match(/[A-Z]/)) {
      return t('errors.password.uppercase');
    }

    if (!value.match(/[a-z]/)) {
      return t('errors.password.lowercase');
    }

    if (!value.match(/[0-9]/)) {
      return t('errors.password.number');
    }
  }, []);

  /**
   * validate
   */
  const validate = React.useCallback((value: any, type: Type, params?: IValidationParams) => {
    switch (type) {
      case REQUIRED:
        return required(value);
      case FORMAT:
        return format(value, params?.format!);
      case EMAIL:
        return email(value);
      case PHONE:
        return phone(value);
      case NUMBER:
        return number(value);
      case MAX_LENGTH:
        return max(value, params?.max!);
      case MIN_LENGTH:
        return min(value, params?.min!);
      case DATE:
        return date(value);
      case NIF:
        return nif(value);
      case NIE:
        return nie(value);
      case CIF:
        return cif(value);
      case DOCUMENT_ID:
        return documentId(value, params?.documentTypeId);
      case PASSWORD:
        return password(value);
      case CUSTOM:
        return params?.validate?.(value);
    }
  }, [required, format, email, phone, number, max, min, date, nif, nie, cif, documentId, password]);

  /**
   * validate all
   */
  const validateAll = React.useCallback((object: any, params: IValidationParams[]) => {
    const errors: any = {};

    params.forEach((param) => {
      const types: Type[] = Array.isArray(param.type) ? param.type : [param.type];

      for (let type of types) {
        const error = validate(object[param.name], type, param);

        if (!!error) {
          errors[param.name] = error
          break;
        }
      }
    });

    const hasErrors = Object.keys(errors).length > 0;

    return { errors, hasErrors };
  }, [validate]);

  return { validateAll, validate, required, format, email, phone, number, max, min, date, nif, nie, cif, documentId, password };
}