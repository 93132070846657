import * as React from 'react';
import { Container } from 'src/components/Container';
import { TeamCreation } from '../components/TeamCreation';

export const RegisterTeam = () => {
  return (
    <Container>
      <TeamCreation />
    </Container>
  );
}