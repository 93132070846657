import * as React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { Navigate, Route, Routes } from "react-router-dom";
import { OWCOpenDmrContext } from 'src/context/OWCOpenDmrContext';
import { Login } from "src/pages/public/users/Login";
import { PasswordRecovery } from 'src/pages/public/users/PasswordRecovery';
import { PasswordRecoveryRequest } from 'src/pages/public/users/PasswordRecoveryRequest';
import { Register } from 'src/pages/public/users/Register';
import { Validate } from 'src/pages/public/users/Validate';

export const Public = () => {
  const context = React.useContext(OWCOpenDmrContext);

  if (!!context.user) {
    return (
      <Navigate to='/auth' />
    );
  }

  return (
    <ImageBackground source={{ uri: context.configuration.portal.background }} blurRadius={0} style={styles.container}>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='password-recovery-request' element={<PasswordRecoveryRequest />} />
        <Route path='pw-recovery/:code' element={<PasswordRecovery />} />
        <Route path='register' element={<Register />} />
        <Route path='validate/:code' element={<Validate />} />
        <Route path='*' element={<Navigate to='login' />} />
      </Routes>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#b2def0',
  },
});