import * as React from "react";
import { StyleSheet } from 'react-native';
import { View } from 'src/components/View';
import { Text } from 'src/components/Text';
import { InputDate } from "src/components/InputDate";
import { t } from "owc-react-lib-common";
import { minifyTeamName } from "src/utils/StringUtils";

export const Combination = ({ matchTable, groupTeams, positionTable, updateRound }) => {
    return (
        matchTable.map((round, id) => {
  
          return (
            <View key={id} style={[styles.combination]} >
              <View style={{ position: 'relative', height: 35 }}>
                <Text bold style={[{ padding: 5, backgroundColor: '#ffccaa', position: 'absolute', top: -12, left: -9, width: 350, borderTopLeftRadius: 10, borderTopRightRadius: 10 }]}>
                  {`${t('admin.events.league.round')} ${round.roundNumber}`}
                </Text>
              </View>
              <InputDate onChange={(e) => updateRound(e, round)} useTime defaultValue={round.startDate} />
              {round.matches.map((match, id) => {
                if (round.roundNumber === match.roundNumber) {
                  const matchPositions = positionTable.find(pt => pt.roundNumber === round.roundNumber).matches[id];
                  const teamHome = groupTeams.find(t => t.id === match.homeTeam);
                  const teamAway = groupTeams.find(t => t.id === match.awayTeam);
  
                  return (
                    <View key={id} flexDirection='row' justifyContent='space-between' >
                      <View flexDirection='row' alignItems='center' style={{ width: '23%' }}>
                        <Text bold style={[{ backgroundColor: matchPositions?.homeTeam.bgColor }, styles.colorBox]}>{matchPositions?.homeTeam.position}</Text>
                        <Text bold>{` ${minifyTeamName(teamHome?.name).toUpperCase() ?? t('admin.events.league.rest')}`}</Text>
                      </View>
                      <View flexDirection='row' alignItems='center'>
                        <Text>-</Text>
                      </View>
                      <View flexDirection='row' alignItems='center' justifyContent='flex-end' style={{ width: '23%' }}>
                        <Text bold>{` ${minifyTeamName(teamAway?.name).toUpperCase() ?? t('admin.events.league.rest')} `}</Text>
                        <Text bold={true} style={[{ backgroundColor: matchPositions?.awayTeam.bgColor }, styles.colorBox]}>{matchPositions?.awayTeam.position}</Text>
                      </View>
                    </View>
                  )
                } else return null;
              })}
            </View>
          )
        })
      )
}
const styles = StyleSheet.create({
    combination: {
      borderColor: '#dbdbdb',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 10,
      padding: 8,
      display: 'flex',
      shadowColor: 'black',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      width: 350
  
    },
    colorBox: {
      padding: 6,
      paddingHorizontal: 12,
      // marginVertical: 10,
      color: 'white',
      fontWeight: '800',
      borderRadius: 5
    }
  });