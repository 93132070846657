import * as React from 'react';

import { StyleSheet } from "react-native";
import { useNavigate, useParams } from "react-router-dom";
import * as OrganizerService from "src/services/OrganizerService"
import * as PortalService from "src/services/PortalService"
import { Loader } from 'src/components/Loader';
import { View } from "src/components/View";
import { styling } from "src/config";
import { useAlerts } from "src/hooks/useAlerts";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { OrganizerUpdate } from './OrganizerUpdate';
import { IPortal } from 'src/services/PortalService';

export const Organizer = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const alerts = useAlerts();

  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [portals, setPortals] = React.useState<IPortal[]>([]);

  /**
   * component did mount
   */
  useComponentDidMount(async () => {
    // validate params
    if (!uuid) {
      navigate('/admin/organizers');
      return;
    }

    // fetch data
    try {
      const portalsResponse = await PortalService.getPortals();
      const response = await OrganizerService.findOrganizerByUuid(uuid);
      setData(response.data);
      setPortals(portalsResponse);
    } catch (error) {
      alerts.createDSMError(error);
    }

    // done
    setLoading(false);
  });

  /**
   * loading
   */
  if (loading) {
    return <Loader />
  }


  return (
    <View style={styles.container}>
      <OrganizerUpdate data={data} onSave={setData} portals={portals} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  sidebar: {
    width: 300,
    backgroundColor: 'white',
    margin: styling.spacing,
    marginTop: 0,
  },
  content: {
    flex: 1,
    paddingHorizontal: styling.spacing,
    marginLeft: 300,
  },
  submit: {
    marginBottom: 0,
  },
});