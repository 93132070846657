import * as React from 'react';
import { ColorValue } from 'react-native';
import { Icon as OWCIcon } from 'owc-react-lib-common';
import { IconType } from 'libs/owc-react-lib-common/dist/components/Icon/IconType';

const SIZES = ['small', 'medium', 'large', undefined];
export type IIconSize = typeof SIZES[number];
export interface IIcon {
  type?: 'primary' | 'secondary';
  name: IconType;
  color?: ColorValue;
  backgroundColor?: ColorValue;
  size?: IIconSize;
  style?: React.CSSProperties;
  fontSize?: number;
  padding?: number;
  text?: string;
  title?: string;
  onPress?: (v: any) => void;
};

export const Icon = ({ name, color, onPress, style, fontSize = 20, text, title, padding }: IIcon) => {
  return (
    <OWCIcon padding={padding} text={text} fontSize={fontSize} title={title} type={name} color={color?.toString()} containerStyle={style} onPress={onPress}></OWCIcon>
  );
}