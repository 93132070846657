import * as React from "react";
import { t } from "owc-react-lib-common";
import { useAlerts } from "src/hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "src/components/Container";
import { View } from "src/components/View";
import { Title } from "src/components/Title";
import { FloatingIconButton } from "src/components/FloatingIconButton";
import { useEffectChange } from "src/hooks/useEffectChange";
import { Loader } from "src/components/Loader";
import * as RankingSystemService from "src/services/RankingSystemService";
import { ModalConfirm } from "src/components/ModalConfirm";
import { Tabs } from "src/components/Tabs";
import { ORGANIZER_READ_ONLY } from "src/context/OWCOpenDmrContext";
import { useUserPermission } from "src/hooks/useUserPermission";
import { ButtonLink } from "src/components/ButtonLink";
import { useColors } from "src/hooks/useColors";
import { RankingSystemContext } from "./RankingSystemContext";
import { RankingSystemForm } from "./RankingSystemForm";
import { RankingSystemDetailList } from "./RankingSystemDetailList";

export const RankingSystem = () => {
  const readOnly = useUserPermission(ORGANIZER_READ_ONLY);
  const rankingSystemUuid = useParams().uuid;
  const colors = useColors('primary');
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [deleteRankingSystemModal, setDeleteRankingSystemModal] = React.useState<boolean>(false);
  const tab = React.useRef(0);

  useEffectChange(async () => {
    setLoading(true);
    if (rankingSystemUuid) {
      try {
        const response = await RankingSystemService.getRankingSystemByUuid(rankingSystemUuid);
        setData(response.data);
      } catch (error) {
        alerts.createDSMError(error);
      }
    }
    setLoading(false);
  }, [rankingSystemUuid]);

  const deleteRankingSystem = React.useCallback(async (facilityId) => {
    if (facilityId) {
      try {
        await RankingSystemService.deleteRankingSystem(facilityId);
        alerts.create({ text: t("admin.rankingSystems.deleteConfirmation"), type: "success", });
        navigate(`/organizer/facilities`);
      } catch (error) {
        alerts.createDSMError(error);
      }
      setDeleteRankingSystemModal(false);
    }
  }, [alerts, navigate]);

  if (loading) {
    return <Loader />;
  }
  const headers = [t("admin.rankingSystems.detail"), t("admin.rankingSystems.points")];

  return (
    <RankingSystemContext.Provider value={{ data }}>
      <Container title={t("admin.rankingSystems.title")}>
        <View flexDirection="row" alignItems="center">
          {data.id ? (
            <Title text={data.name} containerStyle={{ flex: 1 }} />
          ) : (
            <Title text={t("admin.rankingSystems.new")} containerStyle={{ flex: 1 }} />
          )}
          <ButtonLink
            type='link'
            color={colors.main}
            to={`/organizer/ranking-systems`}
            label={`« ${t("admin.rankingSystems.title")}`}
            contentStyle={{ right: 15 }}
          />
          {rankingSystemUuid && !readOnly && <FloatingIconButton
            name="trash"
            title={t("global.delete")}
            color="black"
            backgroundColor="transparent"
            onPress={() => setDeleteRankingSystemModal(true)}
            containerStyle={{ position: "relative" }}
            right={5}
          />}

        </View>
        {data.id ? (
          <Tabs headers={headers} initialTab={tab.current} onChangeTab={(index: number) => (tab.current = index)}>
            <RankingSystemForm />
            <RankingSystemDetailList />
          </Tabs>
        ) : (
          <RankingSystemForm />
        )}
        <ModalConfirm
          visible={deleteRankingSystemModal}
          text={t("admin.rankingSystems.deleteQuestion")}
          onClose={() => setDeleteRankingSystemModal(false)}
          onSuccess={() => deleteRankingSystem(data.id)}
        />
      </Container>
    </RankingSystemContext.Provider>
  );
};
