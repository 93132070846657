import { BACKEND_URL } from 'src/config';
import * as RestService from 'src/services/RestService';

export interface IFacilityDTO {
    id?: number;
    uuid?: string;
    name?: string;
    externalUuid?: string;
    eventId?: number;
    organizerId?: number;
    adress?: string;
    geoX?: number;
    geoY?: number;
    numCovered?: number;
    numUncovered?: number;
    townId?: number;
    zipCode?: string;
    deleted?: any;
}

const BASE_URL = BACKEND_URL + '/auth/facilities'

export const findAll = () => {
    return RestService.get(`${BASE_URL}/by-organizer`)
}

export const getFacilityById = (facilityId: string) => {
    return RestService.get(`${BASE_URL}/${facilityId}`)
}

export const getFacilityByUuid = (facilityUuid: string) => {
    return RestService.get(`${BASE_URL}/by-uuid/${facilityUuid}`)
}

export const createFacility = (facilityDTO: IFacilityDTO) => {
    return RestService.post(`${BASE_URL}`, facilityDTO)
}

export const updateFacility = (facilityDTO: IFacilityDTO) => {
return RestService.put(`${BASE_URL}`, facilityDTO)
}

export const deleteFacility = (facilityId: string) => {
    return RestService.delete(`${BASE_URL}/${facilityId}`)
}