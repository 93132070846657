import { Button, Checkbox, Input, t, Table } from "owc-react-lib-common";
import * as React from 'react';
import { View } from "src/components/View";
import { useAlerts } from "src/hooks/useAlerts";
import { REGISTER_STATUS_COMPLETED } from 'src/pages/public/actor/IRegistrationProcess';
import * as ActorService from 'src/services/ActorService';
import { ITeamActorDTO } from "src/services/TeamActorService";


interface IRegisterTeam3x3Actors {
  teamEventCategory?: any;
  disabled?: boolean;
  eventCategory: any;
  team: any;
  actors: any[];
  onSelectActors: (actors: any[]) => void;
}

export const RegisterTeam3x3Actors = ({ teamEventCategory, disabled = false, eventCategory, team, actors: initialActors, onSelectActors }: IRegisterTeam3x3Actors) => {
  
  const [actors, setActors] = React.useState<any[]>(initialActors);
  const { createDSMError } = useAlerts();


  /**
   * toggle actor selection
   */
  const toggleActor = React.useCallback((checked: boolean, teamActor: ITeamActorDTO) => {

    const selectedActors = actors.filter((actor: any) => actor.id !== teamActor.id);
    if (checked) {
      selectedActors.push(teamActor);
    }

    
    setActors(selectedActors);


  }, [actors]);

  /**
   * set actor number
   */
  const setNumber = React.useCallback((value: string, actorId: number) => {
    setActors(actors.map((actor: any) => actor.id === actorId ? { ...actor, number: value } : actor));
  }, [actors]);

  const changeActorParticipation = async (checked: boolean, teamActor: ITeamActorDTO) => {

    // Si teamEventCategory no informat --> Crida durant el proces inscripcio. Nomes valida si es pot afegir. No cal cridar a back per afegir actors,
    // es fa al completar el proces. Si informat, es a posteriori i cal afegir-los
    try {
      toggleActor(checked, teamActor);

      if (!teamEventCategory) {
        if (checked) {
          await ActorService.checkAddActorToTeam(teamActor.id, team.id, eventCategory.id);
        }
      }
      else {
        if (checked) {
          await ActorService.addTeamEventCategoryActor(teamEventCategory.id, teamActor);
        } 
        else {
          await ActorService.removeTeamEventCategoryActor(teamEventCategory.id, teamActor.id);
        }
      }

    }
    catch(error) {
     createDSMError(error)
     toggleActor(!checked, teamActor);  
    }
  
  };

  

  const getDisabled = (actor) => {
    return disabled || 
    (!actors.some((current: any) => actor.id === current.id) && actors.length >= (eventCategory?.maxPlayers || 99)) 
    || (actors.some((current: any) => actor.id === current.id) && eventCategory?.minPlayers == actors.length)
  }

  /**
   * render
   */
  return (
    <View flex={1}>
      <Table
        searchable={false}
        csvButton={false}
        pagination={false}
        headers={[
          t('3x3.teams.data.name'),
          t('3x3.teams.data.lastName'),
          { label: t('3x3.teams.data.gender'), width: 120 },
          { label: t('3x3.teams.data.birthDate'), width: 120 },
          { label: t('3x3.teams.data.number'), width: 100 },
          { key: 'checkbox', width: 50 },
        ]}
        data={team?.actors?.filter((actor: any) => actor.registrationStatus >= REGISTER_STATUS_COMPLETED && (eventCategory.gender !== 'X' ? actor.gender === eventCategory.gender : true)).map((actor: any) => [
          actor.peopleName,
          actor.peopleSurname,
          actor.gender,
          actor.formattedPeopleBirthdate,
          <Input key={`number-` + actors.some((current: any) => current.id === actor.id)} defaultValue={actor.number} onChange={(value: string) => setNumber(value, actor.id)} containerStyle={{ maxWidth: '100%', marginBottom: 0 }} disabled={!actors.some((current: any) => actor.id === current.id)} numeric />,
          <Checkbox
            value={actors.some(a => a.id === actor.id)} onChange={(value) => changeActorParticipation(value, actor)} containerStyle={{ marginBottom: 0, userSelect: 'none' }}
            disabled={getDisabled(actor)}
          />,
        ]) || []}
      />

      <View flexDirection="row" justifyContent="flex-end">
        <Button text={t('global.next')} onPress={() => onSelectActors(actors)} disabled={actors.length < (eventCategory?.minPlayers || 0)} />
      </View>
    </View>
  );
}